import React from 'react'
import { bool, func, node } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

const OFFSET = 63

const useStyles = makeStyles(theme => ({
  sidePanel: {
    top: OFFSET,
    width: 500,
    height: `calc(100vh - ${OFFSET}px)`,
    borderLeft: `1px solid ${theme.palette.color.primary}`,
    background: theme.palette.background.default,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))

export const SidePanel = ({ children, isOpened, setIsOpened }) => {
  const classes = useStyles()

  return (
    <Drawer
      classes={{
        paper: classes.sidePanel,
      }}
      anchor="right"
      open={isOpened}
      onClose={() => setIsOpened(false)}
    >
      {children}
    </Drawer>
  )
}

SidePanel.propTypes = {
  children: node.isRequired,
  isOpened: bool.isRequired,
  setIsOpened: func.isRequired,
}
