import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { FIELDS } from '#pages/distro/_utils/constants'
import { CustomSelect } from '#components/inputs/custom-select'
import { DatePicker } from '#components/date-picker'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useWizard } from '#modules/forms/form-wizard/context'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  fieldsWrapper: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  container: {
    backgroundColor: theme.palette.color.darkBlue09,
  },
  field: {
    padding: '0 30px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  releaseDateField: {
    paddingTop: '0.35rem',
  },
}))

const SCustomSelect = styled(CustomSelect)`
  width: 100%;
`

export const ReleaseDetailsFormControlledFields = ({
  control,
  selectOptions,
  errors,
  isPreviouslyReleased,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { storedFormState } = useWizard()

  return (
    <div className={classes.fieldsWrapper}>
      <div className={classes.field}>
        <Controller
          key={FIELDS.RELEASE_COPYRIGHT_YEAR}
          name={FIELDS.RELEASE_COPYRIGHT_YEAR}
          control={control}
          defaultValue={storedFormState[FIELDS.RELEASE_COPYRIGHT_YEAR]}
          render={({ field: { onChange, value } }) => {
            return (
              <SCustomSelect
                options={selectOptions[FIELDS.RELEASE_COPYRIGHT_YEAR]}
                label={t('releaseCopyrightYear')}
                value={value}
                errorMessage={
                  errors[FIELDS.RELEASE_COPYRIGHT_YEAR]?.message ||
                  errors[FIELDS.RELEASE_COPYRIGHT_YEAR]?.value?.message
                }
                isBolded
                required
                onChange={onChange}
              />
            )
          }}
        />
      </div>
      <div className={classes.field}>
        <Controller
          key={FIELDS.ARTWORK_COPYRIGHT_YEAR}
          name={FIELDS.ARTWORK_COPYRIGHT_YEAR}
          control={control}
          defaultValue={storedFormState[FIELDS.ARTWORK_COPYRIGHT_YEAR]}
          render={({ field: { onChange, value } }) => {
            return (
              <SCustomSelect
                options={selectOptions[FIELDS.ARTWORK_COPYRIGHT_YEAR]}
                label={t('artWorkCopyrightYear')}
                value={value}
                errorMessage={
                  errors[FIELDS.ARTWORK_COPYRIGHT_YEAR]?.message ||
                  errors[FIELDS.ARTWORK_COPYRIGHT_YEAR]?.value?.message
                }
                isBolded
                required
                onChange={onChange}
              />
            )
          }}
        />
      </div>
      <div className={`${classes.field} ${classes.releaseDateField}`}>
        <Controller
          name={FIELDS.PREVIOUSLY_RELEASED_DATE}
          control={control}
          defaultValue={
            new Date(storedFormState[FIELDS.PREVIOUSLY_RELEASED_DATE])
          }
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={new Date(value)}
              label={t('previousReleaseDate')}
              disabled={!isPreviouslyReleased?.value}
              errorMessage={errors[FIELDS.PREVIOUSLY_RELEASED_DATE]?.message}
              maxDate={new Date()}
              isBolded
              onChange={date => {
                onChange(date)
              }}
            />
          )}
        />
      </div>
    </div>
  )
}
