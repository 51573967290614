import React from 'react'
import { shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '40px',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
  },
  bullet: {
    color: theme.palette.color.primary,
    paddingRight: 10,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '100px',
  },
  textGroup: {
    padding: '30px',
    maxWidth: '45%',
    whiteSpace: 'pre-line',
  },
  pointItem: {
    display: 'flex',
  },
}))

export const ImageWithBullets = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <div className={classes.textGroup}>
        <div className={classes.header}>{t(item.header)}</div>
        {item.points.map((point, index) => (
          <div
            key={generateUniqueKey(point, index)}
            className={classes.pointItem}
          >
            <span className={classes.bullet}>&#8226;</span>
            <div>{t(point.text)}</div>
          </div>
        ))}
      </div>
      <img src={item.image} alt="" width={800} />
    </div>
  )
}

ImageWithBullets.propTypes = {
  item: shape({
    header: string.isRequired,
    image: string.isRequired,
    points: shape([{ text: string.isRequired }]),
  }).isRequired,
}
