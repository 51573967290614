import { NAVIGATION_PATHS } from '#routes/routes'

export const getBreadcrumbs = t => {
  return [
    {
      link: NAVIGATION_PATHS.VAULT_PROFILES,
      title: t('myVault'),
    },
  ]
}
