import React from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, shape } from 'prop-types'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Row } from '#pages/promotion/reports/components/row'
import { ColumnInRow } from '#modules/table/components/column-in-row'
import { ReviewReadyIcon } from '#pages/promotion/reports/components/review-ready-icon'
import { HEADERS, PROMOTION_STATUS } from '#pages/promotion/_utils/constants'
import { formatDate } from '#utils/formatDate'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const ReportsList = ({ reviews }) => {
  const classes = useStyles()
  const reportRedirect = review =>
    navigate(
      buildUrl(NAVIGATION_PATHS.PROMOTION_REPORT, { promotionId: review.id })
    )

  const renderPromotionStatus = review => {
    switch (review?.status) {
      case PROMOTION_STATUS.COMPLETED:
        return <ReviewReadyIcon onClickHandler={() => reportRedirect(review)} />
      default:
        return review.status
    }
  }

  return (
    <>
      {reviews.map((review, index) => {
        return (
          <Row key={review.orderId} isLighter={!isEven(index)}>
            <ColumnInRow width={HEADERS[0].width}>
              {formatDate(review.updatedAt)}
            </ColumnInRow>
            <ColumnInRow width={HEADERS[1].width}>
              <div className={classes.ellipsis}>{review.file.profile.name}</div>
            </ColumnInRow>
            <ColumnInRow width={HEADERS[2].width}>
              <div className={classes.ellipsis}>{review.trackName}</div>
            </ColumnInRow>
            <ColumnInRow width={HEADERS[3].width}>
              {review.trackRating || '- - -'}
            </ColumnInRow>
            <ColumnInRow width={HEADERS[4].width}>
              {review.passionRating || '- - -'}
            </ColumnInRow>
            <ColumnInRow
              width={HEADERS[5].width}
              className={classes.capitalize}
            >
              {review.reviewLevel}
            </ColumnInRow>
            <ColumnInRow width={HEADERS[6].width}>{review.id}</ColumnInRow>
            <ColumnInRow
              width={HEADERS[7].width}
              className={classes.capitalize}
            >
              {renderPromotionStatus(review)}
            </ColumnInRow>
          </Row>
        )
      })}
    </>
  )
}

ReportsList.propTypes = {
  reviews: arrayOf(shape({})).isRequired,
}
