import React, { useState } from 'react'
import { node, func, string, bool } from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { navigate } from '@reach/router'
import { useWizard } from '#modules/forms/form-wizard/context'
import { BottomButtonsNavigation } from '#modules/forms/components/bottom-buttons-navigation'
import { StepExitWarningModalContainer } from '#modules/forms/form-wizard/containers/step-exit-warning-modal-container'

export const StepContainer = ({
  children,
  onBackButtonClick,
  onNextButtonClick,
  noNextButton,
  noBackButton,
  customNextTitle,
  customBackTitle,
  shouldDisableNextButton,
  resolver,
  noFormStep,
  customSubmitAction,
  isInnerStep,
  isDistroStepSubmit,
}) => {
  const {
    setStoredFormState,
    incrementStep,
    decrementStep,
    storedFormState,
    step,
    isStoredFormStateEmpty,
  } = useWizard()
  const [isExitWarningModalOpen, setIsExitWarningModalOpen] = useState(false)

  const goBack = () => navigate(-1)
  const closeExitWarningModal = () => setIsExitWarningModalOpen(false)
  const enableExitWarningModal = () => setIsExitWarningModalOpen(true)

  const methods = useForm({
    resolver,
    defaultValues: storedFormState,
  })

  const onSubmit = data => {
    if (!isDistroStepSubmit) {
      setStoredFormState(data)
    }
    incrementStep()
  }

  const onStepSubmit = () => {
    if (onNextButtonClick) onNextButtonClick()
    if (!noFormStep) methods.handleSubmit(customSubmitAction || onSubmit)()
  }

  const onStepBack = () => {
    if (step === 1) {
      if (!isStoredFormStateEmpty) {
        enableExitWarningModal()
        return
      }
      goBack()
      return
    }
    if (onBackButtonClick) onBackButtonClick()
    if (isInnerStep) return
    decrementStep()
  }

  const content = (
    <>
      {children}
      <BottomButtonsNavigation
        shouldDisableNextButton={shouldDisableNextButton}
        fixed
        {...{
          customNextTitle,
          customBackTitle,
        }}
        {...(!noNextButton && { onNextButtonClick: onStepSubmit })}
        {...(!noBackButton && { onBackButtonClick: onStepBack })}
      />
    </>
  )

  const renderContent = () => {
    return noFormStep ? <div>{content}</div> : <form>{content}</form>
  }

  return (
    <FormProvider {...methods}>
      {renderContent()}
      <StepExitWarningModalContainer
        isOpened={isExitWarningModalOpen}
        onClose={closeExitWarningModal}
        onConfirm={goBack}
      />
    </FormProvider>
  )
}

StepContainer.propTypes = {
  children: node.isRequired,
  onBackButtonClick: func,
  onNextButtonClick: func,
  noNextButton: bool,
  noBackButton: bool,
  customNextTitle: string,
  customBackTitle: string,
  className: string,
  shouldDisableNextButton: bool,
  resolver: func,
  customSubmitAction: func,
  noFormStep: bool,
  isInnerStep: bool,
  isDistroStepSubmit: bool,
}

StepContainer.defaultProps = {
  onBackButtonClick: null,
  onNextButtonClick: null,
  noNextButton: false,
  noBackButton: false,
  customNextTitle: '',
  customBackTitle: '',
  className: '',
  shouldDisableNextButton: false,
  resolver: null,
  customSubmitAction: null,
  noFormStep: false,
  isInnerStep: false,
  isDistroStepSubmit: false,
}
