/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, string, bool, number } from 'prop-types'
import * as d3 from 'd3'
import useTheme from '@material-ui/core/styles/useTheme'
import { useWindowSize } from '#hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chart: {
    zIndex: '2',
  },
  horizontalLines: {
    '& path': {
      stroke: theme.palette.color.primary,
    },
  },
  valueLine: {
    '& path': {
      stroke: '#494949',
      fill: theme.palette.background.default,
      '&:nth-child(even)': {
        fill: '#232323',
      },
    },
    '& line': {
      stroke: '#494949',
    },
  },
  dotLabel: {
    textShadow: `0px 0px 5px ${theme.palette.color.black}`,
  },
}))

// TODO props are mislabeled and not respected, also these defaults are overridden by propTypes
export const RadarChart = ({
  data,
  showBarLabels,
  showDotLabels,
  ticks = [],
  margin: inputMargin = 120,
  labelMargin = 30,
  domain = [0, 10],
  dotRadius = 7,
}) => {
  const margin = inputMargin - labelMargin
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()
  const theme = useTheme()
  const [chartSize, setChartSize] = useState(null)
  const preparedData = data.map(obj => ({
    key: Object.keys(obj)[0],
    value: obj[Object.keys(obj)[0]],
  }))
  const calculateWithMargin = value => value - 2 * margin

  const svgContainerRefMeasured = useCallback(node => {
    if (node !== null) {
      svgContainerRef.current = node
      setChartSize([
        calculateWithMargin(node.parentElement.offsetWidth),
        calculateWithMargin(node.parentElement.offsetHeight),
      ])
    }
  }, [])

  useEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(svgContainerRef.current.parentElement.offsetWidth),
        calculateWithMargin(svgContainerRef.current.parentElement.offsetHeight),
      ]
    setChartSize(getContainerSize())
  }, [windowSize])

  const draw = () => {
    if (!chartSize) {
      return
    }
    const width = chartSize[0] + 2 * margin
    const height = chartSize[1] + 2 * margin
    // TODO proper margin calculation  (margins are currently 0)
    const radius = (chartSize[0] - labelMargin) / 3
    const dimensions = preparedData.map(x => x.key)
    const radialLine = d3.lineRadial()
    const radiusPerDimension = 360 / preparedData.length
    const gridLines = [...Array(preparedData.length + 1).keys()]
    const calcRadius = i =>
      ((Math.PI * 2) / preparedData.length) * (i + preparedData.length / 2)

    // reset
    d3.select(svgContainerRef.current).select('svg').remove()

    // Prepare Scale
    const scale = d3.scaleLinear().range([0, radius]).domain(domain)

    // SVG Chart init
    const chart = d3
      .select(`.${classes.chartWrapper}`)
      .append('svg')
      .attr('class', classes.chart)
      .attr('width', chartSize[0] + 2 * margin)
      .attr('height', chartSize[1] + 2 * margin)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`)

    // Gird lines for references
    chart
      .append('g')
      .attr('class', classes.valueLine)
      .selectAll('path')
      .data(ticks.sort((a, b) => b - a))
      .enter()
      .append('path')
      .attr('d', d =>
        radialLine(gridLines.map((v, i) => [calcRadius(i), scale(d)]))
      )
      .attr('transform', `translate(${width / 2}, ${height / 2}) rotate(180)`)
      .attr('fill', 'none')

    dimensions.forEach((dimension, i) => {
      const j = i + dimensions.length / 2
      const horizontal = 0.5 * (1 - Math.sin(calcRadius(j)))

      const anchor =
        // eslint-disable-next-line no-nested-ternary
        horizontal < 0.4 ? 'start' : horizontal > 0.6 ? 'end' : 'middle'

      // Transform to center
      const g = chart
        .append('g')
        .attr(
          'transform',
          `translate(${width / 2}, ${height / 2}) rotate(${
            j * radiusPerDimension
          })`
        )

      // Ticks
      g.append('g')
        .attr('class', classes.valueLine)
        .call(d3.axisLeft(scale).tickFormat('').tickValues(ticks).tickSize(0))

      // Bar Labels
      if (showBarLabels) {
        const point = d3.pointRadial(calcRadius(j), radius + 20)

        chart
          .append('text')
          .text(dimension)
          .attr('text-anchor', anchor)
          // .attr('dy', marginY)
          .attr('x', width / 2 + point[0])
          .attr('y', height / 2 + point[1])
          .attr('fill', 'white')
      }

      // Dot Labels
      if (showDotLabels) {
        const pointLabel = d3.pointRadial(
          calcRadius(j),
          scale(preparedData[i].value) // + 30
        )
        chart
          .append('text')
          .attr('class', `${classes.dotLabel} dotLabel`)
          .text(preparedData[i].value)
          .attr('text-anchor', 'middle')
          // .attr('dy', marginY)
          .attr('x', width / 2 + pointLabel[0])
          .attr('y', height / 2 + pointLabel[1] - 20)
          .attr('fill', 'white')
          .attr('opacity', '0')
          .attr('pointer-events', 'none')
      }
    })

    // Path
    chart
      .append('g')
      .selectAll('path')
      .data([[...preparedData, preparedData[0]]]) // Because of closing line
      .enter()
      .append('path')
      .attr('d', arr =>
        radialLine(
          arr.map((d, i) => {
            return [Math.PI + calcRadius(i), scale(d.value)]
          })
        )
      )
      .attr('transform', `translate(${width / 2}, ${height / 2})`)
      .attr('stroke', theme.palette.color.primary)
      .attr('stroke-width', 5)
      .attr('fill', 'transparent')

    // Dots
    const dot = chart
      .append('g')
      .selectAll('circle')
      .data(preparedData)
      .enter()
      .append('circle')
      .attr('cx', (d, i) => {
        const j = i + preparedData.length / 2
        // eslint-disable-next-line no-param-reassign
        d.point = d3.pointRadial(calcRadius(j), scale(preparedData[i].value))
        if (showDotLabels) {
          // eslint-disable-next-line no-param-reassign
          d.label = d3.select(d3.selectAll('.dotLabel').nodes()[i])
        }
        return width / 2 + d.point[0]
      })
      .attr('cy', d => height / 2 + d.point[1])
      .attr('r', dotRadius)
      .attr('stroke', theme.palette.color.white)
      .attr('stroke-width', 2)
      .attr('fill', theme.palette.color.primary)
    if (showDotLabels) {
      dot
        // eslint-disable-next-line func-names
        .on('mouseover', function (d) {
          d.label.raise()
          d.label.transition().duration('150').attr('opacity', '1')

          d3.select(this)
            .transition()
            .duration('150')
            .attr('r', dotRadius * 1.5)
        })
        // eslint-disable-next-line func-names
        .on('mouseout', function (d) {
          d.label.transition().duration('150').attr('opacity', '0')

          d3.select(this).transition().duration('150').attr('r', dotRadius)
        })
    }
  }

  // TODO less redraws
  useEffect(() => {
    draw()
  }, [
    chartSize,
    data,
    showBarLabels,
    ticks,
    margin,
    domain,
    labelMargin,
    dotRadius,
  ])

  return (
    <div
      ref={svgContainerRefMeasured}
      id="chartWrapper"
      className={classes.chartWrapper}
    />
  )
}

RadarChart.propTypes = {
  data: arrayOf(string).isRequired,
  domain: arrayOf(string).isRequired,
  showBarLabels: bool,
  showDotLabels: bool,
  ticks: arrayOf(string).isRequired,
  margin: number,
  labelMargin: number,
  dotRadius: number,
}

RadarChart.defaultProps = {
  showBarLabels: false,
  showDotLabels: false,
  margin: 0,
  labelMargin: 0,
  dotRadius: 7,
}
