import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  bg: {
    backgroundImage: `url("/assets/images/mastering-piano.png")`,
    height: 350,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    width: '100%',
  },
}))

export const GenresRightSide = () => {
  const classes = useStyles()
  return <div className={classes.bg} />
}
