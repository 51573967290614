import React from 'react'
import { node } from 'prop-types'
import { Payment } from './payment'
import { PaymentSuccess } from './payment-success'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export { Payment, PaymentSuccess, Routes }
