import React from 'react'
import { func, string } from 'prop-types'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useWizard } from '#modules/forms/form-wizard/context'
import { OneCardOption } from '#pages/promotion/wizard/steps/select-review-type/components/one-card-option'
import { OPTIONS } from '#pages/promotion/_utils/constants'
import { PricingTable } from '#pages/promotion/wizard/steps/select-review-type/components/pricing-table'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin: '48px 0',
  },
  tableWrapper: {
    marginTop: 24,
  },
}))

export const SelectReviewType = () => {
  const classes = useStyles()
  const { setStoredFormState, incrementStep } = useWizard()
  const onBackButtonClick = () => navigate(NAVIGATION_PATHS.PROMOTION)
  const promotionPlanTypeRedirect = planType =>
    navigate(`${NAVIGATION_PATHS.PROMOTION_NEW}?type=${planType}`, {
      replace: true,
    })

  const onPlanSelect = async planType => {
    setStoredFormState({ planType })
    incrementStep()
    await promotionPlanTypeRedirect(planType)
  }

  return (
    <StepContainer noNextButton {...{ onBackButtonClick }} noFormStep>
      <div className={classes.wrapper}>
        {OPTIONS.map(option => (
          <OneCardOption key={option.name} {...option} {...{ onPlanSelect }} />
        ))}
      </div>
      <div className={classes.tableWrapper}>
        <PricingTable {...{ onPlanSelect }} />
      </div>
    </StepContainer>
  )
}

SelectReviewType.propTypes = {
  onSubmit: func,
  navigateOnCancel: string,
}

SelectReviewType.defaultProps = {
  onSubmit: () => {},
  navigateOnCancel: null,
}
