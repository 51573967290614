import { countries } from 'countries-list'
import {
  FIELDS,
  TERRITORY,
  PERFORMANCE_TYPE,
  HAS_VOCALS_OPTIONS,
} from '#pages/distro/_utils/constants'
import { capitalizeWords } from '#utils/capitalizeWords'

export const prepareDataForMetadataForm = data => {
  const {
    explicitContent,
    titleLanguage,
    trackVersion,
    primaryGenre,
    secondaryGenre,
    performanceLanguage,
    lyrics,
  } = data

  return {
    ...data,
    [FIELDS.SONG_FILE_METADATA_EXPLICIT_CONTENT]: explicitContent
      ? {
          value: explicitContent,
          label: capitalizeWords(explicitContent?.replace(/-/g, ' ')),
        }
      : undefined,
    [FIELDS.SONG_FILE_METADATA_GENRE]: primaryGenre
      ? {
          value: primaryGenre?.id,
          label: capitalizeWords(primaryGenre?.name),
        }
      : undefined,
    [FIELDS.SONG_FILE_METADATA_GENRE]: primaryGenre
      ? {
          value: primaryGenre?.id,
          label: capitalizeWords(primaryGenre?.name),
        }
      : undefined,
    [FIELDS.SONG_FILE_METADATA_SECONDARY_GENRE]: secondaryGenre
      ? {
          value: secondaryGenre?.id,
          label: capitalizeWords(secondaryGenre?.name),
        }
      : undefined,
    ...(titleLanguage && {
      [FIELDS.SONG_FILE_METADATA_TITLE_LANGUAGE]: {
        value: titleLanguage,
        label: capitalizeWords(titleLanguage),
      },
    }),
    ...(trackVersion && {
      [FIELDS.SONG_FILE_METADATA_TRACK_VERSION]: {
        value: trackVersion,
        label: capitalizeWords(trackVersion?.replace(/-/g, ' ')),
      },
    }),
    [FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE]:
      // eslint-disable-next-line no-nested-ternary
      performanceLanguage === null
        ? undefined
        : performanceLanguage === PERFORMANCE_TYPE.INSTRUMENTAL
        ? undefined
        : {
            value: performanceLanguage,
            label: capitalizeWords(performanceLanguage?.replace(/-/g, ' ')),
          },
    // eslint-disable-next-line no-nested-ternary
    [FIELDS.HAS_VOCALS]: lyrics
      ? {
          ...HAS_VOCALS_OPTIONS[0],
        }
      : performanceLanguage === null ||
        performanceLanguage === PERFORMANCE_TYPE.INSTRUMENTAL
      ? {
          ...HAS_VOCALS_OPTIONS[1],
        }
      : {
          ...HAS_VOCALS_OPTIONS[0],
        },
  }
}

export const formatFormValues = formValues => {
  let values = {}
  // eslint-disable-next-line guard-for-in
  for (const property in formValues) {
    values = {
      ...values,
      // eslint-disable-next-line no-nested-ternary, no-negated-condition
      [property]: !formValues[property]
        ? property === FIELDS.SONG_FILE_METADATA_LYRICS
          ? ''
          : null
        : // eslint-disable-next-line no-prototype-builtins
        formValues[property]?.hasOwnProperty('value')
        ? formValues[property].value
        : formValues[property],
    }
  }

  return values
}

export const checkIfHasRole = (users, role) =>
  !!users?.find(user => user.role.includes(role))

export const checkIfIsReadyForDistribution = file => {
  return (
    !!(file.primaryGenre && file.explicitContent) &&
    checkIfHasRole(file.contributors, 'composer') &&
    (file.performanceLanguage === PERFORMANCE_TYPE.INSTRUMENTAL ||
      checkIfHasRole(file.contributors, 'lyricist'))
  )
}

export const findCountries = (continents, continent) => {
  return Object.entries(countries)
    .filter(([, value]) => {
      return continents.includes(value.continent)
    })
    .map(([key, value]) => ({ ...value, code: key, continent }))
}

export const territoryConfig = {
  Africa: findCountries(TERRITORY.AFRICA.code, TERRITORY.AFRICA.name),
  Americas: findCountries(TERRITORY.AMERICAS.code, TERRITORY.AMERICAS.name),
  Asia: findCountries(TERRITORY.ASIA.code, TERRITORY.ASIA.name),
  Europe: findCountries(TERRITORY.EUROPE.code, TERRITORY.EUROPE.name),
  Oceania: findCountries(TERRITORY.OCEANIA.code, TERRITORY.OCEANIA.name),
}

// eslint-disable-next-line no-shadow
export const getCountriesCodes = countries => {
  const checkedCountries = []
  for (const [key, value] of Object.entries(countries)) {
    if (value) checkedCountries.push(key)
  }

  return checkedCountries
}

export const createYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []
  let startYear = 1000

  while (startYear <= currentYear) {
    years.push(startYear++)
  }

  return years
    .map(year => ({
      value: String(year),
      label: String(year),
    }))
    .reverse()
}
