import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { string } from 'prop-types'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  item: {
    marginBottom: '30px',
  },
  icon: {
    marginBottom: 24,
  },
}))

export const HowToSubheaderItem = ({ iconUrl, header, subheader }) => {
  const classes = useStyles()

  return (
    <div className={classes.item}>
      <img className={classes.icon} src={iconUrl} alt="" />
      <Text type={TEXT_TYPE.H3} className={classes.header}>
        {header}
      </Text>
      <span className={classes.subheader}>{subheader}</span>
    </div>
  )
}

HowToSubheaderItem.propTypes = {
  iconUrl: string.isRequired,
  header: string.isRequired,
  subheader: string.isRequired,
}
