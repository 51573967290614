import React from 'react'
import { arrayOf, func, node, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { AutoComplete } from '#components/inputs/auto-complete'
import { makeStyles } from '@material-ui/core/styles'
import { loadProfiles } from '#utils/loadProfiles'
import { CreateProfileRedirect } from '#components/create-profile-redirect'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 160,
    marginLeft: 210,
    width: 360,
  },
  header: {
    width: '100%',
    marginBottom: '2rem',
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '2rem',
  },
  selectContent: {
    width: '100%',
  },
  description: {
    width: '21.5rem',
    marginTop: '3.75rem',
  },
})

export const SelectProfileComponent = ({
  subtitle,
  createNewProfileText,
  name,
  roles,
  children,
  handleChange,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { useAltStructuresEndpoint } = useFlags()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="h2" gutterBottom>
          {t('selectProfile').toUpperCase()}
        </Typography>
        <Typography>{t(subtitle)}</Typography>
      </div>
      <div className={classes.selectWrapper}>
        <div className={classes.selectContent}>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => (
              <AutoComplete
                id="search_profile"
                errorMessage={errors[name]?.message}
                placeholder={t('search')}
                loadOptions={loadProfiles(roles, useAltStructuresEndpoint)}
                label={t('chooseProfile')}
                hasNftStyles
                isRequired
                onChange={option => {
                  handleChange(option)
                  onChange(option)
                }}
              />
            )}
          />
        </div>
      </div>
      {children}
      <div className={classes.description}>
        {!!createNewProfileText && (
          <CreateProfileRedirect
            text={t(createNewProfileText)}
            linkText={t('clickHere')}
            linkTextMore={t('toCreateNewProfile')}
          />
        )}
      </div>
    </div>
  )
}

SelectProfileComponent.propTypes = {
  subtitle: string.isRequired,
  createNewProfileText: string,
  name: string,
  roles: arrayOf(string),
  children: node,
  handleChange: func.isRequired,
}

SelectProfileComponent.defaultProps = {
  createNewProfileText: '',
  name: 'parent',
  roles: [],
  children: undefined,
}
