import React from 'react'
import styled from 'styled-components'
import { func, string, node } from 'prop-types'
import { Button, ButtonTheme, ButtonSize } from '@tunego/tunego-ui-components'

const SButtonRow = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.secondary_70};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding-right: 10px;
  gap: 1rem;
`

const SButton = styled(Button)`
  margin-right: 10px;
  text-transform: uppercase;
`

export const ButtonRow = ({
  onClick,
  text,
  secondaryOnClick,
  secondaryText,
  className,
  children,
}) => {
  return (
    <SButtonRow className={className}>
      {children}
      {text && (
        <SButton
          buttonTheme={ButtonTheme.Secondary}
          buttonSize={ButtonSize.Sm}
          onClick={onClick}
        >
          {text}
        </SButton>
      )}
      {secondaryText && (
        <SButton
          buttonTheme={ButtonTheme.Secondary}
          buttonSize={ButtonSize.Sm}
          onClick={secondaryOnClick}
        >
          {secondaryText}
        </SButton>
      )}
    </SButtonRow>
  )
}

ButtonRow.propTypes = {
  onClick: func,
  text: string,
  secondaryOnClick: func,
  secondaryText: string,
  className: string,
  children: node,
}

ButtonRow.defaultProps = {
  onClick: () => {},
  text: '',
  secondaryOnClick: () => {},
  secondaryText: '',
  className: '',
  children: undefined,
}
