import React from 'react'
import styled from 'styled-components'
import { func, string } from 'prop-types'
import { Label } from '#components/label'
import { BaseInput } from '#components/inputs/base-input'
import { ErrorMessage } from '#components/error-message'

const SBaseInput = styled(BaseInput)`
  margin-bottom: 0;
  width: 100%;

  & input {
    margin-bottom: 0;
  }

  & > div {
    margin-bottom: 0;
    border-top: none;
    border-bottom: none;
    border-right: none;
    height: 38px;
  }
`

const SWrapper = styled.div`
  display: flex;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
      error,
    }) => (error ? COLORS.warning_60 : COLORS.neutral_70)};
  margin-bottom: 0.5rem;
`

const SDollar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  width: 2.5rem;
  flex-shrink: 0;
`

export const PriceInput = ({
  errorMessage,
  register,
  name,
  label,
  className,
  ...inputProps
}) => {
  return (
    <div className={className}>
      <Label name={name} label={label} required />
      <SWrapper error={!!errorMessage}>
        <SDollar>$</SDollar>
        <SBaseInput
          name={name}
          inputRef={register}
          type="number"
          {...inputProps}
        />
      </SWrapper>
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </div>
  )
}

PriceInput.propTypes = {
  register: func.isRequired,
  errorMessage: string,
  label: string,
  name: string.isRequired,
  className: string,
}
