import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { node, string, object } from 'prop-types'

const useStyles = makeStyles(theme => ({
  header: {
    textTransform: 'uppercase',
    fontSize: 50,
    lineHeight: '50px',
    padding: '0 0.5rem',
    fontFamily: 'AvenirNextLTPro-Bold',
  },
  outlineWrapper: {
    position: 'relative',
    border: `0.25rem solid ${theme.palette.color.primary}`,
    color: theme.palette.color.default,
    padding: '1.5rem',
    paddingTop: 0,
    width: '31.25rem',
  },
}))

export const OutlinedElement = ({ children, header }) => {
  const classes = useStyles()

  return (
    <fieldset className={classes.outlineWrapper}>
      <legend className={classes.header}>{header}</legend>
      {children}
    </fieldset>
  )
}

OutlinedElement.propTypes = {
  children: node.isRequired,
  header: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  headerProps: object,
}

OutlinedElement.defaultProps = {
  headerProps: {},
}
