import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { number, shape, string, arrayOf, func, bool, node } from 'prop-types'
import { OrderSummary } from '#modules/order-summary'
import { CheckoutContainer } from '#modules/checkout/containers/checkout-container'
import { hexToRgba } from '#utils/hexToRgba'
import { usePayment } from '#hooks/swr/usePayments'

const useStyles = makeStyles(theme => ({
  cardDetailsVertical: {
    minHeight: 'calc(100vh - 161px)',
    borderLeft: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    padding: '60px 50px',
    width: '100%',
    maxWidth: 400,
  },
  containerVertical: {
    display: 'flex',
    minWidth: 960,
  },
  orderSummaryVertical: {
    borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    padding: '60px 75px',
    width: '100%',
  },
  orderSummaryHorizontal: {
    width: '100%',
    marginTop: '30px',
  },
}))

export const PaymentCheckout = ({
  handlePayment,
  type,
  orderSummaryProps,
  onPaymentSuccess,
  paymentId,
  freePaymentStatus,
  horizontal,
  children,
}) => {
  const classes = useStyles()
  const [checkoutSuccess, setCheckoutSuccess] = useState(false)
  const { payment } = usePayment(paymentId)
  const showHorizontally = horizontal && checkoutSuccess
  const classType = showHorizontally ? 'Horizontal' : 'Vertical'

  return (
    <div className={classes[`container${classType}`]}>
      <div className={classes[`cardDetails${classType}`]}>
        <CheckoutContainer
          {...{
            handlePayment,
            type,
            onPaymentSuccess,
            payment,
            freePaymentStatus,
            checkoutSuccess,
            setCheckoutSuccess,
            showHorizontally,
          }}
        />
      </div>
      <div className={classes[`orderSummary${classType}`]}>
        <OrderSummary
          {...{
            ...orderSummaryProps,
            payment,
            freePaymentStatus,
            showCard: !freePaymentStatus,
            showHorizontally,
          }}
        />
      </div>
      {children}
    </div>
  )
}

PaymentCheckout.defaultProps = {
  horizontal: false,
  onPaymentSuccess: null,
  freePaymentStatus: false,
}

PaymentCheckout.propTypes = {
  horizontal: bool,
  paymentId: string.isRequired,
  handlePayment: func.isRequired,
  onPaymentSuccess: func,
  type: string.isRequired,
  orderSummaryProps: shape({
    orderData: arrayOf(shape({ rowTitle: string, rowValue: string }))
      .isRequired,
    price: number.isRequired,
    isConfirmation: bool,
    orderNumber: string,
    showPriceRowOnly: bool,
    customPriceRowTitle: string,
    withCard: bool,
    withOrderNumber: bool,
  }).isRequired,
  freePaymentStatus: bool,
  children: node.isRequired,
}
