import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core'
import { arrayOf, func, shape, string } from 'prop-types'
import { SongFile } from '#pages/distro/wizard/steps/add-song-files/components/song-file'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'

const useStyles = makeStyles(theme => {
  return {
    container: areSongsUploaded => ({
      position: 'relative',
      borderTop: areSongsUploaded
        ? `1px solid ${theme.palette.color.darkGrey}`
        : 'none',
      width: '100%',
    }),
  }
})

export const SongFilesList = ({
  songFiles,
  setSongFiles,
  onDeleteSong,
  setError,
}) => {
  const classes = useStyles()
  const { storedFormState } = useWizard()

  const { actions } = useWithAsyncAction({
    orderSong: DistroApi.orderSong,
  })

  const handleReorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = async result => {
    const { destination, source, draggableId } = result
    const isDroppableIdEqual = destination.droppableId === source.droppableId
    const isIndexEqual = destination.index === source.index
    const isOrderChanged = isDroppableIdEqual && isIndexEqual

    if (isOrderChanged || !destination) {
      return
    }

    try {
      const reorderedSongs = handleReorder(
        songFiles,
        source.index,
        destination.index
      )
      setSongFiles(reorderedSongs, false)

      await actions.orderSong(storedFormState[FIELDS.DISTRO_ID], draggableId, {
        newPosition: destination.index,
      })
    } catch (err) {
      setError(err)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div
            ref={provided.innerRef}
            className={classes.container}
            {...provided.droppableProps}
          >
            {songFiles.map((song, index) => (
              <SongFile
                key={song.id}
                file={song}
                index={index}
                onDeleteSong={onDeleteSong}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SongFilesList.propTypes = {
  songFiles: arrayOf(
    shape({
      name: string,
    })
  ).isRequired,
  setSongFiles: func.isRequired,
  onDeleteSong: func.isRequired,
  setError: func.isRequired,
}
