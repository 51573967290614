import React from 'react'
import { navigate } from '@reach/router'
import { oneOf } from 'prop-types'
import { Navigation } from '#pages/profile/components/navigation'
import { NAVIGATION_PATHS } from '#routes/routes'
import { POSSIBLE_PROFILE_PAGES } from '#pages/profile/utils/constants'

export const NavigationContainer = ({ currentPage }) => {
  const handleSelect = option =>
    navigate(NAVIGATION_PATHS.PROFILE, { state: { tab: option } })

  return <Navigation currentPage={currentPage} handleSelect={handleSelect} />
}

NavigationContainer.propTypes = {
  currentPage: oneOf(POSSIBLE_PROFILE_PAGES).isRequired,
}
