import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

const SHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Header = ({ children }) => {
  return <SHeaderWrapper>{children}</SHeaderWrapper>
}

Header.propTypes = {
  children: node,
}
