import React from 'react'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { DistributeSubHeader } from '../../components/distribute-sub-header'
import { Section } from '#components/section'

export const Distribute = () => {
  const { t } = useTranslation('videogo')

  return (
    <Section
      header={t('distributeYourMusicVideos')}
      getStartedUrl={`${NAVIGATION_PATHS.VIDEO_GO}/coming-soon`}
      iconUrl="/assets/svg/ic-video-large.svg"
      subheaderComponent={<DistributeSubHeader />}
    />
  )
}
