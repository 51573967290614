import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'
import { getFileInfo } from '#utils/getFileInfo'
import { S3Bucket } from '#api/requests/s3bucket'

export class DistroApi {
  static createSubscription = data =>
    getApiClient().post(ENDPOINTS.DISTRO_CREATE_PAYMENT, data)

  static postTemporaryDistro = data =>
    getApiClient().post(ENDPOINTS.TEMPORARY_DISTRO, {
      ...data,
    })

  static setUp = data =>
    getApiClient().post(ENDPOINTS.DISTRO_SETUP, {
      ...data,
    })

  static addSong = (distroId, body) =>
    getApiClient().post(buildUrl(ENDPOINTS.DISTRO_ADD_SONG, { distroId }), body)

  static deleteSong = (distroId, songId) =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.DISTRO_DELETE_SONG, { distroId, songId })
    )

  static searchArtistIds = (name, platform) =>
    getApiClient().get(buildUrl(ENDPOINTS.DISTRO_ARTIST, { name, platform }))

  static setArtistIds = (distroId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.SET_DISTRO_ARTIST, { distroId }),
      data
    )

  static orderSong = (distroId, songId, data) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.DISTRO_ORDER_SONG, { distroId, songId }),
      data
    )
  }

  static addReleaseMetadata = (distroId, body) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.DISTRO_SINGLE_DISTRO, { distroId }),
      body
    )
  }

  static updateDeliveryDetails = (distroId, data) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.DISTRO_DELIVERY_DETAILS, { distroId }),
      data
    )
  }

  static submitForApproval = distroId => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.DISTRO_SUBMIT_FOR_APPROVAL, { distroId })
    )
  }

  static postImageUploadLink = (distroId, body) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.DISTRO_IMAGE_LINK, { distroId }),
      body
    )

  static uploadImageHealthCheck = (distroId, body) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.DISTRO_IMAGE_HEALTH_CHECK, { distroId }),
      body
    )

  static addImageFile = async (distroId, file) => {
    const { fileSuffix, fileSize } = getFileInfo(file)

    const {
      data: { url, fields, uploadId },
    } = await this.postImageUploadLink(distroId, { fileSuffix, fileSize })

    await S3Bucket.uploadImg({
      url,
      file,
      fields,
    })

    await this.uploadImageHealthCheck(distroId, { uploadId, fileSuffix })
  }
}
