import React from 'react'
import styled from 'styled-components'
import { ARTICLES } from '#pages/learn-web3/utils/constants'
import { useTranslation } from 'react-i18next'

const SList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  max-width: 1120px;
  margin: 4.5rem auto;
  padding: 0;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
    max-width: 540px;
  }
`

const SListItem = styled.li`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  width: 33.5rem;
  height: 18.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SLink = styled.a`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.default};
  text-decoration: none;
  font-size: ${({ theme: { fontSize } }) => fontSize['4xl']};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  white-space: pre;
  text-align: center;
`

export const ArticlesList = () => {
  const { t } = useTranslation('learnWeb3')

  return (
    <SList>
      {ARTICLES.map(({ title, img, href }, i) => (
        <SListItem key={i} backgroundImage={img}>
          <SLink href={href} target="_blank">
            {t(title)}
          </SLink>
        </SListItem>
      ))}
    </SList>
  )
}
