export const formatPrice = (cents, config = {}) => {
  const DEFAULT_CONFIG = {
    freeText: 'FREE',
    trailingZeros: false,
  }

  const { freeText, trailingZeros } = { ...DEFAULT_CONFIG, ...config }

  if (cents === 0 && freeText) return freeText

  if ([null, undefined].includes(cents) || isNaN(cents) || !isFinite(cents))
    return '- -'

  const price = cents / 100

  const fractionDigits = price % 1 === 0 && !trailingZeros ? 0 : 2

  return `$${price.toFixed(fractionDigits)}`
}
