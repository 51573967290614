import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { AddSingle } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/add_single'
import { AddPack } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/add_pack'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'

const STreeHeader = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  text-transform: uppercase;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SButtonsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const TreeHeader = ({ parentId, name, title }) => {
  const { readOnly } = useNftCreator()

  return (
    <STreeHeader>
      {title}
      {!readOnly && (
        <SButtonsWrapper>
          <AddSingle parentId={parentId} />
          <AddPack parentId={parentId} name={name} />
        </SButtonsWrapper>
      )}
    </STreeHeader>
  )
}

TreeHeader.propTypes = {
  title: string,
  parentId: string.isRequired,
  name: string,
}
