import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { bool, string, oneOfType, node, func } from 'prop-types'
import { Label } from '#components/label'
import { ErrorMessage } from '#components/error-message'

const SDateInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`

const SDateInput = styled.input`
  border: 1px solid
    ${({
      errorMessage,
      theme: {
        tunego: { COLORS },
      },
    }) => (errorMessage ? COLORS.warning_60 : COLORS.neutral_70)};
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
  height: 40px;
  font-size: 14px;
  padding-left: 15px;
  outline: none;
  text-transform: none;
  width: 100%;
`

export const DateInput = forwardRef(
  ({
    name,
    label,
    errorMessage,
    isBolded,
    isUpperCased,
    required,
    ...restInputProps
  }) => {
    return (
      <SDateInputContainer>
        {label && (
          <Label
            name={name}
            label={label}
            isBolded={isBolded}
            isUpperCased={isUpperCased}
            required={required}
          />
        )}
        <SDateInput
          name={name}
          id={name}
          errorMessage={errorMessage}
          placeholder="Select date"
          required={required}
          {...restInputProps}
        />
        <ErrorMessage errorMessage={errorMessage} />
      </SDateInputContainer>
    )
  }
)

DateInput.propTypes = {
  name: string.isRequired,
  label: oneOfType([string, node]),
  errorMessage: string,
  isBolded: bool,
  isUpperCased: bool,
  required: bool,
  register: func,
}

DateInput.defaultProps = {
  label: '',
  errorMessage: '',
  isBolded: false,
  isUpperCased: false,
}
