import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { FILE_QUALIFICATION_TYPES } from '#pages/vault/song/tabs/files/utils/constants'
import { string } from 'prop-types'
import { Text, TEXT_TYPE } from '#components/text'
import { Tooltip } from '#components/tooltip'
import styled from 'styled-components'

const STag = styled.div`
  color: ${({ theme: { colors } }) => colors.default};
  padding: 0.1875rem 0.625rem;
  margin: 0 0.3125rem;
  background-color: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  border-radius: 0.1875rem;
  white-space: nowrap;
`

const STagLabel = styled(Text)`
  font-size: 0.5rem;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
`

const SLink = styled(Link)`
  text-decoration: none;
`

export const FileListItemTag = ({ tag }) => {
  const { t } = useTranslation('vault')

  const getLink = () => {
    switch (tag) {
      case FILE_QUALIFICATION_TYPES.DISTRO:
        return NAVIGATION_PATHS.DISTRO
      case FILE_QUALIFICATION_TYPES.PROMOTION:
        return NAVIGATION_PATHS.PROMOTION
      case FILE_QUALIFICATION_TYPES.NFT:
        return NAVIGATION_PATHS.SELL_COLLECTIBLES
      default:
        return NAVIGATION_PATHS.DISTRO
    }
  }

  return (
    <Tooltip text={t('meetsTheRequirements', { view: tag })}>
      <SLink to={getLink()}>
        <STag>
          <STagLabel type={TEXT_TYPE.SUBTITLE2}>
            {t('readyFor', { tag })}
          </STagLabel>
        </STag>
      </SLink>
    </Tooltip>
  )
}

FileListItemTag.propTypes = {
  tag: string,
}
