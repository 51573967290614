import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { string, oneOfType } from 'prop-types'
import { VARIANTS } from '#pages/profile/utils/constants'

const getVariantTheme = (variant, theme) => {
  switch (variant) {
    case VARIANTS.CURRENT:
      return {
        backgroundColor: theme.palette.color.primary,
      }
    case VARIANTS.OTHER:
      return {
        backgroundColor: theme.palette.color.darkGrey39,
      }
    default:
      return {
        backgroundColor: theme.palette.color.primary,
      }
  }
}

const useStyles = ({ variant }) => {
  return makeStyles(theme => {
    const barTheme = getVariantTheme(variant, theme)
    return {
      bar: {
        height: 40,
        width: '100%',
        fontSize: '16px',
        fontWeight: 700,
        color: theme.palette.color.default,
        lineHeight: '36px',
        padding: '0 30px',
        ...barTheme,
      },
    }
  })()
}

export const SubscriptionBar = ({ text, variant }) => {
  const classes = useStyles({
    variant,
  })

  return <div className={classes.bar}>{text}</div>
}

SubscriptionBar.propTypes = {
  text: string.isRequired,
  variant: oneOfType(['current', 'other']).isRequired,
}
