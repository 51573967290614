import React, { createContext, useContext, useEffect, useState } from 'react'
import { node } from 'prop-types'
import { useSingleNft } from '#hooks/swr/useNfts'
import { useError } from '#hooks/useError'
import {
  useFilesStep,
  useDetailsStep,
  useSplitsStep,
} from '#pages/sell-collectibles/create-collectible/_hooks'
import {
  NFT_STATUS,
  NFT_ACCESS_ROLE,
} from '#pages/sell-collectibles/_utils/constants'

export const NftCreatorContext = createContext({
  activeStep: 0,
  setActiveStep: () => {},
  completedStep: -1,
  setCompletedStep: () => {},
  disableNavigation: false,
  setDisableNavigation: () => {},
  nftId: null,
  setNftId: () => {},
  goToNextStep: () => {},
  resetWizard: () => {},
  setInitialData: () => {},
  loading: false,
  error: null,
  minReleaseDate: null,
  readOnly: false,
})

const { Provider } = NftCreatorContext

export const NftCreatorProvider = ({ children }) => {
  const [nftId, setNftId] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [completedStep, setCompletedStep] = useState(-1)
  const [disableNavigation, setDisableNavigation] = useState(false)
  const [initialDataLoaded, setInitialDataLoaded] = useState(true)
  const [readOnly, setReadOnly] = useState(false)
  const [loading, setLoading] = useState(true)
  const { error, setError } = useError()
  const [validatedStep, setValidatedStep] = useState(-1)
  const [processCompleted, setProcessCompleted] = useState(false)

  const { data: savedNft, mutate } = useSingleNft(nftId)

  const {
    methods: filesStepMethods,
    resetFiles,
    setPersona,
    setInitialFilesAndGetTrack,
  } = useFilesStep()

  const {
    minReleaseDate,
    methods: detailsStepMethods,
    resetDetails,
    isDetailsStepCompleted,
    setInitialDetails,
  } = useDetailsStep(setError)

  const { methods: splitsStepMethods } = useSplitsStep()

  const resetWizard = () => {
    resetFiles()
    resetDetails()

    setNftId(null)
    setActiveStep(0)
    setCompletedStep(-1)
    setDisableNavigation(false)
    setValidatedStep(-1)
    setProcessCompleted(false)
  }

  const clearWizard = () => {
    resetDetails()

    // TODO: reset splits etc
  }

  const isProcessCompleted = status =>
    [
      NFT_STATUS.UNDER_REVIEW,
      NFT_STATUS.REVIEW_DENIED,
      NFT_STATUS.QUEUED_FOR_MINTING,
      NFT_STATUS.QUEUED_FOR_DROP,
      NFT_STATUS.MINTING_IN_PROGRESS,
      NFT_STATUS.MINTED,
      NFT_STATUS.MINTING_ERROR,
      NFT_STATUS.LISTED_FOR_SALE,
      NFT_STATUS.SOLD_OUT,
    ].includes(status)

  const goToNextStep = () => {
    setCompletedStep(Math.max(activeStep, completedStep))

    setActiveStep(activeStep + 1)
  }

  const setInitialSteps = async () => {
    setLoading(true)
    setInitialDataLoaded(true)

    try {
      const { accessRoles, status } = savedNft

      setReadOnly(!accessRoles?.includes(NFT_ACCESS_ROLE.PROFILE_OWNER))

      let completed = -1

      const setStepAsCompleted = index => {
        if (index - completed === 1) completed = index
      }

      const track = setInitialFilesAndGetTrack(savedNft)

      if (!track) return

      setStepAsCompleted(0)

      await setInitialDetails(savedNft, track)

      if (isDetailsStepCompleted()) setStepAsCompleted(1)

      setCompletedStep(completed)
      setActiveStep(completed + 1)
      setValidatedStep(completed)

      if (isProcessCompleted(status)) {
        setCompletedStep(2)
        setActiveStep(2)
        setProcessCompleted(true)
      }
    } catch {
      setError('errorLoadingData')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!initialDataLoaded && savedNft) {
      setInitialSteps()
    }
  }, [savedNft])

  const setInitialData = nftId => {
    setInitialDataLoaded(!nftId)
    setNftId(nftId)

    if (!nftId) setLoading(false)
  }

  const restoreProcess = async () => {
    setInitialDataLoaded(false)
    setProcessCompleted(false)

    await mutate()
  }

  const value = {
    activeStep,
    setActiveStep,
    completedStep,
    setCompletedStep,
    disableNavigation,
    setDisableNavigation,
    nftId,
    setNftId,
    goToNextStep,
    savedNft,
    resetWizard,
    setInitialData,
    loading,
    error,
    minReleaseDate,
    readOnly,
    validatedStep,
    setValidatedStep,
    setPersona,
    filesStepMethods,
    detailsStepMethods,
    clearWizard,
    splitsStepMethods,
    processCompleted,
    setProcessCompleted,
    restoreProcess,
  }

  return <Provider {...{ value }}>{children}</Provider>
}

NftCreatorProvider.propTypes = {
  children: node.isRequired,
}

export const useNftCreator = () => {
  const context = useContext(NftCreatorContext)
  if (!context) {
    throw new Error('useNftCreator must be used within NftCreatorProvider')
  }
  return context
}
