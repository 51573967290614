import React, { useState, Fragment } from 'react'
import { arrayOf, shape, string, number, func, bool } from 'prop-types'
import { convertBytesAndDetermineUnit } from '#utils/convertBytes'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { NameColumn } from '#pages/vault/song/tabs/files/components/name-column'
import { Row } from '#pages/vault/song/tabs/files/components/row'
import { EditOrRemoveContainer } from '#pages/vault/song/tabs/files/containers/edit-or-remove-container'
import {
  getMenuOptions,
  TABLE_HEADER,
  ACTION_TYPES,
  FILE_PREVIEW_STATUS,
  FILE_QUALIFICATION_TYPES,
} from '#pages/vault/song/tabs/files/utils/constants'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { formatDate } from '#utils/formatDate'
import { Checkbox } from '#components/checkbox'
import { LoadingIndicator } from '#components/loaders/loading-indicator'
import { isEven } from '#utils/isEven'
import styled from 'styled-components'
import { useReload } from '#modules/page-wrapper/context'

const SFileSuffix = styled.div`
  display: flex;
  align-items: center;
  width: ${TABLE_HEADER[1].width};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SUpdatedAt = styled.div`
  display: flex;
  align-items: center;
  width: ${TABLE_HEADER[2].width};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SFileSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${TABLE_HEADER[3].width};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SLastColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7%;
`

const SLoader = styled.div`
  margin-left: 1.5625rem;
`
export const FilesListItem = ({
  file,
  projectId,
  masterId,
  actions,
  accessRoles,
  setShouldRefreshData,
  handlePrivateShare,
  handleListen,
  handlePreview,
  index,
  checkedFiles,
  setCheckedFiles,
}) => {
  const {
    id,
    name,
    fileSuffix,
    previewStatus,
    updatedAt,
    size,
    audioCodecs,
    bitDepth,
    category,
    isUsedInQuicklisting,
    hasMetadata,
  } = file || {}
  const [activeIdInlineAction, setActiveIdInlineAction] = useState(null)
  const mediaInfo = { audioCodecs, bitDepth }
  const isMaster = id === masterId
  const isProcessing =
    previewStatus === FILE_PREVIEW_STATUS.PROCESSING ||
    previewStatus === FILE_PREVIEW_STATUS.PENDING
  const hasError = previewStatus === FILE_PREVIEW_STATUS.ERROR

  const fileInfo = convertBytesAndDetermineUnit(size)
  const showEllipsisMenu = !isProcessing && hasMetadata

  const { setReloading } = useReload()

  const toggleFileCheck = file => {
    setCheckedFiles(prevState => {
      let arrayOfFiles = []
      if (prevState.some(savedFile => savedFile.id === file.id)) {
        arrayOfFiles = [...prevState].filter(item => item.id !== file.id)
      } else {
        arrayOfFiles = [...prevState, file]
      }
      return arrayOfFiles
    })
  }

  const handleDeleteOrRemove = fileId => {
    setActiveIdInlineAction(fileId)
  }

  const handleSetMasterFile = async fileId => {
    await actions.setMasterFile(projectId, fileId)
    setReloading()
  }

  const handleDownload = async (fileId, save) => {
    const {
      data: { link },
    } = await actions.getFileDownloadLink(fileId, save)
    // TODO this can be blocked by aggressive adblockers
    const a = document.createElement('a')
    a.href = link
    if (!save) a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const determineMenuAction = (actionType, fileId) => {
    switch (actionType) {
      case ACTION_TYPES.editOrRemove: {
        return handleDeleteOrRemove(fileId)
      }
      case ACTION_TYPES.setMaster: {
        return handleSetMasterFile(fileId)
      }
      case ACTION_TYPES.privateSharing: {
        return handlePrivateShare(fileId)
      }
      default: {
        return null
      }
    }
  }

  const checkIsOwner = roles => {
    return roles?.includes(ACCESS_ROLES.OWNER)
  }
  // eslint-disable-next-line no-shadow
  const onMenuOpen = file => {
    if (
      file?.previewStatus === FILE_PREVIEW_STATUS.PROCESSING ||
      file?.previewStatus === FILE_PREVIEW_STATUS.PENDING
    ) {
      setReloading()
      setShouldRefreshData(true)
    } else {
      setShouldRefreshData(false)
    }
  }

  const getFileQualificationTags = () => {
    const { isNftAllowed, isNftImageAllowed } = file
    let tags = []

    // TODO uncomment when Distro/promotion will be available/not hidden
    // if (isDistributionAllowed) tags = [...tags, FILE_QUALIFICATION_TYPES.DISTRO]
    // if (isPromotionAllowed) tags = [...tags, FILE_QUALIFICATION_TYPES.PROMOTION]
    if (isNftAllowed || isNftImageAllowed)
      tags = [...tags, FILE_QUALIFICATION_TYPES.NFT]

    return tags
  }

  const closeInlineActionHandler = () => {
    setActiveIdInlineAction(null)
    setReloading()
  }

  return (
    <Fragment key={id}>
      {activeIdInlineAction === id ? (
        <EditOrRemoveContainer
          {...{
            shouldEnableDeletion: checkIsOwner(accessRoles),
          }}
          id={id}
          name={name}
          disableDelete={isUsedInQuicklisting}
          handleCloseInlineAction={closeInlineActionHandler}
        />
      ) : (
        <Row isMaster={isMaster} isEven={isEven(index)}>
          <Checkbox
            name={name}
            checked={checkedFiles.find(file => file.id === id) || false}
            onChange={() => toggleFileCheck(file)}
          />
          <NameColumn
            name={name}
            fileSuffix={fileSuffix}
            isProcessing={isProcessing}
            hasError={hasError}
            width={TABLE_HEADER[0].width}
            isMaster={isMaster}
            mediaInfo={mediaInfo}
            category={category}
            tags={getFileQualificationTags()}
            onPlay={() => handleListen(id)}
            onPreview={() => handlePreview(id)}
            onDownload={() => handleDownload(id, false)}
          />
          <SFileSuffix>{`.${fileSuffix}`}</SFileSuffix>
          <SUpdatedAt>{formatDate(updatedAt)}</SUpdatedAt>
          <SFileSize>{`${fileInfo.size} ${fileInfo.unit}`}</SFileSize>
          <SLastColumn>
            {showEllipsisMenu ? (
              <EllipsisMenu
                options={getMenuOptions({
                  file,
                  isOwner: checkIsOwner(accessRoles),
                  isMaster,
                })}
                onMenuOpen={() => onMenuOpen(file)}
                onChoose={actionType => {
                  determineMenuAction(actionType, id)
                }}
              />
            ) : (
              <SLoader>
                <LoadingIndicator />
              </SLoader>
            )}
          </SLastColumn>
        </Row>
      )}
    </Fragment>
  )
}

FilesListItem.propTypes = {
  file: shape({}).isRequired,
  projectId: string.isRequired,
  masterId: string.isRequired,
  accessRoles: arrayOf(string).isRequired,
  actions: shape({}).isRequired,
  setShouldRefreshData: func.isRequired,
  handlePrivateShare: func.isRequired,
  handleListen: func.isRequired,
  handlePreview: func.isRequired,
  index: number.isRequired,
  checkedFiles: arrayOf(shape({ id: string, isUsedInQuicklisting: bool }))
    .isRequired,
  setCheckedFiles: func.isRequired,
}
