import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomSelect } from '#components/inputs/custom-select'
import { BaseInput } from '#components/inputs/base-input'
import { Button } from '#components/button'
import { PRO_OPTIONS as SELECT_OPTIONS } from '#constants/proOptions'
import { proPublishingValidationSchema } from '#pages/profile/utils/validation'
import { PRO_PUBLISHING_FIELDS } from '#pages/profile/utils/constants'
import { useUser } from '#hooks/useUser'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  form: {
    marginTop: '3.75rem',
    margin: '0 6.25rem',
  },
  input: {
    marginTop: '1rem',
  },
  submitButton: {
    marginTop: '2.5rem',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const ProPublishingContainer = ({ handleSave }) => {
  const { user } = useUser()
  const classes = useStyles()
  const { proAttribute, ipiCaeNumber, publisher } = user || {}

  const currentlySelected = SELECT_OPTIONS.filter(
    option => option.value === proAttribute
  )

  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(proPublishingValidationSchema(profileTranslation.t)),
  })

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleSave)}>
      <Controller
        name={PRO_PUBLISHING_FIELDS.PRO}
        control={control}
        render={({ field: { onChange } }) => (
          <CustomSelect
            id="publishing_pro"
            defaultValue={currentlySelected}
            options={SELECT_OPTIONS}
            label={profileTranslation.t('pro')}
            placeholder={profileTranslation.t('pro')}
            onChange={({ value }) => {
              onChange(value)
            }}
          />
        )}
      />
      <div className={classes.input}>
        <BaseInput
          defaultValue={ipiCaeNumber}
          name={PRO_PUBLISHING_FIELDS.IPI_CAE}
          label={profileTranslation.t('ipiCaeNumber')}
          placeholder={profileTranslation.t('ipiCaeNumber')}
          inputRef={register}
          errorMessage={errors?.ipiCaeNumber?.message}
        />
      </div>
      <div className={classes.input}>
        <BaseInput
          defaultValue={publisher}
          name={PRO_PUBLISHING_FIELDS.PUBLISHER}
          label={profileTranslation.t('publisher')}
          placeholder={profileTranslation.t('publisher')}
          inputRef={register}
        />
      </div>
      <div className={classes.submitButton}>
        <SButton type="submit" disabled={!isDirty}>
          {t('save')}
        </SButton>
      </div>
    </form>
  )
}

ProPublishingContainer.propTypes = {
  handleSave: func.isRequired,
}
