import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BaseInput } from '#components/inputs/base-input'
import { string, func, shape } from 'prop-types'
import { TextArea } from '#components/inputs/textarea'

const SContainer = styled.div`
  width: 24rem;
  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    width: 100%;
    margin-right: 0;
  }
`

const SInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`

export const ProfileActionArea = ({ inputRef, errors, inputDefaultValues }) => {
  const { t } = useTranslation('profile')

  return (
    <SContainer>
      <SInputBox>
        <BaseInput
          name="profileName"
          label={t('profileName')}
          errorMessage={errors.profileName?.message}
          inputRef={inputRef}
          defaultValue={inputDefaultValues.profileName}
          customWidth="100%"
          isBolded
        />
        <TextArea
          name="bio"
          label={t('bio')}
          errorMessage={errors.bio?.message}
          defaultValue={inputDefaultValues.bio}
          inputRef={inputRef}
          rows={5}
          hint={t('bioHint')}
          isBolded
        />
      </SInputBox>
    </SContainer>
  )
}

ProfileActionArea.propTypes = {
  inputRef: func.isRequired,
  errors: shape({}).isRequired,
  inputDefaultValues: shape({}),
  profileName: string,
}

ProfileActionArea.defaultProps = {
  inputDefaultValues: {},
  profileName: '',
}
