import { NAVIGATION_PATHS } from '#routes/routes'

export const STEPS = [
  {
    withButton: true,
    href: NAVIGATION_PATHS.CREATE_PROFILE,
  },
  {
    withButton: false,
  },
  {
    withButton: false,
  },
  {
    withButton: true,
    href: NAVIGATION_PATHS.CONNECT_WALLET,
  },
  {
    withButton: true,
    href: NAVIGATION_PATHS.SELL_COLLECTIBLES,
  },
]
