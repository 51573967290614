import { ACTION_TYPES } from '#modules/forms/form-wizard/context'
import {
  FIELDS,
  RELEASE_VERSION as RELEASE_VERSION_ARR,
  DISTRO_STATUS,
  STEPS,
} from '#pages/distro/_utils/constants'
import { explicitContent as explicitContentArr } from '#pages/vault/song/tabs/metadata/utils/constants/explicitContent'
import {
  yesNoNO,
  yesNoYES,
} from '#pages/vault/song/tabs/metadata/utils/constants/wasPreviouslyReleased'
import {
  createYears,
  checkIfIsReadyForDistribution,
} from '#pages/distro/_utils/helpers'

const findValue = (arr, value) => arr.find(item => item.value === String(value))

const getGenre = genreObj =>
  genreObj ? { label: genreObj?.name, value: genreObj?.id } : undefined

const isPreviouslyReleased = previousReleaseDate =>
  previousReleaseDate ? yesNoYES : yesNoNO

const isArtworkExplicit = artworkExplicit => {
  if (artworkExplicit === null) return
  return artworkExplicit
    ? { label: 'Yes', value: 'yes' }
    : { label: 'No', value: 'no' }
}

const getDraftSongs = songs =>
  songs.map(song => ({
    ...song.project,
    fileId: song.file.id,
    songId: song.id,
    isMetadataFormOpened: false,
  }))

const getSnapshotSongs = songs => {
  return songs.map(song => {
    return {
      ...song.lastSnapshot,
      fileId: song.file.id,
      songId: song.id,
      isMetadataFormOpened: false,
    }
  })
}

const arraysEquals = (profileArtistIds, distroArtistIds) => {
  return (
    profileArtistIds.length === distroArtistIds.length &&
    profileArtistIds.every(artist =>
      distroArtistIds.some(distroArtist => distroArtist.id === artist.id)
    )
  )
}
// eslint-disable-next-line consistent-return
const getArtistId = (profileArtistIds, distroArtists) => {
  const distroArtistIds = distroArtists.map(obj => ({ ...obj.artistId }))

  if (!distroArtistIds.length) {
    return {
      option: '',
      value: '',
    }
  }

  if (distroArtistIds.length > 0) {
    if (arraysEquals(profileArtistIds, distroArtistIds)) {
      return {
        option: 1,
        value: profileArtistIds,
      }
    }
    if (distroArtistIds.some(artist => !artist.externalId)) {
      return {
        option: 2,
        value: distroArtistIds,
      }
    }
    return {
      option: 3,
      value: distroArtistIds.map(obj => ({
        artistId: obj.externalId,
        avatarUrl: obj.avatar,
        type: obj.type,
        name: obj.name,
      })),
    }
  }
}
// eslint-disable-next-line consistent-return
export const resolvePastRelease = (dispatch, pastRelease) => {
  const { id, songs, createdAt, profile, status, lastSnapshot } = pastRelease

  const fromSnapshot =
    status === DISTRO_STATUS.COMPLETE || status === DISTRO_STATUS.PENDING

  const {
    releaseName,
    primaryArtistName,
    deliveryDate,
    musicStores,
    labelName,
    upc,
    deliveryRegions,
    releaseVersion,
    releaseCopyrightOwner,
    releaseCopyrightYear,
    artworkCopyrightOwner,
    artworkCopyrightYear,
    explicitContent,
    primaryGenre,
    recordingYear,
    secondaryGenre,
    problems,
    problemsTemp,
    artworkUrl,
    previousReleaseDate,
    attestArtwork,
    artworkExplicit,
    distroArtists,
    subscription,
  } = fromSnapshot ? lastSnapshot : pastRelease

  const yearValues = createYears()

  dispatch({
    type: ACTION_TYPES.SET_STORED_FORM_STATE,
    payload: {
      [FIELDS.DISTRO_ID]: id,
      [FIELDS.PARENT_ID]: profile.id,
      [FIELDS.ARTIST_ID]: getArtistId(profile.artistIds, distroArtists || []),
      [FIELDS.RELEASE_NAME]: releaseName,
      [FIELDS.RELEASE_VERSION]: findValue(RELEASE_VERSION_ARR, releaseVersion),
      [FIELDS.PRIMARY_ARTIST_NAME]: primaryArtistName,
      [FIELDS.LABEL_NAME]: labelName,
      [FIELDS.GENRE]: getGenre(primaryGenre),
      [FIELDS.SECONDARY_GENRE]: getGenre(secondaryGenre),
      [FIELDS.EXPLICIT_CONTENT]: findValue(explicitContentArr, explicitContent),
      [FIELDS.UPC]: upc,
      [FIELDS.RECORDING_YEAR]: findValue(yearValues, recordingYear) || {},
      [FIELDS.RELEASE_COPYRIGHT_OWNER]: releaseCopyrightOwner,
      [FIELDS.ARTWORK_COPYRIGHT_OWNER]: artworkCopyrightOwner,
      [FIELDS.IS_PREVIOUSLY_RELEASED]:
        isPreviouslyReleased(previousReleaseDate),
      [FIELDS.RELEASE_COPYRIGHT_YEAR]:
        findValue(yearValues, releaseCopyrightYear) || {},
      [FIELDS.ARTWORK_COPYRIGHT_YEAR]:
        findValue(yearValues, artworkCopyrightYear) || {},
      [FIELDS.PREVIOUSLY_RELEASED_DATE]: previousReleaseDate,
      [FIELDS.ARTWORK]: artworkUrl,
      [FIELDS.TEXT_MATCHES]: attestArtwork,
      [FIELDS.NO_OTHER_TEXT]: attestArtwork,
      [FIELDS.NO_LOGOS]: attestArtwork,
      [FIELDS.CLEAR_ARTWORK]: attestArtwork,
      [FIELDS.ARTWORK_EXPLICIT]: isArtworkExplicit(artworkExplicit),
      [FIELDS.ESTIMATED_RELEASE_DATE]: deliveryDate,
      ...(musicStores.length > 0 && { [FIELDS.MUSIC_STORES]: musicStores }),
      ...(deliveryRegions.length > 0 && {
        [FIELDS.COUNTRIES]: deliveryRegions,
      }),
      [FIELDS.CREATED_AT]: createdAt,
      [FIELDS.PROBLEMS]: problems,
      [FIELDS.PROBLEMS_TEMP]: problemsTemp,
      [FIELDS.SUBSCRIPTION]: subscription,
    },
  })

  if (fromSnapshot) {
    dispatch({
      type: ACTION_TYPES.SET_STORED_FORM_STATE,
      payload: {
        [FIELDS.SONG_FILES]: getSnapshotSongs(songs),
      },
    })
  }

  if (status === DISTRO_STATUS.DENIED) {
    dispatch({
      type: ACTION_TYPES.SET_STORED_FORM_STATE,
      payload: {
        [FIELDS.SONG_FILES]: getDraftSongs(songs),
      },
    })
    dispatch({ type: ACTION_TYPES.SET_STEP, payload: 6 })
  }

  if (status === DISTRO_STATUS.UNPAID) {
    dispatch({
      type: ACTION_TYPES.SET_STORED_FORM_STATE,
      payload: {
        [FIELDS.SONG_FILES]: getDraftSongs(songs),
      },
    })
    dispatch({ type: ACTION_TYPES.SET_STEP, payload: 7 })
  }

  if (status === DISTRO_STATUS.IN_PROGRESS) {
    dispatch({
      type: ACTION_TYPES.SET_STORED_FORM_STATE,
      payload: {
        [FIELDS.SONG_FILES]: getDraftSongs(songs),
      },
    })

    const areFilesReadyForDistro = getDraftSongs(songs).every(
      checkIfIsReadyForDistribution
    )

    switch (true) {
      case !!pastRelease.deliveryDate:
        return dispatch({ type: ACTION_TYPES.SET_STEP, payload: 6 })
      case !!pastRelease.releaseName:
        return dispatch({ type: ACTION_TYPES.SET_STEP, payload: 5 })
      case !areFilesReadyForDistro:
        return dispatch({ type: ACTION_TYPES.SET_STEP, payload: 3 })
      case !!songs.length:
        return dispatch({ type: ACTION_TYPES.SET_STEP, payload: 4 })
      default:
        return dispatch({ type: ACTION_TYPES.SET_STEP, payload: 2 })
    }
  }
}

// Fixme: for multiple artistId types
export const isSameArtist = (currentArtist, newArtist) => {
  return (
    currentArtist[0]?.name === newArtist[0]?.name &&
    currentArtist[0]?.type === newArtist[0]?.type
  )
}

export const getFormNavigation = t => [
  { text: t('artistProfile'), stepNumber: STEPS.FIRST },
  { text: t('artistId'), stepNumber: STEPS.SECOND },
  { text: t('addSongFiles'), stepNumber: STEPS.THIRD },
  { text: t('releaseDetails'), stepNumber: STEPS.FOURTH },
  {
    text: t('deliveryAndReleaseDate'),
    stepNumber: STEPS.FIFTH,
  },
  { text: t('reviewAndSubmit'), stepNumber: STEPS.SIXTH },
  { text: t('checkout'), stepNumber: STEPS.SEVENTH },
]
