import { useCallback, useState } from 'react'

export const useToggle = (initialState = false) => {
  const [isEnabled, setIsEnabled] = useState(initialState)

  const toggle = useCallback(() => setIsEnabled(state => !state), [])
  const enable = useCallback(() => setIsEnabled(true), [])
  const disable = useCallback(() => setIsEnabled(false), [])

  return {
    isEnabled,
    toggle,
    enable,
    disable,
  }
}
