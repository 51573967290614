import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { BaseInput } from '#components/inputs/base-input'
import { TextArea } from '#components/inputs/textarea'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import {
  NFT_DETAILS_METADATA_FIELDS,
  NFT_DETAILS_STEP_FIELDS,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { chunk } from 'lodash'

const SFormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  padding-bottom: 1.5rem;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
  height: 6.25rem;
`

const STextareaWrapper = styled.div`
  height: 16rem;
`

const STextArea = styled(TextArea)`
  width: 100%;
`

export const DetailsForm = () => {
  const { t } = useTranslation('nftWizard')
  const { register, control, formState } = useFormContext()

  const { errors } = formState

  const { readOnly } = useNftCreator()

  const getProps = name => {
    return {
      inputRef: register,
      name,
      label: t(`steps.details.${name}`),
      errorMessage: errors[name]?.message,
      required: true,
      disabled: readOnly,
      ...(![
        NFT_DETAILS_STEP_FIELDS.DESCRIPTION,
        NFT_DETAILS_STEP_FIELDS.NAME,
      ].includes(name) && { hint: t('steps.details.hint') }),
    }
  }
  const renderInputField = fieldName => {
    if (fieldName === NFT_DETAILS_STEP_FIELDS.DESCRIPTION)
      return (
        <STextareaWrapper>
          <STextArea {...getProps(fieldName)} />
        </STextareaWrapper>
      )

    return <SBaseInput {...getProps(fieldName)} control={control} />
  }

  return (
    <SFormWrapper>
      {chunk(NFT_DETAILS_METADATA_FIELDS, 3).map((inputGroup, i) => (
        <div key={i}>{inputGroup.map(renderInputField)}</div>
      ))}
    </SFormWrapper>
  )
}
