import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  NFT_DETAILS_STEP_DEFAULT_VALUES,
  NFT_DETAILS_STEP_FIELDS,
  nftDetailsSchema,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { isNftSubmitted } from '#pages/sell-collectibles/_utils'
import { StructureApi } from '#api/requests/structure'
import { useTranslation } from 'react-i18next'

const WEEK_MILLISECONDS = 7 * 24 * 60 * 60 * 1000
const DELAY = 10 * 60 * 1000

export const useDetailsStep = setError => {
  const { t } = useTranslation('nftWizard')

  const minReleaseDate = new Date(Date.now() + WEEK_MILLISECONDS + DELAY)

  const methods = useForm({
    resolver: yupResolver(nftDetailsSchema(t, minReleaseDate)),
    defaultValues: NFT_DETAILS_STEP_DEFAULT_VALUES,
    mode: 'all',
  })

  const resetDetails = () => {
    methods.reset(NFT_DETAILS_STEP_DEFAULT_VALUES)
  }

  const setInitialDetails = async (nft, track) => {
    try {
      const { startDate, totalUnits, price, status, collectibleSnapshot } = nft

      let collectibleData = collectibleSnapshot

      if (!isNftSubmitted(status) && track) {
        const { data } = await StructureApi.getSingleStructure(track.projectId)

        collectibleData = data.collectible
      }

      methods.reset({
        ...collectibleData,
        [NFT_DETAILS_STEP_FIELDS.RELEASE_DATE]: startDate
          ? new Date(startDate)
          : null,
        [NFT_DETAILS_STEP_FIELDS.SCARCITY]: totalUnits,
        [NFT_DETAILS_STEP_FIELDS.PRICE]: price,
      })

      await methods.trigger()
    } catch {
      setError('errorLoadingStructure')
    }
  }

  const isDetailsStepCompleted = () => {
    return Object.keys(NFT_DETAILS_STEP_FIELDS).reduce((a, b) => {
      return a && !methods.getFieldState(NFT_DETAILS_STEP_FIELDS[b]).error
    }, true)
  }

  return {
    methods,
    minReleaseDate,
    resetDetails,
    setInitialDetails,
    isDetailsStepCompleted,
  }
}
