import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { NFT_BASE_EVENTS, NFT_EVENTS, NFT_ITEM_EVENTS } from '#api/event'
import { SWR_EVENT_ACTION_RELOAD, useEventSWR } from '#hooks/swr/useEventSWR'

const nftEvents = nftId => [
  ...NFT_BASE_EVENTS.map(event => `${event}.${nftId}`),
  ...NFT_ITEM_EVENTS,
]
const nftEventFilterAction = nftId => (eventData, mutate) => {
  if (NFT_ITEM_EVENTS.includes(eventData.type)) {
    if (eventData.payload.nftId === nftId) {
      mutate()
    }
    return
  }
  SWR_EVENT_ACTION_RELOAD(eventData, mutate)
}

export const useNfts = query => {
  // const { data, error, ...rest } = useSWR(
  //   `${ENDPOINTS.NFT_SETUP}${query || ''}`
  // )
  // FIXME: remove
  const { data, error, ...rest } = useEventSWR({
    eventKey: NFT_EVENTS, // TODO not all of these are important for the list..
    eventAction: SWR_EVENT_ACTION_RELOAD,
    swrKey: `${ENDPOINTS.NFT_SETUP}${query || ''}`,
  })
  return {
    nfts: data?.data,
    count: data?.count,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useSingleNft = nftId => {
  // const { data, error, ...rest } = useSWR(
  //   nftId ? buildUrl(ENDPOINTS.NFT_NFT, { nftId }) : null,
  //   {
  //     revalidateOnFocus: false,
  //   }
  // )
  // FIXME: remove

  const { data, error, ...rest } = useEventSWR({
    eventKey: nftId ? nftEvents(nftId) : [],
    eventAction: nftId ? nftEventFilterAction(nftId) : SWR_EVENT_ACTION_RELOAD,
    swrKey: nftId ? buildUrl(ENDPOINTS.NFT_NFT, { nftId }) : null,
  })
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
