import React from 'react'
import { string, node } from 'prop-types'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import styled from 'styled-components'

const SHeroBackground = styled.div`
  width: 100%;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-size: cover;
  background-position: center;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
`

const SHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 9.5rem;
  left: 12.5rem;
`

const SHeroHeader = styled.h1`
  font-size: 50px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  white-space: pre-wrap;
  margin-bottom: 0.5rem;
`

const SHeroDescription = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 40rem;
  font-size: 1.5rem;
  white-space: pre-line;
`

const SHeroButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const SButtonMain = styled(Button)`
  width: 300px;
  height: auto;
  font-size: 23px;
  padding: 20px;
  text-transform: uppercase;
`

const SButtonSecond = styled(SButtonMain)`
  width: 360px;
`

export const Hero = ({
  header,
  subheaderComponent,
  bgUrl,
  buttonOneUrl,
  buttonTwoUrl,
  buttonOneText,
  buttonTwoText,
}) => {
  return (
    <SHeroBackground bgUrl={bgUrl}>
      <SHeroWrapper>
        <SHeroHeader>{header}</SHeroHeader>
        <SHeroDescription>{subheaderComponent}</SHeroDescription>
        <SHeroButtonsWrapper>
          <SButtonMain onClick={() => navigate(buttonOneUrl)}>
            {buttonOneText}
          </SButtonMain>
          {buttonTwoUrl && (
            <SButtonSecond
              variant={VARIANTS.NEW_BLUE_SPECIAL}
              onClick={() => navigate(buttonTwoUrl)}
            >
              {buttonTwoText}
            </SButtonSecond>
          )}
        </SHeroButtonsWrapper>
      </SHeroWrapper>
    </SHeroBackground>
  )
}

Hero.propTypes = {
  header: string.isRequired,
  bgUrl: string.isRequired,
  buttonOneUrl: string.isRequired,
  buttonTwoUrl: string.isRequired,
  buttonOneText: string.isRequired,
  buttonTwoText: string.isRequired,
  subheaderComponent: node.isRequired,
}
