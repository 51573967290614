import React from 'react'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

const SInnerCircle = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    disabled,
  }) => (disabled ? COLORS.neutral_70 : COLORS.primary_50)};
`

const SRadioButton = styled.div`
  margin-right: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
      checked,
      disabled,
    }) => (checked && !disabled ? COLORS.primary_50 : COLORS.neutral_70)};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    disabled,
  }) => (disabled ? COLORS.neutral_80 : COLORS.neutral_90)};
`

export const RadioButton = ({ checked, disabled, className }) => {
  return (
    <SRadioButton checked={checked} disabled={disabled} className={className}>
      {checked && <SInnerCircle disabled={disabled} />}
    </SRadioButton>
  )
}

RadioButton.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
}
