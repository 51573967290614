import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, ButtonSize, ButtonTheme } from '@tunego/tunego-ui-components'
import { AddSplit } from '#pages/sell-collectibles/create-collectible/_components/steps/splits/_components/add-split'

const STableFooter = styled.tfoot``

const SRow = styled.tr`
  height: 3.75rem;
`

const SSummaryRow = styled(SRow)`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
`

const SCell = styled.td`
  padding: 0 2rem;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SButtonWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const TableFooter = () => {
  const { t } = useTranslation('nftWizard')

  return (
    <STableFooter>
      <SRow>
        <SCell>
          <AddSplit />
        </SCell>
      </SRow>
      <SSummaryRow>
        <SCell colSpan={3}>{t('steps.splits.total')}</SCell>
        <SCell>
          {/* TODO: total split value */}
          100%
        </SCell>
        <SCell colSpan={2}>
          <SButtonWrapper>
            <Button
              buttonTheme={ButtonTheme.Secondary}
              buttonSize={ButtonSize.Sm}
            >
              {t('steps.splits.edit')}
            </Button>
          </SButtonWrapper>
        </SCell>
      </SSummaryRow>
    </STableFooter>
  )
}
