import { useCallback, useState } from 'react'
import {
  CROPPER_DEFAULT_COORDINATES,
  CROPPER_DEFAULT_ROTATION,
  CROPPER_MIN_ZOOM,
} from '#components/image-cropper/constants'
import { blobUrlToFile, getCroppedImg } from '#components/image-cropper/utils'

export const useImageCropper = ({
  image,
  acceptedFile,
  setPreview,
  updateImage,
}) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [rotation, setRotation] = useState(CROPPER_DEFAULT_ROTATION)

  const [zoom, setZoom] = useState(CROPPER_MIN_ZOOM)
  const [crop, setCrop] = useState(CROPPER_DEFAULT_COORDINATES)

  const clearCropSettings = () => {
    setCrop(CROPPER_DEFAULT_COORDINATES)
    setRotation(CROPPER_DEFAULT_ROTATION)
    setZoom(CROPPER_MIN_ZOOM)
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onCropConfirm = useCallback(async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)
    const file = await blobUrlToFile(
      croppedImage,
      acceptedFile.name,
      acceptedFile.type
    )
    if (setPreview) setPreview(croppedImage)
    await updateImage(file)
  }, [croppedAreaPixels, rotation])

  return {
    rotation,
    zoom,
    crop,
    croppedAreaPixels,
    clearCropSettings,
    setRotation,
    setZoom,
    setCrop,
    onCropComplete,
    onCropConfirm,
  }
}
