import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { TransitionGroup } from 'react-transition-group'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { NotificationToastWrapper } from '#modules/notifications/components/notification-toast-wrapper'
import { useBetterHover } from '#hooks/useBetterHover'
import { TOAST_MAX_SHOWN_NOTIFICATIONS } from '#modules/notifications/utils/constants'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const NotificationsToastContainer = React.forwardRef(
  ({ markAsRead }, ref) => {
    const classes = useStyles()
    const [shownNotifications, setShownNotifications] = useState([])
    const [queuedNotifications, setQueuedNotifications] = useState([])
    const [isIn, wrapperRef] = useBetterHover()

    const addNotification = useCallback(
      notification => {
        // !isIn prevents pushing the notifications upwards if user is hovering them
        if (
          !isIn &&
          shownNotifications.length < TOAST_MAX_SHOWN_NOTIFICATIONS
        ) {
          setShownNotifications([...shownNotifications, notification])
        } else {
          setQueuedNotifications([...queuedNotifications, notification])
        }
      },
      [shownNotifications, queuedNotifications, isIn]
    )

    const mutateUnmounting = id =>
      setShownNotifications(current => current.filter(entry => entry.id !== id))

    const flushQueue = useCallback(() => {
      if (queuedNotifications.length < 1) return
      if (shownNotifications.length < TOAST_MAX_SHOWN_NOTIFICATIONS + 1) {
        const consumedItems = queuedNotifications
          .slice(
            0,
            Math.min(
              TOAST_MAX_SHOWN_NOTIFICATIONS - shownNotifications.length,
              queuedNotifications.length
            )
          )
          .filter(item => item)
        const consumedItemIds = consumedItems.map(item => item.id)
        setQueuedNotifications(current =>
          current.filter(
            notification => !consumedItemIds.includes(notification.id)
          )
        )
        // TODO prevent duplicate notification on race condition?
        setShownNotifications(current => [...current, ...consumedItems])
      }
    }, [shownNotifications, queuedNotifications])

    useEffect(() => {
      if (!isIn) flushQueue()
    }, [flushQueue, isIn])

    const clearNotification = useCallback(
      id => {
        setQueuedNotifications(current =>
          current.filter(notification => notification.id !== id)
        )
        setShownNotifications(current =>
          current.filter(notification => notification.id !== id)
        )
        flushQueue()
      },
      [flushQueue]
    )

    const clearAll = useCallback(() => {
      setQueuedNotifications([])
      setShownNotifications([])
    }, [])

    useImperativeHandle(ref, () => ({
      addNotification,
      clearNotification,
      clearAll,
    }))

    return (
      <div ref={wrapperRef} className={classes.wrapper}>
        <TransitionGroup appear>
          {shownNotifications.map(notif => (
            <NotificationToastWrapper
              key={notif.id}
              notification={notif}
              isRunning={!isIn}
              markAsRead={markAsRead}
              onExpired={() => mutateUnmounting(notif.id)}
              onRemoved={flushQueue}
            />
          ))}
        </TransitionGroup>
      </div>
    )
  }
)

NotificationsToastContainer.propTypes = {
  markAsRead: func,
}

NotificationsToastContainer.defaultProps = {
  markAsRead: () => {},
}
