import { capitalize } from '@material-ui/core'
import {
  BILLING_PERIOD,
  SUBSCRIPTION_ACTION,
  CARD_BRAND,
} from '#pages/profile/utils/constants'
import { formatPrice } from '#utils/formatPrice'
import { formatDate } from '#utils/formatDate'

export const formatIntervalPrice = (
  cents,
  isMonthlyValue = true,
  showPerMonth = true
) => {
  const price = isMonthlyValue ? cents : cents / 12
  const formattedPrice = formatPrice(price)
  const isFormattedPriceNumber = formattedPrice[0] === '$'
  if (!isFormattedPriceNumber) {
    return formattedPrice
  }
  return `${formattedPrice}${showPerMonth ? '/mo' : ''}`
}

export const formatBytes = bytes => {
  if (bytes === null) return '--'
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)

  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`
}
// eslint-disable-next-line consistent-return
export const formatPriceValue = (value, t, trackOrAlbum = '') => {
  if (value && trackOrAlbum) {
    return `${formatIntervalPrice(value)}/${t(trackOrAlbum)}`
  }
  if (value) return value
  if (value === 0) return t('free')
}

export const formatPriceValueHistory = (period, amount, t) => {
  if (!period || !amount || isNaN(amount) || !isFinite(amount)) return null
  const isMonthly = period === BILLING_PERIOD.MONTH
  const price = formatIntervalPrice(amount, isMonthly)
  const formattedPeriod = isMonthly ? t('monthlyBilling') : t('annualBilling')

  return `${formattedPeriod} (${price})`
}

export const formatProratedAdjustment = adjustmentPrice => {
  return `${
    adjustmentPrice?.includes('-') ? '-' : ''
  } ${adjustmentPrice?.replace('-', '')}`
}
// eslint-disable-next-line consistent-return
export const displayContent = (action, level) => {
  if (action === SUBSCRIPTION_ACTION.UPGRADE) {
    switch (level) {
      case 1:
        return {
          title: 'upgradeToGold',
          subTitle: 'moreSongsMoreStorage',
          description: 'doMoreWithTuneGoGold',
          btnText: 'upgradeToGold',
        }
      case 2:
        return {
          title: 'upgradeToPlatinum',
          subTitle: 'massiveStorageBigSavings',
          description: 'tuneGoPlatinumOffers4xStorage',
          btnText: 'upgradeToPlatinum',
        }
      case 3:
        return {
          title: 'upgradeToDiamond',
          subTitle: 'serousPlayersStart',
          description: 'asIfUpTo500SongsAndFull',
          btnText: 'upgradeToDiamond',
        }
      default:
        return {}
    }
  }

  if (action === SUBSCRIPTION_ACTION.DOWNGRADE) {
    switch (level) {
      case 0:
        return {
          title: 'downgradeAreYouSure',
          description: 'ifYouDowngradeBack',
          btnText: 'downgradeToFree',
        }
      case 1:
        return {
          title: 'downgradeAreYouSure',
          description: 'ifYouDowngradeBackGold',
          btnText: 'downgradeToGold',
        }
      case 2:
        return {
          title: 'downgradeAreYouSure',
          description: 'ifYouDowngradeBackPlatinum',
          btnText: 'downgradeToPlatinum',
        }
      default:
        return {}
    }
  }

  if (
    action === SUBSCRIPTION_ACTION.CANCEL ||
    action === SUBSCRIPTION_ACTION.DOWNGRADE_TO_FREE
  ) {
    return {
      title: 'cancelSubscription',
      description: 'byCancelingYourTuneGoSubscription',
      btnText: 'cancelMyTuneGo',
    }
  }
}

export const capitalizeFirstLetter = string => {
  if (!string) return ''
  return capitalize(string)
}

export const checkCard = card => card?.id && card?.last4Digits

export const findCardInfo = payment => {
  if (checkCard(payment.card)) return payment.card
  if (payment.subscription && checkCard(payment.subscription.card)) {
    return payment.subscription.card
  }
  const foundInvoice = payment.invoices?.find(invoice =>
    checkCard(invoice.card)
  )
  if (foundInvoice) return foundInvoice.card
  const foundCharge = payment.charges?.find(charge => checkCard(charge.card))
  if (foundCharge) return foundCharge.card
  return null
}

export const formatCardBrand = brand => CARD_BRAND[brand] || brand || ''

export const formatCardNumber = digits =>
  digits ? `(XXXX-XXXX-XXXX-${digits})` : ''

export const paymentCardInfo = (createdAt, cardBrand, last4Digits) =>
  `${formatDate(createdAt)} ${capitalizeFirstLetter(
    formatCardBrand(cardBrand)
  )} ${formatCardNumber(last4Digits)}`
