export const SECURITY_ICONS = [
  {
    icon: '/assets/svg/ic-mobile.svg',
    text: 'desktopAndMobile',
  },
  {
    icon: '/assets/svg/ic-fingerprint-shield.svg',
    text: 'privacyAndSecurity',
  },
  {
    icon: '/assets/svg/ic-add-collab.svg',
    text: 'collaborationAndTeams',
  },
]

export const FINGERPRINT = {
  header: 'fingerprintYourMusic',
  description: 'everyTrackIsUniqueLikeYour',
  image: '/assets/images/fingerprint.png',
}

export const NEXT_LEVEL = {
  header: 'nextLevel',
  points: [
    { text: 'cloudStorageFor' },
    {
      text: 'grantDigitalSecurity',
    },
    {
      text: 'privatelyAndSecurely',
    },
  ],
  image: '/assets/images/security.png',
}

export const METADATA = {
  header: 'metadataMoney',
  description: 'manageYourMetadata',
  image: '/assets/images/dollar.png',
}

export const BOTTOM_ICONS = [
  {
    icon: '/assets/svg/ic-plane.svg',
    text: 'instantDirect',
  },
  {
    icon: '/assets/svg/ic-collab-icon.svg',
    text: 'privateAndSecure',
  },
  {
    icon: '/assets/svg/ic-file-storage.svg',
    text: 'unlimitedFileSharing',
  },
]
