import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { navigate } from '@reach/router'
import { captureException } from '@sentry/react'

import Qs from 'qs'
import { Auth0Api } from '#api/auth0'

import {
  getAccessToken,
  API_URL,
  NO_REDIRECT_URLS,
  getRedirect,
} from './static'
import { maintenanceSwitch } from '../../globals/helpers/maintenance'

const getClientId = () => {
  let clientId = localStorage.getItem('req_client_id')
  if (!clientId) {
    clientId = uuidv4()
    localStorage.setItem('req_client_id', clientId)
  }
  return clientId
}

export const getApiClient = () => {
  const shouldRedirectAfter403 = !NO_REDIRECT_URLS.includes(
    window.location.pathname
  )

  const axiosInstance = axios.create({
    baseURL: API_URL,
    method: 'get',
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json',
      'Request-ID': uuidv4(),
      'Client-ID': getClientId(),
    },
    paramsSerializer: params => {
      return Qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })

  // add interceptor to handle token refreshing
  axiosInstance.interceptors.response.use(
    // return a response for codes 2xx
    response => {
      if (
        response?.data?.mode === 'maintenance' ||
        (IS_DEV_QA && !!localStorage.getItem('_simulateMaintenance'))
      ) {
        maintenanceSwitch({ ...response.data, enabled: true }, true)
        return Promise.reject(response)
      }
      return response
    },
    // catch codes other than 2xx
    error => {
      if (error?.response) {
        if (error.response.status === 401) {
          Auth0Api.logoutRedirect()
        } else if (error.response.status === 403 && shouldRedirectAfter403) {
          navigate(getRedirect(window.location.pathname))
        } else {
          captureException(error.response.data)
        }
      }
      // return errors with a status code other than 401
      return Promise.reject(error)
    }
  )
  return axiosInstance
}

export const swrFetcher = url => getApiClient()(url).then(res => res.data)
