export const setLocalStorageValue = (key, value) => {
  const oldValue = localStorage.getItem(key)
  if (oldValue === value) return
  localStorage.setItem(key, value)
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: value,
      oldValue,
      storageArea: localStorage,
    })
  )
}

export const deleteLocalStorageValue = key => {
  const oldValue = localStorage.getItem(key)
  localStorage.removeItem(key)
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue: null,
      oldValue,
      storageArea: localStorage,
    })
  )
}
