import React from 'react'
import { node } from 'prop-types'
import { LandingPage } from './landing-page'
import { GetStartedComponent } from './wizard/get-started'
import { PastReleases } from '#pages/distro/past-releases'
import { PastReleasesDetails } from '#pages/distro/past-release-details'
import { DistroCreateNew } from '#pages/distro/wizard'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export {
  DistroCreateNew as CreateNew,
  GetStartedComponent as GetStarted,
  LandingPage,
  PastReleases,
  PastReleasesDetails,
  Routes,
}
export { SALES_IMAGES } from '#pages/distro/_utils/constants'
