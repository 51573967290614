/* eslint-disable camelcase */
import React from 'react'
import { bool, func, shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Tooltip } from '@material-ui/core'
import cn from 'classnames'
import { determineMostRecent } from '#utils/determineMostRecent'
import { hexToRgba } from '#utils/hexToRgba'
import { determineAvatar } from '../../utils/helpers'
import { UserImage } from '#components/user-image'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  bg: {
    background: hexToRgba(theme.palette.color.default, 0.05),
  },
  lighterBg: {
    background: hexToRgba(theme.palette.background.default, 0.05),
  },
  unread: {
    background: hexToRgba(theme.palette.color.primary, 0.5),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    minHeight: 80,
    padding: '0 16px',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  userName: {
    fontSize: 14,
    opacity: 0.5,
  },
  date: {
    color: theme.palette.color.primary,
  },
  msg: {
    width: 310,
    fontSize: 13,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
  },
  nameWrapper: {
    paddingLeft: 16,
    flex: 1,
    overflow: 'hidden',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const SUserImage = styled(UserImage)`
  object-fit: ${props => (props.isProfile ? 'cover' : 'contain')};
`

export const ChatRoom = ({
  room: {
    name,
    last_message_date_sent,
    last_message,
    unread_messages_count,
    data,
    photo,
    updated_at,
  },
  isLighter,
  onClickHandler,
  lastSenderName,
}) => {
  const classes = useStyles()
  const category = data?.category
  const decodedName = decodeURIComponent(name)

  return (
    <div
      className={cn({
        [classes.bg]: !isLighter,
        [classes.lighterBg]: isLighter,
        [classes.unread]: unread_messages_count !== 0,
        [classes.item]: true,
      })}
      onClick={onClickHandler}
    >
      <SUserImage
        userImageUrl={determineAvatar(category, photo)}
        isProfile={category === 'profile'}
        size={45}
      />
      <div className={classes.nameWrapper}>
        <Tooltip {...{ title: decodedName }}>
          <Typography className={classes.title}>{decodedName}</Typography>
        </Tooltip>
        <div className={classes.flex}>
          <Typography className={classes.userName}>
            {decodeURIComponent(lastSenderName)}
          </Typography>
          <Typography className={classes.date}>
            {determineMostRecent(last_message_date_sent * 1000 || updated_at)}
          </Typography>
        </div>
        <Typography className={classes.msg}>{last_message}</Typography>
      </div>
    </div>
  )
}

ChatRoom.propTypes = {
  isLighter: bool,
  room: shape({}).isRequired,
  onClickHandler: func.isRequired,
  lastSenderName: string,
}

ChatRoom.defaultProps = {
  isLighter: false,
  lastSenderName: '',
}
