import React, { useCallback, useMemo, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { sortBy } from 'lodash'
import { FIELDS } from '#pages/distro/_utils/constants'
import { CheckBoxItem } from '#pages/distro/wizard/steps/delivery-and-release-date/components/checkbox-item'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SelectAll } from '#pages/distro/wizard/steps/delivery-and-release-date/components/select-all'
import { BaseLoader } from '#components/loaders/base-loader'
import { ContentWrapper } from '#pages/distro/wizard/steps/delivery-and-release-date/components/content-wrapper'
import { useSystem } from '#hooks/useSystem'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  gridItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stores: {
    width: '100%',
    display: 'grid',
    gap: '2.5rem',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}))

export const MusicStoresForm = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const { storedFormState } = useWizard()
  const { settings, isLoading } = useSystem()
  const distroStores = useMemo(
    () => sortBy(settings?.distroStores || [], 'order'),
    [settings]
  )

  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useFormContext()

  const [isChecked, setIsChecked] = useState(() => {
    return Object.fromEntries(
      distroStores.map(store => [
        store.externalName,
        storedFormState.musicStores?.includes(store.externalName),
      ])
    )
  })

  const toggleCheck = checkboxName => {
    clearErrors(FIELDS.MUSIC_STORES)
    setIsChecked(prevState => {
      const newState = { ...prevState }
      newState[checkboxName] = !prevState[checkboxName]
      return newState
    })
  }

  const selectAll = useCallback(
    value => {
      if (value) clearErrors(FIELDS.MUSIC_STORES)
      const storesObject = Object.fromEntries(
        distroStores.map(store => [store.externalName, value])
      )
      setIsChecked(storesObject)

      const storesKeys = Object.keys(storesObject).filter(k => storesObject[k])
      setValue(FIELDS.MUSIC_STORES, storesKeys)
    },
    [clearErrors, distroStores]
  )

  const deselectAll = () => {
    selectAll(false)
    setValue(FIELDS.MUSIC_STORES, [])
  }

  const checkedStores = Object.keys(isChecked).filter(
    k => isChecked[k] === true
  )

  const isSelectDisabled =
    checkedStores.length === storedFormState?.musicStoresLength
  const isDeselectDisabled = checkedStores.length === 0

  return isLoading ? (
    <BaseLoader isLoading={isLoading} />
  ) : (
    <ContentWrapper>
      <SelectAll
        title={t('deliverToTheFollowingMusicStores')}
        isSelectDisabled={isSelectDisabled}
        isDeselectDisabled={isDeselectDisabled}
        error={errors[FIELDS.MUSIC_STORES]?.message}
        onSelectAll={() => selectAll(true)}
        onDeselectAll={deselectAll}
      />
      <div className={classes.stores}>
        {distroStores.map(({ displayName, externalName, iconUrl }, i) => (
          <div
            key={generateUniqueKey(displayName, i)}
            className={classes.gridItem}
          >
            <CheckBoxItem
              register={register}
              label={displayName}
              name={FIELDS.MUSIC_STORES}
              value={externalName}
              checked={isChecked[externalName]}
              icon={iconUrl}
              onChange={() => toggleCheck(externalName)}
            />
          </div>
        ))}
      </div>
    </ContentWrapper>
  )
}
