import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { shape } from 'prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    backgroundImage: `url("/assets/images/error-background.png")`,
    backgroundSize: 'cover',
    textAlign: 'center',
  },
  outerWrapper: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    display: 'block',
    fontSize: '36px',
    marginTop: 40,
  },
  description: {
    fontSize: '18px',
  },
  message: {
    display: 'block',
    fontSize: '16px',
    margin: '40px 0',
  },
  buttonWrapper: {
    width: '100%',
    maxWidth: 250,
  },
  button: {
    paddingTop: '12px',
    paddingBottom: '12px',
    background: theme.palette.color.primary,
  },
  buttonText: {
    textTransform: 'none',
  },
}))

export const FullScreenError = ({ error }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // FIXME make the logout button respect clearing redux state
  // const [hasLogoutOrTimeout, setHasLogoutOrTimeout] = useState(false)
  const [showNavigateProcess, setShowNavigateProcess] = useState(false)

  const handleTryAgain = () => {
    setShowNavigateProcess(true)
    setTimeout(() => {
      // Yes, you have to do it this way that I can tell.  navigate doesn't work in
      // a context where the thing you're wrapping is also what you're trying to
      // navigate to.
      window.location.href = '/'
    }, 3000)
  }

  return (
    <Fade timeout={500} in>
      <div className={classes.container}>
        <div className={classes.outerWrapper}>
          <div className={classes.wrapper}>
            <img
              src="/assets/images/tunego-static-icon.png"
              height={45}
              alt=""
            />
            <Typography variant="h2" className={classes.header}>
              {t('oops')}
            </Typography>
            <Typography className={classes.description}>
              {t('wellThisIsEmbarrassing')}
            </Typography>
            <Typography className={classes.message}>{error.message}</Typography>
            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth
                onClick={handleTryAgain}
              >
                {showNavigateProcess ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h5" className={classes.buttonText}>
                    {t('letsTryAgain')}
                  </Typography>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}

FullScreenError.propTypes = {
  error: shape({}),
}

FullScreenError.defaultProps = {
  error: null,
}
