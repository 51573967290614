import React from 'react'
import { bool, shape, string } from 'prop-types'
import styled from 'styled-components'
import { useArtwork } from '#pages/sell-collectibles/create-collectible/_hooks/use-artwork'
import { FilePreviewLabel } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/file-preview-label'
import { isFileReady } from '#pages/sell-collectibles/create-collectible/_utils'
import { useTranslation } from 'react-i18next'
import { LoadingIndicator } from '#components/loaders/loading-indicator'

const SImage = styled.img`
  height: 22.5rem;
  width: 22.5rem;
  object-fit: contain;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_80};
`

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SWarning = styled.p`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  text-align: center;
  white-space: pre-wrap;
`

const SImageWrapper = styled.div`
  position: relative;
  height: 22.5rem;
  width: 22.5rem;
  margin-bottom: 2rem;
`

const SOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['neutral_70%']};
  display: flex;
  justify-content: center;
  align-items: center;
`

const SLoadingIndicator = styled(LoadingIndicator)`
  height: 2rem;
  width: 2rem;
`

export const ArtworkPreview = ({ file }) => {
  const { t } = useTranslation('nftWizard')
  const artwork = useArtwork(file)
  const processing = !isFileReady(file)
  const showOverlay = processing || !file.isNftImageAllowed

  return (
    <SWrapper>
      <FilePreviewLabel file={!!artwork} />
      {artwork && file && (
        <SImageWrapper>
          <SImage src={artwork} alt={`${file.name} artwork`} />
          {showOverlay && (
            <SOverlay>
              {processing ? (
                <SLoadingIndicator />
              ) : (
                <SWarning>
                  {t('steps.files.uploadOrSelectAnotherFile')}
                </SWarning>
              )}
            </SOverlay>
          )}
        </SImageWrapper>
      )}
    </SWrapper>
  )
}

ArtworkPreview.propTypes = {
  file: shape({
    id: string,
    name: string,
    fileSuffix: string,
    hasMetadata: bool,
    previewStatus: string,
    isNftAllowed: bool,
  }),
}
