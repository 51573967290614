import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { bool, func, string } from 'prop-types'
import { Button } from '#components/button'
import { ErrorMessage } from '#components/error-message'
import { VARIANTS } from '#components/button/constants'
import { Text } from '#components/text'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 35,
    width: '100%',
  },
  buttonsWrapper: {
    display: 'flex',
  },
}))

const SButton = styled(Button)`
  width: 125px;
`

export const SelectAll = ({
  title,
  onSelectAll,
  isSelectDisabled,
  onDeselectAll,
  isDeselectDisabled,
  error,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <Text className={classes.title}>{title}</Text>
        <ErrorMessage errorMessage={error} />
      </div>
      <div className={classes.buttonsWrapper}>
        <SButton
          variant={VARIANTS.NEW_BLUE_SPECIAL}
          disabled={isDeselectDisabled}
          onClick={onDeselectAll}
        >
          {t('deselectAll')}
        </SButton>
        <SButton disabled={isSelectDisabled} onClick={onSelectAll}>
          {t('selectAll')}
        </SButton>
      </div>
    </div>
  )
}

SelectAll.propTypes = {
  title: string.isRequired,
  onSelectAll: func.isRequired,
  isSelectDisabled: bool.isRequired,
  onDeselectAll: func.isRequired,
  isDeselectDisabled: bool.isRequired,
  error: string.isRequired,
}
