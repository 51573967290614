import React from 'react'
import styled from 'styled-components'
import { func, shape } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { BaseInput } from '#components/inputs/base-input'
import { SideSwitcherText } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/side-switcher-text'

const useStyles = makeStyles(() => ({
  inputsWrapper: {
    display: 'flex',
    gap: '1rem',
  },
  sideSwitch: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

export const InviteByEmail = ({ setSide, register, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <>
      <div className={classes.inputsWrapper}>
        <SBaseInput
          inputRef={register}
          name="pendingName"
          label={t('name')}
          errorMessage={errors.pendingName?.message}
          isBolded
        />
        <SBaseInput
          inputRef={register}
          name="recipientEmail"
          label={t('email')}
          errorMessage={errors.recipientEmail?.message}
          isBolded
        />
      </div>
      <div className={classes.sideSwitch}>
        <SideSwitcherText onClick={setSide}>
          {t('addExisting')}
        </SideSwitcherText>
      </div>
    </>
  )
}

InviteByEmail.propTypes = {
  setSide: func.isRequired,
  register: func.isRequired,
  errors: shape().isRequired,
}
