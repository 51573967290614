import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { useLogin } from '#hooks/useLogin'

const useStyles = makeStyles(theme => ({
  navigationWrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: theme.zIndex.appBar,
  },
  homepageHeader: {
    display: 'flex',
    justifyContent: 'center',
    height: '64px',
    border: `solid 1px ${theme.palette.color.darkGrey}`,
    borderBottom: `1px solid ${theme.palette.color.primary}`,
    backgroundColor: theme.palette.color.charcoal,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    margin: 8,
    width: '100%',
  },
  icon: {
    width: '137px',
    height: '24px',
  },
  buttonIcon: {
    paddingRight: 5,
  },
  flex: {
    display: 'flex',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

const isInLimitedMaintenance = window.LIMITED_MAINTENANCE

export const HomeNavigation = () => {
  const classes = useStyles()
  const { t } = useTranslation('home')

  const login = useLogin()

  return (
    <div className={classes.navigationWrapper}>
      <div className={classes.homepageHeader}>
        <div className={classes.headerWrapper}>
          <img
            src="/assets/svg/tune-go-logo.svg"
            alt=""
            className={classes.icon}
          />
          <div className={`${isInLimitedMaintenance ? '' : classes.flex}`}>
            <SButton variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={login}>
              <img
                src="/assets/svg/ic-sign-in.svg"
                width="25px"
                className={classes.buttonIcon}
                alt="Sign in"
              />
              {t('signIn')}
            </SButton>
            {/* FIXME: temporarily hidden register button */}
            {/* <SButton*/}
            {/*  onClick={() => loginWithRedirect({ initialScreenTab: 'signup' })}*/}
            {/* >*/}
            {/*  <img*/}
            {/*    src="/assets/svg/ic-register.svg"*/}
            {/*    width="25px"*/}
            {/*    className={classes.buttonIcon}*/}
            {/*    alt="Register"*/}
            {/*  />*/}
            {/*  {t('register')}*/}
            {/* </SButton>*/}
          </div>
        </div>
      </div>
    </div>
  )
}
