import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { func, string, bool, number, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { navigate } from '@reach/router'
import {
  displayContent,
  formatIntervalPrice,
} from '#pages/profile/utils/helpers'
import {
  SUBSCRIPTION_PERIOD,
  BILLING_PERIOD,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_ACTION,
  SUBSCRIPTION_STATUS,
} from '#pages/profile/utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useSubscriptionsPlans } from '#hooks/swr/useSubscription'
import { ConfirmationModal } from '#components/confirmation-modal'
import { SubscriptionRadioButtons } from '#pages/profile/tabs/subscription/components/subscription-radio-buttons'
import { SubscriptionModalInfo } from '#pages/profile/tabs/subscription/components/subscription-modal-info'
import { CURRENCY } from '#constants/currency'
import { useUser } from '#hooks/useUser'

const useStyles = ({ isUpgrade }) =>
  makeStyles(theme => ({
    contentWrapper: {
      width: '100%',
      margin: 'auto auto 30px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: 32,
      fontFamily: theme.typography.bold,
      lineHeight: '28px',
      marginBottom: 10,
      textTransform: 'uppercase',
      textAlign: isUpgrade ? 'left' : 'center',
    },
    titleUpgrade: {
      color: theme.palette.color.default,
    },
    titleDowngrade: {
      color: theme.palette.color.warning,
    },
    subTitle: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '24px',
      marginBottom: 24,
      color: theme.palette.color.primary,
    },
    description: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      color: theme.palette.color.default,
      textAlign: isUpgrade ? 'left' : 'center',
      marginBottom: 20,
    },
  }))()

export const SubscriptionModal = ({
  action,
  level,
  isOpened,
  setIsModalOpened,
  planId,
  actions,
}) => {
  const { t } = useTranslation('profile')

  const { user, mutate } = useUser()
  const isUpgrade = action === SUBSCRIPTION_ACTION.UPGRADE

  const classes = useStyles({ isUpgrade })

  const currentSubscription = user?.subscription
  const currentSubscriptionStatus = currentSubscription?.subscription?.status

  const hasPaidPlan = !currentSubscription?.subscriptionPlan.free
  const paidPlanPeriod = currentSubscription?.billingPeriod

  const { plans } = useSubscriptionsPlans()
  const chosenPlan = plans?.find(plan => plan.id === planId)
  const { priceMap } = chosenPlan || {}
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    SUBSCRIPTION_PERIOD.YEAR
  )

  useEffect(() => {
    if (hasPaidPlan) {
      const period =
        paidPlanPeriod === BILLING_PERIOD.MONTH
          ? SUBSCRIPTION_PERIOD.MONTH
          : SUBSCRIPTION_PERIOD.YEAR
      setSubscriptionPeriod(period)
    }
  }, [hasPaidPlan, paidPlanPeriod])

  const monthlyPriceValue = priceMap?.[SUBSCRIPTION_TYPES.MONTHLY]?.value
  const annualPriceValue = priceMap?.[SUBSCRIPTION_TYPES.YEARLY]?.value

  const monthlyPrice = formatIntervalPrice(monthlyPriceValue, true)
  const annualPrice = formatIntervalPrice(annualPriceValue, false)

  const content = displayContent(action, level)

  const handleSubscriptionCancel = async () => {
    await actions.postUpgradeSubscription({
      planId,
      billingPeriod: null,
      amount: 0,
      currency: CURRENCY.USD,
      paymentMethodId: null,
    })
  }

  const handleSubscriptionDowngrade = async () => {
    const downgradeAmount =
      paidPlanPeriod === BILLING_PERIOD.MONTH
        ? monthlyPriceValue
        : annualPriceValue

    await actions.postUpgradeSubscription({
      planId,
      billingPeriod: paidPlanPeriod,
      amount: downgradeAmount,
      currency: CURRENCY.USD,
    })
  }

  const handleClick = async () => {
    const isProrated = hasPaidPlan
      ? currentSubscriptionStatus !== SUBSCRIPTION_STATUS.CANCELED
      : false

    switch (action) {
      case SUBSCRIPTION_ACTION.UPGRADE:
        navigate(
          `${NAVIGATION_PATHS.SUBSCRIPTIONS_PAYMENT}?planId=${planId}&planPeriod=${subscriptionPeriod}&isProrated=${isProrated}`
        )
        return
      case SUBSCRIPTION_ACTION.DOWNGRADE:
        if (currentSubscriptionStatus === 'canceled') {
          navigate(
            `${NAVIGATION_PATHS.SUBSCRIPTIONS_PAYMENT}?planId=${planId}&planPeriod=${subscriptionPeriod}&isProrated=${isProrated}`
          )
        } else {
          await handleSubscriptionDowngrade()
        }

        mutate()
        return
      case SUBSCRIPTION_ACTION.DOWNGRADE_TO_FREE:
      case SUBSCRIPTION_ACTION.CANCEL:
        await handleSubscriptionCancel()
        mutate()
        return
      default:
        // eslint-disable-next-line consistent-return
        return null
    }
  }

  const handleClose = () => {
    setIsModalOpened(!isOpened)
  }

  return (
    <ConfirmationModal
      isOpened={isOpened}
      isWarning={!isUpgrade}
      confirmationText={t(content?.btnText)}
      isOwner
      onClose={handleClose}
      onConfirm={handleClick}
    >
      <div className={classes.contentWrapper}>
        <span
          className={cn(
            classes.title,
            isUpgrade ? classes.titleUpgrade : classes.titleDowngrade
          )}
        >
          {t(content?.title)}
        </span>
        {isUpgrade && (
          <span className={classes.subTitle}>{t(content?.subTitle)}</span>
        )}
        <span className={classes.description}>{t(content?.description)}</span>
        {isUpgrade && !hasPaidPlan && (
          <SubscriptionRadioButtons
            subscriptionPeriod={subscriptionPeriod}
            setSubscriptionPeriod={setSubscriptionPeriod}
            annualPrice={annualPrice}
            monthlyPrice={monthlyPrice}
          />
        )}
        {isUpgrade && hasPaidPlan && (
          <SubscriptionModalInfo
            annualPrice={annualPrice}
            monthlyPrice={monthlyPrice}
            isAnnualVisible={
              hasPaidPlan && paidPlanPeriod === BILLING_PERIOD.YEAR
            }
            isMonthVisible={
              hasPaidPlan && paidPlanPeriod === BILLING_PERIOD.MONTH
            }
          />
        )}
      </div>
    </ConfirmationModal>
  )
}

SubscriptionModal.defaultProps = {
  planId: '',
}

SubscriptionModal.propTypes = {
  action: string.isRequired,
  level: number.isRequired,
  isOpened: bool.isRequired,
  setIsModalOpened: func.isRequired,
  actions: shape().isRequired,
  planId: string,
}
