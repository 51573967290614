import React from 'react'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Section } from '#components/section'
import { PricingSubHeader } from '../../components/pricing-sub-header/PricingSubHeader'

export const PricingSection = () => {
  const { t } = useTranslation('videogo')

  return (
    <Section
      header={t('videoGoPricing')}
      getStartedUrl={`${NAVIGATION_PATHS.VIDEO_GO}/coming-soon`}
      subheaderComponent={<PricingSubHeader />}
    />
  )
}
