import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { node, string, bool } from 'prop-types'
import { useOnClickOutside } from '@tunego/tunego-ui-components'
import {
  ARROW_STYLE,
  TOOLTIP_COLOR,
  TOOLTIP_THEME,
} from '#components/tooltip/tooltipStyle'

const SWrapper = styled.div`
  position: relative;
`

const STooltip = styled.div`
  padding: ${({ theme: { tunego } }) =>
    `${tunego.SPACING.sm} ${tunego.SPACING.lg}`};
  background-color: ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
  border: 1px solid ${({ tooltipTheme }) => TOOLTIP_COLOR[tooltipTheme]};
  z-index: 100000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme: { tunego } }) => tunego.FONT.SIZE.sm};
  font-weight: 500;
  transition: opacity 0.3s linear;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: none;
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 20rem;
`

const SSpan = styled.span`
  font-weight: inherit;
  font-size: inherit;
`

const SArrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;

  ${({ position }) => ARROW_STYLE[position]}
`

export const Tooltip = ({
  children,
  disabled,
  text,
  className,
  theme,
  position,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const tooltip = useRef(null)
  const tooltipWrapper = useRef(null)
  const arrow = useRef(null)

  const show = () => {
    setShowTooltip(true)
  }

  const hide = () => {
    setShowTooltip(false)
  }

  useOnClickOutside(tooltipWrapper, hide)

  const { styles, attributes, update } = usePopper(
    tooltipWrapper.current,
    tooltip.current,
    {
      placement: position,
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'arrow', options: { element: arrow.current } },
        { name: 'preventOverflow', options: { padding: 20 } },
      ],
    }
  )

  useEffect(() => {
    if (update) update()
  }, [update, showTooltip])

  return (
    <SWrapper
      ref={tooltipWrapper}
      className={className}
      onBlur={hide}
      onMouseLeave={hide}
    >
      <STooltip
        ref={tooltip}
        style={styles.popper}
        {...attributes.popper}
        show={!disabled && showTooltip}
        tooltipTheme={theme}
      >
        {text}
        <SArrow
          ref={arrow}
          style={styles.arrow}
          position={position}
          tooltipTheme={theme}
        />
      </STooltip>
      <SSpan onMouseOver={show} onFocus={show} onClick={show}>
        {children}
      </SSpan>
    </SWrapper>
  )
}

Tooltip.propTypes = {
  children: node,
  disabled: bool,
  text: string | node,
  className: string,
  position: string,
  theme: string,
}

Tooltip.defaultProps = {
  position: 'top',
  theme: TOOLTIP_THEME.PRIMARY,
}
