import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { CHECKOUT_TYPE } from '#modules/checkout/utils/constants'

export const getSuccessData = (type, t) => {
  switch (type) {
    case CHECKOUT_TYPE.MASTERING:
      return {
        buttonText: t('goToMyMasters'),
        congratulationsMessage: t('yourTrackIsBeingProcessed'),
        onClick: () => navigate(NAVIGATION_PATHS.VIEW_MASTERS),
      }
    case CHECKOUT_TYPE.DISTRO:
      return {
        buttonText: t('promoteYourRelease'),
        congratulationsMessage: t('yourReleaseIsOnTheWay'),
        onClick: () => navigate(NAVIGATION_PATHS.PROMOTION),
      }
    case CHECKOUT_TYPE.PROMOTION:
      return {
        buttonText: t('goToMyCrowdReports'),
        congratulationsMessage: t('yourSongIsOnTheWay'),
        onClick: () => navigate(NAVIGATION_PATHS.PROMOTION_REPORTS),
      }
    default:
      return ''
  }
}
