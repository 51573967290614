import React from 'react'
import { makeStyles } from '@material-ui/core'
import { oneOf } from 'prop-types'
import { SALES_IMAGES } from '#pages/distro'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  salesWrapper: {
    maxWidth: '70rem',
    textAlign: 'center',
    marginBottom: '2rem',
  },
  imagesWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    alignItems: 'center',
    columnGap: '6rem',
    rowGap: '3rem',
    marginBottom: '3rem',
  },
}))

export const SalesImages = ({ mode = 'dark' }) => {
  const classes = useStyles()

  return (
    <div className={classes.salesWrapper}>
      <div className={classes.imagesWrapper}>
        {SALES_IMAGES.map((image, i) => (
          <img
            key={generateUniqueKey(image[mode], i)}
            src={`/assets/images/stores/${image[mode]}.png`}
            alt=""
          />
        ))}
      </div>
    </div>
  )
}

SalesImages.propTypes = {
  mode: oneOf(['dark', 'light']),
}

SalesImages.defaultProps = {
  mode: 'dark',
}
