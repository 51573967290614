import React, { useState, useEffect } from 'react'
import { arrayOf, string } from 'prop-types'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import {
  useArtistProjectFiles,
  useArtistStructure,
} from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { FilesListContainer } from '#pages/vault/song/tabs/files/containers/files-list-container'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { useReload } from '#modules/page-wrapper/context'

export const FilesContainer = ({ accessRoles }) => {
  const { t } = useTranslation('vault')
  const { projectId } = useParams()

  const [, setShouldRefreshData] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [shouldSortAscending, setShouldSortAscending] = useState(true)
  const [sortBy, setSortBy] = useState('')

  const determineSwrSortingQuery = () => {
    if (sortBy) {
      return `&sortBy=${sortBy}&sortDirection=${
        shouldSortAscending ? 'ASC' : 'DESC'
      }`
    }
    return ''
  }

  const query = `?page=${pageIndex}&limit=${rowsPerPage}&isHealthChecked=true${determineSwrSortingQuery()}`

  const { project, mutate: mutateStructure } = useArtistStructure(projectId)
  const {
    files,
    isLoading,
    mutate: mutateFiles,
  } = useArtistProjectFiles(
    { projectId, profileId: project?.profileId, folderId: project?.folderId },
    query
  )

  const { shouldReload, reload } = useReload()

  useEffect(() => {
    if (shouldReload) {
      reload(() => {
        mutateFiles()
        mutateStructure()
      })
    }
  }, [shouldReload])

  return (
    <ColumnWrapper>
      <BaseLoader isLoading={isLoading} text={t('songFilesAreBeingLoaded')} />
      {files?.data && (
        <FilesListContainer
          {...{
            accessRoles,
            setShouldSortAscending,
            setSortBy,
            sortBy,
          }}
          component="div"
          files={files?.data}
          masterId={project?.masterFileId}
          count={files?.count}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          projectId={projectId}
          profileId={project?.profileId || project.parentsPath[0]?.id}
          folderId={project?.folderId}
          shouldSortAscending={shouldSortAscending}
          setShouldRefreshData={setShouldRefreshData}
          parentName={project?.parentsPath[0].name}
        />
      )}
    </ColumnWrapper>
  )
}

FilesContainer.propTypes = {
  accessRoles: arrayOf(string).isRequired,
}
