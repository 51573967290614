import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { BaseLoader } from '#components/loaders/base-loader'
import { Button } from '#components/button'
import { PaymentMethodsTable } from '#pages/profile/tabs/payment-methods/components/payment-methods-table'
import { PaymentMethodModal } from '#pages/profile/tabs/payment-methods/components/payment-method-modal'
import { usePaymentMethods } from '#hooks/swr/usePayments'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { PaymentsApi } from '#api/requests/payments'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { AddPaymentMethodModalContainer } from '#pages/profile/tabs/payment-methods/containers/add-payment-method-modal-container'
import { MODAL_TYPES } from '#pages/profile/utils/constants'
import { useToggle } from '#hooks/useToggle'
import { useError } from '#hooks/useError'
import styled from 'styled-components'

const SBaseLoader = styled(BaseLoader)`
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SButton = styled(Button)`
  width: 250px;
  text-transform: uppercase;
`

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
  },
  newMethodButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1.9rem',
    margin: '2rem 0',
  },
}))

export const PaymentMethodsContainer = () => {
  const { t } = useTranslation('profile')
  const classes = useStyles()
  const [modalType, setModalType] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const { error, setResponseError, clearError } = useError()
  const [isInProgress, setIsInProgress] = useState(false)
  const { isLoading, paymentMethods, mutate } = usePaymentMethods()
  const modalTitle =
    modalType === MODAL_TYPES.DELETE_THIS_CARD
      ? t(`areYouSure`)
      : t(`setAsDefault`)

  const {
    isEnabled: isAddNewModalOpened,
    enable: enableAddNewModal,
    disable: disableAddNewModal,
  } = useToggle()

  const {
    isEnabled: isModalOpened,
    enable: enableModal,
    disable: disableModal,
  } = useToggle()

  const { anyLoading, actions } = useWithAsyncAction({
    addNewCard: PaymentsApi.addNewCard,
    setAsDefaultMethod: PaymentsApi.setPaymentMethodAsDefault,
    deletePaymentMethod: PaymentsApi.deletePaymentMethod,
  })

  const onModalOpen = (id, type) => {
    setModalType(type)
    setPaymentMethodId(id)
    enableModal()
  }

  const onModalClose = () => {
    disableModal()
    setModalType('')
    setPaymentMethodId('')
  }

  const handleDeletePaymentMethod = async () => {
    try {
      await actions.deletePaymentMethod(paymentMethodId)
      mutate()
    } catch (err) {
      setResponseError(err)
    }
  }

  const handleSetAsDefaultMethod = async () => {
    try {
      await actions.setAsDefaultMethod(paymentMethodId)
      mutate()
    } catch (err) {
      setResponseError(err)
    }
  }

  const onModalConfirm = () => {
    return modalType === MODAL_TYPES.DELETE_THIS_CARD
      ? handleDeletePaymentMethod()
      : handleSetAsDefaultMethod()
  }

  const handleAddNewPaymentMethod = async (stripe, cardElement, nameOnCard) => {
    setIsInProgress(true)
    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: nameOnCard,
        },
      })

      const {
        data: { clientSecret },
      } = await actions.addNewCard(paymentMethod.id)

      if (clientSecret) {
        try {
          await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
        } catch (err) {
          setIsInProgress(false)
          setResponseError(err)
        }
      }
    } catch (err) {
      setIsInProgress(false)
      setResponseError(err)
    }
    mutate()
    disableAddNewModal()
    setIsInProgress(false)
  }

  const showLoader = isLoading || anyLoading || isInProgress

  return (
    <div className={classes.container}>
      <div className={classes.containerContent}>
        <SBaseLoader isLoading={isLoading} />
        <CommonLoadingOverlay
          dialogOpen={showLoader}
          error={error}
          onErrorBackdropClick={clearError}
        />
        {!isLoading && (
          <PaymentMethodsTable openModal={onModalOpen} data={paymentMethods} />
        )}
        <PaymentMethodModal
          modalType={modalType}
          isOpened={isModalOpened}
          title={modalTitle}
          isOwner
          onClose={onModalClose}
          onConfirm={onModalConfirm}
        />
      </div>
      <div className={classes.newMethodButton}>
        <SButton onClick={enableAddNewModal}>
          {t('addNewPaymentMethod')}
        </SButton>
      </div>
      <AddPaymentMethodModalContainer
        isOpened={isAddNewModalOpened}
        onSubmit={handleAddNewPaymentMethod}
        onClose={disableAddNewModal}
      />
    </div>
  )
}
