import React from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'

import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { Modal } from '#components/modal'
import { Text, TEXT_TYPE } from '#components/text'

const SModal = styled(Modal)`
  justify-content: space-between;
  min-height: 200px;
`

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const RemoveSplitModal = ({ onClose, isOpened, isLastSplit }) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  return (
    <SModal isOpen={isOpened} setIsOpen={onClose}>
      <Text type={TEXT_TYPE.BODY2} align="center">
        {isLastSplit
          ? vaultTranslation.t('existingSplitsRecords')
          : vaultTranslation.t('setUsersPercentToZero')}
      </Text>
      <SButton onClick={onClose}>{t('ok')}</SButton>
    </SModal>
  )
}

RemoveSplitModal.propTypes = {
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  isLastSplit: bool.isRequired,
}
