/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { S3Bucket } from './s3bucket'
import { buildUrl } from '#utils/buildUrl'
import { getFileInfo } from '#utils/getFileInfo'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

export class ArtistApi {
  static getArtists = (data, useAltStructuresEndpoint = false) => {
    return getApiClient().get(
      useAltStructuresEndpoint
        ? ENDPOINTS.STRUCTURES_ALT
        : ENDPOINTS.STRUCTURES,
      data
    )
  }

  static postArtistProfile = data => {
    return getApiClient().post(ENDPOINTS.STRUCTURES, {
      category: STRUCTURE_TYPE.PROFILE,
      ...data,
    })
  }

  static patchArtistProfile = (structureId, data) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE, {
        id: structureId,
      }),
      data
    )
  }

  static deleteArtistStructure = structureId => {
    return getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE, {
        id: structureId,
      })
    )
  }

  static duplicateArtistStructure = (structureId, data) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_DUPLICATE, { id: structureId }),
      data
    )
  }

  static postArtistProfileUploadLink = (structureId, data) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURE_UPLOAD_LINK, { id: structureId }),
      data
    )
  }

  static uploadProfileImageHealthCheck = (
    structureId,
    uploadId,
    fileSuffix
  ) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURE_UPLOAD_LINK_HEALTH_CHECK, {
        id: structureId,
      }),
      { uploadId, fileSuffix }
    )
  }

  static deleteAvatar = structureId => {
    return getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURE_AVATAR, {
        id: structureId,
      })
    )
  }

  static manageUploadAvatar = async (structureId, file) => {
    const { fileSuffix, fileSize } = getFileInfo(file)
    try {
      const {
        data: { url, fields, uploadId },
      } = await this.postArtistProfileUploadLink(structureId, {
        fileSuffix,
        fileSize,
      })
      await S3Bucket.uploadImg({
        url,
        file,
        fields,
      })
      await this.uploadProfileImageHealthCheck(
        structureId,
        uploadId,
        fileSuffix
      )
    } catch (err) {
      throw err
    }
  }

  static postArtistSong = data => {
    return getApiClient().post(ENDPOINTS.STRUCTURES, {
      category: STRUCTURE_TYPE.SONG,
      ...data,
    })
  }

  static postArtistAlbum = data => {
    return getApiClient().post(ENDPOINTS.STRUCTURES, {
      category: STRUCTURE_TYPE.ALBUM,
      ...data,
    })
  }

  static setMasterFile = (structureId, fileId) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SET_MASTER, {
        structureId,
        fileId,
      })
    )
  }

  static patchArtistSong = (structureId, data) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SONG, {
        id: structureId,
      }),
      data
    )
  }

  static deleteArtistAvatar = structureId =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURE_AVATAR, {
        id: structureId,
      })
    )

  static restoreNft = nftId =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.RESTORE_NFT, {
        nftId,
      })
    )
}
