import React from 'react'
import styled from 'styled-components'
import { node, string, oneOfType } from 'prop-types'

const SHeader = styled.h2`
  text-transform: uppercase;
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  margin: 1rem 0;
`
export const SecondaryHeader = ({ children, className }) => {
  return <SHeader className={className}>{children}</SHeader>
}

SecondaryHeader.propTypes = {
  children: oneOfType([node, string]),
  className: string,
}
