import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { arrayOf, shape, func } from 'prop-types'
import { ArtistIdListItem } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-list-item'
import { ARTIST_ID_PLATFORM } from '#pages/distro/_utils/constants'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    margin: '10px 0 25px',
  },
  listTitle: {
    fontSize: 18,
    fontWeight: 900,
    fontFamily: theme.typography.avenirNextFont,
    paddingTop: 15,
  },
  message: {
    fontSize: 16,
    fontWeight: 500,
  },
  notFoundBox: {
    fontFamily: theme.typography.avenirNextFont,
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.color.default,
    padding: 25,
    opacity: 0.5,
  },
}))

export const ArtistIdList = ({ ids, setSelectedArtistIds }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const [selectedItem, setSelectedItem] = useState(0)

  useEffect(() => {
    if (ids?.length) {
      const { artistId, name, avatarUrl } = ids[selectedItem]
      setSelectedArtistIds([
        { artistId, name, type: ARTIST_ID_PLATFORM.SPOTIFY, avatarUrl },
      ])
    } else {
      setSelectedArtistIds([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, ids])

  const select = index => () => {
    if (index !== selectedItem) {
      setSelectedItem(index)
    }
  }

  const noItemsFound = ids?.length === 0
  return (
    <>
      <span className={classes.listTitle}>Spotify ID</span>
      <div className={classes.list}>
        {ids?.map((item, index) => (
          <ArtistIdListItem
            key={generateUniqueKey(item, index)}
            {...{
              ...item,
              selected: selectedItem === index,
              select: select(index),
            }}
          />
        ))}
        {noItemsFound && (
          <span className={classes.notFoundBox}>{t('noMatchesFound')}</span>
        )}
      </div>
    </>
  )
}

ArtistIdList.propTypes = {
  ids: arrayOf(shape({})).isRequired,
  setSelectedArtistIds: func.isRequired,
}
