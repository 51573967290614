import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { SectionTitle } from '#components/section-title'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '12.5rem',
  },
}))

const SButton = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.default};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.primary};
  min-width: 120px;
  height: 40px;
  border-radius: 10px;
  margin-top: 60px;
  text-transform: uppercase;
`

const SSectionTitle = styled(SectionTitle)`
  color: ${({ theme: { colors } }) => colors.primary};
`

export const ProcessingView = () => {
  const { t } = useTranslation('mastering')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <SSectionTitle>{t('yourMasterIsProcessing')}</SSectionTitle>
      <p>{t('thereIsSomeNextLevelTech')}</p>
      <p>{t('checkBackLater')}</p>
      <SButton onClick={() => navigate(NAVIGATION_PATHS.VIEW_MASTERS)}>
        {t('viewMyMasters')}
      </SButton>
    </div>
  )
}
