import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const SEmptyDropzone = styled.img`
  height: 30px;
  width: 30px;
  margin-bottom: 1.5rem;
`

export const EmptyDropzone = ({ icon, buttonText }) => {
  const { t } = useTranslation('onboarding')

  return (
    <>
      <SEmptyDropzone src={icon} alt="" />
      <Button>{t(buttonText)}</Button>
    </>
  )
}

EmptyDropzone.propTypes = {
  icon: string,
  buttonText: string,
}

EmptyDropzone.defaultProps = {
  icon: '/assets/svg/ic-upload.svg',
  buttonText: 'selectFiles',
}
