import * as yup from 'yup'

const MAX_TEXT = 255

const MAX_TITLE = 100

export const generalMetadataValidationSchema = yup.object().shape({
  primaryArtistName: yup
    .string()
    .max(255, 'Primary artist name must be at most 255 characters')
    .nullable(),
  songName: yup
    .string()
    .max(255, 'Song name must be at most 255 characters')
    .nullable(),
  albumTitle: yup
    .string()
    .max(255, 'Collection title must be at most 255 characters')
    .nullable(),
  isrc: yup
    .string()
    .nullable()
    .max(15)
    .matches(/^[A-Z]{2}-[A-Z0-9]{3}-[0-9]{2}-[0-9]{5}/i, {
      message: 'ISRC must follow the provided format XX-XXX-99-99999',
      excludeEmptyString: true,
    }),
  copyrightOwner: yup
    .string()
    .max(100, 'Copyright owner must be at most 100 characters')
    .nullable(),
  copyrightYear: yup
    .string()
    .test('empty or in range', 'Year range has to be 1000 - 9999', val => {
      if (!val) {
        return true
      }
      return (
        !!val.match(/[0-9]+/gi) && Number(val) >= 1000 && Number(val) <= 9999
      )
    })
    .nullable(),
  label: yup
    .string()
    .max(255, 'Label must be at most 255 characters')
    .nullable(),
  publisher: yup
    .string()
    .max(255, 'Publisher must be at most 255 characters')
    .nullable(),
  primaryGenreId: yup.object().shape({
    value: yup.string(),
    label: yup.string(),
  }),
  secondaryGenreId: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .when('primaryGenreId', (primaryGenreId, schema) => {
      return schema.test({
        test: secondaryGenreId => {
          if (primaryGenreId?.value && secondaryGenreId?.value) {
            return primaryGenreId.value !== secondaryGenreId.value
          }
          return true
        },
        message: 'Secondary Genre cannot be the same as Primary Genre',
      })
    }),
})

export const trackMetadataValidationSchema = yup.object().shape({
  recordingDate: yup.string().nullable(),
  recordingStudio: yup.string().nullable().max(255),
  recordingCity: yup.string().nullable().max(255),
  recordingCountry: yup.string().nullable(),
  trackDuration: yup
    .string()
    .nullable()
    .matches(
      /^((?=(?:\D*\d\D*){6})(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d))$/,
      {
        message: 'Track duration must follow the provided format hh:mm:ss',
        excludeEmptyString: true,
      }
    ),
  // trackDuration: yup.string().when('previousReleaseDate', {
  //   is: true,
  //   then: yup.string().required('Track Duration must not be empty'),
  // }), // TODO - require when we get proper value from the API
  sourceRecordingFormat: yup.string().nullable(),
  mixRecordingFormat: yup.string().nullable(),
  previousReleaseDate: yup
    .date()
    .max(new Date(), 'Previous release must be a past date')
    .nullable(),
  bpm: yup
    .string()
    .matches(/^[0-9]+$/, 'BPM must be only digits')
    .min(2, 'BPM must be at least 2 digits')
    .max(3, 'BPM must be maximum 3 digits')
    .nullable()
    .transform((value, originalValue) =>
      originalValue?.toString().trim() === '' ? null : value
    ),
  timeSignature: yup
    .string()
    .max(50)
    .nullable()
    .transform((value, originalValue) =>
      originalValue?.toString().trim() === '' ? null : value
    ),
  key: yup.string().nullable(),
  catalogId: yup
    .string()
    .max(50)
    .nullable()
    .transform((value, originalValue) =>
      originalValue?.toString().trim() === '' ? null : value
    ),
  iswc: yup
    .string()
    .nullable()
    .max(13)
    .matches(/^T-\d{9}-\d/, {
      message: 'ISWC must follow the provided format T-111111111-1',
      excludeEmptyString: true,
    }),
})

const collectiblesTextFieldSchema = (max = MAX_TEXT) =>
  yup
    .string()
    .max(max, `This field cannot be longer than ${max} characters`)
    .nullable()
    .transform((value, originalValue) =>
      originalValue?.toString().trim() === '' ? null : value
    )

export const collectiblesMetadataValidationSchema = yup.object().shape({
  collectible: yup.object().shape({
    songName: collectiblesTextFieldSchema(MAX_TITLE),
    songwriters: collectiblesTextFieldSchema(),
    mixedBy: collectiblesTextFieldSchema(),
    artworkBy: collectiblesTextFieldSchema(),
    producers: collectiblesTextFieldSchema(),
    masteredBy: collectiblesTextFieldSchema(),
    songDescription: yup
      .string()
      .max(1000, 'Song description cannot be longer than 1000 characters')
      .nullable()
      .transform((value, originalValue) =>
        originalValue?.toString().trim() === '' ? null : value
      ),
  }),
})
