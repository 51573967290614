import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal, Button } from '@tunego/tunego-ui-components'
import { BaseInput } from '#components/inputs/base-input'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { createCollaboratorOffPlatformValidationSchema } from '#pages/vault/_modules/add-collaborator-modal/utils/validation'
import { Checkbox } from '#components/checkbox'

const SSubTitle = styled.div`
  margin-bottom: 1rem;
`

const SNote = styled.div`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.xs};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin-bottom: 1rem;
`

const SSpan = styled.span`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  text-decoration: underline;
  cursor: pointer;
`

const SGrid = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SBox = styled.div`
  text-align: right;
  line-height: 2.5rem;
`

const SField = styled.div`
  margin-bottom: 1rem;
`

const SFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SForm = styled.form`
  width: 100%;
  outline: none;
  max-height: 80vh;
  overflow: auto;
`

const SModal = styled(Modal)`
  width: 500px;
  max-height: 100vh;
  overflow: auto;

  h2 {
    margin: 0;
  }
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SButton = styled(Button)`
  align-self: center;
`

export const SideTwo = props => {
  const {
    open,
    handleClose,
    handleSwitchSides,
    handleInviteCollaborator,
    setChecked,
    checked,
    isProject,
  } = props
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(
      createCollaboratorOffPlatformValidationSchema(vaultTranslation.t)
    ),
  })

  return (
    <SModal
      isOpen={open}
      title={vaultTranslation.t('inviteCollaborator')}
      handleClose={handleClose}
      closeButton
    >
      <SForm onSubmit={handleSubmit(handleInviteCollaborator)}>
        <div>
          <SSubTitle>
            {vaultTranslation.t('sendAndEmailToInviteCollaborator')}
          </SSubTitle>
          <SNote>{vaultTranslation.t('noteASingleContributorCanHave')}</SNote>
          <SFields>
            <SField>
              <SBaseInput
                label={vaultTranslation.t('name')}
                name="collaboratorName"
                errorMessage={errors?.collaboratorName?.message}
                inputRef={register}
                isBolded
              />
            </SField>
            <SField>
              <RolesSelect
                control={control}
                errorMessage={errors?.role?.message}
              />
            </SField>
            <SBaseInput
              label={vaultTranslation.t('email')}
              name="emailAddress"
              inputRef={register}
              errorMessage={errors?.emailAddress?.message}
              isBolded
            />
          </SFields>
          {isProject && (
            <div>
              <Checkbox
                name="addAsContributor"
                checked={checked}
                label={vaultTranslation.t('addAsContributor')}
                onChange={() => setChecked(!checked)}
              />
            </div>
          )}
          <SGrid>
            <div>
              <SBox>
                <SSpan onClick={handleSwitchSides}>
                  {vaultTranslation.t('addExisting')}
                </SSpan>
              </SBox>
            </div>
            <SButton type="submit">{t('send')}</SButton>
          </SGrid>
        </div>
      </SForm>
    </SModal>
  )
}

SideTwo.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleSwitchSides: func.isRequired,
  handleInviteCollaborator: func.isRequired,
  setChecked: func.isRequired,
  checked: bool.isRequired,
  isProject: bool.isRequired,
}
