import { explicitContent } from '#pages/vault/song/tabs/metadata/utils/constants/explicitContent'
import { titleLanguage } from '#pages/vault/song/tabs/metadata/utils/constants/titleLanguage'
import { performanceLanguage } from '#pages/vault/song/tabs/metadata/utils/constants/performanceLanguage'
import { trackVersion } from '#pages/vault/song/tabs/metadata/utils/constants/trackVersion'
import { audioFormat } from '#pages/vault/song/tabs/metadata/utils/constants/audioFormat'
import { wasPreviouslyReleased } from '#pages/vault/song/tabs/metadata/utils/constants/wasPreviouslyReleased'
import { PRO_OPTIONS } from '#constants/proOptions'
import { ACCESS_ROLES } from '#constants/accessRoles'

export const TABLE_HEADER = [
  { name: 'name', width: '25%', sortBy: 'name' },
  {
    name: 'role',
    width: '20%',
    sortBy: 'role',
  },
  {
    name: 'pro',
    width: '10%',
    sortBy: 'proAttribute',
  },
  {
    name: 'ipiCae',
    width: '18%',
    sortBy: 'ipiCaeNumber',
  },
  { name: 'publisher', width: '12%', sortBy: 'publisher' },
  { name: 'isni', width: '25%', sortBy: 'isni' },
  { name: '', width: '10%' },
]

export const MIN_ROW_HEIGHT = 60

export const metadataOptions = {
  general: 'general',
  contributors: 'contributors',
  track: 'track',
  collectibles: 'collectibles',
}

const metaDataTabs = {
  COLLECTIBLES: {
    title: 'collectibles',
    iconUrl: '/assets/svg/ic-collectibles-meta.svg',
    metadataOption: 'collectibles',
  },
  GENERAL: {
    title: 'general',
    iconUrl: '/assets/svg/ic-general-meta.svg',
    metadataOption: 'general',
  },
  TRACK: {
    title: 'track',
    iconUrl: '/assets/svg/ic-track-meta.svg',
    metadataOption: 'track',
  },
  CONTRIBUTORS: {
    title: 'contributors',
    iconUrl: '/assets/svg/ic-contributors-meta.svg',
    metadataOption: 'contributors',
  },
}

export const metadataTiles = roles => {
  const ownerOrCollab =
    roles?.includes(ACCESS_ROLES.OWNER) ||
    roles?.includes(ACCESS_ROLES.COLLABORATOR)

  switch (true) {
    case ownerOrCollab:
      return [
        metaDataTabs.COLLECTIBLES,
        metaDataTabs.GENERAL,
        metaDataTabs.CONTRIBUTORS,
        metaDataTabs.TRACK,
      ]
    case !ownerOrCollab && roles?.includes(ACCESS_ROLES.CONTRIBUTOR):
      return [metaDataTabs.CONTRIBUTORS]
    default:
      return []
  }
}

export const generalMetadataFields = [
  {
    name: 'primaryArtistName',
    defaultValueKey: 'primaryArtistName',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'explicitContent',
    defaultValueKey: 'explicitContent',
    type: 'select',
    isReadOnly: false,
    options: explicitContent,
  },
  {
    name: 'performanceLanguage',
    defaultValueKey: 'performanceLanguage',
    type: 'select',
    isReadOnly: false,
    options: performanceLanguage,
  },
  {
    name: 'songName',
    defaultValueKey: 'songName',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'primaryGenreId',
    defaultValueKey: 'primaryGenreId',
    type: 'select',
    isReadOnly: false,
    options: 'api genres',
  },
  {
    name: 'titleLanguage',
    defaultValueKey: 'titleLanguage',
    type: 'select',
    isReadOnly: false,
    options: titleLanguage,
  },
  {
    name: 'albumTitle',
    defaultValueKey: 'albumTitle',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'secondaryGenreId',
    defaultValueKey: 'secondaryGenreId',
    type: 'select',
    isReadOnly: false,
    options: 'api genres',
  },
  {
    name: 'label',
    defaultValueKey: 'label',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'isrc',
    defaultValueKey: 'isrc',
    type: 'input',
    isReadOnly: false,
    mask: 'aa-***-99-99999',
    displayUppercase: true,
  },
  {
    name: 'copyrightOwner',
    defaultValueKey: 'copyrightOwner',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'publisher',
    defaultValueKey: 'publisher',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'trackVersion',
    defaultValueKey: 'trackVersion',
    type: 'select',
    isReadOnly: false,
    options: trackVersion,
  },
  {
    name: 'copyrightYear',
    defaultValueKey: 'copyrightYear',
    type: 'input',
    isReadOnly: false,
  },
]

export const trackMetadataFields = [
  {
    name: 'recordingDate',
    defaultValueKey: 'recordingDate',
    type: 'calendar',
    isReadOnly: false,
  },
  {
    name: 'audioFormat',
    defaultValueKey: 'audioFormat',
    type: 'select',
    isReadOnly: false,
    options: audioFormat,
  },
  {
    name: 'bpm',
    defaultValueKey: 'bpm',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'recordingStudio',
    defaultValueKey: 'recordingStudio',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'sourceRecordingFormat',
    defaultValueKey: 'sourceRecordingFormat',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'timeSignature',
    defaultValueKey: 'timeSignature',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'recordingCity',
    defaultValueKey: 'recordingCity',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'mixRecordingFormat',
    defaultValueKey: 'mixRecordingFormat',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'key',
    defaultValueKey: 'key',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'recordingCountry',
    defaultValueKey: 'recordingCountry',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'isPreviouslyReleased',
    defaultValueKey: 'isPreviouslyReleased',
    type: 'select',
    isReadOnly: false,
    options: wasPreviouslyReleased,
  },
  {
    name: 'catalogId',
    defaultValueKey: 'catalogId',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'trackDuration',
    defaultValueKey: 'trackDuration',
    type: 'input',
    isReadOnly: false,
    mask: '99:99:99',
  },
  {
    name: 'previousReleaseDate',
    defaultValueKey: 'previousReleaseDate',
    type: 'calendar',
    isReadOnly: false,
  },
  {
    name: 'iswc',
    defaultValueKey: 'iswc',
    type: 'input',
    isReadOnly: false,
    mask: 'T-999999999-9',
  },
]

export const collectiblesMetadataFields = [
  {
    name: 'artist',
    defaultValueKey: 'collectible.artist',
    type: 'input',
    isReadOnly: true,
    omit: true,
  },
  {
    name: 'songwriters',
    defaultValueKey: 'collectible.songwriters',
    type: 'input',
    isReadOnly: false,
    hint: 'commaSeparated',
  },
  {
    name: 'mixedBy',
    defaultValueKey: 'collectible.mixedBy',
    type: 'input',
    isReadOnly: false,
    hint: 'commaSeparated',
  },
  {
    name: 'artworkBy',
    defaultValueKey: 'collectible.artworkBy',
    type: 'input',
    isReadOnly: false,
    hint: 'commaSeparated',
  },
  {
    name: 'songName',
    defaultValueKey: 'collectible.songName',
    type: 'input',
    isReadOnly: false,
  },
  {
    name: 'producers',
    defaultValueKey: 'collectible.producers',
    type: 'input',
    isReadOnly: false,
    hint: 'commaSeparated',
  },
  {
    name: 'masteredBy',
    defaultValueKey: 'collectible.masteredBy',
    type: 'input',
    isReadOnly: false,
    hint: 'commaSeparated',
  },
  {
    name: 'songDescription',
    defaultValueKey: 'collectible.songDescription',
    type: 'textarea',
    isReadOnly: false,
  },
]

export const EDIT_CONTRIBUTOR_FIELDS = {
  VAULT: [
    {
      name: 'isni',
      label: 'isni',
      type: 'input',
      inputType: 'string',
      order: 6,
    },
    {
      name: 'proAttribute',
      label: 'pro',
      type: 'select',
      display: 'inline-flex',
      flexDirection: 'column',
      width: '48%',
      marginRight: '4%',
      marginTop: 8,
      options: PRO_OPTIONS,
      order: 3,
    },
    {
      name: 'ipiCaeNumber',
      label: 'ipiCae',
      type: 'input',
      inputType: 'number',
      display: 'inline-flex',
      width: '48%',
      order: 4,
    },
  ],
  DISTRO: [
    {
      name: 'contributorName',
      label: 'contributorName',
      type: 'input',
      order: 1,
    },
    {
      name: 'role',
      label: 'role',
      type: 'multiselect',
      order: 2,
    },
    {
      name: 'publisher',
      label: 'publisher',
      type: 'input',
      inputType: 'string',
      order: 5,
    },
  ],
}

export const CONTRIBUTOR_ACTION_TYPES = {
  resendInvite: 'resend-invite',
  cancelInvite: 'cancel-invite',
}

export const MENU_OPTIONS = [
  {
    title: `Resend Invite`,
    actionType: CONTRIBUTOR_ACTION_TYPES.resendInvite,
  },
  {
    title: `Cancel Invite`,
    actionType: CONTRIBUTOR_ACTION_TYPES.cancelInvite,
  },
]

export const CONTRIBUTOR_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
}
