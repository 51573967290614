import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

const SSpan = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
`

export const BolderTranslation = ({ children, ...props }) => {
  return <SSpan {...props}>{children}</SSpan>
}

BolderTranslation.propTypes = {
  children: node,
}

BolderTranslation.defaultProps = {
  children: undefined,
}
