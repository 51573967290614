import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { oneOfType, string, number } from 'prop-types'
import { useTranslation } from 'react-i18next'

const getImgUrl = id => `/assets/images/heart-${id}.png`

// eslint-disable-next-line consistent-return, complexity
const getImgNum = rating => {
  if (rating <= 0) return 1
  if (rating < 0.5) return 2
  if (rating < 1) return 3
  if (rating === 1) return 4
  if (rating < 1.5) return 5
  if (rating < 2) return 6
  if (rating === 2) return 7
  if (rating < 3) return 8
  if (rating === 3) return 9
  if (rating < 4) return 10
  if (rating === 4) return 11
  if (rating < 5) return 12
  if (rating === 5) return 13
  if (rating < 6) return 14
  if (rating === 6) return 15
  if (rating < 7) return 16
  if (rating === 7) return 17
  if (rating < 8) return 18
  if (rating === 8) return 19
  if (rating < 8.5) return 20
  if (rating < 9) return 21
  if (rating === 9) return 22
  if (rating < 9.5) return 23
  if (rating < 10) return 24
  if (rating >= 10) return 25
}

const useStyles = makeStyles(theme => ({
  passionRatingContainer: {
    position: 'relative',
    padding: 16,

    '& img': {
      width: '100%',
    },
  },
  centered: {
    position: 'absolute',
    top: '35%',
    left: '49%',
    fontFamily: 'AvenirNextLTPro-Bold',
    textAlign: 'center',
    transform: 'translate(-50%)',
    textShadow: `0px 0px 5px ${theme.palette.color.black}`,
  },
  passion: {
    fontSize: 16,
  },
  rating: {
    fontSize: 18,
  },
}))

export const PassionRatingGraph = ({ rating }) => {
  const classes = useStyles()
  const displayRating = rating * 10
  const imgSrc = getImgUrl(getImgNum(displayRating))
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.passionRatingContainer}>
      <img src={imgSrc} alt={`ratingValue-${rating}`} />
      <div className={classes.centered}>
        <div className={classes.passion}>{t('passion')}</div>
        <div className={classes.rating}>{displayRating}</div>
      </div>
    </div>
  )
}
PassionRatingGraph.propTypes = {
  rating: oneOfType([string, number]),
}

PassionRatingGraph.defaultProps = {
  rating: 0,
}
