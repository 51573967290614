export const MAX_FILE_SIZE = parseInt(
  process.env.REACT_APP_VAULT_MAX_FILE_SIZE,
  10
)

export const CHUNK_SIZE = parseInt(
  process.env.REACT_APP_MULTI_PART_CHUNK_SIZE,
  10
)

export const UPPY_ID = 'uppy'

export const INITIAL_STATE_EVENT = 'GET_INITIAL_STATE'

export const CANCEL_STATUS = {
  None: 'none',
  InProgress: 'in_progress',
  Error: 'error',
  Success: 'success',
}
