import React from 'react'
import { string, shape } from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { getParam } from '#utils/getParam'
import { DefaultHomepage } from '#pages/home/default-homepage'
import { SnoopDropHomepage } from '#pages/home/snoop-drop-homepage'

export const Home = () => {
  const { t } = useTranslation('home')
  const { snoopDropActive } = useFlags()
  const origin = getParam('origin')
  const secureStream = origin === 'securestream'

  if (secureStream) {
    return <div>{t('createYourFreeAccount')}</div>
  }

  return snoopDropActive ? <SnoopDropHomepage /> : <DefaultHomepage />
}

Home.propTypes = {
  location: shape({
    search: string.isRequired,
  }).isRequired,
}
