export const getNavigation = t => [
  {
    text: t('selectReviewType'),
    stepNumber: 1,
  },
  { text: t('selectArtistProfile'), stepNumber: 2 },
  {
    text: t('selectGenre'),
    stepNumber: 3,
  },
  {
    text: t('checkout'),
    stepNumber: 4,
  },
  {
    text: t('confirmation'),
    stepNumber: 5,
  },
]
