import { theme } from '@tunego/tunego-ui-components'

export const DEFAULT_PIXEL_RATIO = 2
export const ROTATION_SPEED = 0.1

export const PARTICLES = {
  COUNT: 9000,
  SPREAD: 5,
  Z_OFFSET: 0.5,
}

export const SPHERE = {
  RADIUS: 0.8,
  WIDTH_SEGMENTS: 42,
  HEIGHT_SEGMENTS: 42,
}
export const MATERIAL = {
  COLOR: theme.nftTools.COLORS.DAY,
  SIZE: 0.005,
}

export const LIGHT = {
  COLOR: theme.nftTools.COLORS.DAY,
  INTENSITY: 0.1,
  POSITION: {
    x: 2,
    y: 3,
    z: 4,
  },
}

export const CAMERA = {
  FOV: 75,
  NEAR: 0.1,
  FAR: 100,
  POSITION: {
    x: 0,
    y: 0,
    z: 2,
  },
}
