import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { NftApi } from '#api/requests/nft'
import { useWallet } from '#hooks/swr/useWallet'
import { useError } from '#hooks/useError'
import { NFT_STATUS } from '#pages/sell-collectibles/_utils/constants'
import {
  NFT_DETAILS_METADATA_FIELDS,
  NFT_DETAILS_STEP_FIELDS,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { ArtistApi } from '#api/requests/artist'
import { reduceFalsyToNullValues } from '#pages/vault/song/tabs/metadata/utils/helpers'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

export const useNftData = () => {
  const { nftId, setNftId, savedNft, detailsStepMethods, restoreProcess } =
    useNftCreator()
  const { wallet } = useWallet()

  const { actions } = useWithAsyncAction({
    assignWallet: NftApi.assignWallet,
    setUp: NftApi.setUp,
    addNftDetails: NftApi.addNftDetails,
    addFile: NftApi.addFile,
    addImageFileFromVault: NftApi.addImageFileFromVault,
    deleteNft: NftApi.deleteNft,
    nftComplete: NftApi.nftComplete,
    deleteItem: NftApi.deleteItem,
    mintNft: NftApi.mintNft,
    patchArtistSong: ArtistApi.patchArtistSong,
    restoreNft: ArtistApi.restoreNft,
  })

  const { error, setError, clearError } = useError()

  const deleteDraft = async () => {
    clearError()

    try {
      if (nftId) {
        await actions.deleteNft(nftId)
      }

      setNftId(null)
    } catch (err) {
      setError(err)
    }
  }

  const saveArtwork = async (nftId, artwork) => {
    clearError()

    try {
      await actions.addImageFileFromVault(nftId, artwork.id)
    } catch (err) {
      setError(err)
    }
  }

  const saveAudio = async (nftId, music) => {
    const [currentAudio] =
      savedNft?.files?.filter(file => file.type === 'track') || []

    clearError()

    try {
      if (currentAudio) {
        await actions.deleteItem(currentAudio.id, 'file')
      }

      await actions.addFile(nftId, music.id)
    } catch (err) {
      setError(err)
    }
  }

  const init = async (profileId, music, artwork) => {
    clearError()

    try {
      const { data } = await actions.setUp({ profileId })

      setNftId(data.nftId)

      await Promise.all([
        actions.assignWallet(data.nftId, { walletId: wallet.id }),
        saveArtwork(data.nftId, artwork),
        saveAudio(data.nftId, music),
      ])

      await navigate(
        `${NAVIGATION_PATHS.CREATE_COLLECTIBLES}?nftId=${data.nftId}`,
        { replace: true }
      )
    } catch (err) {
      setError(err)
    }
  }

  const assignWallet = async () => {
    await actions.assignWallet(nftId, { walletId: wallet.id })
  }

  const saveDetails = async structureId => {
    clearError()

    try {
      const [totalUnits, price, startDate] = detailsStepMethods.getValues([
        NFT_DETAILS_STEP_FIELDS.SCARCITY,
        NFT_DETAILS_STEP_FIELDS.PRICE,
        NFT_DETAILS_STEP_FIELDS.RELEASE_DATE,
      ])

      await actions.addNftDetails(nftId, {
        totalUnits: parseInt(totalUnits, 10),
        price: parseFloat(price),
        startDate,
      })

      const collectiblePayload = {}

      NFT_DETAILS_METADATA_FIELDS.forEach(field => {
        collectiblePayload[field] = detailsStepMethods.getValues(field)
      })

      await actions.patchArtistSong(structureId, {
        collectible: reduceFalsyToNullValues(collectiblePayload),
      })
    } catch (err) {
      setError(err)
    }
  }

  const submitForReview = async callback => {
    clearError()

    try {
      if (savedNft.status !== NFT_STATUS.READY_FOR_MINTING) {
        await actions.nftComplete(nftId)
      }

      await actions.mintNft(nftId)

      callback()
    } catch (err) {
      setError(err)
    }
  }

  const restoreNft = async () => {
    await actions.restoreNft(nftId)

    await restoreProcess()
  }

  return {
    init,
    saveArtwork,
    saveAudio,
    saveDetails,
    submitForReview,
    error,
    deleteDraft,
    restoreNft,
    wallet,
    assignWallet,
  }
}
