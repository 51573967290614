/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { TermsWrapper } from '#pages/_components/terms-wrapper'
import styled from 'styled-components'
import { NAVIGATION_PATHS } from '#routes/routes'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  line-height: 1.5;
  padding: 0 24px 70px;
  font-weight: 400;

  b {
    font-family: ${({
      theme: {
        tunego: { FONT },
      },
    }) => FONT.FAMILY.bold};
  }

  a {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
    text-decoration: none;
  }
`

const SNestedList = styled.ol`
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;

  > li {
    display: table;
    counter-increment: item;

    &:before {
      content: counters(item, '.') '. ';
      display: table-cell;
      padding-right: 0.6em;
    }
  }

  li {
    margin-bottom: 0.6em;
  }
`

export const TermsOfUseText = () => {
  return (
    <TermsWrapper>
      <SWrapper>
        <h1>TuneGO Terms of Use</h1>
        <p>Revised: May 15, 2024</p>
        <br />

        <b>
          <u>
            These Terms of Use contain an arbitration provision and class action
            waiver. &nbsp;Please review the Arbitration section for details.
          </u>
        </b>
        <br />

        <p>
          <span>These TuneGO Terms of Use (the &ldquo;</span>
          <b>Terms</b>
          <span>
            &rdquo;) serve as a legal agreement between TuneGO, Inc., a Delaware
            Corporation, &nbsp;(&ldquo;
          </span>
          <b>TuneGO</b>
          <span>,&rdquo; </span>
          <b>we</b>
          <span>,&rdquo; &ldquo;</span>
          <b>our</b>
          <span>,&rdquo; &ldquo;</span>
          <b>us</b>
          <span>
            &rdquo;) and you, the visitor, governing your access to and use of:
            (a) all content and functionalities available at the TuneGO.com
            websites and our digital marketplace located at
            marketplace.tunego.com and accessible via any corresponding TuneGO
            proprietary mobile application (the &ldquo;Marketplace&rdquo;), and
            any and all other sites to which these Terms are applied and
            subdomains of the foregoing (collectively, the &ldquo;
          </span>
          <b>Site</b>
          <span>&rdquo;); (b) any TuneGO proprietary</span>
          <span>&nbsp;</span>
          <span>
            mobile application(s), including the TuneGO applications currently
            available for download on iOS and Android, including the TuneGO
            application, the TuneGO Creative Studio, and the TuneGO Event
            Manager (collectively, the &ldquo;
          </span>
          <b>App</b>
          <span>
            &rdquo;); and (c) any other&nbsp;content, applications, features,
            functionality, information and services offered by us through the
            Site and/or the App, including, without limitation, embeddable or
            application/device-based features and related technology (
          </span>
          <span>e.g.</span>
          <span>
            , mobile, web, console, desktop and other APIs, widgets, plugins,
            applications, etc.)&nbsp;((a), (b) and (c), collectively, the &quot;
          </span>
          <b>Services</b>
          <span>
            &quot;). &nbsp;These Terms apply whether you are accessing the
            Services via a wireless or mobile device, a personal computer or any
            other technology or device.&nbsp;
          </span>
        </p>
        <br />
        <p>
          <b>
            THIS IS A LEGALLY BINDING CONTRACT. BY ACCESSING OR USING THE
            SERVICES OR BY INDICATING YOUR ACCEPTANCE OF THESE TERMS, YOU
            ACKNOWLEDGE AND REPRESENT THAT YOU HAVE READ THESE TERMS, UNDERSTAND
            THEM, AND AGREE TO BE BOUND BY THEM. YOU ALSO REPRESENT AND WARRANT
            THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS CONTRACT AND ARE NOT
            PROHIBITED BY LAW FROM ACCESSING OR USING THE SERVICES. YOU DO NOT
            HAVE TO ENTER INTO THESE TERMS, BUT IF YOU DO NOT, YOU WILL NOT BE
            PERMITTED TO, AND YOU MAY NOT, USE THE SERVICES.
          </b>
          <br />
        </p>
        <SNestedList start={1}>
          <li>
            <b>Eligibility</b>
            <span>
              . To use the Services, you must be the greater of: (i) 18 years
              old; or (ii) the age of majority in your state or jurisdiction of
              residence. &nbsp;Further, you represent and warrant that you (i)
              are not located in a country that is subject to a U.S. Government
              embargo, or that has been designated by the U.S. Government as a
              terrorist-supporting country; and (ii) are not listed on any U.S.
              Government list of prohibited or restricted parties.
            </span>
          </li>
          <li>
            <b>Your Account</b>
            <span>
              . &nbsp;Certain aspects of the Services may require you to create
              an account by completing a registration form and selecting a user
              ID and password (your &quot;Account&quot;). &nbsp;You agree that
              the information you provide as part of the registration process
              will be true, current and complete, and you agree to update such
              information as applicable so that it continues to be true, current
              and complete. Your Account is personal to you and may not be used
              by any other person. You agree to contact us at{' '}
              <a href="mailto:support@tunego.com">support@tunego.com</a> if you
              become aware of any unauthorized use of the Services on your
              Account.
            </span>
          </li>
          <li>
            <b>Fee-Based Services; Billing and Autorenewals</b>.
            <SNestedList start={1}>
              <li>
                <span>
                  TuneGO offers both free and fee-based Services.
                  &nbsp;Additional Terms may apply to your use of, access to and
                  purchase of such fee-based products or services. You may only
                  purchase such fee-based products and/or services if you are at
                  least 18 years of age or the age of majority in your
                  jurisdiction. You agree to pay in full the prices and fees
                  (including, without limitation, all applicable taxes) for any
                  purchases made utilizing your Account. &nbsp;In making an
                  Account purchase, you will utilize only those methods of
                  payment authorized by us at time of purchase, which is subject
                  to change, at our sole discretion, from time to time (each, a
                  &ldquo;Payment Method&rdquo;). &nbsp;In utilizing the Services
                  and making a purchase on our Site or App, you authorize us to
                  charge your provided Payment Method for the Services you have
                  selected and for any paid feature(s) that you select. &nbsp;
                </span>
              </li>
              <li>
                <span>
                  We may bill: (a) in advance; (b) at the time of purchase; (c)
                  shortly after purchase; or (d) if you have elected a
                  subscription service, on a recurring basis. &nbsp;To the
                  extent TuneGO has not received your payment, in order to bring
                  your account up to date, we may bill you simultaneously for
                  both past due and current amounts. &nbsp;You understand that
                  failure to pay any charges or fees may result in the
                  suspension or cancellation of your Services.
                </span>
              </li>
              <li>
                <span>
                  TuneGO may revise any or all of the fees and prices associated
                  with fee-based products and/or services at any time for any or
                  no reason. However, if we have offered a specific duration and
                  fee for your use of the Service, we agree that the fee will
                  remain in force for that duration, after which your use of the
                  Service will be charged at the then-current fees. If you
                  don&rsquo;t agree to the change in fees, you must stop using
                  the Service and cancel via email to{' '}
                  <a href="mailto:support@tunego.com">support@tunego.com</a>. If
                  you cancel, your Service ends at the end of your current
                  Service period or payment period, and no refunds for
                  previously paid services will be issued. &nbsp;If you do not
                  cancel in accordance with these Terms of Use, the subscription
                  for the applicable Service will automatically renew at the
                  then-current price and for the same subscription period and we
                  will charge your Payment Method on file with us on the first
                  day of the renewal of the subscription period. If you have a
                  credit in your TuneGO account, we may deduct amounts owed to
                  us hereunder from such amounts in your TuneGO account.
                </span>
              </li>
              <li>
                <span>
                  We may calculate taxes payable by you based on the billing
                  information that you provide us at the time of purchase. You
                  are responsible for all charges related to using the purchased
                  Service (for example, data charges and currency exchange
                  settlements). &nbsp;
                </span>
              </li>
              <li>
                <span>
                  TuneGO does not guarantee that product descriptions or other
                  content and products will be available, accurate, complete,
                  reliable, current or error-free. Descriptions and images of,
                  and references to, products or services do not imply our or
                  any of our affiliates&#39; endorsement of such products or
                  services. Moreover, TuneGO and its third party operational
                  service providers reserve the right, with or without prior
                  notice, for any or no reason, to change product descriptions,
                  images, and references; to limit the available quantity of any
                  product; to honor, or impose conditions on the honoring of,
                  any coupon, coupon code, promotional code or other similar
                  promotions; to bar any user from conducting any or all
                  transaction(s); and/or to refuse to provide any user with any
                  product. Further, if TuneGO terminates your use of or
                  registration to the Services because you have breached these
                  Terms of Use, you shall not be entitled to a refund of any
                  unused portion of any fees, payments or other consideration.
                </span>
              </li>
            </SNestedList>
          </li>
          <li>
            <b>Service Specific Terms.</b>
            <SNestedList>
              <li>
                <u>
                  <b>TuneGO Vault</b>
                </u>
                <span>. &nbsp;</span>
                <span>
                  The TuneGO Vault (the &ldquo;Vault&rdquo;) allows you to store
                  and manage files and metadata (the &ldquo;
                </span>
                <b>Vault Files</b>
                <span>
                  &rdquo;). &nbsp;In using the Vault, you will be enabled to
                  collaborate with and (privately) share Vault Files, including
                  music files, with other Vault users. &nbsp;Vault Users will
                  also be empowered to collaborate and communicate with other
                  Vault users using TuneGO Connect. &nbsp;The Vault also enables
                  users to share songs and other Vault Files with selected Vault
                  users. &nbsp;Vault uses will also have the power and ability
                  to create and mint Digital Collectibles for listing on the
                  Marketplace. &nbsp;In using the Vault, you agree to abide by
                  the TuneGO Vault Terms and Conditions (&ldquo;Vault
                  Terms&rdquo;). &nbsp;You acknowledge and accept that we may
                  suspend, revoke, or otherwise limit your access to, and/or use
                  of the Vault if you do not adhere to the Vault Terms, in their
                  entirety. &nbsp;
                </span>
              </li>
              <li>
                <u>
                  <b>TuneGO Digital Collectible Creation Service</b>
                </u>
                <span>
                  . &nbsp;This Service allows users to mint their digital
                  asset(s), music, or other creative work(s) (the &ldquo;
                </span>
                <b>Work(s)</b>
                <span>&rdquo;) into non-fungible tokens (the &ldquo;</span>
                <b>Digital Collectibles</b>
                <span>
                  &rdquo;). To use our Service, you must have a third-party
                  wallet which allows you to engage in transactions on
                  blockchains. Your ownership of the Digital Collectible will be
                  registered on a blockchain by association with your wallet. We
                  do not provide, nor have custody or control over, any third
                  party wallets or blockchains you are interacting with
                  (collectively, &ldquo;
                </span>
                <b>Third Party Platforms</b>
                <span>&rdquo;</span>
                <span>
                  ). You are solely responsible for any transaction you conduct
                  using a Third Party Platform, and we are not responsible or
                  liable for any damage or loss related to the use of any Third
                  Party Platform. &nbsp;You should always read the terms and
                  conditions and privacy policy of a Third Party Platform before
                  using it.
                </span>
                <ol type="A">
                  <li>
                    <u>Verification</u>
                    <span>
                      . &nbsp;Prior to using the Service (e.g., minting a
                      Digital Collectible), users may be required to verify
                      their identity using one or more methods available on the
                      Site. &nbsp;Verification services may be provided by a
                      third party provider (&ldquo;
                    </span>
                    <b>Verification Provider</b>
                    <span>
                      &rdquo;), in which case your use of the verification
                      services will be subject to the Verification
                      Provider&rsquo;s terms and conditions and privacy policy.
                      &nbsp;We do not control and are not responsible for any
                      transaction you conduct with the Verification Provider.
                      &nbsp; If you are unable or unwilling to verify your
                      identity using one of the provided methods, we may refuse
                      your use of some or all of the Services, in our sole
                      discretion. &nbsp;
                    </span>
                  </li>
                  <li>
                    <u>License</u>
                    <span>
                      . &nbsp;You understand and agree that the Works associated
                      with an Digital Collectible will be licensed to the
                      purchaser in accordance with the terms of the Digital
                      Collectible Owner Agreement (defined below).
                    </span>
                  </li>
                  <li>
                    <u>Resale Royalty</u>
                    <span>
                      . During the minting process, you may be provided an
                      opportunity to specify a resale royalty that you may be
                      entitled to receive when an Digital Collectible you mint
                      is resold after the initial sale subject to the operation
                      of any third party technology used in connection with such
                      resale. You understand that depending on where the Digital
                      Collectible is listed for sale, this resale royalty may or
                      may not be implemented, in part, due to the fact that not
                      all Digital Collectible sales sites will necessarily
                      implement the resale royalty. You understand and agree
                      that TuneGO has no responsibility for or involvement in
                      whether a resale royalty is actually paid to you and
                      TuneGO shall not owe you anything if you do not receive
                      any resale royalty.
                    </span>
                  </li>
                  <li>
                    <u>Fees</u>
                    <span>. </span>
                    <span>
                      You may be required to pay fees for use of the Digital
                      Collectible Creation Services. Applicable fees will be set
                      forth on the Site. The Fees and payment methods available
                      to pay the fees and are subject to change at any time, for
                      any reason, and in our sole and exclusive discretion.
                    </span>
                  </li>
                  <li>
                    <u>Payment Terms</u>
                    <span>. </span>
                    <span>
                      You may be required to have a valid third party wallet and
                      form of payment that are compatible with the blockchain on
                      which you intend to mint the Digital Collectible and/or
                      which is available on the Site. You must have all
                      necessary legal authorization to use that wallet and/or
                      payment to complete the transaction. &nbsp;
                    </span>
                  </li>
                  <li>
                    <u>No Refunds</u>
                    <span>.</span>
                    <span>
                      &nbsp;All transactions are irreversible, final and there
                      are no refunds. &nbsp;Please carefully review your order
                      prior to submitting.
                    </span>
                  </li>
                </ol>
              </li>
              <li>
                <u>
                  <b>TuneGO Marketplace</b>
                </u>
                <span>
                  . &nbsp;The TuneGO Marketplace (the &ldquo;Marketplace&rdquo;)
                  offers Digital Collectibles for sale.
                </span>
                <span>&nbsp; </span>
                <span>
                  Except as expressly set forth herein and in the Owner
                  Agreement (defined below), the purchase of a Digital
                  Collectible does not grant purchaser ownership, or any other
                  rights, to the Work(s).
                </span>
              </li>
              <li>
                <ol type="A">
                  <li>
                    <u>Digital Collectible Owner Agreement</u>
                    <span>
                      . &nbsp;Purchase of a Digital Collectible on the
                      Marketplace is subject to the &ldquo;
                    </span>
                    <b>Owner Agreement</b>
                    <span>
                      .&rdquo; &nbsp;By purchasing a Digital Collectible you are
                      agreeing to the terms and conditions set forth in the
                      Owner Agreement, which will be presented at the time of
                      purchase. &nbsp;As detailed in the Owner Agreement, while
                      a purchaser may own a Digital Collectible, they often will
                      have only a limited license to the Work(s), subject to the
                      terms and conditions set forth therein. Unless a custom
                      license is provided by the seller, the Owner License
                      available
                    </span>
                    <span>&nbsp;</span>
                    <a href={NAVIGATION_PATHS.OWNER_AGREEMENT}>here </a>

                    <span>
                      will apply. &nbsp;In the event of a conflict between the
                      Owner Agreement and these Terms, the terms set forth in
                      these Terms shall apply.
                    </span>
                  </li>
                  <li>
                    <u>Purchaser Qualification</u>
                    <span>
                      . &nbsp;You represent and warrant that You (i) are not
                      located in a country that is subject to a U.S. Government
                      embargo, or that has been designated by the U.S.
                      Government as a terrorist-supporting country; and (ii) are
                      not listed on any U.S. Government list of prohibited or
                      restricted parties.
                    </span>
                  </li>
                  <li>
                    <u>Pricing</u>
                    <span>
                      : &nbsp;Prices shown on the Site may be listed in
                      cryptocurrency or U.S. dollars, and are exclusive of
                      taxes. We may elect to provide access to third-party
                      payment methods and forms of payments that allow an
                      as-converted to U.S. dollars basis. &nbsp;Such access is
                      provided in our sole discretion, and your use of such
                      third-party platforms are at your own risk. &nbsp;The
                      Prices and payment methods available are subject to change
                      at any time, for any reason, and in our sole and exclusive
                      discretion.
                    </span>
                  </li>
                  <li>
                    <u>Fees</u>
                    <span>. </span>
                    <span>
                      You may be required to pay fees for use of the Services.
                      Applicable fees will be set forth on the Site. The Fees
                      and payment methods available to pay the fees and are
                      subject to change at any time, for any reason, and in our
                      sole and exclusive discretion. &nbsp;
                    </span>
                  </li>
                  <li>
                    <u>Fees for Creators</u>
                    <span>
                      . &nbsp;If you are a creator (ie a musical artist)
                      utilizing the Marketplace for release of a Digital
                      Collectible or pack of Digital Collectibles associated
                      with your music and/or brand (a &ldquo;Creator&rdquo;),
                      fees assessed to you for use of the Marketplace shall be
                      subdivided as follows:
                    </span>

                    <ol>
                      <li>
                        <span>Primary Sales</span>

                        <ol type="a" start="1">
                          <li>
                            <span>
                              When a Creator mints and creates a primary
                              release, or &ldquo;Drop&rdquo; for the
                              creator&rsquo;s Digital Collectible or a pack of
                              Digital Collectibles, upon purchase we assess a
                              20% (twenty percent) fee. &nbsp;We remit the 80%
                              (eighty percent) remainder of the consideration
                              paid for the Drop to you, the Creator.
                            </span>
                          </li>
                        </ol>
                      </li>
                      <li>
                        <span>Secondary Sales</span>
                        <ol type="a">
                          <li>
                            <span>
                              When a Digital Collectible is sold on the
                              Marketplace, this empowers the purchaser to offer
                              the Digital Collectible on the Marketplace to
                              secondary purchasers (a &ldquo;Secondary
                              Sale&rdquo;). &nbsp;In the event of a Secondary
                              Sale, the seller will receive 91.5% of the sale
                              (list) price of the Digital Collectible. &nbsp;The
                              following fees shall apply to any Secondary Sale:
                            </span>
                          </li>
                          <ol type="i">
                            <li>
                              <span>
                                We will assess a 3.5% royalty fee on all
                                Secondary Sales.
                              </span>
                            </li>
                            <li>
                              <span>
                                The Creator will receive a 5% royalty on all
                                Secondary Sales.
                              </span>
                            </li>
                          </ol>
                        </ol>
                      </li>
                      <li>
                        <span>
                          Marketplace Additional Fees. &nbsp;As part of the
                          purchase process, if applicable, a purchaser of a
                          Creator&rsquo;s Digital Collectible(s) may incur
                          additional charges such as gas fees, credit card
                          charges, currency conversion assessments, bank fees,
                          wallet fees, or similar fees associated with the
                          transaction (&ldquo;Additional Fees&rdquo;). &nbsp;The
                          purchaser acknowledges and accepts that in making a
                          purchase on the Marketplace, the purchaser shall be
                          responsible for payment of these Additional Fees, as
                          applicable.
                        </span>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <span>Marketplace Transactions - Wallets</span>
                    <span>. &nbsp;</span>
                    <span>
                      To either sell or make purchases on the Marketplace, we
                      may require that you utilize a third-party token wallet or
                      similar blockchain-compatible application and/or tool
                      (altogether a &ldquo;Wallet&rdquo;). &nbsp;In the event we
                      do require, or you otherwise utilize a Wallet for
                      Marketplace transactions, in using the Marketplace you
                      acknowledge and warrant that you have all necessary legal
                      authorization to use that Wallet associated with your
                      Marketplace transaction(s). &nbsp; &nbsp;
                    </span>
                  </li>
                  <li>
                    <span>No Refunds</span>
                    <span>.</span>
                    <span>
                      &nbsp;You acknowledge and accept that in utilizing the
                      Marketplace, that all transactions are irreversible, final
                      and there are no refunds. &nbsp;As such, please carefully
                      review all Marketplace orders prior to completion of your
                      transaction(s). &nbsp;
                    </span>
                  </li>
                </ol>
              </li>
            </SNestedList>
          </li>
          <li>
            <b>Inaccuracy Disclaimer</b>
            <p>
              <span>
                From time to time, there may be information on the Site that
                contains typographical errors, inaccuracies, or omissions that
                may relate to product descriptions, pricing, and availability.
                &nbsp;We reserve the right to correct any errors, inaccuracies,
                or omissions and to change or update information at any time
                without prior notice (including after you have submitted your
                order). &nbsp;If you do not wish to continue with your order
                after pricing or other information has been corrected, please
                contact us right away and we will work with you.
              </span>
            </p>
          </li>
          <li>
            <b>Additional Terms</b>
            <p>
              <span>
                Some of our Services have additional terms and conditions
                (&ldquo;
              </span>
              <b>Additional Terms</b>
              <span>
                &rdquo;). &nbsp;Where Additional Terms apply to a Service, we
                will make them available for you to read through your use of
                that Service. &nbsp;By using that Service, you agree to the
                Additional Terms.
              </span>
            </p>
          </li>
          <li>
            <b>Acceptable Use of the Site and Services</b>
            <p>
              <span>
                You are responsible for your use of the Site and Services, and
                for any use of the Services made using your Account or wallet.
                &nbsp;Our goal is to create a positive, useful, legal and safe
                user experience. &nbsp;To promote this goal, we prohibit certain
                kinds of conduct that may be harmful to other users or to us.
                &nbsp;When you use the Services, you may not:
              </span>
            </p>
            <ul>
              <li>
                <span>violate any law or regulation;</span>
              </li>
              <li>
                <span>
                  violate, infringe, or misappropriate other people&rsquo;s
                  intellectual property, privacy, publicity, or other legal
                  rights;
                </span>
              </li>
              <li>
                <span>
                  post or share anything that is illegal, abusive, harassing,
                  harmful to reputation, pornographic, indecent, profane,
                  obscene, hateful, racist, or otherwise objectionable;
                </span>
              </li>
              <li>
                <span>
                  send unsolicited or unauthorized advertising or commercial
                  communications, such as spam;
                </span>
              </li>
              <li>
                <span>
                  engage in spidering or harvesting, or participate in the use
                  of software, including spyware, designed to collect data from
                  the Services;
                </span>
              </li>
              <li>
                <span>
                  transmit any viruses or other computer instructions or
                  technological means whose purpose is to disrupt, damage, or
                  interfere with the use of computers or related systems;
                </span>
              </li>
              <li>
                <span>stalk, harass, or harm another individual;</span>
              </li>
              <li>
                <span>
                  impersonate any person or entity or perform any other similar
                  fraudulent activity, such as phishing;
                </span>
              </li>
              <li>
                <span>
                  use any means to scrape or crawl any Web pages contained in
                  the Site;
                </span>
              </li>
              <li>
                <span>
                  attempt to circumvent any technological measure implemented by
                  us or any of our providers or any other third party (including
                  another user) to protect the Services;
                </span>
              </li>
              <li>
                <span>
                  attempt to decipher, decompile, disassemble, or reverse
                  engineer any of the software or other underlying code used to
                  provide the Services; or
                </span>
              </li>
              <li>
                <span>
                  advocate, encourage, or assist any third party in doing any of
                  the foregoing.
                </span>
              </li>
            </ul>
          </li>
          <li>
            <b>
              Access to App (applicable only to the extent that you download the
              App)
            </b>
            <SNestedList>
              <li>
                <span>
                  If you download the App from the Apple Store or Google Play
                  App Store
                </span>
                <span>, </span>
                <span>
                  subject to your compliance with these Terms and the Apple
                  Store or Google Play App Store terms, TuneGO hereby grants you
                  a non-exclusive, non-transferable, worldwide, royalty-free,
                  limited-term right to access the App solely for personal,
                  non-commercial use on a single mobile device owned or
                  otherwise controlled by you, strictly in accordance with the
                  App&rsquo;s documentation and these Terms.
                </span>
              </li>
              <li>
                <span>
                  As between the parties, TuneGO owns all right, title and
                  interest in and to App, and any intellectual property rights
                  associated with it. TuneGO reserves all rights in and to App
                  not expressly granted to you in these Terms. Except as
                  expressly permitted by these Terms, by law, or by applicable
                  third party license, you must not and must not allow any third
                  party to: (i) sublicense, sell, rent, lease, transfer, assign,
                  or redistribute the App; (ii) host the App for the benefit of
                  third parties; (iii) disclose or permit any third party to
                  access the App, except as expressly permitted in these Terms;
                  (iv) modify or create derivative works of the App, or merge
                  the App with other software; (v) disassemble, decompile,
                  bypass any code obfuscation, or otherwise reverse engineer the
                  App or attempt to derive any of its source code, in whole or
                  in part; (vi) modify, obscure, or delete any proprietary
                  rights notices included in or on the App; (vii) otherwise use
                  or copy the App in a manner not expressly permitted by these
                  Terms; or (viii) use the App beyond its applicable term.
                </span>
              </li>
              <li>
                <span>By using the App,</span>
                <span>&nbsp;</span>
                <span>you</span>
                <span>&nbsp;</span>
                <span>
                  acknowledge that this Section of the Terms is entered into by
                  and between TuneGO and not with Apple, Inc. or Google, Inc.
                  Notwithstanding the foregoing, you acknowledge that Apple,
                  Inc. and its subsidiaries are third-party beneficiaries of
                  this Section and that Apple, Inc. and Google, Inc. have the
                  right (and is deemed to have accepted the right) to enforce
                  this Section. TuneGO is solely responsible for the App and any
                  content contained therein. You acknowledge that Apple, Inc.
                  and Google, Inc. have no obligation whatsoever to furnish any
                  maintenance and support services with respect to the App. You
                  represent and warrant that (i) You are not located in a
                  country that is subject to a U.S. Government embargo, or that
                  has been designated by the U.S. Government as a
                  &quot;terrorist supporting&quot; country; and (ii) You are not
                  listed on any U.S. Government &quot;watch list&quot; of
                  prohibited or restricted parties, including the Specially
                  Designated Nationals list published by the Office of Foreign
                  Assets Control of the U.S. Treasury or the Denied Persons List
                  published by the U.S. Department of Commerce. &nbsp;
                </span>
              </li>
              <li>
                <span>
                  In the event of any third party claim that the App or your
                  possession and use of the App infringes that third
                  party&rsquo;s intellectual property rights, TuneGO, not Apple,
                  Inc. or Google, Inc., will be solely responsible for the
                  investigation, defense, settlement and discharge of any such
                  intellectual property infringement claim.
                </span>
              </li>
              <li>
                <span>
                  This Section 8 only applies to the extent that you have access
                  to the App. Upon deletion of the App from your mobile device,
                  all rights granted to you in this Section will also terminate,
                  and you must cease use of the App and delete all copies of the
                  App from your mobile device and account.
                </span>
                <span>
                  &nbsp;Termination will not limit any of TuneGO&rsquo;s rights
                  or remedies at law or in equity.
                </span>
              </li>
            </SNestedList>
          </li>
          <li>
            <b>User Content</b>
            <p>
              <span>
                The Site and some of our Services allow you to upload, submit,
                store, send, or receive content and data (&ldquo;
              </span>
              <b>User Content</b>
              <span>
                &rdquo;). &nbsp;User Content includes, without limitation, any
                art, media or other creative works, including the Works. You
                retain ownership of any intellectual property rights that you
                hold in that User Content. &nbsp;
              </span>
            </p>
            <p>
              <span>
                When you provide User Content to the Services, you give us
                permission under all of the rights in any copyright associated
                with the User Content to reproduce and use your User Content for
                all activities and purposes related to operating the Site and
                Services as they exist now and in the future. This license is
                for the limited purpose of operating, promoting, and improving
                the Site and Services, and to develop new Services. &nbsp;Our
                license to your User Content is non-exclusive, meaning you may
                use the User Content for your own purposes or let others use
                your User Content for their purposes. &nbsp;This license is
                fully-paid and royalty free, meaning we do not owe you anything
                for the right to use of your User Content. You agree we may
                exercise our rights under this license anywhere in the world and
                in any media. &nbsp;The license is perpetual, meaning that our
                rights under this license continue even after you stop using the
                Site and Services. &nbsp;
              </span>
            </p>
            <p>
              <span>You promise that:</span>
            </p>
            <ul>
              <li>
                <span>
                  you own all rights to your User Content or, alternatively,
                  that you have the right to give us the rights described above;
                  and
                </span>
              </li>
              <li>
                <span>
                  your User Content does not infringe the intellectual property
                  rights, privacy rights, publicity rights, or other legal
                  rights of any third party.
                </span>
              </li>
            </ul>
            <p>
              <span>
                We may refuse to accept or transmit User Content for any reason.
                &nbsp;We may remove from the Services or disable access to any
                User Content for any reason. &nbsp;
              </span>
            </p>
          </li>
          <li>
            <b>Ownership of the Services</b>
            <p>
              <span>
                Other than the User Content, we (or our licensors) own all
                right, title, and interest in and to (a) the Site and Services,
                including all software, text, media, and other content available
                on the Site and Services (&ldquo;
              </span>
              <b>Our</b>
              <span>&nbsp;</span>
              <b>Content</b>
              <span>
                &rdquo;); and (b) our trademarks, logos, and brand elements
                (&ldquo;
              </span>
              <b>Marks</b>
              <span>
                &rdquo;). &nbsp;The Site and Services, Our Content, and Marks
                are all protected under U.S. and international laws. &nbsp;The
                look and feel of the Site and Services are copyright &copy;
                TuneGO. All rights reserved. &nbsp;You may not duplicate, copy,
                or reuse any portion of the Site, including any HTML/CSS,
                Javascript, or visual design elements or concepts without
                express written permission from us.
              </span>
            </p>
          </li>
          <li>
            <b>Copyright and Intellectual Property Policy</b>
            <p>
              <span>
                We respond to notices of alleged copyright infringement and
                terminate Accounts of repeat infringers according to the process
                set out in the U.S. Digital Millennium Copyright Act. &nbsp;If
                you believe that your work has been copied in a way that
                constitutes copyright infringement, please forward the following
                information to the Copyright Agent named below:
              </span>
            </p>
            <ul>
              <li>
                <span>Your address, telephone number, and email address.</span>
              </li>
              <li>
                <span>
                  A description of the copyrighted work that you claim has been
                  infringed.
                </span>
              </li>
              <li>
                <span>
                  A description of where the alleged infringing material is
                  located.
                </span>
              </li>
              <li>
                <span>
                  A statement by you that you have a good faith belief that the
                  disputed use is not authorized by you, the copyright owner,
                  its agent, or the law.
                </span>
              </li>
              <li>
                <span>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright interest.
                </span>
              </li>
              <li>
                <span>
                  A statement by you, made under penalty of perjury, that the
                  above information is accurate and that you are the copyright
                  owner or authorized to act on behalf of the copyright owner.
                </span>
              </li>
            </ul>
            <p>
              <span>Copyright Agent:</span>
            </p>
            <p>
              <span>By email: &nbsp;copyright@tunego.com or by mail to:</span>
            </p>
            <p>
              <span>By mail: &nbsp; &nbsp; &nbsp; TuneGO, Inc.</span>
            </p>
            <p>
              <span>Attn: DMCA Complaints</span>
            </p>
            <p>
              <span>2505 Anthem Village Dr. #E283</span>
            </p>
            <p>
              <span>Henderson, NV 89052</span>
            </p>
            <p>
              <span>
                For clarity, only copyright infringement notices should go to
                our Copyright Agent. &nbsp;You acknowledge that if you fail to
                comply with all of the requirements of this Section your notice
                may not be valid.
              </span>
            </p>
            <p>
              <span>
                If you believe the content that was removed (or to which access
                was disabled) is not infringing, or that you have the
                authorization from the copyright owner, the copyright
                owner&#39;s agent, or pursuant to the law, to post and use such
                content, you may submit a counter-notice to the address listed
                above containing the following information:
              </span>
            </p>
            <ul>
              <li>
                <span>Your physical or electronic signature; </span>
              </li>
              <li>
                <span>
                  Identification of the content that has been removed or to
                  which access has been disabled and the location at which the
                  content appeared before it was removed or disabled;
                </span>
              </li>
              <li>
                <span>
                  A statement that you have a good faith belief that the content
                  was removed or disabled as a result of mistake or a
                  misidentification of the content; and
                </span>
              </li>
              <li>
                <span>
                  Your name, physical address, telephone number, and e-mail
                  address, a statement that you consent to the jurisdiction of
                  the federal court in the judicial district in which your
                  physical address is located, or if your physical address is
                  located outside of the United States, then to the federal
                  court in Richmond, VA and a statement that you will accept
                  service of process from the person who provided notification
                  of the alleged infringement.
                </span>
              </li>
            </ul>
            <p>
              <span>
                After we receive your counter-notification, we will forward it
                to the party who submitted the original claim of copyright
                infringement. &nbsp;Please note that when we forward the
                counter-notification, it includes your personal information.
                &nbsp;By submitting a counter-notification, you consent to
                having your information revealed in this way. &nbsp;We will not
                forward the counter-notification to any party other than the
                original claimant.
              </span>
            </p>
            <p>
              <span>
                After we send out the counter-notification, the claimant must
                then notify us within 10 days that he or she has filed an action
                seeking a court order to restrain you from engaging in
                infringing activity relating to the content that was removed or
                disabled. &nbsp;If we receive such notification, we will be
                unable to restore the material. &nbsp;If we do not receive such
                notification, we may reinstate the material.
              </span>
            </p>
          </li>
          <li>
            <b>Privacy</b>
            <p>
              <span>Your privacy is very important to us. &nbsp;Our </span>
              <a href={NAVIGATION_PATHS.PRIVACY_POLICY}>Privacy Policy</a>{' '}
              <span>
                explains how we collect, use, protect, and when we share
                personal information and other data with others. &nbsp;You are
                responsible for maintaining the confidentiality of your Account
                information, including your private key. &nbsp;You are
                responsible for all activities that occur under your Account and
                you agree to notify us immediately of any unauthorized access or
                use of your Account. &nbsp;We are not responsible or liable for
                any damage or loss related to any unauthorized access or use of
                your Account.
              </span>
            </p>
          </li>
          <li>
            <b>Links</b>
            <p>
              <span>
                The Site and Services may contain links to other websites and
                online resources. &nbsp;A link to a third party&rsquo;s website
                does not mean that we endorse it or that we are affiliated with
                it. &nbsp;We are not responsible or liable for any damage or
                loss related to the use of any third-party website. &nbsp;You
                should always read the terms and conditions and privacy policy
                of a third-party website before using it. &nbsp;
              </span>
            </p>
          </li>
          <li>
            <b>Changes to the Services</b>
            <p>
              <span>
                We enhance and update the Site and Services often. &nbsp;We may
                change or discontinue the Services at any time, with or without
                notice to you.
              </span>
            </p>
          </li>
          <li>
            <b>Termination</b>
            <p>
              <span>
                We reserve the right to not provide the Services to any person.
                &nbsp;We also reserve the right to terminate any user&rsquo;s
                right to access the Services at any time, in our discretion.
                &nbsp;If you violate any of these Terms, your permission to use
                the Site and Services automatically terminates.
              </span>
            </p>
          </li>
          <li>
            <b>Disclaimer and Limitations on Our Liability</b>
            <p>
              <span>
                YOU USE THE SITE AND SERVICES AT YOUR OWN RISK. &nbsp;THE SITE
                AND SERVICES ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND
                &ldquo;AS AVAILABLE&rdquo; BASIS. &nbsp;TO THE MAXIMUM EXTENT
                PERMITTED BY APPLICABLE LAW, OUR COMPANY AND ITS OFFICERS,
                EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES,
                AFFILIATES, AGENTS, AND LICENSORS (&ldquo;
              </span>
              <b>AFFILIATES</b>
              <span>
                &rdquo;) DISCLAIM ALL WARRANTIES, CONDITIONS, AND
                REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT AND THOSE ARISING OUT OF COURSE OF DEALING OR
                USAGE OF TRADE.
              </span>
            </p>
            <p>
              <span>
                IN PARTICULAR, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                LAW, OUR COMPANY AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR
                WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT
                AVAILABLE ON OR THROUGH THE SERVICES, OR THE CONTENT OF ANY
                WEBSITES OR ONLINE SERVICES LINKED TO OR INTEGRATED WITH THE
                SERVICES. &nbsp;OUR COMPANY AND ITS AFFILIATES WILL HAVE NO
                LIABILITY FOR ANY: &nbsp;(A) ERRORS, MISTAKES, OR INACCURACIES
                OF CONTENT; (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING
                FROM YOUR ACCESS TO OR USE OF THE SERVICES; (c) ANY UNAUTHORIZED
                ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL INFORMATION
                OR USER DATA; (d) ANY INTERRUPTION OF TRANSMISSION TO OR FROM
                THE SERVICES; (e) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                WHICH MAY BE TRANSMITTED ON OR THROUGH THE SERVICES BY ANY THIRD
                PARTY; OR (f) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE
                SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
                COMPANY MAKES NO WARRANTY THAT THE SITE AND SERVICES WILL MEET
                YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE,
                OR ERROR-FREE BASIS. FURTHER, COMPANY MAKES NO WARRANTY
                REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS,
                COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE
                SERVICES.
              </span>
            </p>
            <p>
              <span>
                YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW, ANY MATERIAL OR INFORMATION DOWNLOADED OR
                OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT
                YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                DAMAGE ARISING FROM DOING SO. &nbsp;NO ADVICE OR INFORMATION,
                WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE
                SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE.
              </span>
            </p>
            <p>
              <span>
                THE DIGITAL COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS. THEY
                EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
                APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSACTION THAT MIGHT OCCUR
                IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER
                WITHIN SUCH BLOCKCHAIN NETWORK, WHICH COMPANY DOES NOT CONTROL.
                YOU UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW, WILL NOT BE RESPONSIBLE FOR ANY COMMUNICATION
                FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY
                EXPERIENCE WHEN EFFECTING DIGITAL COLLECTIBLES TRANSACTIONS, TO
                THE EXTENT CAUSED BY OR RELATED TO THIRD-PARTY TECHNOLOGY,
                PLATFORMS, SERVICES OR OTHER FUNCTIONALITY, INCLUDING WITHOUT
                LIMITATION ANY WALLETS, SMART CONTRACTS, BLOCKCHAINS OR FILE
                STORAGE SYSTEMS.
              </span>
            </p>
            <p>
              <span>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                WILL WE BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT,
                SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                (INCLUDING FOR LOSS OF PROFITS, REVENUE, OR DATA) OR FOR THE
                COST OF OBTAINING SUBSTITUTE PRODUCTS ARISING OUT OF OR IN
                CONNECTION WITH THESE TERMS, HOWEVER CAUSED, WHETHER SUCH
                LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY,
                TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND
                WHETHER OR NOT WE&rsquo;VE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. &nbsp;
              </span>
            </p>
            <p>
              <span>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR TOTAL
                CUMULATIVE LIABILITY TO YOU OR ANY THIRD PARTY UNDER THESE
                TERMS, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY,
                WILL BE LIMITED TO AND WILL NOT EXCEED THE AMOUNTS YOU HAVE
                ACTUALLY PAID US DURING THE TWELVE (12) MONTHS PRECEDING THE
                CLAIM GIVING RISE TO SUCH LIABILITY. &nbsp;
              </span>
            </p>
            <p>
              <span>
                You understand and agree that we have set our fees and entered
                into these Terms with you in reliance upon the limitations of
                liability set forth in these Terms, which allocate risk between
                us and form the basis of a bargain between the parties.
              </span>
            </p>
          </li>
          <li>
            <b>Indemnification</b>
            <p>
              <span>
                To the maximum extent permitted by applicable law, you agree to
                indemnify and hold harmless our company and its Affiliates from
                and against any and all claims, costs, proceedings, demands,
                losses, damages, and expenses (including, without limitation,
                reasonable attorney&rsquo;s fees and legal costs) of any kind or
                nature, relating to, any actual or alleged breach of these Terms
                by you or anyone using your Account or wallet. &nbsp;If we
                assume the defense of such a matter, you will reasonably
                cooperate with us in such defense.
              </span>
            </p>
          </li>
          <li>
            <b>Arbitration Agreement &amp; Waiver of Certain Rights</b>
            <p>
              <span>
                Except as set forth below, you and we agree that we will resolve
                any disputes between us (including any disputes between you and
                a third-party agent of ours) through binding and final
                arbitration instead of through court proceedings. &nbsp;You and
                we hereby waive any right to a jury trial of any Claim (defined
                below). &nbsp;All controversies, claims, counterclaims, or other
                disputes between you and us or you and a third-party agent of
                ours (each a &ldquo;
              </span>
              <b>Claim</b>
              <span>
                &rdquo;) shall be submitted for binding arbitration in
                accordance with the Rules of the American Arbitration
                Association (&ldquo;
              </span>
              <b>AAA Rules</b>
              <span>
                &rdquo;). &nbsp;The arbitration will be heard and determined by
                a single arbitrator. &nbsp;The arbitrator&#39;s decision in any
                such arbitration will be final and binding upon the parties and
                may be enforced in any court of competent jurisdiction.
                &nbsp;You and we agree that the arbitration proceedings will be
                kept confidential and that the existence of the proceeding and
                any element of it (including, without limitation, any pleadings,
                briefs or other documents submitted or exchanged and any
                testimony or other oral submissions and awards) will not be
                disclosed beyond the arbitration proceedings, except as may
                lawfully be required in judicial proceedings relating to the
                arbitration, by applicable disclosure rules and regulations of
                securities regulatory authorities or other governmental
                agencies, or as specifically permitted by state law. &nbsp;The
                Federal Arbitration Act and federal arbitration law apply to
                this agreement. &nbsp;However, the Arbitrator, and not any
                federal, state, or local court or agency, shall have the
                exclusive authority to resolve any dispute relating to the
                interpretation, applicability, enforceability, or formation of
                these Terms including, but not limited to, a claim that all or
                any part of these Terms is void or voidable.
              </span>
            </p>
            <p>
              <span>
                If you demonstrate that the costs of arbitration will be
                prohibitive as compared to the costs of litigation, we will pay
                as much of the administrative costs and arbitrator&#39;s fees
                required for the arbitration as the arbitrator deems necessary
                to prevent the cost of the arbitration from being prohibitive.
                &nbsp;In the final award, the arbitrator may apportion the costs
                of arbitration and the compensation of the arbitrator among the
                parties in such amounts as the arbitrator deems appropriate.
              </span>
            </p>
            <p>
              <span>
                This arbitration agreement does not preclude either party from
                seeking action by federal, state, or local government agencies.
                &nbsp;You and we also have the right to bring qualifying claims
                in small claims court. &nbsp;In addition, you and we retain the
                right to apply to any court of competent jurisdiction for
                provisional relief, including pre-arbitral attachments or
                preliminary injunctions, and any such request shall not be
                deemed incompatible with these Terms, nor a waiver of the right
                to have disputes submitted to arbitration as provided in these
                Terms.
              </span>
            </p>
            <p>
              <span>
                Neither you nor we may act as a class representative or private
                attorney general, nor participate as a member of a class of
                claimants, with respect to any Claim. &nbsp;Claims may not be
                arbitrated on a class or representative basis. &nbsp;The
                arbitrator can decide only your and/or our individual Claims.
                &nbsp;The arbitrator may not consolidate or join the claims of
                other persons or parties who may be similarly situated.
                &nbsp;The arbitrator may award in the arbitration the same
                damages or other relief available under applicable law,
                including injunctive and declaratory relief, as if the action
                were brought in court on an individual basis.
                &nbsp;Notwithstanding anything to the contrary in the foregoing
                or herein, the arbitrator may not issue a &ldquo;public
                injunction&rdquo; and any such &ldquo;public injunction&rdquo;
                may be awarded only by a federal or state court. &nbsp;If either
                party seeks a &ldquo;public injunction,&rdquo; all other claims
                and prayers for relief must be adjudicated in arbitration first
                and any prayer or claim for a &ldquo;public injunction&rdquo; in
                federal or state court stayed until the arbitration is
                completed, after which the federal or state court can adjudicate
                the party&rsquo;s claim or prayer for &ldquo;public injunctive
                relief.&rdquo; &nbsp;In doing so, the federal or state court is
                bound under principles of claim or issue preclusion by the
                decision of the arbitrator.
              </span>
            </p>
            <p>
              <span>
                If any provision of this Section is found to be invalid or
                unenforceable, then that specific provision shall be of no force
                and effect and shall be severed, but the remainder of this
                Section shall continue in full force and effect. &nbsp;No waiver
                of any provision of this Section of the Terms will be effective
                or enforceable unless recorded in a writing signed by the party
                waiving such a right or requirement. &nbsp;Such a waiver shall
                not waive or affect any other portion of these Terms. &nbsp;This
                Section of the Terms will survive the termination of your
                relationship with us.
              </span>
            </p>
            <p>
              <span>
                THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO
                MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO
                PARTICIPATE IN ANY FORM OF CLASS OR REPRESENTATIVE CLAIM, THE
                RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES,
                AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF.
                &nbsp;OTHER RIGHTS THAT YOU OR WE WOULD HAVE IN COURT ALSO MAY
                NOT BE AVAILABLE IN ARBITRATION.
              </span>
            </p>
          </li>
          <li>
            <b>Other Provisions</b>
            <p>
              <span>
                Under no circumstances will we be held liable for any delay or
                failure in performance due in whole or in part to any acts of
                nature or other causes beyond our reasonable control.
              </span>
            </p>
            <p>
              <span>
                These Terms will be governed by and construed in accordance with
                the laws of the State of Virginia, without giving effect to any
                conflict of laws rules or provisions. &nbsp;
              </span>
            </p>
            <p>
              <span>
                Without altering the arbitration requirement set forth above, in
                the event any action of whatever nature relating to these Terms,
                the Site, or Services must be filed in a court, we mutually
                agree that such action may only be filed in the state or federal
                courts located in Richmond, VA. &nbsp;You consent and submit to
                the personal jurisdiction of such courts for the purposes of any
                such action and agree not to challenge the personal jurisdiction
                or convenience of the forum.
              </span>
            </p>
            <p>
              <span>
                If any provision of these Terms is found to be unlawful or
                unenforceable, then that provision will be deemed severable from
                these Terms and will not affect the enforceability of any other
                provisions. &nbsp;
              </span>
            </p>
            <p>
              <span>
                The failure by us to enforce any right or provision of these
                Terms will not prevent us from enforcing such right or provision
                in the future.
              </span>
            </p>
            <p>
              <span>
                We may assign our rights and obligations under these Terms,
                including in connection with a merger, acquisition, sale of
                assets or equity, or by operation of law.
              </span>
            </p>
          </li>
          <li>
            <b>Changes to these Terms</b>
            <p>
              <span>
                From time to time, we may change these Terms. &nbsp;If we change
                these Terms, we will give you notice by posting the revised
                Terms on the Site. &nbsp;Those changes will go into effect on
                the Revision Date shown in the revised Terms. &nbsp;By
                continuing to use the Services, you are agreeing to the revised
                Terms. &nbsp;You may be required to indicate accept of the
                changed Terms to continue use of the Services.
              </span>
            </p>
            <p>
              <b>
                PLEASE PRINT A COPY OF THESE TERMS FOR YOU RECORDS AND PLEASE
                CHECK THE SITE FREQUENTLY FOR ANY CHANGES TO THESE TERMS.
              </b>
            </p>
          </li>
        </SNestedList>
      </SWrapper>
    </TermsWrapper>
  )
}
