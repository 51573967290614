export const TOAST_DISPLAY_TIME = 5000
export const TOAST_FADE_TIME = 700
export const TOAST_MAX_SHOWN_NOTIFICATIONS = 3

export const NOTIFICATION_TYPES = {
  COLLABORATOR_INVITED: 'collaborator_invited',
  COLLABORATOR_INVITATION: 'collaborator_invitation',
  COLLABORATOR_INVITATION_ACCEPTED: 'collaborator_invitation_accepted',
  COLLABORATOR_INVITATION_REJECTED: 'collaborator_invitation_rejected',
  COLLABORATOR_INVITATION_CANCELLED: 'collaborator_invitation_cancelled',
  COLLABORATOR_REMOVED: 'collaborator_removed',
  COLLABORATOR_ACCEPTED_INVITATION: 'collaborator_accepted_invitation',
  COLLABORATOR_ROLE_DESIGNATED: 'collaborator_role_designated',

  SPLIT_INVITATION: 'split_invitation',
  SPLIT_INVITATION_ACCEPTED: 'split_invitation_accepted',
  SPLIT_INVITATION_REJECTED: 'split_invitation_rejected',
  SPLIT_INVITATION_CANCELLED: 'split_invitation_cancelled',
  SPLIT_CREATED: 'split_created',
  SPLIT_APPROVED: 'split_approved',
  SPLIT_REJECTED: 'split_rejected',
  SPLIT_REMOVED: 'split_removed',
  SPLIT_ROLES_UPDATED: 'split_roles_updated',
  SPLIT_PARAMETERS_UPDATED: 'split_parameters_updated',

  CONTRIBUTOR_INVITATION: 'contributor_invitation',
  CONTRIBUTOR_INVITATION_ACCEPTED: 'contributor_invitation_accepted',
  CONTRIBUTOR_INVITATION_REJECTED: 'contributor_invitation_rejected',
  CONTRIBUTOR_INVITATION_CANCELLED: 'contributor_invitation_cancelled',
  CONTRIBUTOR_UPDATED: 'contributor_updated',

  SHARE_INVITED: 'share_invited',
  SHARE_INVITATION: 'share_invitation',
  SHARE_INVITATION_ACCEPTED: 'share_invitation_accepted',
  SHARE_INVITATION_REJECTED: 'share_invitation_rejected',
  SHARE_INVITATION_REVOKED: 'share_invitation_revoked',
  SHARE_INVITATION_CANCELLED: 'share_invitation_cancelled',
  SHARE_REJECTED_INVITATION: 'share_rejected_invitation',
  SHARE_ACCEPTED_INVITATION: 'share_accepted_invitation',
  SHARE_REVOKED: 'share_revoked',
  SHARE_LISTENED: 'share_listened',
  SHARE_EXPIRED: 'share_expired',

  FILE_CREATED: 'file_created',
  FILE_OVERWRITTEN: 'file_overwritten',
  FILE_RENAMED: 'file_renamed',
  FILE_DELETED: 'file_deleted',

  STRUCTURE_CREATED: 'structure_created',
  STRUCTURE_IMAGE_CHANGED: 'structure_image_changed',
  STRUCTURE_NAME_CHANGED: 'structure_name_changed',
  STRUCTURE_MASTER_CHANGED: 'structure_master_changed',
  STRUCTURE_METADATA_CHANGED: 'structure_metadata_changed',
  STRUCTURE_LYRICS_CHANGED: 'structure_lyrics_changed',

  MASTERED_FILE_PREVIEW_READY: 'mastered_file_preview_ready',
  MASTERED_FILE_MASTER_READY: 'mastered_file_master_ready',

  NFT_SPLIT_CREATED: 'nft_split_created',
  NFT_SPLIT_INVITATION: 'nft_split_invitation',
  NFT_SPLIT_MY_PARAMETERS_UPDATED: 'nft_split_my_parameters_updated',
  NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED:
    'nft_split_my_pending_parameters_updated',
  NFT_SPLIT_MY_PARAMETERS_APPROVED: 'nft_split_my_parameters_approved',
  NFT_SPLIT_MY_PARAMETERS_REJECTED: 'nft_split_my_parameters_rejected',
  NFT_PENDING_SPLIT_REINVITED: 'nft_pending_split_reinvited', // TODO what is this
  NFT_SPLIT_FULLY_APPROVED: 'nft_split_fully_approved',
  NFT_SPLIT_REMOVED: 'nft_split_removed',
  NFT_SOLD_OUT: 'nft_sold_out',
  NFTS_SOLD: 'nfts_sold',
  NFTS_SOLD_SINGLE: 'nfts_sold_single',
  NFT_APPROVED: 'nft_approved',
  NFT_DENIED: 'nft_denied',
}

export const ACCEPTABLE_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPES.COLLABORATOR_INVITATION,
  NOTIFICATION_TYPES.SHARE_INVITATION,
  NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION,
  NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED,
  NOTIFICATION_TYPES.NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED,
  NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED,
  'nft_split_my_parameters', // workaround
]

export const USE_TITLE_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPES.MASTERED_FILE_PREVIEW_READY,
  NOTIFICATION_TYPES.MASTERED_FILE_MASTER_READY,
  NOTIFICATION_TYPES.NFT_SPLIT_INVITATION,
  NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED,
  NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED,
  NOTIFICATION_TYPES.NFT_SPLIT_FULLY_APPROVED,
  NOTIFICATION_TYPES.NFT_SOLD_OUT,
  NOTIFICATION_TYPES.NFTS_SOLD,
  NOTIFICATION_TYPES.NFTS_SOLD_SINGLE,
  NOTIFICATION_TYPES.NFT_APPROVED,
  NOTIFICATION_TYPES.NFT_DENIED,
]

const nftSplitAcceptableConfig = {
  acceptedTypeOverride: NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_APPROVED,
  rejectedTypeOverride: NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_REJECTED,
  // Accepted
  acceptedPositive: 'View ',
  acceptedTitle: 'Accepted',
  acceptedBody: notification =>
    notification.payload?.nftName
      ? `You accepted your NFT Split on ${notification.payload?.nftName}.`
      : `You accepted your NFT Split.`,
  // Rejected
  rejectedTitle: 'NFT Split rejected',
  rejectedBody: notification =>
    notification.payload?.nftName
      ? `You rejected your NFT Split on ${notification.payload?.nftName}.`
      : `You rejected your NFT Split.`,
  // Confirmation
  confirmPositive: 'Reject NFT Split',
  confirmNegative: 'Cancel',
  confirmTitle: 'Are you sure?',
  confirmBody: notification =>
    notification.payload?.nftName
      ? `You are about to reject your NFT Split on ${notification.payload?.nftName}.`
      : `You are about to reject your NFT Split.`,
}

// TODO translations..
export const ACCEPTABLE_NOTIFICATION_CONFIGS = {
  nft_split_my_parameters: nftSplitAcceptableConfig, // workaround
  [NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED]:
    nftSplitAcceptableConfig,
  [NOTIFICATION_TYPES.NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED]:
    nftSplitAcceptableConfig,
  [NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED]: nftSplitAcceptableConfig,
  [NOTIFICATION_TYPES.COLLABORATOR_INVITATION]: {
    // Accepted
    acceptedPositive: 'Join',
    acceptedTitle: 'Congratulations!',
    acceptedBody: notification =>
      notification.payload?.structureName
        ? `You now have access to ${notification.payload.structureName} for collaboration.`
        : `You accepted the collaboration invitation.`,
    // Rejected
    rejectedTitle: 'Collaboration declined',
    rejectedBody: notification =>
      notification.payload?.structureName
        ? `You declined to collaborate on ${notification.payload.structureName}. You will not have access to this content until you are invited again.`
        : `You declined a collaboration invitation. You will not have access to this content until you are invited again.`,
    // Confirmation
    confirmPositive: 'Decline Collaboration',
    confirmNegative: 'Cancel',
    confirmTitle: 'Are you sure?',
    confirmBody: notification =>
      notification.payload?.structureName
        ? `You are about to decline to collaborate on ${notification.payload.structureName}. You will not have access to this content until you are invited again.`
        : `You are about to decline a collaboration invitation. You will not have access to this content until you are invited again.`,
  },
  [NOTIFICATION_TYPES.SHARE_INVITATION]: {
    // Accepted
    acceptedTitle: 'Congratulations!',
    acceptedPositive: 'Go to Private Shares',
    acceptedBody: `You accepted new private share.`,

    // Confirm
    confirmTitle: 'Are you sure?',
    confirmPositive: 'Decline',
    confirmNegative: 'Cancel',
    confirmBody: notification =>
      `${notification.body} You are about to decline access to this file. You will not have access to this content until you are invited again.`,

    // Rejected
    rejectedTitle: 'Private Share declined',
    rejectedBody: notification =>
      notification.payload?.structureName
        ? `You declined private access to ${notification.payload.structureName}. You will not have access to this content until you are invited again.`
        : `You declined a private share invitation. You will not have access to this content until you are invited again.`,
  },
  [NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION]: {
    // Accepted
    acceptedPositive: 'Join',
    acceptedTitle: 'Congratulations!',
    acceptedBody: notification =>
      notification.payload?.structureName
        ? `You now have access to ${notification.payload.structureName} for contribution.`
        : `You accepted the contribution invitation.`,
    // Rejected
    rejectedTitle: 'Contribution declined',
    rejectedBody: notification =>
      notification.payload?.structureName
        ? `You declined to contribute on ${notification.payload.structureName}. You will not have access to this content until you are invited again.`
        : `You declined a contribution invitation. You will not have access to this content until you are invited again.`,
    // Confirmation
    confirmPositive: 'Decline Contribution',
    confirmNegative: 'Cancel',
    confirmTitle: 'Are you sure?',
    confirmBody: notification =>
      notification.payload?.structureName
        ? `You are about to decline to contribute on ${notification.payload.structureName}. You will not have access to this content until you are invited again.`
        : `You are about to decline a contribution invitation. You will not have access to this content until you are invited again.`,
  },
}
