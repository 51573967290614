export const explicitContent = [
  {
    label: 'Explicit',
    value: 'explicit',
  },
  {
    label: 'Not Explicit',
    value: 'not-explicit',
  },
  {
    label: 'Clean',
    value: 'clean',
  },
]
