import React from 'react'
import styled, { keyframes } from 'styled-components'
import { string } from 'prop-types'

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SLoadingIndicator = styled.img`
  animation-name: ${rotateAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const LoadingIndicator = ({ className }) => {
  return (
    <SLoadingIndicator
      className={className}
      src="/assets/svg/ic-loading.svg"
      alt="Loading icon"
    />
  )
}

LoadingIndicator.propTypes = {
  className: string,
}
