import React from 'react'
import {
  NFT_STATUS,
  STATUS_LABELS,
} from '#pages/sell-collectibles/_utils/constants'
import { theme } from '@tunego/tunego-ui-components'
import {
  AUDIO_MAX_SIZE,
  WAV_EXTENSION,
  GIF_EXTENSION,
  GIF_MAX_SIZE,
  IMAGE_MAX_SIZE,
  WAV_MAX_SIZE,
} from '#pages/sell-collectibles/create-collectible/_constants/upload'
import { FILE_PREVIEW_STATUS } from '#pages/vault/song/tabs/files/utils/constants'
import { Trans } from 'react-i18next'

export const getStatusLabel = status => {
  return STATUS_LABELS[status]
}

export const getStatusColor = status => {
  const { COLORS } = theme.tunego

  switch (status) {
    case NFT_STATUS.IN_COMPLETE:
      return COLORS.neutral_70
    case NFT_STATUS.SPLITS_DENIED:
    case NFT_STATUS.REVIEW_DENIED:
    case NFT_STATUS.MINTING_ERROR:
      return COLORS.orange_50
    case NFT_STATUS.SPLITS_APPROVED:
    case NFT_STATUS.READY_FOR_MINTING:
    case NFT_STATUS.QUEUED_FOR_MINTING:
    case NFT_STATUS.MINTED:
    case NFT_STATUS.QUEUED_FOR_DROP:
      return COLORS.primary_50
    case NFT_STATUS.VERIFY_PERSONA:
      return COLORS.yellow_50
    case NFT_STATUS.LISTED_FOR_SALE:
    case NFT_STATUS.ACTIVE:
    case NFT_STATUS.COMING_SOON:
      return COLORS.success_50
    case NFT_STATUS.SOLD_OUT:
    case NFT_STATUS.EXPIRED:
      return COLORS.error_50
    default:
      return COLORS.neutral_0
  }
}

export const audioFileValidator = (file, t) => {
  const { size, name, type } = file

  if (size === 0) {
    return {
      message: t('validation.emptyFile'),
    }
  }
  if (type === WAV_EXTENSION && size > WAV_MAX_SIZE) {
    return {
      message: t('validation.wavTooLarge', { name }),
    }
  } else if (type !== WAV_EXTENSION && size > AUDIO_MAX_SIZE) {
    return {
      message: (
        <Trans
          ns="nftWizard"
          i18nKey="validation.audioTooLarge"
          values={{ name }}
        >
          <b />
        </Trans>
      ),
    }
  }

  return null
}

const isGif = type => {
  const [, extension] = type.split('/')

  return extension === GIF_EXTENSION
}

export const artworkFileValidator = (file, t) => {
  const { size, type, name } = file
  if (size === 0) {
    return {
      message: t('validation.emptyFile'),
    }
  }
  const isFileTooBig = isGif(type) ? size > GIF_MAX_SIZE : size > IMAGE_MAX_SIZE

  if (isFileTooBig) {
    return {
      message: (
        <Trans
          ns="nftWizard"
          i18nKey="validation.artworkTooLarge"
          values={{ name }}
        >
          <b />
        </Trans>
      ),
    }
  }

  return null
}

export const isFileReady = file => {
  if (!file) return false

  return (
    file.hasMetadata &&
    [FILE_PREVIEW_STATUS.DONE, FILE_PREVIEW_STATUS.ERROR].includes(
      file.previewStatus
    )
  )
}
