import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Text } from '#components/text'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '50px',
    textTransform: 'uppercase',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
  },
  leftBox: {
    background: theme.palette.color.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '130%',
    color: theme.palette.color.black,
  },
  leftBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    padding: '2rem',
    textAlign: 'center',
  },
  leftBoxDescription: {
    padding: '0 2rem',
  },
  rightBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    height: '31rem',
  },
  rightBoxImage: {
    background: 'url("/assets/images/discover-background-refined.jpg")',
    filter: 'brightness(50%)',
    width: '100%',
    backgroundSize: 'cover',
    height: '100%',
    position: 'absolute',
  },
  rightBoxContent: {
    width: '100%',
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -70%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: '1',
    padding: '2rem',
    textAlign: 'center',
  },
  rightBoxQuote: {
    width: '100%',
    fontStyle: 'italic',
    fontFamily: 'AvenirNextLTPro-Bold',
    marginBottom: '2rem',
    textAlign: 'center',
    fontSize: '1.5rem',
    whiteSpace: 'pre-line',
  },
  rightBoxAuthor: {
    width: '100%',
    textAlign: 'center',
    maxWidth: '26rem',
    whiteSpace: 'pre-line',
    fontSize: '1.3rem',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const DiscoverSection = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftBox}>
        <div className={classes.leftBoxContent}>
          <Text className={classes.header}>{t('getDiscovered')}</Text>
          <div className={classes.leftBoxDescription}>
            {t('readyToTakeYourMusic')}
          </div>
        </div>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.DISTRO_GET_STARTED)}>
          {t('getStarted')}
        </SButton>
      </div>
      <div className={classes.rightBox}>
        <div className={classes.rightBoxImage} />
        <div className={classes.rightBoxContent}>
          <span className={classes.rightBoxQuote}>
            {t('itTakesTeamworkToMake')}
          </span>
          <div className={classes.rightBoxAuthor}>{t('author')}</div>
        </div>
      </div>
    </div>
  )
}

DiscoverSection.propTypes = {
  getStartedUrl: string.isRequired,
}
