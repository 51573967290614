import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { StyleTile } from '#pages/mastering/wizard/steps/options-step/components/style-tile'
import { ALLOWED_GENRES } from '#pages/mastering/wizard/steps/options-step/utils/constants'
import { SectionTitle } from '#components/section-title'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  gridWrapper: {
    marginBottom: '5rem',
  },
  titleWrapper: {
    maxWidth: '55rem',
    margin: '0 auto',
    marginBottom: '2.5rem',
  },
  genres: {
    padding: '0 5.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    rowGap: '1.5rem',
  },
}))

const SSectionTitle = styled(SectionTitle)`
  color: ${({ theme: { colors } }) => colors.primary};
`

export const StylesGrid = () => {
  const classes = useStyles()
  const [t] = useTranslation('mastering')

  return (
    <div className={classes.gridWrapper}>
      <div className={classes.titleWrapper}>
        <SSectionTitle>{t('selectYourMasteringStyle')}</SSectionTitle>
        <p>{t('dontWorryIfYouDontLikeWhat')}</p>
      </div>
      <div className={classes.genres}>
        {ALLOWED_GENRES.map((genre, i) => (
          <div key={generateUniqueKey(genre, i)}>
            <StyleTile name={genre} />
          </div>
        ))}
      </div>
    </div>
  )
}
