import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, objectOf, number } from 'prop-types'
import { WordCloudGraph } from './WordCloudGraph'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  wordCloudGraphContainer: {
    minHeight: '400px',
    minWidth: '300px',
    margin: '0 auto',
    width: '100%',
  },
})

export const WordCloud = ({ data }) => {
  const classes = useStyles()
  return (
    <ReviewRowElement
      refName="wordCloudEl"
      title="worldCloud"
      description="theWorldCloudHighlightsTheMost"
    >
      <div className={classes.wordCloudGraphContainer}>
        <WordCloudGraph
          data={data}
          domain={[0, 102]}
          fontSizeRange={[16, 60]}
          // TODO: selecting words and filtering comments?
          // hoverEffects
          // onSelect={(word)=>{}}
        />
      </div>
    </ReviewRowElement>
  )
}

WordCloud.propTypes = {
  data: arrayOf(objectOf(number)).isRequired,
}
