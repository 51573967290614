import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { IconWithText } from '../../components/icon-with-text'
import { BOTTOM_ICONS } from '#pages/vault/landing-page/utils/constants'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.color.darkGrey12,
    minHeight: '400px',
    marginTop: '100px',
    position: 'relative',
    textAlign: 'center',
  },
  header: {
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    fontSize: '40px',
    paddingTop: '100px',
    wordSpacing: '20px',
  },
  iconGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 50,
  },
}))

export const BottomIconsSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <div className={classes.header}>{t('connectCollaborateCreate')}</div>
      <div className={classes.iconGroup}>
        {BOTTOM_ICONS.map(item => (
          <IconWithText key={item.icon} item={item} />
        ))}
      </div>
    </div>
  )
}
