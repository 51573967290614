import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Section } from '#components/section'
import { SyncLicensingIcons } from '#pages/promotion/landing-page/components/sync-licensing-icons'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled from 'styled-components'

const SSection = styled(Section)`
  padding-bottom: 200px;
  background-image: none;
`

export const SyncLicensingSection = () => {
  const { t } = useTranslation('promotion')

  return (
    <SSection
      header={t('syncLicensing')}
      getStartedUrl={NAVIGATION_PATHS.PROMOTION_NEW}
      subheaderComponent={
        <>
          <p style={{ textAlign: 'left' }}>{t('wePartneredWithTheLargest')}</p>
          <SyncLicensingIcons />
        </>
      }
      isAlignedToLeft
    />
  )
}

SyncLicensingSection.propTypes = {
  redirectLink: string.isRequired,
}
