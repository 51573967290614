import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { string } from 'prop-types'

const useStyles = makeStyles(theme => ({
  mainHeader: {
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
  },
  subHeader: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  wrapper: {
    margin: '1.5rem',
  },
}))

export const IconSection = ({
  iconToDisplay,
  mainHeaderText,
  subHeaderText,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <img src={iconToDisplay} alt="" />
      <div className={classes.mainHeader}>{mainHeaderText}</div>
      <div className={classes.subHeader}>{subHeaderText}</div>
    </div>
  )
}

IconSection.propTypes = {
  iconToDisplay: string.isRequired,
  mainHeaderText: string.isRequired,
  subHeaderText: string.isRequired,
}
