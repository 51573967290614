import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class SharesApi {
  static addExistingUserShare = (structureId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SHARES, { structureId }),
      data
    )

  static inviteOffPlatformUserShare = (structureId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SHARES_INVITE, { structureId }),
      data
    )

  static deleteShare = shareId =>
    getApiClient().delete(buildUrl(ENDPOINTS.SHARES_SHARE, { shareId }))

  static getShare = shareId =>
    getApiClient().get(buildUrl(ENDPOINTS.SHARES_SHARE, { shareId }))

  static accessShare = shareId =>
    getApiClient().get(buildUrl(ENDPOINTS.SHARES_SHARE_ACCESS, { shareId }))

  static acceptShare = shareId =>
    getApiClient().post(buildUrl(ENDPOINTS.SHARES_SHARE_ACCEPT, { shareId }))

  static revokeShare = shareId =>
    getApiClient().post(buildUrl(ENDPOINTS.SHARES_SHARE_REVOKE, { shareId }))
}
