import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { mutate } from 'swr'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { func, string, bool, number } from 'prop-types'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { BaseInput } from '#components/inputs/base-input'
import { ENDPOINTS } from '#api/endpoints'
import { ArtistApi } from '#api/requests/artist'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { nameValidationSchema } from '#pages/vault/wizard/utils/validations'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { useError } from '#hooks/useError'
import { TOOLTIP_THEME } from '#components/tooltip/tooltipStyle'
import { Tooltip } from '#components/tooltip'
import { useStructureNftFiles } from '#hooks/swr/useArtists'
import { useFlags } from 'launchdarkly-react-client-sdk'

const getRowBackgroundColor = ({ isEven, isNested, isSelected }) => {
  if (isSelected) return 'primary_25%'

  if (isNested) return isEven ? 'secondary_70' : 'secondary_80'

  return isEven ? 'neutral_95' : 'neutral_100'
}

const SEditContainer = styled.form`
  position: relative;
  padding: ${({ isNested }) =>
    `1rem 1.5rem 0.25rem ${isNested ? '3.125rem' : '1rem'}`};
  height: 90px;
  display: flex;
  align-items: center;
  background-color: ${({
    isNested,
    isSelected,
    index,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    COLORS[
      getRowBackgroundColor({ isNested, isSelected, isEven: index % 2 === 0 })
    ]};
`

const SButtons = styled.div`
  display: flex;
  margin: 0 0 0.5rem 0.5rem;
  padding-left: 0;
  gap: 0.5rem;
  min-width: 30rem;
`

const SLine = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
  width: 1px;
  position: absolute;
  left: 1.5rem;
  bottom: 0;
  top: 0;
  height: ${({ isLastRow }) => (isLastRow ? '50%' : '100%')};
`

const SNameInputColumn = styled.div`
  padding-right: 0.5rem;
  flex: 1;
  display: flex;
  align-items: center;
`

const SNameInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 19px;
  width: 100%;
`

const STooltip = styled(Tooltip)`
  min-width: 10rem;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

export const EditRow = ({
  name,
  id,
  index,
  category,
  handleCloseInlineAction,
  shouldEnableDeletion,
  handleDeleteModalOpen,
  parentId,
  isNested,
  isSelected,
  isLastRow,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const { error, setResponseError, clearError, setError } = useError()
  const { data, isLoading } = useStructureNftFiles(id)

  const disableDelete = data?.count > 0

  const structureName = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('songName'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('albumName'),
  }
  const { useAltStructuresEndpoint } = useFlags()

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty },
  } = useForm({
    resolver: yupResolver(nameValidationSchema(structureName[category])),
  })

  const { actions, loading, errors } = useWithAsyncAction({
    patchArtistProfile: ArtistApi.patchArtistProfile,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.patchArtistProfile) {
      handleSetError(errors.patchArtistProfile)
    }
  }, [errors, handleSetError])

  const handleEditInline = async data => {
    try {
      await actions.patchArtistProfile(id, { name: data.name })

      const endpoint = useAltStructuresEndpoint
        ? ENDPOINTS.STRUCTURES_ALT
        : ENDPOINTS.STRUCTURES

      const swrKey = isNested
        ? `${endpoint}?parentId=${parentId}&withoutPagination=true&sortDirection=ASC`
        : `${endpoint}?parentId=${parentId}`

      mutate(swrKey)
      handleCloseInlineAction()
    } catch (err) {
      setResponseError(err)
    }
  }

  const deleteButtonMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('deleteSong'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('deleteAlbum'),
  }

  const loadingMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('songIsBeingUpdated'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('albumIsBeingUpdated'),
  }

  const placeholderMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('newSong'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('newAlbum'),
  }

  const cannotDeleteMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('cannotDeleteSingle'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('cannotDeleteAlbum'),
  }

  return (
    <SEditContainer
      index={index}
      isSelected={isSelected}
      isNested={isNested}
      onSubmit={handleSubmit(handleEditInline)}
    >
      {isNested && <SLine isLastRow={isLastRow} />}
      <SNameInputColumn>
        <img
          src={`/assets/svg/${
            isSelected || isNested
              ? `ic-${category}-white.svg`
              : `ic-${category}-blue.svg`
          }`}
          width="20px"
          style={{ paddingBottom: '8px' }}
          alt=""
        />
        <SNameInput>
          <SBaseInput
            name="name"
            inputRef={register}
            defaultValue={name}
            placeholder={placeholderMessage[category]}
            errorMessage={formErrors?.name?.message}
            customMarginBottom={0}
            customBg="black"
          />
          <SButtons>
            <SButton type="submit" disabled={!isDirty}>
              {t('save')}
            </SButton>
            <SButton
              variant={VARIANTS.CANCEL_BLACK}
              onClick={handleCloseInlineAction}
            >
              {t('cancel')}
            </SButton>

            {shouldEnableDeletion && (
              <STooltip
                disabled={!disableDelete}
                text={cannotDeleteMessage[category]}
                theme={TOOLTIP_THEME.WARNING}
              >
                <SButton
                  disabled={disableDelete || isLoading}
                  variant={VARIANTS.WARNING}
                  onClick={() => handleDeleteModalOpen(category, id, parentId)}
                >
                  {deleteButtonMessage[category]}
                </SButton>
              </STooltip>
            )}
          </SButtons>
        </SNameInput>
      </SNameInputColumn>
      <CommonLoadingOverlay
        message={loadingMessage[category]}
        dialogOpen={loading.patchArtistProfile}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </SEditContainer>
  )
}

EditRow.propTypes = {
  shouldEnableDeletion: bool.isRequired,
  handleDeleteModalOpen: func.isRequired,
  handleCloseInlineAction: func,
  name: string.isRequired,
  id: string.isRequired,
  index: number.isRequired,
  parentId: string.isRequired,
  category: string.isRequired,
  isNested: bool,
  isLastRow: bool,
  isSelected: bool,
}

EditRow.defaultProps = {
  handleCloseInlineAction: () => {},
  isNested: false,
  isLastRow: false,
  isSelected: false,
}
