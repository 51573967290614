import React from 'react'
import { node } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  wrapper: {
    maxWidth: 1050,
    minHeight: '100vh',
    margin: '0 auto',
    borderLeft: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
  },
}))

export const ColumnWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.wrapper}>{children}</div>
}

ColumnWrapper.propTypes = {
  children: node.isRequired,
}
