import React from 'react'
import { bool, func, node, string } from 'prop-types'
import styled, { css } from 'styled-components'

const SRow = styled.div`
  height: 3.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_5%']};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &::after {
    content: '';
    width: 1px;
    position: absolute;
    left: ${({ nested }) => (nested ? '4.5rem' : '2.25rem')};
    bottom: 0;
    height: 100%;
    top: 0;
    background-color: ${({ theme: { colors } }) => colors.default};
  }

  ${({
    isEven,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    isEven &&
    css`
      background: ${COLORS['primary_10%']};
    `}

  ${({ nested }) =>
    nested &&
    css`
      padding-left: 2.5rem;
    `}

  ${({ isLastRow }) =>
    isLastRow &&
    css`
      &::after {
        height: 50%;
      }
    `}

  ${({ isFileAlreadySelected }) =>
    isFileAlreadySelected &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const RowWithLine = ({
  children,
  isLastRow,
  isEven,
  onClick,
  nested,
  isFileAlreadySelected,
  className,
  disabled,
}) => {
  return (
    <SRow
      isEven={isEven}
      isLastRow={isLastRow}
      nested={nested}
      isFileAlreadySelected={isFileAlreadySelected}
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </SRow>
  )
}

RowWithLine.propTypes = {
  isEven: bool.isRequired,
  isLastRow: bool.isRequired,
  children: node.isRequired,
  onClick: func,
  nested: bool,
  isFileAlreadySelected: bool,
  className: string,
  disabled: bool,
}

RowWithLine.defaultProps = {
  onClick: null,
  nested: false,
  isFileAlreadySelected: false,
}
