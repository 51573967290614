import React from 'react'
import { element, func, oneOfType } from 'prop-types'
import { useIsAuth } from '#hooks/useIsAuth'
import { LoginRequired } from '#pages/home/login-required'

export const ProtectedRoute = ({ component: Component, ...props }) => {
  const isAuth = useIsAuth()
  if (!isAuth) {
    return <LoginRequired />
  }
  return <Component {...props} />
}

ProtectedRoute.propTypes = {
  component: oneOfType([element, func]).isRequired,
}
