import React, { useState, useEffect } from 'react'
import { string, func, number, bool } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  useArtistStructure,
  useArtistStructureContent,
} from '#hooks/swr/useArtists'
import { Pagination } from '#components/pagination'
import { ProfileCheckbox } from '#pages/vault/profile-content/components/profile-checkbox'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import { FolderRow } from '#pages/vault/profile-content/components/folder-row'
import { BaseLoader } from '#components/loaders/base-loader'
import { useReload } from '#modules/page-wrapper/context'

const SContainer = styled.div`
  text-align: center;
  margin: auto;
  padding-top: 1.25rem;
  max-width: min(565px, 100%);
`

const STitle = styled.div`
  text-align: left;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin-bottom: 5px;
  text-transform: uppercase;
`

const SFolderWrapper = styled.div`
  min-height: 15.625rem;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
  width: 100%;
  max-height: calc(100vh - 20rem);
  overflow: auto;
`

const SNoFolders = styled.div`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['4xl']};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 50%;
`

export const AlbumOrProfileContainer = ({
  parentId,
  selectedId,
  setSelectedId,
  albumParentId,
  isProjectInFolder,
  setHasFolders,
}) => {
  const { t } = useTranslation('vault')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { project } = useArtistStructure(parentId)
  const query = `&page=${page}&limit=${rowsPerPage}&category=${STRUCTURE_TYPE.ALBUM}`
  const { artistContent, isLoading, mutate } = useArtistStructureContent(
    parentId,
    query
  )
  const albums = artistContent?.data

  useEffect(() => {
    setHasFolders(!isArrayEmpty(albums))
  }, [albums])

  const { shouldReload } = useReload()

  useEffect(() => {
    if (shouldReload) mutate()
  }, [shouldReload])

  const filteredFolders =
    albumParentId && albums
      ? albums.filter(folder => folder.id !== albumParentId)
      : albums

  const onChangeRowsPerPage = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPage(0)
  }

  if (isLoading) {
    return <BaseLoader text="Loading..." isLoading={isLoading} />
  }

  return (
    <SContainer>
      {isArrayEmpty(albums) ? (
        <SNoFolders>{t('youHaventCreatedAnyAlbums')}</SNoFolders>
      ) : (
        <div>
          <STitle>{t('whichPackDoYouWantToMove')}</STitle>
          <SFolderWrapper>
            {isProjectInFolder && (
              <ProfileCheckbox
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                project={project}
              />
            )}
            {filteredFolders.map(({ id, name }) => (
              <FolderRow
                key={id}
                {...{
                  selectedId,
                  setSelectedId,
                  id,
                  name,
                }}
              />
            ))}
          </SFolderWrapper>
        </div>
      )}

      <Pagination
        count={artistContent?.count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={setPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </SContainer>
  )
}

AlbumOrProfileContainer.defaultProps = {
  albumParentId: '',
}

AlbumOrProfileContainer.propTypes = {
  parentId: string.isRequired,
  selectedId: number.isRequired,
  setSelectedId: func.isRequired,
  albumParentId: string,
  isProjectInFolder: bool.isRequired,
  setHasFolders: bool.isRequired,
}
