import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  header: {
    borderBottom: `2px solid ${theme.palette.color.primary}`,
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#1a1a1a',
  },
  tunegoLogo: {
    width: '114px',
    zIndex: 1,
  },
}))

export const OnboardingHeader = () => {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      <img
        alt="TuneGO"
        src="/assets/svg/tune-go-logo.svg"
        className={classes.tunegoLogo}
      />
    </div>
  )
}
