import React, { useEffect, useState } from 'react'
import { string, func, bool, arrayOf } from 'prop-types'
import { useUploadManager } from '#modules/upload-manager/context'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Modal,
} from '@tunego/tunego-ui-components'
import styled from 'styled-components'
import { UploadModalContent } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/upload-modal-content'
import {
  MODAL_HEADERS,
  UPLOAD_STATUS,
} from '#pages/sell-collectibles/create-collectible/_components/steps/files/_constants'

const SModal = styled(Modal)`
  width: 34rem;
  h2 {
    margin: 0;
  }
`

const SHeader = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    warning,
  }) => (warning ? COLORS.orange_50 : COLORS.primary_50)};
  font-family: inherit;
`

const SContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  max-width: 100%;
`

export const UploadFile = ({ parentId, hasPersonaSingle, ...props }) => {
  const { t } = useTranslation('nftWizard')
  const [isOpen, setIsOpen] = useState(false)
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.SELECT_FILE)
  const [files, setFiles] = useState([])
  const [uploadedFileId, setUploadedFileId] = useState(null)

  const {
    clearValidationError,
    clearFileUploadError,
    isOverwriteFilesModalOpen,
    setIsOverwriteFilesModalOpen,
    isUploading,
    clearAlreadyExistingFiles,
  } = useUploadManager()

  useEffect(() => {
    const status = isOverwriteFilesModalOpen
      ? UPLOAD_STATUS.FILE_ALREADY_EXISTS
      : isUploading || uploadedFileId
      ? UPLOAD_STATUS.PROCESSING_IN_PROGRESS
      : null

    if (status) setUploadStatus(status)
  }, [isUploading, uploadedFileId, isOverwriteFilesModalOpen])

  const onClose = () => {
    clearFileUploadError()
    clearValidationError()
    setFiles([])
    setIsOverwriteFilesModalOpen(false)
    setUploadStatus(UPLOAD_STATUS.SELECT_FILE)
    setUploadedFileId(null)
    setIsOpen(false)
    clearAlreadyExistingFiles()
  }

  return (
    <>
      <Button
        buttonTheme={ButtonTheme.Secondary}
        buttonSize={ButtonSize.Sm}
        onClick={() => setIsOpen(true)}
      >
        {t('steps.files.uploadFile')}
      </Button>
      <SModal
        isOpen={isOpen}
        closeButton={uploadStatus !== UPLOAD_STATUS.PROCESSING_IN_PROGRESS}
        title={
          <SHeader warning={uploadStatus === UPLOAD_STATUS.FILE_ALREADY_EXISTS}>
            {MODAL_HEADERS(t)[uploadStatus]}
          </SHeader>
        }
        onClose={onClose}
      >
        <SContentWrapper>
          {hasPersonaSingle ? (
            <UploadModalContent
              parentId={parentId}
              close={() => setIsOpen(false)}
              uploadStatus={uploadStatus}
              setUploadStatus={setUploadStatus}
              files={files}
              setFiles={setFiles}
              uploadedFileId={uploadedFileId}
              setUploadedFileId={setUploadedFileId}
              {...props}
            />
          ) : (
            <>{t('steps.files.noSingleError')}</>
          )}
        </SContentWrapper>
      </SModal>
    </>
  )
}

UploadFile.propTypes = {
  parentId: string.isRequired,
  children: func,
  onUpload: func,
  accept: arrayOf(string),
  validator: func,
  hasPersonaSingle: bool,
  onFileSelect: func,
  isImage: bool,
}
