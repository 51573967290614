import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { Artwork } from '#pages/distro/wizard/steps/release-details/components/artwork'
import { ArtworkRequirements } from '#pages/distro/wizard/steps/release-details/components/artwork-requirements'
import { Confirmations } from '#pages/distro/wizard/steps/release-details/components/confirmation'
import { CustomSelect } from '#components/inputs/custom-select'
import { FIELDS, CONTENT_TYPE_OPTIONS } from '#pages/distro/_utils/constants'
import { ContentWrapper } from '#pages/distro/wizard/steps/delivery-and-release-date/components/content-wrapper'
import { Label } from '#components/label'
import { useWizard } from '#modules/forms/form-wizard/context'

const useStyles = makeStyles(() => ({
  confirmationWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
  },
  explicitContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
}))

const SCustomSelect = styled(CustomSelect)`
  width: 220px;
`

export const ArtworkAndConfirmations = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const distroTranslation = useTranslation('distro')
  const { storedFormState } = useWizard()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const errorMessage =
    errors[FIELDS.ARTWORK_EXPLICIT]?.message ||
    errors[FIELDS.ARTWORK_EXPLICIT]?.value?.message

  const translatedOptions = () =>
    CONTENT_TYPE_OPTIONS.map(option => ({
      label: t(option.label),
      value: option.value,
    }))

  return (
    <ContentWrapper wrap={false}>
      <Artwork />
      <div className={classes.confirmationWrapper}>
        <div className={classes.container}>
          <ArtworkRequirements />
          <Confirmations />
        </div>
        <div className={classes.explicitContent}>
          <Label
            label={distroTranslation.t('doesTheArtworkShowSexDrugsOr')}
            name={FIELDS.ARTWORK_EXPLICIT}
            required
            isBolded
          />
          <Controller
            key={FIELDS.ARTWORK_EXPLICIT}
            name={FIELDS.ARTWORK_EXPLICIT}
            control={control}
            defaultValue={storedFormState[FIELDS.ARTWORK_EXPLICIT]}
            render={({ field: { onChange, value } }) => {
              return (
                <SCustomSelect
                  options={translatedOptions()}
                  value={value}
                  errorMessage={errorMessage}
                  isBolded
                  required
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}
