import React, { useCallback, useEffect, useState } from 'react'
import { func, bool, string, arrayOf, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, makeStyles } from '@material-ui/core'
import { ContributorItem } from '#pages/distro/wizard/steps/add-song-files/components/contributor-item'
import { RequiredContributorRole } from '#pages/distro/wizard/steps/add-song-files/components/required-contributor-role'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ContributorsApi } from '#api/requests/contributors'
import {
  CONTRIBUTOR_ACTION_TYPES,
  CONTRIBUTOR_STATUS,
  MENU_OPTIONS,
} from '#pages/vault/song/tabs/metadata/utils/constants'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { ROLES } from '#constants/roles'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  acceptBtn: {
    backgroundImage: 'url("/assets/images/edit-icon.png")',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 18,
  },
}))

export const ContributorsList = ({
  contributors,
  openContributorModal,
  openEditContributorModal,
  setDefaultRole,
  mutateDistro,
  songId,
  hasComposer,
  hasLyricist,
  isOwner,
}) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const [error, setError] = useState('')
  const { setStoredFormState, storedFormState } = useWizard()

  const { actions, errors } = useWithAsyncAction({
    deleteContributor: ContributorsApi.deleteContributor,
    postReInviteContributor: ContributorsApi.postReInviteContributor,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.deleteContributor) {
      handleSetError(errors.deleteContributor)
    }
    if (errors.postReInviteContributor) {
      handleSetError(errors.postReInviteContributor)
    }
  }, [errors, handleSetError])

  const handleResendInvite = async id => {
    await actions.postReInviteContributor(songId, id)
    mutateDistro()
  }

  const handleCancelInvite = async (id, userId) => {
    await actions.deleteContributor(songId, id)

    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === songId
          ? {
              ...file,
              contributors: file.contributors.filter(
                contributor => contributor.userId !== userId
              ),
            }
          : { ...file }
      ),
    })

    mutateDistro()
  }

  const determineMenuAction = (actionType, id, userId) => {
    switch (actionType) {
      case CONTRIBUTOR_ACTION_TYPES.resendInvite: {
        return handleResendInvite(id)
      }
      case CONTRIBUTOR_ACTION_TYPES.cancelInvite: {
        return handleCancelInvite(id, userId)
      }
      default: {
        return null
      }
    }
  }

  const renderEllipsisMenu = (status, id, userId) => {
    switch (status) {
      case CONTRIBUTOR_STATUS.PENDING:
        return (
          <EllipsisMenu
            options={MENU_OPTIONS}
            onChoose={actionType => {
              determineMenuAction(actionType, id, userId)
            }}
          />
        )
      case CONTRIBUTOR_STATUS.ACCEPTED:
        return (
          <Button
            className={classes.acceptBtn}
            onClick={() => openEditContributorModal(id)}
          />
        )
      default:
        return null
    }
  }

  const displayRoles = roles =>
    roles
      .map(item => ROLES.filter(role => role.value === item))
      .flat()
      .map(({ label }) => label)
      .join(', ')

  const displayContributors = contributors?.map((contributor, index) => (
    <ContributorItem
      key={generateUniqueKey(contributor, index)}
      contributor={contributor}
      {...(isOwner ? { renderEllipsisMenu } : null)}
      displayRoles={displayRoles}
      index={index}
    />
  ))

  if (error) {
    return (
      <CommonLoadingOverlay
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return (
    <>
      {!hasComposer && (
        <RequiredContributorRole
          role={t('composer')}
          buttonLabel={t('addComposer')}
          handleOpenContributorModal={openContributorModal}
          setDefaultRole={setDefaultRole}
        />
      )}
      {!hasLyricist && (
        <RequiredContributorRole
          role={t('lyricist')}
          buttonLabel={t('addLyricist')}
          handleOpenContributorModal={openContributorModal}
          setDefaultRole={setDefaultRole}
          isCustom
        />
      )}
      {displayContributors}
    </>
  )
}

ContributorsList.propTypes = {
  contributors: arrayOf(shape({})).isRequired,
  openContributorModal: func.isRequired,
  openEditContributorModal: func.isRequired,
  mutateDistro: func.isRequired,
  songId: string.isRequired,
  setDefaultRole: func.isRequired,
  hasComposer: bool.isRequired,
  hasLyricist: bool.isRequired,
  isOwner: bool.isRequired,
}
