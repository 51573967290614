import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class NotificationsApi {
  static markAsRead = (id, data) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.NOTIFICATIONS_NOTIFICATION_READ, {
        id,
      }),
      data
    )

  static markAllAsRead = () =>
    getApiClient().patch(ENDPOINTS.NOTIFICATIONS_READ_ALL)

  static getNotifications = (query = '') =>
    getApiClient().get(`${ENDPOINTS.NOTIFICATIONS}${query}`)

  static getSingleNotification = notificationId =>
    getApiClient().get(
      buildUrl(ENDPOINTS.NOTIFICATIONS_NOTIFICATION, {
        notificationId,
      })
    )

  static sendAppInvite = phoneNumber =>
    getApiClient().post(ENDPOINTS.NOTIFICATIONS_APP_INVITE, {
      phoneNumber,
    })
}
