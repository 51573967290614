import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`

export const BackgroundBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const Background = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
`

export const VideoContainer = styled.div`
  opacity: 25%;
`

export const Video = styled.video`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  display: block;
  transform: translate(-50%, -50%);
`

export const Particles = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`

export const BackgroundContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-x: auto;
  flex-grow: 1;
  position: relative;
  top: 60px;
`

export const BackgroundContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 300px;
  z-index: 15;
`

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const Title = styled.div`
  font-family: 'AvenirNextLTPro-Bold';
  text-transform: uppercase;
  line-height: 100%;
  font-size: 40px;
  @media (max-width: 800px) {
    font-size: 32px;
  }
  @media (max-width: 599px) {
    font-size: 26px;
  }
`

export const SubTitle = styled.div`
  margin-bottom: 30px;
  margin-inline: auto;
  white-space: pre-line;
  text-align: center;
  text-transform: uppercase;
  maxwidth: fit-content;
  font-size: 18px;
  @media (max-width: 800px) {
    font-size: 14px;
  }
  @media (max-width: 599px) {
    font-size: 12px;
  }
`

export const LogoImage = styled.img`
  margin-bottom: 20px;
  width: 280px;
`

export const ButtonSection = styled.div`
  display: ${props => (props.isInLimitedMaintenance ? 'none' : 'flex')};
  justify-content: center;
`
