import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { string, bool, func } from 'prop-types'
import { Button } from '#components/button'
import { ROLES } from '#constants/roles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.color.darkBlue,
    height: 60,
    alignItems: 'center',
    paddingLeft: 30,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  wrapper: {
    display: 'flex',
  },
  custom: {
    backgroundColor: theme.palette.color.lighterBlue,
  },
  requiredLabel: {
    alignSelf: 'center',
  },
}))

const SButton = styled(Button)`
  width: 180px;
  height: 30px;
  margin-right: 25px;
`

export const RequiredContributorRole = ({
  buttonLabel,
  role,
  isCustom,
  handleOpenContributorModal,
  setDefaultRole,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  const handleModalOpen = () => {
    if (role === 'Composer') {
      setDefaultRole(ROLES.find(({ value }) => value === 'composer'))
    } else {
      setDefaultRole(ROLES.find(({ value }) => value === 'lyricist'))
    }
    handleOpenContributorModal()
  }

  return (
    <div className={`${classes.container} ${isCustom && classes.custom}`}>
      <div className={classes.wrapper}>
        <SButton onClick={handleModalOpen}>{`+ ${buttonLabel}`}</SButton>
        <span className={classes.requiredLabel}>({t('required')})</span>
      </div>
      <div>
        <span>{role}</span>
      </div>
    </div>
  )
}

RequiredContributorRole.propTypes = {
  buttonLabel: string.isRequired,
  role: string.isRequired,
  handleOpenContributorModal: func.isRequired,
  setDefaultRole: func.isRequired,
  isCustom: bool,
}

RequiredContributorRole.defaultProps = {
  isCustom: false,
}
