import React from 'react'
import { TableHeader } from '#pages/vault/song/tabs/files/components/table-header'
import { TABLE_HEADER } from '#pages/vault/song/tabs/files/utils/constants'
import { FilesListItem } from '#pages/vault/song/tabs/files/components/file-list-item'
import { Pagination } from '#components/pagination'
import { useUploadManager } from '#modules/upload-manager/context'

export const FilesList = ({
  files,
  setShouldSortAscending,
  sortBy,
  setSortBy,
  setPageIndex,
  shouldSortAscending,
  projectId,
  masterId,
  actions,
  accessRoles,
  handleListen,
  handlePreview,
  handlePrivateShare,
  setShouldRefreshData,
  checkedFiles,
  setCheckedFiles,
  count,
  pageIndex,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const { uploadingFilesInProgress } = useUploadManager()

  const onChangeRowsPerPageHandler = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPageIndex(0)
  }

  return (
    <>
      <TableHeader
        {...{
          setShouldSortAscending,
          sortBy,
          setSortBy,
          setPageIndex,
          shouldSortAscending,
        }}
        headerItems={TABLE_HEADER}
        isUploadInProgress={uploadingFilesInProgress.length > 0}
      />
      {files?.map((file, index) => (
        <FilesListItem
          key={file.id}
          file={file}
          projectId={projectId}
          masterId={masterId}
          actions={actions}
          accessRoles={accessRoles}
          handleListen={handleListen}
          handlePreview={handlePreview}
          handlePrivateShare={handlePrivateShare}
          setShouldRefreshData={setShouldRefreshData}
          index={index}
          checkedFiles={checkedFiles}
          setCheckedFiles={setCheckedFiles}
        />
      ))}
      <Pagination
        count={count}
        page={pageIndex}
        rowsPerPage={rowsPerPage}
        onChangePage={setPageIndex}
        onChangeRowsPerPage={onChangeRowsPerPageHandler}
      />
    </>
  )
}
