import React from 'react'
import { func, bool, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'
import { BOTTOM_PANEL_HEIGHT } from '#pages/sell-collectibles/create-collectible/_constants/ui'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'

const SBottomBar = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_95};
  border-top: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  height: ${BOTTOM_PANEL_HEIGHT}px;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  z-index: 1000;
`

const SInnerWrapper = styled.div`
  height: 100%;
  max-width: 75rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: ${({ backEnabled }) =>
    backEnabled ? 'space-between' : 'flex-end'};
`
export const BottomBar = ({
  disabled,
  disabledBack,
  nextBtnText,
  onNextClick,
  hideNextBtn,
}) => {
  const { t } = useTranslation('nftWizard')
  const { activeStep, setActiveStep, readOnly, completedStep } = useNftCreator()

  const backEnabled = activeStep > 0

  return (
    <SBottomBar>
      <SInnerWrapper backEnabled={backEnabled}>
        {backEnabled && (
          <Button
            buttonTheme={ButtonTheme.Secondary}
            disabled={disabledBack}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            {t('back')}
          </Button>
        )}
        {!hideNextBtn && (
          <Button
            disabled={readOnly ? completedStep === activeStep - 1 : disabled}
            onClick={onNextClick}
          >
            {nextBtnText || t('next')}
          </Button>
        )}
      </SInnerWrapper>
    </SBottomBar>
  )
}

BottomBar.propTypes = {
  onNextClick: func.isRequired,
  nextBtnText: string,
  disabled: bool,
  disabledBack: bool,
  hideNextBtn: bool,
}
