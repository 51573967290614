import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { bool, func, shape } from 'prop-types'
import { BaseInput } from '#components/inputs/base-input'
import { collectiblesMetadataFields } from '#pages/vault/song/tabs/metadata/utils/constants'
import { TabWrapper } from '#pages/vault/song/tabs/metadata/components/tab-wrapper'
import { TextArea } from '#components/inputs/textarea'
import { isFormDirty } from '#pages/vault/song/tabs/metadata/utils/helpers'

const SBaseInput = styled(BaseInput)`
  width: 100%;
  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    width: 18.75rem;
    height: 6.25rem;
  }
`

const STextArea = styled(TextArea)`
  height: 22.5rem;

  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    width: 18.75rem;
  }
`

const STabWrapper = styled(TabWrapper)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 6rem;

  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    height: 35rem;
    grid-row-gap: 0;
    padding-bottom: 0;
  }
`

export const CollectiblesMetadata = ({
  isLoading,
  setHasUnsavedChanges,
  formattedFields,
}) => {
  const { t } = useTranslation('vault')

  const {
    register,
    control,
    getValues,
    watch,
    formState: { errors, isDirty: isCurrentFormDirty, dirtyFields },
  } = useFormContext()
  const changes = watch()

  const isCollectibleFormDirty = () => {
    return isFormDirty({
      dirtyFields,
      formattedFields,
      values: getValues(),
      getter: value => value.collectible,
    })
  }

  useEffect(() => {
    setHasUnsavedChanges(isCollectibleFormDirty())
  }, [isCurrentFormDirty, setHasUnsavedChanges, changes])

  const getDefaultValue = key => {
    if (key === 'collectible.artist')
      return formattedFields.parentsPath?.[0]?.name

    return _.get(formattedFields, key)
  }

  return (
    <STabWrapper isLoading={isLoading}>
      {collectiblesMetadataFields.map(
        ({ name, type, isReadOnly, mask, defaultValueKey, hint }) => {
          switch (type) {
            case 'textarea':
              return (
                <STextArea
                  key={name}
                  inputRef={register}
                  name={`collectible.${name}`}
                  label={t(`${name}`)}
                  errorMessage={errors.collectible?.[`${name}`]?.message}
                  defaultValue={getDefaultValue(defaultValueKey)}
                />
              )

            default:
              return (
                <SBaseInput
                  key={name}
                  isReadOnly={isReadOnly}
                  disabled={isReadOnly}
                  inputRef={register}
                  name={`collectible.${name}`}
                  label={t(`${name}`)}
                  errorMessage={errors.collectible?.[`${name}`]?.message}
                  mask={mask}
                  control={control}
                  hint={t(hint)}
                  defaultValue={getDefaultValue(defaultValueKey)}
                />
              )
          }
        }
      )}
    </STabWrapper>
  )
}

CollectiblesMetadata.propTypes = {
  isLoading: bool.isRequired,
  setHasUnsavedChanges: func.isRequired,
  formattedFields: shape({}).isRequired,
}
