import React from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconWrapper: {
    marginTop: '0.2rem',
  },
})

const SButton = styled(Button)`
  border: none;
  color: ${({ isReadyForDistribution }) =>
    isReadyForDistribution
      ? ({ theme: { colors } }) => colors.primary
      : ({ theme: { colors } }) => colors.warning};
  opacity: 1;
`

export const MetadataInfo = ({ onAddMetaData, isReadyForDistribution }) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  const text = isReadyForDistribution
    ? t('readyForDistribution')
    : t('addMetadata')

  return (
    <div className={classes.wrapper}>
      <SButton variant={VARIANTS.TRANSPARENT} onClick={onAddMetaData}>
        {text}
      </SButton>
      <div className={classes.iconWrapper}>
        <img
          src={`/assets/svg/ic-${
            isReadyForDistribution ? 'check-blue' : 'slash'
          }.svg`}
          alt=""
          width="25px"
        />
      </div>
    </div>
  )
}

MetadataInfo.propTypes = {
  onAddMetaData: func.isRequired,
  isReadyForDistribution: bool.isRequired,
}
