import React from 'react'
import styled from 'styled-components'
import { node, number, string } from 'prop-types'

const SBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const SBadge = styled.div`
  color: ${({ theme: { colors } }) => colors.primary};
  background-color: ${({ theme: { colors } }) => colors.default};
  border: 2px solid ${({ theme: { colors } }) => colors.darkGrey};
  font-size: 12px;
  font-weight: 700;
  padding: 0 4px;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  right: -20px;
`

export const Badge = ({ text, children, className }) => {
  const textToNumber = isNaN(text) ? parseInt(text, 10) : text
  const formattedText = textToNumber >= 100 ? '99+' : textToNumber

  return (
    <SBadgeWrapper>
      {children}
      {textToNumber > 0 ? (
        <SBadge className={className}>{formattedText}</SBadge>
      ) : null}
    </SBadgeWrapper>
  )
}

Badge.propTypes = {
  text: number.isRequired,
  children: node.isRequired,
  className: string,
}
