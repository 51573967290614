import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  logoWrapper: {
    marginTop: '2.5rem',
  },
}))

export const Logo = () => {
  const styles = useStyles()

  return (
    <div className={styles.logoWrapper}>
      <img src="/assets/svg/ic-connect-large.svg" width="250" alt="Connect" />
    </div>
  )
}
