import React from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'
import { DateInput } from '#components/date-picker/date-input'
import { hexToRgba } from '#utils/hexToRgba'

const SReactDatePickerWrapper = styled.div`
  .react-datepicker {
    background-color: ${({ theme: { colors } }) => colors.charcoal16};
    border: 1px solid ${({ theme: { colors } }) => colors.default};

    &-wrapper {
      width: 100%;
    }

    &__header {
      background-color: ${({ theme: { colors } }) => colors.charcoal16};
      border-bottom: 1px solid ${({ theme: { colors } }) => colors.default};
    }

    &__day-name,
    &__day,
    &__time-name,
    &__current-month,
    &-time__header,
    &-year-header {
      color: ${({ theme: { colors } }) => colors.default};
    }

    &__day,
    &__month-text,
    &__querter-text,
    &__year-text {
      &:hover {
        background-color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    &__day--keyboard-selected,
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__year-text--keyboard-selected {
      background-color: ${({ theme: { colors } }) => colors.black};
    }

    &__day--disabled {
      pointer-events: none;
      color: ${hexToRgba('#fff', 0.5)};
    }

    &__time {
      background-color: ${({ theme: { colors } }) => colors.charcoal16};
      color: ${({ theme: { colors } }) => colors.default};

      &-list-item:hover {
        background-color: ${({ theme: { colors } }) =>
          colors.primary}!important;
      }
    }
  }
`

export const DatePicker = ({
  name,
  label,
  value,
  onChange,
  minDate,
  maxDate,
  errorMessage,
  disabled,
  className,
  isBolded,
  isUpperCased,
  required,
  register,
  control,
  ...rest
}) => {
  return (
    <SReactDatePickerWrapper className={className}>
      <ReactDatePicker
        selected={value}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        disabled={disabled}
        required={required}
        customInput={
          <DateInput
            name={name}
            label={label}
            errorMessage={errorMessage}
            isBolded={isBolded}
            isUpperCased={isUpperCased}
            required={required}
            register={register}
            control={control}
          />
        }
        onChange={onChange}
        {...rest}
      />
    </SReactDatePickerWrapper>
  )
}
