import React from 'react'
import styled from 'styled-components'
import { bool, func, shape } from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Modal, Button } from '@tunego/tunego-ui-components'
import { BaseInput } from '#components/inputs/base-input'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { inviteOffPlatformUserValidationSchema } from '#pages/_modules/add-contributor-modal/utils/validation'
import { Checkbox } from '#components/checkbox'

const SModal = styled(Modal)`
  width: 500px;

  h2 {
    margin: 0;
  }
`

const SButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
`

const SSendEmail = styled.div`
  margin-bottom: 1rem;
`

const SNameRoleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  margin-bottom: 0.5rem;
`

const SAddExisting = styled.div`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
`

const SBoxGroup = styled.div`
  margin-top: 0;
`

const SButton = styled(Button)`
  align-self: center;
`

export const InviteOffPlatformUser = ({
  isOpened,
  handleClose,
  isForDistro,
  defaultRole,
  handleSwitchSides,
  handleInviteContributor,
  setChecked,
  checked,
}) => {
  const { t } = useTranslation('')
  const vaultTranslation = useTranslation('vault')

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(
      inviteOffPlatformUserValidationSchema(vaultTranslation.t, isForDistro)
    ),
  })

  const hasDefaultRole =
    defaultRole !== null && Object.keys(defaultRole).length > 0
  const roleSelectProps = hasDefaultRole ? { defaultValue: defaultRole } : null

  return (
    <SModal
      isOpen={isOpened}
      title={vaultTranslation.t('inviteContributor')}
      closeButton
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(handleInviteContributor)}>
        <SSendEmail>{vaultTranslation.t('sendAnEmailAnd')}</SSendEmail>
        <SNameRoleGrid>
          <BaseInput
            label={vaultTranslation.t('contributorName')}
            name="pendingName"
            customWidth="100%"
            inputRef={register}
            errorMessage={errors?.pendingName?.message}
            isBolded
          />
          <RolesSelect
            control={control}
            errorMessage={errors.role?.message}
            {...roleSelectProps}
          />
        </SNameRoleGrid>
        <BaseInput
          label={vaultTranslation.t('email')}
          name="email"
          customWidth="100%"
          inputRef={register}
          errorMessage={errors?.email?.message}
          isBolded
        />
        <Checkbox
          name="addAsCollaborator"
          checked={checked}
          label={vaultTranslation.t('addAsCollaborator')}
          onChange={() => setChecked(!checked)}
        />
        {isForDistro && (
          <BaseInput
            name="publisher"
            inputRef={register}
            label={vaultTranslation.t('publisher')}
            errorMessage={errors?.publisher?.message}
            makeCustomContainerStyles={() => ({
              width: 'unset',
              marginTop: 10,
            })}
            isBolded
          />
        )}
        <SButtonWrapper>
          <SBoxGroup>
            <SAddExisting onClick={handleSwitchSides}>
              {vaultTranslation.t('addExisting')}
            </SAddExisting>
          </SBoxGroup>
          <SButton type="submit">{t('add')}</SButton>
        </SButtonWrapper>
      </form>
    </SModal>
  )
}

InviteOffPlatformUser.propTypes = {
  isOpened: bool.isRequired,
  handleClose: func.isRequired,
  isForDistro: bool.isRequired,
  defaultRole: shape({}).isRequired,
  handleSwitchSides: func.isRequired,
  handleInviteContributor: func.isRequired,
  setChecked: func.isRequired,
  checked: bool.isRequired,
}
