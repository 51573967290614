import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    textAlign: 'center',
    paddingTop: '40px',
    height: '220px',
    backgroundColor: theme.palette.color.darkBlue09,
  },
  line: {
    width: '1px',
    height: '90%',
    position: 'absolute',
    left: '24px',
    top: '0px',
    backgroundColor: theme.palette.color.default,
  },
  description: {
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '30px',
  },
}))

const SButton = styled(Button)`
  margin: auto 0 auto auto;
`

export const EmptyAlbumRow = ({ onAddNewProject }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <img src="/assets/svg/ic-add-files.svg" alt="" width="60px" />
      <div className={classes.description}>{t('youHaventAddedAnySong')}</div>
      <SButton onClick={onAddNewProject}>+ {t('newSong')}</SButton>
    </div>
  )
}

EmptyAlbumRow.propTypes = {
  onAddNewProject: func.isRequired,
}
