import useSWR from 'swr'
import { buildUrl } from '#utils/buildUrl'
import { ENDPOINTS } from '#api/endpoints'

export const useProfiles = type => {
  const { data, error, ...rest } = useSWR(
    buildUrl(ENDPOINTS.STRUCTURES_PROFILES_FOR, { type })
  )

  return {
    profiles: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
