import React from 'react'
import { Dialog, Slide } from '@material-ui/core'
import { bool, func, string } from 'prop-types'
import { BackdropMessage } from '#components/backdrop-message'
import { SubscriptionBackdropMessage } from '#pages/profile/tabs/subscription/components/subscription-backdrop-message'
import { SUBSCRIPTION_ERROR } from '#pages/profile/utils/constants'
import styled from 'styled-components'

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_100};
  width: fit-content;
  padding: 10px;
  margin: auto;
`

const SMessage = styled.div`
  margin-top: 5px;
  font-size: 20px;
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} direction="up" {...props} />
})

export const CommonLoadingOverlay = ({
  dialogOpen,
  message,
  error,
  onErrorBackdropClick,
  isOwner,
  structureName,
}) => {
  return (
    <>
      <div>
        <Dialog
          open={dialogOpen}
          TransitionComponent={Transition}
          fullScreen
          disableBackdropClick
        >
          <SContainer>
            <img src="/assets/gifs/loading.gif" alt="loading" />
            <SMessage>{message}</SMessage>
          </SContainer>
        </Dialog>
      </div>
      {error === SUBSCRIPTION_ERROR.NOT_ENOUGH_SPACE ||
      error === SUBSCRIPTION_ERROR.TOO_MANY_PROJECTS ? (
        <SubscriptionBackdropMessage
          isOwner={isOwner}
          structureName={structureName}
          onBackdropClick={onErrorBackdropClick}
        />
      ) : (
        <BackdropMessage
          message={error}
          onBackdropClick={onErrorBackdropClick}
        />
      )}
    </>
  )
}

CommonLoadingOverlay.propTypes = {
  message: string,
  dialogOpen: bool,
  onErrorBackdropClick: func,
  error: string,
  isOwner: bool,
  structureName: string,
}

CommonLoadingOverlay.defaultProps = {
  dialogOpen: false,
  message: '',
  onErrorBackdropClick: null,
  error: '',
  isOwner: false,
  structureName: '',
}
