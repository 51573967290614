const getSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) return 'android'
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'

  return null
}

export const mobileAppLink = (deepLink = false) => {
  const system = getSystem()

  if (system === 'android') {
    return deepLink
      ? process.env.REACT_APP_ANDROID_DEEP_LINK
      : process.env.REACT_APP_ANDROID_APP_LINK
  }
  if (system === 'ios') {
    return deepLink
      ? process.env.REACT_APP_IOS_DEEP_LINK
      : process.env.REACT_APP_IOS_APP_LINK
  }
  return '/'
}
