import React from 'react'
import { useTranslation } from 'react-i18next'
import { BaseInput } from '#components/inputs/base-input'
import { string, func, shape } from 'prop-types'

export const ArtistIdInput = ({ register, errors, name, onFocus }) => {
  const error = errors[name]?.message
  const { t } = useTranslation('distro')

  return (
    <BaseInput
      label={t('artistName')}
      inputRef={register}
      errorMessage={error}
      {...{ onFocus, name }}
    />
  )
}

ArtistIdInput.propTypes = {
  register: func.isRequired,
  onFocus: func.isRequired,
  errors: shape({}).isRequired,
  name: string.isRequired,
}
