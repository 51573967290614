import React from 'react'
import { Modal } from '#components/modal'
import { bool, func } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { navigate } from '@reach/router'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'

const SModal = styled(Modal)`
  width: 48.5rem;
  padding: 2.5rem;
  align-items: flex-start;
`

const SHeading = styled.h2`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['3xl']};
  text-transform: uppercase;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  margin: 0;
  padding: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
`

const SButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: center;
`

const SButton = styled(Button)`
  width: 16.875rem;
`

export const Success = ({ setIsOpen, ...modalProps }) => {
  const { resetWizard } = useNftCreator()

  const { t } = useTranslation('nftWizard')

  const restartProcess = () => {
    resetWizard()

    navigate(NAVIGATION_PATHS.CREATE_COLLECTIBLES)
  }

  return (
    <SModal setIsOpen={setIsOpen} {...modalProps}>
      <SHeading>{t('steps.success.title')}</SHeading>
      <SText>{t('steps.success.text')}</SText>
      <SButtonWrapper>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.MY_COLLECTIBLES)}>
          {t('steps.success.viewCollectibles')}
        </SButton>
        <SButton buttonTheme={ButtonTheme.Secondary} onClick={restartProcess}>
          {t('steps.success.sellMore')}
        </SButton>
      </SButtonWrapper>
    </SModal>
  )
}

Success.propTypes = {
  isOpen: bool,
  setIsOpen: func,
}
