import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import mobile from 'is-mobile'
import { ParticleSphere } from '#pages/home/default-homepage/sections/default-welcome-section/components/particleSphere'
import { Button } from '#components/button'
import {
  Wrapper,
  BackgroundBox,
  BackgroundContent,
  Background,
  VideoContainer,
  Video,
  BackgroundContentWrapper,
  Particles,
  TitleWrapper,
  Title,
  SubTitle,
  ButtonSection,
  LogoImage,
} from '#pages/creators-vault-setup/styled'
import { useLogin } from '#hooks/useLogin'

export const CreatorsVaultSetup = () => {
  const { t } = useTranslation('creatorsVaultSetup')
  const isInLimitedMaintenance = window.LIMITED_MAINTENANCE
  const login = useLogin(true)

  const signupRedirect = () =>
    mobile({ tablet: true })
      ? navigate(process.env.REACT_APP_MOBILE_APP_URL)
      : login()
  const video = useRef()
  useEffect(() => {
    video.current.play()
  })

  return (
    <Wrapper>
      <BackgroundBox>
        <Background>
          <VideoContainer>
            <Video ref={video} id="myVideo" autoPlay muted loop>
              <source src="assets/mp4/main-bg-creators.mp4" type="video/mp4" />
            </Video>
          </VideoContainer>
          <Particles>
            <ParticleSphere />
            <BackgroundContentWrapper>
              <BackgroundContent>
                <TitleWrapper>
                  <LogoImage src="assets/images/tunego-logo-blue.png" />
                  <Title>{t('setupYourVault')}</Title>
                </TitleWrapper>
                <SubTitle>{t('yourDestination')}</SubTitle>
                <ButtonSection isInLimitedMaintenance={isInLimitedMaintenance}>
                  <Button isUppercase onClick={signupRedirect}>
                    {mobile({ tablet: true })
                      ? t('downloadAndRegister')
                      : t('setupVault')}
                  </Button>
                </ButtonSection>
              </BackgroundContent>
            </BackgroundContentWrapper>
          </Particles>
        </Background>
      </BackgroundBox>
    </Wrapper>
  )
}
