import React, { useState } from 'react'
import { bool, shape, arrayOf, func } from 'prop-types'
import { setDesktopHeaders } from '#pages/_modules/private-shares/utils/constants'
import { TableHeader } from '#pages/_components/table-header'
import { SharesListItem } from '#pages/_modules/private-shares/components/share-list-item'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { SharesApi } from '#api/requests/shares'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sharesDesktop: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
}))

export const SharesListDesktop = ({
  shares,
  onClear,
  isPrivateShareRole,
  isCollaborator,
  onPlay,
  mutate,
  isOwner,
}) => {
  const classes = useStyles()
  const [error, setError] = useState('')

  const { actions } = useWithAsyncAction({
    acceptShare: SharesApi.acceptShare,
    rejectShare: SharesApi.revokeShare,
  })

  const handleAcceptShare = async shareId => {
    try {
      await actions.acceptShare(shareId)
      mutate()
    } catch (err) {
      setError(err.response?.data?.message)
    }
  }

  const handleRejectShare = async shareId => {
    try {
      await actions.rejectShare(shareId)
      mutate()
    } catch (err) {
      setError(err.response?.data?.message)
    }
  }

  const handleClearErrors = () => {
    setError('')
  }

  return (
    <>
      <div className={classes.sharesDesktop}>
        <TableHeader headerItems={setDesktopHeaders(isOwner)} />
        {shares.map((share, index) => (
          <SharesListItem
            key={share.id}
            {...{
              isOwner,
              share,
              onClear,
              isCollaborator,
              isPrivateShareRole,
              index,
              onPlay,
              handleAcceptShare,
              handleRejectShare,
            }}
          />
        ))}
      </div>
      <CommonLoadingOverlay
        error={error}
        onErrorBackdropClick={handleClearErrors}
      />
    </>
  )
}

SharesListDesktop.propTypes = {
  shares: arrayOf(shape()).isRequired,
  onClear: func.isRequired,
  onPlay: func.isRequired,
  isPrivateShareRole: bool,
  mutate: func.isRequired,
  isOwner: bool.isRequired,
}

SharesListDesktop.defaultProps = {
  isPrivateShareRole: false,
}
