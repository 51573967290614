import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { node } from 'prop-types'
import { Footer } from '#components/footer'

const useStyles = makeStyles(() => ({
  homepageHeader: {
    height: '64px',
    border: 'solid 1px #2b2d2d',
    backgroundColor: '#1c1e1e',
    marginBottom: '40px',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    margin: '0.5rem',
    justifyContent: 'space-between',
  },
  icon: {
    width: '137px',
    height: '24px',
  },
}))

export const UnauthenticatedPageWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.homepageHeader}>
        <div className={classes.logoWrapper}>
          <img
            src="/assets/svg/tune-go-logo.svg"
            alt=""
            className={classes.icon}
          />
        </div>
      </div>
      {children}
      <Footer />
    </>
  )
}

UnauthenticatedPageWrapper.propTypes = {
  children: node.isRequired,
}
