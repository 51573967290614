import React, { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, bool, string } from 'prop-types'
import * as d3 from 'd3'
import { useWindowSize } from '#hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chart: {
    zIndex: '2',
  },
  barBg: {
    position: 'absolute',
    background: theme.palette.color.black,
    boxShadow: `inset 0 0 25px ${theme.palette.color.primary}`,
    zIndex: '1',
    border: `1px solid ${theme.palette.color.primary}`,
  },
  xAxis: {
    fontSize: '14px',
  },
  yAxis: {
    fontSize: '14px',
  },
  barValue: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  verticalLines: {
    '& line': {
      stroke: theme.palette.color.primary,
    },
  },
  barBGGroups: {},
}))

export const HorizontalBarGraph = ({
  data,
  showBarLabels,
  xUnit = '',
  yUnit = '',
  xDomain = [],
}) => {
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()
  const margin = 60
  const extraLeftMargin = 30
  const padding = 0.3
  const preparedData = data.map(obj => ({
    y: Object.keys(obj)[0],
    x: Number(obj[Object.keys(obj)[0]]),
  }))
  const [chartSize, setChartSize] = useState(null)
  const calculateWithMargin = value => value - 2 * margin

  const svgContainerRefMeasured = useCallback(node => {
    if (node !== null) {
      svgContainerRef.current = node
      setChartSize([
        calculateWithMargin(node.parentElement.offsetWidth) - extraLeftMargin,
        calculateWithMargin(node.parentElement.offsetHeight) + margin,
      ])
    }
  }, [])

  useEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(svgContainerRef.current.parentElement.offsetWidth) -
          extraLeftMargin,
        calculateWithMargin(
          svgContainerRef.current.parentElement.offsetHeight
        ) + margin,
      ]

    setChartSize(getContainerSize())
  }, [windowSize])

  const draw = () => {
    if (!chartSize) {
      return
    }
    // reset
    d3.select(svgContainerRef.current).select('svg').remove()
    d3.select(svgContainerRef.current)
      .select(`.${classes.barBGGroups}`)
      .remove()

    // define scales
    const xScale = d3.scaleLinear().domain(xDomain).range([0, chartSize[0]])

    const yScale = d3
      .scaleBand()
      .domain(preparedData.map(s => s.y))
      .range([chartSize[1], 0])
      .padding([padding])

    // Chart container - includes SVG and bg divs
    const chartContainer = d3
      .select(svgContainerRef.current)
      .append('div')
      .attr('class', classes.barBGGroups)
      .attr('transform', `translate(${margin}, ${margin})`)

    // SVG Chart init
    const chart = d3
      .select(svgContainerRef.current)
      .append('svg')
      .attr('class', classes.chart)
      .attr('width', chartSize[0] + 2 * margin)
      .attr('height', chartSize[1] + margin)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin / 2})`)

    // X Axis
    chart
      .append('g')
      .attr('class', classes.xAxis)
      .attr('transform', `translate(${extraLeftMargin}, ${chartSize[1]})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(0)
          .tickFormat(d => d + xUnit)
      )
      .call(g => g.select('.domain').remove())

    // Y Axis
    chart
      .append('g')
      .attr('class', classes.yAxis)
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(0)
          .tickFormat(d => d + yUnit)
      )
      .call(g => g.select('.domain').remove())

    // Value needed to reduce tick size
    const tickSizeReduce = 14

    // Vertical Lines
    chart
      .append('g')
      .attr('class', classes.verticalLines)
      .attr('transform', `translate(${extraLeftMargin}, ${tickSizeReduce})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(chartSize[1] - 2 * tickSizeReduce)
          .tickFormat('')
      )
      .call(g => g.select('.domain').remove())

    // Remove first and last horizontal line
    d3.select(`.${classes.verticalLines} .tick:first-child`).remove()
    d3.select(`.${classes.verticalLines} .tick:last-child`).remove()

    // Add containers for bars
    const barGroups = chart
      .append('g')
      .selectAll()
      .data(preparedData)
      .enter()
      .append('g')

    // Add containers for div bg
    const barBGGroups = chartContainer
      .selectAll()
      .data(preparedData)
      .enter()
      .append('div')

    // Inserting bars (rects) into g elements
    barGroups
      .append('rect')
      .attr('x', extraLeftMargin)
      .attr('y', d => yScale(d.y))
      .attr('width', d => xScale(d.x))
      .attr('height', yScale.bandwidth())
      .style('fill', '#00A0FF')
      .style('z-index', '2')

    // Bg divs for bar elements
    barBGGroups
      .append('div')
      .attr('class', classes.barBg)
      .style('top', d => `${yScale(d.y) + margin / 2}px`)
      .style('left', `${margin + extraLeftMargin}px`)
      .style('height', `${yScale.bandwidth()}px`)
      .style('width', `${chartSize[0]}px`)

    if (showBarLabels) {
      // Values on bar elements
      barGroups
        .append('text')
        .attr('class', classes.barValue)
        .attr('text-anchor', 'middle')
        .attr('fill', 'white')
        .attr('x', a => xScale(a.x) / 2 + extraLeftMargin)
        .attr('y', a => yScale(a.y) + yScale.bandwidth() / 2 + 5)
        .attr('text-anchor', 'middle')
        .text(a => `${a.x}${xUnit}`)
    }
  }

  useEffect(() => {
    draw() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSize, data, showBarLabels, xUnit, yUnit, xDomain])

  return (
    <div
      ref={svgContainerRefMeasured}
      id="chartWrapper"
      className={classes.chartWrapper}
    />
  )
}

HorizontalBarGraph.propTypes = {
  data: arrayOf(string).isRequired,
  xDomain: arrayOf(string).isRequired,
  showBarLabels: bool,
  xUnit: string,
  yUnit: string,
}

HorizontalBarGraph.defaultProps = {
  showBarLabels: false,
  xUnit: '',
  yUnit: '',
}
