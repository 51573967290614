import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, objectOf, string } from 'prop-types'
import { CircularGraph } from '#pages/promotion/report/components/circular-graph'
import { ReviewRowElement } from '../reviewer-row-element'
import { generateColorRange } from '#pages/promotion/_utils/helpers'

const useStyles = makeStyles({
  elementAnalysisChartContainer: {
    width: '100%',
    height: '600px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  similarArtistsImage: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '600px',
    padding: 32,
  },
  image: {
    width: '100%',
    marginBottom: 8,
  },
})

export const SimilarArtistsMentioned = ({ data, total }) => {
  const classes = useStyles()

  return (
    <ReviewRowElement
      refName="similarArtistsMentionedEl"
      title="similarArtistsMentioned"
      description="everWonderWhatFamousArtists"
    >
      {Array.isArray(data) ? (
        <div className={classes.elementAnalysisChartContainer}>
          <CircularGraph
            data={data}
            total={total}
            unit="%"
            legendPosition="right"
            colorRange={generateColorRange(data)}
            align="center"
            showLabels
          />
        </div>
      ) : (
        <div className={classes.similarArtistsImage}>
          <img
            className={classes.image}
            src="/assets/images/similar-artists-100.png"
            alt="TuneGO logo"
          />
        </div>
      )}
    </ReviewRowElement>
  )
}

SimilarArtistsMentioned.propTypes = {
  data: objectOf(string).isRequired,
  total: number,
}

SimilarArtistsMentioned.defaultProps = {
  total: null,
}
