import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { func } from 'prop-types'
import { Button } from '#components/button'
import { ArtworkRequirements } from '#pages/vault/profile-content/components/empty-project/components/artwork-requirements'
import { MusicRequirements } from '#pages/vault/profile-content/components/empty-project/components/music-requirements'

const SContainer = styled.div`
  text-align: center;
  max-width: 670px;
  margin: auto;
  padding-top: 139px;

  @media (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    padding: 1.25rem;
  }
`

const STitle = styled.div`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['6xl']};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin-bottom: 2rem;
  opacity: 0.33;
`

const SSubTitle = styled.div`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  opacity: 0.33;
`

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
`

const STextAndIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
`

const SText = styled.span`
  font-weight: 700;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SIcon = styled.img`
  margin-top: 0.625rem;
`

const SOr = styled.span`
  margin: 4.375rem;
`

const SRequirementsWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-bottom: 5rem;
`

const SButton = styled(Button)`
  height: 48px;
  width: 240px;
`

export const EmptyProject = ({ onClick }) => {
  const { t } = useTranslation('vault')

  return (
    <SContainer>
      <STitle>{t('youHaventUploadedAnyFiles')}</STitle>
      <SSubTitle>{t('securelyStoreYourMostImportant')}</SSubTitle>
      <SWrapper>
        <STextAndIcon>
          <SText>{t('dragAndDropFiles')}</SText>
          <SIcon
            src="/assets/svg/ic-upload.svg"
            width="29px"
            height="35px"
            alt=""
          />
        </STextAndIcon>
        <SOr>or</SOr>
        <SButton onClick={onClick}>{t('selectFiles')}</SButton>
      </SWrapper>
      <STitle>{t('requirementsForCollectibles')}</STitle>
      <SRequirementsWrapper>
        <ArtworkRequirements />
        <MusicRequirements />
      </SRequirementsWrapper>
    </SContainer>
  )
}

EmptyProject.propTypes = {
  onClick: func.isRequired,
}
