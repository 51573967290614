import React, { useState } from 'react'
import Persona from 'persona'
import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const usePersona = ({ config }) => {
  const [inProgress, setInProgress] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const {
    templateId,
    personaInquiryId,
    sessionToken,
    environment,
    handleVerify,
    setError,
  } = config

  const inquiry = {
    ...(personaInquiryId && { inquiryId: personaInquiryId, sessionToken }),
    ...(!personaInquiryId && { templateId }),
  }

  const openClient = React.useCallback(
    function openClient() {
      setInProgress(true)
      const client = new Persona.Client({
        ...inquiry,
        environment,
        onReady: () => {
          client.open()
          setIsReady(true)
        },
        onCancel: async ({ inquiryId }) => {
          if (personaInquiryId) {
            setInProgress(false)
          } else {
            try {
              await handleVerify({ inquiryId })
              setIsCompleted(false)
            } catch (err) {
              setError(err)
            } finally {
              setInProgress(false)
            }
          }
        },
        onComplete: async ({ inquiryId }) => {
          try {
            await handleVerify({ inquiryId })
            setIsCompleted(true)
          } catch (err) {
            setError(err)
          } finally {
            setInProgress(false)
          }
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config]
  )

  return { openClient, isReady, isCompleted, inProgress }
}

export const usePersonaSession = inquiryId => {
  const { data, error, ...rest } = useSWR(
    inquiryId ? ENDPOINTS.PERSONA_SESSION : null
  )

  return {
    personaSessionToken: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
