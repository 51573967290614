import React from 'react'
import { UnauthenticatedPageWrapper } from '#modules/unauthenticated-page-wrapper'
import { DistroTermsText } from '#pages/distro-terms/components/distro-terms-text'

export const DistroTerms = () => {
  return (
    <UnauthenticatedPageWrapper>
      <DistroTermsText />
    </UnauthenticatedPageWrapper>
  )
}
