import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { bool, func, string, shape } from 'prop-types'
import { Controller } from 'react-hook-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  FIELDS,
  HAS_VOCALS_OPTIONS,
  SONG_METADATA_FIELDS,
} from '#pages/distro/_utils/constants'
import { CustomSelect } from '#components/inputs/custom-select'
import { BaseInput } from '#components/inputs/base-input'
import { explicitContent } from '#pages/vault/song/tabs/metadata/utils/constants/explicitContent'
import { TextArea } from '#components/inputs/textarea'
import { titleLanguage } from '#pages/vault/song/tabs/metadata/utils/constants/titleLanguage'
import { performanceLanguage } from '#pages/vault/song/tabs/metadata/utils/constants/performanceLanguage'
import { trackVersion } from '#pages/vault/song/tabs/metadata/utils/constants/trackVersion'
import { FIELD_TYPE } from '#constants/filedTypes'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: 30,
  },
  lyricsTitle: {
    textAlign: 'center',
    color: theme.palette.color.primary,
  },
  wrapper: {
    padding: '0 30px 0 0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fields: {
    display: 'grid',
    width: '75%',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  lyrics: {
    width: '25%',
  },
}))

const SBaseInput = styled(BaseInput)`
  margin-bottom: 10px;
`

export const STextArea = styled(TextArea)`
  margin-bottom: 50px;
  max-height: 300px;
`

const SCustomSelect = styled(CustomSelect)`
  margin-bottom: 10px;
`

export const TrackDetailsForm = ({
  register,
  control,
  formErrors,
  hasVocals,
  hasLyrics,
  genres,
  profileName,
  songName,
}) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  const selectOptions = {
    [FIELDS.SONG_FILE_METADATA_GENRE]: genres,
    [FIELDS.SONG_FILE_METADATA_SECONDARY_GENRE]: genres,
    [FIELDS.SONG_FILE_METADATA_EXPLICIT_CONTENT]: explicitContent,
    [FIELDS.SONG_FILE_METADATA_TITLE_LANGUAGE]: titleLanguage,
    [FIELDS.SONG_FILE_METADATA_TRACK_VERSION]:
      hasLyrics || hasVocals
        ? trackVersion.filter(option => option.value !== 'instrumental')
        : trackVersion,
    [FIELDS.HAS_VOCALS]: hasLyrics
      ? HAS_VOCALS_OPTIONS.filter(
          option => option.value !== HAS_VOCALS_OPTIONS[1].value
        )
      : HAS_VOCALS_OPTIONS,
  }

  const inputPlaceholder = {
    [FIELDS.SONG_FILE_METADATA_PRIMARY_ARTIST_NAME]: profileName,
    [FIELDS.SONG_FILE_METADATA_SONG_NAME]: songName,
  }

  const renderField = field => {
    switch (field.type) {
      case FIELD_TYPE.INPUT:
        return (
          <div className={classes.wrapper}>
            <SBaseInput
              name={field.name}
              inputRef={register}
              label={t(field.label)}
              customWidth="100%"
              required={field.required}
              error={formErrors[field.name]}
              errorMessage={formErrors[field.name]?.message}
              placeholder={inputPlaceholder[field.name]}
              isBolded
            />
          </div>
        )
      case FIELD_TYPE.SELECT:
        return (
          <div className={classes.wrapper}>
            <Controller
              key={field.name}
              name={field.name}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SCustomSelect
                    options={selectOptions[field.name]}
                    label={t(field.label)}
                    placeholder={t(field.placeholder)}
                    value={value}
                    required={field.required}
                    errorMessage={
                      formErrors[field.name]?.message ||
                      formErrors[field.name]?.value?.message
                    }
                    isBolded
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.fields}>
        {genres && SONG_METADATA_FIELDS.map(field => renderField(field))}
        {hasVocals && (
          <div className={classes.wrapper}>
            <Controller
              key={FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE}
              name={FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <SCustomSelect
                    options={performanceLanguage}
                    label={`${t('performanceLanguage')}`}
                    value={value}
                    required={hasVocals}
                    errorMessage={
                      formErrors[FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE]
                        ?.message ||
                      formErrors[FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE]
                        ?.value?.message
                    }
                    isBolded
                    onChange={onChange}
                  />
                )
              }}
            />
          </div>
        )}
      </div>
      {hasVocals && (
        <div className={classes.lyrics}>
          <STextArea
            name={FIELDS.SONG_FILE_METADATA_LYRICS}
            inputRef={register}
            label={t('songLyrics')}
            placeholder={t('addYOurLyricsHere')}
            errorMessage={formErrors[FIELDS.SONG_FILE_METADATA_LYRICS]?.message}
            isBolded
          />
        </div>
      )}
    </div>
  )
}

TrackDetailsForm.propTypes = {
  register: func.isRequired,
  control: func.isRequired,
  formErrors: shape({}).isRequired,
  hasVocals: bool.isRequired,
  hasLyrics: bool.isRequired,
  genres: shape([]).isRequired,
  profileName: string.isRequired,
  songName: string.isRequired,
}
