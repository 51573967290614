import React from 'react'
import { makeStyles } from '@material-ui/core'
import { bool, func, node, number, string } from 'prop-types'
import { AddSongMetadataTabMenu } from '#pages/distro/wizard/steps/add-song-files/components/add-song-metadata-tab-menu'
import { AddSongMetadataFooter } from '#pages/distro/wizard/steps/add-song-files/components/add-song-metadata-footer'

const useStyles = hasSongVocals =>
  makeStyles(theme => ({
    wrapper: {
      minHeight: hasSongVocals ? 400 : 310,
      backgroundColor: theme.palette.color.darkBlue,
    },
  }))()

export const AddSongMetadataWrapper = ({
  children,
  metadataStep,
  setMetadataStep,
  hasSongVocals,
  onBackButtonClick,
  onNextButtonClick,
  customNextTitle,
  shouldDisableNextButton,
}) => {
  const classes = useStyles(hasSongVocals)

  return (
    <>
      <AddSongMetadataTabMenu {...{ metadataStep, setMetadataStep }} />
      <div className={classes.wrapper}>{children}</div>
      <AddSongMetadataFooter
        {...{
          onBackButtonClick,
          onNextButtonClick,
          customNextTitle,
          shouldDisableNextButton,
        }}
      />
    </>
  )
}

AddSongMetadataWrapper.defaultProps = {
  onBackButtonClick: null,
  shouldDisableNextButton: false,
  customNextTitle: '',
}

AddSongMetadataWrapper.propTypes = {
  children: node.isRequired,
  onNextButtonClick: func.isRequired,
  metadataStep: number.isRequired,
  setMetadataStep: func.isRequired,
  hasSongVocals: bool.isRequired,
  onBackButtonClick: func,
  shouldDisableNextButton: bool,
  customNextTitle: string,
}
