import React from 'react'
import styled from 'styled-components'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { CustomDropzone } from '#components/custom-dropzone'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { EmptyDropzone } from '#components/custom-dropzone/EmptyDropzone'
import { DropzoneOverlay } from '#components/custom-dropzone/DropzoneOverlay'
import { ACCEPTED_IMAGE_TYPES } from '#constants/acceptedFileTypes'
import { avatarValidator } from '#pages/onboarding/wizard/utils/avatar-validator'
import { AvatarRequirements } from '#pages/_components/avatar-requirements'

const SContainer = styled.div`
  margin: auto;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  justify-content: center;
  padding-left: 211px;
  padding-right: 212px;
  padding-top: 60px;
  flex-direction: column;
  height: 100vh;
  max-width: 1051px;
`

const STitle = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 245px;
  margin: 20px auto 30px;
`

const SSkipStep = styled.div`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: center;
`

const SImageError = styled.div`
  text-align: center;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  width: 100%;
`

const SButton = styled(Button)`
  margin-right: 0;
  padding: 0 30px;
`

const SCustomDropzone = styled(CustomDropzone)`
  position: relative;
  height: 400px;
  width: 400px;
  margin: auto auto 40px auto;
  background-image: ${props =>
    props.preview ? `url(${props.preview})` : 'none'};
  background-size: cover;
  background-position: center;
  border: ${props =>
    props.preview
      ? 'none'
      : `2px dashed ${({ theme: { colors } }) => colors.primary}`};
  box-shadow: ${props =>
    props.preview
      ? 'none'
      : `0 0 20px 0 ${({ theme: { colors } }) => colors.primary}`};
  cursor: pointer;
`

export const AddProfileImage = ({
  nextStep,
  onDrop,
  onDropRejected,
  preview,
  imageError,
}) => {
  const { t } = useTranslation()
  const validationTranslation = useTranslation('validation')

  const onboardingTranslation = useTranslation('onboarding')

  return (
    <SContainer>
      <STitle>{onboardingTranslation.t('addYourImage')}</STitle>
      <SCustomDropzone
        multiple={false}
        accept={ACCEPTED_IMAGE_TYPES}
        title={preview ? '' : onboardingTranslation.t('uploadImage')}
        preview={preview}
        validator={file => avatarValidator(file, validationTranslation.t)}
        onDropAccepted={onDrop}
        onDropRejected={onDropRejected}
      >
        {preview ? (
          <DropzoneOverlay description={t('edit')} />
        ) : (
          <EmptyDropzone buttonText="orSelectImage" />
        )}
      </SCustomDropzone>
      <AvatarRequirements />
      {imageError && <SImageError>{imageError}</SImageError>}
      <SButtonWrapper>
        <SButton
          disabled={preview === ''}
          variant={VARIANTS.NEW_BLUE_SPECIAL}
          onClick={nextStep}
        >
          {t('letsGo')}
        </SButton>
      </SButtonWrapper>
      <SSkipStep>
        <span onClick={() => navigate(NAVIGATION_PATHS.CONNECT_WALLET)}>
          {onboardingTranslation.t('skipThisStep')}
        </span>
      </SSkipStep>
    </SContainer>
  )
}

AddProfileImage.propTypes = {
  nextStep: func.isRequired,
  onDrop: func.isRequired,
  onDropRejected: func.isRequired,
  preview: string.isRequired,
  imageError: string.isRequired,
}
