const VAULT_ROUTES = {
  MAIN: '/vault',
  VAULT: '/',
  PROFILES: '/profiles',
  SONG: 'profiles/:artistId/song/:projectId',
  SONG_TAB: 'profiles/:artistId/song/:projectId/:projectTab',
  EDIT_PROFILE: '/profiles/:id/edit',
  PROFILE_CONTENT: 'profiles/:id',
  PROFILE_COLLABORATORS: 'profiles/:artistId/collaborators',
  CREATE_PROFILE: '/create-profile',
}

const DISTRO_ROUTES = {
  MAIN: '/distribution',
  GET_STARTED: '/get-started',
  PAST_RELEASES: '/past-releases',
  PAST_RELEASE_DETAILS: '/past-release/:id',
  CREATE_NEW: '/create-new',
}

const PROMOTION_ROUTES = {
  MAIN: '/promotion',
  NEW_PROMOTION: '/new-promotion',
  REPORTS: '/reports',
  REPORT: '/report/:promotionId',
}

const SUBSCRIPTIONS_ROUTES = {
  MAIN: '/subscriptions',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment-success',
}

const MASTERING_ROUTES = {
  MAIN: '/mastering',
  VIEW_MASTERS: '/view-masters',
  NEW: '/new',
}

const SELL_COLLECTIBLES_ROUTES = {
  MAIN: '/sell-your-collectibles',
  CREATE: '/create',
  MY_COLLECTIBLES: 'my-collectibles',
}

export const ROUTES = {
  LOGIN_REQUIRED: '/login-required',
  VIP_ENTRY: '/vip-lets-go',
  CREATORS_VAULT_SETUP: '/creators-vault-setup',
  CONFIRM_EMAIL: '/confirm-email',
  VERIFY_EMAIL: '/verify-email',
  VERIFY_EMAIL_MOBILE: '/verify-email-mobile',
  TERMS: '/terms-of-use',
  OWNER_AGREEMENT: '/owner-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  DISTRO_TERMS: '/distro-terms',
  DOWNLOAD_MOBILE_APP: '/download-mobile-app',
  ONBOARDING: '/onboarding',
  MAINTENANCE: '/maintenance',
  PROFILE: '/profile',
  VIDEO_GO: '/video-go',
  CONNECT: '/connect',
  ARTISTS: VAULT_ROUTES,
  DISTRO: DISTRO_ROUTES,
  PROMOTION: PROMOTION_ROUTES,
  SUBSCRIPTIONS: SUBSCRIPTIONS_ROUTES,
  MASTERING: MASTERING_ROUTES,
  CONNECT_WALLET: '/connect-wallet',
  SELL_COLLECTIBLES: SELL_COLLECTIBLES_ROUTES,
  LEARN_WEB3: '/learn-web3',
  GETTING_STARTED: '/getting-started',
  DIRECT_SIGNUP: '/direct-signup',
  CONNECT_WALLET_MOBILE: '/connect-wallet-mobile',
}

export const NAVIGATION_PATHS = {
  HOME: '/',
  TERMS: '/terms-of-use',
  OWNER_AGREEMENT: '/owner-agreement',
  PRIVACY_POLICY: '/privacy-policy',
  ONBOARDING: '/onboarding',
  VERIFY_EMAIL: '/verify-email',
  DOWNLOAD_MOBILE_APP: '/download-mobile-app',
  MAINTENANCE: '/maintenance',
  PROFILE: '/profile',
  VAULT_PROFILES: '/vault/profiles',
  VAULT: '/vault',
  PROFILE_CONTENT: '/vault/profiles/:id',
  PROFILE_COLLABORATORS: '/vault/profiles/:id/collaborators',
  CREATE_PROFILE: '/vault/create-profile',
  EDIT_PROFILE: '/vault/profiles/:id/edit',
  CREATE_SONG: '/vault/create-song',
  MOVE_SONG: '/vault/profiles/:artistId/move-song',
  SONG: '/vault/profiles/:artistId/song/:projectId/',
  SONG_TAB: '/vault/profiles/:artistId/song/:projectId/:projectTab',
  MASTERING: '/mastering',
  VIEW_MASTERS: '/mastering/view-masters',
  NEW_MASTERING: '/mastering/new',
  DISTRO: '/distribution',
  DISTRO_GET_STARTED: '/distribution/get-started',
  DISTRO_CREATE_NEW: '/distribution/create-new',
  DISTRO_PAST_RELEASES: '/distribution/past-releases',
  DISTRO_PAST_RELEASE_DETAILS: '/distribution/past-release/:id',
  PROMOTION: '/promotion',
  PROMOTION_NEW: '/promotion/new-promotion',
  PROMOTION_REPORTS: '/promotion/reports',
  PROMOTION_REPORT: '/promotion/report/:promotionId',
  CONNECT: '/connect',
  CONNECT_COMING_SOON: '/connect/coming-soon',
  VIDEO_GO: '/video-go',
  COMING_SOON: '/coming-soon',
  NFT: '/nft',
  NEW_NFT: '/nft/new',
  NFT_LIST: '/nft/my-nfts',
  SUBSCRIPTIONS_PAYMENT: '/subscriptions/payment',
  SUBSCRIPTIONS_PAYMENT_SUCCESS: '/subscriptions/payment-success',
  CONNECT_WALLET: '/connect-wallet',
  SELL_COLLECTIBLES: '/sell-your-collectibles',
  CREATE_COLLECTIBLES: '/sell-your-collectibles/create',
  MY_COLLECTIBLES: '/sell-your-collectibles/my-collectibles',
  LEARN_WEB3: '/learn-web3',
  GETTING_STARTED: '/getting-started',
  CONNECT_WALLET_MOBILE: '/connect-wallet-mobile',
}
