import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import styled, { css } from 'styled-components'
import { number, func, string, shape, arrayOf } from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { EllipsisMenu } from '#pages/vault/profile-content/components/ellipsis-menu'
import { AddSongRow } from '#pages/vault/profile-content/components/rows/add-song-row'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { formatDate } from '#utils/formatDate'
import { useVault } from '#hooks/useVault'

const STableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.75rem;
  ${({
    theme: {
      tunego: { COLORS },
    },
    isSelected,
  }) =>
    isSelected &&
    css`
      background-color: ${COLORS.primary_80};
    `}
`

const SNameColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
`

const SStructureImage = styled.div`
  padding-left: 1rem;
  display: flex;
  align-items: center;
`

const SRowName = styled.div`
  margin-left: 1.25rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  max-width: 34.375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
  }
`

const SLastUpdateColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`

export const MainTableRow = ({
  accessRoles,
  name,
  category,
  updatedAt,
  index,
  id,
  parent,
  typeOfCurrentAction,
  setTypeOfCurrentAction,
  idOfActiveActionItem,
  setIdOfActiveActionItem,
  handleCloseInlineAction,
  handleAddEditCollaborators,
  handleMoveSingle,
  setStructureCategory,
}) => {
  const { expandedStructureId, setExpandedStructureId } = useVault()

  const isActive = idOfActiveActionItem === id
  const isSelected = expandedStructureId === id
  const isFolder = category === STRUCTURE_TYPE.ALBUM

  const handleToggleFolderContent = folderId => {
    if (expandedStructureId === folderId) {
      setExpandedStructureId(null)
    } else {
      setExpandedStructureId(folderId)
    }
  }

  const shouldDisplayEllipsisMenu = accessRoles?.some(role =>
    [ACCESS_ROLES.OWNER, ACCESS_ROLES.COLLABORATOR].includes(role)
  )

  useEffect(() => {
    if (typeOfCurrentAction === ELLIPSIS_OPTIONS.MOVE_SONG) {
      handleMoveSingle()
    }
  }, [typeOfCurrentAction])

  const songFolderAction = () => {
    if (isFolder) {
      handleToggleFolderContent(id)
    } else {
      navigate(
        buildUrl(NAVIGATION_PATHS.SONG, {
          artistId: parent?.id,
          projectId: id,
        })
      )
    }
  }

  return (
    <>
      <STableRow isSelected={isSelected} index={index}>
        <SNameColumn onClick={songFolderAction}>
          <SStructureImage>
            <img
              src={`/assets/svg/${
                isSelected
                  ? `ic-${category}-white.svg`
                  : `ic-${category}-blue.svg`
              }`}
              width="20px"
              alt=""
            />
            <Tooltip title={name}>
              <SRowName>{name}</SRowName>
            </Tooltip>
          </SStructureImage>
        </SNameColumn>
        <SLastUpdateColumn>
          <div>{formatDate(updatedAt)}</div>
          {shouldDisplayEllipsisMenu && (
            <EllipsisMenu
              folderOrSong={category}
              mainRowIndex={index}
              {...{
                accessRoles: isFolder ? accessRoles : parent.accessRoles,
                index,
                id,
                name,
                setTypeOfCurrentAction,
                setIdOfActiveActionItem,
                handleAddEditCollaborators,
                isSubRow: isSelected,
                setStructureCategory,
              }}
            />
          )}
        </SLastUpdateColumn>
      </STableRow>
      {typeOfCurrentAction === ELLIPSIS_OPTIONS.ADD_INLINE && isActive && (
        <AddSongRow
          isSelected={isSelected}
          id={id}
          handleCloseInlineAction={handleCloseInlineAction}
        />
      )}
    </>
  )
}

MainTableRow.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  name: string.isRequired,
  category: string.isRequired,
  updatedAt: string.isRequired,
  parent: shape({}),
  index: number.isRequired,
  id: string.isRequired,
  typeOfCurrentAction: string,
  setTypeOfCurrentAction: func.isRequired,
  idOfActiveActionItem: string,
  setIdOfActiveActionItem: func.isRequired,
  handleCloseInlineAction: func.isRequired,
  handleAddEditCollaborators: func.isRequired,
  setStructureCategory: func.isRequired,
  handleMoveSingle: func.isRequired,
}

MainTableRow.defaultProps = {
  typeOfCurrentAction: null,
  idOfActiveActionItem: null,
  parent: {},
}
