export const checkIfUserHasRole = (array, role) => {
  return array.includes(role)
}

export const createImageUrl = file => {
  if (!file) return

  const { size, type, name } = file
  const blob = file.slice(0, size, type)
  const newFile = new File([blob], name, { type })
  const url = URL.createObjectURL(newFile)
  // eslint-disable-next-line consistent-return
  return url
}
