import * as yup from 'yup'
import i18next from 'i18next'
import { FORM_FIELDS } from '#pages/_modules/add-contributor-modal/utils/constants'

const validateRole = t =>
  yup
    .array()
    .required(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('role'),
      })
    )
    .typeError(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('role'),
      })
    )

const validatePublisher = t =>
  yup
    .string()
    .trim()
    .required(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('publisher'),
      })
    )

export const addExistingUserValidationSchema = (t, isForDistro) => {
  return yup.object().shape({
    [FORM_FIELDS.CONTRIBUTOR_NAME]: yup
      .object()
      .nullable()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('contributorName'),
        })
      ),
    [FORM_FIELDS.ROLE]: validateRole(t),
    ...(isForDistro && {
      [FORM_FIELDS.PUBLISHER]: validatePublisher(t),
    }),
    [FORM_FIELDS.IPI_CAE_NUMBER]: yup
      .string()
      .trim()
      .nullable()
      .transform(value => value || null)
      .test(
        i18next.t('validation:acceptOnlyDigits'),
        i18next.t('validation:onlyDigits', {
          field: t(`ipiCae`),
        }),
        value => {
          if (value) {
            return /^\d+$/.test(value)
          }
          return true
        }
      )
      .min(
        9,
        i18next.t('validation:minimumLength', {
          field: t(`ipiCae`),
          number: 9,
        })
      )
      .max(
        11,
        i18next.t('validation:maximumLength', {
          field: t(`ipiCae`),
          number: 11,
        })
      ),
  })
}

export const inviteOffPlatformUserValidationSchema = (t, isForDistro) => {
  const email = t('email')
  const name = t('contributorName')

  return yup.object().shape({
    pendingName: yup.string().required(
      i18next.t('validation:mustNotBeEmpty', {
        field: name,
      })
    ),
    email: yup
      .string()
      .email(i18next.t('validation:validEmailAddress'))
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: email,
        })
      ),
    role: validateRole(t),
    ...(isForDistro && {
      publisher: validatePublisher(t),
    }),
  })
}

export const editContributorValidationSchema = (t, isForDistro) => {
  return yup.object().shape({
    [FORM_FIELDS.ROLE]: validateRole(t),
    ...(isForDistro && {
      [FORM_FIELDS.PUBLISHER]: validatePublisher(t),
    }),
  })
}
