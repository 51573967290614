import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import { number, func, string, arrayOf, bool, shape } from 'prop-types'
import { EllipsisMenu } from '#pages/vault/profile-content/components/ellipsis-menu'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { formatDate } from '#utils/formatDate'
import styled from 'styled-components'

const SStructureName = styled.span`
  margin-left: 1.2rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 32.5rem;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.xs}) {
    max-width: 5.3125rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const SSubRowWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 3.75rem;
  background: ${({ theme: { colors }, subRowIndex }) =>
    subRowIndex % 2 ? colors.darkBlue : colors.lighterBlue};
`

const SLine = styled.div`
  background-color: ${({ theme: { colors } }) => colors.default};
  width: 1px;
  position: absolute;
  left: 1.5rem;
  bottom: 0;
  top: 0;
  height: ${({ isLastRow }) => (isLastRow ? '50%' : '100%')};
`

const SNameColumn = styled.div`
  display: flex;
  flex: 2;
  align-content: center;
`

const SStructureImage = styled.div`
  padding-left: 3.125rem;
  display: flex;
  align-items: center;
`

const SLastUpdatedColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
`

export const AlbumSubRow = ({
  accessRoles,
  subRowIndex,
  id,
  vaultId,
  name,
  updatedAt,
  category,
  index,
  typeOfCurrentNestedAction,
  setTypeOfCurrentNestedAction,
  idOfNestedActiveActionItem,
  setIdOfNestedActiveActionItem,
  parent,
  isLastRow,
  setStructureCategory,
  handleMoveSingle,
}) => {
  const shouldDisplayEllipsisMenu = () => {
    return parent?.accessRoles.some(role =>
      [
        ACCESS_ROLES.OWNER,
        ACCESS_ROLES.COLLABORATOR,
        ACCESS_ROLES.READ,
      ].includes(role)
    )
  }

  useEffect(() => {
    if (typeOfCurrentNestedAction === ELLIPSIS_OPTIONS.MOVE_SONG) {
      handleMoveSingle(idOfNestedActiveActionItem, parent?.id)
    }
  }, [typeOfCurrentNestedAction])

  const onSubRowClickHandler = () => {
    navigate(
      buildUrl(NAVIGATION_PATHS.SONG_TAB, {
        artistId: vaultId,
        projectId: id,
      })
    )
  }

  return (
    <SSubRowWrapper key={name} subRowIndex={subRowIndex}>
      <SNameColumn onClick={onSubRowClickHandler}>
        <SStructureImage>
          <SLine isLastRow={isLastRow} />
          <img src="/assets/svg/ic-project-white.svg" width="20px" alt="" />
          <SStructureName>{name}</SStructureName>
        </SStructureImage>
      </SNameColumn>

      <SLastUpdatedColumn>
        <span>{formatDate(updatedAt)}</span>
        {shouldDisplayEllipsisMenu() && (
          <EllipsisMenu
            folderOrProject={category}
            mainRowIndex={index}
            isSubRow
            {...{
              accessRoles,
              index,
              id,
              name,
              setTypeOfCurrentAction: setTypeOfCurrentNestedAction,
              setIdOfActiveActionItem: setIdOfNestedActiveActionItem,
              setStructureCategory,
            }}
          />
        )}
      </SLastUpdatedColumn>
    </SSubRowWrapper>
  )
}

AlbumSubRow.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  name: string.isRequired,
  updatedAt: string.isRequired,
  id: string.isRequired,
  vaultId: string.isRequired,
  subRowIndex: number.isRequired,
  mainRowIndex: number.isRequired,
  category: string.isRequired,
  index: number.isRequired,
  typeOfCurrentNestedAction: string.isRequired,
  setTypeOfCurrentNestedAction: func.isRequired,
  idOfNestedActiveActionItem: string.isRequired,
  setIdOfNestedActiveActionItem: func.isRequired,
  parent: shape({ id: string.isRequired }),
  isLastRow: bool.isRequired,
  setStructureCategory: func.isRequired,
  handleMoveSingle: func.isRequired,
}

AlbumSubRow.defaultProps = {
  parent: null,
}
