import React from 'react'
import { func, shape, string, bool, node, arrayOf } from 'prop-types'
import { Row } from '#components/tree-select/_components/row'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LoadingImage } from '#components/loaders/loading-image'
import { ROW_HEIGHT } from '#components/tree-select/_constants'

const SContent = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;

  &::after {
    content: '';
    width: 1px;
    height: ${({ itemsCount }) =>
      `calc(${itemsCount - 0.5} * ${ROW_HEIGHT}px)`};
    position: absolute;
    left: 3.75rem;
    top: 0;
    background-color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_0};
  }
`

const SText = styled.span`
  margin-left: 4.5rem;
  height: ${ROW_HEIGHT}px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const SError = styled(SText)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.warning_50};
`

const SLoadingIndicator = styled(LoadingImage)`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`

export const ExpandableRowContent = ({
  items,
  selectedOption,
  isLoading,
  error,
  onSelect,
}) => {
  const { t } = useTranslation('common')

  if (isLoading)
    return (
      <SText>
        <SLoadingIndicator />
        {t('loading')}
      </SText>
    )

  if (error) return <SError>{t('errorFetchingPackContent')}</SError>

  if (!items?.length) return <SText>{t('emptyPack')}</SText>

  return (
    <SContent itemsCount={items.length}>
      {items?.map((item, i) => {
        return (
          <Row
            key={item.value}
            option={item}
            isSelected={selectedOption?.value === item.value}
            isEven={i % 2 === 0}
            isNested
            onSelect={onSelect}
          />
        )
      })}
    </SContent>
  )
}

ExpandableRowContent.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      value: string,
      isExpandable: bool,
      icon: node,
    })
  ).isRequired,
  isLoading: bool,
  onSelect: func,
  selectedOption: shape({
    label: string,
    value: string,
    isExpandable: bool,
    icon: node,
  }).isRequired,
  error: bool,
}
