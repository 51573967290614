import React from 'react'
import { string, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  tunegoLogo: {
    cursor: 'pointer',
    height: '20px',
    width: '114px',
    zIndex: 1,
  },
  header: {
    borderBottom: props =>
      props.shouldShowBorder ? `2px solid ${theme.palette.color.primary}` : '0',
    height: props => props.height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: props =>
      props.isBlackBackground
        ? theme.palette.background.default
        : theme.palette.color.darkGrey1A,
    position: 'relative',
  },
}))

export const GenericHeader = ({
  height,
  shouldShowBorder,
  isBlackBackground,
}) => {
  const classes = useStyles({ height, shouldShowBorder, isBlackBackground })
  return (
    <div className={classes.header}>
      <img
        alt="TuneGO"
        src="/assets/svg/tune-go-logo.svg"
        className={classes.tunegoLogo}
        onClick={() => navigate(NAVIGATION_PATHS.HOME)}
      />
    </div>
  )
}

GenericHeader.propTypes = {
  height: string.isRequired,
  shouldShowBorder: bool,
  isBlackBackground: bool,
}

GenericHeader.defaultProps = {
  shouldShowBorder: true,
  isBlackBackground: false,
}
