import React from 'react'
import styled from 'styled-components'
import { bool, func, node, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { NAVIGATION_PATHS } from '#routes/routes'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'

const useStyles = makeStyles(theme => ({
  title: {
    margin: 0,
    fontSize: 32,
    fontFamily: theme.typography.h2.fontFamily,
  },
  subTitle: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    marginBottom: 30,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3rem',
    maxWidth: 600,
    width: '100%',
    margin: 'auto',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'start',
    gap: '1rem',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    gap: '1rem',
  },
}))

const SButton = styled(Button)`
  margin-top: 30px;
  width: 100%;
`

export const CreateStructureFormWrapper = ({
  shouldHideCancelButton,
  buttonMargin,
  children,
  title,
  subTitle,
  onSubmit,
  disabled,
  nextBtnText,
  navigateOnCancel,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subTitle}>{subTitle}</p>
      <form className={classes.form} onSubmit={onSubmit}>
        <div>{children}</div>
        <div className={classes.buttons} style={{ margin: buttonMargin }}>
          <SButton type="submit" disabled={disabled}>
            {nextBtnText}
          </SButton>
          {!shouldHideCancelButton && (
            <SButton
              variant={VARIANTS.NEW_BLUE_SPECIAL}
              onClick={() =>
                navigate(navigateOnCancel || NAVIGATION_PATHS.VAULT_PROFILES)
              }
            >
              {t('cancel')}
            </SButton>
          )}
        </div>
      </form>
    </div>
  )
}

CreateStructureFormWrapper.propTypes = {
  shouldHideCancelButton: bool,
  children: node.isRequired,
  title: string.isRequired,
  subTitle: string.isRequired,
  onSubmit: func.isRequired,
  nextBtnText: string.isRequired,
  disabled: bool,
  navigateOnCancel: string,
}

CreateStructureFormWrapper.defaultProps = {
  shouldHideCancelButton: false,
  disabled: false,
  navigateOnCancel: null,
}
