/* eslint-disable no-useless-catch */
import { getApiClient } from '#api/api'
import { ENDPOINTS } from '#api/endpoints'

const AUTH0_API_URL = process.env.REACT_APP_AUTH0_API_URL
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID

const REDIRECT_URL = window.location.origin

const apiClient = getApiClient()

export class Auth0Api {
  static loginApiRequest = idToken =>
    apiClient.post(ENDPOINTS.LOGIN, {
      idToken,
    })

  static logoutRedirect = () => {
    localStorage.removeItem('auth')
    window.location.href = `https://${AUTH0_API_URL}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${REDIRECT_URL}`
  }

  static emailResendRequest = userId =>
    apiClient.post(ENDPOINTS.RESEND_EMAIL, {
      userId,
    })
}
