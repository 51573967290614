import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { OutlinedElement } from '#pages/distro/_components/outlined-element'
import { BolderTranslation } from '#components/bolder-translation'
import { Button } from '#components/button'
import { useUser } from '#hooks/useUser'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '3rem',
  },
  year: {
    fontSize: '1.5rem',
  },
  header: {
    color: theme.palette.color.primary,
    fontFamily: 'AvenirNextLTPro-Bold',
    display: 'flex',
    alignItems: 'baseline',
  },
  description: {
    whiteSpace: 'pre-line',
  },
}))

const SButton = styled(Button)`
  margin-top: 2rem;
  text-transform: uppercase;
`

export const DistroTypeCard = ({ title, price, onClick, buttonText }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { formattedPriceOf } = useUser()

  return (
    <div>
      <OutlinedElement header={title}>
        <div className={classes.header}>
          <span className={classes.title}>{formattedPriceOf(price)}</span>
          <span className={classes.year}>/{t('year')}</span>
        </div>
        <div className={classes.description}>
          <Trans
            t={t}
            i18nKey="keep100ofYourMusicRights"
            components={Array.from({ length: 5 }, (_, i) => (
              <BolderTranslation key={i} />
            ))}
          />
        </div>
      </OutlinedElement>
      <SButton onClick={onClick}>{buttonText}</SButton>
    </div>
  )
}
