import React from 'react'
import styled from 'styled-components'
import { bool } from 'prop-types'
import { Button } from '#components/button'
import { PageWrapper } from '#modules/page-wrapper'
import { useTranslation } from 'react-i18next'

const SComingSoon = styled.div`
  background-image: url('/assets/images/curtain.jpg');
  background-position: center;
  background-size: cover;
  height: calc(100vh - 65px);
  width: 100%;
  text-align: center;
  overflow: auto;
`

const SComingSoonHeader = styled.h2`
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 20px;
  padding-top: 20%;
`

const SComingSoonSubHeader = styled.p`
  font-size: 20px;
  margin-bottom: 40px;
`

const SButton = styled(Button)`
  margin: auto;
  text-transform: uppercase;
`

export const ComingSoon = ({ noNavbar }) => {
  const { t } = useTranslation()

  return (
    <PageWrapper showNavBar={!noNavbar} isFullWidth>
      <SComingSoon>
        <SComingSoonHeader>{t('comingAttraction')}</SComingSoonHeader>
        <SComingSoonSubHeader>{t('weWillNotifyYou')}</SComingSoonSubHeader>
        <SButton onClick={() => window.history.go(-1)}>{t('ok')}</SButton>
      </SComingSoon>
    </PageWrapper>
  )
}

ComingSoon.propTypes = {
  noNavbar: bool,
}

ComingSoon.defaultProps = {
  noNavbar: false,
}
