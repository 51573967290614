import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { string, shape, bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { navigate } from '@reach/router'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CollaboratorsApi } from '#api/requests/collaborators'
import { SharesApi } from '#api/requests/shares'
import { ContributorsApi } from '#api/requests/contributors'
import {
  NOTIFICATION_TYPES,
  ACCEPTABLE_NOTIFICATION_TYPES,
  ACCEPTABLE_NOTIFICATION_CONFIGS,
  USE_TITLE_NOTIFICATION_TYPES,
} from '#modules/notifications/utils/constants'
import { navigateTo } from '#modules/notifications/utils/helpers'
import { NAVIGATION_PATHS } from '#routes/routes'
import { PROFILE_PAGES } from '#pages/profile/utils/constants'
import { NftApi } from '#api/requests/nft'
import { SPLIT_ACTIONS } from '#pages/nft/_utils/constants'
import { formatDate } from '#utils/formatDate'
import { UserImage } from '#components/user-image'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { LogoLoader } from '#components/loaders/logo-loader'

const useStyles = makeStyles(theme => ({
  notification: {
    cursor: 'pointer',
    borderBottom: `1px solid ${theme.palette.background.default}`,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: ({ isToast }) => (isToast ? '14px 0 0 0' : 14),
  },
  wrapper: {
    width: '100%',
    lineHeight: ({ isToast }) => (isToast ? '1em' : 'inherit'),
  },
  notificationConfirm: {
    // TODO
  },
  notificationDark: {
    background: theme.palette.background.default,
  },
  notificationUnread: {
    background: theme.palette.color.primary25,
  },
  notificationName: {
    fontWeight: 'bold',
    fontFamily: ({ isToast }) =>
      isToast ? 'inherit' : theme.typography.h2.fontFamily,
    paddingRight: '5px',
    display: ({ isToast }) => (isToast ? 'inline' : 'flex'),
  },
  notificationMessage: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontSize: 12,
    opacity: 0.5,
  },
  notificationTime: {
    fontSize: 10,
    opacity: 0.5,
    paddingTop: 8,
  },
  notificationImageContainer: {
    paddingRight: '16px',
    alignSelf: ({ isToast }) => (isToast ? 'start' : 'inherit'),
  },
  notificationButtonContainer: {
    marginTop: 12,
    alignItems: 'center',
    display: 'flex',
  },
  notificationLoader: {
    flex: '1 1',
  },
  inlineOrFlex: {
    display: ({ isToast }) => (isToast ? 'inline' : 'flex'),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const SButton = styled(Button)`
  font-size: ${({ accept }) => (accept ? '12px' : '14px')};
  height: ${({ accept }) => (accept ? '35px' : '40px')};
  margin-bottom: 0;
  padding: 5px 20px;
`

const computeNotificationValues = (t, notification, isConfirm) => {
  const isAcceptedGrey =
    notification.type.endsWith('_accepted_grey') ||
    notification.type.endsWith('_approved_grey')
  const isAccepted =
    isAcceptedGrey ||
    notification.type.endsWith('_accepted') ||
    notification.type.endsWith('_approved')
  const isRejected = notification.type.endsWith('_rejected')
  const baseType =
    isAccepted || isRejected
      ? notification.type.slice(0, Math.max(0, notification.type.length - 9))
      : notification.type
  const isAcceptableInvitation =
    ACCEPTABLE_NOTIFICATION_TYPES.includes(baseType)
  const titleOrFullName = USE_TITLE_NOTIFICATION_TYPES.includes(
    notification.type // TODO baseType?
  )
    ? notification.title
    : `${notification.senderUser?.firstName} ${notification.senderUser?.lastName}`

  const confirmationConfig = ACCEPTABLE_NOTIFICATION_CONFIGS[baseType]
  const shouldConfirm = !!confirmationConfig

  const acceptedType =
    confirmationConfig?.acceptedTypeOverride || `${baseType}_accepted`
  const rejectedType =
    confirmationConfig?.rejectedTypeOverride || `${baseType}_rejected`

  let positive = t('accept')
  let negative = t('reject')

  let type = 'base'
  if (isAccepted) {
    type = 'accepted'
  } else if (isRejected) {
    type = 'rejected'
  } else if (isConfirm) {
    type = 'confirm'
    positive = t('confirm')
    negative = t('cancel')
  }
  if (type === 'base' || !shouldConfirm) {
    return {
      title: titleOrFullName,
      body: notification.body,
      positive,
      negative,
      shouldConfirm,
      isAcceptableInvitation,
      baseType,
      acceptedType,
      rejectedType,
    }
  }

  const computeValue = (fallback, valueOrFunctionName) => {
    const valueOrFunction = confirmationConfig[valueOrFunctionName]
    if (!valueOrFunction) {
      return fallback
    }
    if (
      typeof valueOrFunction === 'string' ||
      valueOrFunction instanceof String
    ) {
      return valueOrFunction
    }
    return valueOrFunction(notification, t)
  }

  return {
    title: computeValue(titleOrFullName, `${type}Title`),
    body: computeValue(notification.body, `${type}Body`),
    positive: computeValue(positive, `${type}Positive`),
    negative: computeValue(negative, `${type}Negative`),
    shouldConfirm,
    isAcceptableInvitation,
    isAccepted,
    isRejected,
    baseType,
    grey: isAcceptedGrey,
    acceptedType,
    rejectedType,
  }
}

export const Notification = ({
  notification,
  isDark,
  isRead, // TODO what/why is this?
  markAsRead,
  updateNotificationType,
  isToast,
}) => {
  const classes = useStyles({ isDark, isRead, isToast })
  const { t } = useTranslation('notifications')
  const [isConfirm, setIsConfirm] = useState(false)

  const notificationStructureId = notification.payload?.structureId
  const notificationCollaboratorId = notification.payload?.collaboratorId
  const notificationShareId = notification.payload?.shareId
  const notificationContributorId = notification.payload?.contributorId
  const notificationNftSplitId = notification.payload?.splitId

  const { actions, anyLoading } = useWithAsyncAction({
    acceptCollaboration: CollaboratorsApi.acceptCollaboration,
    rejectCollaboration: CollaboratorsApi.rejectCollaboration,
    acceptShare: SharesApi.acceptShare,
    revokeShare: SharesApi.revokeShare,
    acceptContribution: ContributorsApi.postAcceptContribution,
    rejectContribution: ContributorsApi.postRejectContribution,
    splitAction: NftApi.nftSplitAction,
  })

  const {
    title,
    body,
    positive,
    negative,
    shouldConfirm,
    isAcceptableInvitation,
    isAccepted,
    isRejected,
    baseType,
    grey,
    acceptedType,
    rejectedType,
  } = useMemo(
    () => computeNotificationValues(t, notification, isConfirm),
    [isConfirm, notification, t]
  )

  const acceptNotification = useCallback(
    () => updateNotificationType(notification.id, acceptedType),
    [notification, acceptedType, updateNotificationType]
  )

  const rejectNotification = useCallback(
    () => updateNotificationType(notification.id, rejectedType),
    [notification, rejectedType, updateNotificationType]
  )

  const handleAcceptCollaboration = useCallback(async () => {
    if (isAccepted) {
      navigateTo(notification.type, notification.payload)
    } else {
      await actions.acceptCollaboration(
        notificationStructureId,
        notificationCollaboratorId
      )
    }
  }, [
    actions,
    isAccepted,
    notification.payload,
    notification.type,
    notificationCollaboratorId,
    notificationStructureId,
  ])

  const handleRejectCollaboration = async () => {
    await actions.rejectCollaboration(
      notificationStructureId,
      notificationCollaboratorId
    )
  }

  const handleAcceptShare = async () => {
    if (isAccepted) {
      navigate(
        `${NAVIGATION_PATHS.PROFILE}?tab=${PROFILE_PAGES.PRIVATE_SHARES}`
      )
    } else {
      await actions.acceptShare(notificationShareId)
    }
  }

  const handleRejectShare = async () => {
    await actions.revokeShare(notificationShareId)
  }

  const handleAcceptContribution = async () => {
    if (isAccepted) {
      navigateTo(notification.type, notification.payload)
    } else {
      await actions.acceptContribution(
        notificationStructureId,
        notificationContributorId
      )
    }
  }

  const handleRejectContribution = async () => {
    await actions.rejectContribution(
      notificationStructureId,
      notificationContributorId
    )
  }

  const handleAcceptNftSplit = async () => {
    if (isAccepted) {
      navigateTo(notification.type, notification.payload)
    } else {
      await actions.splitAction(notificationNftSplitId, SPLIT_ACTIONS.APPROVE)
    }
  }

  const handleRejectNftSplit = async () => {
    await actions.splitAction(notificationNftSplitId, SPLIT_ACTIONS.REJECT)
  }

  const determineNotificationProfileImage = () => {
    if (notification.senderUser?.profileImageUrl) {
      return buildCloudFrontImgUrl(notification.senderUser?.profileImageUrl)
    }
    return ''
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const determineAcceptAction = () => {
    switch (baseType) {
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION:
        return handleAcceptCollaboration()
      case NOTIFICATION_TYPES.SHARE_INVITATION:
        return handleAcceptShare()
      case NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION:
        return handleAcceptContribution()
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED:
        return handleAcceptNftSplit()
      default:
        return null
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const determineRejectAction = () => {
    switch (baseType) {
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION:
        return handleRejectCollaboration()
      case NOTIFICATION_TYPES.SHARE_INVITATION:
        return handleRejectShare()
      case NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION:
        return handleRejectContribution()
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED:
        return handleRejectNftSplit()
      default:
        return null
    }
  }

  const handleNotificationClick = () => {
    markAsRead(notification.id)
    navigateTo(notification.type, notification.payload)
  }

  const handleAcceptClick = useCallback(
    async event => {
      event.stopPropagation()
      if (isConfirm) {
        await determineRejectAction()
        rejectNotification()
        setIsConfirm(false)
        return
      }
      await determineAcceptAction()
      if (!isAccepted) {
        acceptNotification()
      }
    },
    [
      isConfirm,
      determineAcceptAction,
      isAccepted,
      acceptNotification,
      determineRejectAction,
      rejectNotification,
    ]
  )

  const handleRejectClick = useCallback(
    async event => {
      event.stopPropagation()
      if (!isConfirm && shouldConfirm) {
        setIsConfirm(true)
        return
      }
      if (isConfirm) {
        setIsConfirm(false)
        return
      }
      await determineRejectAction(notification.type)
      rejectNotification()
    },
    [
      isConfirm,
      shouldConfirm,
      determineRejectAction,
      notification.type,
      rejectNotification,
    ]
  )

  return (
    <div
      className={cn({
        [classes.notification]: true,
        [classes.notificationDark]: !isToast && isDark,
        [classes.notificationConfirm]: isConfirm,
        [classes.notificationUnread]: !isToast && !notification.isRead,
      })}
      onClick={handleNotificationClick}
    >
      <div className={classes.notificationImageContainer}>
        <UserImage
          userImageUrl={determineNotificationProfileImage()}
          size={40}
          isRounded
        />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.inlineOrFlex}>
          <div className={classes.notificationName}>{title}</div>
          {!isToast && (
            <div className={classes.notificationTime}>
              {formatDate(notification.createdAt)}
            </div>
          )}
        </div>
        <div className={cn(classes.notificationMessage, classes.inlineOrFlex)}>
          {body}
        </div>
        {isAcceptableInvitation && !isRejected && (
          <div className={classes.notificationButtonContainer}>
            <SButton
              accept={true}
              disabled={grey || anyLoading}
              onClick={handleAcceptClick}
            >
              {positive.toUpperCase()}
            </SButton>
            {!isAccepted && (
              <SButton
                accept={false}
                variant={VARIANTS.CANCEL_TEXT}
                disabled={grey || anyLoading}
                onClick={handleRejectClick}
              >
                {negative}
              </SButton>
            )}
            {anyLoading && (
              <div className={classes.notificationLoader}>
                <LogoLoader />
              </div>
            )}
          </div>
        )}
        {isToast && (
          <div className={classes.notificationTime}>
            {formatDate(notification.createdAt)}
          </div>
        )}
      </div>
    </div>
  )
}

Notification.propTypes = {
  notification: shape({
    body: string,
    createdAt: string,
  }).isRequired,
  isRead: bool.isRequired,
  updateNotificationType: func.isRequired,
  isDark: bool,
  isToast: bool,
  markAsRead: func.isRequired,
}

Notification.defaultProps = {
  isDark: false,
  isToast: false,
}
