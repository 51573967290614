import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { string, node, func, bool, number } from 'prop-types'
import { ArtistIdText } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-text'

const useStyles = makeStyles(theme => ({
  container: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    display: 'grid',
    gridTemplateColumns: '1em auto',
    gap: 20,
  },
  radio: {
    backgroundColor: theme.palette.background.default,
    '-webkit-appearance': 'none',
    appearance: 'none',
    margin: 0,
    font: `100%`,
    color: 'white',
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    transform: 'translateY(-0.075em)',
    display: 'grid',
    placeContent: 'center',
    '&:checked': {
      '&::before': {
        transform: 'scale(1)',
      },
    },
    '&::before': {
      content: '""',
      width: 14,
      height: 14,
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: '120ms transform ease-in-out',
      boxShadow: `inset .99em .99em ${theme.palette.primary.main}`,
    },
  },
}))

export const ArtistIdRadioButton = ({
  setOptionFn,
  option,
  type,
  children,
  withoutOr,
  title,
  hide,
}) => {
  const radioRef = useRef()
  const classes = useStyles()
  const { t } = useTranslation('distro')

  if (hide) {
    if (option === type) {
      setOptionFn(type + 1)
    }
    return null
  }
  const onLabelClick = () => {
    if (option !== type) {
      radioRef.current.click()
    }
  }

  return (
    <>
      <label className={classes.container} onClick={onLabelClick}>
        <input
          ref={radioRef}
          name="artistId"
          type="radio"
          checked={option === type}
          className={classes.radio}
          onClick={setOptionFn(type)}
        />
        {t(title)}
        {children}
      </label>
      {!withoutOr && <ArtistIdText text="or" />}
    </>
  )
}

ArtistIdRadioButton.propTypes = {
  setOptionFn: func.isRequired,
  option: number.isRequired,
  type: number.isRequired,
  children: node,
  withoutOr: bool,
  title: string.isRequired,
  hide: bool,
}

ArtistIdRadioButton.defaultProps = {
  hide: false,
  withoutOr: false,
  children: undefined,
}
