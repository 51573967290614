import * as yup from 'yup'
import i18next from 'i18next'
import { FIELDS, HAS_VOCALS_OPTIONS } from '#pages/distro/_utils/constants'

const defaultValidation = field =>
  yup
    .string()
    .trim()
    .max(
      255,
      i18next.t('validation:maximumLength', {
        field,
        number: 255,
      })
    )
    .nullable()

const requiredDefaultValidation = field =>
  yup
    .string()
    .trim()
    .required(
      i18next.t('validation:mustNotBeEmpty', {
        field,
      })
    )
    .max(
      255,
      i18next.t('validation:maximumLength', {
        field,
        number: 255,
      })
    )
    .nullable()

const genreValidation = field =>
  yup.object().shape({
    value: yup.string().required(
      i18next.t('validation:mustNotBeEmpty', {
        field,
      })
    ),
    label: yup.string(),
  })

const secondaryGenreValidation = (field, t) =>
  yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable()
    .when(field, (primaryGenreId, schema) => {
      return schema.test({
        test: secondaryGenreId => {
          if (primaryGenreId?.value && secondaryGenreId?.value) {
            return primaryGenreId.value !== secondaryGenreId.value
          }
          return true
        },
        message: t('differentSecondaryGenre'),
      })
    })

const selectContentValidation = field =>
  yup
    .object()
    .default({})
    .shape({
      value: yup.string().required(
        i18next.t('validation:mustNotBeEmpty', {
          field,
        })
      ),
      label: yup.string(),
    })

const checkboxValidation = () =>
  yup.boolean().oneOf([true], i18next.t('validation:requiredField'))

const yearValidation = () => {
  const currentYear = new Date().getFullYear()
  return yup
    .number()
    .typeError(i18next.t('validation:requiredField'))
    .min(1000, i18next.t('validation:yearMustBeMore'))
    .max(
      currentYear,
      i18next.t('validation:yearMustBeLess', {
        year: currentYear,
      })
    )
}

const releasedBeforeValidation = t =>
  yup
    .string()
    .nullable()
    .when(FIELDS.IS_PREVIOUSLY_RELEASED, {
      is: val => val?.value === true,
      then: yup
        .string()
        .trim()
        .required(
          i18next.t('validation:mustNotBeEmpty', {
            field: t('previousReleaseDate'),
          })
        ),
      otherwise: yup.string().nullable(),
    })

export const metadataValidationSchema = (t, profileName, songName) => {
  const primaryArtistName = t('primaryArtistName')
  const songNameLabel = t('songName')
  const genre = t('genre')
  const explicitContent = t('explicitContent')
  const performanceLanguage = t('performanceLanguage')
  const songLyrics = t('songLyrics')

  return yup.object().shape({
    [FIELDS.PRIMARY_ARTIST_NAME]: profileName
      ? defaultValidation(primaryArtistName)
      : requiredDefaultValidation(primaryArtistName),
    [FIELDS.SONG_FILE_METADATA_SONG_NAME]: songName
      ? defaultValidation(songNameLabel)
      : requiredDefaultValidation(songNameLabel),
    [FIELDS.SONG_FILE_METADATA_GENRE]: genreValidation(genre),
    [FIELDS.SONG_FILE_METADATA_SECONDARY_GENRE]: secondaryGenreValidation(
      FIELDS.SONG_FILE_METADATA_GENRE,
      t
    ),
    [FIELDS.SONG_FILE_METADATA_EXPLICIT_CONTENT]:
      selectContentValidation(explicitContent),
    [FIELDS.SONG_FILE_METADATA_ISRC]: yup
      .string()
      .nullable()
      .max(15)
      .matches(/^[A-Z]{2}-[A-Z0-9]{3}-[0-9]{2}-[0-9]{5}/i, {
        message: t('isrcMustFollowPattern'),
        excludeEmptyString: true,
      }),
    [FIELDS.SONG_FILE_METADATA_LYRICS]: yup
      .string()
      .when(FIELDS.HAS_VOCALS, {
        is: val => val?.value === HAS_VOCALS_OPTIONS[0].value,
        then: yup
          .string()
          .trim()
          .required(
            i18next.t('validation:mustNotBeEmpty', {
              field: songLyrics,
            })
          )
          .max(
            50000,
            i18next.t('validation:maximumLength', {
              field: songLyrics,
              number: 50000,
            })
          ),
        otherwise: yup.string().nullable(),
      })
      .nullable(),
    [FIELDS.SONG_FILE_METADATA_PERFORMANCE_LANGUAGE]: yup
      .object()
      .when(FIELDS.HAS_VOCALS, {
        is: val => val?.value === HAS_VOCALS_OPTIONS[0].value,
        then: selectContentValidation(performanceLanguage, t),
        otherwise: yup
          .object()
          .shape({
            value: yup.string(),
            label: yup.string(),
          })
          .nullable(),
      }),
  })
}

export const featuredArtistsValidationSchema = t => {
  const featuredArtistName = t('featuredArtistName')

  return yup.object().shape({
    [FIELDS.SONG_FILE_METADATA_FEATURED_ARTISTS]:
      requiredDefaultValidation(featuredArtistName),
  })
}

export const releaseDetailsValidationSchema = t => {
  const releaseName = t('releaseName')
  const primaryArtistName = t('primaryArtistName')
  const labelName = t('labelName')
  const genre = t('genre')
  const releaseCopyrightOwner = t('releaseCopyrightOwner')
  const artWorkCopyrightOwner = t('artWorkCopyrightOwner')
  const explicitContent = t('explicitContent')
  const releaseCopyrightYear = t('releaseCopyrightYear')
  const artWorkCopyrightYear = t('artWorkCopyrightYear')

  return yup.object().shape({
    [FIELDS.RELEASE_NAME]: requiredDefaultValidation(releaseName),
    [FIELDS.PRIMARY_ARTIST_NAME]: defaultValidation(primaryArtistName),
    [FIELDS.LABEL_NAME]: defaultValidation(labelName),
    [FIELDS.GENRE]: selectContentValidation(genre),
    [FIELDS.SECONDARY_GENRE]: secondaryGenreValidation(FIELDS.GENRE, t),
    [FIELDS.EXPLICIT_CONTENT]: selectContentValidation(explicitContent),
    [FIELDS.RECORDING_YEAR]: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable(),
    [FIELDS.RELEASE_COPYRIGHT_OWNER]: requiredDefaultValidation(
      releaseCopyrightOwner
    ),
    [FIELDS.ARTWORK_COPYRIGHT_OWNER]: requiredDefaultValidation(
      artWorkCopyrightOwner
    ),
    [FIELDS.IS_PREVIOUSLY_RELEASED]: yup
      .object()
      .default({})
      .shape({
        value: yup.bool().required(i18next.t('validation:requiredField')),
        label: yup.string(),
      }),
    [FIELDS.PREVIOUSLY_RELEASED_DATE]: releasedBeforeValidation(t),
    [FIELDS.RELEASE_COPYRIGHT_YEAR]:
      selectContentValidation(releaseCopyrightYear),
    [FIELDS.ARTWORK_COPYRIGHT_YEAR]:
      selectContentValidation(artWorkCopyrightYear),
    [FIELDS.ARTWORK]: yup
      .string()
      .trim()
      .required(i18next.t('validation:requiredField')),
    [FIELDS.TEXT_MATCHES]: checkboxValidation(t),
    [FIELDS.NO_OTHER_TEXT]: checkboxValidation(t),
    [FIELDS.NO_LOGOS]: checkboxValidation(t),
    [FIELDS.CLEAR_ARTWORK]: checkboxValidation(t),
    [FIELDS.ARTWORK_EXPLICIT]: yup
      .object()
      .default({})
      .shape({
        value: yup.string().required(i18next.t('validation:requiredField')),
        label: yup.string(),
      }),
    [FIELDS.RELEASE_VERSION]: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .nullable(),
    [FIELDS.RECORDING_DATE]: yearValidation(t),
  })
}

export const deliveryAndReleaseDateValidationSchema = (t, hasCountries) => {
  return yup.object().shape({
    [FIELDS.ESTIMATED_RELEASE_DATE]: yup
      .string()
      .required(i18next.t('validation:requiredField'))
      .nullable(),
    [FIELDS.MUSIC_STORES]: yup.array().required(t('atLeastOneStore')),
    ...(!hasCountries && {
      [FIELDS.COUNTRIES]: yup.array().required(t('atLeastOneCountry')),
    }),
  })
}

export const validateFiles = files => {
  const errors = []
  const validFiles = files.filter(file => {
    if (file.size === 0) {
      const errorMessage = i18next.t('validation:errorEmptyFile', {
        name: file.name,
      })
      errors.push(errorMessage)
      return null
    }
    // eslint-disable-next-line no-control-regex,unicorn/escape-case
    if (/[^\u0000-\u00ff]/g.test(file.name)) {
      const errorMessage = i18next.t('validation:errorWrongChar', {
        name: file.name,
      })
      errors.push(errorMessage)
      return null
    }
    if (file.size > process.env.REACT_APP_VAULT_MAX_FILE_SIZE) {
      const errorMessage = i18next.t('validation:errorTooLarge', {
        name: file.name,
      })
      errors.push(errorMessage)
      return null
    }
    return file
  })

  return {
    validFiles,
    errors,
  }
}

export const validateNewArtistName = fieldName =>
  yup.object().shape({
    serachArtistName: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: fieldName,
        })
      ),
  })
