import React from 'react'
import styled, { css } from 'styled-components'
import { arrayOf, shape, string, bool, func } from 'prop-types'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import { LoadingImage } from '#components/loaders/loading-image'
import { useTranslation } from 'react-i18next'

const SDropdown = styled.div`
  width: 100%;
  max-height: 12rem;
  overflow-y: scroll;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  position: absolute;
  top: 4.85rem;
  left: 0;
  z-index: 1000;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
  border-top: none;
  transition: background-color 0.3s linear;
`

const SOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const SOption = styled.li`
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    disabled,
  }) => (disabled ? COLORS.neutral_60 : COLORS.neutral_0)};

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({
        theme: {
          tunego: { COLORS },
        },
      }) => COLORS.neutral_70};
  }

  ${({
    theme: {
      tunego: { COLORS },
    },
    disabled,
  }) =>
    disabled
      ? css`
          color: ${COLORS.neutral_60};
        `
      : css`
          color: ${COLORS.neutral_0};

          &:hover {
            background-color: ${COLORS.neutral_85};
          }
        `};
`

const SImg = styled.img`
  height: 2rem;
  width: 2rem;
  object-fit: cover;
`

const SLoadingWrapper = styled.span`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

const SLoadingIndicator = styled(LoadingImage)`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`

export const Dropdown = ({ items, showImg, isLoading, onSelect }) => {
  const { t } = useTranslation('common')

  return (
    <SDropdown>
      {isLoading ? (
        <SLoadingWrapper>
          <SLoadingIndicator />
          {t('loading')}
        </SLoadingWrapper>
      ) : (
        <SOptions>
          {items.map(item => (
            <SOption
              key={item.value}
              disabled={item.disabled}
              {...(!item.disabled && { onClick: () => onSelect(item) })}
            >
              {showImg && <SImg src={item.img || DEFAULT_AVATAR} />}
              {item.label}
            </SOption>
          ))}
        </SOptions>
      )}
    </SDropdown>
  )
}

Dropdown.propTypes = {
  items: arrayOf(
    shape({
      id: string,
      label: string,
      img: string,
      value: string,
      disabled: bool,
    })
  ),
  isLoading: bool,
  onSelect: func,
  showImg: bool,
}
