import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { string } from 'prop-types'
import { SupportedFileTypes } from '#pages/distro/wizard/steps/add-song-files/components/supported-file-types'
import { PROFILE_STATUS } from '../../../_utils/constants'

const useStyles = makeStyles(theme => ({
  info: {
    fontSize: 16,
    marginTop: 30,
    whiteSpace: 'pre-line',
    fontFamily: props => props.isBold && theme.typography.bold,
  },
}))

export const NoFilesForNft = ({ profileStatus }) => {
  const { t } = useTranslation('nft')
  const isNoProperData =
    profileStatus === PROFILE_STATUS.NO_DATA ||
    profileStatus === PROFILE_STATUS.NO_PROPER_DATA
  const classes = useStyles({
    isBold: isNoProperData,
  })

  if (!isNoProperData) {
    return null
  }

  return (
    <>
      <p className={classes.info}>{t('noFiles')}</p>
      <SupportedFileTypes />
    </>
  )
}

NoFilesForNft.propTypes = {
  profileStatus: string,
}

NoFilesForNft.defaultProps = {
  profileStatus: undefined,
}
