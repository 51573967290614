import React, { useEffect } from 'react'
import { func } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { StylesGrid } from '#pages/mastering/wizard/steps/options-step/components/styles-grid'
import { OptionsGrid } from '#pages/mastering/wizard/steps/options-step/components/options-grid'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  options: {
    marginTop: '2.5rem',
  },
}))

export const OptionsContainer = ({ setNextDisabled }) => {
  const { watch } = useFormContext()
  const classes = useStyles()
  const genre = watch('genre')
  const profile = watch('profile')

  useEffect(() => {
    if (genre) {
      setNextDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genre, profile])

  return (
    <div className={classes.options}>
      <StylesGrid />
      <OptionsGrid />
    </div>
  )
}

OptionsContainer.propTypes = {
  setNextDisabled: func.isRequired,
}
