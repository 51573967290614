import React from 'react'
import { SPLITS_COLUMNS } from '#pages/sell-collectibles/create-collectible/_constants/splits'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SRow = styled.tr`
  height: 3.75rem;
`

const SCell = styled.td`
  padding: 0 2rem;
`

export const TableBody = () => {
  const { t } = useTranslation('nftWizard')

  // FIXME
  const mockedData = [
    {
      id: '1',
      recipient: 'DONNIE TEST!',
      role: 'owner',
      email: 'dmcgaughey+testdata-1@tunego.com',
      splits: 100,
      status: 'new',
    },
  ]

  return (
    <tbody>
      {mockedData.map(entry => (
        <SRow key={entry.id}>
          {SPLITS_COLUMNS(t).map(col => (
            <SCell key={`${entry.id}.${col.value}`}>{entry[col.value]}</SCell>
          ))}
        </SRow>
      ))}
    </tbody>
  )
}
