import React from 'react'
import { useTranslation } from 'react-i18next'
import { func, bool, arrayOf, shape, string } from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ProfileCard } from '#pages/vault/profiles/components/profile-card'
import { BaseLoader } from '#components/loaders/base-loader'
import styled from 'styled-components'

const SProfiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem 1.5rem 1rem 1rem;
  gap: 1rem;
`

export const ProfilesList = ({ loadMore, hasMore, items }) => {
  const { t } = useTranslation('vault')

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={loadMore}
      hasMore={hasMore}
      loader={<BaseLoader isLoading="true" text={t('loadingArtistProfiles')} />}
      endMessage=""
    >
      <SProfiles>
        {items?.map(artist => (
          <ProfileCard key={artist?.id} artist={artist} />
        ))}
      </SProfiles>
    </InfiniteScroll>
  )
}

ProfilesList.propTypes = {
  loadMore: func.isRequired,
  hasMore: bool.isRequired,
  items: arrayOf(
    shape({
      id: string,
      category: string,
      name: string,
    })
  ).isRequired,
}
