import React from 'react'
import { MenuItem } from '@material-ui/core'
import { string, func, arrayOf, shape } from 'prop-types'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

export const FolderMenu = ({
  handleClose,
  id,
  folderOptions,
  setTypeOfCurrentAction,
  setIdOfActiveActionItem,
  setStructureCategory,
}) => {
  const onFolderMenuItemClickHandler = (option, type) => {
    setStructureCategory(STRUCTURE_TYPE.ALBUM)
    setIdOfActiveActionItem(id)
    setTypeOfCurrentAction(type)
    handleClose()
    option && option()
  }

  return (
    <div>
      {folderOptions.map(({ type, title, option }) => (
        <MenuItem
          key={`${type}folder`}
          onClick={() => onFolderMenuItemClickHandler(option, type)}
        >
          {title}
        </MenuItem>
      ))}
    </div>
  )
}

FolderMenu.propTypes = {
  handleClose: func,
  setTypeOfCurrentAction: func.isRequired,
  setIdOfActiveActionItem: func.isRequired,
  id: string.isRequired,
  folderOptions: arrayOf(
    shape({
      type: string,
      title: string,
    })
  ).isRequired,
  setStructureCategory: func.isRequired,
}

FolderMenu.defaultProps = {
  handleClose: () => {},
}
