export const AUDIO_MAX_SIZE = 15000000
export const WAV_MAX_SIZE = 100000000
export const IMAGE_MAX_SIZE = 15000000

export const GIF_MAX_SIZE = 25000000

export const ACCEPTED_AUDIO_FORMATS = {
  'audio/*': [
    'flac',
    'wav',
    'wave',
    'ogg',
    'mp3',
    'm4a',
    'opus',
    'oga',
    'mka',
    'aif',
    'aiff',
  ],
}

export const ACCEPTED_IMAGE_FORMATS = {
  'image/*': ['gif', 'jpeg', 'jpg', 'png'],
}

export const GIF_EXTENSION = 'gif'
export const WAV_EXTENSION = 'audio/wav'
