import React from 'react'
import { arrayOf, func, shape, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const STabs = styled.div`
  max-width: 1050px;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  margin: 0 auto;
`

const STab = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  padding: 5px 30px;
  height: 100%;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      font-weight: 700;
      border-bottom: 2px solid ${({ theme: { colors } }) => colors.primary};
    `}
`

export const Tabs = ({ tabs, activeTab, onChangeTab }) => {
  const { t } = useTranslation('vault')

  return (
    <STabs>
      {tabs.map(({ label, value }) => {
        return (
          <STab
            key={label}
            isActive={value === activeTab}
            onClick={() => onChangeTab(value)}
          >
            {t(label)}
          </STab>
        )
      })}
    </STabs>
  )
}

Tabs.propTypes = {
  tabs: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ).isRequired,
  activeTab: string.isRequired,
  onChangeTab: func.isRequired,
}
