import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonSize, ButtonTheme } from '@tunego/tunego-ui-components'
import { Modal } from '#components/modal'
import { VaultModalContainer } from '#pages/vault/profile-content/containers/vault-modal-container'
import { FormProvider, useForm } from 'react-hook-form'
import { ContactAutocomplete } from '#pages/sell-collectibles/create-collectible/_components/steps/splits/_components/contact-autocomplete'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { BaseInput } from '#components/inputs/base-input'
import styled from 'styled-components'

const SBaseInput = styled(BaseInput)`
  width: 100%;
  margin: 1rem 0 2rem;
`

const SRolesSelect = styled(RolesSelect)`
  margin-top: 1rem;
`
export const AddSplit = () => {
  const { t } = useTranslation('nftWizard')
  const [modal, setModal] = useState(false)
  const methods = useForm()

  const addSplitRecipient = () => {
    // TODO
  }

  return (
    <FormProvider {...methods}>
      <Button
        buttonTheme={ButtonTheme.Secondary}
        buttonSize={ButtonSize.Md}
        onClick={() => setModal(true)}
      >
        {t('steps.splits.addSplit')}
      </Button>
      <Modal
        isOpen={modal}
        setIsOpen={setModal}
        closeBtn
        onClose={methods.reset}
      >
        <VaultModalContainer
          title={t('steps.splits.addSplit')}
          btnText={t('steps.splits.add')}
          onSubmit={methods.handleSubmit(addSplitRecipient)}
        >
          <ContactAutocomplete />
          {/* TODO: fix styling */}
          <SRolesSelect control={methods.control} isBolded={false} />
          <SBaseInput
            name="split"
            inputRef={methods.register}
            label={t('steps.splits.enterSplit')}
            type="number"
            defaultValue={0}
            max={100}
          />
        </VaultModalContainer>
      </Modal>
    </FormProvider>
  )
}
