/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { S3Bucket } from './s3bucket'
import { buildUrl } from '#utils/buildUrl'
import { getFileInfo } from '#utils/getFileInfo'

const HEARTBEAT_INTERVAL = Number(process.env.REACT_APP_ACCESS_NODE || 30000)

export class FilesApi {
  static getFile = id => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILES_FILE, {
        id,
      })
    )
  }

  static postFile = data => {
    return getApiClient().post(ENDPOINTS.FILES, data)
  }

  static updateFile = (id, data) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.FILES_FILE, {
        id,
      }),
      data
    )
  }

  static getFileDownloadLink = (fileId, save) => {
    const savePart = save ? '?save=true' : ''
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILE_DOWNLOAD_LINK, {
        fileId,
      }) + savePart
    )
  }

  static getFileUploadLink = fileId => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILE_UPLOAD_LINK, {
        id: fileId,
      })
    )
  }

  static deleteFile = id => {
    return getApiClient().delete(
      buildUrl(ENDPOINTS.FILES_FILE, {
        id,
      })
    )
  }

  static uploadFileHealthCheck = id => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.FILE_UPLOAD_LINK_HEALTH_CHECK, {
        id,
      })
    )
  }

  static checkForExistingFileName = (structureId, fileName) =>
    getApiClient().get(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_FILENAME, {
        structureId,
        fileName,
      })
    )

  static getListenLink = fileId => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILE_LISTEN_LINK, {
        fileId,
      })
    )
  }

  // TODO: check heartbeats for multipart
  static postHeartbeat = (fileId, progress) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.FILE_HEARTBEAT, {
        fileId,
      }),
      { progress }
    )
  }

  static uploadMultiPartFile = (fileId, contentType) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.FILE_MULTIPART_UPLOAD, {
        fileId,
      }),
      {
        contentType,
      }
    )
  }

  static getSignedPart = (fileId, params) => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILE_SIGN_PART, {
        fileId,
      }),
      { params }
    )
  }

  static getSignedPartsList = (fileId, uploadId) => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.FILE_PARTS_LIST, {
        fileId,
        uploadId,
      })
    )
  }

  static completeMultiPartUpload = (fileId, uploadId) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.FILE_MULTI_UPLOAD_COMPLETE, {
        fileId,
        uploadId,
      })
    )
  }

  static abortMultiPartUpload = (fileId, uploadId) => {
    return getApiClient().delete(
      buildUrl(ENDPOINTS.FILE_DELETE_MULTIPART_UPLOAD, {
        fileId,
        uploadId,
      })
    )
  }

  static manageUploadFile = async (
    structureId,
    file,
    onUploadProgress = () => {},
    createProject
  ) => {
    let heartbeatTimeout = null
    let isComplete = false
    try {
      const { fileSuffix, name, category, fileSize } = getFileInfo(file)
      const { data } = await this.postFile({
        fileSuffix,
        fileSize,
        name,
        category,
        structureId,
        createProject,
      })
      // TODO better handling ?
      if (!data?.id) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('No id returned for file upload')
      }
      const {
        data: { url, fields },
      } = await this.getFileUploadLink(data?.id)
      let progress = 0
      const sendHeartbeat = () => {
        if (isComplete) {
          return
        }
        if (heartbeatTimeout) {
          clearTimeout(heartbeatTimeout)
          heartbeatTimeout = null
        }
        this.postHeartbeat(data?.id, progress).finally(() => {
          heartbeatTimeout = setTimeout(sendHeartbeat, HEARTBEAT_INTERVAL)
        })
      }
      const uploadProgressProxy = e => {
        progress = Math.round((e.loaded / e.total) * 100)
        onUploadProgress(e, file)
      }
      heartbeatTimeout = setTimeout(sendHeartbeat, HEARTBEAT_INTERVAL)
      await S3Bucket.uploadImg({
        url,
        file,
        fields,
        headers: {
          'Content-Disposition': `attachment; filename=${name}.${fileSuffix}`,
        },
        onUploadProgress: e => uploadProgressProxy(e),
      })
      if (heartbeatTimeout) {
        clearTimeout(heartbeatTimeout)
      }
      isComplete = true
      await this.uploadFileHealthCheck(data?.id)
      return data
    } catch (err) {
      // noinspection PointlessBooleanExpressionJS
      if (heartbeatTimeout) {
        clearTimeout(heartbeatTimeout)
      }
      isComplete = true
      throw err
    }
  }
}
