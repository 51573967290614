import * as yup from 'yup'
import i18next from 'i18next'

export const nameValidationSchema = field => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field,
        })
      )
      .max(
        100,
        i18next.t('validation:maximumLength', {
          field,
          number: 100,
        })
      ),
  })
}
