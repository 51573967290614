import React from 'react'
import { arrayOf, shape, string, objectOf, func, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MIN_ROW_HEIGHT } from '#pages/vault/song/tabs/files/utils/constants'
import { ChevronIcon } from '#pages/vault/song/tabs/files/components/chervron-icon'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    background: theme.palette.color.minorGrey,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    width: '100%',
    paddingLeft: '1rem',
    minHeight: MIN_ROW_HEIGHT,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
  },
  sortingChevron: {
    cursor: 'pointer',
    marginLeft: 5,
  },
  activeSortingChevron: {
    borderBottom: `1px solid ${theme.palette.color.default}`,
  },
  ascending: {
    transform: 'rotate(180deg)',
    borderBottom: 'none',
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'theme.typography.h2.fontFamily',
    fontSize: '14px',
  },
}))

export const TableHeader = ({ headerItems, withSorting }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  // eslint-disable-next-line consistent-return
  const renderSorting = (header, index) => {
    if (withSorting && index !== headerItems.length - 1) {
      const {
        shouldSortAscending,
        setShouldSortAscending,
        sortBy,
        setSortBy,
        setPageIndex,
      } = withSorting

      return (
        <div
          className={`${classes.sortingChevron} ${classes.headerWrapper} ${
            sortBy === header.sortBy && classes.activeSortingChevron
          }`}
        >
          <ChevronIcon
            width={22}
            height={22}
            isActive={sortBy === header.sortBy}
            className={`${
              shouldSortAscending &&
              sortBy === header.sortBy &&
              classes.ascending
            }`}
            onClick={() => {
              setPageIndex(0)
              setSortBy(header.sortBy)
              setShouldSortAscending(prevState => !prevState)
            }}
          />
        </div>
      )
    }
  }

  return (
    <div className={classes.header}>
      {headerItems.map((header, index) => (
        <div
          key={header.name}
          className={classes.headerItem}
          style={{
            justifyContent:
              index === 0 ? 'flex-start' : index > 1 ? 'center' : '',
            width: header.width,
          }}
        >
          {t(header.label || header.name)}
          {renderSorting(header, index)}
        </div>
      ))}
    </div>
  )
}

TableHeader.propTypes = {
  headerItems: arrayOf(
    shape({
      name: string,
      width: objectOf(string),
    })
  ).isRequired,
  withSorting: shape({
    shouldSortAscending: bool.isRequired,
    setShouldSortAscending: func.isRequired,
    sortBy: string.isRequired,
    setSortBy: func.isRequired,
    setPageIndex: func.isRequired,
  }),
}

TableHeader.defaultProps = {
  withSorting: undefined,
}
