import { NAVIGATION_PATHS } from '#routes/routes'
import { PRICE_TYPE } from '#constants/priceTypes'

export const PROMOTION_STATUS = {
  UNPAID: 'unpaid',
  PENDING: 'pending',
  COMPLETED: 'completed',
  ERROR: 'error',
}

export const REVIEW_LEVEL = {
  GOLD: 'gold',
  PLATINUM: 'platinum',
  DIAMOND: 'diamond',
}

export const promotionBreadcrumbs = t => [
  {
    title: t('promotion'),
    link: NAVIGATION_PATHS.PROMOTION,
  },
  {
    title: t('reports'),
    link: NAVIGATION_PATHS.PROMOTION_REPORTS,
  },
]

export const SYNC_LICENSING_ICONS = {
  espn: '/assets/images/brands/espn.png',
  bod: '/assets/images/brands/bod.png',
  bsports: '/assets/images/brands/bsports.png',
  ncaa: '/assets/images/brands/ncaa.png',
  pbc: '/assets/images/brands/pbc.png',
  nhl: '/assets/images/brands/nhl.png',
  mlb: '/assets/images/brands/mlb.png',
  nba: '/assets/images/brands/nba.png',
  pga: '/assets/images/brands/pga.png',
  nascar: '/assets/images/brands/nascar.png',
  mbenz: '/assets/images/brands/mbenz.png',
  infiniti: '/assets/images/brands/infiniti.png',
  nissan: '/assets/images/brands/nissan.png',
  pubg: '/assets/images/brands/pubg.png',
  snapchat: '/assets/images/brands/snapchat.png',
  fx: '/assets/images/brands/fx.png',
  npr: '/assets/images/brands/npr.png',
  cbs: '/assets/images/brands/cbs.png',
  freeform: '/assets/images/brands/freeform.png',
  dove: '/assets/images/brands/dove.png',
}

export const HEADERS = [
  { nameTranslationKey: `date`, width: '11%' },
  {
    nameTranslationKey: `artistProfile`,
    width: '14%',
  },
  {
    nameTranslationKey: `songName`,
    width: '18%',
  },
  {
    nameTranslationKey: `trackRating`,
    width: '8%',
  },
  {
    nameTranslationKey: `passionRating`,
    width: '9%',
  },
  {
    nameTranslationKey: `reviewType`,
    width: '9%',
  },
  {
    nameTranslationKey: `orderId`,
    width: '21%',
  },
  {
    nameTranslationKey: `status`,
    width: '10%',
  },
]

export const SUPPORTED_PROMOTION_FILE_TYPES = ['MP3', 'WAV', 'OGG', 'M4A']

export const PROMOTION_GENRES = [
  { label: 'Ballad', value: '2' },
  { label: 'Classical', value: '4' },
  { label: 'Opera', value: '5' },
  { label: 'Country', value: '8' },
  { label: 'Dance', value: '10' },
  { label: 'Drum and Bass', value: '11' },
  { label: 'Electronic', value: '12' },
  { label: 'Electro/Dance', value: '13' },
  { label: 'Folk', value: '18' },
  { label: 'Pop Folk', value: '19' },
  { label: 'Alternative', value: '22' },
  { label: 'Indie', value: '23' },
  { label: 'Blues', value: '26' },
  { label: 'Jazz', value: '27' },
  { label: 'Comedy', value: '30' },
  { label: 'Experimental', value: '31' },
  { label: 'Holiday', value: '32' },
  { label: 'Latin', value: '33' },
  { label: 'New Age', value: '34' },
  { label: 'Progressive', value: '35' },
  { label: 'Religious', value: '36' },
  { label: 'Soundtrack', value: '37' },
  { label: 'Spoken Word', value: '38' },
  { label: 'World', value: '39' },
  { label: 'Acoustic', value: '46' },
  { label: 'Easy Listening', value: '47' },
  { label: 'Pop', value: '48' },
  { label: 'Reggae', value: '50' },
  { label: 'Ska', value: '51' },
  { label: 'Emo', value: '54' },
  { label: 'Hardcore', value: '55' },
  { label: 'Metal', value: '56' },
  { label: 'New Wave', value: '57' },
  { label: 'Pop Rock', value: '58' },
  { label: 'Pop Punk', value: '59' },
  { label: 'Punk', value: '60' },
  { label: 'Punk Rock', value: '61' },
  { label: 'Punk Metal', value: '62' },
  { label: 'Rock', value: '63' },
  { label: 'Screamo', value: '64' },
  { label: 'Funk', value: '70' },
  { label: 'Hip Hop/Rap', value: '71' },
  { label: 'R&B/Soul', value: '72' },
]

export const OPTIONS = [
  {
    name: 'crowdReviewGold',
    price: PRICE_TYPE.REVIEW_GOLD,
    reviewers: 30,
    description: 'forSongsEarlyInTheCreative',
    value: 'gold',
  },
  {
    name: 'crowdReviewPlatinum',
    price: PRICE_TYPE.REVIEW_PLATINUM,
    reviewers: 75,
    description: 'forSongsMidwayToLateIn',
    value: 'platinum',
  },
  {
    name: 'crowdReviewDiamond',
    price: PRICE_TYPE.REVIEW_DIAMOND,
    reviewers: 200,
    description: 'forSongsReadyForTheWorld',
    value: 'diamond',
  },
]

export const REVIEW_OPTIONS = [
  {
    value: 'gold',
    label: 'crowdReviewGold',
    price: 'review-gold',
  },
  {
    value: 'platinum',
    label: 'crowdReviewPlatinum',
    price: 'review-platinum',
  },
  {
    value: 'diamond',
    label: 'crowdReviewDiamond',
    price: 'review-diamond',
  },
]

export const TABLE_KEYS = [
  'price',
  'reviewers',
  'trackRating',
  'demographics',
  'reviewWordCloud',
  'wordCloudSentiments',
  'reviewerComments',
  'marketPotential',
  'distributionRatings',
  'passingRating',
  'inGenreClassification',
  'genreTrackPositioning',
  'elementAnalysis',
  'marketPotentialInGenre',
  'majorLabelTrackPositioning',
  'sampleGroup',
  'similarArtistsMentioned',
]

export const TABLE_KEY_TOOLTIPS = [
  'tooltips.price',
  'tooltips.reviewers',
  'tooltips.trackRating',
  'tooltips.demographics',
  'tooltips.reviewWordCloud',
  'tooltips.wordCloudSentiments',
  'tooltips.reviewerComments',
  'tooltips.marketPotential',
  'tooltips.distributionRatings',
  'tooltips.passingRating',
  'tooltips.inGenreClassification',
  'tooltips.genreTrackPositioning',
  'tooltips.elementAnalysis',
  'tooltips.marketPotentialInGenre',
  'tooltips.majorLabelTrackPositioning',
  'tooltips.sampleGroup',
  'tooltips.similarArtistsMentioned',
]

export const TABLE_COLUMNS = [
  ['review-gold', '30', true, true, true, true, true],
  [
    'review-platinum',
    '75',
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
  [
    'review-diamond',
    '200',
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
]

export const MARKET_RATING_KEYS = [
  'average',
  'above-average',
  'good',
  'very-good',
  'excellent',
]

export const SONG_REPORT_NAVIGATION = {
  trackRatingCard: {
    title: 'trackRating',
    element: 'trackRatingEl',
  },
  passionTargetMarketing: {
    title: 'passionTargetMarketing',
    element: 'passionTargetMarketingEl',
  },
  marketPotential: {
    title: 'marketPotential',
    element: 'marketPotentialEl',
  },
  marketPotentialInGenre: {
    title: 'marketPotentialInGenre',
    element: 'marketPotentialInGenreEl',
  },
  distributionOfRatings: {
    title: 'distributionOfRatings',
    element: 'distributionOfRatingsEl',
  },
  majorLabelTrackRating: {
    title: 'majorLabelTrackRating',
    element: 'majorLabelTrackRatingEl',
  },
  genreTrackRating: {
    title: 'genreTrackRating',
    element: 'genreTrackRatingEl',
  },
  wordCloud: {
    title: 'worldCloud',
    element: 'wordCloudEl',
  },
  elementAnalysis: {
    title: 'elementAnalysis',
    element: 'elementAnalysisEl',
  },
  similarArtistsMentioned: {
    title: 'similarArtistsMentioned',
    element: 'similarArtistsMentionedEl',
  },
  reviewerDemographics: {
    title: 'reviewerDemographics',
    element: 'reviewerDemographicsEl',
  },
  reviewerComments: {
    title: 'reviewerComments',
    element: 'reviewerCommentsEl',
  },
}
