import { NAVIGATION_PATHS } from '#routes/routes'

export const NAV_BREAKPOINT = 1280

export const getNavItems = ({
  enableWalletConnect,
  enableBlog,
  enablePersona,
  enableGettingStarted,
}) => [
  ...(enableGettingStarted
    ? [
        {
          title: 'Getting Started',
          link: NAVIGATION_PATHS.GETTING_STARTED,
        },
      ]
    : []),
  {
    title: 'Sell Your Music',
    link: NAVIGATION_PATHS.SELL_COLLECTIBLES,
  },
  ...(enableWalletConnect
    ? [
        {
          title: 'Connect Wallet',
          link: NAVIGATION_PATHS.CONNECT_WALLET,
        },
      ]
    : []),
  {
    title: 'Vault',
    link: NAVIGATION_PATHS.VAULT_PROFILES,
  },
  ...(enablePersona
    ? [
        {
          title: 'Create Persona',
          link: NAVIGATION_PATHS.CREATE_PROFILE,
        },
      ]
    : []),
  ...(enableBlog
    ? [
        {
          title: 'Blog',
          link: NAVIGATION_PATHS.LEARN_WEB3,
        },
      ]
    : []),
]
