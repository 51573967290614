import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, string, oneOfType } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(theme => ({
  ratingCardContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  ratingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '124px',
    color: 'white',
    boxShadow: `inset 0 0 45px ${theme.palette.color.primary}`,
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    border: `1px solid ${theme.palette.color.primary}`,
    background: hexToRgba(theme.palette.color.primary, 0.25),
    width: '40%',
  },
  descriptionBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 24,
    border: `1px solid ${theme.palette.color.primary}`,
    backgroundColor: theme.palette.color.primary,
    width: '60%',
  },
}))

export const TrackRatingCard = ({ rating }) => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.ratingCardContainer}>
      <div className={classes.ratingBox}>{rating}</div>
      <div className={classes.descriptionBox}>
        <Text type={TEXT_TYPE.SUBTITLE1}>{t('trackRating')}</Text>
        <span>{t('yourTrackRatingIsTheOverall')}</span>
      </div>
    </div>
  )
}

TrackRatingCard.propTypes = {
  rating: oneOfType([number, string]).isRequired,
}
