import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Hero } from '#components/hero'
import { BolderTranslation } from '#components/bolder-translation'

export const TopSection = () => {
  const { t } = useTranslation()
  const videogoTranslation = useTranslation('videogo')

  return (
    <Hero
      header={videogoTranslation.t('letsGoVideo')}
      subheaderComponent={
        <Trans
          t={videogoTranslation.t}
          i18nKey="keep100OfYourSalesRevenue"
          components={[<BolderTranslation key={0} />]}
        />
      }
      buttonOneUrl={`${NAVIGATION_PATHS.VIDEO_GO}/coming-soon`}
      buttonTwoUrl={`${NAVIGATION_PATHS.VIDEO_GO}/coming-soon`}
      buttonOneText={t('getStarted')}
      buttonTwoText={videogoTranslation.t('viewPastReleases')}
      bgUrl="/assets/images/video-bg.png"
    />
  )
}
