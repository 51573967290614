import React, { useEffect } from 'react'
import { bool, shape, string } from 'prop-types'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import styled from 'styled-components'
import { useSong } from '#pages/sell-collectibles/create-collectible/_hooks/use-song'
import { useTranslation } from 'react-i18next'
import { BOTTOM_PANEL_HEIGHT } from '#pages/sell-collectibles/create-collectible/_constants/ui'
import { FilePreviewLabel } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/file-preview-label'
import { LoadingIndicator } from '#components/loaders/loading-indicator'
import { isFileReady } from '#pages/sell-collectibles/create-collectible/_utils'

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`

const SButton = styled.button`
  flex-grow: 0;
  flex-shrink: 0;
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  border: none;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s linear;

  &:disabled {
    background-color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};

    img {
      opacity: 0.8;
    }
  }
`

const SSongTitle = styled.p`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['2xl']};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    notReady,
  }) => (notReady ? COLORS.neutral_70 : COLORS.neutral_0)};
  overflow: hidden;
  max-width: 28rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SPlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SWarning = styled.p`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

export const PlaySong = ({ file, profileId }) => {
  const { t } = useTranslation('nftWizard')

  const { setFileInfo, fileInfo, setOffset } = useGlobalAudioPlayer()

  useEffect(() => {
    setOffset(BOTTOM_PANEL_HEIGHT)

    return () => {
      setOffset(0)
    }
  }, [])

  const song = useSong(file)

  const processing = !isFileReady(file)

  const handlePlay = () => {
    const { id: fileId, name: fileName, fileSuffix: suffix } = file

    setFileInfo({
      fileId,
      profileId,
      uri: song,
      fileName,
      suffix,
    })
  }
  return (
    <SPlayWrapper>
      <FilePreviewLabel file={!!song} />
      {song && file && (
        <>
          <SWrapper>
            <SButton
              disabled={file.id === fileInfo?.fileId}
              onClick={handlePlay}
            >
              <img src="assets/svg/ic-play.svg" alt={t('play')} />
            </SButton>
            <SSongTitle notReady={processing || !file.isNftAllowed}>
              {file.name}
            </SSongTitle>
            {processing && <LoadingIndicator />}
          </SWrapper>
          {!processing && !file.isNftAllowed && (
            <SWarning>{t('steps.files.uploadOrSelectAnotherFile')}</SWarning>
          )}
        </>
      )}
    </SPlayWrapper>
  )
}

PlaySong.propTypes = {
  file: shape({
    id: string,
    name: string,
    fileSuffix: string,
    hasMetadata: bool,
    previewStatus: string,
    isNftAllowed: bool,
  }).isRequired,
  profileId: string.isRequired,
}
