import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 60,
    paddingRight: 16,
    backgroundColor: theme.palette.color.darkBlue09,
    width: '100%',
  },
}))

const SButton = styled(Button)`
  height: 30px;
  padding: 0 20px;
`

export const ButtonRow = ({ handleOpenContributorModal }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.actions}>
      <SButton
        variant={VARIANTS.NEW_BLUE_SPECIAL}
        onClick={handleOpenContributorModal}
      >
        {`+ ${t('addContributor')}`}
      </SButton>
    </div>
  )
}

ButtonRow.propTypes = {
  handleOpenContributorModal: func.isRequired,
}
