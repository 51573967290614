import React, { createContext } from 'react'
import { node } from 'prop-types'
import { useUsersMe } from '#hooks/swr/useUsersMe'
import { formatPrice } from '#utils/formatPrice'
import { useIsAuth } from '#hooks/useIsAuth'

export const UserContext = createContext({
  user: {},
  mutate: () => {},
  priceOf: () => {},
  formattedPriceOf: () => {},
})

const { Provider } = UserContext

export const UserProvider = ({ children }) => {
  const isAuthenticated = useIsAuth()

  const { user, mutate } = useUsersMe(isAuthenticated)
  const priceOf = type => user?.subscription?.subscriptionPlan?.priceMap?.[type]

  const formattedPriceOf = type => {
    const price = priceOf(type)?.value
    return formatPrice(price)
  }

  const value = { user, mutate, priceOf, formattedPriceOf }

  return <Provider {...{ value }}>{children}</Provider>
}

UserProvider.propTypes = {
  children: node.isRequired,
}
