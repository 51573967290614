import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  descriptionWrapper: {
    marginBottom: '0.5rem',
    padding: '3rem',
    paddingBottom: '1.5rem',
  },
}))

export const Description = () => {
  const classes = useStyles()
  const { t } = useTranslation('connect')

  return (
    <div className={classes.descriptionWrapper}>
      {t('downloadTuneGoConnect')}
    </div>
  )
}
