import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { number, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { makeStyles } from '@material-ui/core'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { Button } from '#components/button'
import { ArtistIdInput } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-input'
import { validateNewArtistName } from '#pages/distro/_utils/validation'
import { FIELDS, ARTIST_ID_PLATFORM } from '#pages/distro/_utils/constants'
import { VARIANTS } from '#components/button/constants'

const useStyles = makeStyles(() => ({
  searchWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}))

const SButton = styled(Button)`
  width: 140px;
  margin-right: 0;
  margin-top: 29px;
`

export const SearchArtistIdsForm = ({ setIds, option, onFocus }) => {
  const name = FIELDS.SEARCH_ARTIST_NAME
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(
      validateNewArtistName(distroTranslation.t('artistName'))
    ),
    mode: 'onChange',
  })

  useEffect(() => {
    if (option !== 3) {
      reset({ [name]: '' })
      setIds(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  const {
    actions: { searchArtistIds },
  } = useWithAsyncAction({
    searchArtistIds: DistroApi.searchArtistIds,
  })

  const submit = async data => {
    try {
      const res = await searchArtistIds(data[name], ARTIST_ID_PLATFORM.SPOTIFY)
      setIds(res?.data || [])
    } catch (err) {
      setIds([])
    }
  }

  return (
    <form {...{ onSubmit: handleSubmit(submit) }}>
      <div className={classes.searchWrapper}>
        <ArtistIdInput {...{ register, errors, name, onFocus }} />
        <SButton type="submit" variant={VARIANTS.NEW_BLUE_SPECIAL}>
          {t('search')}
        </SButton>
      </div>
    </form>
  )
}

SearchArtistIdsForm.propTypes = {
  setIds: func.isRequired,
  option: number.isRequired,
  onFocus: func.isRequired,
}
