import React, { useEffect } from 'react'
import { string, func, bool } from 'prop-types'
import { useFile } from '#hooks/swr/useFiles'
import { FILE_PREVIEW_STATUS } from '#pages/vault/song/tabs/files/utils/constants'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { debounce } from 'lodash'
import { FILE_CHECK_DEBOUNCE } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_constants'
import { LoadingImage } from '#components/loaders/loading-image'
import { Button } from '@tunego/tunego-ui-components'

const SContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SLoadingImage = styled(LoadingImage)`
  height: 3.75rem;
`

const SText = styled.p`
  margin-bottom: 2.5rem;
`

const STitle = styled.h3`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['3xl']};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  margin-bottom: 0;
`

export const UploadedFileIndicator = ({
  fileId,
  closeDisabled,
  onFinishProcessing,
  onClose,
}) => {
  const { t } = useTranslation('nftWizard')
  const { file, mutate, isLoading, isValidating } = useFile(fileId)

  const isProcessing =
    file?.previewStatus === FILE_PREVIEW_STATUS.PROCESSING ||
    file?.previewStatus === FILE_PREVIEW_STATUS.PENDING

  const isNftReady = file?.isNftAllowed || file?.isNftImageAllowed

  const debouncedMutate = debounce(mutate, FILE_CHECK_DEBOUNCE)

  useEffect(() => {
    if (file && (!isProcessing || isNftReady)) {
      onFinishProcessing()
    } else if (!isLoading && !isValidating) {
      debouncedMutate()
    }
  }, [file, isLoading, isValidating])

  return (
    <SContainer>
      <SLoadingImage />
      <STitle>{t('steps.files.processingInProgress')}</STitle>
      <SText>{t('steps.files.processingText')}</SText>
      {!closeDisabled && (
        <Button onClick={onClose}>{t('steps.files.close')}</Button>
      )}
    </SContainer>
  )
}

UploadedFileIndicator.propTypes = {
  fileId: string.isRequired,
  onFinishProcessing: func,
  onClose: func,
  closeDisabled: bool,
}
