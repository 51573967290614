import React from 'react'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import {
  PROFILE_PAGES,
  SUBSCRIPTION_PLANS,
} from '#pages/profile/utils/constants'
import { formatBytes } from '#pages/profile/utils/helpers'
import { NAVIGATION_PATHS } from '#routes/routes'
import { ConfirmationModal } from '#components/confirmation-modal'
import { useUser } from '#hooks/useUser'
import styled from 'styled-components'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 43.75rem;
  min-height: 9.375rem;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  text-align: center;
`

const STitle = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.warning_50};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['7xl']};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin-bottom: ${({
    theme: {
      tunego: { SPACING },
    },
  }) => SPACING.sm};
  text-transform: uppercase;
`

const SMessageWrapper = styled.div`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['2xl']};
  line-height: 1.625rem;
  margin-bottom: ${({
    theme: {
      tunego: { SPACING },
    },
  }) => SPACING['3xl']};
`

const SPlanName = styled.span`
  display: inline;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin: 0 0.25rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['2xl']};
  line-height: 1.625rem;
  text-transform: uppercase;
`

const SBoldText = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const SubscriptionBackdropMessage = ({
  onBackdropClick,
  isOwner,
  structureName,
}) => {
  const { t } = useTranslation('profile')
  const { user } = useUser()

  const currentPlanLevel = user?.subscription.subscriptionPlan.tierLevel
  const currentPlanName = SUBSCRIPTION_PLANS[currentPlanLevel]

  const handleNavigate = () => {
    navigate(NAVIGATION_PATHS.PROFILE, {
      state: { tab: PROFILE_PAGES.SUBSCRIPTION },
    })
  }

  const title = isOwner ? t('itsTimeToUpgrade') : t('warning')

  return (
    <ConfirmationModal
      confirmationText={t('viewSubscriptions')}
      cancelText={t('noThanks')}
      isOwner={isOwner}
      isOpened
      isWarning
      isUpgrade
      onClose={onBackdropClick}
      onConfirm={handleNavigate}
    >
      <SWrapper>
        <STitle>{title}</STitle>
        {isOwner ? (
          <SMessageWrapper>
            {t('yourTuneGo')}
            <SPlanName>{t(currentPlanName)}</SPlanName>
            {t('subscriptionAllowsUpTo', {
              projects: user?.limits['project-count'].limit,
              storage: formatBytes(user?.limits['files-size'].limit),
            })}
          </SMessageWrapper>
        ) : (
          <SMessageWrapper>
            <SBoldText>
              {t('theProfileHasReached', {
                name: structureName,
              })}
            </SBoldText>
            {t('pleaseContactTheCreator')}
          </SMessageWrapper>
        )}
      </SWrapper>
    </ConfirmationModal>
  )
}

SubscriptionBackdropMessage.propTypes = {
  onBackdropClick: func.isRequired,
  isOwner: bool.isRequired,
  structureName: string.isRequired,
}
