import React, { useRef, useCallback, useState, useLayoutEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, objectOf, number, bool, func } from 'prop-types'
import * as d3 from 'd3'
import * as d3cloud from 'd3-cloud'
import useTheme from '@material-ui/core/styles/useTheme'

import { useWindowSize } from '#hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chart: {
    zIndex: '2',
  },
  barBg: {
    position: 'absolute',
    background: 'transparent',
    boxShadow: `inset 0 0 45px ${theme.palette.color.primary}`,
    zIndex: '1',
    border: `1px solid ${theme.palette.color.primary}`,
  },
}))

export const WordCloudGraph = ({
  data,
  domain = [],
  fontSizeRange = [],
  hoverEffects,
  onSelect,
}) => {
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()
  const theme = useTheme()
  const margin = 20
  const formatedData = data.map(obj => {
    const [key] = Object.keys(obj)
    return { word: key, value: obj[key] }
  })
  const [chartSize, setChartSize] = useState(null)

  const calculateWithMargin = value => value - 2 * margin

  const svgContainerRefMeasured = useCallback(node => {
    if (node !== null) {
      svgContainerRef.current = node
      setChartSize([
        calculateWithMargin(node.parentElement.offsetWidth),
        calculateWithMargin(node.parentElement.offsetHeight),
      ])
    }
  }, [])

  useLayoutEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(svgContainerRef.current.parentElement.offsetWidth),
        calculateWithMargin(svgContainerRef.current.parentElement.offsetHeight),
      ]
    setChartSize(getContainerSize())
  }, [windowSize])

  const draw = () => {
    if (!chartSize) {
      return
    }

    // reset
    d3.select(svgContainerRef.current).select('svg').remove()

    // scale
    const scale = d3.scaleLinear().domain(domain).range(fontSizeRange)

    // chart init
    const chart = d3
      .select(`.${classes.chartWrapper}`)
      .append('svg')
      .attr('width', chartSize[0] + 2 * margin)
      .attr('height', chartSize[1] + 2 * margin)
      .append('g')
      .attr(
        'transform',
        `translate(${chartSize[0] / 2 + margin}, ${chartSize[1] / 2 + margin})`
      )

    const drawWords = words => {
      const cloud = chart.selectAll().data(words)

      // Add words
      const word = cloud
        .enter()
        .append('text')
        .style('font-family', d =>
          d.value > domain[1] / 2
            ? 'AvenirNextLTPro-Bold'
            : 'AvenirNextLTPro-Regular'
        )
        .style('fill', d =>
          d.value === domain[1]
            ? theme.palette.color.white
            : theme.palette.color.primary
        )
        .attr('text-anchor', 'middle')
        .attr('font-size', d => `${scale(d.value)}px`)
        .text(d => d.word)
        .attr('transform', d => `translate(${d.x},${d.y})`)
        .style('fill-opacity', 1)
        .style('cursor', hoverEffects ? 'pointer' : 'default')

      if (hoverEffects) {
        word
          // eslint-disable-next-line func-names
          .on('mouseover', function (d) {
            d3.select(this)
              .transition()
              .duration('150')
              .style('font-size', `${scale(d.value) + 5}px`)
          })
          // eslint-disable-next-line func-names
          .on('mouseout', function (d) {
            d3.select(this)
              .transition()
              .duration('150')
              .style('font-size', `${scale(d.value)}px`)
          })
          // eslint-disable-next-line func-names
          .on('click', function (d) {
            onSelect(d.word)
          })
      }

      cloud.exit().style('fill-opacity', 1e-6).attr('font-size', 1).remove()
    }

    // Init d3Cloud lib
    d3cloud()
      .font("'AvenirNextLTPro-Bold', Impact")
      .size(chartSize)
      .words(formatedData)
      .padding(2)
      .rotate(() => 0)
      .text(d => d.word)
      .fontSize(d => scale(d.value))
      .on('end', drawWords)
      .start()
  }

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (!document.fonts) {
      return draw() // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    document.fonts.load('normal 100px AvenirNextLTPro-Bold').then(() => draw()) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSize])

  return (
    <div
      ref={svgContainerRefMeasured}
      id="chartWrapper"
      className={classes.chartWrapper}
    />
  )
}

WordCloudGraph.propTypes = {
  data: arrayOf(objectOf(number)).isRequired,
  domain: arrayOf(number).isRequired,
  fontSizeRange: arrayOf(number).isRequired,
  hoverEffects: bool,
  onSelect: func,
}

WordCloudGraph.defaultProps = {
  hoverEffects: false,
  onSelect: () => ({}),
}
