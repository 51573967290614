import React from 'react'
import { node, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  invite: {
    color: theme.palette.color.primary,
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export const SideSwitcherText = ({ children, onClick }) => {
  const classes = useStyles()

  return (
    <span className={classes.invite} onClick={onClick}>
      {children}
    </span>
  )
}

SideSwitcherText.propTypes = {
  children: node.isRequired,
  onClick: func.isRequired,
}
