import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SEmptyMessage = styled.div`
  padding: 26px;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_10%']};
`

export const EmptyContent = ({ type }) => {
  const { t } = useTranslation('validation')

  return (
    <SEmptyMessage>
      {t('emptyStructure', {
        structure: type,
      })}
    </SEmptyMessage>
  )
}

EmptyContent.propTypes = {
  type: string.isRequired,
}
