import React, { useCallback, useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { string, func } from 'prop-types'
import { navigate } from '@reach/router'
import { hexToRgba } from '#utils/hexToRgba'
import { FilesApi } from '#api/requests/files'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { useFile } from '#hooks/swr/useFiles'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: `1px solid ${hexToRgba(theme.palette.color.default, 0.33)}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
    marginBottom: 8,
    width: 300,
    height: 120,
  },
  fileIcon: {
    background: hexToRgba(theme.palette.color.primary, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 120,
  },
  informational: {
    width: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  title: {
    width: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
  },
  songName: {
    width: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
  fileInfoWrapper: {
    cursor: 'pointer',
    margin: '16px 0 16px 16px',
  },
  playButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 8px',
  },
  playButton: {
    fontSize: 40,
    background: theme.palette.color.primary,
    borderRadius: '50%',
    width: 60,
    height: 60,
    padding: 5,
    cursor: 'pointer',
  },
}))

export const FileBox = ({ fileId, setIsOpened }) => {
  const classes = useStyles()
  const { setFileInfo } = useGlobalAudioPlayer()
  const {
    file,
    isLoading: fileIsLoading,
    isError: fileIsError,
  } = useFile(fileId)
  const [songName, setSongName] = useState('')

  useEffect(() => {
    setSongName(
      file?.profile ? `${file?.profile?.name} · ${file?.project?.name}` : ''
    )
  }, [file])

  const onListen = useCallback(async () => {
    if (!file) return
    const {
      data: { link },
    } = await FilesApi.getListenLink(fileId)
    setFileInfo({
      fileId,
      profileId: file?.profile?.id,
      folderId: file?.folder?.id,
      projectId: file?.project?.id,
      uri: link,
      fileName: file?.name,
      projectName: songName,
      suffix: file?.fileSuffix,
    })
    setIsOpened(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, fileId, songName])

  if (fileIsLoading || fileIsError || !file) {
    return (
      <div className={classes.wrapper}>
        <Typography className={classes.informational}>
          {fileIsLoading ? 'Loading data...' : 'File is no longer available'}
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.fileIcon}>
        <img
          src="/assets/images/attachment-icon.png"
          width="62px"
          height="56px"
          alt="Attachment"
        />
      </div>
      <div
        className={classes.fileInfoWrapper}
        onClick={() => {
          navigate(
            buildUrl(NAVIGATION_PATHS.SONG, {
              artistId: file?.profile?.id,
              projectId: file?.projectId,
            })
          )
        }}
      >
        <Typography className={classes.title}>{file?.name || ''}</Typography>
        <Typography className={classes.songName}>{songName}</Typography>
      </div>
      <div className={classes.playButtonWrapper}>
        <PlayArrowIcon className={classes.playButton} onClick={onListen} />
      </div>
    </div>
  )
}

FileBox.propTypes = {
  fileId: string.isRequired,
  setIsOpened: func.isRequired,
}
