import React, { useEffect, useState } from 'react'
import {
  CROP_SHAPE_TYPES,
  CROPPER_NFT_ASPECT_RATIO,
} from '#components/image-cropper/constants'
import { ImageCropSettings } from '#components/image-cropper/components/image-crop-settings'
import { useImageCropper } from '#components/image-cropper/hooks/useImageCropper'
import { Button } from '@tunego/tunego-ui-components'
import { UPLOAD_STATUS } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_constants'
import { func, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ErrorMessage } from '#components/error-message'
import styled from 'styled-components'
import {
  ARTWORK_DIMENSIONS,
  ARTWORK_MIN_SIDE_LENGTH,
} from '#pages/sell-collectibles/create-collectible/_constants/form'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  width: 100%;
  min-height: 500px;
  justify-content: center;
  overflow: hidden;
`

const cropperStyle = {
  containerStyle: {
    height: '280px',
  },
}

export const CropImage = ({ file, setUploadStatus, updateImage }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [invalidSize, setInvalidSize] = useState(false)
  const { t } = useTranslation('nftWizard')

  const { croppedAreaPixels, onCropConfirm, clearCropSettings, ...props } =
    useImageCropper({
      image: URL.createObjectURL(file),
      acceptedFile: file,
      updateImage,
    })

  useEffect(() => {
    if (!croppedAreaPixels) {
      setInvalidSize(false)
      return
    }

    const { width, height } = croppedAreaPixels
    setInvalidSize(
      width < ARTWORK_MIN_SIDE_LENGTH || height < ARTWORK_MIN_SIDE_LENGTH
    )
  }, [croppedAreaPixels])

  useEffect(() => {
    return () => {
      clearCropSettings()
    }
  }, [])

  const handleSave = async () => {
    setLoading(true)
    try {
      await onCropConfirm()
      setUploadStatus(UPLOAD_STATUS.SELECT_SINGLE)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SWrapper>
      <ImageCropSettings
        image={URL.createObjectURL(file)}
        cropShape={CROP_SHAPE_TYPES.RECT}
        aspect={CROPPER_NFT_ASPECT_RATIO}
        customStyle={cropperStyle}
        {...props}
      />

      <Button disabled={invalidSize} loading={loading} onClick={handleSave}>
        {t('steps.files.cropImage')}
      </Button>
      {(error || invalidSize) && (
        <ErrorMessage
          errorMessage={
            invalidSize
              ? t('steps.files.imageSizeError', {
                  ...croppedAreaPixels,
                  dimensions: ARTWORK_DIMENSIONS,
                })
              : t('steps.files.cropError')
          }
        />
      )}
    </SWrapper>
  )
}

CropImage.propTypes = {
  file: object.isRequired,
  updateImage: func,
}
