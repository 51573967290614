export const MASTER_STATUS = {
  PREVIEW_PROCESSING: 'preview-processing',
  PREVIEW_READY: 'preview-ready',
  MASTER_PROCESSING: 'master-processing',
  MASTER_READY: 'master-ready',
  PENDING: 'pending-payment',
}

export const MASTERING_STEP = {
  CHOOSE_PROFILE: 1,
  ADD_TRACK: 2,
  OPTIONS: 3,
  MASTERING: 4,
  CHECKOUT: 5,
}
