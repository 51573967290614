export const HEADERS = [
  {
    name: 'User',
    desktopWidth: '25%',
    textAlign: '',
    paddingRight: '',
  },
  {
    name: 'Activity',
    desktopWidth: '50%',
    textAlign: '',
    paddingRight: '',
  },
  {
    name: 'Most recent',
    desktopWidth: '25%',
    textAlign: 'right',
    paddingRight: 2,
  },
]
