export const CROPPER_MIN_ZOOM = 1
export const CROPPER_MAX_ZOOM = 3
export const CROPPER_ZOOM_STEP = 0.1
export const CROPPER_MIN_ROTATION = -45
export const CROPPER_MAX_ROTATION = 45
export const CROPPER_DEFAULT_ROTATION = 0
export const CROPPER_ROTATION_STEP = 1
export const CROPPER_VAULT_ASPECT_RATIO = 180.25 / 188
export const CROPPER_NFT_ASPECT_RATIO = 1
export const CROPPER_DEFAULT_COORDINATES = { x: 0, y: 0 }
export const CROP_SHAPE_TYPES = {
  ROUND: 'round',
  RECT: 'rect',
}
