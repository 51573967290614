import React, { useState } from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ImageCropper } from '#components/image-cropper'
import { AddProfileImage } from '#pages/onboarding/wizard/steps/add-profile-image'

export const AddProfileImageStepContainer = ({ addProfileImage, nextStep }) => {
  const [preview, setPreview] = useState('')
  const [imageToCrop, setImageToCrop] = useState('')
  const [acceptedFile, setAcceptedFile] = useState('')
  const [imageError, setImageError] = useState('')
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false)
  const { t } = useTranslation('validation')

  const openCropperModal = () => setIsCropperModalOpen(true)
  const closeCropperModal = () => setIsCropperModalOpen(false)

  const onDrop = acceptedFiles => {
    setImageError('')
    setAcceptedFile(acceptedFiles[0])
    setImageToCrop(URL.createObjectURL(acceptedFiles[0]))
    openCropperModal()
  }

  const onDropRejected = ([rejectedFile]) => {
    const { errors } = rejectedFile

    setImageError(errors?.[0]?.message || t('requestNoDescription'))
  }

  return (
    <>
      <AddProfileImage
        nextStep={nextStep}
        imageError={imageError}
        preview={preview}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
      />
      <ImageCropper
        isOpen={isCropperModalOpen}
        updateImage={addProfileImage}
        acceptedFile={acceptedFile}
        setPreview={setPreview}
        image={imageToCrop}
        onClose={closeCropperModal}
      />
    </>
  )
}

AddProfileImageStepContainer.propTypes = {
  addProfileImage: func.isRequired,
  nextStep: func.isRequired,
}
