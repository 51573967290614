import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  line: {
    backgroundColor: theme.palette.color.darkGrey84,
    width: '25%',
    height: '3px',
    margin: '1px 0',
  },
  triggerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const DraggingTrigger = props => {
  const classes = useStyles()
  return (
    <div className={classes.triggerContainer} {...props}>
      <div className={classes.line} />
      <div className={classes.line} />
    </div>
  )
}
