import React from 'react'
import { string } from 'prop-types'
import DoneIcon from '@material-ui/icons/Done'
import styled from 'styled-components'

const SSelectedFileToast = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.primary80};
  height: 60px;
  font-size: 14px;
  padding: 0 32px;
  position: sticky;
  top: 160px;
  z-index: 10;
`

export const SelectedFileToast = ({ selectedFileName }) => {
  return (
    <SSelectedFileToast>
      {selectedFileName}
      <DoneIcon />
    </SSelectedFileToast>
  )
}

SelectedFileToast.propTypes = {
  selectedFileName: string.isRequired,
}
