import React from 'react'
import { string, number, bool, node } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { navigate } from '@reach/router'
import styled from 'styled-components'

const SStep = styled.p`
  text-transform: uppercase;
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  margin: 0;
  font-family: ${({ theme: { typography } }) => typography.bold};
  color: ${({ theme: { colors } }) => colors.primary};
`

const SStepTitle = styled.h2`
  margin: 0;
  text-transform: uppercase;
  font-size: ${({ theme: { fontSize } }) => fontSize['4xl']};
  font-family: ${({ theme: { typography } }) => typography.bold};
`

const SText = styled.p`
  white-space: pre-wrap;
  max-width: 53.75rem;
`

const SButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 2rem;
`

export const Step = ({ title, index, text, withButton, buttonText, href }) => {
  const { t } = useTranslation('gettingStarted')

  const onClick = () => {
    if (href) navigate(href)
  }

  return (
    <li>
      <SStep>{t('step', { index })}</SStep>
      <SStepTitle>{title}</SStepTitle>
      <SText>{text}</SText>
      {withButton && <SButton onClick={onClick}>{buttonText}</SButton>}
    </li>
  )
}

Step.propTypes = {
  title: string.isRequired,
  index: number.isRequired,
  text: node.isRequired,
  withButton: bool.isRequired,
  buttonText: string,
  href: string,
}
