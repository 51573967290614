import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StepTitle } from '#pages/sell-collectibles/landing-page/_components/step-title'

const SStart = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 95vw;
  margin: auto;

  @media screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    max-width: 62rem;
  }
`

const SText = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

export const Start = () => {
  const { t } = useTranslation('sellCollectibles')
  return (
    <SStart>
      <img src="/assets/svg/ic-money.svg" alt="" />
      <StepTitle>{t('start.title')}</StepTitle>
      <SText>{t('start.text')}</SText>
    </SStart>
  )
}
