import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { bool } from 'prop-types'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { Button } from '#components/button'
import { ReleaseDetailsItem } from '#pages/distro/past-release-details/components/release-details-item'

const useStyles = makeStyles(theme => ({
  typoLabel: {
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.color.default,
    fontSize: 22,
    marginBottom: 15,
  },
  releaseDetails: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '0.5rem',
  },
  releaseDetailsCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
}))

const SButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  border-radius: 10px;
  text-transform: uppercase;
`

export const ReleaseDetails = ({ isPreview }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStep, storedFormState } = useWizard()

  const handleStepChange = () => setStep(4)

  return (
    <>
      <span className={classes.typoLabel}>{t('releaseDetails')}</span>
      <div className={classes.releaseDetails}>
        <div className={classes.releaseDetailsCol}>
          <ReleaseDetailsItem
            label={t('releaseName')}
            value={storedFormState[FIELDS.RELEASE_NAME]}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('releaseVersion')}
            value={storedFormState[FIELDS.RELEASE_VERSION]?.label}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('primaryArtist')}
            value={storedFormState[FIELDS.PRIMARY_ARTIST_NAME]}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('labelName')}
            value={storedFormState[FIELDS.LABEL_NAME]}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('upc')}
            value={storedFormState[FIELDS.UPC]}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('yearRecorded')}
            value={storedFormState[FIELDS.RECORDING_YEAR]?.value}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('previousReleaseDate')}
            value={
              storedFormState[FIELDS.RELEASED_BEFORE]
                ? dayjs(storedFormState[FIELDS.RELEASED_BEFORE]).format(
                    'MMMM DD, YYYY'
                  )
                : null
            }
          />
        </div>
        <div className={classes.releaseDetailsCol}>
          <ReleaseDetailsItem
            label={t('explicitContent')}
            value={storedFormState[FIELDS.EXPLICIT_CONTENT]?.label}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('genre')}
            value={storedFormState[FIELDS.GENRE]?.label}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('secondaryGenre')}
            value={storedFormState[FIELDS.SECONDARY_GENRE]?.label}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('releaseCopyrightOwner')}
            value={storedFormState[FIELDS.RELEASE_COPYRIGHT_OWNER]}
          />
          <ReleaseDetailsItem
            label={t('releaseCopyrightYear')}
            value={storedFormState[FIELDS.RELEASE_COPYRIGHT_YEAR]?.value}
          />
          <ReleaseDetailsItem
            label={t('artworkCopyrightOwner')}
            value={storedFormState[FIELDS.ARTWORK_COPYRIGHT_OWNER]}
            isBolded
          />
          <ReleaseDetailsItem
            label={t('artworkCopyrightYear')}
            value={storedFormState[FIELDS.ARTWORK_COPYRIGHT_YEAR]?.value}
          />
        </div>
      </div>
      {isPreview && (
        <SButton onClick={handleStepChange}>{t('editReleaseDetails')}</SButton>
      )}
    </>
  )
}

ReleaseDetails.propTypes = {
  isPreview: bool.isRequired,
}
