import styled from 'styled-components'
import { Button } from '#components/button'

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  min-width: 100%;
`

export const VideoContainer = styled.div`
  opacity: 10%;
`

export const Video = styled.video`
  position: absolute;
  top: 50%;
  min-height: 100%;
  min-width: 100%;
  display: block;
  transform: translate(-50%, -50%);
`

export const Particles = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`

export const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const LogoWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
`

export const Logo = styled.img`
  margin-bottom: 24px;
  width: 200px;
`

export const Title = styled.div`
  font-size: 32px;
  text-align: center;
  font-family: 'AvenirNextLTPro-Bold';
  @media (max-width: 600px) {
    font-size: 26px;
  }
`

export const Bold = styled.div`
  font-family: inherit;
  line-height: 100%;
`

export const BuiltFor = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  white-space: pre-line;
  max-with: 650px;
  padding: 8px;
  margin: 0 auto;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto 0px auto;
`
export const SButton = styled(Button)`
  margin-bottom: 40px;
`
