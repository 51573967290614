/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class CollaboratorsApi {
  static postCollaboratorInvite = (structureId, data) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_INVITE, {
        id: structureId,
      }),
      data
    )
  }

  static postCollaboratorReInvite = (structureId, collaborationId) => {
    return getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_COLLABORATOR_REINVITE,
        {
          structureId,
          collaborationId,
        }
      )
    )
  }

  static postCollaborator = (structureId, data) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS, {
        id: structureId,
      }),
      data
    )
  }

  static patchCollaborator = ({ structureId, newRole, collaboratorId }) => {
    return getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_COLLABORATOR, {
        structureId,
        collaboratorId,
      }),
      newRole
    )
  }

  static deleteCollaborator = ({ structureId, collaboratorId }) => {
    return getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_COLLABORATOR, {
        structureId,
        collaboratorId,
      })
    )
  }

  static acceptCollaboration = (structureId, collaborationId) =>
    getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_ACCEPT_COLLABORATION,
        {
          structureId,
          collaborationId,
        }
      )
    )

  static rejectCollaboration = (structureId, collaborationId) =>
    getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS_REJECT_COLLABORATION,
        {
          structureId,
          collaborationId,
        }
      )
    )
}
