import React from 'react'
import { bool, string } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SBox = styled.div`
  padding: 0.75rem;
  margin-top: 0.75rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SInfo = styled(SBox)`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_15%']};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
`

const SWarning = styled(SBox)`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['warning_15%']};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

const SQuery = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const SplitContactInfo = ({ query, isSearchValidEmail }) => {
  const { t } = useTranslation('nftWizard')

  if (query) {
    return (
      <SWarning>
        <Trans
          t={t}
          i18nKey={
            isSearchValidEmail
              ? 'steps.splits.noMatches'
              : 'steps.splits.invalidEmail'
          }
          values={{ query }}
        >
          <SQuery />
        </Trans>
      </SWarning>
    )
  }

  return <SInfo>{t('steps.splits.addExistingContact')}</SInfo>
}

SplitContactInfo.propTypes = {
  query: string,
  isSearchValidEmail: bool,
}
