import io from 'socket.io-client'
import EventEmitter from 'events'
import { API_URL, getAccessToken } from '#api/api/static'

class SocketClient extends EventEmitter {
  initialized = false

  initialize = () => {
    if (this.initialized) {
      return
    }
    this.initialized = true
    const socket = io(API_URL, {
      withCredentials: true,
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${getAccessToken()}`,
          },
        },
      },
    })
    socket.on('connect', () => {
      this.emit('connected')
    })
    socket.on('disconnected', reason => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect()
      } else if (reason !== 'io client disconnect') {
        this.emit('disconnected', reason)
      }
    })
    socket.on('event', event => {
      this.emit('event', event)
      this.emit(`event.${event.type}`, event)
      if (event.routingKey) {
        this.emit(`event.${event.type}.${event.routingKey}`, event)
      }
    })
    this.socket = socket
  }

  updateAuthToken = () => {
    this.socket.io.opts.transportOptions.polling.extraHeaders = {
      authorization: `Bearer ${getAccessToken()}`,
    }
  }
}

export const socketInstance = new SocketClient()
