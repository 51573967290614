import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import styled, { css } from 'styled-components'
import { node, string, bool, func } from 'prop-types'
import { theme } from '#theme/index'

const SWrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const STooltip = styled.div`
  ${({ show }) =>
    show
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
        `};
`

const popperStyle = {
  border: `1px solid ${theme.colors.primary}`,
  borderRadius: 5,
  backgroundColor: theme.colors.black,
  fontSize: theme.fontSize.sm,
  padding: '0.5rem 1rem',
}

const SSpan = styled.span`
  font-weight: inherit;
  font-size: inherit;
  max-width: 100%;
  overflow: hidden;
`

export const PopperTooltip = ({
  children,
  disabled,
  text,
  className,
  placement,
  onOverflow,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const tooltip = useRef(null)
  const tooltipWrapper = useRef(null)

  const show = () => {
    setShowTooltip(true)
  }

  const hide = () => {
    setShowTooltip(false)
  }

  const { styles, attributes, update } = usePopper(
    tooltipWrapper.current,
    tooltip.current,
    {
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'preventOverflow', options: { padding: 20 } },
      ],
    }
  )

  useEffect(() => {
    if (update) update()
  }, [update, showTooltip, disabled])

  useEffect(() => {
    if (!onOverflow) return

    if (tooltip?.current) {
      const { offsetWidth, scrollWidth } = tooltipWrapper.current

      onOverflow(scrollWidth > offsetWidth)
    } else onOverflow(false)
  }, [tooltip])

  return (
    <SWrapper
      ref={tooltipWrapper}
      className={className}
      onBlur={hide}
      onMouseLeave={hide}
    >
      <STooltip
        ref={tooltip}
        style={{ ...styles.popper, ...popperStyle }}
        {...attributes.popper}
        show={!disabled && showTooltip}
      >
        {text}
      </STooltip>
      <SSpan onMouseOver={show} onFocus={show} onClick={show}>
        {children}
      </SSpan>
    </SWrapper>
  )
}

PopperTooltip.propTypes = {
  children: node,
  disabled: bool,
  text: string,
  className: string,
  placement: string,
  onOverflow: func,
}

PopperTooltip.defaultProps = {
  placement: 'top',
}
