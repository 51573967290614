import React, { useState } from 'react'

import { PaymentHistory } from '#pages/profile/tabs/payment-history/components/payment-history'
import { Pagination } from '#components/pagination'
import { usePayments } from '#hooks/swr/usePayments'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    justifyContent: 'center',
    marginTop: '3.125rem',
  },
}))

export const PaymentHistoryContainer = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { payments, count } = usePayments(`?page=${page}&limit=${rowsPerPage}`)
  return (
    <div className={classes.container}>
      <PaymentHistory payments={payments} />
      <Pagination
        count={count || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={setPage}
        onChangeRowsPerPage={nextRowsPerPage => {
          setRowsPerPage(nextRowsPerPage)
          setPage(0)
        }}
      />
    </div>
  )
}
