import { useTranslation } from 'react-i18next'
import { useProfiles } from '#hooks/swr/useProfiles'

export const useGetProfiles = type => {
  const { t } = useTranslation()
  const { profiles } = useProfiles(type)
  const formattedProfiles = profiles
    ? profiles.map(profile => {
        return {
          label: profile.name,
          value: profile.id,
          avatarUrl: profile.avatarUrl,
        }
      })
    : []

  return [
    {
      label: t('allProfiles'),
      value: '',
      avatarUrl: '',
    },
    ...formattedProfiles,
  ]
}
