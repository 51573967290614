import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import { object, shape } from 'prop-types'
import { UsersApi } from '#api/requests/users'
import { ENDPOINTS } from '#api/endpoints'
import { useQuery } from '#hooks/useQuery'
import { PROFILE_PAGES } from '#pages/profile/utils/constants'
import { Header } from '#pages/profile/components/header'
import { NavigationContainer } from '#pages/profile/containers/navigation-container'
import { UserDetailsContainer } from '#pages/profile/tabs/user-details/containers/user-details-container'
import { ProPublishingContainer } from '#pages/profile/tabs/pro-publishing/containers/pro-pubslishing-container'
import { PrivateSharesContainer } from '#pages/profile/tabs/private-shares/containers/private-shares-container'
import { Logout } from '#pages/profile/tabs/logout'
import { SubscriptionContainer } from '#pages/profile/tabs/subscription/containers/subscription-container'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { PaymentMethodsContainer } from '#pages/profile/tabs/payment-methods/containers/payment-methods-container'
import { PaymentHistoryContainer } from '#pages/profile/tabs/payment-history/containers/payment-history-container'
import { PageWrapper } from '#modules/page-wrapper'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { Button } from '#components/button'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles(theme => ({
  overlay: {
    zIndex: 100,
  },
  componentLayout: {
    minHeight: 'calc(100vh - 140px)',
    paddingBottom: '65px',
    display: 'flex',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '300px',
    height: '150px',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.color.white,
    backgroundColor: theme.palette.color.darkGrey,
    border: `1px solid ${theme.palette.color.primary}`,
    padding: '20px',
  },
  wrapper: {
    margin: '60px 100px 0 100px',
  },
  pageWrapper: {
    display: 'flex',
    width: '100%',
  },
}))

const pageWrapperStyles = () => {
  return {
    maxWidth: 1200,
  }
}

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const Profile = ({ location }) => {
  const { state } = location
  const classes = useStyles()
  const { t } = useTranslation()
  const validationTranslation = useTranslation('validation')
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState()
  const [message, setMessage] = useState('')
  const { enableSubscriptions, enablePayment } = useFlags()

  const tabQuery = useQuery('tab')
  const tabState = state?.tab

  const { actions, loading: asyncLoading } = useWithAsyncAction({
    patchUsersMe: UsersApi.patchUsersMe,
  })

  useEffect(() => {
    setCurrentPage(tabState || tabQuery || PROFILE_PAGES.SETTINGS)
  }, [tabState, tabQuery])

  const handleSave = async values => {
    try {
      await actions.patchUsersMe(values)
      mutate(ENDPOINTS.USERS_ME)
    } catch (err) {
      setLoading(false)
      setMessage(
        validationTranslation.t('requestOnlyMessage', {
          message: err.response.data.message,
        })
      )
    }
  }

  const renderCurrentPage = () => {
    const defaultView = (
      <UserDetailsContainer
        handleSave={handleSave}
        setLoading={setLoading}
        setMessage={setMessage}
      />
    )

    switch (currentPage) {
      case PROFILE_PAGES.PRIVATE_SHARES:
        return <PrivateSharesContainer />
      case PROFILE_PAGES.SETTINGS:
        return defaultView
      case PROFILE_PAGES.PRO:
        return <ProPublishingContainer handleSave={handleSave} />
      case PROFILE_PAGES.SUBSCRIPTION:
        return enableSubscriptions ? (
          <SubscriptionContainer handleSave={handleSave} />
        ) : (
          defaultView
        )
      case PROFILE_PAGES.PAYMENT_METHODS:
        return enablePayment ? (
          <PaymentMethodsContainer handleSave={handleSave} />
        ) : (
          defaultView
        )
      case PROFILE_PAGES.PAYMENT_HISTORY:
        return enablePayment ? <PaymentHistoryContainer /> : defaultView
      case PROFILE_PAGES.LOGOUT:
        return <Logout />
      default:
        return defaultView
    }
  }

  return (
    <PageWrapper makeCustomStyles={pageWrapperStyles} showFooter showNavBar>
      <Backdrop
        open={!!message}
        className={classes.overlay}
        onClick={() => setMessage('')}
      >
        <div className={classes.box}>
          {message}
          <SButton onClick={() => setMessage('')}>{t('ok')}</SButton>
        </div>
      </Backdrop>
      <CommonLoadingOverlay
        message="Saving Profile Info"
        dialogOpen={loading || asyncLoading.patchUsersMe}
      />
      <Header />
      <div className={classes.componentLayout}>
        <NavigationContainer currentPage={currentPage} />
        <div className={classes.pageWrapper}>{renderCurrentPage()}</div>
      </div>
    </PageWrapper>
  )
}

Profile.propTypes = {
  location: shape({
    state: object,
  }).isRequired,
}
