import React from 'react'
import { makeStyles } from '@material-ui/core'
import { GenresLeftSide } from './components/genres-left-side'
import { GenresRightSide } from './components/genres-right-side'

const useStyles = makeStyles(() => ({
  genresWrapper: {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
  },
}))

export const AllGenresSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.genresWrapper}>
      <GenresLeftSide />
      <GenresRightSide />
    </div>
  )
}
