import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 700,
    display: 'inline',
  },
  infoWrapper: {
    maxWidth: 650,
    margin: '0 auto 60px',
  },
}))

export const UpgradeInfoBox = () => {
  const classes = useStyles()
  const { t } = useTranslation('profile')

  return (
    <div className={classes.infoWrapper}>
      <span className={classes.bold}>{t('byUpgrading')}</span>
      {t('theRemainingPortionOf')}
    </div>
  )
}
