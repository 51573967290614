/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react'
import * as QB from 'quickblox/quickblox'
import { useTranslation } from 'react-i18next'
import 'react-chat-elements/dist/main.css'
import { uniq, uniqBy, orderBy } from 'lodash'
import { makeStyles, Typography } from '@material-ui/core'
import { ConnectApi } from '#api/requests/connect'
import { BaseLoader } from '#components/loaders/base-loader'
import { OverwrittenChatStylesWrapper } from '#modules/connect-chat/components/overwritten-chat-styles-wrapper'
import { SidePanel } from '#modules/connect-chat/components/side-panel'
import { ConnectIcon } from '#modules/connect-chat/components/connect-icon'
import { Header } from './components/header'
import { ChatRoom } from '#modules/connect-chat/components/chat-room/chat-room'
import { ChooseFile } from '#modules/connect-chat/components/choose-file'
import { Chat } from '#modules/connect-chat/components/chat'
import { useServerEvent } from '#hooks/swr/useServerEvent'
import { EVENTS } from '#api/event'
import i18next from 'i18next'

const GET_CHAT_MESSAGES_COMMON_PARAMS = {
  sort_desc: 'date_sent',
  limit: 20,
}

const inAirFetchUsers = {}
let notifyUserFetch = []
const fetchUser = userId => {
  if (inAirFetchUsers[userId]) return inAirFetchUsers[userId]
  const promise = new Promise((resolve, reject) => {
    QB.users.get(userId, (_usersDataError, usersData) => {
      if (_usersDataError) {
        // TODO ?
        reject(_usersDataError)
      } else {
        const finalUser = usersData ?? {
          id: userId,
          full_name: '[UNKNOWN USER]',
        }
        notifyUserFetch.forEach(callback => callback(finalUser))
        resolve(finalUser)
      }
      delete inAirFetchUsers[userId]
    })
  })
  inAirFetchUsers[userId] = promise
  return promise
}

// TODO this is an ugly workaround but there is no better way to do it currently
const useEffectCatch = (effect, deps) =>
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      return effect()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('CAUGHT quicblox fatal error', err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

const useStyles = makeStyles(() => ({
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
}))

export const ConnectChat = () => {
  const [isChatReady, setIsChatReady] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const [chat, setChat] = useState([])
  const [dialogs, setDialogs] = useState([])
  const [activeDialog, setActiveDialog] = useState(null)
  const [newMessage, setNewMessage] = useState(null)
  const [isChooseFileViewOpened, setIsChooseFileViewOpened] = useState(false)
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [structureCategory, setStructureCategory] = useState()
  const [adminUserId, setAdminUserId] = useState(null)
  const [unreadRoomIds, setUnreadRoomIds] = useState([])
  const classes = useStyles()

  const unreadCount = unreadRoomIds.length

  useEffectCatch(() => {
    if (dialogs.length) {
      const allUnreadRoomIds = dialogs.reduce((ids, room) => {
        return room.unread_messages_count ? [...ids, room._id] : ids
      }, [])
      setUnreadRoomIds(allUnreadRoomIds)
    }
  }, [dialogs.length])

  const connectTranslation = useTranslation('connect')
  const handleOpenAttachments = () => {
    if (!activeDialog?.data?.external_id) {
      return
    }
    setIsChooseFileViewOpened(true)
  }

  const onMsg = (senderId, { body, ...restDialog }) => {
    // Workaround which gives us a possibility to read
    // the component's state, because onMsg listener's callback
    // doesn't allow to read a current state of activeDialog (state inside cb always has an initial value).
    // To make it possible we set newMessage state equals to last recieved message,
    // then we read the message inside the useEffect hook which
    // gives as possibility to read the state of activeDialog
    setNewMessage({
      ...restDialog,
      message: body,
      sender_id: senderId,
      fileId: restDialog.extension.fileId,
    })
  }

  useEffectCatch(() => {
    const newMessageRoomIsDifferentFromActiveRoom =
      newMessage?.dialog_id !== activeDialog?._id
    if (newMessage && newMessageRoomIsDifferentFromActiveRoom) {
      setUnreadRoomIds(preUnreadRoomIds =>
        uniqBy([...preUnreadRoomIds, newMessage.dialog_id])
      )
    }
  }, [newMessage])

  const getToken = () => ConnectApi.getToken().then(d => d.data)

  const loadAllOccupants = mdialogs => {
    return new Promise(resolve => {
      // get an array of all dialogs' users
      const occupants = mdialogs.reduce((prev, current) => {
        return [...prev, ...current.occupants_ids]
      }, [])
      // reduce the occupants array to unique values
      const uniqOccupants = uniq(occupants)
      // build filter params
      const filter = {
        field: 'id',
        param: 'in',
        value: uniqOccupants,
      }
      const searchParams = {
        per_page: 100, // TODO more than 100 users...
        filter,
      }
      // get all users
      QB.users.listUsers(searchParams, (_usersDataError, usersData) => {
        const newUsers = [
          ...users.filter(user => !uniqOccupants.includes(user.id)),
          ...(usersData?.items || []),
        ]
        setUsers(newUsers)
        resolve()
      })
    })
  }

  // eslint-disable-next-line no-shadow
  const setSortedDialogs = dialogs =>
    setDialogs(orderBy(dialogs, ['updated_at'], ['desc']))

  const reloadDialogs = () => {
    QB.chat.dialog.list({}, (_dialogsDataError, dialogsData) => {
      setSortedDialogs(dialogsData.items)
      // TODO detect actual change of dialogs?
      loadAllOccupants(dialogsData.items)
    })
  }

  useServerEvent({
    eventKey: [
      EVENTS.STRUCTURE_CREATED,
      EVENTS.STRUCTURE_REMOVED,
      EVENTS.PERMISSION_REVOKED,
      EVENTS.PERMISSION_GRANTED,
    ],
    eventAction: reloadDialogs,
    onConnected: () => {
      if (isChatReady) {
        reloadDialogs()
      }
    },
  })

  useEffectCatch(() => {
    const addUserToState = usersData =>
      setUsers(prev => uniqBy([...prev, { user: usersData }], 'user.id'))
    notifyUserFetch.push(addUserToState)
    return () => {
      notifyUserFetch = notifyUserFetch.filter(item => item !== addUserToState)
    }
  }, [])

  const initQb = async () => {
    // get QB's config data from our API
    const data = await getToken()
    setAdminUserId(Number(data.metadata.adminUserId))
    // init QB based on the config
    QB.init(
      data.token,
      Number(data.metadata.applicationId),
      null,
      data.metadata.accountKey,
      {
        endpoints: {
          api: data.metadata.endpoint,
          chat: data.metadata.chatEndpoint,
        },
      }
    )
    // get QB session to make it possible to connect to the chat
    QB.getSession((_sessionDataError, sessionData) => {
      if (sessionData) {
        // connect to the QB chat based on session and config if there is no error
        QB.chat.connect(
          {
            userId: sessionData.session.user_id,
            password: data.token,
          },
          (_chatDataError, chatData) => {
            if (chatData) {
              // connect a listener to receive real time messages
              QB.chat.onMessageListener = onMsg
              // receive newly joined users
              QB.chat.onJoinOccupant = (dialogId, userId) => {
                // TODO verify this works, tho no other event to handle it..
                if (userId === data.userId) {
                  reloadDialogs()
                } else if (!users.some(user => user.id === userId)) {
                  fetchUser(userId)
                }
              }
              // fetch all dialogs
              QB.chat.dialog.list({}, (_dialogsDataError, dialogsData) => {
                if (!dialogsData) {
                  setError(i18next.t('validation:requestNoDescription'))
                } else if (dialogsData.total_entries !== 0) {
                  setSortedDialogs(dialogsData.items)

                  loadAllOccupants(dialogsData.items).then(() => {
                    // set the ready state to true
                    setIsChatReady(true)
                  })
                } else if (dialogsData.total_entries === 0) {
                  // set the ready state to true
                  setIsChatReady(true)
                }
              })
            } else {
              setError(i18next.t('validation:requestNoDescription'))
            }
          }
        )
      } else {
        setError(i18next.t('validation:requestNoDescription'))
      }
    })
  }

  // init QB on mount
  useEffectCatch(() => {
    initQb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // join to all dialogs once we get them
  // to recieve real time messages
  useEffectCatch(() => {
    if (dialogs && !!dialogs.length) {
      dialogs.forEach(dialog => {
        QB.chat.muc.join(dialog.xmpp_room_jid)
      })
    }
  }, [dialogs])

  // watch for a new message that comes from real time chat
  useEffectCatch(() => {
    // add a message to an active dialog
    if (
      newMessage &&
      activeDialog &&
      newMessage.dialog_id === activeDialog._id
    ) {
      setChat(prev => {
        return [newMessage, ...prev]
      })
    }
    // fetch all dialogs if we are not in a room
    // to display updated dialogs state (last message, last message sender etc.)
    else if (isChatReady) {
      reloadDialogs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage])

  // fetch fresh dialogs when we go back from a room to the all dialogs list
  useEffectCatch(() => {
    if (!activeDialog && isChatReady) {
      reloadDialogs()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDialog])

  const handleSelectFile = id => {
    const message = {
      type: 'groupchat',
      extension: {
        save_to_history: 1,
        dialog_id: activeDialog._id,
        fileId: id,
      },
      markable: 1,
    }
    const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(activeDialog._id)
    message.id = QB.chat.send(dialogJid, message)
    setIsChooseFileViewOpened(false)
  }

  const handleSelectChat = room => {
    setUnreadRoomIds(prevUnreadRoomIds => [
      ...prevUnreadRoomIds.filter(roomId => roomId !== room._id),
    ])
    setChat(null)
    setActiveDialog(room)
    setStructureCategory(room.data?.category)
    const params = {
      chat_dialog_id: room._id,
      ...GET_CHAT_MESSAGES_COMMON_PARAMS,
    }
    QB.chat.message.list(params, (_err, result) => {
      setChat(result.items)
    })
  }

  const loadMoreMessages = () => {
    const params = {
      chat_dialog_id: activeDialog._id,
      skip: chat.length,
      ...GET_CHAT_MESSAGES_COMMON_PARAMS,
    }
    QB.chat.message.list(params, (_err, result) => {
      setChat(prev => [...prev, ...result.items])
    })
  }

  const onBackClick = () => {
    isChooseFileViewOpened
      ? setIsChooseFileViewOpened(false)
      : setActiveDialog(null)
  }

  const getUserById = userId => {
    if (!userId) return null
    if (userId === adminUserId) {
      return { id: userId, full_name: 'TuneGO' }
    }
    const user = users?.find(({ user: { id } }) => {
      return userId === id
    })
    if (!user) {
      fetchUser(userId)
    }
    return user?.user
  }

  return (
    <>
      <ConnectIcon {...{ isOpened, setIsOpened, unreadCount }} />
      <SidePanel {...{ setIsOpened, isOpened }}>
        <OverwrittenChatStylesWrapper>
          <Header
            {...{
              activeDialog,
              setIsOpened,
              isChooseFileViewOpened,
              setIsChooseFileViewOpened,
              onBackClick,
            }}
          />
          <BaseLoader
            isLoading={!isChatReady && !error}
            text={connectTranslation.t('connectIsLoaded')}
          />
          {error && (
            <div className={classes.errorWrapper}>
              <Typography>{error}</Typography>
            </div>
          )}
          {!activeDialog &&
            dialogs.map((room, index) => {
              return (
                <ChatRoom
                  key={room._id}
                  isLighter={index % 2 === 1}
                  room={room}
                  lastSenderName={
                    getUserById(room.last_message_user_id)?.full_name
                  }
                  onClickHandler={() => handleSelectChat(room)}
                />
              )
            })}
          {!!activeDialog && !!chat && (
            <Chat
              isShown={!isChooseFileViewOpened}
              chat={chat}
              getUserById={getUserById}
              activeDialog={activeDialog}
              handleOpenAttachments={handleOpenAttachments}
              loadMoreMessages={loadMoreMessages}
              setIsOpened={setIsOpened}
            />
          )}
          {isChooseFileViewOpened && (
            <ChooseFile
              structureId={activeDialog.data.external_id}
              structureCategory={structureCategory}
              handleSelectFile={handleSelectFile}
            />
          )}
        </OverwrittenChatStylesWrapper>
      </SidePanel>
    </>
  )
}
