import React from 'react'
import { useTranslation } from 'react-i18next'
import { HowToSubheaderItem } from '#pages/promotion/landing-page/components/how-to-subheader-item'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyItems: 'center',
    alignItems: 'center',
    gridGap: 20,
  },
}))

export const HowToSubheader = () => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  return (
    <div className={classes.gridContainer}>
      <HowToSubheaderItem
        iconUrl="/assets/images/thumb.png"
        header={t('getCrowdReviewed')}
        subheader={t('purchaseAtuneGoCrowd')}
      />
      <HowToSubheaderItem
        iconUrl="/assets/images/stars.png"
        header={t('getTrackRating')}
        subheader={t('getAhighRating')}
      />
      <HowToSubheaderItem
        iconUrl="/assets/images/megaphone.png"
        header={t('getPromoted')}
        subheader={t('ourTeamWillPromote')}
      />
    </div>
  )
}
