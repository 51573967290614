import * as yup from 'yup'
import i18next from 'i18next'
import { SHARE_TYPES } from '#pages/vault/song/tabs/files/utils/constants'

const offPlatformUserInvite = t => {
  return {
    pendingName: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('name'),
        })
      ),
    recipientEmail: yup
      .string()
      .trim()
      .email(i18next.t('validation:validEmailAddress'))
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('email'),
        })
      ),
  }
}

const existingUserInvite = t => {
  return {
    user: yup
      .object()
      .nullable()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('user'),
        })
      ),
  }
}

const playsGrantedTimes = t => {
  return {
    maxAccessCount: yup
      .number()
      .typeError(t('playsMustBeANumber'))
      .moreThan(
        0,
        i18next.t('validation:greaterThanZero', { field: t('plays') })
      )
      .lessThan(999, t('playsMustBeLess'))
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('plays'),
        })
      ),
  }
}

const playsExpiresAt = t => {
  return {
    expiresAt: yup
      .string()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('date'),
        })
      )
      .nullable(),
  }
}

export const determineValidationSchema = ({
  t,
  shareType,
  isExistingRecipient,
}) => {
  let matchingSchemas = {}

  if (isExistingRecipient) {
    matchingSchemas = { ...matchingSchemas, ...existingUserInvite(t) }
  } else {
    matchingSchemas = { ...matchingSchemas, ...offPlatformUserInvite(t) }
  }

  if (shareType === SHARE_TYPES.plays) {
    matchingSchemas = { ...matchingSchemas, ...playsGrantedTimes(t) }
  }

  if (shareType === SHARE_TYPES.days) {
    matchingSchemas = { ...matchingSchemas, ...playsExpiresAt(t) }
  }

  return yup.object().shape(matchingSchemas)
}
