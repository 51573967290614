import { NAVIGATION_PATHS } from '#routes/routes'

export const API_URL = `https://${process.env.REACT_APP_REALM}`

export const getAccessToken = () => {
  const { access_token: accessToken } =
    JSON.parse(localStorage.getItem('auth')) || {}
  return accessToken
}

export const NO_REDIRECT_URLS = [
  NAVIGATION_PATHS.NEW_MASTERING,
  NAVIGATION_PATHS.DISTRO_NEW,
  NAVIGATION_PATHS.NEW_NFT,
  NAVIGATION_PATHS.PROMOTION_NEW,
  NAVIGATION_PATHS.CREATE_COLLECTIBLES,
]

export const getRedirect = path => {
  if (path?.contains('vault')) return NAVIGATION_PATHS.VAULT_PROFILES

  return NAVIGATION_PATHS.HOME
}
