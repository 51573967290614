import React from 'react'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'
import { PageWrapper } from '#modules/page-wrapper'
import { Breadcrumbs } from '#components/breadcrumbs'
import { CollaboratorsTable } from '#pages/_modules/collaborators-table'

export const ArtistCollaborators = () => {
  const { artistId } = useParams()
  const { project } = useArtistStructure(artistId)
  const { t } = useTranslation('vault')

  const navigation = [
    {
      link: NAVIGATION_PATHS.VAULT_PROFILES,
      title: t('myVault'),
    },
    {
      link: buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { id: project?.id }),
      title: project?.name,
    },
  ]

  return (
    <PageWrapper showNavBar>
      <Breadcrumbs breadcrumbs={navigation} />
      <CollaboratorsTable
        accessRoles={project?.accessRoles}
        topBarTitle={t('collaborators')}
        projectId={artistId}
      />
    </PageWrapper>
  )
}
