import React from 'react'
import { FullScreenError } from './components/full-screen-error'
import {
  isOnMaintenancePage,
  maintenanceSwitch,
} from '../../globals/helpers/maintenance'

export class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error) {
    const currentMaintenance = localStorage.getItem('maintenance')
    if (currentMaintenance && !isOnMaintenancePage()) {
      maintenanceSwitch(currentMaintenance, true)
      return
    }
    this.setState({ error, hasError: true })
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // eslint-disable-next-line react/destructuring-assignment
      return <FullScreenError error={this.state.error.message} />
    }
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    return this.props.children
  }
}
