import useSWR from 'swr'
import { EVENTS } from '#api/event'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { SWR_EVENT_ACTION_RELOAD, useEventSWR } from '#hooks/swr/useEventSWR'

export const useMasteredFile = (fileId, config) => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: fileId
      ? [
          `${EVENTS.MASTERED_FILE_UPDATED}.${fileId}`,
          `${EVENTS.MASTERED_FILE_REMOVED}.${fileId}`,
        ]
      : null,
    swrKey: fileId
      ? buildUrl(ENDPOINTS.MASTERING_GET_FILE, { id: fileId })
      : null,
    swrConfig: config,
  })
  return {
    file: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useMasteredFilePreviewLink = fileId => {
  const { data, error, ...rest } = useSWR(
    fileId
      ? buildUrl(ENDPOINTS.MASTERING_GET_PREVIEW_LINK, { id: fileId })
      : null
  )
  return {
    link: data?.link,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useMasteredFiles = query => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.MASTERED_FILE_CREATED,
      EVENTS.MASTERED_FILE_UPDATED,
      EVENTS.MASTERED_FILE_REMOVED,
    ],
    eventAction: SWR_EVENT_ACTION_RELOAD, // TODO patch in-place?
    swrKey: `${ENDPOINTS.MASTERING_GET_FILES}${query}`,
  })
  return {
    files: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useProcessingStatus = () => {
  const { data, error, ...rest } = useSWR(ENDPOINTS.MASTERING_PROCESSING_STATUS)
  return {
    isProcessingLimitReached: data?.isProcessingLimitReached,
    isReadyLimitReached: data?.isReadyLimitReached,
    isLoading: !error,
    isError: error,
    ...rest,
  }
}
