import React, { useEffect, useCallback } from 'react'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { ArtistApi } from '#api/requests/artist'
import { DeleteModal } from '#pages/_components/delete-modal'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { useError } from '#hooks/useError'

export const DeleteModalContainer = ({
  open,
  handleClose,
  folderOrSong,
  structureId,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const { error, setError, clearError } = useError()
  const { actions, loading, errors } = useWithAsyncAction({
    deleteArtistStructure: ArtistApi.deleteArtistStructure,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.deleteArtistStructure) {
      handleSetError(errors.deleteArtistStructure)
    }
  }, [errors, handleSetError])

  const handleDeleteStructure = async () => {
    await actions.deleteArtistStructure(structureId)

    handleClose()
  }

  const deleteButtonMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('yesPermanentlyDeleteStructure', {
      structure: t('song'),
    }),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t(
      'yesPermanentlyDeleteStructure',
      {
        structure: vaultTranslation.t('album'),
      }
    ),
  }

  const loadingMessage = {
    [STRUCTURE_TYPE.SONG]: vaultTranslation.t('songIsBeingDeleted'),
    [STRUCTURE_TYPE.ALBUM]: vaultTranslation.t('albumIsBeingDeleted'),
  }

  const determineStructure = () => {
    return folderOrSong === STRUCTURE_TYPE.SONG
      ? [
          t('song'),
          vaultTranslation.t('allFiles'),
          vaultTranslation.t('allFiles'),
        ]
      : [
          vaultTranslation.t('album'),
          vaultTranslation.t('allSongsAndFiles'),
          vaultTranslation.t('allSongsAndFiles'),
        ]
  }

  if (loading.deleteArtistStructure || error) {
    return (
      <CommonLoadingOverlay
        message={loadingMessage[folderOrSong]}
        dialogOpen={loading.deleteArtistStructure}
        error={error}
        onErrorBackdropClick={clearError}
      />
    )
  }

  return (
    <DeleteModal
      handleDelete={handleDeleteStructure}
      deleteBtnText={deleteButtonMessage[folderOrSong]}
      open={open}
      handleClose={handleClose}
      firstSpan={vaultTranslation.t('deletingThisStructure', {
        structure: determineStructure()[0],
        contains: determineStructure()[1],
      })}
      secondSpan={vaultTranslation.t('thisActionIsPermanent')}
      thirdSpan={vaultTranslation.t('pleaseConfirmThatYouWantDeleteStructure', {
        structure: determineStructure()[0],
      })}
    />
  )
}

DeleteModalContainer.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  structureId: string.isRequired,
  parentId: string.isRequired,
  folderOrSong: string,
  onDelete: func.isRequired,
}
DeleteModalContainer.defaultProps = {
  folderOrSong: 'folder',
}
