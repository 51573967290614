import * as Sentry from '@sentry/react'
import { BrowserTracing } from "@sentry/tracing";
import jwtDecode from 'jwt-decode'

export const setSentryUser = () => {
  const auth = JSON.parse(localStorage.getItem('auth'))
  if (auth) {
    const { email } = jwtDecode(auth.id_token)
    Sentry.setUser({ email })
  }
}

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (
          error &&
          error.match &&
          error.match(/settings/i) &&
          event.tags
      ) {
        event.tags.type = "Settings"
      }

      event.breadcrumbs = event.breadcrumbs.reduce((acc, breadcrumb) => {
        if(!breadcrumb.category) {
          return acc;
        }

        if(breadcrumb.message && breadcrumb.message.match(/Warning:/)) {
          breadcrumb.level = 'warning'
        }

        acc.push(breadcrumb)
        return acc;
      }, [])

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if(breadcrumb.level === "log" || breadcrumb.level === "info") return ;

      return breadcrumb;
    }
  })

  setSentryUser()
}


