import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'
import { territoryConfig } from '#pages/distro/_utils/helpers'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { Button } from '#components/button'
import { TerritoryAccordion } from '#pages/distro/past-release-details/components/territory-accordion'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  typoLabel: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.color.default,
    marginBottom: 15,
  },
}))

const SButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  border-radius: 10px;
  margin-top: 10px;
  text-transform: uppercase;
`

export const Territories = ({ isPreview }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStep, storedFormState } = useWizard()

  const territoriesFiltered = Object.fromEntries(
    Object.entries(territoryConfig)
      .map(([key, value]) => [
        key,
        value.filter(country =>
          storedFormState[FIELDS.COUNTRIES].includes(country.code)
        ),
      ])
      .filter(([, value]) => value.length)
  )

  const displayTerritories = () => {
    const territories = []
    for (const [key, value] of Object.entries(territoriesFiltered)) {
      territories.push([key, value])
    }

    return territories.map((territory, i) => (
      <TerritoryAccordion
        key={generateUniqueKey(territory, i)}
        title={territory[0]}
        content={territory[1]}
      />
    ))
  }

  const handleStepChange = () => setStep(5)

  return (
    <>
      <span className={classes.typoLabel}>{t('territories')}</span>
      {displayTerritories()}
      {isPreview && (
        <SButton onClick={handleStepChange}>{t('editTerritories')}</SButton>
      )}
    </>
  )
}

Territories.propTypes = {
  isPreview: bool.isRequired,
}
