import hash from 'hash-sum'

export const generateUniqueKey = (input, index) => {
  const objectWithId = !Array.isArray(input) && input?.id

  switch (typeof input) {
    case 'object':
    case 'function':
      if (objectWithId) input.id
      return `${hash(input)}-${index}`
    default:
      return `${input}-${index}`
  }
}
