import React from 'react'
import { navigate } from '@reach/router'
import { string, node, bool } from 'prop-types'
import { Trans } from 'react-i18next'
import { ROUTES } from '#routes/routes'
import styled from 'styled-components'

const SAnchor = styled.a`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  text-decoration: underline;
  cursor: pointer;
`

const SBold = styled.b`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  white-space: pre-wrap;
`

const SWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1rem;
`

export const Info = ({ i18nKey, icon, bolded, className }) => {
  const onClick = () => {
    navigate(ROUTES.GETTING_STARTED)
  }

  const infoIcon = icon ?? (
    <img src="assets/svg/ic-warning.svg" alt="Warning icon" />
  )

  return (
    <SWrapper className={className}>
      {infoIcon}
      <SText>
        <Trans i18nKey={i18nKey} ns="nftWizard">
          {bolded ? <SBold /> : <SAnchor onClick={onClick} />}
        </Trans>
      </SText>
    </SWrapper>
  )
}

Info.propTypes = {
  i18nKey: string.isRequired,
  icon: node,
  className: string,
  bolded: bool,
}
