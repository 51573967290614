export const BACKGROUND_SCROLL_SPEED = 50
export const SNOOP_SCROLL_SPEED = 20
export const KOL_REGISTER_URL = process.env.REACT_APP_KOL_REGISTER
export const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL
export const MARKETPLACE_SNOOP_PAGE_URL = `${MARKETPLACE_URL}/newoldschool`

export const LANDING_PAGE_INFO = {
  vault: {
    image: '/assets/images/vault-screen-mobile.png',
    titleTranslationKey: 'tuneGoVault',
    icon: '/assets/images/tunego-static-icon.png',
    titleIcon: '/assets/svg/ic-promo.svg',
    subtitleTranslationKey: 'mostArtistsEvenAlisters',
    descriptionTranslationKey: 'patentedTechnologyBuiltForCreators',
    buttonTextTranslationKey: 'creatorsStartHere',
  },
  marketplace: {
    image: '/assets/images/web3-screen-mobile.png',
    titleTranslationKey: 'tuneGoWeb3Marketplace',
    icon: '/assets/images/tunego-static-icon.png',
    titleIcon: '/assets/svg/ic-distro.svg',
    subtitleTranslationKey: 'havingYourMetadataOnTHeSame',
    descriptionTranslationKey: 'web3BuiltForRealPeople',
    buttonTextTranslationKey: 'creatorsStartHere',
    buttonTextTranslationKey2: 'collectorsStartHere',
  },
  distro: {
    image: '/assets/images/phone-distro.png',
    titleTranslationKey: 'distribution',
    icon: '/assets/images/tunego-static-icon.png',
    titleIcon: '/assets/svg/ic-distro.svg',
    subtitleTranslationKey: 'havingYourMetadataOnTHeSame',
    descriptionTranslationKey: 'sellYourMusicWorldwide',
    buttonTextTranslationKey: 'letsGo',
  },
  social: {
    image: '/assets/images/social-screen-mobile.png',
    titleTranslationKey: 'socialRoyalties',
    descriptionTranslationKey: 'distributeYourMusicToSocialPlatforms',
    buttonTextTranslationKey: 'letsGo',
  },
}

export const PARTNERS = [
  {
    name: 'Dapper',
    logo: '/assets/svg/company-icons/dapper-logo.svg',
    url: 'https://www.dapperlabs.com/',
  },
  {
    name: 'Flow',
    logo: '/assets/svg/company-icons/flow-logo.svg',
    url: 'https://flow.com/',
  },
]

export const SOCIALS = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/tunego/',
    logo: '/assets/svg/company-icons/facebook-f.svg',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/tunego?lang=en',
    logo: '/assets/svg/company-icons/twitter.svg',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/tunego/?hl=en',
    logo: '/assets/svg/company-icons/instagram.svg',
  },
]
