import React from 'react'
import styled from 'styled-components'
import Cropper from 'react-easy-crop'
import { func, string, number, shape, object } from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  CROPPER_MAX_ROTATION,
  CROPPER_MAX_ZOOM,
  CROPPER_MIN_ROTATION,
  CROPPER_MIN_ZOOM,
  CROPPER_ROTATION_STEP,
  CROPPER_VAULT_ASPECT_RATIO,
  CROPPER_ZOOM_STEP,
} from '#components/image-cropper/constants'
import { RangeInput } from '#components/inputs/range-input'

const SCropperOptions = styled.div`
  margin-top: 320px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  width: 100%;
  padding-bottom: 0;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_70};
`

const SCropperOptionLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const ImageCropSettings = ({
  image,
  cropShape,
  rotation,
  zoom,
  crop,
  aspect,
  onCropComplete,
  setRotation,
  customStyle,
  setZoom,
  setCrop,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Cropper
        cropShape={cropShape}
        image={image}
        style={customStyle}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <SCropperOptions>
        <div>
          <SCropperOptionLabel>
            <span>
              {t('zoom')} {zoom}
            </span>
          </SCropperOptionLabel>
          <RangeInput
            min={CROPPER_MIN_ZOOM}
            max={CROPPER_MAX_ZOOM}
            step={CROPPER_ZOOM_STEP}
            value={zoom}
            setValue={e => setZoom(e.target.value)}
          />
        </div>
        <div>
          <SCropperOptionLabel>
            <span>
              {t('rotate')} {rotation}
            </span>
          </SCropperOptionLabel>
          <RangeInput
            min={CROPPER_MIN_ROTATION}
            max={CROPPER_MAX_ROTATION}
            step={CROPPER_ROTATION_STEP}
            value={rotation}
            setValue={e => setRotation(e.target.value)}
          />
        </div>
      </SCropperOptions>
    </>
  )
}

ImageCropSettings.propTypes = {
  image: string.isRequired,
  cropShape: string,
  rotation: number,
  zoom: number,
  aspect: number,
  customStyle: object,
  crop: shape({ x: number, y: number }),
  onCropComplete: func,
  setRotation: func,
  setZoom: func,
  setCrop: func,
}

ImageCropSettings.defaultProps = {
  aspect: CROPPER_VAULT_ASPECT_RATIO,
  customStyle: {
    containerStyle: {
      height: '280px',
      top: '40px',
    },
  },
}
