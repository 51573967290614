import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { string, func } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { mutate } from 'swr'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { ArtistApi } from '#api/requests/artist'
import { BaseInput } from '#components/inputs/base-input'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { ENDPOINTS } from '#api/endpoints'
import { addProjectValidationSchema } from '#pages/vault/profile-content/utils/validation'
import { useError } from '#hooks/useError'

const useStyles = makeStyles(theme => ({
  addProjectWrapper: {
    position: 'relative',
    padding: '20px 24px 8px',
    paddingLeft: 50,
    background: theme.palette.color.darkBlue03,
    height: 128,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      paddingTop: 16,
      height: 80,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  buttons: {
    flex: 1,
    display: 'flex',
    marginBottom: '8px',
    paddingLeft: 0,
    gap: '0.5rem',
  },
  line: props => ({
    ...(props.isSelected
      ? { backgroundColor: theme.palette.color.default }
      : {
          backgroundImage: `linear-gradient(180deg, ${theme.palette.color.default} 2px, transparent 2px)`,
          backgroundSize: '1px 10px',
          height: '50%',
        }),
    width: 1,
    position: 'absolute',
    left: 24,
    bottom: 0,
    top: 0,
  }),
  newSongInput: {
    paddingRight: '0.5rem',
    flex: 2,
    justifyContent: 'center',
  },
}))

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

export const AddSongRow = ({ isSelected, id, handleCloseInlineAction }) => {
  const classes = useStyles({ isSelected })
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const { setError, clearError, error } = useError()

  const { actions, errors, loading } = useWithAsyncAction({
    postArtistSong: ArtistApi.postArtistSong,
  })

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(addProjectValidationSchema(vaultTranslation.t)),
    defaultValues: { name: '' },
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.postArtistSong) {
      handleSetError(errors.postArtistSong)
    }
  }, [errors, handleSetError])

  const handleCreateSong = async data => {
    const { name } = data
    const projectToSubmit = {
      name,
      parentId: id,
    }

    await actions.postArtistSong(projectToSubmit)
    mutate(
      `${ENDPOINTS.STRUCTURES}?parentId=${id}&withoutPagination=true&sortDirection=ASC`
    )

    handleCloseInlineAction()
  }

  return (
    <form
      className={classes.addProjectWrapper}
      onSubmit={handleSubmit(handleCreateSong)}
    >
      <div className={classes.line} />
      <div className={classes.newSongInput}>
        <SBaseInput
          name="name"
          inputRef={register}
          placeholder={vaultTranslation.t('newSong')}
          errorMessage={formErrors?.name?.message}
          customBg="black"
        />
      </div>

      <div className={classes.buttons}>
        <SButton type="submit">{t('add')}</SButton>
        <SButton
          variant={VARIANTS.CANCEL_BLACK}
          onClick={handleCloseInlineAction}
        >
          {t('cancel')}
        </SButton>
      </div>

      <CommonLoadingOverlay
        message={vaultTranslation.t('songIsBeingCreated')}
        dialogOpen={loading.postArtistSong}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </form>
  )
}

AddSongRow.propTypes = {
  isSelected: string.isRequired,
  id: string.isRequired,
  handleCloseInlineAction: func.isRequired,
}
