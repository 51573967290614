import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { bool, func, shape } from 'prop-types'
import { useArtistGenres } from '#hooks/swr/useArtists'
import { CustomSelect } from '#components/inputs/custom-select'
import { BaseInput } from '#components/inputs/base-input'
import { TabWrapper } from '#pages/vault/song/tabs/metadata/components/tab-wrapper'
import { generalMetadataFields } from '#pages/vault/song/tabs/metadata/utils/constants'
import { isFormDirty } from '#pages/vault/song/tabs/metadata/utils/helpers'

const SCustomSelect = styled(CustomSelect)`
  width: 100%;
  text-transform: capitalize;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

export const GeneralMetadata = ({
  isLoading,
  setHasUnsavedChanges,
  formattedFields,
}) => {
  const { t } = useTranslation('vault')
  const { genres, isLoading: isGenresListLoading } = useArtistGenres()

  const {
    register,
    control,
    getValues,
    watch,
    formState: { errors, isDirty: isCurrentFormDirty, dirtyFields },
  } = useFormContext()

  const changes = watch()

  const isGeneralFormDirty = () => {
    return isFormDirty({
      dirtyFields,
      formattedFields,
      values: getValues(),
    })
  }

  useEffect(() => {
    setHasUnsavedChanges(isGeneralFormDirty())
  }, [isCurrentFormDirty, setHasUnsavedChanges, changes])

  const genresOptions =
    genres && genres.map(({ name, id }) => ({ label: name, value: id }))

  return (
    <TabWrapper isLoading={isLoading || isGenresListLoading}>
      {genresOptions &&
        generalMetadataFields.map(
          ({ name, type, isReadOnly, options, defaultValueKey, mask }) => {
            if (type === 'input') {
              return (
                <SBaseInput
                  key={name}
                  defaultValue={formattedFields[defaultValueKey]}
                  isReadOnly={isReadOnly}
                  inputRef={register}
                  name={name}
                  mask={mask}
                  label={t(`${name}`)}
                  errorMessage={errors[`${name}`]?.message}
                />
              )
            }
            return (
              <Controller
                key={name}
                name={name}
                defaultValue={formattedFields[defaultValueKey]}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <SCustomSelect
                      id={`${name}_select`}
                      options={
                        options === 'api genres' ? genresOptions : options
                      }
                      label={t(`${name}`)}
                      value={value}
                      errorMessage={errors[`${name}`]?.message}
                      onChange={onChange}
                    />
                  )
                }}
              />
            )
          }
        )}
    </TabWrapper>
  )
}

GeneralMetadata.propTypes = {
  isLoading: bool.isRequired,
  setHasUnsavedChanges: func.isRequired,
  formattedFields: shape({}).isRequired,
}
