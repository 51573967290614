import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SingleOrAlbumChoose } from '#pages/distro/_components/single-or-album-choose'
import { PageWrapper } from '#modules/page-wrapper'
import { SalesImages } from '#pages/distro/_components/sales-images'

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '65px',
  },
  salesImagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}))

export const GetStartedComponent = () => {
  const classes = useStyles()

  return (
    <PageWrapper showFooter isFullWidth showNavBar>
      <div className={classes.container}>
        <SingleOrAlbumChoose />
        <div className={classes.salesImagesContainer}>
          <SalesImages mode="light" />
        </div>
      </div>
    </PageWrapper>
  )
}
