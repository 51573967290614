import React from 'react'
import { func, shape } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UsersApi } from '#api/requests/users'
import { AutoComplete } from '#components/inputs/auto-complete'
import { SideSwitcherText } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/side-switcher-text'

const loadOptions = inputValue => {
  return UsersApi.getMyContacts({ params: { search: inputValue } }).then(
    ({ data }) => {
      return data.data.map(d => {
        return {
          label: `${d.firstName} ${d.lastName}`,
          value: { id: d.id, name: `${d.firstName} ${d.lastName}` },
          avatarUrl: d.profileImageUrl,
        }
      })
    }
  )
}

const useStyles = makeStyles(() => ({
  searchTip: {
    fontSize: 14,
    marginTop: 8,
    marginRight: 4,
    display: 'inline-block',
  },
}))

export const SearchContactsAutocomplete = ({ setSide, control, errors }) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <>
      <Controller
        name="user"
        control={control}
        render={({ field: { onChange } }) => (
          <AutoComplete
            id="search_contacts"
            label={t('searchContacts')}
            loadOptions={loadOptions}
            errorMessage={errors.user?.message}
            onChange={({ value }) => {
              onChange(value)
            }}
          />
        )}
      />
      <span className={classes.searchTip}>{t('dontSeeWho')}</span>
      <SideSwitcherText onClick={setSide}>{t('inviteThem')}</SideSwitcherText>
    </>
  )
}

SearchContactsAutocomplete.propTypes = {
  setSide: func.isRequired,
  control: func.isRequired,
  errors: shape().isRequired,
}
