import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from '#components/modal'
import { bool, func, string } from 'prop-types'
import { BaseInput } from '#components/inputs/base-input'
import { AlbumSelection } from '#pages/vault/_modules/album-selection'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { nameValidationSchema } from '#pages/vault/wizard/utils/validations'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ArtistApi } from '#api/requests/artist'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { VaultModalContainer } from '#pages/vault/profile-content/containers/vault-modal-container'
import styled from 'styled-components'
import { useReload } from '#modules/page-wrapper/context'
import { useVault } from '#hooks/useVault'

const SModal = styled(Modal)`
  padding: 2rem 2.5rem;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

export const AddSingleModal = ({
  isOwner,
  structureName,
  setIsOpen,
  parentId,
  ...modalProps
}) => {
  const [error, setError] = useState('')
  const [selectedFolderId, setSelectedFolderId] = useState(null)
  const { t } = useTranslation('vault')
  const validationTranslation = useTranslation('validation')
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(nameValidationSchema(t('songName'))),
  })
  const { actions, errors, loading } = useWithAsyncAction({
    postArtistSong: ArtistApi.postArtistSong,
  })

  const { setReloading } = useReload()

  const handleSetError = useCallback(
    err => {
      setError(
        validationTranslation.t('requestOnlyMessage', {
          message: err.message,
        })
      )
    },
    [t]
  )

  useEffect(() => {
    if (errors.postArtistSong) {
      handleSetError(errors.postArtistSong)
    }
  }, [errors, handleSetError])

  const { setExpandedStructureId } = useVault()

  const onCloseModal = () => {
    reset()
    setSelectedFolderId(null)
  }

  const onSubmit = async values => {
    const data = {
      ...values,
      parentId: selectedFolderId || parentId,
    }
    await actions.postArtistSong(data)

    setIsOpen(false)
    setReloading()

    if (selectedFolderId) {
      setExpandedStructureId(selectedFolderId)
    }

    onCloseModal()
  }

  if (loading.postArtistSong || error) {
    return (
      <CommonLoadingOverlay
        message={t('songIsBeingCreated')}
        dialogOpen={loading.postArtistSong}
        error={error}
        isOwner={isOwner}
        structureName={structureName}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return (
    <SModal
      {...modalProps}
      setIsOpen={setIsOpen}
      closeBtn
      onClose={onCloseModal}
    >
      <VaultModalContainer
        title={t('createNewSingle')}
        text={t('singlesHelpYouSecurelyStore')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SBaseInput
          errorMessage={formErrors.name?.message}
          name="name"
          inputRef={register}
          label={t('songName')}
        />
        <AlbumSelection
          parentId={parentId}
          selectedAlbumId={selectedFolderId}
          setSelectedAlbumId={setSelectedFolderId}
        />
      </VaultModalContainer>
    </SModal>
  )
}

AddSingleModal.propTypes = {
  isOpen: bool,
  setIsOpen: func,
  isOwner: bool,
  structureName: string,
  parentId: string.isRequired,
}
