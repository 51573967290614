import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { bool } from 'prop-types'

const SReflectionBox = styled.div`
  position: absolute;
  height: 140px;
  overflow: hidden;
  width: 70%;
  left: 20%;
  cursor: pointer;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  ${({ isOnMainPage }) =>
    isOnMainPage &&
    css`
      -webkit-box-reflect: below 2px -webkit-gradient(linear, right top, right
            bottom, from(transparent), color-stop(50%, transparent), to(rgba(255, 255, 255, 0.4)));
    `}
`

export const ReflectionBox = forwardRef(
  ({ isOnMainPage, visible, ...props }, ref) => {
    return (
      <SReflectionBox
        isOnMainPage={isOnMainPage}
        isVisible={visible}
        {...props}
        ref={ref}
      />
    )
  }
)

ReflectionBox.propTypes = {
  isOnMainPage: bool.isRequired,
  visible: bool.isRequired,
}
