import React, { useEffect, useState } from 'react'
import { bool, func, shape } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, Popover, Typography } from '@material-ui/core'
import NotificationIcon from '@material-ui/icons/Notifications'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { NotificationsList } from '#modules/notifications/components/notification-list'

const useStyles = makeStyles(theme => ({
  notificationDropDown: {
    padding: 0,
    margin: 0,
    width: 370,
    height: 'calc(100vh - 67px)',
    borderRadius: 2,
    backgroundColor: theme.palette.color.charcoal,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignContent: 'baseline',
    borderLeft: `1px solid ${theme.palette.color.primary}`,
  },
  notificationDropDownEmpty: {
    alignContent: 'unset',
  },
  notificationDropdownHeaderWrapper: {
    padding: '0 24px',
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.color.darkGrey}`,
  },
  notificationDropdownHeader: {
    fontSize: 18,
    color: theme.palette.color.default,
    fontFamily: theme.typography.h2.fontFamily,
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  markAsRead: {
    color: theme.palette.color.primary,
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  markAsReadDisabled: {
    cursor: 'default',
  },
  popover: {
    right: 0,
    top: 64,
  },
}))

export const NotificationsListContainer = ({
  wsNotification,
  isOpened,
  setIsOpened,
  markAllAsRead,
  markAsRead,
  triggerUnreadNotificationsMutate,
  getNotifications,
  isLoading,
}) => {
  const [page, setPage] = useState(0)
  const [loadedNotifications, setLoadedNotifications] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [isReadingAll, setReadingAll] = useState(false)
  const areAllNotificationsDisplayed = totalCount > loadedNotifications.length

  const { t } = useTranslation('notifications')
  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { data, count },
      } = await getNotifications(`?page=${page}&limit=10`)
      setLoadedNotifications(data)
      setTotalCount(count)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (page !== 0) {
      const fetchData = async () => {
        const {
          data: { data, count },
        } = await getNotifications(`?page=${page}&limit=10`)
        setLoadedNotifications(prev => [...prev, ...data])
        setTotalCount(count)
      }
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (
      wsNotification &&
      isOpened &&
      wsNotification?.id !== loadedNotifications[0]?.id
    ) {
      setLoadedNotifications(prev => [wsNotification, ...prev])
    }
  }, [isOpened, loadedNotifications, wsNotification])

  const loadMore = () => {
    if (areAllNotificationsDisplayed) {
      setPage(prevPage => prevPage + 1)
    }
  }

  const markAsReadHandler = async id => {
    if (isReadingAll) {
      return
    }
    let wasChanged = false
    // TODO ^ this is a race condition as callback setState is async
    setLoadedNotifications(notifications =>
      notifications.map(notification => {
        if (notification.id === id && !notification.isRead) {
          wasChanged = true
          return { ...notification, isRead: true }
        }
        return notification
      })
    )
    if (wasChanged) {
      triggerUnreadNotificationsMutate(
        unread => ({ ...unread, count: Math.max(unread.count - 1, 0) }),
        false
      )
    }
    await markAsRead(id) // TODO this should return currently unread count
    triggerUnreadNotificationsMutate()
  }

  const markAllAsReadHandler = async () => {
    if (isReadingAll) {
      return
    }
    setReadingAll(true)
    setLoadedNotifications(prevLoadedNotifications => {
      return prevLoadedNotifications.map(singleNotification => {
        return {
          ...singleNotification,
          isRead: true,
        }
      })
    })
    triggerUnreadNotificationsMutate(unread => ({ ...unread, count: 0 }), false)
    await markAllAsRead()
    triggerUnreadNotificationsMutate()
    setReadingAll(false)
  }

  const updateNotificationType = async (notificationId, newType) => {
    setLoadedNotifications(notifications =>
      notifications.map(notification =>
        notification.id === notificationId
          ? { ...notification, type: newType }
          : notification
      )
    )
    await markAsReadHandler(notificationId)
  }

  const zeroNotifications = loadedNotifications?.length === 0

  const handleClose = () => {
    setIsOpened(false)
    setPage(0)
  }

  return (
    <Popover
      anchorReference="none"
      classes={{
        paper: classes.popover,
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open
      onClose={handleClose}
    >
      <div
        className={cn(classes.notificationDropDown, {
          [classes.notificationDropDownEmpty]: zeroNotifications,
        })}
      >
        <div className={classes.notificationDropdownHeaderWrapper}>
          <div className={classes.notificationHeader}>
            <NotificationIcon />
            <Typography
              component="h3"
              className={classes.notificationDropdownHeader}
            >
              {t('notifications')}
            </Typography>
          </div>
          <Typography
            className={cn({
              [classes.markAsRead]: true,
              [classes.markAsReadDisabled]: isReadingAll,
            })}
            onClick={markAllAsReadHandler}
          >
            {t('markAsRead')}
          </Typography>
        </div>
        <NotificationsList
          loadedNotifications={loadedNotifications}
          isLoading={isLoading}
          markAsRead={markAsReadHandler}
          loadMore={loadMore}
          areAllNotificationsDisplayed={areAllNotificationsDisplayed}
          updateNotificationType={updateNotificationType}
        />
      </div>
    </Popover>
  )
}

NotificationsListContainer.propTypes = {
  wsNotification: shape(),
  isLoading: bool.isRequired,
  isOpened: bool.isRequired,
  setIsOpened: func.isRequired,
  markAllAsRead: func.isRequired,
  markAsRead: func.isRequired,
  getSingleNotification: func.isRequired,
  triggerUnreadNotificationsMutate: func.isRequired,
  getNotifications: func.isRequired,
}

NotificationsListContainer.defaultProps = {
  wsNotification: null,
}
