import * as yup from 'yup'
import i18next from 'i18next'

export const parentValidationSchema = () => {
  return yup.object().shape({
    parent: yup
      .object()
      .nullable()
      .required(i18next.t('validation:requiredField')),
  })
}

export const trackNameGenreValidationSchema = t => {
  return yup.object().shape({
    trackName: yup
      .string()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('songName'),
        })
      )
      .trim(),
    genre: yup
      .object()
      .default({})
      .shape({
        value: yup.string().required(
          i18next.t('validation:mustNotBeEmpty', {
            field: t('genre'),
          })
        ),
        label: yup.string(),
      }),
  })
}
