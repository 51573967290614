import React from 'react'
import { func, bool, arrayOf, string } from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { BottomBar } from '#pages/sell-collectibles/create-collectible/_components/bottom-bar'

export const SubmitStep = ({ disabled, formFields, onSubmit }) => {
  const { handleSubmit, formState, trigger } = useFormContext()

  const { activeStep, setActiveStep, readOnly, completedStep } = useNftCreator()

  const checkIsFormDirty = () => {
    return formFields.some(field => formState.dirtyFields[field])
  }

  const submitHandler = async values => {
    if (readOnly && activeStep + 1 <= completedStep) {
      setActiveStep(activeStep + 1)

      return
    }

    await trigger()

    const isFormDirty = checkIsFormDirty ? checkIsFormDirty() : false

    if (isFormDirty || formState.isDirty) {
      onSubmit(values)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  return (
    <BottomBar disabled={disabled} onNextClick={handleSubmit(submitHandler)} />
  )
}

SubmitStep.propTypes = {
  onSubmit: func.isRequired,
  disabled: bool,
  formFields: arrayOf(string),
}

SubmitStep.defaultProps = {
  formFields: [],
}
