/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { node } from 'prop-types'

const useStyles = makeStyles(() => ({
  termsWrapper: {
    width: 1080,
    margin: '0 auto',
  },
}))

export const TermsWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.termsWrapper}>{children}</div>
}

TermsWrapper.propTypes = {
  children: node.isRequired,
}
