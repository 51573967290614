import React, { useState } from 'react'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { object, shape } from 'prop-types'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { TopButtonBar } from '#pages/vault/profile-content/components/top-button-bar'
import { Breadcrumbs } from '#components/breadcrumbs'
import { ContentTableContainer } from '#pages/vault/profile-content/containers/content-table-container'
import { CollaboratorsTable } from '#pages/_modules/collaborators-table'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { PageWrapper } from '#modules/page-wrapper'
import { getNavigation } from '#pages/vault/profile-content/utils/helpers'

export const ProfileContent = () => {
  const { id } = useParams()
  const { project: parent } = useArtistStructure(id)
  const { t } = useTranslation('vault')
  const [contentItem, setContentItem] = useState(null)
  const [isFolderCollaboratorView, setIsFolderCollaboratorView] =
    useState(false)
  const { id: contentItemId, accessRoles, name } = contentItem || {}

  const navigation = getNavigation({
    parent,
    setIsFolderCollaboratorView,
    t,
  })

  const handleAddEditCollaborators = value => {
    setContentItem(value)
    setIsFolderCollaboratorView(true)
  }

  return (
    <PageWrapper showNavBar isFullWidth>
      <Breadcrumbs breadcrumbs={navigation} />
      <ColumnWrapper>
        {isFolderCollaboratorView ? (
          <CollaboratorsTable
            topBarTitle={name}
            projectId={contentItemId}
            accessRoles={accessRoles}
          />
        ) : (
          <div>
            <TopButtonBar {...{ parent }} />
            <ContentTableContainer
              {...{
                parent: parent ?? { id },
                handleAddEditCollaborators,
              }}
            />
          </div>
        )}
      </ColumnWrapper>
    </PageWrapper>
  )
}

ProfileContent.propTypes = {
  location: shape({
    state: object,
  }).isRequired,
}
