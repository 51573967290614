import React, { useState } from 'react'
import styled from 'styled-components'
import { func, bool } from 'prop-types'
import { InviteExistingSide } from '#pages/vault/song/tabs/splits/components/modals/add-split-modal/components/invite-existing-side'
import { Modal } from '#components/modal'
import { InviteOffPlatformSplit } from '#pages/vault/song/tabs/splits/components/modals/add-split-modal/components/invite-off-platform-split'

const SModal = styled(Modal)`
  width: 620px;
`

export const AddSplitModal = ({
  closeModal,
  isOpened,
  addSplit,
  addSplitOffPlatform,
}) => {
  const [isFirstModalSide, setIsFirstModalSide] = useState(true)

  const onClose = () => {
    closeModal()
    setIsFirstModalSide(true)
  }

  return (
    <SModal isOpen={isOpened} setIsOpen={onClose}>
      {isFirstModalSide ? (
        <InviteExistingSide {...{ onClose, setIsFirstModalSide, addSplit }} />
      ) : (
        <InviteOffPlatformSplit
          {...{ onClose, setIsFirstModalSide, addSplitOffPlatform }}
        />
      )}
    </SModal>
  )
}

AddSplitModal.propTypes = {
  closeModal: func.isRequired,
  isOpened: bool.isRequired,
  addSplit: func.isRequired,
  addSplitOffPlatform: func.isRequired,
}
