import { UsersApi } from '#api/requests/users'

export const loadContactsList = user => async inputValue => {
  const { data } = await UsersApi.getMyContacts(
    inputValue ? { params: { search: inputValue } } : null
  )

  const myContacts =
    data?.data.map(d => ({
      label: `${d.firstName} ${d.lastName}`,
      value: { id: d.id, name: `${d.firstName} ${d.lastName}` },
      avatarUrl: d.profileImageUrl,
    })) || []

  if (user) {
    const me = {
      label: `${user.firstName} ${user.lastName} (you)`,
      value: { id: user.id, name: `${user.firstName} ${user.lastName}` },
      avatarUrl: user.profileImageUrl,
    }
    return [me, ...myContacts]
  }

  return myContacts
}
