/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class ContributorsApi {
  static postContributor = (structureId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS, {
        id: structureId,
      }),
      data
    )

  static patchContributor = (
    { projectId: structureId, idOfActiveContributor: contributorId },
    data
  ) => {
    getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR, {
        structureId,
        contributorId,
      }),
      data
    )
  }

  static deleteContributor = (structureId, contributorId) =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR, {
        structureId,
        contributorId,
      })
    )

  static postInviteContributor = (structureId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_INVITE, {
        id: structureId,
      }),
      data
    )

  static postReInviteContributor = (structureId, contributorId) =>
    getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR_REINVITE,
        {
          structureId,
          contributorId,
        }
      )
    )

  static postAcceptContribution = (structureId, contributorId) =>
    getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_ACCEPT_CONTRIBUTION,
        {
          structureId,
          contributorId,
        }
      )
    )

  static postRejectContribution = (structureId, contributorId) =>
    getApiClient().post(
      buildUrl(
        ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_REJECT_CONTRIBUTION,
        {
          structureId,
          contributorId,
        }
      )
    )
}
