import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { string, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { buildUrl } from '#utils/buildUrl'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import {
  ACTION_TYPES,
  OWNER_MENU_OPTIONS,
  MENU_OPTIONS,
  COLLABORATOR_MENU_OPTIONS,
} from '#pages/vault/profiles/utils/constants'
import { Card } from '#components/card'
import { MarketplaceEllipsisOption } from '#pages/vault/profiles/components/marketplace-ellipsis-option'
import { BackdropMessage } from '#components/backdrop-message'
import { checkIfUserHasRole } from '#pages/nft/_utils/helpers'

export const ProfileCard = ({ artist }) => {
  const { t } = useTranslation('vault')
  const [copyMessage, setCopyMessage] = useState('')

  const handleClick = () => {
    navigate(buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { id: artist.id }))
  }

  const determineMenuAction = (actionType, id) => {
    switch (actionType) {
      case ACTION_TYPES.editProfile: {
        return navigate(
          buildUrl(NAVIGATION_PATHS.EDIT_PROFILE, {
            id,
          })
        )
      }
      case ACTION_TYPES.addEditCollaborators: {
        return navigate(
          buildUrl(NAVIGATION_PATHS.PROFILE_COLLABORATORS, {
            id,
          })
        )
      }
      default: {
        return null
      }
    }
  }

  const hasRole = role => checkIfUserHasRole(artist.accessRoles, role)

  const determineMenuOptions = () => {
    if (hasRole(ACCESS_ROLES.OWNER)) return OWNER_MENU_OPTIONS
    else if (hasRole(ACCESS_ROLES.COLLABORATOR))
      return COLLABORATOR_MENU_OPTIONS

    return MENU_OPTIONS
  }

  const avatar = artist.avatarUrl
    ? buildCloudFrontImgUrl(artist.avatarUrl)
    : DEFAULT_AVATAR

  const getRoles = () => {
    if (artist.accessRoles?.length > 0) {
      return artist.accessRoles.map(role => {
        if (role === ACCESS_ROLES.READ) return t('collaborator')
        return t(role)
      })
    }
    return []
  }

  return (
    <Card
      title={artist.name}
      tags={getRoles()}
      imgSrc={avatar}
      options={determineMenuOptions()}
      customOption={
        <MarketplaceEllipsisOption
          artistId={artist?.id}
          onCopy={setCopyMessage}
        />
      }
      onChooseOption={actionType => determineMenuAction(actionType, artist.id)}
      onClick={handleClick}
    >
      <BackdropMessage
        message={copyMessage}
        header={t('success')}
        transparent
        onBackdropClick={() => setCopyMessage('')}
      />
    </Card>
  )
}

ProfileCard.propTypes = {
  artist: shape({
    id: string,
    name: string,
  }).isRequired,
}
