import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'
import { Button } from '#components/button'
import { useEventListener } from '#hooks/useEventListener'
import {
  BACKGROUND_SCROLL_SPEED,
  MARKETPLACE_SNOOP_PAGE_URL,
  KOL_REGISTER_URL,
  SNOOP_SCROLL_SPEED,
} from '#pages/home/_utils/constants'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100vh',
    width: '100%',
    paddingBottom: 60,
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    zIndex: -1,
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: hexToRgba(theme.palette.color.black, 0.7),
  },
  background: {
    zIndex: '-2',
    height: '100vh',
    width: '100%',
    background: `url("/assets/images/snoop/snoop-background.jpeg")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'absolute',
  },
  contentWrapper: {
    position: 'absolute',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    top: '0',
    overflow: 'hidden',

    '@media(max-width: 780px)': {
      gridTemplateColumns: '1fr',
    },
  },
  snoopMethod: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '5rem',
    zIndex: '-1',
    backgroundImage: `url("/assets/images/snoop/snoop-method.png")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    '@media(max-width: 980px)': {
      marginLeft: '-13rem',
    },

    '@media(max-width: 780px)': {
      display: 'none',
    },
  },
  snoopMethodLogo: {
    height: '3rem',
    width: '20rem',
    backgroundImage: `url("/assets/images/snoop/snoop-method-logo.png")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  welcomeContent: {
    width: '100%',
    gridColumnStart: '2',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifySelf: 'center',
    justifyContent: 'center',
    padding: '0 1.5rem',

    '@media(max-width: 780px)': {
      gridColumnStart: '1',
    },
  },
  title: {
    padding: '0.5rem 0',
    maxWidth: '30rem',
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '2rem',
  },
  description: {
    padding: '0.5rem 0 2rem',
    maxWidth: '25rem',
  },
}))

export const SnoopWelcomeSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('home')
  const [scrollOffset, setScrollOffset] = useState(window.scrollY)
  const { preLaunch } = useFlags()

  const dropRedirect = () =>
    navigate(preLaunch ? KOL_REGISTER_URL : MARKETPLACE_SNOOP_PAGE_URL)
  const scrollListenerHandler = () => setScrollOffset(window.scrollY / 100)
  const getYOffset = speed => scrollOffset * speed

  useEventListener('scroll', scrollListenerHandler)

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay} />
      <div
        className={classes.background}
        style={{
          transform: `translateY(${getYOffset(BACKGROUND_SCROLL_SPEED)}px)`,
        }}
      />
      <div className={classes.contentWrapper}>
        <div
          className={classes.snoopMethod}
          style={{
            transform: `translateY(${getYOffset(SNOOP_SCROLL_SPEED)}px)`,
          }}
        />
        <div className={classes.welcomeContent}>
          <div className={classes.snoopMethodLogo} />
          <span className={classes.title}>{t('exclusiveNewOriginal')}</span>
          <span className={classes.description}>
            {t('twoLegendaryArtistsBringTheirFlow')}
          </span>
          <Button onClick={dropRedirect}> {t('joinTheDrop')} </Button>
        </div>
      </div>
    </div>
  )
}
