import { useEffect } from 'react'
import { socketInstance } from '#api/socketio'

export const useServerEvent = ({ eventKey, eventAction, onConnected }) => {
  let eventKeys = eventKey
  if (eventKey && !Array.isArray(eventKeys)) {
    eventKeys = [eventKey]
  }

  useEffect(() => {
    if (!eventKey) return
    if (onConnected) socketInstance.on('connected', onConnected)
    eventKeys.forEach(key => socketInstance.on(`event.${key}`, eventAction))
    // eslint-disable-next-line consistent-return
    return () => {
      eventKeys.forEach(key => socketInstance.off(`event.${key}`, eventAction))
      if (onConnected) socketInstance.off('connected', onConnected)
    }
  }, [eventAction, eventKey, eventKeys, onConnected])
}
