import React from 'react'
import { func, number, shape } from 'prop-types'
import { UserImage } from '#components/user-image'
import styled from 'styled-components'
import { RadioButton } from '#pages/_components/radio-button'

const SArtistRow = styled.div`
  display: flex;
  height: 3.75rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.primary25};
  }
`

const SArtistIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1.25rem 0 1.875rem;
`

const SArtistName = styled.div`
  width: 25rem;
  margin-left: 1.25rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ProfileCheckbox = ({ project, selectedId, setSelectedId }) => {
  const { id, avatarUrl, name } = project

  const handleSelect = () => {
    return selectedId === id ? setSelectedId(null) : setSelectedId(id)
  }

  return (
    <SArtistRow onClick={() => handleSelect()}>
      <SArtistIcon>
        <UserImage size={25} userImageUrl={avatarUrl} />
        <SArtistName>{name}</SArtistName>
      </SArtistIcon>
      <RadioButton checked={selectedId === id} />
    </SArtistRow>
  )
}

ProfileCheckbox.propTypes = {
  project: shape({}).isRequired,
  selectedId: number.isRequired,
  setSelectedId: func.isRequired,
}
