import React from 'react'
import { makeStyles } from '@material-ui/core'
import { string, arrayOf, number, bool, oneOfType, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TABLE_KEY_TOOLTIPS } from '#pages/promotion/_utils/constants'
import { formatPrice } from '#utils/formatPrice'
import { hexToRgba } from '#utils/hexToRgba'
import { Tooltip } from '#components/tooltip'

const useStyles = makeStyles(theme => {
  return {
    flex: {
      display: 'flex',
    },
    tableRow: {
      display: 'grid',
      gridTemplateColumns: '4fr 8fr',
      fontFamily: 'AvenirNextLTPro-Bold',
      padding: '25px 20px',
    },
    rowCellWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 4fr)',
    },
    lighterBg: {
      background: hexToRgba(theme.palette.color.default, 0.05),
    },
    tooltip: {
      cursor: 'pointer',
      marginLeft: 5,
    },
    content: {
      textAlign: 'center',
    },
  }
})

export const PricingTableRow = ({ title, colValues, index, prices }) => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  const tableRowCell = value => {
    const resolveContent = () => {
      switch (true) {
        case index === 0:
          return prices[value] ? formatPrice(prices[value]?.value) : ''
        case typeof value === 'string': {
          return value
        }
        case value: {
          return <img src="/assets/svg/ic-check-blue.svg" alt="" width="25px" />
        }
        default:
          return ''
      }
    }

    return <span className={classes.content}>{resolveContent()}</span>
  }

  return (
    <div
      className={`${classes.tableRow} ${index % 2 === 1 && classes.lighterBg}`}
    >
      <div>
        <div className={classes.flex}>
          <span className={classes.bold}>{t(title)}</span>
          {index !== 0 && (
            <div className={classes.tooltip}>
              <Tooltip text={t(TABLE_KEY_TOOLTIPS[index])}>
                <img
                  alt="info"
                  className={classes.toolTipMarker}
                  src="/assets/svg/ic-tooltip.svg"
                  width="20px"
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className={classes.rowCellWrapper}>
        {colValues.map((val, i) => tableRowCell(val, i))}
      </div>
    </div>
  )
}

PricingTableRow.propTypes = {
  index: number.isRequired,
  title: string.isRequired,
  colValues: arrayOf(oneOfType([string, bool])).isRequired,
  prices: shape().isRequired,
}
