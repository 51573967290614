import i18next from 'i18next'
import { MB_AS_BYTES } from '#constants/maxFileSize'

export const createImageValidator = (
  allowedTypes,
  t,
  // eslint-disable-next-line no-unused-vars
  minWidth = 1,
  minHeight = 1,
  maxSizeMb,
  hasToBeSquare = false,
  maxWidth = null,
  maxHeight = null
) => {
  return async file => {
    const maxFileSize = MB_AS_BYTES * maxSizeMb
    const type = file?.type.toLowerCase()

    if (!file) {
      return { isValid: false, message: i18next.t('validation:requiredField') }
    }

    if (file.size > maxFileSize) {
      return {
        isValid: false,
        message: i18next.t('validation:fileIsTooBig', {
          size: maxSizeMb,
        }),
      }
    }

    if (!allowedTypes.includes(type)) {
      return { isValid: false, message: i18next.t('validation:wrongFileType') }
    }

    const image = await new Promise(resolve => {
      const url = URL.createObjectURL(file)
      const newImage = new Image()
      newImage.onload = () => {
        resolve(newImage)
      }
      newImage.src = url
    })

    const isImageSquare = hasToBeSquare ? image.width === image.height : true

    const hasImageMinDimension =
      minWidth || minHeight
        ? image.width >= minWidth && image.height >= minHeight
        : true

    const hasImageMaxDimension =
      maxWidth || maxHeight
        ? image.width <= maxWidth && image.height <= maxHeight
        : true

    const isDimensionValid =
      isImageSquare &&
      hasImageMinDimension &&
      hasImageMaxDimension &&
      image.width >= minHeight &&
      image.height >= minHeight

    return {
      isValid: isDimensionValid,
      message: i18next.t('validation:wrongFileDimension', {
        min: i18next.t('validation:minDimension', {
          width: minWidth,
          height: minHeight,
        }),
        max:
          maxWidth && maxHeight
            ? i18next.t('validation:maxDimension', {
                width: maxWidth,
                height: maxHeight,
              })
            : '',
      }),
    }
  }
}
