import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class SplitsApi {
  static postSplit = data => getApiClient().post(ENDPOINTS.SPLITS, data)

  static deleteSplit = splitId =>
    getApiClient().delete(buildUrl(ENDPOINTS.SPLITS_SPLIT, { splitId }))

  static postSplitOffPlatform = data =>
    getApiClient().post(ENDPOINTS.SPLITS_INVITE, data)

  static updateSplit = (splitId, data) =>
    getApiClient().patch(buildUrl(ENDPOINTS.SPLITS_SPLIT, { splitId }), data)

  static updateIncomes = (structureId, data) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SPLIT, { structureId }),
      data
    )

  static approveSplit = (splitId, data) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.SPLITS_SPLIT_APPROVE, { splitId }),
      data
    )

  static denySplit = (splitId, data) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.SPLITS_SPLIT_REJECT, { splitId }),
      data
    )

  static reinviteSplit = splitId =>
    getApiClient().post(buildUrl(ENDPOINTS.SPLITS_SPLIT_REINVITE, { splitId }))
}
