import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, makeStyles } from '@material-ui/core'
import { useParams } from '@reach/router'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useSinglePromotion } from '#hooks/swr/usePromotions'
import { Breadcrumbs } from '#components/breadcrumbs'
import {
  REVIEW_LEVEL,
  promotionBreadcrumbs,
} from '#pages/promotion/_utils/constants'
import { PassionTargetMarketingRating } from '#pages/promotion/report/components/passion-target-marketing-rating'
import { StickyReviewNavbar } from '#pages/promotion/report/components/sticky-review-navbar'
import { generateSideBarMenuFilteredLinks } from '../_utils/helpers'
import { MarketPotential } from '#pages/promotion/report/components/market-potential'
import { TrackRating } from '#pages/promotion/report/components/track-rating'
import { MarketPotentialInGenre } from '#pages/promotion/report/components/market-potential-in-genre'
import { DistributionOfRatings } from '#pages/promotion/report/components/distribution-of-ratings'
import { MajorLabelTrackRating } from '#pages/promotion/report/components/major-label-track-rating'
import { WordCloud } from './components/world-cloud'
import { ElementAnalysis } from '#pages/promotion/report/components/element-analysis'
import { GenreTrackRating } from '#pages/promotion/report/components/genre-track-rating'
import { SimilarArtistsMentioned } from '#pages/promotion/report/components/similar-artists-mentioned'
import { ReviewerDemographics } from '#pages/promotion/report/components/reviewer-demographics'
import { ReviewRowElement } from './components/reviewer-row-element'
import { ReviewerComments } from '#pages/promotion/report/components/reviewer-comment'
import { PageWrapper } from '#modules/page-wrapper'

const useStyles = makeStyles(theme => ({
  reportContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  reportWrapper: {
    padding: '24px 0 24px 24px',
  },
  horizontalGraphContainer: {
    width: '100%',
    minHeight: '250px',
    minWidth: '300px',
    margin: '0 auto',
  },
  distributionOfRatingsGraphContainer: {
    width: '100%',
    minHeight: '350px',
    minWidth: '300px',
    margin: '0 auto',
  },
  majorLabelTrackRatingGraphContainer: {
    width: '100%',
    height: '500px',
    minWidth: '300px',
    margin: '0 auto',
    marginBottom: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
    },
  },
  stickyNavbarContainer: {
    justify: 'center',
    alignItems: 'flex-start',
    borderRight: `1px solid ${theme.palette.color.darkGrey}`,
    minWidth: 325,
    padding: 24,
  },
}))

// eslint-disable-next-line complexity
export const Report = () => {
  const { promotionId } = useParams()
  const { promotion, isLoading } = useSinglePromotion(promotionId)
  const { t } = useTranslation()
  const promotionTranslation = useTranslation('promotion')
  const classes = useStyles()
  const { trackName, file, reviewLevel, report } = promotion || {}
  const { data, reviews } = report || {}
  const {
    PassionRating,
    InGenreClass,
    TrackRating: trackRating,
    RatingDistribution,
    PositioningLabelOther,
    PositioningThisTrack,
    PositioningGenreOther,
    WordCloud: wordCloud,
    SongAnalysis,
    ArtistsMentioned,
    SampleGroupAge,
    SampleGroupGender,
    Reviews,
    MktPot,
    MktPotAge,
    MktPotAgeGenre,
    MktPotGenre,
  } = data || {}

  const [mktPotData, setMktPotData] = useState([])
  const [mktPotDataGenre, setMktPotDataGenre] = useState([])

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const data = [
      {
        [promotionTranslation.t('overall')]: MktPot,
      },
      ...(MktPotAge || []),
    ]
    setMktPotData(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MktPot])

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const data = [
      {
        [promotionTranslation.t('overall')]: MktPotGenre,
      },
      ...(MktPotAgeGenre || []),
    ]
    setMktPotDataGenre(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MktPotAgeGenre])

  const sidebarMenuFilteredLinks = generateSideBarMenuFilteredLinks(reviewLevel)

  const isTier2or3 =
    reviewLevel === REVIEW_LEVEL.PLATINUM ||
    reviewLevel === REVIEW_LEVEL.DIAMOND

  const isPassionTargetMarketingRatingShown =
    isTier2or3 && PassionRating && InGenreClass
  const isMarketPotentialShown = isTier2or3 && mktPotData.length > 0
  const isMarketPotentialInGenreShown =
    reviewLevel === REVIEW_LEVEL.DIAMOND && mktPotDataGenre.length > 0
  const isDistributionOfRatingsShown = isTier2or3
  const isMajorLabelTrackRatingShown =
    reviewLevel === REVIEW_LEVEL.DIAMOND &&
    PositioningLabelOther &&
    PositioningThisTrack
  const isGenreTrackRatingShown = isTier2or3 && PositioningGenreOther
  const isElementAnalysisShown = isTier2or3 && SongAnalysis
  const isSimilarArtistsMentionedShown =
    reviewLevel === REVIEW_LEVEL.DIAMOND && ArtistsMentioned

  const breadCrumbs = [
    ...promotionBreadcrumbs(promotionTranslation.t),
    {
      title: trackName,
      link: '',
    },
  ]

  if (!promotion) {
    return (
      <CommonLoadingOverlay
        dialogOpen={isLoading}
        message={t('loadingTunego')}
      />
    )
  }

  return (
    <PageWrapper showFooter showNavBar>
      <Container>
        <Breadcrumbs breadcrumbs={breadCrumbs} />
        <div>
          <div className={classes.reportContainer}>
            <div className={classes.stickyNavbarContainer}>
              <StickyReviewNavbar links={sidebarMenuFilteredLinks} />
            </div>
            <div className={classes.reportWrapper}>
              <TrackRating
                promotionId={promotionId}
                songName={trackName}
                file={file}
                trackRating={trackRating}
              />
              {isPassionTargetMarketingRatingShown && (
                <PassionTargetMarketingRating
                  passionRating={PassionRating}
                  inGenreClass={InGenreClass}
                />
              )}
              {isDistributionOfRatingsShown && (
                <DistributionOfRatings data={RatingDistribution} />
              )}
              {isMarketPotentialShown && <MarketPotential data={mktPotData} />}
              {isMarketPotentialInGenreShown && (
                <MarketPotentialInGenre data={mktPotDataGenre} />
              )}
              {isMajorLabelTrackRatingShown && (
                <MajorLabelTrackRating
                  data={PositioningLabelOther}
                  thisTrack={PositioningThisTrack}
                />
              )}
              {isGenreTrackRatingShown && (
                <GenreTrackRating
                  data={PositioningGenreOther}
                  thisTrack={PositioningThisTrack}
                />
              )}
              {wordCloud && <WordCloud data={wordCloud} />}
              {isElementAnalysisShown && (
                <ElementAnalysis data={SongAnalysis} />
              )}
              {isSimilarArtistsMentionedShown && (
                <SimilarArtistsMentioned
                  data={ArtistsMentioned}
                  total={Reviews}
                />
              )}
              {reviewLevel === REVIEW_LEVEL.DIAMOND &&
                SampleGroupAge &&
                SampleGroupGender && (
                  <ReviewerDemographics
                    total={Reviews}
                    dataSampleGroupAge={SampleGroupAge}
                    dataSampleGroupGender={SampleGroupGender}
                  />
                )}
              {reviews && (
                <ReviewRowElement
                  refName="reviewerCommentsEl"
                  title="reviewerComments"
                >
                  <ReviewerComments data={reviews} />
                </ReviewRowElement>
              )}
            </div>
          </div>
        </div>
      </Container>
    </PageWrapper>
  )
}
