export const PRICE_TYPE = {
  MASTER_TRACK: 'master-track',
  DISTRO_ALBUM: 'distro-album',
  DISTRO_SINGLE: 'distro-single',
  REVIEW_GOLD: 'review-gold',
  REVIEW_PLATINUM: 'review-platinum',
  REVIEW_DIAMOND: 'review-diamond',
  SUBSCRIPTION_FREE: 'subscription-free',
}

const PROMO_TYPE = {
  GOLD: 'gold',
  PLATINUM: 'platinum',
  DIAMOND: 'diamond',
}

export const PROMO_PRICE_TYPE = {
  [PROMO_TYPE.GOLD]: PRICE_TYPE.REVIEW_GOLD,
  [PROMO_TYPE.PLATINUM]: PRICE_TYPE.REVIEW_PLATINUM,
  [PROMO_TYPE.DIAMOND]: PRICE_TYPE.REVIEW_DIAMOND,
}
