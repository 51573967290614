export const FILE_EXTENSIONS = {
  AUDIO: [
    'flac',
    'wav',
    'wave',
    'ogg',
    'mp3',
    'm4a',
    'opus',
    'oga',
    'mka',
    'aif',
    'aiff',
  ],
  DIGITAL_ART: ['jpeg', 'jpg', 'png', 'svg', 'tiff', 'gif'],
  VIDEO: ['webm', 'mp4', 'avi', 'mov', 'mpg', 'qt'],
  NOT_SUPPORTED: ['tiff', 'avi', 'mpg', 'qt'],
}
