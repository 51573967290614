export const ACTION_TYPES = {
  editProfile: 'editProfile',
  addEditCollaborators: 'addEditCollaborators',
  creatorPage: 'creatorPage',
  copyLink: 'copyLink',
}

export const OWNER_MENU_OPTIONS = [
  {
    title: 'Edit Persona',
    actionType: ACTION_TYPES.editProfile,
  },
  {
    title: 'Add / Edit Collaborators',
    actionType: ACTION_TYPES.addEditCollaborators,
  },
]

export const COLLABORATOR_MENU_OPTIONS = [
  {
    title: 'Collaborators',
    actionType: ACTION_TYPES.addEditCollaborators,
  },
]

export const MENU_OPTIONS = []
