import React, { useState } from 'react'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'
import { useNftData } from '#pages/sell-collectibles/create-collectible/_hooks'
import { ErrorMessage } from '#components/error-message'

const SWrapper = styled.div`
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.orange_50};
  border-top-width: 0.625rem;
  padding: 2rem 2.5rem;
  margin-bottom: 2.5rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SHeading = styled.h3`
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: inherit;
  margin-top: 0;
`

const SList = styled.ul`
  margin: 0 0 1.5rem;
  padding: 0 0 0 0.625rem;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50}; ;
`

const SReviewProblems = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50}; ;
`

const SErrorMessage = styled(ErrorMessage)`
  margin-top: 1rem;
`

export const DeniedInfo = () => {
  const { t } = useTranslation('nftWizard')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const {
    savedNft: { reviewProblems },
  } = useNftCreator()

  const { restoreNft } = useNftData()
  const goToEdit = async () => {
    setError(false)
    setLoading(true)

    try {
      await restoreNft()
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SWrapper>
      <SHeading>{t('steps.review.submissionDenied')}</SHeading>
      <SList>
        <li>
          <SReviewProblems>{reviewProblems}</SReviewProblems>
        </li>
      </SList>

      <Button
        buttonTheme={ButtonTheme.Warning}
        loading={loading}
        onClick={goToEdit}
      >
        {t('steps.review.goToEdit')}
      </Button>
      {error && <SErrorMessage errorMessage={t('steps.review.restoreError')} />}
    </SWrapper>
  )
}
