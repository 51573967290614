import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ConfirmationModal } from '#components/confirmation-modal'
import { MODAL_TYPES } from '#pages/profile/utils/constants'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 32,
    fontFamily: theme.typography.bold,
    lineHeight: '28px',
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  deleteTitle: {
    textAlign: 'center',
    color: theme.palette.color.warning,
  },
  deleteDescription: {
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 700,
  },
  buttonsWrapper: {
    margin: '1rem 0',
    flex: 1,
  },
}))

export const PaymentMethodModal = ({
  title,
  onClose,
  onConfirm,
  isOpened,
  modalType,
  isOwner,
}) => {
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const classes = useStyles()

  const isDeleteModal = modalType === MODAL_TYPES.DELETE_THIS_CARD

  return (
    <ConfirmationModal
      isWarning={isDeleteModal}
      isOpened={isOpened}
      confirmationText={`${t('yes')}, ${profileTranslation.t(
        isDeleteModal ? `permanentlyDelete` : `${modalType}`
      )}`}
      isOwner={isOwner}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <span
        className={cn(classes.title, {
          [classes.deleteTitle]: isDeleteModal,
        })}
      >
        {title}
      </span>
      <div className={classes.buttonsWrapper}>
        <span
          className={cn({
            [classes.deleteDescription]: isDeleteModal,
          })}
        >
          {isDeleteModal
            ? profileTranslation.t(`youAreAboutTo`)
            : profileTranslation.t(`areYouSureSetAsDefault`)}
        </span>
      </div>
    </ConfirmationModal>
  )
}

PaymentMethodModal.propTypes = {
  title: string.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  isOpened: bool.isRequired,
  modalType: string.isRequired,
  isOwner: bool.isRequired,
}
