import React from 'react'
import { string, number, bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    icon: {
      fill: props =>
        props.isActive
          ? theme.palette.color.default
          : theme.palette.color.darkGrey84,
    },
  }
})

export const ChevronIcon = ({
  className,
  width,
  height,
  isActive,
  onClick,
}) => {
  const classes = useStyles({ isActive })
  return (
    <svg className={className} width={width} height={height} onClick={onClick}>
      <path
        className={classes.icon}
        d="M208.5 25.517c-.668-.69-1.748-.69-2.416 0l-5.074 5.227-5.074-5.227c-.669-.69-1.766-.69-2.435 0-.668.688-.668 1.819 0 2.507l6.274 6.464c.343.353.772.512 1.217.512.446 0 .875-.177 1.218-.512l6.274-6.464c.685-.688.685-1.819.017-2.507z"
        transform="translate(-190 -20)"
      />
    </svg>
  )
}

ChevronIcon.propTypes = {
  className: string,
  width: number.isRequired,
  height: number.isRequired,
  isActive: bool,
  onClick: func.isRequired,
}

ChevronIcon.defaultProps = {
  className: '',
  isActive: false,
}
