import React from 'react'
import { string } from 'prop-types'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const SSpan = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
`
const SRequired = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.sm};
  margin-top: 1.25rem;
`

export const RequiredInfo = ({ i18nKey }) => {
  return (
    <SRequired>
      <Trans i18nKey={i18nKey || 'required'} ns="nftWizard">
        <SSpan />
      </Trans>
    </SRequired>
  )
}

RequiredInfo.propTypes = {
  i18nKey: string,
}
