import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { useEventSWR } from '#hooks/swr/useEventSWR'
import { EVENTS } from '#api/event'
import { useServerEvent } from '#hooks/swr/useServerEvent'

export const useOwnerShares = (structureId, query = '') => {
  const { data, error, mutate, ...rest } = useEventSWR({
    eventKey: structureId ? `${EVENTS.STRUCTURE_REMOVED}.${structureId}` : null,
    swrKey: structureId
      ? `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SHARES, {
          structureId,
        })}${query}`
      : null,
  })
  useServerEvent({
    eventKey: [
      EVENTS.SHARE_UPDATED,
      EVENTS.SHARE_EXPIRED,
      EVENTS.SHARE_CREATED,
    ],
    eventAction: event => {
      if (event.type === EVENTS.SHARE_CREATED) {
        if (event.payload.projectId === structureId) {
          mutate() // mutate entire list due to pagination
        }
      } else if (event.payload.projectId === structureId) {
        // patch share in-place
        mutate({
          count: data.count,
          data: data.data.map(share => {
            if (share.id === event.payload.id) {
              return event.payload
            }
            return share
          }),
        })
      }
    },
  })
  return {
    shares: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    ...rest,
  }
}

export const useAllReceivedShares = (query = '', config) => {
  const { data, error, mutate, ...rest } = useSWR(
    `${ENDPOINTS.SHARES_ALL_RECEIVED}${query}`,
    config
  )
  useServerEvent({
    eventKey: [
      EVENTS.SHARE_UPDATED,
      EVENTS.SHARE_EXPIRED,
      EVENTS.SHARE_CREATED,
      EVENTS.STRUCTURE_REMOVED,
    ],
    eventAction: event => {
      if (
        event.type === EVENTS.SHARE_CREATED ||
        event.type === EVENTS.STRUCTURE_REMOVED
      ) {
        mutate() // mutate entire list due to pagination and unknown structure
      } else {
        // patch share in-place
        mutate({
          count: data.count,
          data: data.data.map(share => {
            if (share.id === event.payload.id) {
              return event.payload
            }
            return share
          }),
        })
      }
    },
  })
  return {
    shares: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    ...rest,
  }
}
