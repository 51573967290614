import React, { useEffect, useState } from 'react'
import { Modal } from '#components/modal'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { VaultModalContainer } from '#pages/vault/profile-content/containers/vault-modal-container'
import styled from 'styled-components'
import { useReload } from '#modules/page-wrapper/context'
import { AlbumOrProfileContainer } from '#pages/vault/profile-content/containers/album-or-profile-container'
import { SongApi } from '#api/requests/song'
import { packValidationSchema } from '#pages/vault/song/utils/validation'
import { ErrorMessage } from '#components/error-message'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useVault } from '#hooks/useVault'

const SModal = styled(Modal)`
  padding: 2rem 2.5rem;
`

const SWrapper = styled.div`
  padding-bottom: 1rem;
`

const PACK_FIELD = 'pack'

export const MoveSingleModal = ({
  setIsOpen,
  artistId,
  projectId,
  isProjectInFolder,
  folderParentId,
  ...modalProps
}) => {
  const { t } = useTranslation('vault')
  const [hasFolders, setHasFolders] = useState(true)

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(packValidationSchema),
  })

  useEffect(() => {
    setValue(PACK_FIELD, folderParentId)
  }, [folderParentId])

  const { setReloading } = useReload()
  const { setExpandedStructureId } = useVault()

  const { actions, loading, errors } = useWithAsyncAction({
    moveSong: SongApi.postMoveSong,
  })

  const onSubmit = async ({ pack }) => {
    await actions.moveSong(projectId, pack)

    setReloading(true)
    setExpandedStructureId(pack)
    setIsOpen(false)
  }

  return (
    <>
      <SModal {...modalProps} setIsOpen={setIsOpen} closeBtn onClose={reset}>
        <VaultModalContainer
          title={t('moveSingle')}
          disabled={!hasFolders}
          btnText={t('move')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name={PACK_FIELD}
            render={({ field: { value, onChange }, fieldState }) => {
              const errMsg =
                fieldState.error?.message || errors.moveSong?.message

              const onSelect = value => {
                onChange(value || artistId)
              }

              return (
                <SWrapper>
                  <AlbumOrProfileContainer
                    parentId={artistId}
                    selectedId={value}
                    setSelectedId={onSelect}
                    isProjectInFolder={isProjectInFolder}
                    setHasFolders={setHasFolders}
                  />
                  {errMsg && <ErrorMessage errorMessage={errMsg} />}
                </SWrapper>
              )
            }}
          />
        </VaultModalContainer>
      </SModal>
      <CommonLoadingOverlay
        message={t('loading')}
        dialogOpen={loading.moveSong}
      />
    </>
  )
}

MoveSingleModal.propTypes = {
  isOpen: bool,
  setIsOpen: func,
  artistId: string,
  isProjectInFolder: bool,
  folderParentId: string,
  projectId: string,
}
