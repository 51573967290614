import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'

export const usePastRelease = distroId => {
  const { data, error, ...rest } = useSWR(
    distroId
      ? buildUrl(ENDPOINTS.DISTRO_SINGLE_DISTRO, {
          distroId,
        })
      : null
  )

  return {
    pastRelease: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const usePastReleases = (query = '') => {
  const { data, error, ...rest } = useSWR(`${ENDPOINTS.DISTRO_SETUP}${query}`)

  return {
    pastReleases: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
