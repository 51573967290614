import { css } from 'styled-components'
import { theme } from '@tunego/tunego-ui-components'

export const TOOLTIP_THEME = {
  PRIMARY: 'primary',
  WARNING: 'warning',
}
export const TOOLTIP_COLOR = {
  [TOOLTIP_THEME.PRIMARY]: theme.tunego.COLORS.primary_50,
  [TOOLTIP_THEME.WARNING]: theme.tunego.COLORS.warning_50,
}

const arrowTop = css`
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 0.75rem solid ${({ tooltipTheme }) => TOOLTIP_COLOR[tooltipTheme]};
  bottom: -0.75rem;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    border-top: 0.7rem solid
      ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
    bottom: 0.1rem;
    left: -0.7rem;
  }
`

const arrowBottom = css`
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-bottom: 0.75rem solid
    ${({ tooltipTheme }) => TOOLTIP_COLOR[tooltipTheme]};
  top: -0.75rem;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    border-bottom: 0.7rem solid
      ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
    top: 0.1rem;
    left: -0.7rem;
  }
`

const arrowLeft = css`
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
  border-left: 0.75rem solid
    ${({ tooltipTheme }) => TOOLTIP_COLOR[tooltipTheme]};
  right: -0.75rem;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 0.7rem solid transparent;
    border-bottom: 0.7rem solid transparent;
    border-left: 0.7rem solid
      ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
    right: 0.1rem;
    bottom: -0.7rem;
  }
`

const arrowRight = css`
  border-top: 0.75rem solid transparent;
  border-bottom: 0.75rem solid transparent;
  border-right: 0.75rem solid
    ${({ tooltipTheme }) => TOOLTIP_COLOR[tooltipTheme]};
  left: -0.75rem;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 0.7rem solid transparent;
    border-bottom: 0.7rem solid transparent;
    border-right: 0.7rem solid
      ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
    left: 0.1rem;
    bottom: -0.7rem;
  }
`

export const ARROW_STYLE = {
  top: arrowTop,
  right: arrowRight,
  left: arrowLeft,
  bottom: arrowBottom,
}
