import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '#api/api'

export class WalletsApi {
  static postWallet = data => {
    return getApiClient().post(ENDPOINTS.WALLETS, data)
  }

  static checkRoyaltyReceivers = accounts => {
    return getApiClient().get(
      `${ENDPOINTS.WALLETS_CHECK_ROYALTY_RECEIVERS}?accounts[]=${accounts[0]}`
    )
  }

  static getRoyaltySetupTxCode = () => {
    return getApiClient().get(ENDPOINTS.WALLETS_GET_ROYALTY_SETUP_TX_CODE)
  }
}
