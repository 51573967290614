import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import { func } from 'prop-types'
import { SupportedFileTypes } from '#pages/distro/wizard/steps/add-song-files/components/supported-file-types'
import { SectionTitle } from '#components/section-title'
import { ACCEPTED_AUDIO_TYPES } from '#constants/acceptedFileTypes'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  dropzoneWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 480,
    height: 480,
    cursor: 'pointer',
    border: `2px dashed ${theme.palette.color.primary}`,
    boxShadow: `0 0 20px 0 ${theme.palette.color.primary}`,
  },
  title: {
    fontSize: 16,
    fontFamily: theme.typography.bold,
    margin: '30px auto',
    textAlign: 'center',
  },
}))

const SButton = styled(Button)`
  width: 238px;
  height: 48px;
  border-radius: 10px;
  margin: 30px 0;
`

export const SongFileUploader = ({ handleFileDrop }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')

  return (
    <div>
      <SectionTitle>{distroTranslation.t('uploadNewSongFiles')}</SectionTitle>
      <Dropzone accept={ACCEPTED_AUDIO_TYPES} onDrop={handleFileDrop}>
        {({ getRootProps, getInputProps }) => {
          return (
            <div className={classes.dropzoneWrapper} {...getRootProps()}>
              <input {...getInputProps()} />
              <span className={classes.title}>
                {distroTranslation.t('uploadAudioFiles')}
              </span>
              <img
                src="/assets/svg/ic-upload.svg"
                width="29px"
                height="35px"
                alt=""
              />
              <SButton>{t('selectFiles')}</SButton>
            </div>
          )
        }}
      </Dropzone>
      <SupportedFileTypes />
    </div>
  )
}

SongFileUploader.propTypes = {
  handleFileDrop: func.isRequired,
}
