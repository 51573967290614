import React from 'react'
import { string } from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { SUPPORTED_PROMOTION_FILE_TYPES } from '#pages/promotion/_utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { buildUrl } from '#utils/buildUrl'
import { SectionTitle } from '#components/section-title'

const useStyles = makeStyles(() => ({
  instructionsWrapper: {
    padding: '32px 48px',
  },
  wrapper: {
    margin: '32px 0',
  },
  information: {
    display: 'inline',
    fontSize: 16,
  },
  redirectMessage: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  supportedTypes: {
    display: 'inline-block',
    marginBottom: 20,
  },
  fileType: {
    width: 40,
    marginRight: 94,
    fontWeight: 'bold',
  },
  minLength: {
    display: 'inline-block',
    marginTop: 20,
  },
  extensionsWrapper: {
    display: 'flex',
  },
}))

export const PromotionInstructions = ({ parentId }) => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  const handleRedirect = () =>
    navigate(
      buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { profileId: parentId })
    )

  return (
    <div className={classes.instructionsWrapper}>
      <SectionTitle>{t('promotionInstructions')}</SectionTitle>
      <div className={classes.wrapper}>
        <span className={classes.information}>{t('youCanSelectSongs')}</span>
        <span
          className={cn(classes.information, classes.redirectMessage)}
          onClick={handleRedirect}
        >
          {t('clickHereIfYouDont')}
        </span>
      </div>
      <span className={classes.supportedTypes}>{t('supportedFileTypes')}</span>
      {SUPPORTED_PROMOTION_FILE_TYPES.map(fileExtension => {
        return (
          <div key={fileExtension} className={classes.extensionsWrapper}>
            <span className={classes.fileType}>
              {t(`fileType${fileExtension}`)}
            </span>
            <span>{t(`fileType${fileExtension}Description`)}</span>
          </div>
        )
      })}
      <span className={classes.minLength}>{t('yourTrackMustBe90sec')}</span>
    </div>
  )
}

PromotionInstructions.propTypes = {
  parentId: string.isRequired,
}
