import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { formatPrice } from '#utils/formatPrice'
import { number, shape } from 'prop-types'

const SCell = styled.th`
  border-bottom: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  padding: 0.625rem 0;
  text-align: left;

  &:last-child {
    text-align: right;
  }
`

const SBoldCell = styled(SCell)`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SSummaryCell = styled(SCell)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  border: none;
`

export const SummaryTableBody = ({ fees, price, scarcity }) => {
  const { t } = useTranslation('nftWizard')

  const totalPrice = scarcity * price

  const feePercentage = fees?.quickListing?.percentage

  const fee = (feePercentage * totalPrice) / 100

  const creatorEarnings = totalPrice - fee

  return (
    <tbody>
      <tr>
        <SBoldCell>{scarcity || 0}</SBoldCell>
        <SBoldCell>
          {formatPrice(price || 0, {
            freeText: false,
            trailingZeros: true,
          })}
        </SBoldCell>
        <SBoldCell>
          {formatPrice(totalPrice, {
            freeText: false,
            trailingZeros: true,
          })}
        </SBoldCell>
      </tr>
      <tr>
        <SCell colSpan={2}>
          {t('steps.details.dropFee', {
            fee: `${feePercentage}`,
          })}
        </SCell>
        <SCell>
          -{formatPrice(fee, { freeText: false, trailingZeros: true })}
        </SCell>
      </tr>
      <tr>
        <SSummaryCell colSpan={2}>
          {t('steps.details.creatorEarnings')}
        </SSummaryCell>
        <SSummaryCell>
          {formatPrice(creatorEarnings, {
            freeText: false,
            trailingZeros: true,
          })}
        </SSummaryCell>
      </tr>
    </tbody>
  )
}

SummaryTableBody.propTypes = {
  fees: shape({
    quicklisting: shape({
      percentage: number,
    }),
  }),
  price: number,
  scarcity: number,
}
