import React from 'react'
import { useTranslation } from 'react-i18next'
import { func, string } from 'prop-types'
import { ImageDropzone } from '#components/image-dropzone'

export const ProfileImageDropzone = props => {
  const { handlePreview, previewFile, handleDeleteFile } = props
  const { t } = useTranslation('profile')

  return (
    <ImageDropzone
      label={t('profileImage')}
      name="profileImage"
      handlePreview={handlePreview}
      previewFile={previewFile}
      placeholderImage="/assets/svg/ic-default-user.svg"
      handleDeleteFile={handleDeleteFile}
    />
  )
}

ProfileImageDropzone.propTypes = {
  handlePreview: func.isRequired,
  handleDeleteFile: func.isRequired,
  previewFile: string,
}

ProfileImageDropzone.defaultProps = {
  previewFile: '',
}
