import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { bool, shape, string, func } from 'prop-types'
import { PopperTooltip } from '#components/popper-tooltip'

const SBreadcrumbSpan = styled.span`
  color: ${({ theme: { colors } }) => colors.default};
  font-size: 1.125rem;
  font-family: ${({ theme: { typography } }) => typography.bold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
`

const SPopperTooltip = styled(PopperTooltip)`
  padding-right: 2.5rem;
  &:not(:last-of-type) {
    &::after {
      content: '-';
      position: absolute;
      right: 1rem;
      top: 0;
      height: 100%;
    }
  }
`

const SBreadcrumbLink = styled(Link)`
  :link,
  :visited,
  :hover,
  :active {
    color: ${({ theme: { colors } }) => colors.default};
    font-size: 18px;
    font-family: ${({ theme: { typography } }) => typography.bold};
    text-decoration: none;
  }
`

export const BreadcrumbItem = ({ title, link, state, disabled, onClick }) => {
  const [tooltipActive, setTooltipActive] = useState(false)

  return (
    <SPopperTooltip
      text={title}
      disabled={!tooltipActive}
      placement="bottom"
      onOverflow={setTooltipActive}
    >
      <SBreadcrumbSpan>
        {title &&
          (disabled || !link ? (
            title
          ) : (
            <SBreadcrumbLink to={link} state={state} onClick={onClick}>
              {title}
            </SBreadcrumbLink>
          ))}
      </SBreadcrumbSpan>
    </SPopperTooltip>
  )
}

BreadcrumbItem.propTypes = {
  title: string.isRequired,
  state: shape,
  disabled: bool,
  link: string,
  onClick: func,
}

BreadcrumbItem.defaultProps = {}
