import React from 'react'
import { PageWrapper } from '#modules/page-wrapper'
import { ArticlesList } from '#pages/learn-web3/_components/articles-list'

export const LearnWeb3 = () => {
  return (
    <PageWrapper isFullWidth showNavBar>
      <ArticlesList />
    </PageWrapper>
  )
}
