import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseLoader } from '#components/loaders/base-loader'
import { SharesApi } from '#api/requests/shares'
import { FilesApi } from '#api/requests/files'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useSong } from '#pages/vault/song/context/song'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'

import { OwnerSharesContainer } from './containers/owner-shares-container'
import { ReceivedSharesContainer } from './containers/received-shares-container'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'

export const PrivateShares = () => {
  const song = useSong()
  const player = useGlobalAudioPlayer()
  const [areSharesLoading, setAreSharesLoading] = useState(false)
  const [error, setError] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const profileTranslation = useTranslation('profile')
  const vaultTranslation = useTranslation('vault')
  const { actions, loading, errors } = useWithAsyncAction({
    deleteShare: SharesApi.deleteShare,
    accessShare: SharesApi.accessShare,
    getListenLink: FilesApi.getListenLink,
  })

  const setFileToPlay = (link, share) => {
    player.setFileInfo({
      shareId: share.id,
      uri: link,
      fileName: share.fileName,
      projectName: `${share?.profile?.name} · ${share?.project?.name}`,
      suffix: 'mp3',
    })
  }

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.deleteShare) {
      handleSetError(errors.deleteShare)
    }
  }, [errors, handleSetError])

  const isPrivateShareRole =
    song?.accessRoles.includes(ACCESS_ROLES.PRIVATE_SHARE) ||
    song?.accessRoles.includes(ACCESS_ROLES.COLLABORATOR)

  return (
    <ColumnWrapper>
      <BaseLoader
        isLoading={areSharesLoading || loading.deleteShare}
        text={profileTranslation.t('privateSharesLoading')}
      />
      {(loading.deleteShare || error) && (
        <CommonLoadingOverlay
          message={vaultTranslation.t('deletingPrivateShare')}
          dialogOpen={loading.deleteShare}
          error={error}
          onErrorBackdropClick={() => {
            setError('')
          }}
        />
      )}
      {!!song && !isPrivateShareRole && (
        <OwnerSharesContainer
          {...{
            setFileToPlay,
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            setAreSharesLoading,
            deleteShare: actions.deleteShare,
            getListenLink: actions.getListenLink,
          }}
        />
      )}
      {!!song && isPrivateShareRole && (
        <ReceivedSharesContainer
          {...{
            setFileToPlay,
            isPrivateShareRole,
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            setAreSharesLoading,
            accessShare: actions.accessShare,
          }}
        />
      )}
    </ColumnWrapper>
  )
}
