import useSWR from 'swr'
import { useCallback } from 'react'
import { useServerEvent } from '#hooks/swr/useServerEvent'

export const SWR_EVENT_ACTION_RELOAD = (payload, mutate) => mutate()

export const useEventSWR = ({
  eventKey,
  eventAction,
  swrKey,
  swrFetcher,
  swrConfig,
}) => {
  const swrParams = [swrKey]
  if (swrFetcher) {
    swrParams.push(swrFetcher)
  }
  if (swrConfig) {
    swrParams.push(swrConfig)
  }
  // WARNING: passing last parameter to useSWR as undefined will not work properly
  const result = useSWR(...swrParams)
  const { mutate } = result
  const onConnected = useCallback(() => {
    mutate()
  }, [mutate])
  const listener = useCallback(
    eventData => {
      if (eventAction) {
        eventAction(eventData, mutate)
      } else {
        mutate(eventData.payload)
      }
    },
    [eventAction, mutate]
  )

  useServerEvent({ eventKey, eventAction: listener, onConnected })

  return result
}
