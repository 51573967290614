import React, { createContext, useState } from 'react'
import { node } from 'prop-types'

export const VaultContext = createContext({
  expandedStructureId: null,
  setExpandedStructureId: () => {},
})

const { Provider } = VaultContext

export const VaultProvider = ({ children }) => {
  const [expandedStructureId, setExpandedStructureId] = useState(null)

  const value = { expandedStructureId, setExpandedStructureId }
  return <Provider {...{ value }}>{children}</Provider>
}

VaultProvider.propTypes = {
  children: node.isRequired,
}
