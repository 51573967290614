import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { EVENTS } from '#api/event'
import { useEventSWR } from '#hooks/swr/useEventSWR'

export const useFileCheck = structureId => {
  const { data, error, ...rest } = useEventSWR({
    swrKey: buildUrl(ENDPOINTS.STRUCTURES_CHECK_FILES, {
      id: structureId,
    }),
    eventKey: [
      EVENTS.FILE_CREATED,
      EVENTS.FILE_UPDATED,
      EVENTS.FILE_REMOVED,
      EVENTS.FILE_OVERWRITTEN,
    ],
  })

  return {
    fileChecks: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
