import React from 'react'
import { node } from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  overwritten: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .rce-container-mbox': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .rce-mbox': {
      background: 'none',
      width: '100%',
      float: 'none',
    },
    '& .rce-avatar-container': {
      width: 60,
      height: 60,
      borderRadius: '50%',
      marginRight: 30,
      '& img': {
        objectFit: 'cover',
      },
    },
    '& .rce-mbox-title': {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.color.default,
    },
    '& .rce-mbox-text': {
      marginLeft: 90,
      marginTop: -15,
      opacity: 0.5,
    },
    '& .rce-mbox-time': {
      fontSize: 18,
      color: theme.palette.color.primary,
      top: 16,
      right: 30,
    },
    '& .rce-container-input': {
      height: 100,
      background: theme.palette.color.darkGrey12,
    },
    '& .rce-input': {
      background: theme.palette.color.darkGrey12,
      color: hexToRgba(theme.palette.color.default, 0.5),
      paddingLeft: 30,
      maxHeight: '100%',
    },
  },
}))

export const OverwrittenChatStylesWrapper = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.overwritten}>{children}</div>
}

OverwrittenChatStylesWrapper.propTypes = {
  children: node.isRequired,
}
