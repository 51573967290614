import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { SYNC_LICENSING_ICONS } from '#pages/promotion/_utils/constants'

const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    justifyItems: 'center',
    alignItems: 'center',
    gridGap: 40,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
  },
  img: {
    width: 'auto',
  },
  more: {
    fontSize: '32px',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginTop: 80,
  },
}))

export const SyncLicensingIcons = () => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  return (
    <>
      <div className={classes.gridContainer}>
        {Object.entries(SYNC_LICENSING_ICONS).map(([key, value]) => (
          <div key={key} className={classes.item}>
            <img src={value} className={classes.img} alt="" />
          </div>
        ))}
      </div>
      <span className={classes.more}>{t('plusManyMore')}</span>
    </>
  )
}
