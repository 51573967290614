import React from 'react'
import { func } from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { PaymentForm } from '#pages/subscriptions/_components/payment-form'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB)

export const PaymentFormContainer = ({ handlePayment }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm handlePurchaseSubmit={handlePayment} />
    </Elements>
  )
}

PaymentFormContainer.propTypes = {
  handlePayment: func.isRequired,
}
