import React, { useState } from 'react'
import styled from 'styled-components'
import { bool, func, string, object, arrayOf, shape } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { Modal } from '#components/modal'
import { Heading } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/heading'
import { DeterminePlaysGranting } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/determine-plays-granting'
import { SearchContactsAutocomplete } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/search-contacts-autocomplete'
import { determineValidationSchema } from '#pages/vault/song/tabs/files/utils/validation'
import { InviteByEmail } from '#pages/vault/song/tabs/files/components/modals/private-share-modal/invite-by-email'
import {
  SHARE_TYPES,
  SIDES,
} from '#pages/vault/song/tabs/files/utils/constants'
import { endOfLocalDay } from '#utils/endOfLocalDay'

const SModal = styled(Modal)`
  width: 620px;
`

const SForm = styled.form`
  width: 100%;
`

export const PrivateShareModalContainer = ({
  name,
  actions,
  projectId,
  files,
  privateShareFileId,
  setPrivateShareFileId,
  setModalFileName,
  isExistingRecipient,
  setSide,
  onClose,
}) => {
  const { t } = useTranslation('vault')
  const [shareType, setShareType] = useState(SHARE_TYPES.plays)
  const {
    handleSubmit,
    register,
    control,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(
      determineValidationSchema({ t, shareType, isExistingRecipient })
    ),
  })

  const sendPrivateShare = async ({
    user,
    expiresAt,
    maxAccessCount,
    pendingName,
    recipientEmail,
  }) => {
    const { fileSuffix } = files.find(file => file.id === privateShareFileId)
    const expirationType = typeof maxAccessCount === 'string' ? 'date' : 'count'

    const share = {
      fileId: privateShareFileId,
      // eslint-disable-next-line radix
      maxAccessCount: parseInt(maxAccessCount),
      expirationType,
      fileSuffix,
      ...(expiresAt && { expiresAt: endOfLocalDay(expiresAt) }),
      ...(isExistingRecipient
        ? { recipientUserId: user.id }
        : { pendingName, recipientEmail }),
    }

    if (isExistingRecipient) {
      await actions.addExistingUserShare(projectId, share)
    } else {
      await actions.inviteOffPlatformUserShare(projectId, share)
    }

    setPrivateShareFileId(null)
    setModalFileName('')
    setSide(SIDES.contacts)
    setShareType(SHARE_TYPES.plays)
    reset()
  }

  const handleClose = () => {
    reset()
    onClose()
    setSide(SIDES.contacts)
    setShareType(SHARE_TYPES.plays)
  }

  return (
    <SModal isOpen={!!name} setIsOpen={handleClose}>
      <Heading />
      <SForm onSubmit={handleSubmit(sendPrivateShare)}>
        {isExistingRecipient ? (
          <SearchContactsAutocomplete
            {...{ setSide: () => setSide(SIDES.email), control, errors }}
          />
        ) : (
          <InviteByEmail
            {...{ setSide: () => setSide(SIDES.contacts), register, errors }}
          />
        )}
        <DeterminePlaysGranting
          {...{
            shareType,
            setShareType,
            onClose: handleClose,
            songName: name,
            register,
            control,
            getValues,
            errors,
          }}
        />
      </SForm>
    </SModal>
  )
}

PrivateShareModalContainer.propTypes = {
  name: string.isRequired,
  sendPrivateShare: func.isRequired,
  isExistingRecipient: bool.isRequired,
  setSide: func.isRequired,
  onClose: func.isRequired,
  actions: object.isRequired,
  projectId: string.isRequired,
  files: arrayOf(
    shape({
      name: string,
    })
  ).isRequired,
  privateShareFileId: string.isRequired,
  setPrivateShareFileId: func.isRequired,
  setModalFileName: func.isRequired,
}
