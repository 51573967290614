import React from 'react'
import { func, string, bool, node, array, oneOfType } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { AutoComplete } from '#components/inputs/auto-complete'
import { CreateStructureFormWrapper } from '#pages/_components/create-structure-form-wrapper'
import { loadProfiles } from '#utils/loadProfiles'
import { profileValidationSchema } from '#pages/_modules/choose-profile-step/utils/validation'
import { makeStyles } from '@material-ui/core/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles(() => ({
  controllerWrapper: {
    maxWidth: 294,
  },
}))

export const ChooseProfileStep = ({
  onSubmit,
  navigateOnCancel,
  withoutCancelButton,
  subtitle,
  children,
  roles,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const classes = useStyles()
  const { useAltStructuresEndpoint } = useFlags()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(profileValidationSchema()),
  })

  const isSubmitDisabled = !isValid && !watch('profile')

  return (
    <CreateStructureFormWrapper
      title={t('selectProfile')}
      subTitle={subtitle || vaultTranslation.t('songsHelpYouSecurelyStore')}
      disabled={isSubmitDisabled}
      nextBtnText={t('select')}
      shouldHideCancelButton={withoutCancelButton}
      navigateOnCancel={navigateOnCancel}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.controllerWrapper}>
        <Controller
          name="profile"
          control={control}
          render={({ field: { onChange } }) => (
            <AutoComplete
              id="search_profile"
              errorMessage={errors.profile?.message}
              placeholder={t('search')}
              loadOptions={loadProfiles(roles, useAltStructuresEndpoint)}
              label={t('chooseProfile')}
              onChange={option => {
                onChange(option)
              }}
            />
          )}
        />
      </div>
      {children}
    </CreateStructureFormWrapper>
  )
}

ChooseProfileStep.propTypes = {
  withoutCancelButton: bool,
  onSubmit: func,
  navigateOnCancel: string,
  subtitle: string,
  children: node,
  roles: oneOfType([array, null]),
}

ChooseProfileStep.defaultProps = {
  withoutCancelButton: false,
  onSubmit: () => {},
  navigateOnCancel: null,
  subtitle: '',
  children: null,
  roles: null,
}
