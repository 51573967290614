export const VARIANTS = {
  WARNING: 'warning',
  CANCEL: 'cancel',
  CANCEL_BLACK: 'cancelBlack',
  CANCEL_TEXT: 'cancelText',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
  DARK_GREY: 'darkGrey',
  NEW_BLUE_SPECIAL: 'newBlueSpecial',
  DISABLED_BLUE: 'disabledBlue',
}
