import React, { useEffect } from 'react'
import { node, func, string, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, FormProvider } from 'react-hook-form'
import { useWizard } from '#modules/forms/form-wizard/context'
import { BottomButtonsNavigation } from '#modules/forms/components/bottom-buttons-navigation'
import { useQuery } from '#hooks/useQuery'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { MasteringApi } from '#api/requests/mastering'
import { MASTERING_STEP } from '#pages/mastering/wizard/utils/constants'

const useStyles = makeStyles(() => ({
  buttons: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
  },
  wrapper: {
    width: '100%',
  },
}))

export const StepWrapperContainer = ({
  children,
  onBackButtonClick,
  onNextButtonClick,
  noNextButton,
  customNextTitle,
  customBackTitle,
  className,
  shouldDisableNextButton,
}) => {
  const classes = useStyles()
  const parentId = useQuery('artistId')
  const selectedFileId = useQuery('trackId')
  const masteredId = useQuery('masteredId')
  const isEdited = useQuery('isEdited')
  const { actions } = useWithAsyncAction({
    deleteMasteredFile: MasteringApi.deleteMasteredFile,
  })

  const {
    setStep,
    setStoredFormState,
    incrementStep,
    decrementStep,
    step,
    previousFormState,
  } = useWizard()

  const noStateData = step === 1 && !previousFormState

  useEffect(() => {
    if (parentId && noStateData) {
      setStep(2)
      setStoredFormState({ parentId })
    }

    if (selectedFileId && noStateData) {
      setStep(3)
      setStoredFormState({ selectedFileId })
    }

    if (masteredId && selectedFileId && noStateData) {
      setStep(4)
      setStoredFormState({ masteredId, selectedFileId, isEdited })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId, selectedFileId, masteredId])

  const methods = useForm({
    defaultValues: {
      profile: [],
    },
  })

  const onSubmit = async data => {
    if (isEdited && step === MASTERING_STEP.OPTIONS) {
      await actions.deleteMasteredFile(masteredId)
      setStoredFormState({ ...data, masteredId: '' })
      incrementStep()
    } else {
      setStoredFormState(data)
      incrementStep()
    }
  }

  const onStepSubmit = () => {
    if (onNextButtonClick) onNextButtonClick()
    methods.handleSubmit(onSubmit)()
  }

  const onStepBack = () => {
    if (onBackButtonClick) onBackButtonClick()
    decrementStep()
  }

  return (
    <div className={`${classes.wrapper} ${className} `}>
      <FormProvider {...methods}>
        <form>
          {children}
          <BottomButtonsNavigation
            className={classes.buttons}
            shouldDisableNextButton={shouldDisableNextButton}
            onBackButtonClick={onStepBack}
            {...{
              customNextTitle,
              customBackTitle,
            }}
            {...(!noNextButton && { onNextButtonClick: onStepSubmit })}
          />
        </form>
      </FormProvider>
    </div>
  )
}

StepWrapperContainer.propTypes = {
  children: node.isRequired,
  onBackButtonClick: func,
  onNextButtonClick: func,
  noNextButton: bool,
  customNextTitle: string,
  customBackTitle: string,
  className: string,
  shouldDisableNextButton: func,
}

StepWrapperContainer.defaultProps = {
  onBackButtonClick: null,
  onNextButtonClick: null,
  noNextButton: false,
  customNextTitle: '',
  customBackTitle: '',
  className: '',
  shouldDisableNextButton: null,
}
