import { useEffect, useState } from 'react'
import { WalletsApi } from '#api/requests/wallets'
import { useError } from '#hooks/useError'

export const useWalletSetup = address => {
  const [isLoading, setIsLoading] = useState(false)
  const [isRoyaltyChecked, setIsRoyaltyChecked] = useState(false)
  const { error, setResponseError } = useError()

  const checkRoyalty = async () => {
    if (!address) return
    setIsLoading(true)

    try {
      const {
        data: { results },
      } = await WalletsApi.checkRoyaltyReceivers([address.toString()])

      const areRoyaltiesTrue = Object.values(results[address]).every(
        value => value === true
      )

      if (areRoyaltiesTrue) {
        setIsRoyaltyChecked(true)
      }
    } catch (err) {
      setResponseError(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkRoyalty()
  }, [address])

  return {
    isRoyaltyChecked,
    error,
    loading: isLoading,
    setIsRoyaltyChecked,
  }
}
