import React from 'react'
import styled from 'styled-components'
import { RadioButton } from '#pages/_components/radio-button'

const SStyledFolderRow = styled.div`
  display: flex;
  height: 3.75rem;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s linear;
  &:hover {
    background-color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_80};
  }
`

const SFolderIcon = styled.img`
  width: 25px;
  margin-right: 1rem;
`

const SFolderName = styled.span`
  width: 25rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SRadioButton = styled(RadioButton)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 1rem;
`

export const AlbumSelectionItem = ({
  album,
  selectedAlbumId,
  setSelectedAlbumId,
}) => {
  const onFolderClickHandler = () => {
    if (selectedAlbumId === album.id) {
      setSelectedAlbumId(null)
    } else {
      setSelectedAlbumId(album.id)
    }
  }

  return (
    <SStyledFolderRow onClick={onFolderClickHandler}>
      <SRadioButton checked={selectedAlbumId === album.id} />
      <SFolderIcon src="/assets/svg/ic-folder-blue.svg" alt="" />
      <SFolderName>{album.name}</SFolderName>
    </SStyledFolderRow>
  )
}
