import React from 'react'
import { arrayOf, number, shape, string, bool, func } from 'prop-types'
import { ListHeader } from '#pages/sell-collectibles/my-collectibles/_components/list-header'
import { CollectibleRow } from '#pages/sell-collectibles/my-collectibles/_components/collectible-row'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { COLLECTIBLE_COLUMNS } from '#pages/sell-collectibles/my-collectibles/_utils/constants'
import { BaseLoader } from '#components/loaders/base-loader'

const STable = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`

const SLoadingCell = styled.td`
  text-align: center;
  padding: 2rem 0;
`

export const CollectibleTable = ({ nfts, isLoading, revalidate }) => {
  const { t } = useTranslation('sellCollectibles')

  return (
    <STable>
      <ListHeader />
      <tbody>
        {isLoading ? (
          <tr>
            <SLoadingCell colSpan={COLLECTIBLE_COLUMNS(t).length}>
              <BaseLoader text={t('loadingCollectibles')} isLoading />
            </SLoadingCell>
          </tr>
        ) : (
          nfts?.map(nft => (
            <CollectibleRow key={nft.id} nft={nft} revalidate={revalidate} />
          ))
        )}
      </tbody>
    </STable>
  )
}

CollectibleTable.propTypes = {
  nfts: arrayOf(
    shape({
      id: string,
      imageId: string,
      packsCount: number,
      status: string,
      title: string,
      totalUnits: number,
    })
  ).isRequired,
  query: string.isRequired,
  isLoading: bool,
  revalidate: func.isRequired,
}
