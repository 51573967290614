import React from 'react'
import { bottomIconType } from './utils/constants'
import { BottomIconTile } from '#pages/connect/landing-page/components/bottom-icons/components/bottom-icon-tile'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4rem',
  },
}))

export const BottomIcons = () => {
  const classes = useStyles()

  return (
    <div className={classes.iconsWrapper}>
      <BottomIconTile iconType={bottomIconType.CLOUD_LOCK} />
      <BottomIconTile iconType={bottomIconType.INFINITY} />
    </div>
  )
}
