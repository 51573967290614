import React, { Fragment, useState } from 'react'
import { string, func, bool, number, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useArtistStructureContent } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { FileRow } from '#modules/choose-file-tree/components/file-row'
import { FilesList } from '#modules/choose-file-tree/components/files-list'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { RowWithLine } from '#modules/choose-file-tree/components/row-with-line'
import { EmptyContent } from '#modules/choose-file-tree/components/empty-content'
import { isEven } from '#utils/isEven'
import styled from 'styled-components'

const SProjectIcon = styled.img`
  width: 1.25rem;
  margin-left: 4.125rem;
  margin-right: 1.25rem;
`

const SProjectName = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SLoaderWrapper = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_5%']};
  padding-bottom: ${({ isLoading }) => (isLoading ? '0.5rem' : '0')};
`

export const FolderContent = ({
  parentId,
  handleFileSelect,
  selectedFileId,
  selectedFilesIds,
  showFilesCondition,
  showFileType,
  storedFilesIds,
  disabled,
  disableSelect,
  disabledTooltipText,
  reloadCount,
  showProcessing,
}) => {
  const [filesListId, setIsFilesListId] = useState()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')

  const query = '&withoutPagination=true'

  const { artistContent, isLoading } = useArtistStructureContent(
    parentId,
    query
  )

  if (artistContent?.data.length === 0) {
    return <EmptyContent type={distroTranslation.t('album')} />
  }

  return (
    <>
      <SLoaderWrapper>
        <BaseLoader isLoading={isLoading} text={t('loading')} />
      </SLoaderWrapper>
      {artistContent?.data.map(
        ({ id, category, name, isPromotionAllowed }, index) => {
          const isLastRow = index + 1 === artistContent?.count
          if (category === STRUCTURE_TYPE.SONG) {
            return (
              <Fragment key={id}>
                <RowWithLine
                  isEven={isEven(index)}
                  isLastRow={isLastRow && filesListId !== id}
                  onClick={() => setIsFilesListId(id)}
                >
                  <SProjectIcon src="/assets/svg/ic-project-white.svg" alt="" />
                  <SProjectName>{name}</SProjectName>
                </RowWithLine>
                {filesListId === id && (
                  <FilesList
                    parentId={id}
                    nested
                    {...{
                      handleFileSelect,
                      selectedFileId,
                      selectedFilesIds,
                      showFilesCondition,
                      showFileType,
                      isMainRowLast: isLastRow,
                      storedFilesIds,
                      disabled,
                      disableSelect,
                      disabledTooltipText,
                      reloadCount,
                      showProcessing,
                    }}
                  />
                )}
              </Fragment>
            )
          }
          if (isPromotionAllowed) {
            return <FileRow key={id} />
          }
          return null
        }
      )}
    </>
  )
}

FolderContent.propTypes = {
  handleFileSelect: func.isRequired,
  parentId: string,
  selectedFileId: string,
  selectedFilesIds: arrayOf(string),
  showFilesCondition: string,
  showFileType: string,
  storedFilesIds: arrayOf(string),
  disabled: bool,
  disableSelect: func,
  disabledTooltipText: string,
  reloadCount: number,
  showProcessing: bool,
}

FolderContent.defaultProps = {
  parentId: null,
  selectedFileId: null,
  selectedFilesIds: null,
  showFilesCondition: '',
  showFileType: '',
  storedFilesIds: [],
  disableSelect: () => false,
  reloadCount: 0,
}
