import React, { useEffect, useState } from 'react'
import { navigate, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { StepWrapperContainer } from '#pages/mastering/wizard/containers/step-wrapper-container'
import { useWizard } from '#modules/forms/form-wizard/context'
import { ProcessingView } from '#pages/mastering/wizard/steps/mastering-step/components/processing-view'
import { ProcessedView } from '#pages/mastering/wizard/steps/mastering-step/components/processed-view'
import { MasteringApi } from '#api/requests/mastering'
import {
  useMasteredFile,
  useMasteredFilePreviewLink,
} from '#hooks/swr/useMasteredFile'
import { NAVIGATION_PATHS } from '#routes/routes'
import { MASTER_STATUS } from '#pages/mastering/wizard/utils/constants'
import { ErrorMessage } from '#pages/mastering/wizard/components/error-message'

const useStyles = makeStyles(() => ({
  errorWrapper: {
    marginTop: '4rem',
  },
}))

export const Mastering = () => {
  const location = useLocation()
  const classes = useStyles()
  const { t } = useTranslation('mastering')
  const [error, setError] = useState('')
  const [masteredFileId, setMasteredFileId] = useState('')
  const [masteredPreviewUrl, setMasteredPreviewUrl] = useState('')
  const [originalPreviewUrl, setOriginalPreviewUrl] = useState('')
  const previewReady = masteredPreviewUrl && originalPreviewUrl
  const { file } = useMasteredFile(previewReady ? null : masteredFileId)
  const { storedFormState, setStoredFormState } = useWizard()
  const { genre, profile, selectedFileId, masteredId } = storedFormState
  const { actions } = useWithAsyncAction({
    masterFiles: MasteringApi.masterFiles,
    deleteMasteredFile: MasteringApi.deleteMasteredFile,
  })

  const { link, isError } = useMasteredFilePreviewLink(
    file?.status === MASTER_STATUS.PREVIEW_READY ? masteredFileId : null
  )

  useEffect(() => {
    if (link) {
      setMasteredPreviewUrl(link)
    }
  }, [link])

  const masterFile = async () => {
    const profiles = profile.length ? profile : ['default']
    try {
      const res = await actions.masterFiles({
        genre,
        profile: profiles,
        fileId: selectedFileId,
      })
      setMasteredFileId(res.data.id)
    } catch (err) {
      const response = err.response?.data
      const { message } = response
      const errorMessage = message || t('somethingWentWrong')
      setError(errorMessage)
    }
  }

  useEffect(() => {
    if (!masteredFileId) return
    MasteringApi.getOriginalListenLink(masteredFileId)
      .then(({ data }) => {
        setOriginalPreviewUrl(data.link)
      })
      .catch(() => setError(t('somethingWentWrong')))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masteredFileId])

  useEffect(() => {
    if (!masteredId) masterFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masteredId && !masteredFileId) {
      setMasteredFileId(masteredId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masteredId])

  const navigateToCheckout = () => {
    setStoredFormState({ masteredId: masteredFileId })
    navigate(
      `${NAVIGATION_PATHS.NEW_MASTERING}?trackId=${selectedFileId}&masteredId=${masteredFileId}&checkout=true`
    )
  }

  const shouldNavigateToMastersList = () => {
    location.state?.fromMastersView && navigate(NAVIGATION_PATHS.VIEW_MASTERS)
  }

  return (
    <StepWrapperContainer
      customNextTitle={t('masterAndDownload')}
      shouldDisableNextButton={!previewReady}
      onNextButtonClick={navigateToCheckout}
      onBackButtonClick={shouldNavigateToMastersList}
    >
      {error || isError ? (
        <div className={classes.errorWrapper}>
          <ErrorMessage message={error || t('somethingWentWrong')} />
        </div>
      ) : (
        <div>
          {previewReady ? (
            <ProcessedView
              masteredFileId={masteredFileId}
              masteredTrack={masteredPreviewUrl}
              originalTrack={originalPreviewUrl}
            />
          ) : (
            <ProcessingView />
          )}
        </div>
      )}
    </StepWrapperContainer>
  )
}
