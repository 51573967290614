import React, { useCallback, useEffect, useState } from 'react'
import { bool, func, string, number, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { mutate } from 'swr'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { ContributorsApi } from '#api/requests/contributors'
import { AddExistingUser } from '#pages/_modules/add-contributor-modal/components/add-existing-user'
import { InviteOffPlatformUser } from '#pages/_modules/add-contributor-modal/components/invite-off-platform-user'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { CollaboratorsApi } from '#api/requests/collaborators'

export const AddContributorModal = ({
  isOpened,
  handleClose,
  isForDistro,
  songId,
  mutateDistro,
  handleAddContributor,
  defaultRole,
  pageIndex,
  rowsPerPage,
}) => {
  const [withAddAsCollaborator, setWithAddAsCollaborator] = useState(false)
  const [error, setError] = useState('')
  const { t } = useTranslation('vault')
  const [isAddExistingUser, setIsAddExistingUser] = useState(true)
  const { projectId } = useParams()

  const { actions, errors, loading } = useWithAsyncAction({
    postContributor: ContributorsApi.postContributor,
    postInviteContributor: ContributorsApi.postInviteContributor,
    postCollaborator: CollaboratorsApi.postCollaborator,
    postCollaboratorInvite: CollaboratorsApi.postCollaboratorInvite,
  })
  const {
    postContributor,
    postInviteContributor,
    postCollaborator,
    postCollaboratorInvite,
  } = loading

  const handleSwitchSides = () => {
    setIsAddExistingUser(prevIsAddExistingUser => !prevIsAddExistingUser)
    setWithAddAsCollaborator(false)
  }

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.postContributor) {
      handleSetError(errors.postContributor)
    }
    if (errors.postInviteContributor) {
      handleSetError(errors.postInviteContributor)
    }
    if (errors.postCollaborator) {
      handleSetError(errors.postCollaborator)
    }
    if (errors.postCollaboratorInvite) {
      handleSetError(errors.postCollaboratorInvite)
    }
  }, [errors, handleSetError])

  const triggerMutate = structureId => {
    mutate(
      `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS, {
        id: structureId,
      })}?page=${pageIndex}&limit=${rowsPerPage}`
    )
  }

  const onClose = () => {
    setIsAddExistingUser(true)
    handleClose()
  }

  const handleInviteContributor = async ({
    contributorName,
    role,
    ...rest
  }) => {
    const formattedData = {
      userId: contributorName.id,
      role: role.map(({ value }) => value),
    }

    await actions.postContributor(isForDistro ? songId : projectId, {
      ...formattedData,
      ...rest,
    })

    if (withAddAsCollaborator) {
      await actions.postCollaborator(
        isForDistro ? songId : projectId,
        formattedData
      )
    }

    triggerMutate(projectId)

    if (mutateDistro) {
      handleAddContributor(formattedData)
      mutateDistro()
    }

    onClose()
  }

  const handleInviteOffPlatformContributor = async ({ role, ...rest }) => {
    const { email, pendingName, publisher } = rest
    const formattedData = {
      role: role.map(({ value }) => value),
      email,
      pendingName,
      ...(!isForDistro && { publisher }),
    }

    await actions.postInviteContributor(
      isForDistro ? songId : projectId,
      formattedData
    )

    if (withAddAsCollaborator) {
      await actions.postCollaboratorInvite(
        isForDistro ? songId : projectId,
        formattedData
      )
    }

    triggerMutate(projectId)

    if (mutateDistro) {
      handleAddContributor(formattedData)
      mutateDistro()
    }

    onClose()
  }

  if (
    postContributor ||
    postInviteContributor ||
    postCollaborator ||
    postCollaboratorInvite ||
    error
  ) {
    return (
      <CommonLoadingOverlay
        message={t('contributorSaving')}
        dialogOpen={
          postContributor ||
          postInviteContributor ||
          postCollaborator ||
          postCollaboratorInvite
        }
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return isAddExistingUser ? (
    <AddExistingUser
      {...{
        handleSwitchSides,
        isOpened,
        handleInviteContributor,
        isForDistro,
        defaultRole,
      }}
      handleClose={onClose}
      setChecked={setWithAddAsCollaborator}
      checked={withAddAsCollaborator}
    />
  ) : (
    <InviteOffPlatformUser
      {...{ handleSwitchSides, isOpened, isForDistro, defaultRole }}
      handleClose={onClose}
      handleInviteContributor={handleInviteOffPlatformContributor}
      setChecked={setWithAddAsCollaborator}
      checked={withAddAsCollaborator}
    />
  )
}

AddContributorModal.propTypes = {
  isOpened: bool.isRequired,
  handleClose: func.isRequired,
  structureId: string.isRequired,
  pageIndex: number.isRequired,
  rowsPerPage: number.isRequired,
  isForDistro: bool,
  songId: string,
  mutateDistro: func,
  handleAddContributor: func,
  defaultRole: shape({}),
  setDefaultRole: func,
}

AddContributorModal.defaultProps = {
  isForDistro: false,
  songId: '',
  mutateDistro: null,
  handleAddContributor: null,
  defaultRole: null,
  setDefaultRole: null,
}
