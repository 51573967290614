import React from 'react'
import styled from 'styled-components'
import { func, bool, shape, arrayOf, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { makeStyles } from '@material-ui/core'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { BaseInput } from '#components/inputs/base-input'
import { Modal } from '#components/modal'
import { ModalHeader } from '#pages/vault/song/tabs/splits/components/modals/_components/modal-header/ModalHeader'
import { ROLES } from '#constants/roles'
import { editRoleValidationSchema } from '#pages/vault/song/tabs/splits/utils/validation'

const useStyles = makeStyles(() => ({
  formWrapper: {
    paddingTop: '1rem',
    width: '100%',
  },
  buttons: {
    marginTop: '1.5rem',
    display: 'flex',
    gap: 16,
  },
}))

const SModal = styled(Modal)`
  width: 620px;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

export const EditSplitRoleModal = ({
  onClose,
  isOpened,
  updateSplit,
  split,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const defaultValue = split?.role?.map(item => ({
    label: ROLES.find(role => role.value === item).label,
    value: item,
  }))
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(editRoleValidationSchema(vaultTranslation.t)),
    defaultValues: { role: defaultValue },
  })

  return (
    <SModal isOpen={isOpened} setIsOpen={onClose}>
      <ModalHeader>{vaultTranslation.t('editRoles')}</ModalHeader>
      <SBaseInput
        inputRef={register}
        name="name"
        label={vaultTranslation.t('userName')}
        value={
          split?.pendingName ||
          `${split?.user.firstName} ${split?.user.lastName}`
        }
        isReadOnly
      />
      <form
        className={classes.formWrapper}
        onSubmit={handleSubmit(updateSplit)}
      >
        <RolesSelect
          control={control}
          defaultValue={defaultValue}
          errorMessage={errors.role?.message}
        />
        <div className={classes.buttons}>
          <Button type="submit">{t('save')}</Button>
          <Button variant={VARIANTS.CANCEL} onClick={onClose}>
            {t('cancel')}
          </Button>
        </div>
      </form>
    </SModal>
  )
}

EditSplitRoleModal.propTypes = {
  onClose: func.isRequired,
  isOpened: bool.isRequired,
  updateSplit: func.isRequired,
  split: shape({
    role: arrayOf(string),
  }).isRequired,
}
