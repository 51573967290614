import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { addSplitOffPlatformValidationSchema } from '#pages/vault/song/tabs/splits/utils/validation'
import { BaseInput } from '#components/inputs/base-input'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { ModalHeader } from '#pages/vault/song/tabs/splits/components/modals/_components/modal-header/ModalHeader'
import { hexToRgba } from '#utils/hexToRgba'

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SButtonCancel = styled(Button)`
  color: ${hexToRgba('#fff', 0.3)};
  width: 116px;
  text-transform: uppercase;
`

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    gap: '1rem',
    marginTop: 20,
  },
  span: {
    fontSize: 14,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.color.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: 0,
  },
  subtitle: {
    display: 'block',
    width: '100%',
  },
  subTitleNote: {
    color: theme.palette.color.primary,
    fontSize: 10,
    fontFamily: theme.typography.h2.fontFamily,
    display: 'block',
  },
  formWrapper: {
    marginTop: '1rem',
    width: '100%',
  },
  fields: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    width: 250,
  },
  addExisting: {
    textAlign: 'right',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

export const InviteOffPlatformSplit = ({
  onClose,
  setIsFirstModalSide,
  addSplitOffPlatform,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    resolver: yupResolver(
      addSplitOffPlatformValidationSchema(vaultTranslation.t)
    ),
  })

  return (
    <>
      <ModalHeader>{vaultTranslation.t('inviteSplit')}</ModalHeader>
      <span className={classes.subtitle}>
        {vaultTranslation.t('sendAndEmailToInvite')}
      </span>
      <span className={classes.subTitleNote}>
        {vaultTranslation.t('noteASingleContributorCanHave')}
      </span>
      <form
        className={classes.formWrapper}
        onSubmit={handleSubmit(addSplitOffPlatform)}
      >
        <div className={classes.fields}>
          <BaseInput
            customWidth={250}
            label={vaultTranslation.t('name')}
            name="pendingName"
            inputRef={register}
            errorMessage={errors.pendingName?.message}
            isBolded
          />
          <div className={classes.select}>
            <RolesSelect
              control={control}
              errorMessage={errors.role?.message}
            />
          </div>
        </div>
        <SBaseInput
          errorMessage={errors.email?.message}
          label={vaultTranslation.t('email')}
          name="email"
          inputRef={register}
          isBolded
        />
        <div className={classes.grid}>
          <SButton type="submit">{t('send')}</SButton>
          <SButtonCancel variant={VARIANTS.DARK_GREY} onClick={onClose}>
            {t('cancel')}
          </SButtonCancel>
          <div className={classes.addExisting}>
            <span
              className={classes.span}
              onClick={() => setIsFirstModalSide(true)}
            >
              {vaultTranslation.t('addExisting')}
            </span>
          </div>
        </div>
      </form>
    </>
  )
}

InviteOffPlatformSplit.propTypes = {
  onClose: func.isRequired,
  setIsFirstModalSide: func.isRequired,
  addSplitOffPlatform: func.isRequired,
}
