import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { func, number, string } from 'prop-types'
import { AddSongMetadataWrapper } from '#pages/distro/wizard/steps/add-song-files/components/add-song-metadata-wrapper'
import { Header } from '#pages/distro/wizard/steps/add-song-files/components/header'
import { ButtonRow } from '#components/button-row'
import { AddFeaturedArtistModal } from '#pages/distro/wizard/steps/add-song-files/components/add-featured-artist-modal'
import { useWizard } from '#modules/forms/form-wizard/context'
import { HEADER_TITLES, FIELDS } from '#pages/distro/_utils/constants'
import { FeaturedArtistsList } from '#pages/distro/wizard/steps/add-song-files/components/featured-artists-list'
import styled from 'styled-components'

const SButtonRow = styled(ButtonRow)`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.darkGrey};
`

export const FeaturedArtistsStep = ({
  songId,
  metadataStep,
  setMetadataStep,
}) => {
  const [isOpened, setIsOpen] = useState(false)
  const { t } = useTranslation('distro')
  const { storedFormState, setStoredFormState } = useWizard()

  const selectedSong = storedFormState[FIELDS.SONG_FILES].find(
    item => item.id === songId || item.isMetadataFormOpened
  )
  const hasFeaturedArtists = selectedSong?.featuredArtists?.length > 0

  const handleNextClick = () => {
    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === selectedSong?.id
          ? { ...file, isMetadataFormOpened: false }
          : { ...file }
      ),
    })
  }

  return (
    <AddSongMetadataWrapper
      {...{ metadataStep, setMetadataStep }}
      customNextTitle={t('saveTrack')}
      onBackButtonClick={() => setMetadataStep(metadataStep - 1)}
      onNextButtonClick={handleNextClick}
    >
      <SButtonRow
        text={`+ ${t('addFeaturedArtist')}`}
        onClick={() => setIsOpen(true)}
      />
      {hasFeaturedArtists ? <Header titles={[HEADER_TITLES[0]]} /> : null}
      <FeaturedArtistsList
        artists={selectedSong?.featuredArtists}
        songId={selectedSong?.id}
      />
      <AddFeaturedArtistModal
        {...{ isOpened, setIsOpen }}
        songId={selectedSong?.id}
      />
    </AddSongMetadataWrapper>
  )
}

FeaturedArtistsStep.propTypes = {
  songId: string.isRequired,
  metadataStep: number.isRequired,
  setMetadataStep: func.isRequired,
}
