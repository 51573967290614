import React from 'react'
import { bool } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SLabel = styled.label`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_60};
  text-transform: uppercase;
  margin-bottom: 1rem;
`

const SNoFileLabel = styled(SLabel)`
  height: 3.75rem;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_95};
  margin-bottom: 1.5rem;
`

export const FilePreviewLabel = ({ file }) => {
  const { t } = useTranslation('nftWizard')

  return file ? (
    <SLabel>{t('steps.files.selectedFile')}:</SLabel>
  ) : (
    <SNoFileLabel>{t('steps.files.noFileSelected')}</SNoFileLabel>
  )
}

FilePreviewLabel.propTypes = {
  file: bool,
}
