import React from 'react'
import styled from 'styled-components'
import { string, node, oneOfType } from 'prop-types'

const SStepTitle = styled.h3`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: ${({ theme: { fontSize } }) => fontSize['4xl']};
  text-transform: uppercase;
  margin: 1.25rem 0 0;
`

export const StepTitle = ({ children, className }) => (
  <SStepTitle className={className}>{children}</SStepTitle>
)

StepTitle.propTypes = {
  children: oneOfType([node, string]),
  className: string,
}
