import React from 'react'
import styled, { css } from 'styled-components'
import { func, number } from 'prop-types'

const thumbMixin = css`
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  box-shadow: 0 0 2px 0 ${({ theme: { colors } }) => colors.darkGrey66};
  transition: background 0.3s ease-in-out;
`

const SRangeInput = styled.input`
  -webkit-appearance: none;
  height: 2px;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.darkGreyB3};
  border-radius: 5px;
  background-image: linear-gradient(#00a0ff, #00a0ff);
  background-repeat: no-repeat;
  background-size: ${({ value, min, max }) =>
    `${((value - min) * 100) / (max - min)}% 100%`};

  &::-webkit-slider-thumb {
    ${thumbMixin}
  }

  &::-moz-range-thumb {
    ${thumbMixin}
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-webkit-slider-thumb:hover {
    box-shadow: #00a0ff50 0 0 0 8px;
  }

  &::-webkit-slider-thumb:active {
    box-shadow: #00a0ff50 0 0 0 11px;
    transition: box-shadow 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      left 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      bottom 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const RangeInput = ({ value, setValue, min, max, step }) => {
  return (
    <SRangeInput
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      onChange={setValue}
    />
  )
}

RangeInput.propTypes = {
  value: number.isRequired,
  setValue: func.isRequired,
  min: number.isRequired,
  max: number.isRequired,
  step: number.isRequired,
}
