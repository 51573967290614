import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ParticleSphere } from '#pages/home/default-homepage/sections/default-welcome-section/components/particleSphere'
import { node, bool, string, number } from 'prop-types'
import mobile from 'is-mobile'

const SBackground = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`

const SVideoContainer = styled.div`
  opacity: ${({ opacity }) => opacity};
  height: 100%;
  width: 100%;
`

const SVideo = styled.video`
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  display: block;
  transform: translate(-50%, -50%);
`

const SParticles = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`

const SOuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 70rem;
  margin: 0 auto;
  flex-grow: 1;
  position: relative;
`

const SInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: center;
  z-index: 15;
`

export const VideoBackground = ({
  children,
  particles,
  src,
  opacity,
  showVideo,
}) => {
  const video = useRef()
  const isMobile = mobile({ tablet: true })

  useEffect(() => {
    if (!isMobile && video.current) video.current.play()
  }, [video])

  return (
    <SBackground>
      <SVideoContainer opacity={opacity}>
        {showVideo && (
          <SVideo ref={video} id="myVideo" autoPlay muted loop>
            <source src={src} type="video/mp4" />
          </SVideo>
        )}
      </SVideoContainer>
      <SParticles>
        {particles && <ParticleSphere />}
        <SOuterWrapper>
          <SInnerWrapper>{children}</SInnerWrapper>
        </SOuterWrapper>
      </SParticles>
    </SBackground>
  )
}

VideoBackground.propTypes = {
  children: node,
  particles: bool,
  src: string.isRequired,
  opacity: number,
  showVideo: bool,
}

VideoBackground.defaultProps = {
  opacity: 0.1,
  showVideo: true,
}
