import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { func, bool, arrayOf, shape } from 'prop-types'
import { Notification } from '#modules/notifications/components/notification'
import { Button } from '#components/button'
import { ZeroNotification } from '#modules/notifications/components/zero-notification'
import { BaseLoader } from '#components/loaders/base-loader'
import { LoadingImage } from '#components/loaders/loading-image'
import { isEven } from '#utils/isEven'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  notificationListWrapper: {
    width: '100%',
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '16px 0',
  },
  loadingImage: {
    display: 'flex',
    alignItems: 'center',
  },
  loading: {
    marginLeft: 8,
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const NotificationsList = ({
  loadedNotifications,
  isLoading,
  markAsRead,
  loadMore,
  areAllNotificationsDisplayed,
  updateNotificationType,
}) => {
  const { t } = useTranslation('notifications')
  const classes = useStyles()

  if (!isLoading && loadedNotifications.length === 0) {
    return <ZeroNotification />
  }

  if (loadedNotifications.length === 0) {
    return <BaseLoader isLoading={isLoading} />
  }

  return (
    <div className={classes.notificationListWrapper}>
      {loadedNotifications.map((notification, index) => {
        return (
          <Notification
            key={notification.id}
            isDark={isEven(index)}
            notification={notification}
            markAsRead={markAsRead}
            updateNotificationType={updateNotificationType}
          />
        )
      })}
      {areAllNotificationsDisplayed ? (
        <div className={classes.loadingWrapper}>
          {isLoading ? (
            <div className={classes.loadingImage}>
              <LoadingImage />
              <div className={classes.loading}>{t('loading')}</div>
            </div>
          ) : (
            <SButton onClick={loadMore}>{t('loadMore')}</SButton>
          )}
        </div>
      ) : null}
    </div>
  )
}

NotificationsList.propTypes = {
  loadMore: func.isRequired,
  updateNotificationType: func.isRequired,
  markAsRead: func.isRequired,
  isLoading: bool.isRequired,
  loadedNotifications: arrayOf(shape({})).isRequired,
  areAllNotificationsDisplayed: bool.isRequired,
}
