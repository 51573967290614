import React from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { BaseInput } from '#components/inputs/base-input'
import { CustomSelect } from '#components/inputs/custom-select'
import { DatePicker } from '#components/date-picker'
import { FIELD_TYPE } from '#constants/filedTypes'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.color.darkBlue09,
  },
  wrapper: {
    width: '100%',
    padding: '0 30px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
}))

const SBaseInput = styled(BaseInput)`
  margin-bottom: 15px;
`

const SCustomSelect = styled(CustomSelect)`
  width: 100%;
`

export const ReleaseDetailsFormField = ({
  field,
  selectOptions,
  control,
  register,
  errors,
  profileName,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { storedFormState } = useWizard()
  const inputPlaceholder = { [FIELDS.PRIMARY_ARTIST_NAME]: profileName }

  switch (field.type) {
    case FIELD_TYPE.INPUT:
      return (
        <div className={classes.wrapper}>
          <SBaseInput
            name={field.name}
            label={t(field.label)}
            inputRef={register}
            required={field.required}
            customWidth="100%"
            errorMessage={errors[field.name]?.message}
            placeholder={inputPlaceholder[field.name]}
            defaultValue={storedFormState[field.name]}
            isBolded
          />
        </div>
      )
    case FIELD_TYPE.SELECT:
      return (
        <div className={classes.wrapper}>
          <Controller
            key={field.name}
            name={field.name}
            control={control}
            defaultValue={storedFormState[field.name]}
            render={({ field: { onChange, value } }) => {
              return (
                <SCustomSelect
                  options={selectOptions[field.name]}
                  label={t(field.label)}
                  placeholder={t(field.placeholder)}
                  value={value}
                  required={field.required}
                  errorMessage={
                    errors[field.name]?.message ||
                    errors[field.name]?.value?.message
                  }
                  isBolded
                  onChange={onChange}
                />
              )
            }}
          />
        </div>
      )
    case FIELD_TYPE.CALENDAR:
      return (
        <div className={classes.wrapper}>
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label={t(field.label)}
                value={new Date(value)}
                maxDate={new Date()}
                errorMessage={errors[field.name]?.message}
                isBolded
                onChange={date => {
                  onChange(date || null)
                }}
              />
            )}
          />
        </div>
      )
    default:
      return null
  }
}
