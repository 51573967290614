import React, { useState, createContext, useContext } from 'react'
import { node } from 'prop-types'

export const ReloadContext = createContext({
  shouldReload: false,
  setReloading: () => {},
  reload: () => {},
})

export const useReload = () => {
  const context = useContext(ReloadContext)
  if (!context) {
    throw new Error('useReload must be used within ReloadContextProvider')
  }
  return context
}

export const ReloadContextProvider = ({ children }) => {
  const [shouldReload, setShouldReload] = useState(null)
  const setReloading = () => setShouldReload(true)
  const reload = action => {
    if (shouldReload) {
      action()
      setShouldReload(false)
    }
  }

  return (
    <ReloadContext.Provider
      value={{
        shouldReload,
        setReloading,
        reload,
      }}
    >
      {children}
    </ReloadContext.Provider>
  )
}

ReloadContextProvider.propTypes = {
  children: node.isRequired,
}
