import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { bool, string, object } from 'prop-types'
import AsyncSelect from 'react-select/async'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import { ErrorMessage } from '#components/error-message'
import { usePopperReactSelect } from '#hooks/usePopperReactSelect'

const SAutoComplete = styled.div`
  position: relative;
`

const SAutoCompleteLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  ${({
    theme: {
      tunego: { FONT },
    },
    bold,
  }) => bold && `font-family: ${FONT.FAMILY.bold}`};
`

const SAsterisk = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-size: 18px;
`

const SOption = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`

const searchIcon = '/assets/svg/searchIcon.svg'

const searchIconStyles = {
  height: 15,
  width: 15,
  top: 11,
  left: 10,
}

const searchIconStylesNft = {
  height: 21,
  width: 21,
  top: 16,
  left: 10,
}

const imageStyles = {
  height: 25,
  width: 25,
  top: 7,
  left: 9,
}

const imageStylesNft = {
  height: 30,
  width: 30,
  top: 10,
  left: 9,
}

const selectCustomStyles = (
  theme,
  { errorMessage, hasNftStyles, hideDropdownIndicator, disabled }
) => ({
  container: provided => ({
    ...provided,
    width: '100%',
    background: disabled
      ? theme.tunego.COLORS.neutral_95
      : theme.tunego.COLORS.neutral_90,
    fontSize: hasNftStyles ? '16px' : '14px',
  }),
  control: (_, state) => {
    const avatarUrl = state?.selectProps?.value?.avatarUrl
    const inputValue = state?.selectProps?.inputValue
    const imageUrl = avatarUrl || DEFAULT_AVATAR
    const url = state.hasValue ? imageUrl : searchIcon
    const iconStyles = hasNftStyles ? searchIconStylesNft : searchIconStyles
    const userImageStyles = hasNftStyles ? imageStylesNft : imageStyles
    const styles = state.hasValue ? userImageStyles : iconStyles
    const border = `1px solid ${
      disabled ? theme.tunego.COLORS.neutral_85 : theme.tunego.COLORS.neutral_70
    }`
    const paddingLeft = hasNftStyles ? 15 : 0

    return {
      height: hasNftStyles ? 50 : 40,
      display: 'flex',
      paddingLeft: state.hasValue && !inputValue ? 30 : paddingLeft,
      border: errorMessage
        ? `1px solid ${theme.tunego.COLORS.warning_60}`
        : border,
      ':before': {
        backgroundImage: disabled ? 'none' : `url("${url}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        content: '" "',
        display: inputValue ? 'none' : 'block',
        position: 'absolute',
        ...styles,
      },
    }
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  menu: () => ({
    background: theme.tunego.COLORS.neutral_90,
    border: `1px solid ${theme.tunego.COLORS.neutral_70}`,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  }),
  menuList: () => ({
    padding: 0,
    maxHeight: hasNftStyles ? 250 : 220,
    overflow: 'auto',
  }),
  input: () => ({
    color: theme.tunego.COLORS.neutral_0,
    paddingLeft: hasNftStyles ? 20 : 25,
    flexShrink: 0,
    flexGrow: 0,
  }),
  placeholder: () => ({
    opacity: 0.5,
    position: 'absolute',
    left: disabled ? 16 : hasNftStyles ? 45 : 35,
    color: disabled
      ? theme.tunego.COLORS.neutral_70
      : theme.tunego.COLORS.neutral_50,
  }),
  valueContainer: () => ({
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: 8,
  }),
  singleValue: () => ({
    color: disabled
      ? theme.tunego.COLORS.neutral_80
      : theme.tunego.COLORS.neutral_0,
    paddingLeft: 8,
    flexShrink: 1,
    flexGrow: 0,
    maxWidth: 'calc(100% - 2rem)',
  }),
  option: (provided, state) => {
    const { avatarUrl } = state.data
    const imageUrl = avatarUrl || DEFAULT_AVATAR
    return {
      ...provided,
      display: 'flex',
      alignItems: 'baseline',
      height: hasNftStyles ? 50 : 40,
      lineHeight: hasNftStyles ? '30px' : '20px',
      background: state.isFocused
        ? theme.tunego.COLORS.neutral_70
        : theme.tunego.COLORS.neutral_90,
      transition: 'background 0.3s linear',
      borderBottom: `1px solid ${theme.tunego.COLORS.neutral_70}`,
      position: 'relative',
      paddingLeft: hasNftStyles ? 45 : 35,
      ':before': {
        backgroundImage: `url("${imageUrl}")`,
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        content: '" "',
        position: 'absolute',
        height: hasNftStyles ? 30 : 20,
        width: hasNftStyles ? 30 : 20,
        top: 8,
        left: 8,
      },
    }
  },
  dropdownIndicator: (_, state) => ({
    display: hideDropdownIndicator ? 'none' : 'flex',
    alignItems: 'center',
    color: disabled
      ? theme.tunego.COLORS.neutral_80
      : theme.tunego.COLORS.neutral_0,
    marginRight: 10,
    '& svg': {
      transition: 'transform 0.3s linear',
      transform: `rotate(${
        state?.selectProps?.menuIsOpen ? '180deg' : '0deg'
      })`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

export const AutoComplete = ({
  label,
  errorMessage,
  hasNftStyles,
  hideDropdownIndicator,
  isRequired,
  className,
  disabled,
  menuPosition,
  id,
  ...props
}) => {
  const theme = useTheme()
  const [menuOpened, setMenuOpened] = useState(false)

  const baseStyles = selectCustomStyles(theme, {
    errorMessage,
    hasNftStyles,
    hideDropdownIndicator,
    disabled,
  })

  const { styles } = usePopperReactSelect({
    id,
    opened: menuOpened,
    baseStyles,
  })

  const formatOptionLabel = option => {
    return <SOption>{option.label}</SOption>
  }

  return (
    <SAutoComplete className={className}>
      <SAutoCompleteLabel bold={!hasNftStyles}>
        {label}
        {isRequired && <SAsterisk> *</SAsterisk>}
      </SAutoCompleteLabel>
      <AsyncSelect
        styles={styles}
        isDisabled={disabled}
        formatOptionLabel={formatOptionLabel}
        menuPosition={menuPosition}
        defaultOptions
        isClearable
        onMenuOpen={() => setMenuOpened(true)}
        onMenuClose={() => setMenuOpened(false)}
        {...props}
      />
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </SAutoComplete>
  )
}

AutoComplete.propTypes = {
  label: string.isRequired,
  errorMessage: string,
  hasNftStyles: bool,
  hideDropdownIndicator: bool,
  isRequired: bool,
  className: string,
  defaultValue: object,
  disabled: bool,
  menuPosition: string,
  id: string.isRequired,
}

AutoComplete.defaultProps = {
  errorMessage: '',
  hasNftStyles: false,
  hideDropdownIndicator: false,
  isRequired: false,
  defaultValue: object,
}
