import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { shape } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'
import { formatBytes } from '#pages/profile/utils/helpers'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 20,
    background: hexToRgba(theme.palette.color.primary, 0.27),
    borderRadius: '10px',
  },
  bold: {
    fontWeight: 700,
  },
  text: {
    fontSize: '14px',
    minWidth: 55,
    marginRight: 3,
  },
  valuesWrapper: {
    display: 'flex',
  },
  value: {
    fontSize: '14px',
    marginRight: 2,
  },
  lowOnSpace: {
    color: theme.palette.color.warning,
  },
  overQuota: {
    color: theme.palette.color.errorRed,
  },
  overQuotaText: {
    textTransform: 'uppercase',
  },
}))

export const SubscriptionUsage = ({ usage }) => {
  const { t } = useTranslation('profile')
  const classes = useStyles()

  const currentUsage = parseInt(usage['files-size']?.value, 10) || 0
  const limitUsage = parseInt(usage['files-size']?.limit, 10) || 0

  const remainingSpace = (100 - (currentUsage / limitUsage) * 100).toFixed(1)

  const overQuota = remainingSpace <= 0

  const displayUsage = overQuota
    ? t('overQuota')
    : `${remainingSpace}% ${t('remaining')}`

  const projectsCount = usage['project-count']?.value || 0
  const projectsLimit = usage['project-count']?.limit || 0

  const remainingProjects = 100 - (projectsCount / projectsLimit) * 100

  const getColorClass = value => {
    switch (true) {
      case value <= 0:
        return classes.overQuota
      case value <= 10:
        return classes.lowOnSpace
      default:
        return ''
    }
  }

  const quotasColor = getColorClass(remainingSpace)
  const projectsColor = getColorClass(remainingProjects)

  return (
    <div className={classes.wrapper}>
      <span className={classes.bold}>{t('currentUsage')}</span>
      <div className={`${classes.valuesWrapper}`}>
        <span className={classes.text}>{t('storage')}:</span>
        <span className={`${classes.bold} ${classes.value} ${quotasColor}`}>
          {formatBytes(currentUsage)}
        </span>
        <span className={`${classes.value} ${quotasColor}`}>/</span>
        <span className={`${classes.value} ${quotasColor}`}>
          {formatBytes(limitUsage)}
        </span>
      </div>
      <div className={`${classes.valuesWrapper}`}>
        <span className={classes.text} />
        <span
          className={`${classes.bold} ${classes.value} ${quotasColor} ${
            overQuota ? classes.overQuotaText : ''
          }`}
        >
          {displayUsage}
        </span>
      </div>
      <div className={classes.valuesWrapper}>
        <span className={classes.text}>{t('songs')}:</span>
        <span className={`${classes.bold} ${classes.value} ${projectsColor}`}>
          {projectsCount}
        </span>
        <span className={`${classes.value} ${projectsColor}`}>/</span>
        <span className={`${classes.value} ${projectsColor}`}>
          {projectsLimit}
        </span>
      </div>
    </div>
  )
}

SubscriptionUsage.propTypes = {
  usage: shape().isRequired,
}
