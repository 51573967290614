import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Modal, Button } from '@tunego/tunego-ui-components'
import { navigate } from '@reach/router'

const SModal = styled(Modal)`
  h2 {
    text-transform: none;
    margin: 0;
  }
`

const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SText = styled.p`
  margin: 0 0
    ${({
      theme: {
        tunego: { SPACING },
      },
    }) => SPACING['3xl']};
`

const SButton = styled(Button)`
  margin: 0 auto;
`

export const WalletModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation('sellCollectibles')

  return (
    <SModal
      isOpen={isOpen}
      title={t('walletPopupTitle')}
      onClose={() => setIsOpen(false)}
    >
      <SModalContent>
        <SText>{t('walletPopup')}</SText>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.CONNECT_WALLET)}>
          {t('connectSetup')}
        </SButton>
      </SModalContent>
    </SModal>
  )
}

WalletModal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
}
