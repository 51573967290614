import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { Typography } from '@material-ui/core'
import cn from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { bool, func, shape } from 'prop-types'
import { Notification } from '#modules/notifications/components/notification'
import {
  TOAST_DISPLAY_TIME,
  TOAST_FADE_TIME,
} from '#modules/notifications/utils/constants'
import { useElapsedTime } from 'use-elapsed-time'

const defaultStyle = {
  transition: `opacity ${TOAST_FADE_TIME}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '400px',
    minHeight: '100px',
    border: `1px solid ${theme.palette.color.primary}`,
    borderRadius: '12px',
    marginBottom: '18px',
    marginLeft: '10px',
    padding: '15px',
    background: theme.palette.background.default,
    color: theme.palette.color.default,
    boxShadow: `0px 0px 5px ${theme.palette.color.black}`,
    position: 'relative',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
  },
  headerText: {
    flexGrow: 1,
    fontSize: 14,
    color: theme.palette.color.default,
    fontFamily: theme.typography.h2.fontFamily,
  },
  headerIcon: {
    stroke: theme.palette.color.default,
    strokeWidth: 2,
    cursor: 'pointer',
  },
  expiryBar: {
    height: '5px',
    backgroundColor: theme.palette.color.primary,
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
}))

export const NotificationToastWrapper = ({
  notification,
  onExpired,
  isRunning,
  onRemoved,
  markAsRead,
  in: isIn,
  ...props
}) => {
  const { t } = useTranslation('notifications')
  const classes = useStyles()

  const [progress, setProgress] = useState(100)
  const [skipFade, setSkipFade] = useState(false)
  const [type, setType] = useState(notification?.type)

  useElapsedTime({
    isPlaying: isRunning,
    duration: (TOAST_DISPLAY_TIME - TOAST_FADE_TIME) / 1000,
    onUpdate: mTime => {
      setProgress(
        Math.max(
          100 - ((mTime * 1000) / (TOAST_DISPLAY_TIME - TOAST_FADE_TIME)) * 100,
          0
        )
      )
    },
    onComplete: () => {
      onExpired()
    },
  })

  useEffect(() => {
    setType(notification?.type)
  }, [notification])

  const closeMe = useCallback(() => {
    setSkipFade(true)
    markAsRead(notification.id)
    onExpired()
  }, [markAsRead, notification.id, onExpired])

  const onExited = () => {
    onRemoved()
    if (props.onExited) props.onExited()
  }

  useEffect(() => {
    if (notification.isRead && !skipFade) {
      closeMe()
    }
  }, [closeMe, notification, skipFade])

  return (
    <Transition
      {...props}
      in={skipFade ? false : isIn}
      timeout={skipFade ? 0 : TOAST_FADE_TIME}
      onExited={onExited}
    >
      {state => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          className={cn(classes.wrapper)}
        >
          <div className={classes.header}>
            <Typography component="h2" className={classes.headerText}>
              {t('new')}
            </Typography>
            <CloseIcon
              fontSize="small"
              className={classes.headerIcon}
              onClick={closeMe}
            />
          </div>
          <Notification
            notification={{ body: '', createdAt: '', ...notification, type }}
            updateNotificationType={(id, newType) => {
              setType(newType)
            }}
            markAsRead={markAsRead}
            isRead
            isDark
            isToast
          />
          <div
            className={classes.expiryBar}
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
    </Transition>
  )
}

NotificationToastWrapper.propTypes = {
  notification: shape({}).isRequired,
  isRunning: bool.isRequired,
  in: bool,
  onExpired: func,
  onRemoved: func,
  markAsRead: func,
  ...Transition.propTypes,
}

NotificationToastWrapper.defaultProps = {
  onExpired: () => {},
  onRemoved: () => {},
  markAsRead: () => {},
  in: false,
}
