import React, { useState } from 'react'
import { string } from 'prop-types'
import { Button, ButtonSize, ButtonTheme } from '@tunego/tunego-ui-components'
import { useTranslation } from 'react-i18next'
import { AddPackModal } from '#pages/vault/profile-content/components/add-pack-modal'

export const AddPack = ({ parentId }) => {
  const { t } = useTranslation('nftWizard')
  const [isPackModalOpen, setIsPackModalOpen] = useState(false)

  return (
    <>
      <Button
        buttonTheme={ButtonTheme.Secondary}
        buttonSize={ButtonSize.Sm}
        onClick={() => setIsPackModalOpen(true)}
      >
        {t('steps.files.newPack')}
      </Button>

      <AddPackModal
        parentId={parentId}
        isOpen={isPackModalOpen}
        setIsOpen={setIsPackModalOpen}
      />
    </>
  )
}

AddPack.propTypes = {
  parentId: string,
}
