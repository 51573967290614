import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class PromotionApi {
  static postUnpaidPromotion = data =>
    getApiClient().post(ENDPOINTS.PROMOTION, {
      ...data,
    })

  static postPromotionStripeIntent = (promotionId, body) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.PROMOTION_STRIPE_INTENT, { promotionId }),
      body
    )

  static getListenLink = promotionId => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.PROMOTION_LISTEN_LINK, {
        promotionId,
      })
    )
  }
}
