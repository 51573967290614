import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useWizard } from '#modules/forms/form-wizard/context'
import { AddSongFiles } from '#pages/distro/wizard/steps/add-song-files'
import { SelectProfile } from '#pages/distro/wizard/steps/select-profile'
import { SelectArtistId } from '#pages/distro/wizard/steps/select-artist-id'
import { ReleaseDetailss } from '#pages/distro/wizard/steps/release-details'
import { DeliveryAndReleaseDate } from '#pages/distro/wizard/steps/delivery-and-release-date'
import { ReviewAndSubmit } from '#pages/distro/wizard/steps/reaview-and-submit'
import { Checkout } from '#pages/distro/wizard/steps/checkout'
import { useQuery } from '#hooks/useQuery'
import { hexToRgba } from '#utils/hexToRgba'
import { usePastRelease } from '#hooks/swr/useDistro'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { resolvePastRelease } from '../../utils/helpers'
import { ReleaseIssues } from '#pages/distro/past-release-details/components/release-issues'
import { useSystem } from '#hooks/useSystem'

const useStyles = makeStyles(theme => {
  return {
    contentContainer: {
      width: '100%',
      paddingTop: 100,
    },
    contentBorder: {
      borderLeft: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
      borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    },
    paddingBot: {
      paddingBottom: 100,
    },
  }
})

export const StepsContainer = () => {
  const classes = useStyles()
  const { setStoredFormState, dispatch, setStep, step } = useWizard()

  const success = useQuery('success')

  const { settings } = useSystem()
  const stores = useMemo(
    () => settings?.distroStores.map(store => store.externalName),
    [settings]
  )
  const countries = settings?.distroCountries || []

  const [isPastReleaseResolved, setPastReleaseResolved] = useState(false)
  const distroId = useQuery('distroId')
  const { pastRelease, isLoading } = usePastRelease(distroId)

  useEffect(() => {
    setStoredFormState({
      musicStores: stores,
      musicStoresLength: stores?.length,
      deliveryRegions: countries,
      deliveryRegionsLength: countries?.length,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores])

  useEffect(() => {
    if (!isLoading && pastRelease && !isPastReleaseResolved) {
      resolvePastRelease(dispatch, pastRelease)
      setPastReleaseResolved(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, pastRelease])

  useEffect(() => {
    if (success) {
      setStep(2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const currentStep = () => {
    switch (step) {
      case 1:
        return <SelectProfile />
      case 2:
        return <SelectArtistId />
      case 3:
        return <AddSongFiles />
      case 4:
        return <ReleaseDetailss />
      case 5:
        return <DeliveryAndReleaseDate />
      case 6:
        return <ReviewAndSubmit />
      case 7:
        return <Checkout />
      default:
        return <SelectProfile />
    }
  }

  const steps = currentStep()
  const withBorders = step === 1
  const withPaddingBottom = step === 1 || step === 4 || step === 5 || step === 6
  const showIssues = step !== 1 && step !== 2 && step !== 3 && step !== 7

  return (
    <div
      className={`${classes.contentContainer} ${
        withBorders ? classes.contentBorder : ''
      } ${withPaddingBottom ? classes.paddingBot : ''}`}
    >
      {showIssues && <ReleaseIssues />}
      <CommonLoadingOverlay dialogOpen={isLoading && distroId} />
      {steps}
    </div>
  )
}
