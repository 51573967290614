import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '@tunego/tunego-ui-components'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const STitle = styled.div`
  text-align: right;
`

const SButton = styled(Button)`
  text-transform: uppercase;
  align-self: center;
`

const SInvite = styled.p`
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
`

export const AddOffPlatformFooter = ({ handleSwitchSides }) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  return (
    <SWrapper>
      <div>
        <STitle>{vaultTranslation.t('dontSeeWho')}</STitle>
        <SInvite onClick={handleSwitchSides}>
          {vaultTranslation.t('inviteThem')}
        </SInvite>
      </div>
      <SButton type="submit">{t('add')}</SButton>
    </SWrapper>
  )
}

AddOffPlatformFooter.propTypes = {
  handleSwitchSides: func.isRequired,
}
