import { NAVIGATION_PATHS } from '#routes/routes'

export const getBreadcrumbs = (t, profile) => [
  { link: NAVIGATION_PATHS.DISTRO, title: t('distro100') },
  {
    link: NAVIGATION_PATHS.DISTRO_PAST_RELEASES,
    title: t('pastReleases'),
  },
  {
    title: profile.name,
  },
]
