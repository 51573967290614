import React from 'react'
import { TuneGONFTToolsThemeProvider } from '@tunego/tunego-ui-components'

export const theme = {
  colors: {
    black: '#000000',
    default: '#ffffff',
    primary: '#00a0ff',
    primary25: '#255f95',
    primary60: '#004F80',
    primary00: '#002134',
    primary80: '#005080',
    primaryMask: '#00a0ff66',
    secondary: '#fafafa',
    secondary7B: '#7b8180',
    charcoal: '#1c1e1e',
    charcoal1C: '#1c1c1c',
    charcoal16: '#161616',
    gray: '#444444',
    darkGrey: '#2b2d2d',
    darkGreyB3: '#b3b3b3',
    darkGrey84: '#848484',
    darkGrey80: '#808080',
    darkGrey1A: '#1a1a1a',
    darkGrey33: '#333333',
    darkGrey22: '#222222',
    darkGrey66: '#666666',
    darkGrey12: '#121212',
    darkGrey0B: '#0B0B0B',
    darkGrey25: '#252525',
    darkGrey39: '#393A3B',
    error: '#B77207',
    warning: '#ff7c00',
    errorRed: '#FF0000',
    minorGrey: '#141414',
    white: '#dbdbdb',
    darkBlue: '#030917',
    darkBlue09: '#091220',
    darkBlue03: '#030a19',
    darkBlue39: '#091C39',
    lighterBlue: '#0f1826',
    azureRadiance: '#00A0FF',
    mineShaft: '#373737',
    eden: '#11405C',
    eden17: '#174E73',
    eden1D: '#1D5E8B',
    eden05: '#055f63',
    downriver: '#092946',
    downriver0F: '#0F385E',
    downriver15: '#154977',
    downriver16: '#163e64',
    downriver1D: '#1d4e7e',
    codGray: '#0D0D0D',
    purple: '#FF00EE',
    success: '#00FF00',
    yellow: '#ffff00',
  },
  typography: {
    avenirNextFont: 'AvenirNext-Regular, Avenir Next, sans-serif',
    fontFamily: '"AvenirNextLTPro-Regular", "Arial", sans-serif',
    bold: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
  },
  fontWeight: {
    thin: 100,
    extraLight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  breakpoints: {
    sm: '860px',
    md: '1280px',
    lg: '1440px',
  },
}

const Theme = ({ children }) => (
  <TuneGONFTToolsThemeProvider theme={theme}>
    {children}
  </TuneGONFTToolsThemeProvider>
)

export default Theme
