import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.color.minorGrey,
    height: 80,
    padding: '0 28px',
  },
  content: {
    fontSize: 12,
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    textAlign: 'left',

    '&:nth-child(1)': {
      width: 110,
      marginRight: 60,
    },
    '&:nth-child(2)': {
      width: 240,
      marginRight: 60,
    },
    '&:nth-child(3)': {
      width: 240,
      marginRight: 60,
    },
    '&:nth-child(4)': {
      width: 60,
      marginRight: 60,
      textAlign: 'center',
    },
    '&:nth-child(5)': {
      width: 100,
      textAlign: 'center',
    },
  },
}))

export const ListHeader = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <span className={classes.content}>{t('releaseDate')}</span>
      <span className={classes.content}>{t('artistProfile')}</span>
      <span className={classes.content}>{t('release')}</span>
      <span className={classes.content}>{t('tracks')}</span>
      <span className={classes.content}>{t('status')}</span>
    </div>
  )
}
