import { createContext, useContext } from 'react'

export const GlobalAudioPlayerContext = createContext({
  setFileInfo: () => {},
  fileInfo: null,
  offset: 0,
  setOffset: () => {},
})

export const useGlobalAudioPlayer = () => {
  const context = useContext(GlobalAudioPlayerContext)
  if (!context) {
    throw new Error(
      'useGlobalAudioPlayer must be used within GlobalAudioPlayerProvider'
    )
  }
  return context
}
