import React from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBlockchainAccount } from '#modules/blockchain'
import { BasicSection } from '#pages/connect-wallet/_components/basic-section'

export const ConnectDapperWallet = ({ onConnect, onError }) => {
  const { t } = useTranslation('connectWallet')
  const { connectWallet } = useBlockchainAccount()

  const onButtonClick = async () => {
    try {
      const user = await connectWallet()

      if (user?.loggedIn) onConnect()
      else onError()
    } catch {
      onError()
    }
  }

  return (
    <BasicSection
      btnText={t('connectWallet')}
      text={t('tunegoXDapper')}
      title={t('connectWallet')}
      subtitle={t('walletInfo')}
      onClick={onButtonClick}
    />
  )
}

ConnectDapperWallet.propTypes = {
  onConnect: func.isRequired,
  onError: func.isRequired,
}
