import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  declineButton: {
    color: theme.palette.color.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

const SButton = styled(Button)`
  margin-right: 15px;
  padding: 0 10px;
  text-transform: uppercase;
`

export const AcceptDeclineShare = ({ onAcceptShare, onDeclineShare }) => {
  const classes = useStyles()
  const { t } = useTranslation('notification')

  return (
    <div className={classes.wrapper}>
      <SButton onClick={onAcceptShare}>{t('accept')}</SButton>
      <span className={classes.declineButton} onClick={onDeclineShare}>
        {t('decline')}
      </span>
    </div>
  )
}

AcceptDeclineShare.propTypes = {
  onAcceptShare: func.isRequired,
  onDeclineShare: func.isRequired,
}
