export const FORM_FIELDS = {
  WALLET_ADDRESS: 'walletAddress',
  PROFILE: 'profile',
  PROFILE_ID: 'profileId',
  TITLE: 'title',
  ABOUT: 'about',
  DISPLAY_IMAGE: 'imageId',
  TYPE_OF_SALE: 'type',
  TOTAL_UNITS: 'totalUnits',
  STARTING_PRICE: 'startingPrice',
  PRICE: 'price',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PACK_TITLE: 'title',
  DESCRIPTION: 'about',
  OTHER_TITLE: 'otherTitle',
  OTHER_DESCRIPTION: 'otherDescription',
}

export const SPLIT_ACTIONS = {
  APPROVE: 'approve',
  REJECT: 'reject',
}

export const PROFILE_STATUS = {
  NO_DATA: 'noData',
  NO_PROPER_DATA: 'noProperData',
}
