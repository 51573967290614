import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { string } from 'prop-types'
import { Section } from '#components/section'
import { BolderTranslation } from '#components/bolder-translation'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => ({
  distributionWrapper: {
    fontSize: '1.35rem',
  },
  img: {
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
  bottomDescription: {
    display: 'flex',
    justifyContent: 'center',
  },
  bold: {
    fontFamily: 'AvenirNextLTPro-Bold',
  },
  underlinedDescription: {
    position: 'relative',
    fontFamily: 'AvenirNextLTPro-Bold',
  },
}))

export const DistributionSection = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  const renderSubHeader = () => {
    return (
      <div className={classes.distributionWrapper}>
        <Trans
          t={t}
          i18nKey="youGetWhatYouPayFor"
          components={Array.from({ length: 3 }, (_, i) => (
            <BolderTranslation key={i} />
          ))}
        />
        <div className={classes.bottomDescription}>
          <span className={classes.bold}>{t('collectTheMoney')} </span>
          <div className={classes.underlinedDescription}>
            <span>{t('thatsTheBottom')}</span>
            <img
              className={classes.img}
              src="/assets/svg/longRedLine.svg"
              width="100%"
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Section
      header={t('theWorldsLeading')}
      getStartedUrl={NAVIGATION_PATHS.DISTRO_GET_STARTED}
      iconUrl="/assets/svg/ic-distro.svg"
      subheaderComponent={renderSubHeader()}
    />
  )
}

DistributionSection.propTypes = {
  getStartedUrl: string.isRequired,
}
