import { useCallback, useLayoutEffect, useState } from 'react'

/**
 * onMouseEnter / onMouseLeave are not called when the contents or size of the triggering element changes.
 * This is also the case with useHover hook
 * This fixes the issue, if ResizeObserver is not available then a fallback for mouse clicks is used
 * @typedef {boolean} IsIn - IsIn state
 * @typedef {Function} UseRef - for use with ref=
 * @returns {[IsIn, UseRef]}
 */
export const useBetterHover = () => {
  const [isIn, setIsIn] = useState(false)
  const [node, setNode] = useState(null)

  const setRef = useCallback(newNode => {
    setNode(newNode)
  }, [])

  useLayoutEffect(() => {
    if (!node) return () => {}

    const mouseEnter = () => {
      if (!isIn) setIsIn(true)
    }
    const mouseLeave = () => {
      if (isIn) setIsIn(false)
    }

    if (!isIn) {
      node.addEventListener('mouseenter', mouseEnter)
      node.addEventListener('mousemove', mouseEnter)
      node.addEventListener('mouseleave', mouseLeave)
      return () => {
        node.removeEventListener('mouseenter', mouseEnter)
        node.removeEventListener('mousemove', mouseEnter)
        node.removeEventListener('mouseleave', mouseLeave)
      }
    }

    let x = 0
    let y = 0
    const check = () => {
      const top = node.offsetTop
      const left = node.offsetLeft
      const height = node.offsetHeight
      const width = node.offsetWidth

      if (y < top || y > top + height || x < left || x > left + width)
        setIsIn(false)
    }
    const mouseUpdate = e => {
      x = e.pageX
      y = e.pageY
      check()
    }
    const mouseUp = () => {
      check()
      setTimeout(check, 200)
    }

    const resizeObserver = ResizeObserver
      ? new ResizeObserver(() => check())
      : null
    if (resizeObserver) resizeObserver.observe(node)
    document.addEventListener('mousemove', mouseUpdate, false)
    document.addEventListener('mouseenter', mouseUpdate, false)
    document.addEventListener('mouseleave', mouseUpdate, false)
    document.addEventListener('mousedown', mouseUpdate, false)
    document.addEventListener('mouseup', mouseUp, false)
    node.addEventListener('mouseenter', mouseEnter)
    node.addEventListener('mouseleave', mouseLeave)
    return () => {
      if (resizeObserver) resizeObserver.disconnect()
      document.removeEventListener('mousemove', mouseUpdate, false)
      document.removeEventListener('mouseenter', mouseUpdate, false)
      document.removeEventListener('mouseleave', mouseUpdate, false)
      document.removeEventListener('mousedown', mouseUpdate, false)
      document.removeEventListener('mouseup', mouseUp, false)
      node.removeEventListener('mouseenter', mouseEnter)
      node.removeEventListener('mouseleave', mouseLeave)
    }
  }, [isIn, node])

  return [isIn, setRef]
}
