import React from 'react'
import styled, { css } from 'styled-components'
import { func, bool, number, string, node } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { VARIANTS } from '#components/button/constants'
import { Modal } from '#components/modal'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'

const SModal = styled(Modal)`
  padding: ${({ isWarning }) => (isWarning ? '50px 60px' : '40px')};
  max-width: ${({ isUpgrade }) => (isUpgrade ? '700px' : '640px')};
  min-height: ${({ minHeight }) => minHeight};
  align-items: ${({ isWarning }) => (isWarning ? 'center' : 'flex-start')};
  border-color: ${({ isWarning }) =>
    isWarning
      ? ({
          theme: {
            tunego: { COLORS },
          },
        }) => COLORS.warning_50
      : ({
          theme: {
            tunego: { COLORS },
          },
        }) => COLORS.primary_50};
`

const SButton = styled(Button)`
  margin-bottom: 0;

  ${({
    isWarning,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    isWarning
      ? css`
          width: unset;
          background-color: ${COLORS.warning_50};

          &:hover {
            background-color: ${COLORS.warning_60};
          }
        `
      : css`
          width: 415px;
        `};
`
const SCancelOutlinedButton = styled(Button)`
  margin-right: 0;
  margin-left: 1.25rem;
  border-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_70};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_70};
  transition: 0.3s ease-in-out;
  cursor: pointer;
`

const SWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isUpgrade }) => (isUpgrade ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
`

export const ConfirmationModal = ({
  isOpened,
  onClose,
  onConfirm,
  children,
  confirmationText,
  cancelText,
  isWarning,
  isUpgrade,
  isOwner,
  minHeight,
  isCancelButtonOutlined,
}) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (isOwner) {
      onConfirm()
    }
    onClose()
  }

  const btnText = isOwner ? confirmationText || t('confirm') : t('close')

  const cancelButton = isCancelButtonOutlined ? (
    <SCancelOutlinedButton
      variant={VARIANTS.NEW_BLUE_SPECIAL}
      buttonTheme={ButtonTheme.Secondary}
      onClick={onClose}
    >
      {cancelText || t('cancel')}
    </SCancelOutlinedButton>
  ) : (
    <Button buttonTheme={ButtonTheme.Tertiary} onClick={onClose}>
      {cancelText || t('cancel')}
    </Button>
  )

  return (
    <SModal
      isOpen={isOpened}
      setIsOpen={onClose}
      isWarning={isWarning}
      isUpgrade={isUpgrade}
      minHeight={minHeight}
    >
      {children}
      <SWrapper>
        <SButton
          isWarning={isWarning}
          isUpgrade={isUpgrade}
          onClick={handleClick}
          {...(isWarning && { variant: VARIANTS.WARNING })}
        >
          {btnText}
        </SButton>
        {isOwner && cancelButton}
      </SWrapper>
    </SModal>
  )
}

ConfirmationModal.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  children: node.isRequired,
  isWarning: bool,
  confirmationText: string,
  cancelText: string,
  isUpgrade: bool,
  isOwner: bool,
  minHeight: string || number,
}

ConfirmationModal.defaultProps = {
  confirmationText: '',
  cancelText: '',
  isWarning: false,
  isUpgrade: false,
  isOwner: false,
  minHeight: 250,
}
