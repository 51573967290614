import React, { useEffect, useState, useCallback } from 'react'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { UsersApi } from '#api/requests/users'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { NAVIGATION_PATHS } from '#routes/routes'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { OnboardingHeader } from '#pages/onboarding/wizard/components/onboarding-header'
import { WelcomeToTunego } from '#pages/onboarding/wizard/steps/welcome-to-tunego'
import { AddProfileImageStepContainer } from '#pages/onboarding/wizard/steps/add-profile-image/containers/add-profile-image-step-container'
import { ONBOARDING_STEP } from '#pages/onboarding/wizard/utils/constants'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'
import _ from 'lodash'

export const Onboarding = () => {
  const { t } = useTranslation('onboarding')
  const validationTranslation = useTranslation('validation')
  const [step, setStep] = useState(ONBOARDING_STEP.WELCOME)
  const { error, setError, clearError } = useError()
  const { actions, anyLoading, errors } = useWithAsyncAction({
    manageOnboard: UsersApi.manageOnboard,
    manageUploadAvatar: UsersApi.manageUploadAvatar,
  })
  const { mutate: mutateUser } = useUser()

  const handleSetError = useCallback(
    err => {
      setError(
        validationTranslation.t('requestOnlyMessage', {
          message: err.message,
        })
      )
    },
    [t]
  )

  useEffect(() => {
    if (errors.manageOnboard) {
      handleSetError(errors.manageOnboard)
    }
    if (errors.manageUploadAvatar) {
      handleSetError(errors.manageUploadAvatar)
    }
  }, [errors, handleSetError])

  const nextStep = () => {
    if (step === ONBOARDING_STEP.WELCOME) {
      setStep(ONBOARDING_STEP.ADD_PROFILE_IMAGE)
    } else {
      navigate(NAVIGATION_PATHS.CONNECT_WALLET)
    }
  }

  const createAccount = async data => {
    await actions.manageOnboard(_.omit(data, 'location'))
    mutateUser()
    nextStep()
  }

  const addProfileImage = async file => {
    await actions.manageUploadAvatar(file)
    mutateUser()
  }

  return (
    <>
      {(anyLoading || error) && (
        <CommonLoadingOverlay
          message={t('savingProfileInfo')}
          dialogOpen={anyLoading}
          error={error}
          onErrorBackdropClick={clearError}
        />
      )}
      <OnboardingHeader />
      {step === ONBOARDING_STEP.WELCOME && (
        <WelcomeToTunego createAccount={createAccount} />
      )}
      {step === ONBOARDING_STEP.ADD_PROFILE_IMAGE && (
        <AddProfileImageStepContainer
          addProfileImage={addProfileImage}
          nextStep={nextStep}
        />
      )}
    </>
  )
}
