import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { HomeNavigation } from '../_components/home-navigation'
import { DefaultWelcomeSection } from '../default-homepage/sections/default-welcome-section'
import { Footer } from '#components/footer'
import { SnoopWelcomeSection } from '#pages/home/snoop-drop-homepage/sections/snoop-welcome-section'
import { useLogin } from '#hooks/useLogin'

export const LoginRequired = () => {
  const { snoopDropActive } = useFlags()
  const entryLink = useLogin(true)

  return (
    <>
      <HomeNavigation onButtonClick={entryLink} />
      {snoopDropActive ? <SnoopWelcomeSection /> : <DefaultWelcomeSection />}
      <Footer />
    </>
  )
}
