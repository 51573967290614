import React from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'

const SSectionTitle = styled.h2`
  font-size: 22px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin: 0 0 30px 0;
`

export const SectionTitle = ({ className, children }) => {
  return <SSectionTitle className={className}>{children}</SSectionTitle>
}

SectionTitle.propTypes = {
  children: node.isRequired,
  className: string,
}

SectionTitle.defaultProps = {
  className: '',
}
