import { REJECT_TRANSACTION_MESSAGE } from '#pages/connect-wallet/utils/constants'

const isRejectMessageIncluded = (rejectMessage, errorMessage) =>
  !!errorMessage?.toLowerCase().includes(rejectMessage.toLowerCase())

export const isTransactionRejectedByAuthorizer = error => {
  const rejectTransactionMessages = Object.values(REJECT_TRANSACTION_MESSAGE)
  const errorMessage = error instanceof Error ? error.message : error

  return rejectTransactionMessages.some(rejectMessage =>
    isRejectMessageIncluded(rejectMessage, errorMessage)
  )
}
