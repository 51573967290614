import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const SBackdropMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 600px;
  min-height: 150px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.default};
  padding: 20px;
  text-align: center;
  background-color: transparent;

  ${({ theme: { colors }, transparent }) =>
    !transparent &&
    `
    border: 1px solid ${colors.primary};
    background-color: ${colors.darkGrey};
  `}
`

const SMessage = styled.p`
  font-weight: 400;
  white-space: pre-wrap;
`

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SHeader = styled.div`
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize['5xl']};
  text-transform: uppercase;
`

const modalStyles = {
  overlay: {
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
    opacity: 1,
    backdropFilter: 'blur(4px)',
  },
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
}

export const BackdropMessage = ({
  message,
  transparent,
  header,
  onBackdropClick,
}) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState(false)

  useEffect(() => {
    setIsOpened(!!message && message.length)
  }, [message])

  const handleClose = () => {
    setIsOpened(false)
    onBackdropClick()
  }

  return (
    <ReactModal
      isOpen={isOpened}
      style={modalStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
    >
      <SBackdropMessage transparent={transparent}>
        {header && <SHeader>{header}</SHeader>}
        {Array.isArray(message) ? (
          message.map((errorMessage, i) => (
            <SMessage key={generateUniqueKey(errorMessage, i)}>
              {errorMessage}
            </SMessage>
          ))
        ) : (
          <SMessage>{message}</SMessage>
        )}
        <SButton onClick={handleClose}>{t('ok')}</SButton>
      </SBackdropMessage>
    </ReactModal>
  )
}

BackdropMessage.propTypes = {
  message: string.isRequired,
  onBackdropClick: func.isRequired,
  header: string,
  transparent: bool,
}

BackdropMessage.defaultProps = {
  transparent: false,
}
