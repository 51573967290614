import React from 'react'
import { node } from 'prop-types'
import { SellCollectiblesLandingPage } from './landing-page'
import { CreateCollectible } from './create-collectible'
import { MyCollectibles } from './my-collectibles'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export {
  CreateCollectible,
  MyCollectibles,
  Routes,
  SellCollectiblesLandingPage,
}
