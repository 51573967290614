import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { shape, arrayOf, objectOf, string } from 'prop-types'
import { ScatterPlotChart } from '#pages/promotion/report/components/scatter-plot-chart'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  distributionOfRatingsGraphContainer: {
    width: '100%',
    minHeight: '350px',
    minWidth: '300px',
    margin: '0 auto',
  },
})

export const MajorLabelTrackRating = ({ data, thisTrack }) => {
  const classes = useStyles()
  return (
    <ReviewRowElement
      refName="majorLabelTrackRatingEl"
      title="majorLabelTrackRating"
      description="wantToKnowHowYourSong"
    >
      <div className={classes.distributionOfRatingsGraphContainer}>
        <ScatterPlotChart
          data={data}
          thisTrack={thisTrack}
          xDomain={[0, 6]}
          yDomain={[0, 9]}
          pointRadius={5}
          margin={40}
          showLeggend
        />
      </div>
    </ReviewRowElement>
  )
}

MajorLabelTrackRating.propTypes = {
  data: arrayOf(objectOf(string)).isRequired,
  thisTrack: shape({}).isRequired,
}
