import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

export const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 450,
    margin: 'auto',
    textAlign: 'center',
  },
}))

const SButton = styled(Button)`
  border: none;
  background-color: ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.default};
  padding: 0 80px;
  margin-top: 20px;
  text-transform: uppercase;
`

export const EmptyVaultInfo = () => {
  const { t } = useTranslation('vault')
  const classes = useStyles()

  const handleNavigate = () => {
    navigate(NAVIGATION_PATHS.VAULT_PROFILES)
  }

  return (
    <div className={classes.wrapper}>
      <span>{t('youDoNotHaveProfile')}</span>
      <SButton onClick={handleNavigate}>{t('goToVault')}</SButton>
    </div>
  )
}
