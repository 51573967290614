import React from 'react'
import styled from 'styled-components'

const SSubHeader = styled.h2`
  text-transform: uppercase;
  margin: 0 0 1.25rem;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['2xl']};
`

export const StepSubHeader = ({ title }) => {
  return <SSubHeader>{title}</SSubHeader>
}
