import React from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Modal } from '#components/modal'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  message: {
    textAlign: 'center',
  },
}))

const SModal = styled(Modal)`
  justify-content: center;
`

export const NotSupportedModal = ({ isOpened, onClose, suffix }) => {
  const classes = useStyles()
  const { t } = useTranslation('validation')

  return (
    <SModal isOpen={isOpened} setIsOpen={onClose} shouldCloseOnOverlayClick>
      <span className={classes.message}>
        {t('yourBrowserDoesNot', {
          type: suffix.toUpperCase(),
        })}
      </span>
    </SModal>
  )
}

NotSupportedModal.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  suffix: string.isRequired,
}
