import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundImage: 'url("/assets/images/vault-main-bg.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 'calc(100vh - 60px)',
    position: 'relative',
  },
  contentWrapper: {
    maxWidth: 600,
    [theme.breakpoints.up('md')]: {
      marginLeft: 100,
      paddingTop: 150,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 150,
      paddingTop: 200,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: 200,
      paddingTop: 300,
    },
  },
  header: {
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    fontSize: '40px',
    whiteSpace: 'pre-line',
  },
  blueHeader: {
    fontSize: 40,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.color.primary,
    textTransform: 'uppercase',
    paddingLeft: '0.5rem',
  },
  benefits: {
    padding: '1rem 0',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '1rem',
    maxWidth: '25rem',
  },
}))

const SButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
  text-transform: uppercase;
`

export const TopSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          {t('welcomeToYourVault')}
          <span className={classes.blueHeader}>{t('letsCreate')}</span>
        </div>
        <div className={classes.benefits}>
          <div>{t('secureAndShare')}</div>
          <div>{t('protectAndTrack')}</div>
          <div>{t('collectYourMusic')}</div>
        </div>
        <div className={classes.buttonGroup}>
          <SButton onClick={() => navigate(NAVIGATION_PATHS.CREATE_PROFILE)}>
            {t('createNewProfile')}
          </SButton>
          <SButton onClick={() => navigate(NAVIGATION_PATHS.VAULT_PROFILES)}>
            {t('viewMyProfiles')}
          </SButton>
        </div>
      </div>
    </div>
  )
}
