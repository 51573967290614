import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { func, shape, string } from 'prop-types'
import { TrackDetailsStep } from '#pages/distro/wizard/steps/add-song-files/components/track-details-step'
import { ContributorsStep } from '#pages/distro/wizard/steps/add-song-files/components/contributors-step'
import { FeaturedArtistsStep } from '#pages/distro/wizard/steps/add-song-files/components/featured-artists-step'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { FIELDS } from '#pages/distro/_utils/constants'
import { useWizard } from '#modules/forms/form-wizard/context'
import { ACCESS_ROLES } from '#constants/accessRoles'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: 425,
  },
}))

export const SongFileMetadataContainer = ({
  selectedFileId,
  persistedData,
  setPersistedData,
}) => {
  const classes = useStyles()
  const [metadataStep, setMetadataStep] = useState(1)
  const [isOwner, setIsOwner] = useState(false)

  const { setStoredFormState, storedFormState } = useWizard()

  const { project: profile } = useArtistStructure(
    storedFormState[FIELDS.PARENT_ID]
  )

  useEffect(() => {
    const isProfileOwner = profile?.accessRoles.includes(ACCESS_ROLES.OWNER)

    setIsOwner(isProfileOwner)
    setStoredFormState({ isProfileOwner })
  }, [profile])

  const selectStep = () => {
    switch (metadataStep) {
      case 1: {
        return (
          <TrackDetailsStep
            songId={selectedFileId}
            profileName={profile?.name}
            {...{
              metadataStep,
              setMetadataStep,
              persistedData,
              setPersistedData,
            }}
          />
        )
      }
      case 2: {
        return (
          <ContributorsStep
            songId={selectedFileId}
            isOwner={isOwner}
            {...{ metadataStep, setMetadataStep }}
          />
        )
      }
      case 3: {
        return (
          <FeaturedArtistsStep
            songId={selectedFileId}
            {...{ metadataStep, setMetadataStep }}
          />
        )
      }
      default:
        return (
          <TrackDetailsStep
            songId={selectedFileId}
            profileName={profile?.name}
            {...{
              metadataStep,
              setMetadataStep,
              persistedData,
              setPersistedData,
            }}
          />
        )
    }
  }
  return <div className={classes.container}>{selectStep()}</div>
}

SongFileMetadataContainer.propTypes = {
  selectedFileId: string.isRequired,
  persistedData: shape({}).isRequired,
  setPersistedData: func.isRequired,
}
