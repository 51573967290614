import { NAVIGATION_PATHS } from '#routes/routes'

export const getBreadcrumbs = (profile, releaseName, t) => [
  { link: NAVIGATION_PATHS.DISTRO, title: t('distro100') },
  {
    link: NAVIGATION_PATHS.DISTRO_PAST_RELEASES,
    title: t('pastReleases'),
  },
  {
    link: NAVIGATION_PATHS.DISTRO_PAST_RELEASES,
    state: {
      profile: {
        id: profile?.id,
        name: profile?.name,
      },
    },
    title: profile?.name,
  },
  {
    title: releaseName,
  },
]
