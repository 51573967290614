import React from 'react'
import { MenuItem } from '@material-ui/core'
import { string, func, arrayOf, shape } from 'prop-types'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

export const SongMenu = ({
  handleClose,
  id,
  songOptions,
  setTypeOfCurrentAction,
  setIdOfActiveActionItem,
  setStructureCategory,
}) => {
  const onSongMenuItemClickHandler = type => {
    setStructureCategory(STRUCTURE_TYPE.SONG)
    setIdOfActiveActionItem(id)
    setTypeOfCurrentAction(type)
    handleClose()
  }

  return (
    <div>
      {songOptions.map(({ type, title }) => (
        <MenuItem
          key={`${type}project`}
          onClick={() => onSongMenuItemClickHandler(type)}
        >
          {title}
        </MenuItem>
      ))}
    </div>
  )
}

SongMenu.propTypes = {
  handleClose: func,
  setTypeOfCurrentAction: func.isRequired,
  setIdOfActiveActionItem: func.isRequired,
  id: string.isRequired,
  songOptions: arrayOf(
    shape({
      type: string,
      title: string,
    })
  ).isRequired,
  setStructureCategory: func.isRequired,
}

SongMenu.defaultProps = {
  handleClose: () => {},
}
