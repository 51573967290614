import React from 'react'
import styled from 'styled-components'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  checkoutWrapper: {
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  uppercaseParagraph: {
    textTransform: 'uppercase',
    color: theme.palette.color.primary,
    fontWeight: 'bold',
  },
  success: {
    fontSize: 21,
  },
  submittedOrder: {
    fontSize: 18,
    marginBottom: 15,
  },
  congratulations: {
    fontWeight: 'bold',
    fontSize: 14,
    display: 'inline',
  },
  congratulationsMessage: {
    fontSize: 14,
    marginBottom: 20,
    display: 'inline',
  },
}))

const SButton = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.default};
  margin-top: 30px;
  width: 100%;
  text-transform: uppercase;
`

export const CheckoutSuccess = ({
  congratulationsMessage,
  onClick,
  buttonText,
  congratulationsTitle,
}) => {
  const { t } = useTranslation()
  const promotionTranslation = useTranslation('promotion')
  const classes = useStyles()

  return (
    <div className={classes.checkoutWrapper}>
      <span className={cn(classes.uppercaseParagraph, classes.success)}>
        {t('success')}
      </span>
      <span className={cn(classes.uppercaseParagraph, classes.submittedOrder)}>
        {congratulationsTitle}
      </span>
      <span className={classes.congratulations}>
        {promotionTranslation.t('congratulations')}
      </span>
      <span className={classes.congratulationsMessage}>
        {congratulationsMessage}
      </span>
      <SButton onClick={onClick}>{buttonText}</SButton>
    </div>
  )
}

CheckoutSuccess.propTypes = {
  congratulationsMessage: string.isRequired,
  buttonText: string.isRequired,
  onClick: func.isRequired,
  congratulationsTitle: string,
}

CheckoutSuccess.defaultProps = {
  congratulationsTitle: 'yourOrderHasBeen',
}
