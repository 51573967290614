import React from 'react'
import { bool, func, node, string } from 'prop-types'
import styled, { css } from 'styled-components'

const SCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`

const SCheckbox = styled.input`
  appearance: none;
  width: 1rem;
  height: 1rem;
  box-sizing: content-box;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  border: ${({
    theme: {
      tunego: { COLORS },
    },
    hasError,
  }) => `1px solid ${hasError ? COLORS.warning_60 : COLORS.neutral_70}`};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
  position: relative;
  flex-shrink: 0;

  &:checked {
    &::before {
      content: '';
      width: 0.3rem;
      height: 0.6rem;
      border-bottom: ${({
        theme: {
          tunego: { COLORS },
        },
      }) => `2px solid ${COLORS.neutral_0}`};
      border-right: ${({
        theme: {
          tunego: { COLORS },
        },
      }) => `2px solid ${COLORS.neutral_0}`};
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`

export const SLabel = styled.label`
  margin-left: 0.625rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  cursor: pointer;

  ${({
    isLabelBolded,
    theme: {
      tunego: { FONT },
    },
  }) =>
    isLabelBolded &&
    css`
      font-family: ${FONT.FAMILY.bold};
    `};
`

export const Checkbox = ({
  register,
  name,
  hasError,
  disabled,
  label,
  onChange,
  checked,
  customLabel,
  isLabelBolded,
  required,
  className,
}) => {
  return (
    <SCheckboxWrapper className={className}>
      <SCheckbox
        type="checkbox"
        id={name}
        hasError={hasError}
        disabled={disabled}
        defaultChecked={checked}
        required={required}
        {...(onChange && { onChange })}
        {...(register && register(name))}
      />
      {label && (
        <SLabel {...{ isLabelBolded }} htmlFor={name}>
          {label}
        </SLabel>
      )}
      {customLabel}
    </SCheckboxWrapper>
  )
}

Checkbox.propTypes = {
  name: string.isRequired,
  checked: bool.isRequired,
  onChange: func,
  label: string,
  disabled: bool,
  hasError: bool,
  isLabelBolded: bool,
  customLabel: node,
  register: func,
  required: bool,
  className: string,
}

Checkbox.defaultProps = {
  onChange: null,
  label: '',
  disabled: false,
  hasError: false,
  isLabelBolded: false,
  customLabel: null,
  register: null,
}
