import React from 'react'
import styled from 'styled-components'
import { func, node, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { VARIANTS } from '#components/button/constants'
import { Button } from '#components/button'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 60,
    padding: '8px 5px 8px 50px',
  },
  fileName: {
    maxWidth: 550,
    marginRight: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

const SButton = styled(Button)`
  border: none;
  opacity: 0.33;
  margin-left: ${({ marginLeft }) => marginLeft};
`

export const SongFileProcessing = ({
  name,
  onDelete,
  marginLeft,
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <span className={classes.fileName}>{name}</span>
      {children}
      <SButton
        variant={VARIANTS.TRANSPARENT}
        marginLeft={marginLeft}
        onClick={() => onDelete(name)}
      >
        <img
          src="/assets/svg/ic-trash.svg"
          width="20px"
          height="20px"
          alt={`Delete song ${name}`}
        />
      </SButton>
    </div>
  )
}

SongFileProcessing.propTypes = {
  name: string.isRequired,
  onDelete: func.isRequired,
  marginLeft: string.isRequired,
  children: node.isRequired,
}
