import {
  yesNoNO,
  yesNoYES,
} from '#pages/vault/song/tabs/metadata/utils/constants/wasPreviouslyReleased'
import { capitalizeWords } from '#utils/capitalizeWords'
import { applyMask } from '#utils/applyMask'
import { ISRC_PATTERN } from '#pages/vault/song/utils/validation'

export const msToTime = duration => {
  if (duration === null) return ''

  let seconds = parseInt((duration / 1000) % 60, 10)
  let minutes = parseInt((duration / (1000 * 60)) % 60, 10)
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10)

  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${hours}:${minutes}:${seconds}`
}

export const prepareDataForMetadataForm = data => {
  const {
    explicitContent,
    performanceLanguage,
    primaryArtist,
    primaryArtistName,
    titleLanguage,
    trackVersion,
    primaryGenre,
    secondaryGenre,
    audioFormat,
    trackDuration,
    previousReleaseDate,
    isrc,
    collectible,
    parentsPath,
  } = data

  return {
    ...data,
    trackDuration: msToTime(trackDuration),
    primaryArtistName: primaryArtistName || primaryArtist?.name,
    isPreviouslyReleased: previousReleaseDate ? yesNoYES : yesNoNO,
    ...(audioFormat && {
      audioFormat: {
        value: audioFormat,
        label: audioFormat,
      },
    }),
    ...(explicitContent && {
      explicitContent: {
        value: explicitContent,
        label: capitalizeWords(explicitContent?.replace(/-/g, ' ')),
      },
    }),
    ...(performanceLanguage && {
      performanceLanguage: {
        value: performanceLanguage,
        label: capitalizeWords(performanceLanguage),
      },
    }),
    ...(primaryGenre?.id && {
      primaryGenreId: {
        value: primaryGenre?.id,
        label: capitalizeWords(primaryGenre?.name),
      },
    }),
    ...(secondaryGenre?.id && {
      secondaryGenreId: {
        value: secondaryGenre?.id,
        label: capitalizeWords(secondaryGenre?.name),
      },
    }),
    ...(titleLanguage && {
      titleLanguage: {
        value: titleLanguage,
        label: capitalizeWords(titleLanguage),
      },
    }),
    ...(trackVersion && {
      trackVersion: {
        value: trackVersion,
        label: capitalizeWords(trackVersion?.replace(/-/g, ' ')),
      },
    }),
    ...(isrc && {
      isrc: applyMask(isrc, ISRC_PATTERN),
    }),
    collectible: {
      ...collectible,
      artist: parentsPath[0]?.name || primaryArtist?.name,
    },
  }
}

const getValueOrRemoveEmptyObject = data => {
  if (typeof data === 'object' && data !== null) {
    return Object.keys(data).length ? data.value : null
  }
  return data
}

export const reduceFalsyToNullValues = values =>
  Object.keys(values).reduce((prev, current) => {
    return {
      ...prev,
      [current]: getValueOrRemoveEmptyObject(values[current]) || null,
    }
  }, {})

export const timeToMs = time => {
  const [hours, minutes, seconds] = time.split(':').map(x => parseInt(x, 10))

  return (hours * 60 * 60 + minutes * 60 + seconds) * 1000
}

const isValueDirty = (value, defaultValue) => {
  if (defaultValue === null && value === '') return false

  return value !== defaultValue
}

export const isFormDirty = ({
  dirtyFields,
  values,
  formattedFields,
  getter = val => val,
}) => {
  return Object.keys(getter(dirtyFields) || {}).some(key => {
    return isValueDirty(getter(values)[key], getter(formattedFields)[key])
  })
}
