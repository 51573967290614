import React from 'react'
import { UnauthenticatedPageWrapper } from '#modules/unauthenticated-page-wrapper'
import { TermsOfUseText } from '#pages/terms-of-use/components/terms-of-use-text'

export const TermsOfUse = () => {
  return (
    <UnauthenticatedPageWrapper>
      <TermsOfUseText />
    </UnauthenticatedPageWrapper>
  )
}
