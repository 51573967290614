import React from 'react'
import { makeStyles } from '@material-ui/core'
import { node } from 'prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.color.darkBlue09,
    border: `1px solid ${theme.palette.color.darkGrey}`,
    width: '100%',
    padding: 30,
    marginTop: 25,
  },
}))

export const ContentWrapper = ({ children, wrap = true }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.container}
      style={{ flexWrap: wrap ? 'wrap' : 'no-wrap' }}
    >
      {children}
    </div>
  )
}

ContentWrapper.propTypes = {
  children: node.isRequired,
}
