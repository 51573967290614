import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Section } from '#components/section'
import { HOW_IT_WORKS_DATA } from '#pages/mastering/_utils/constants'
import { SubHeader } from '#pages/_components/subheader-component'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.color.default,
    color: theme.palette.background.default,
  },
}))

const SSection = styled(Section)`
  background-image: none;
`

export const HowItWorksSection = () => {
  const { t } = useTranslation('mastering')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <SSection
        header={t('howItWorks')}
        getStartedUrl={
          IS_DEV_QA
            ? NAVIGATION_PATHS.NEW_MASTERING
            : `${NAVIGATION_PATHS.MASTERING}/coming-soon`
        }
        subheaderComponent={
          <SubHeader iconSectionData={HOW_IT_WORKS_DATA} isMastering />
        }
      />
    </div>
  )
}
