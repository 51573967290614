import { buildUrl } from '#utils/buildUrl'
import { ENDPOINTS } from '#api/endpoints'
import { SWR_EVENT_ACTION_RELOAD, useEventSWR } from '#hooks/swr/useEventSWR'
import { EVENTS } from '#api/event'

export const usePayments = (query = '', config) => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.PAYMENT_CREATED,
      EVENTS.PAYMENT_UPDATED,
      EVENTS.PAYMENT_INVOICE_UPDATED,
      EVENTS.PAYMENT_CHARGE_UPDATED,
      // TODO subscription event
    ],
    eventAction: SWR_EVENT_ACTION_RELOAD,
    swrKey: `${ENDPOINTS.PAYMENTS}${query}`,
    swrConfig: config,
  })

  return {
    payments: data?.data ?? [],
    count: data?.count,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const usePayment = (paymentId, config) => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: paymentId
      ? [
          `${EVENTS.PAYMENT_UPDATED}.${paymentId}`,
          `${EVENTS.PAYMENT_CREATED}.${paymentId}`,
        ]
      : null,
    swrKey: paymentId
      ? buildUrl(ENDPOINTS.PAYMENTS_PAYMENT, { paymentId })
      : null,
    swrConfig: config,
  })

  return {
    payment: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const usePaymentMethods = () => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.PAYMENT_METHOD_ADDED,
      EVENTS.PAYMENT_METHOD_REMOVED,
      EVENTS.PAYMENT_METHOD_DEFAULT_CHANGED,
    ],
    // TODO optimize event handling
    eventAction: SWR_EVENT_ACTION_RELOAD,
    swrKey: ENDPOINTS.PAYMENT_METHODS,
  })
  return {
    paymentMethods: data?.paymentMethods ?? [],
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const usePaymentsPrices = () => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [EVENTS.SUBSCRIPTION_UPGRADED],
    eventAction: SWR_EVENT_ACTION_RELOAD,
    swrKey: ENDPOINTS.PAYMENTS_PRICES,
  })

  return {
    prices: data?.prices || {},
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
