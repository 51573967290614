import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  laptop: {
    maxWidth: '60%',
    height: 'auto',
    margin: '0 auto',
  },
  sectionWrapper: {
    marginBottom: '10rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeader: {
    whiteSpace: 'preline',
    padding: '8px 0',
    margin: '8px 0 56px 0',
    fontFamily: 'AvenirNextLTPro-Bold',
    textAlign: 'center',
    lineHeight: 'initial',
    fontSize: 36,
    width: 1200,
  },
}))

export const SecureSection = ({ src }) => {
  const { t } = useTranslation('home')
  const classes = useStyles()

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.contentWrapper}>
        <p className={classes.sectionHeader}>
          {t('captureYourCreativitySecureYourMusic')}
        </p>
        <img src={src} alt="" className={classes.laptop} />
      </div>
    </div>
  )
}
