import { useEffect, useState } from 'react'
import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { FilesApi } from '#api/requests/files'
import GoldenRetriever from '@uppy/golden-retriever'
import {
  CHUNK_SIZE,
  INITIAL_STATE_EVENT,
  UPPY_ID,
} from '#modules/upload-manager/constants'
import { useUser } from '#hooks/useUser'

export const useUppyUpload = (onRestore, onComplete) => {
  const [uppy, setUppy] = useState(null)
  const [uploadingFilesInProgress, setUploadingFilesInProgress] = useState([])
  const { user } = useUser()

  const createMultipartUpload = async (file, signal) => {
    signal?.throwIfAborted()

    const { type, fileId } = file.meta

    const { data } = await FilesApi.uploadMultiPartFile(fileId, type)

    return data
  }
  const signPart = async (file, { uploadId, partNumber }) => {
    const { fileId } = file.meta
    const { data } = await FilesApi.getSignedPart(fileId, {
      uploadId,
      partNumber,
    })

    return data
  }

  const listParts = async (file, { uploadId }) => {
    const { data } = await FilesApi.getSignedPartsList(
      file.meta.fileId,
      uploadId
    )

    onRestore()

    return data
  }

  const completeMultipartUpload = async (file, { uploadId }) => {
    const { data } = await FilesApi.completeMultiPartUpload(
      file.meta.fileId,
      uploadId
    )

    await FilesApi.uploadFileHealthCheck(file.meta.fileId)

    return data
  }

  const initializeUppy = () => {
    const uppy = new Uppy()
      .use(AwsS3, {
        id: UPPY_ID,
        shouldUseMultipart: true,
        getChunkSize: file => {
          const minChunkSize = Math.ceil(file.size / 10000)

          return Math.max(CHUNK_SIZE, minChunkSize)
        },
        createMultipartUpload,
        signPart,
        listParts,
        completeMultipartUpload,
        abortMultipartUpload: (file, { uploadId }) => {
          return FilesApi.abortMultiPartUpload(file.meta.fileId, uploadId)
        },
      })
      .use(GoldenRetriever, { serviceWorker: true })

    setUppy(uppy)
  }

  useEffect(() => {
    initializeUppy()
  }, [])

  const handleFileAdded = file => {
    if (uploadingFilesInProgress.some(({ id }) => id === file.id)) return

    setUploadingFilesInProgress(prev => [
      ...prev,
      {
        id: file.id,
        name: file.name,
        progress: 0,
      },
    ])
  }

  const handleProgress = (file, progress) => {
    const percentage = (progress.bytesUploaded / progress.bytesTotal) * 100

    setUploadingFilesInProgress(prevFiles => {
      const isAlreadyUploading = prevFiles.some(
        prevFile => prevFile.id === file.id
      )
      const updatedFiles = isAlreadyUploading
        ? prevFiles.map(prevFile => {
            if (prevFile.id === file.id) {
              return {
                ...prevFile,
                progress: percentage,
              }
            }
            return prevFile
          })
        : [...prevFiles, { id: file.id, name: file.name, progress: percentage }]

      return updatedFiles.filter(el => el.progress < 100)
    })
  }

  const handleFileRemoved = file => {
    setUploadingFilesInProgress(prev =>
      prev.filter(prevFile => prevFile.id !== file.id)
    )
  }

  const cancelAllUploads = async () => {
    try {
      const files = uppy.getFiles()

      await Promise.all(
        files.map(async file => {
          const { meta, s3Multipart } = file

          await FilesApi.abortMultiPartUpload(meta.fileId, s3Multipart.uploadId)
        })
      )
      uppy.cancelAll()
    } catch (err) {
      // TODO: remove after testing
      // eslint-disable-next-line no-console
      console.log('Failed to cancel uploads', err)
    }
  }

  const cancelUpload = file => {
    uppy.removeFile(file.id)

    const uploadsInProgress = uppy
      .getFiles()
      .some(uploadedFile => uploadedFile.id !== file.id)

    if (!uploadsInProgress) {
      onComplete()
    }
  }

  const handleComplete = ({ successful, failed }) => {
    if ([...successful, ...failed].length > 0) onComplete()
  }
  const clearUppy = () => {
    uppy.off('file-added', handleFileAdded)
    uppy.off('file-removed', handleFileRemoved)
    uppy.off('upload-progress', handleProgress)
    uppy.off('complete', handleComplete)
  }

  const handleServiceWorkerMessage = e => {
    const { type, files } = e.data
    const { recoveredState } = uppy.getState()

    if (type !== INITIAL_STATE_EVENT || !recoveredState) {
      return
    }

    const isEmpty = Object.keys(files).length === 0

    if (isEmpty) {
      // Note: canceling all uploads if blobs aren't available
      cancelAllUploads()
    } else {
      uppy.emit('restore-confirmed')
    }

    navigator.serviceWorker.removeEventListener(
      'message',
      handleServiceWorkerMessage
    )
  }

  useEffect(() => {
    if (uppy) {
      if (user) {
        uppy.on('file-added', handleFileAdded)
        uppy.on('file-removed', handleFileRemoved)
        uppy.on('upload-progress', handleProgress)
        uppy.on('complete', handleComplete)

        navigator.serviceWorker.addEventListener(
          'message',
          handleServiceWorkerMessage
        )

        navigator.serviceWorker.controller.postMessage({
          type: INITIAL_STATE_EVENT,
          name: UPPY_ID,
        })
      } else {
        clearUppy()
      }
    }

    return () => {
      if (uppy) clearUppy()
    }
  }, [uppy, user])

  return {
    uploadingFilesInProgress,
    cancelAllUploads,
    cancelUpload,
    uppy,
  }
}
