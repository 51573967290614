import {
  BILLING_PERIOD,
  SUBSCRIPTION_PLANS,
} from '#pages/profile/utils/constants'
import * as dayjs from 'dayjs'
import React from 'react'
import { formatProratedAdjustment } from '#pages/profile/utils/helpers'

export const getPaymentSuccessOrderData = ({
  classes,
  t,
  tierLevel,
  displayedPrice,
  billingPeriod,
  isSwitchedTrue,
  currentSubscription,
  accountRenewalDate,
}) => {
  const planTierRow = {
    rowTitle: '',
    rowValue: (
      <div className={classes.rowValueWrapper}>
        {t('tunego')}
        <div className={classes.label}>
          <div className={classes.bold}>
            {t(SUBSCRIPTION_PLANS[tierLevel]).toUpperCase()}
          </div>
        </div>
        <div className={classes.label}>{t('subscription')}</div>
      </div>
    ),
  }

  const priceRow = {
    rowTitle: '',
    rowValue: (
      <div className={classes.rowValueWrapper}>
        <div className={classes.bold}>{displayedPrice.toLowerCase()}</div>
        <span className={classes.label}>
          {t('billed', {
            period: t(`billingPeriod.${billingPeriod}`),
          })}
        </span>
      </div>
    ),
  }

  const renewalDateRow = {
    rowTitle: t('accountRenewalDate'),
    rowValue: (
      <div className={classes.bold}>
        {isSwitchedTrue
          ? dayjs(currentSubscription?.currentCycleEnd)
              .add(1, BILLING_PERIOD.YEAR)
              .format('MM/DD/YYYY')
          : accountRenewalDate}
      </div>
    ),
  }

  return [planTierRow, priceRow, renewalDateRow]
}

export const getPaymentSuccessProratedData = ({
  isMonthly,
  classes,
  t,
  tierLevel,
  displayedTotalPrice,
  adjustmentPrice,
}) => {
  const proratedTotalRow = isMonthly
    ? {}
    : {
        rowTitle: (
          <div className={classes.rowValueWrapper}>
            {t('tunego')}
            <div className={`${classes.bold} ${classes.label}`}>
              {t(SUBSCRIPTION_PLANS[tierLevel]).toUpperCase()}
            </div>
            <span className={classes.label}>{t('subscriptionPlanTotal')}</span>
          </div>
        ),
        rowValue: displayedTotalPrice.toLowerCase(),
      }

  const prorateAdjustmentRow = {
    rowTitle: t('proratedAdjustment'),
    rowValue: formatProratedAdjustment(adjustmentPrice),
  }

  return [proratedTotalRow, prorateAdjustmentRow]
}
