import React, { useState } from 'react'
import styled from 'styled-components'
import { func, arrayOf, shape, bool, string } from 'prop-types'
import { capitalize } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { RowWrapper } from '#pages/vault/song/tabs/splits/components/row-wrapper'
import { ColumnInRow } from '#pages/vault/song/tabs/splits/components/column-in-row'
import {
  getMenuOptions,
  HEADERS,
  SPLIT_STATUSES,
} from '#pages/vault/song/tabs/splits/utils/constants'
import { ROLES } from '#constants/roles'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { DeleteModal } from '#pages/_components/delete-modal'
import { NAVIGATION_PATHS } from '#routes/routes'

const SButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`

const SButton = styled(Button)`
  padding: 0 15px;
  text-transform: uppercase;
`

export const SplitRows = ({
  tempSplitIncomes,
  determineMenuAction,
  isAcceptanceView,
  userMeId,
  acceptSplit,
  denySplit,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [splitId, setSplitId] = useState(null)

  const mapRoleValuesToLabels = roleArray =>
    roleArray?.map(
      roleItem => ROLES.find(item => item.value === roleItem)?.label
    )

  const checkIfMySplitsAreEqualToZero = tempSplitIncomes
    ?.filter(split => split.userId === userMeId)
    .map(split => {
      return split.pendingMasterIncome === 0 && split.pendingPublishing === 0
    })

  const handleAcceptSplit = id => {
    if (checkIfMySplitsAreEqualToZero[0]) {
      setSplitId(id)
      setIsDeleteModalOpen(true)
    } else {
      acceptSplit(id)
    }
  }

  return (
    <>
      {!!tempSplitIncomes?.length && (
        <>
          {tempSplitIncomes.map(
            (
              {
                masterIncome,
                masterIncomeToDisplay,
                pendingName,
                publishing,
                publishingToDisplay,
                user,
                role,
                status,
                id,
                pendingMasterIncome,
                pendingPublishing,
              },
              index
            ) => {
              const isDeletable =
                masterIncome === 0 &&
                publishing === 0 &&
                pendingMasterIncome === 0 &&
                pendingPublishing === 0

              return (
                <RowWrapper key={id} isLighter={index % 2 === 1}>
                  <ColumnInRow
                    width={HEADERS[0].width}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    display="block"
                    maxWidth={225}
                    overflow="hidden"
                  >
                    {user.firstName
                      ? `${user.firstName} ${user.lastName}`
                      : pendingName}
                  </ColumnInRow>
                  <ColumnInRow justifyContent="center" width={HEADERS[1].width}>
                    {`${masterIncomeToDisplay}%`}
                  </ColumnInRow>

                  <ColumnInRow justifyContent="center" width={HEADERS[2].width}>
                    {`${publishingToDisplay}%`}
                  </ColumnInRow>
                  <ColumnInRow justifyContent="center" width={HEADERS[3].width}>
                    {mapRoleValuesToLabels(role)
                      .toString()
                      .replaceAll(',', ', ')}
                  </ColumnInRow>
                  <ColumnInRow
                    width={HEADERS[4].width}
                    justifyContent="space-between"
                  >
                    {isAcceptanceView &&
                      user.id === userMeId &&
                      status === SPLIT_STATUSES.PENDING && (
                        <SButtons>
                          <SButton
                            variant={VARIANTS.CANCEL}
                            onClick={() => denySplit(id)}
                          >
                            {t('deny')}
                          </SButton>
                          <SButton onClick={() => handleAcceptSplit(id)}>
                            {t('approve')}
                          </SButton>
                        </SButtons>
                      )}
                    {isAcceptanceView &&
                      status !== SPLIT_STATUSES.PENDING &&
                      capitalize(status)}
                    {isAcceptanceView &&
                      user.id !== userMeId &&
                      status === SPLIT_STATUSES.PENDING &&
                      capitalize(status)}
                    {!isAcceptanceView && (
                      <>
                        {capitalize(status)}
                        <EllipsisMenu
                          options={getMenuOptions(
                            status,
                            isDeletable,
                            vaultTranslation.t
                          )}
                          onChoose={actionType => {
                            determineMenuAction(actionType, id)
                          }}
                        />
                      </>
                    )}
                  </ColumnInRow>
                </RowWrapper>
              )
            }
          )}
        </>
      )}
      <DeleteModal
        handleDelete={async () => {
          await acceptSplit(splitId)
          navigate(NAVIGATION_PATHS.VAULT_PROFILES)
        }}
        deleteBtnText={vaultTranslation.t('yesConfirm')}
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        firstSpan={vaultTranslation.t('areYouSure')}
        thirdSpan={vaultTranslation.t('whenApproved')}
      />
    </>
  )
}

SplitRows.propTypes = {
  determineMenuAction: func.isRequired,
  tempSplitIncomes: arrayOf(shape()).isRequired,
  acceptSplit: func.isRequired,
  denySplit: func.isRequired,
  isAcceptanceView: bool,
  userMeId: string,
}

SplitRows.defaultProps = {
  isAcceptanceView: false,
  userMeId: '',
}
