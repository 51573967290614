import { useTranslation } from 'react-i18next'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { bottomIconType } from '../../utils/constants'

const useStyles = makeStyles(theme => ({
  span: {
    color: theme.palette.color.primary,
    fontWeight: 'bold',
  },
  container: {
    width: 240,
  },
  cloudContent: {
    fontSize: '1.125rem',
    padding: '0 0.5rem',
  },
  infinityContent: {
    fontSize: '1.125rem',
    padding: '0 0.5rem',
    paddingTop: '1.75rem',
  },
}))

export const BottomIconTile = ({ iconType }) => {
  const { t } = useTranslation()
  const connectTranslation = useTranslation('connect')
  const classes = useStyles()

  const bottomIconTiles = {
    [bottomIconType.CLOUD_LOCK]: (
      <div className={classes.container}>
        <img
          src="/assets/svg/ic-cloud-share.svg"
          width="100px"
          alt="Cloud share icon"
        />
        <div className={classes.cloudContent}>
          {connectTranslation.t('securely')}{' '}
          <span className={classes.span}>
            {connectTranslation.t('collaborate')}
          </span>{' '}
          {connectTranslation.t('withTheCreative')}
        </div>
      </div>
    ),

    [bottomIconType.INFINITY]: (
      <div className={classes.container}>
        <img
          src="/assets/svg/ic-infinite.svg"
          width="100px"
          alt="Unlimited file sharing"
        />
        <div className={classes.infinityContent}>
          <span className={classes.span}>
            {connectTranslation.t('unlimited')}
          </span>{' '}
          {connectTranslation.t('fileSharing')}
          <span className={classes.span}>{t('no')}</span>{' '}
          {connectTranslation.t('fileSize')}
        </div>
      </div>
    ),
  }

  return bottomIconTiles[iconType]
}
