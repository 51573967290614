export const audioFormat = [
  {
    label: 'WAV',
    value: 'WAV',
  },
  {
    label: 'FLAC',
    value: 'FLAC',
  },
]
