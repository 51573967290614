import React from 'react'
import { func, number, bool } from 'prop-types'
import { Badge } from '#components/badge'
import styled, { css } from 'styled-components'

const SBadge = styled(Badge)`
  left: 0.625rem;
  right: unset;
`

const openedStyle = css`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 50%;
`

const allReadStyle = css`
  margin: 0 5px;
`

const SWrapper = styled.div`
  cursor: pointer;
  border-radius: 50%;
  margin: 0 1.5rem 0 0.7rem;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isAllRead }) => isAllRead && allReadStyle};
  ${({ isOpened }) => isOpened && openedStyle};
`

export const NotificationIcon = ({ badgeContent, setIsOpened, isOpened }) => {
  return (
    <SWrapper
      isOpened={isOpened}
      allRead={badgeContent === 0}
      onClick={() => setIsOpened(true)}
    >
      <SBadge text={badgeContent}>
        <img
          src={
            isOpened
              ? '/assets/svg/ic-notification.svg'
              : '/assets/svg/ic-notification-blue.svg'
          }
          width="20px"
          height="20px"
          alt="Notification icon"
        />
      </SBadge>
    </SWrapper>
  )
}

NotificationIcon.propTypes = {
  badgeContent: number,
  setIsOpened: func.isRequired,
  isOpened: bool,
}

NotificationIcon.defaultProps = {
  badgeContent: null,
  isOpened: false,
}
