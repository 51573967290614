import React from 'react'
import styled from 'styled-components'
import { func, object, bool, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { useImageCropper } from '#components/image-cropper/hooks/useImageCropper'
import { ImageCropSettings } from '#components/image-cropper/components/image-crop-settings'

const SModal = styled(Modal)`
  width: 600px;
  position: relative;
`

const SImageCropper = styled.div`
  display: flex;
  gap: 50px;
  position: relative;
`

const SCropperTitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const STitle = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SCropperFooter = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ImageCropper = ({
  image,
  onClose,
  isOpen,
  cropShape,
  updateImage,
  setPreview,
  acceptedFile,
}) => {
  const { t } = useTranslation()

  const { onCropConfirm, clearCropSettings, ...props } = useImageCropper({
    image,
    acceptedFile,
    setPreview,
    updateImage,
  })

  const onCloseHandler = () => {
    onClose()
    clearCropSettings()
  }

  const handleClick = async () => {
    await onCropConfirm()

    onCloseHandler()
  }

  return (
    <SImageCropper>
      <SModal isOpen={isOpen} setIsOpen={onClose}>
        <SCropperTitleWrapper>
          <STitle>{t('editPhoto')}</STitle>
          <CloseIcon onClick={onCloseHandler} />
        </SCropperTitleWrapper>
        <ImageCropSettings image={image} cropShape={cropShape} {...props} />
        <SCropperFooter>
          <Button variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={onCloseHandler}>
            {t('cancel')}
          </Button>
          <Button onClick={handleClick}> {t('save')} </Button>
        </SCropperFooter>
      </SModal>
    </SImageCropper>
  )
}

ImageCropper.propTypes = {
  image: string.isRequired,
  onClose: func.isRequired,
  isOpen: bool.isRequired,
  cropShape: string.isRequired,
  updateImage: func.isRequired,
  setPreview: func.isRequired,
  acceptedFile: object.isRequired,
}
