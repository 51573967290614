import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PageWrapper } from '#modules/page-wrapper'
import { hexToRgba } from '#utils/hexToRgba'
import { GetPromotedSection } from './sections/get-promoted-section'
import { SyncLicensingSection } from './sections/sync-licensing-section'
import { PromoteSection } from './sections/promote-section'
import { SyncHighlightSection } from './sections/sync-highlight-section'
import { PlaylistCuratorsSection } from './sections/playlist-curators-section'
import { BlogsAndPublicationsSection } from './sections/blogs-and-publications-section'
import { IndieRadioSection } from './sections/indie-radio-section'
import { HowToSection } from './sections/how-to-section'

const useStyles = makeStyles(theme => ({
  sectionsBg: {
    background: `url("/assets/images/sectionsBgMap.png")`,
    width: '100%',
    backgroundPosition: 'left top',
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat',
  },
  sectionsBgOuter: {
    background: `linear-gradient(to bottom, ${
      theme.palette.color.black
    } 10%, ${hexToRgba(theme.palette.color.primary, 0.7)} 85%, ${
      theme.palette.color.black
    } 100%)`,
    width: '100%',
  },
}))

export const Home = () => {
  const classes = useStyles()

  return (
    <PageWrapper isFullWidth showNavBar>
      <div>
        <GetPromotedSection />
        <div className={classes.sectionsBgOuter}>
          <div className={classes.sectionsBg}>
            <PromoteSection />
            <SyncLicensingSection />
            <SyncHighlightSection />
            <PlaylistCuratorsSection />
            <BlogsAndPublicationsSection />
            <IndieRadioSection />
          </div>
        </div>
        <HowToSection />
      </div>
    </PageWrapper>
  )
}
