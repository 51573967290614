import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { ReleaseDetailsForm } from '#pages/distro/wizard/steps/release-details/components/release-details-form'
import { ArtworkAndConfirmations } from '#pages/distro/wizard/steps/release-details/containers/artwork-and-confirmation-container'
import { useArtistGenres, useArtistStructure } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { ContentWrapper } from '#pages/distro/wizard/steps/delivery-and-release-date/components/content-wrapper'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { Text } from '#components/text'

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 22,
    marginBottom: 25,
    width: '100%',
  },
}))

export const ReleaseDetailsComponent = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { storedFormState } = useWizard()
  const { genres, isLoading } = useArtistGenres()
  const { project: profile } = useArtistStructure(
    storedFormState[FIELDS.PARENT_ID]
  )

  const genresOptions =
    genres && genres.map(({ name, id }) => ({ label: name, value: id }))

  return (
    <>
      <ContentWrapper>
        <Text className={classes.header}>{t('releaseDetails')}</Text>
        {isLoading ? (
          <BaseLoader isLoading={isLoading} />
        ) : (
          <ReleaseDetailsForm
            genres={genresOptions}
            profileName={profile?.name}
          />
        )}
      </ContentWrapper>
      <ArtworkAndConfirmations />
    </>
  )
}
