import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { makeStyles } from '@material-ui/core'
import { PastReleaseContainer } from './containers/past-release-container'
import { usePastRelease } from '#hooks/swr/useDistro'
import { Breadcrumbs } from '#components/breadcrumbs'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { OrderSummary } from './components/order-summary'
import { DISTRO_STATUS } from '#pages/distro/_utils/constants'
import { DISTRO_TYPES } from '#constants/distroTypes'
import { paymentCardInfo } from '#pages/profile/utils/helpers'
import { PageWrapper } from '#modules/page-wrapper'
import { getBreadcrumbs } from '#pages/distro/past-release-details/utils/helpers'
import { WizardContainer } from '#modules/forms/form-wizard/containers/wizard-container'

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 1200,
    margin: '0 auto',
  },
}))

const SBreadcrumbs = styled(Breadcrumbs)`
  width: 1200px;
`

export const PastReleasesDetails = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const params = useParams()

  const { pastRelease, isLoading } = usePastRelease(params.id)
  const { profile, releaseName, songs, subscription } = pastRelease || {}
  const showPastReleaseContainer = !isLoading && pastRelease
  const showOrderSummary =
    pastRelease?.status === DISTRO_STATUS.COMPLETE || subscription !== null
  const breadcrumbs = getBreadcrumbs(profile, releaseName, t)
  const isSingleDistro = songs?.length === 1
  const releaseType = isSingleDistro ? DISTRO_TYPES.SINGLE : DISTRO_TYPES.ALBUM

  const paymentMethod = paymentCardInfo(
    subscription?.currentCycleEnd,
    subscription?.card.brand,
    subscription?.card.last4Digits
  )

  return (
    <PageWrapper showNavBar withoutBorder isFullWidth>
      <SBreadcrumbs {...{ breadcrumbs }} />
      <div className={classes.wrapper}>
        <WizardContainer>
          <CommonLoadingOverlay dialogOpen={isLoading} />
          {showPastReleaseContainer && (
            <PastReleaseContainer pastRelease={pastRelease} />
          )}
          {showOrderSummary && (
            <OrderSummary
              orderNumber={subscription?.externalSubscriptionId}
              artistName={profile?.name}
              releaseName={releaseName}
              releaseType={releaseType}
              paymentMethod={paymentMethod}
            />
          )}
        </WizardContainer>
      </div>
    </PageWrapper>
  )
}
