import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '@auth0/auth0-react'
import mobile from 'is-mobile'
import { Link } from '@material-ui/core'
import { PageWrapper } from '#modules/page-wrapper'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 60,
    textAlign: 'center',
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily,
    margin: 0,
    width: '100%',
    textAlign: 'center',
  },
  mobileVerified: {
    width: '100%',
    textAlign: 'center',
    padding: '0 8px',
  },
  appStoreButton: {
    width: 150,
    height: 50,
    margin: '0 8px',
  },
  appButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 80,
  },
  loginViaDesktop: {
    margin: '16px 0',
    '& img': {
      width: '250px',
    },
  },
  emailWrapper: {
    margin: '16px 0',
  },
  email: {
    color: theme.palette.color.darkGrey66,
    fontSize: 16,
    fontFamily: theme.typography.bold,
    padding: 20,
  },

  issueWrapper: {
    width: '100%',
    whiteSpace: 'pre-line',
    backgroundColor: theme.palette.color.darkGrey12,
    padding: 20,
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },
}))

export const ConfirmEmail = () => {
  const classes = useStyles()
  const { t } = useTranslation('onboarding')
  const isMobile = mobile({ tablet: true })
  const { loginWithRedirect, logout } = useAuth0()

  const clearCookies = () => {
    localStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  useEffect(() => {
    if (!isMobile) {
      loginWithRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper showGenericHeader>
      <div className={classes.wrapper}>
        <h1 className={classes.title}>{t('success')}</h1>
        {isMobile && (
          <div>
            <div className={classes.loginViaDesktop}>
              <img src="/assets/images/login-via-desktop.png" alt="" />
            </div>
            <p className={classes.mobileVerified}>
              {t('congratulationsYourAccountIsNowVerified')}
            </p>
            <div className={classes.issueWrapper}>
              <span className={classes.bold}>{t('Questions?')}</span>{' '}
              <Link
                href="mailTo:support@tunego.com"
                underline="always"
                color="primary"
                variant="button"
              >
                {t('Contact Support')}
              </Link>{' '}
              <br />
              <Link
                underline="always"
                color="primary"
                variant="button"
                onClick={clearCookies}
              >
                {t('Logout')}
              </Link>{' '}
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  )
}
