import { useCallback, useState } from 'react'

export const useError = (initialState = '') => {
  const [error, setError] = useState(initialState)

  const setErrorHandler = useCallback(err => setError(err), [])
  const setResponseError = useCallback(
    err => setError(err.response?.data?.message),
    []
  )
  const setErrorMessage = useCallback(err => setError(err.message), [])

  const clearError = () => setError('')

  return {
    error,
    setError: setErrorHandler,
    setResponseError,
    setErrorMessage,
    clearError,
  }
}
