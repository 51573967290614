/* eslint-disable no-underscore-dangle */
import React from 'react'
import { func, arrayOf, shape, bool } from 'prop-types'
import 'react-chat-elements/dist/main.css'
import * as Sentry from '@sentry/react'
import { makeStyles } from '@material-ui/core'
import * as dayjs from 'dayjs'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { TypingArea } from './components/typing-area'
import { FileBox } from './components/file-box'
import { SYSTEM_MESSAGE_TYPES } from '../../utils/constants'
import { hexToRgba } from '#utils/hexToRgba'
import { UserImage } from '#components/user-image'
import { generateUniqueKey } from '#utils/generateUniqueKey'
import styled from 'styled-components'

const useStyles = isShown =>
  makeStyles(theme => ({
    chat: {
      height: 'calc(100% - 260px)',
      display: isShown ? 'block' : 'none',
    },
    chatWrapper: {
      height: '100%',
    },
    scrollableDiv: {
      height: 'calc(100vh - 324px)',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    chatItem: {
      display: 'flex',
      padding: 16,
      borderBottom: `1px solid ${theme.palette.color.minorGrey}`,
    },
    chatItemWrapper: {
      width: '100%',
    },
    chatItemInnerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      fontFamily: theme.typography.bold,
    },
    createdAt: {
      color: theme.palette.color.primary,
    },
    chatItemText: {
      color: hexToRgba(theme.palette.color.default, 0.7),
      width: 360,
      overflowWrap: 'break-word',
      marginBottom: 8,
    },
  }))()

const SUserImage = styled(UserImage)`
  margin-right: 20px;
`

export const Chat = ({
  chat,
  handleOpenAttachments,
  activeDialog,
  getUserById,
  loadMoreMessages,
  isShown,
  setIsOpened,
}) => {
  const { t } = useTranslation('connect')
  const classes = useStyles(isShown)

  const determineMessage = ({ actionType, actionUsers, isFile, message }) => {
    if (isFile) {
      return t('sharedAFile')
    }
    if (actionType === SYSTEM_MESSAGE_TYPES.ADD_USERS) {
      return `${decodeURI(
        getUserById(Number(actionUsers))?.full_name || '[LOADING]'
      )} has been added to the conversation`
    }
    if (actionType === SYSTEM_MESSAGE_TYPES.REMOVE_USERS) {
      return `${decodeURI(
        getUserById(Number(actionUsers))?.full_name || '[LOADING]'
      )} has been removed from the conversation`
    }
    return message
  }

  return (
    <div className={classes.chat}>
      <div className={classes.chatWrapper}>
        <div id="scrollableDiv" className={classes.scrollableDiv}>
          <InfiniteScroll
            dataLength={chat.length}
            next={loadMoreMessages}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            scrollableTarget="scrollableDiv"
            inverse
            hasMore
          >
            {chat.map((c, i) => {
              const sender = getUserById(c.sender_id)
              const isSystemMsg = !!c.action_type
              const title = isSystemMsg
                ? 'TuneGO'
                : decodeURI(sender?.full_name || '[LOADING]')
              const text = determineMessage({
                actionType: c.action_type,
                actionUsers: c.action_users,
                isFile: !!c.fileId,
                message: c.message,
              })
              const DEFAULT_AVATAR = '/assets/svg/ic-tunego-blue.svg'
              let avatar = null
              if (sender?.custom_data) {
                try {
                  avatar = JSON.parse(sender.custom_data)?.avatarUrl
                } catch (err) {
                  Sentry.captureException(err)
                }
              }
              return (
                <div key={generateUniqueKey(c, i)} className={classes.chatItem}>
                  <SUserImage
                    size={45}
                    userImageUrl={avatar || DEFAULT_AVATAR}
                  />
                  <div className={classes.chatItemWrapper}>
                    <div className={classes.chatItemInnerWrapper}>
                      <div className={classes.title}>{title}</div>{' '}
                      <div className={classes.createdAt}>
                        {dayjs(c.created_at).format('MMM D, YYYY h:mm A')}
                      </div>
                    </div>
                    <div className={classes.chatItemText}>{text}</div>
                    {c.fileId && (
                      <FileBox fileId={c.fileId} setIsOpened={setIsOpened} />
                    )}
                  </div>
                </div>
              )
            })}
          </InfiniteScroll>
        </div>
      </div>
      <TypingArea {...{ handleOpenAttachments, activeDialog }} />
    </div>
  )
}

Chat.propTypes = {
  chat: arrayOf(shape({})).isRequired,
  setChat: func.isRequired,
  handleOpenAttachments: func.isRequired,
  loadMoreMessages: func.isRequired,
  getUserById: func.isRequired,
  activeDialog: shape({}).isRequired,
  isShown: bool.isRequired,
  setIsOpened: func.isRequired,
}
