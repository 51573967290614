import React from 'react'
import {
  capitalizeFirstLetter,
  findCardInfo,
  formatCardBrand,
} from '#pages/profile/utils/helpers'
import { Row } from '#pages/_modules/private-shares/components/row'
import { makeStyles } from '@material-ui/core'
import { PAYMENT_HEADERS } from '#pages/profile/utils/constants'
import { formatDate } from '#utils/formatDate'
import { PaymentPurchase } from '#pages/profile/tabs/payment-history/components/payment-purchase'
import { formatPrice } from '#utils/formatPrice'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  text: {
    maxWidth: 140,
    fontSize: 14,
    lineHeight: '16px',
  },
  breakText: {
    whiteSpace: 'break-spaces',
  },
  wrappedText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  wrappedOrder: {
    maxWidth: 230,
  },
  boldedText: {
    color: theme.palette.color.primary,
    fontWeight: 700,
    fontSize: 16,
  },
  paymentColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const PaymentHistoryList = ({ payments }) => {
  const classes = useStyles()

  return payments.map((payment, index) => {
    const {
      id,
      amount,
      createdAt: date,
      externalPaymentId: orderNumber,
    } = payment
    const card = findCardInfo(payment)
    const cardBrand = capitalizeFirstLetter(formatCardBrand(card?.brand))

    const getHeaderWidth = index => PAYMENT_HEADERS[index].desktopWidth

    return (
      <Row key={id} isLighter={!isEven(index)} alignItems="flex-start">
        <div style={{ width: getHeaderWidth(0) }}>
          <span className={`${classes.text} ${classes.wrappedText}`}>
            {formatDate(date)}
          </span>
        </div>
        <div style={{ width: getHeaderWidth(1) }}>
          <span
            className={`${classes.text} ${classes.wrappedText} ${classes.wrappedOrder}`}
          >
            {orderNumber}
          </span>
        </div>
        <PaymentPurchase
          purchase={payment}
          amount={amount}
          orderNumber={orderNumber}
        />
        <div
          className={classes.paymentColumn}
          style={{ width: getHeaderWidth(3) }}
        >
          <span
            className={`${classes.text} ${classes.wrappedText} ${classes.boldedText}`}
          >
            {formatPrice(amount)}
          </span>
          <span className={`${classes.text} ${classes.breakText}`}>
            {`${cardBrand ?? 'unknown card'} ${
              card?.last4Digits ? `(...${card.last4Digits})` : ''
            }`}
          </span>
        </div>
      </Row>
    )
  })
}
