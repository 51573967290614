import React from 'react'
import { Wizard } from '#pages/sell-collectibles/create-collectible/_components/wizard'
import { WIZARD_STEPS } from '#pages/sell-collectibles/create-collectible/_constants'
import { useTranslation } from 'react-i18next'
import { PageWrapper } from '#modules/page-wrapper'
import { NftCreatorProvider } from '#pages/sell-collectibles/create-collectible/_context'
import { useQuery } from '#hooks/useQuery'

export const CreateCollectible = () => {
  const { t } = useTranslation('nftWizard')
  const nftId = useQuery('nftId')
  const personaId = useQuery('personaId')

  return (
    <NftCreatorProvider>
      <PageWrapper showNavBar isFullWidth>
        <Wizard steps={WIZARD_STEPS(t)} nftId={nftId} personaId={personaId} />
      </PageWrapper>
    </NftCreatorProvider>
  )
}
