import React from 'react'
import { func, string, bool, arrayOf } from 'prop-types'
import { FilesList } from '#modules/choose-file-tree/components/files-list'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { FilesTree } from '#modules/files-tree'
import { ACCESS_ROLES } from '#constants/accessRoles'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = (noBorder, width, height) =>
  makeStyles(theme => ({
    structureContainer: {
      border: noBorder ? 0 : 1,
      borderColor: theme.palette.color.primary,
      borderStyle: noBorder ? 'none' : 'solid',
      height,
      width,
    },
  }))()

export const StructureTree = ({
  width,
  noBorder,
  height,
  structureCategory,
  ...structureProps
}) => {
  const classes = useStyles(noBorder, width, height)
  const displayFoldersOrFiles =
    structureCategory === STRUCTURE_TYPE.ALBUM ||
    structureCategory === STRUCTURE_TYPE.PROFILE ? (
      <FilesTree {...structureProps} />
    ) : (
      <FilesList {...structureProps} />
    )

  return (
    <div className={classes.structureContainer}>{displayFoldersOrFiles}</div>
  )
}

StructureTree.propTypes = {
  handleFileSelect: func.isRequired,
  showFilesCondition: string,
  selectedFileId: string,
  selectedFilesIds: arrayOf(string),
  parentId: string,
  width: string,
  height: string,
  noBorder: bool,
  structureCategory: string,
  roles: arrayOf(ACCESS_ROLES),
}

StructureTree.defaultProps = {
  showFilesCondition: '',
  selectedFileId: null,
  selectedFilesIds: null,
  parentId: null,
  width: '',
  height: '',
  noBorder: false,
  structureCategory: '',
  roles: [],
}
