/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react'
import styled from 'styled-components'
import { func, shape } from 'prop-types'
import { Input } from 'react-chat-elements'
import { makeStyles, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import * as QB from 'quickblox/quickblox'
import { Button } from '#components/button'

const useStyles = makeStyles(() => ({
  imageButtonWrapper: {
    paddingRight: 16,
  },
}))

const SButton = styled(Button)`
  height: 50px;
  width: 50px;
  padding: 0;
  border-radius: 50%;
`

export const TypingArea = ({ handleOpenAttachments, activeDialog }) => {
  const inputRef = useRef(null)
  const { t } = useTranslation('connect')
  const theme = useTheme()
  const classes = useStyles()

  const handleSend = () => {
    const text = inputRef.current.input.value
    if (text) {
      const message = {
        type: 'groupchat',
        body: text,
        extension: {
          save_to_history: 1,
          dialog_id: activeDialog._id,
        },
        markable: 1,
      }
      const dialogJid = QB.chat.helpers.getRoomJidFromDialogId(activeDialog._id)
      message.id = QB.chat.send(dialogJid, message)
      inputRef.current.input.value = ''
    }
  }

  return (
    <Input
      ref={inputRef}
      placeholder={t('typeYourMessage')}
      inputStyle={{
        fontFamily: theme.typography.fontFamily,
        overflowY: 'hidden',
        height: 40,
      }}
      rightButtons={
        <>
          <div className={classes.imageButtonWrapper}>
            <SButton onClick={handleOpenAttachments}>
              <img
                src="/assets/svg/ic-attachment-button.svg"
                width="40px"
                height="40px"
                alt="Attachment"
              />
            </SButton>
          </div>
          <SButton onClick={handleSend}>
            <img
              src="/assets/svg/ic-send-button.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </SButton>
        </>
      }
      autofocus
      multiline
    />
  )
}

TypingArea.propTypes = {
  activeDialog: shape({}).isRequired,
  handleOpenAttachments: func.isRequired,
}
