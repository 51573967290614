import React from 'react'
import { bool, func } from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AddPaymentMethodModal } from '#pages/profile/tabs/payment-methods/components/add-payment-method-modal'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB)

export const AddPaymentMethodModalContainer = ({ isOpened, ...props }) => {
  if (!isOpened) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodModal {...props} />
    </Elements>
  )
}

AddPaymentMethodModalContainer.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
}
