/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class SongApi {
  static postMoveSong = (structureId, newParentId) => {
    return getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_MOVE_PROJECT, {
        structureId,
        newParentId,
      })
    )
  }
}
