import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { Modal } from '#components/modal'
import { NAVIGATION_PATHS } from '#routes/routes'
import { buildUrl } from '#utils/buildUrl'

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

const SModal = styled(Modal)`
  justify-content: space-between;
  min-height: 180px;
`

const SButton = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.default};
  border: none;
  padding: 0 80px;
  text-transform: uppercase;
`

const SButtonCancel = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.default};
  margin-right: 20px;
  text-transform: uppercase;
`

export const EmptyProfileModal = ({
  isOpened,
  setIsModalOpened,
  profileId,
  message,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const handleNavigate = () => {
    navigate(
      buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, {
        id: profileId,
      })
    )
  }

  return (
    <SModal
      isOpen={isOpened}
      setIsOpen={setIsModalOpened}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <span>{message}</span>
      <div className={classes.footer}>
        <SButtonCancel onClick={() => setIsModalOpened(false)}>
          {t('cancel')}
        </SButtonCancel>
        <SButton onClick={handleNavigate}>
          {vaultTranslation.t('goToVault')}
        </SButton>
      </div>
    </SModal>
  )
}

EmptyProfileModal.propTypes = {
  isOpened: bool.isRequired,
  profileId: string.isRequired,
  setIsModalOpened: func.isRequired,
  message: string.isRequired,
}
