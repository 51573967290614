import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { func, number, string } from 'prop-types'
import { navigate } from '@reach/router'
import {
  SUBSCRIPTION_PERIOD,
  BILLING_PERIOD,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_ACTION,
  SUBSCRIPTION_STATUS,
} from '#pages/profile/utils/constants'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useSubscriptionsPlans } from '#hooks/swr/useSubscription'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 275,
  },
}))

const SButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const SButtonCancel = styled(Button)`
  width: 100%;
  margin-top: auto;
  text-transform: uppercase;
`

export const SubscriptionButtons = ({
  planLevel,
  planId,
  setLevel,
  setAction,
  setPlanId,
  setIsModalOpened,
}) => {
  const { t } = useTranslation('profile')
  const classes = useStyles()
  const { user } = useUser()
  const currentSubscriptionStatus = user?.subscription?.subscription?.status

  const { plans } = useSubscriptionsPlans()
  const freePlanId = plans?.filter(plan => plan.tierLevel === 0)[0].id

  const currentPlanLevel = user?.subscription.subscriptionPlan.tierLevel
  const currentPlanPeriod = user?.subscription.billingPeriod

  const handleClick = (action, level, id) => {
    setAction(action)
    setLevel(level)
    setPlanId(id)
    setIsModalOpened(true)
  }

  const handleSubscriptionSwitch = () => {
    const isProrated =
      currentSubscriptionStatus !== SUBSCRIPTION_STATUS.CANCELED

    navigate(
      `${NAVIGATION_PATHS.SUBSCRIPTIONS_PAYMENT}?planId=${planId}&planPeriod=${
        SUBSCRIPTION_PERIOD.YEAR
      }&isProrated=${isProrated}&isSwitched=${true}`
    )
  }

  const upgradeDowngradeButton = current => {
    switch (true) {
      case planLevel === 0 && current === 0:
        return null
      case current < planLevel:
        return (
          <SButton
            onClick={() =>
              handleClick(SUBSCRIPTION_ACTION.UPGRADE, planLevel, planId)
            }
          >
            {t('upgradeTo', {
              planName: t(SUBSCRIPTION_PLANS[planLevel]),
            })}
          </SButton>
        )
      case current > planLevel || planLevel === 0:
        return (
          <SButton
            variant={VARIANTS.CANCEL_BLACK}
            onClick={() =>
              handleClick(
                planLevel === 0
                  ? SUBSCRIPTION_ACTION.DOWNGRADE_TO_FREE
                  : SUBSCRIPTION_ACTION.DOWNGRADE,
                planLevel,
                planId
              )
            }
          >
            {t('downgradeTo', {
              planName: t(SUBSCRIPTION_PLANS[planLevel]),
            })}
          </SButton>
        )
      default:
        return null
    }
  }

  return (
    <div className={classes.buttonsWrapper}>
      {upgradeDowngradeButton(currentPlanLevel)}
      {planLevel === currentPlanLevel &&
        currentPlanLevel !== 0 &&
        currentPlanPeriod === BILLING_PERIOD.MONTH && (
          <SButton
            variant={VARIANTS.CANCEL_BLACK}
            onClick={handleSubscriptionSwitch}
          >
            {t('switchToAnnual')}
          </SButton>
        )}
      {planLevel === currentPlanLevel && currentPlanLevel !== 0 && (
        <SButtonCancel
          variant={VARIANTS.CANCEL_BLACK}
          onClick={() => handleClick(SUBSCRIPTION_ACTION.CANCEL, 0, freePlanId)}
        >
          {t('cancelSubscription')}
        </SButtonCancel>
      )}
    </div>
  )
}

SubscriptionButtons.propTypes = {
  planLevel: number.isRequired,
  planId: string.isRequired,
  setAction: func.isRequired,
  setLevel: func.isRequired,
  setPlanId: func.isRequired,
  setIsModalOpened: func.isRequired,
}
