import React from 'react'
import { number, func } from 'prop-types'

// Commented out mobile view because of mvp - need to adjust the logic to display proper values
// import SharesListMobile from '#pages/_modules/PrivateShares/components/SharesListMobile'
import { SharesListDesktop } from '#pages/_modules/private-shares/components/shares-list-desktop'
import { Pagination } from '#components/pagination'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  sharesWrapper: {
    width: '100%',
  },
}))

export const PrivateShares = ({
  count,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={classes.sharesWrapper}>
      <SharesListDesktop {...props} setPage={setPage} />
      <Pagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={nextPage => {
          setPage(nextPage)
        }}
        onChangeRowsPerPage={nextRowsPerPage => {
          setRowsPerPage(nextRowsPerPage)
          setPage(0)
        }}
      />
    </div>
  )
}

PrivateShares.propTypes = {
  count: number.isRequired,
  page: number.isRequired,
  rowsPerPage: number.isRequired,
  setPage: func.isRequired,
  setRowsPerPage: func.isRequired,
}
