import React from 'react'
import { string, bool } from 'prop-types'
import { CloseIcon, CheckIcon, InfoIcon } from '#icons'
import styled from 'styled-components'
import { Tooltip } from '#components/tooltip'

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const STooltipEl = styled.span`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SCheckIcon = styled(CheckIcon)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.success_40};
`

const SCloseIcon = styled(CloseIcon)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.error_40};
`

export const FileCheck = ({ isValid, text, tooltipText }) => {
  return (
    <SWrapper>
      {isValid ? <SCheckIcon /> : <SCloseIcon />}
      <span>{text}</span>
      {!isValid && (
        <Tooltip text={tooltipText} position="top">
          <STooltipEl>
            <InfoIcon />
          </STooltipEl>
        </Tooltip>
      )}
    </SWrapper>
  )
}

FileCheck.propTypes = {
  isValid: bool,
  text: string,
  tooltipText: string,
}
