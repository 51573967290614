export const ROLES = [
  { label: 'Performing Artist', value: 'performing-artist' },
  { label: 'Producer', value: 'producer' },
  { label: 'Composer', value: 'composer' },
  { label: 'Lyricist', value: 'lyricist' },
  { label: 'Engineer', value: 'engineer' },
  { label: 'Session Musician', value: 'session-musician' },
  { label: 'Songwriter', value: 'song-writer' },
  { label: 'A&R', value: 'a-r' },
  { label: 'Manager', value: 'manager' },
  { label: 'Legal', value: 'legal' },
  { label: 'Distribution', value: 'distribution' },
  { label: 'Marketing / Promotion', value: 'marketing-promotion' },
  { label: 'Executive', value: 'executive' },
  { label: 'Music Publisher', value: 'music-publisher' },
  { label: 'Music Copyright Owner', value: 'music-copyright-owner' },
  { label: 'Photographer', value: 'photographer' },
  { label: 'Videographer', value: 'videographer' },
  { label: 'Video Director', value: 'video-director' },
  { label: 'Digital Artist / Designer', value: 'digital-artist-designer' },
]
