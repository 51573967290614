import React, { useEffect, useState } from 'react'
import { shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  SUBSCRIPTION_PLANS,
  PAYMENT_HEADERS,
} from '#pages/profile/utils/constants'
import { PAYMENT_CATEGORIES } from '#constants/paymentCategories'
import { SUBSCRIPTION_CATEGORIES } from '#constants/subscriptionCategories'
import {
  capitalizeFirstLetter,
  formatPriceValueHistory,
} from '#pages/profile/utils/helpers'
import { formatDate } from '#utils/formatDate'

const useStyles = makeStyles(theme => ({
  purchaseWrapper: {
    width: PAYMENT_HEADERS[2].desktopWidth,
  },
  wrappedText: {
    whiteSpace: 'nowrap',
    maxWidth: 280,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '14px',
    lineHeight: '16px',
    margin: 0,
  },
  boldedText: {
    color: theme.palette.color.primary,
    fontWeight: 700,
    lineHeight: '16px',
  },
  linesText: {
    fontSize: '12px',
  },
}))

export const PaymentPurchase = ({ purchase, amount, orderNumber }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const [name, setName] = useState()
  const [details, setDetails] = useState([])

  const setDistribution = subscription => {
    const distributionClass = capitalizeFirstLetter(
      subscription?.temporaryDistro?.class
    )
    setName(`${distributionClass} ${profileTranslation.t('distribution')}`)
  }

  const setTier = subscription => {
    const tierName = `${t('tunego')} ${
      profileTranslation
        .t(SUBSCRIPTION_PLANS[purchase.subscriptionPlan?.tierLevel])
        .toUpperCase() ?? profileTranslation.t('unknownTier')
    } ${profileTranslation.t('subscription')}`
    const tierDetails = [
      formatPriceValueHistory(
        subscription?.billingPeriod,
        amount,
        profileTranslation.t
      ),
      `${profileTranslation.t('renewalDate')}: ${formatDate(
        subscription?.currentCycleEnd
      )}`,
    ]

    setName(tierName)
    setDetails(tierDetails)
  }

  const setSubscription = subscription => {
    switch (subscription?.category) {
      case SUBSCRIPTION_CATEGORIES.DISTRIBUTION:
        return setDistribution(subscription)
      case SUBSCRIPTION_CATEGORIES.TIER:
        return setTier(subscription)
      default:
        return null
    }
  }

  const setMastering = () => {
    setName(profileTranslation.t('mastering'))
    setDetails([
      purchase.masteredFile?.file?.profile?.name,
      purchase.masteredFile?.file?.name,
    ])
  }

  const setPromotion = () => {
    setName(profileTranslation.t('songPromotion'))
    setDetails([
      purchase.promotion?.file?.profile?.name,
      purchase.promotion?.file?.name,
    ])
  }

  const initializePaymentPurchase = () => {
    switch (purchase?.category) {
      case PAYMENT_CATEGORIES.MASTERING:
        return setMastering()
      case PAYMENT_CATEGORIES.PROMOTION:
        return setPromotion()
      case PAYMENT_CATEGORIES.SUBSCRIPTION:
        return setSubscription(purchase?.subscription)
      default:
        return null
    }
  }

  useEffect(() => {
    initializePaymentPurchase()
  }, [])

  return (
    <div className={classes.purchaseWrapper}>
      <span className={`${classes.wrappedText} ${classes.boldedText}`}>
        {name}
      </span>
      {details.map((line, index) => (
        <p
          key={`${orderNumber}${index}`}
          className={`${classes.wrappedText} ${classes.linesText}`}
        >
          {line}
        </p>
      ))}
    </div>
  )
}

PaymentPurchase.propTypes = {
  purchase: shape().isRequired,
  amount: string.isRequired,
  orderNumber: string.isRequired,
}
