export const NFT_STATUS = {
  IN_COMPLETE: 'incomplete',
  SPLITS_PENDING: 'splits_pending',
  SPLITS_DENIED: 'splits_denied',
  SPLITS_APPROVED: 'splits_approved',
  VERIFY_PERSONA: 'verify_persona',
  APPROVE_SPLITS: 'approve_splits',
  READY_FOR_MINTING: 'ready_for_minting',
  QUEUED_FOR_REVIEW: 'queued_for_review',
  UNDER_REVIEW: 'under_review',
  REVIEW_DENIED: 'review_denied',
  QUEUED_FOR_MINTING: 'queued_for_minting',
  MINTING_IN_PROGRESS: 'minting_in_progress',
  MINTED: 'minted',
  MINTING_ERROR: 'minting_error',
  QUEUED_FOR_DROP: 'queued_for_drop',
  LISTED_FOR_SALE: 'listed_for_sale',
  ACTIVE: 'active',
  COMING_SOON: 'coming_soon',
  EXPIRED: 'expired',
  SOLD_OUT: 'sold_out',
}

export const STATUS_LABELS = {
  [NFT_STATUS.IN_COMPLETE]: 'incomplete',
  [NFT_STATUS.SPLITS_PENDING]: 'pending splits',
  [NFT_STATUS.SPLITS_DENIED]: 'splits denied',
  [NFT_STATUS.SPLITS_APPROVED]: 'splits approved',
  [NFT_STATUS.VERIFY_PERSONA]: 'confirm ID',
  [NFT_STATUS.APPROVE_SPLITS]: 'pending splits',
  [NFT_STATUS.READY_FOR_MINTING]: 'approved',
  [NFT_STATUS.QUEUED_FOR_REVIEW]: 'in review',
  [NFT_STATUS.UNDER_REVIEW]: 'in review',
  [NFT_STATUS.REVIEW_DENIED]: 'denied',
  [NFT_STATUS.QUEUED_FOR_MINTING]: 'approved',
  [NFT_STATUS.MINTING_IN_PROGRESS]: 'approved',
  [NFT_STATUS.MINTED]: 'approved',
  [NFT_STATUS.QUEUED_FOR_DROP]: 'approved',
  [NFT_STATUS.MINTING_ERROR]: 'error creating drop',
  [NFT_STATUS.LISTED_FOR_SALE]: 'drop is live',
  [NFT_STATUS.ACTIVE]: 'drop is live',
  [NFT_STATUS.COMING_SOON]: 'coming soon',
  [NFT_STATUS.EXPIRED]: 'expired',
  [NFT_STATUS.SOLD_OUT]: 'sold out',
}

export const NFT_ACCESS_ROLE = {
  PROFILE_OWNER: 'profileOwner',
  PROFILE_COLLABORATOR: 'profileCollaborator',
  NFT_SPLIT: 'nftSplit',
}
