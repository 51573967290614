import React from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import { bool, func, string, number } from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { LoadingImage } from '#components/loaders/loading-image'
import styled from 'styled-components'

const SPlayerWrapper = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_100};
  position: fixed;
  bottom: ${({ bottomOffset }) => `${bottomOffset}px`};
  width: 100vw;
  height: 90px;
  z-index: 3;
`

const SSongName = styled.div`
  display: flex;
  z-index: 3;
  padding: 10px;
`

const SAudioTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 10px;
`

const SAudioText = styled.h5`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-size: 15px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

const SCloseIcon = styled.div`
  position: absolute;
  right: 25px;
  top: -50px;
  display: flex;
  cursor: pointer;
  &:hover {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  },
`

const SAudioPlayer = styled(AudioPlayer)`
  bottom: ${({ bottomOffset }) => `${bottomOffset}px`};
`

export const FileAudioPlayer = ({
  fileName,
  projectName,
  uri,
  ready,
  bottomOffset,
  onCanPlay,
  onClose,
}) => {
  const songDetails = (
    <SSongName>
      <img src="/assets/svg/ic-tunego.svg" height={40} alt="threeBar" />
      <SAudioTextContainer>
        {/* placeholders for song name and artist profile name */}
        <SAudioText>{ready ? fileName : 'Loading...'}</SAudioText>
        <SAudioText>{projectName}</SAudioText>
      </SAudioTextContainer>
    </SSongName>
  )

  return (
    <SPlayerWrapper bottomOffset={bottomOffset}>
      <SCloseIcon>
        <CloseIcon onClick={onClose} />
      </SCloseIcon>
      <SAudioPlayer
        className="react-h5-audio-player"
        bottomOffset={bottomOffset}
        src={uri}
        showJumpControls={false}
        showLoopControl={false}
        customIcons={{
          play: ready ? <PlayArrowIcon /> : <LoadingImage />,
          pause: ready ? <PauseIcon /> : <LoadingImage />,
        }}
        customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
        customControlsSection={[
          songDetails,
          RHAP_UI.MAIN_CONTROLS,
          RHAP_UI.VOLUME_CONTROLS,
        ]}
        autoPlay
        showFilledVolume
        onCanPlay={onCanPlay}
      />
    </SPlayerWrapper>
  )
}

FileAudioPlayer.propTypes = {
  fileName: string.isRequired,
  projectName: string.isRequired,
  uri: string.isRequired,
  onCanPlay: func.isRequired,
  onClose: func.isRequired,
  ready: bool,
  bottomOffset: number,
}

FileAudioPlayer.defaultProps = {
  ready: false,
  bottomOffset: 0,
}
