import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Section } from '#components/section'
import { SubHeader } from '#pages/_components/subheader-component'
import { ICON_SECTION_DATA } from '#pages/video-go/landing-page/utils/constants'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.color.default,
    color: theme.palette.background.default,
  },
}))

export const HowItWorksSection = () => {
  const { t } = useTranslation('videogo')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Section
        header={t('howItWorks')}
        getStartedUrl={`${NAVIGATION_PATHS.VIDEO_GO}/coming-soon`}
        subheaderComponent={<SubHeader iconSectionData={ICON_SECTION_DATA} />}
        noBg
      />
    </div>
  )
}
