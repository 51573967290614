import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, objectOf, string, oneOfType } from 'prop-types'
import { VerticalBarGraph } from '#pages/promotion/report/components/vertical-bar-graph'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  distributionOfRatingsGraphContainer: {
    width: '100%',
    minHeight: '350px',
    minWidth: '300px',
    margin: '0 auto',
  },
})

export const DistributionOfRatings = ({ data }) => {
  const classes = useStyles()
  return (
    <ReviewRowElement
      refName="distributionOfRatingsEl"
      title="distributionOfRatings"
      description="theDistributionOfRatingsShow"
    >
      <div className={classes.distributionOfRatingsGraphContainer}>
        <VerticalBarGraph data={data} yUnit="%" yDomain={[0, 40]} />
      </div>
    </ReviewRowElement>
  )
}

DistributionOfRatings.propTypes = {
  data: arrayOf(oneOfType[(objectOf(string), string)]).isRequired,
}
