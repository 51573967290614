export const COLLABORATOR_ACTION_TYPES = {
  resendInvite: 'resend-invite',
  cancelInvite: 'cancel-invite',
}

export const MENU_OPTIONS = [
  {
    title: `Resend Invite`,
    actionType: COLLABORATOR_ACTION_TYPES.resendInvite,
  },
  {
    title: `Cancel Invite`,
    actionType: COLLABORATOR_ACTION_TYPES.cancelInvite,
  },
]
