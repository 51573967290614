import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'

const useStyles = isReviewStep =>
  makeStyles(theme => ({
    wrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      background: theme.palette.color.default,
      width: '100%',
      marginTop: 40,
      marginBottom: 30,
    },
    blueBar: {
      background: theme.palette.color.primary,
      width: 30,
    },
    issuesWrapper: {
      padding: 30,
      width: '100%',
      backgroundColor: theme.palette.color.darkGrey22,
      border: `1px solid ${theme.palette.color.primary}`,
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      color: theme.palette.color.default,
      fontSize: 22,
      lineHeight: '24px',
      fontWeight: 700,
      marginLeft: 15,
    },
    issuesList: {
      color: theme.palette.color.warning,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '24px',
      paddingLeft: isReviewStep ? 60 : 20,
      marginTop: isReviewStep ? 20 : 0,
      listStyleType: isReviewStep ? 'disc' : 'none',
      position: 'relative',

      '& li': {
        marginLeft: isReviewStep ? 0 : 15,
      },

      '&::before': !isReviewStep && {
        content: "url('/assets/svg/ic-warning-2.svg')",
        width: 20,
        height: 20,
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
      },
    },
  }))()

export const ReleaseIssues = () => {
  const { storedFormState, step } = useWizard()
  const isReviewStep = step === 6
  const classes = useStyles(isReviewStep)
  const { t } = useTranslation('distro')

  const problemsTemp = storedFormState[FIELDS.PROBLEMS_TEMP]

  return problemsTemp ? (
    <div className={classes.wrapper}>
      {isReviewStep && <div className={classes.blueBar} />}
      <div className={classes.issuesWrapper}>
        {isReviewStep && (
          <div className={classes.titleWrapper}>
            <img
              src="/assets/svg/ic-warning.svg"
              width="25px"
              height="25px"
              alt=""
            />
            <span className={classes.title}>{t('pleaseCorrectIssues')}</span>
          </div>
        )}
        <ul className={classes.issuesList}>
          <li>{problemsTemp}</li>
        </ul>
      </div>
    </div>
  ) : null
}

ReleaseIssues.propTypes = {}
