import { useEventSWR } from '#hooks/swr/useEventSWR'
import { EVENTS } from '#api/event'
import { ENDPOINTS } from '#api/endpoints'

export const useSettings = swrConfig => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [EVENTS.SETTINGS_UPDATED],
    swrKey: ENDPOINTS.SYSTEM_SETTINGS,
    swrConfig,
  })
  return {
    settings: data,
    isLoading: !error && !data,
    isError: !!error,
    ...rest,
  }
}
