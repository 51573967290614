import React from 'react'
import styled from 'styled-components'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'
import { VideoBackground } from '#pages/_components/video-background'
import { MainHeader } from '#pages/_components/section-headers/main-header'
import { SecondaryHeader } from '#pages/_components/section-headers/secondary-header'

const SWrapper = styled.div`
  height: calc(100vh - 60px);
`

const SInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const SLetsGo = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
`

const SList = styled.ol`
  text-align: left;
  margin: 0;
`

const SButton = styled(Button)`
  white-space: nowrap;
  text-transform: uppercase;
`

export const NoProfiles = () => {
  const { t } = useTranslation('vault')

  return (
    <SWrapper>
      <VideoBackground src="/assets/mp4/home-bg.mp4" opacity={0.4}>
        <SInnerWrapper>
          <MainHeader>{t('welcomeToYourTuneGoVault')}</MainHeader>
          <SecondaryHeader>
            {t('theEasiestWayToSellCollectibles')}
          </SecondaryHeader>
          <SList>
            <li>{t('createNewArtist')}</li>
            <li>{t('createProject')}</li>
            <li>{t('addMusic')}</li>
          </SList>
          <SLetsGo>{t('letsGo')}</SLetsGo>
          <SButton onClick={() => navigate(NAVIGATION_PATHS.CREATE_PROFILE)}>
            {t('createProfile')}
          </SButton>
        </SInnerWrapper>
      </VideoBackground>
    </SWrapper>
  )
}
