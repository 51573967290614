import React from 'react'
import { node } from 'prop-types'
import { Profiles } from '#pages/vault/profiles'
import { ProfileContent } from '#pages/vault/profile-content'
import { ArtistCollaborators } from '#pages/vault/profile-collaborators'
import { CreateProfile } from '#pages/vault/create-profile'
import { CreateSong } from '#pages/vault/wizard'
import { CreateFolderInsideProfile } from '#pages/vault/create-album-inside-profile'
import { LandingPage } from '#pages/vault/landing-page'
import { SongPage } from '#pages/vault/song'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export {
  ArtistCollaborators,
  CreateFolderInsideProfile,
  CreateProfile,
  CreateSong,
  LandingPage,
  ProfileContent,
  Profiles,
  Routes,
  SongPage,
}
