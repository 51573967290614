import React, { useState, useEffect, useCallback } from 'react'
import { func, string, number, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { mutate } from 'swr'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { EditContributorModal } from '#pages/vault/song/tabs/metadata/components/edit-contributor-modal/edit-contributor-modal'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ContributorsApi } from '#api/requests/contributors'
import { useSingleContributor } from '#hooks/swr/useContributors'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'

export const EditContributorContainer = ({
  projectId,
  isEditContributorModalOpen,
  handleCloseEditContributorModal,
  idOfActiveContributor,
  isForDistro,
  mutateDistro,
  handleDistroEditContributor,
  handleDeleteDistroContributor,
  pageIndex,
  rowsPerPage,
}) => {
  const { t } = useTranslation('vault')
  const [error, setError] = useState('')
  const [isDeleteContributorModalOpen, setIsDeleteContributorModalOpen] =
    useState(false)

  const {
    actions,
    errors: apiErrors,
    loading,
  } = useWithAsyncAction({
    patchContributor: ContributorsApi.patchContributor,
    deleteContributor: ContributorsApi.deleteContributor,
  })

  const { contributor, mutate: mutateContributor } = useSingleContributor(
    isEditContributorModalOpen,
    projectId,
    idOfActiveContributor
  )

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (apiErrors.patchContributor) {
      handleSetError(apiErrors.patchContributor)
    }
    if (apiErrors.deleteContributor) {
      handleSetError(apiErrors.deleteContributor)
    }
  }, [apiErrors.deleteContributor, apiErrors.patchContributor, handleSetError])

  const handleOpenDeleteModal = () => {
    setIsDeleteContributorModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteContributorModalOpen(false)
  }

  const triggerMutate = structureId =>
    mutate(
      `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS, {
        id: structureId,
      })}?page=${pageIndex}&limit=${rowsPerPage}`
    )

  const handleDeleteContributor = async () => {
    await actions.deleteContributor(projectId, idOfActiveContributor)
    handleCloseDeleteModal()
    handleCloseEditContributorModal()
    triggerMutate(projectId)
    if (mutateDistro) {
      handleDeleteDistroContributor(idOfActiveContributor)
      mutateDistro()
    }
  }

  const handleEditContributor = async ({ contributorName, role, ...rest }) => {
    const replacedValues = {
      ...rest,
      role: role.map(item => {
        if (item.value) {
          return item.value
        }
        return item
      }),
      ...(!contributor.user.isOnboarded && {
        pendingName: contributorName,
      }),
    }
    await actions.patchContributor(
      { projectId, idOfActiveContributor },
      replacedValues
    )

    await mutateContributor({ ...contributor, ...replacedValues })
    await triggerMutate(projectId)

    if (mutateDistro) {
      handleDistroEditContributor({ ...contributor, ...replacedValues })
      mutateDistro()
    }
    handleCloseEditContributorModal()
  }

  return (
    <>
      <EditContributorModal
        {...{
          isDeleteContributorModalOpen,
          isEditContributorModalOpen,
          handleCloseEditContributorModal,
          handleOpenDeleteModal,
          handleCloseDeleteModal,
          handleDeleteContributor,
          handleEditContributor,
          contributor,
          isForDistro,
        }}
      />
      <CommonLoadingOverlay
        message={t('contributorSaving')}
        dialogOpen={loading.patchContributor || loading.deleteContributor}
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    </>
  )
}

EditContributorContainer.propTypes = {
  projectId: string.isRequired,
  isEditContributorModalOpen: bool.isRequired,
  handleCloseEditContributorModal: func.isRequired,
  idOfActiveContributor: string.isRequired,
  pageIndex: number.isRequired,
  rowsPerPage: number.isRequired,
  isForDistro: bool.isRequired,
  mutateDistro: func,
  handleDistroEditContributor: func,
  handleDeleteDistroContributor: func,
}

EditContributorContainer.defaultProps = {
  mutateDistro: null,
  handleDistroEditContributor: null,
  handleDeleteDistroContributor: null,
}
