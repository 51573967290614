import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { AddSplitModal } from '#pages/vault/song/tabs/splits/components/modals/add-split-modal'
import { EditSplitRoleModal } from '#pages/vault/song/tabs/splits/components/modals/edit-split-role-modal'
import { EditIncomeModal } from '#pages/vault/song/tabs/splits/components/modals/edit-income-modal'
import { TableHeader } from '#pages/vault/song/tabs/splits/components/table-header'
import { SplitRows } from '#pages/vault/song/tabs/splits/components/split-rows'
import { TotalRow } from '#pages/vault/song/tabs/splits/components/total-row'
import {
  ACTION_TYPES,
  HEADERS,
  SPLIT_STATUSES,
} from '#pages/vault/song/tabs/splits/utils/constants'
import { SplitsApi } from '#api/requests/splits'
import { useSplits } from '#hooks/swr/useSplits'
import { BaseLoader } from '#components/loaders/base-loader'
import { useSong } from '#pages/vault/song/context/song'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { ButtonRow } from '#components/button-row'
import { RemoveSplitModal } from '#pages/vault/song/tabs/splits/components/modals/remove-split-modal'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'

export const Splits = () => {
  const song = useSong()
  const { user } = useUser()
  const { projectId } = useParams()
  const { t } = useTranslation('vault')
  const { error, clearError, setErrorMessage } = useError()
  const [isAddModalOpened, setIsAddModalOpened] = useState(false)
  const [editedSplitId, setEditedSplitId] = useState('')
  const [editedIncomeType, setEditedIncomeType] = useState('')
  const [tempSplitIncomes, setTempSplitIncomes] = useState(null)
  const { splits, isLoading, revalidate } = useSplits(projectId, '?limit=100')
  const { actions, anyLoading, anyError } = useWithAsyncAction({
    postSplit: SplitsApi.postSplit,
    deleteSplit: SplitsApi.deleteSplit,
    postSplitOffPlatform: SplitsApi.postSplitOffPlatform,
    updateSplit: SplitsApi.updateSplit,
    updateIncomes: SplitsApi.updateIncomes,
    approveSplit: SplitsApi.approveSplit,
    denySplit: SplitsApi.denySplit,
    reinviteSplit: SplitsApi.reinviteSplit,
  })
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const [areSplitsSent, setAreSplitsSent] = useState(false)
  const [isLastSplit, setIsLastSplit] = useState(false)

  const openEditIncomeModal = () => {
    setIsEditModalOpened(!isEditModalOpened)
  }

  useEffect(() => {
    if (splits) {
      const mappedToDisplay = splits?.data.map(split => {
        return {
          ...split,
          masterIncomeToDisplay: split.pendingMasterIncome,
          publishingToDisplay: split.pendingPublishing,
        }
      })
      setTempSplitIncomes(mappedToDisplay)
    }
  }, [splits])

  useEffect(() => {
    if (anyError) {
      setErrorMessage(anyError)
    }
  }, [anyError, setErrorMessage])

  const getCommonSplitValues = values => ({
    structureId: projectId,
    role: values.role.map(({ value }) => value),
  })

  const addSplit = async values => {
    const split = {
      userId: values.user.value.id,
      ...getCommonSplitValues(values),
    }

    await actions.postSplit(split)
    setIsAddModalOpened(false)
    revalidate()
  }

  const addSplitOffPlatform = async values => {
    const { pendingName, email } = values
    const split = {
      pendingName,
      email,
      ...getCommonSplitValues(values),
    }
    await actions.postSplitOffPlatform(split)
    setIsAddModalOpened(false)
    revalidate()
  }

  const cancelSplit = async id => {
    if (splits?.data.length === 1) {
      setIsLastSplit(true)
      setIsModalOpened(true)
    } else {
      await actions.deleteSplit(id)
      revalidate()
    }
  }

  const reinviteSplit = async id => {
    await actions.reinviteSplit(id)
    revalidate()
  }

  const determineMenuAction = (actionType, splitId) => {
    switch (actionType) {
      case ACTION_TYPES.edit: {
        return setEditedSplitId(splitId)
      }
      case ACTION_TYPES.cancel: {
        return cancelSplit(splitId)
      }
      case ACTION_TYPES.cancelWarning: {
        if (splits?.data.length === 1) {
          setIsLastSplit(true)
        }
        setIsModalOpened(true)
        return null
      }
      case ACTION_TYPES.resend: {
        return reinviteSplit(splitId)
      }
      default: {
        return null
      }
    }
  }

  const updateSplit = async ({ role }) => {
    await actions.updateSplit(editedSplitId, {
      role: role.map(r => r.value),
    })
    revalidate()
    setEditedSplitId('')
  }

  const updateIncome = async incomes => {
    setTempSplitIncomes(prev =>
      prev.map(item => ({
        ...item,
        [`masterIncomeToDisplay`]: Number(incomes[`${item.id}-master`]),
        [`publishingToDisplay`]: Number(incomes[`${item.id}-publishing`]),
      }))
    )

    await Promise.all(
      (splits?.data || [])
        .filter(split => split.status === SPLIT_STATUSES.REJECTED)
        .map(split => reinviteSplit(split.id))
    )

    setIsEditModalOpened(false)
    setAreSplitsSent(true)
  }

  useEffect(() => {
    if (tempSplitIncomes && areSplitsSent) {
      const sendForApproval = async () => {
        const splitsToSend = tempSplitIncomes.map(
          ({
            id: splitId,
            masterIncomeToDisplay: masterIncome,
            publishingToDisplay: publishing,
          }) => {
            return {
              splitId,
              masterIncome,
              publishing,
            }
          }
        )
        await actions.updateIncomes(projectId, splitsToSend)
        revalidate()
      }

      sendForApproval()
    }

    return () => {
      setAreSplitsSent(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempSplitIncomes, areSplitsSent])

  const acceptSplit = async id => {
    await actions.approveSplit(id, { structureId: projectId })
    revalidate()
  }

  const denySplit = async id => {
    await actions.denySplit(id, { structureId: projectId })
    revalidate()
  }

  if (anyLoading || error) {
    return (
      <CommonLoadingOverlay
        message={t('splitSending')}
        dialogOpen={anyLoading}
        error={error}
        onErrorBackdropClick={clearError}
      />
    )
  }

  const isAcceptanceView =
    song?.accessRoles.includes(ACCESS_ROLES.SPLIT) ||
    song?.accessRoles.includes(ACCESS_ROLES.COLLABORATOR)

  const closeModal = () => {
    setIsAddModalOpened(false)
  }

  const handleCloseRemoveSplitModal = () => {
    setIsLastSplit(false)
    setIsModalOpened(false)
  }

  return (
    <ColumnWrapper>
      {isAddModalOpened && (
        <AddSplitModal
          isOpened={isAddModalOpened}
          closeModal={closeModal}
          addSplit={addSplit}
          addSplitOffPlatform={addSplitOffPlatform}
        />
      )}
      {!!editedSplitId && (
        <EditSplitRoleModal
          isOpened={!!editedSplitId}
          updateSplit={updateSplit}
          split={splits?.data.find(split => split.id === editedSplitId)}
          onClose={() => {
            setEditedSplitId('')
          }}
        />
      )}
      {isEditModalOpened && (
        <EditIncomeModal
          isOpened={isEditModalOpened}
          editedIncomeType={editedIncomeType}
          updateIncome={updateIncome}
          splits={tempSplitIncomes}
          onClose={() => {
            setIsEditModalOpened(false)
          }}
        />
      )}
      {!isAcceptanceView && (
        <ButtonRow
          secondaryText={`+ ${t('addSplit')}`}
          secondaryOnClick={() => setIsAddModalOpened(true)}
        />
      )}
      <TableHeader headers={HEADERS} />
      <BaseLoader isLoading={isLoading} text={t('splitLoading')} />
      <SplitRows
        {...{
          tempSplitIncomes,
          determineMenuAction,
          isAcceptanceView,
          userMeId: user?.id,
          acceptSplit,
          denySplit,
        }}
      />
      {tempSplitIncomes && (
        <TotalRow
          {...{
            setEditedIncomeType,
            tempSplitIncomes,
            isAcceptanceView,
            openEditIncomeModal,
          }}
        />
      )}
      <RemoveSplitModal
        isOpened={isModalOpened}
        isLastSplit={isLastSplit}
        onClose={handleCloseRemoveSplitModal}
      />
    </ColumnWrapper>
  )
}
