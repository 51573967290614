import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { StepSubHeader } from '#pages/sell-collectibles/create-collectible/_components/step-subheader'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { loadProfiles } from '#utils/loadProfiles'
import styled from 'styled-components'
import { AutoComplete } from '#components/inputs/auto-complete'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { NFT_FILES_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'
import { CreatePersona } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/create-persona'
import { Divider } from '#pages/sell-collectibles/create-collectible/_components/divider'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SAutoComplete = styled(AutoComplete)`
  width: 33.75rem;
`

const SWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`

const SWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.sm};
  margin-top: 1rem;
`

const SIcon = styled.img`
  height: 1.25rem;
`
export const Persona = ({ setPersonaChanged }) => {
  const { t } = useTranslation('nftWizard')
  const { readOnly } = useNftCreator()
  const { control, getValues, setValue } = useFormContext()
  const { useAltStructuresEndpoint } = useFlags()
  const [isPersonaModalOpen, setIsPersonaModalOpen] = useState(false)

  const roles = [ACCESS_ROLES.OWNER]

  const [options, setOptions] = useState(null)

  const loadOptions = async () => {
    const result = await loadProfiles(roles, useAltStructuresEndpoint)()

    setOptions(result)

    return result
  }

  const initOptions = async () => {
    const options = await loadOptions()

    if (options?.length === 0) setIsPersonaModalOpen(true)
  }

  useEffect(() => {
    initOptions()
  }, [])

  const disableSelect = readOnly || !options?.length

  const onCreate = option => {
    setOptions([...options, option])

    setValue(NFT_FILES_STEP_FIELDS.PERSONA, option)
  }

  return (
    <>
      <StepSubHeader title={t('steps.files.createOrSelectPersona')} />

      {!readOnly && options?.length === 0 && (
        <SWarningWrapper>
          <SIcon src="assets/svg/ic-warning.svg" alt="Warning icon" />
          {t('steps.files.noPersonas')}
        </SWarningWrapper>
      )}

      <Controller
        name={NFT_FILES_STEP_FIELDS.PERSONA}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error, isDirty },
        }) => {
          setPersonaChanged(isDirty)
          return (
            <SWrapper>
              <SAutoComplete
                errorMessage={error?.value?.message}
                placeholder={t('search')}
                loadOptions={loadProfiles(roles, useAltStructuresEndpoint)}
                label={t('steps.files.persona')}
                defaultValue={getValues(NFT_FILES_STEP_FIELDS.PERSONA)}
                value={value}
                disabled={disableSelect}
                isRequired
                hasNftStyles
                onChange={onChange}
              />
              {!readOnly && (
                <CreatePersona
                  isOpen={isPersonaModalOpen}
                  setIsOpen={setIsPersonaModalOpen}
                  onCreate={onCreate}
                />
              )}
            </SWrapper>
          )
        }}
      />
      <Divider />
    </>
  )
}

Persona.propTypes = {
  setPersonaChanged: func,
}
