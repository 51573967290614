import React, { useCallback } from 'react'
import { FileAudioPlayer } from '#components/file-audio-player'
import { useServerEvent } from '#hooks/swr/useServerEvent'
import { EVENTS } from '#api/event'
import { ACCESS_ROLES } from '#constants/accessRoles'
import {
  SHARE_EXPIRATION_TYPES,
  SHARE_STATUSES,
} from '#pages/_modules/private-shares/utils/constants'
import { PromotionApi } from '#api/requests/promotion'
import { FilesApi } from '#api/requests/files'
import { SharesApi } from '#api/requests/shares'
import { NftApi } from '#api/requests/nft'
import { FILE_EXTENSIONS } from '#constants/fileExtensions'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { DigitalArtModal } from '#modules/global-audio-player-provider/modals/digital-art-modal'
import { VideoModal } from '#modules/global-audio-player-provider/modals/video-modal'
import { NotSupportedModal } from '#modules/global-audio-player-provider/modals/not-supported-modal'
import { useUser } from '#hooks/useUser'

export const GlobalAudioPlayerContainer = () => {
  const player = useGlobalAudioPlayer()
  const { user } = useUser()
  const userId = user?.id

  const onClose = useCallback(() => {
    player.setFileInfo(null)
  }, [player])

  const info = player.fileInfo ?? {}
  const {
    fileName,
    projectName,
    uri,
    suffix,
    // Vault
    projectId,
    folderId,
    profileId,
    fileId,
    // Share
    shareId,
    // NFT
    nftId,
    nftItemId,
    // Promotion
    promotionId,
  } = info

  const events = []
  if (projectId)
    events.push(
      `${EVENTS.STRUCTURE_REMOVED}.${projectId}`,
      `${EVENTS.PERMISSION_REVOKED}.${projectId}`
    )
  if (folderId)
    events.push(
      `${EVENTS.STRUCTURE_REMOVED}.${folderId}`,
      `${EVENTS.PERMISSION_REVOKED}.${folderId}`
    )
  if (profileId)
    events.push(
      `${EVENTS.STRUCTURE_REMOVED}.${profileId}`,
      `${EVENTS.PERMISSION_REVOKED}.${profileId}`
    )
  if (fileId) events.push(`${EVENTS.FILE_REMOVED}.${fileId}`)

  if (shareId)
    events.push(
      `${EVENTS.SHARE_EXPIRED}.${shareId}`,
      `${EVENTS.SHARE_UPDATED}.${shareId}`
    )

  if (nftId) events.push(`${EVENTS.NFT_DELETED}.${nftId}`)
  if (nftItemId) events.push(`${EVENTS.NFT_FILE_DELETED}.${nftItemId}`)

  const onConnected = useCallback(() => {
    const catcher = error => {
      if (
        error?.response?.status &&
        [404, 401, 403].includes(error.response.status)
      ) {
        onClose()
      }
    }

    if (fileId) {
      FilesApi.getListenLink(fileId).catch(catcher)
    } else if (shareId) {
      SharesApi.getShare(shareId)
        .then(({ data }) => {
          if (data.expirationType === SHARE_EXPIRATION_TYPES.COUNT) {
            if (data.status === SHARE_STATUSES.REVOKED) {
              onClose()
            }
          } else if (
            data.status === SHARE_STATUSES.REVOKED ||
            data.status === SHARE_STATUSES.EXPIRED
          ) {
            onClose()
          }
        })
        .catch(catcher)
    } else if (nftItemId) {
      NftApi.getItemLink(nftItemId, 'file').catch(catcher)
    } else if (promotionId) {
      PromotionApi.getListenLink(promotionId).catch(catcher)
    }
  }, [fileId, nftItemId, promotionId, shareId, onClose])

  const onEvent = useCallback(
    eventData => {
      if (eventData.type === EVENTS.PERMISSION_REVOKED) {
        if (eventData.payload.targetUserId !== userId) return
        const permission = eventData.payload.accessRole
        if (fileId || promotionId) {
          if (permission !== ACCESS_ROLES.COLLABORATOR) {
            return
          }
        } else {
          onConnected()
          return
        }
      } else if (
        eventData.type === EVENTS.SHARE_UPDATED &&
        eventData.payload.status === SHARE_STATUSES.ACTIVE
      ) {
        return
      }
      onClose()
    },
    [fileId, promotionId, userId, onClose, onConnected]
  )

  useServerEvent({
    eventKey: events,
    eventAction: onEvent,
    onConnected,
  })
  // eslint-disable-next-line consistent-return
  const displayPreview = () => {
    if (FILE_EXTENSIONS.NOT_SUPPORTED.includes(suffix)) {
      return (
        <NotSupportedModal isOpened={info} suffix={suffix} onClose={onClose} />
      )
    }

    if (
      ![
        ...FILE_EXTENSIONS.DIGITAL_ART,
        ...FILE_EXTENSIONS.VIDEO,
        ...FILE_EXTENSIONS.AUDIO,
      ].includes(suffix)
    ) {
      return null
    }

    if (FILE_EXTENSIONS.DIGITAL_ART.includes(suffix)) {
      return <DigitalArtModal src={uri} isOpened={info} onClose={onClose} />
    }

    if (FILE_EXTENSIONS.VIDEO.includes(suffix)) {
      return <VideoModal src={uri} isOpened={info} onClose={onClose} />
    }

    if (FILE_EXTENSIONS.AUDIO.includes(suffix)) {
      return (
        <FileAudioPlayer
          bottomOffset={player.offset}
          fileName={fileName}
          projectName={projectName}
          uri={uri}
          ready
          onClose={onClose}
        />
      )
    }
  }

  return !!player.fileInfo && displayPreview()
}
