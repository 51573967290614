import React from 'react'
import { makeStyles } from '@material-ui/core'
import { string } from 'prop-types'
import cn from 'classnames'
import { useFormContext, Controller } from 'react-hook-form'
import { OPPOSITE_VALUES } from '#pages/mastering/wizard/steps/options-step/utils/constants'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => {
  return {
    hexagon: {
      cursor: 'pointer',
      transition: 'all .2s linear',
      stroke: theme.palette.color.primary,
      strokeWidth: 2,
      fill: hexToRgba(theme.palette.color.primary, 0.15),
      '&:hover': {
        fill: hexToRgba(theme.palette.color.primary, 0.5),
      },
    },
    checked: {
      fill: hexToRgba(theme.palette.color.primary, 0.5),
    },
  }
})

export const Hexagon = ({ d, value }) => {
  const classes = useStyles()
  const { getValues, setValue, control } = useFormContext()

  const onChange = () => {
    const values = getValues('profile')
    if (values?.includes(OPPOSITE_VALUES[value])) {
      const filteredValues = values.filter(
        val => val !== OPPOSITE_VALUES[value]
      )
      setValue('profile', [value, ...filteredValues])
    } else if (values.includes(value)) {
      const filteredValues = values.filter(val => val !== value)
      setValue('profile', filteredValues)
    } else {
      setValue('profile', [value, ...values])
    }
  }

  const valueChecked = getValues('profile')?.includes(value)

  return (
    <Controller
      control={control}
      name="profile"
      render={() => (
        <path
          className={cn(classes.hexagon, {
            [classes.checked]: valueChecked,
          })}
          d={d}
          onClick={onChange}
        />
      )}
    />
  )
}

Hexagon.propTypes = {
  d: string.isRequired,
  value: string.isRequired,
}
