export const hexToRgba = (hex, transparency = 1) =>
  `rgba(${hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .slice(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .toString()}, ${transparency})`
