import React, { useEffect, useState } from 'react'
import { number, func } from 'prop-types'
import { useParams } from '@reach/router'

import { PrivateShares } from '#pages/_modules/private-shares'
import { useOwnerShares } from '#hooks/swr/useShares'
import { getSortingQuery } from '#utils/getSortingQuery'

export const OwnerSharesContainer = ({
  deleteShare,
  setAreSharesLoading,
  setFileToPlay,
  getListenLink,
  ...props
}) => {
  const { page, rowsPerPage } = props
  const { projectId } = useParams()
  const [shouldSortAscending, setShouldSortAscending] = useState(true)
  const [sortBy, setSortBy] = useState('')

  const query = `?page=${page}&limit=${rowsPerPage}${getSortingQuery(
    sortBy,
    shouldSortAscending
  )}`

  const { shares, isLoading, revalidate } = useOwnerShares(projectId, query)

  useEffect(() => {
    setAreSharesLoading(isLoading)
  }, [isLoading, props, setAreSharesLoading])

  const onClear = async id => {
    await deleteShare(id)
    revalidate()
  }

  const onPlay = async share => {
    const {
      data: { link },
    } = await getListenLink(share.fileId)
    setFileToPlay(link, share)
  }

  return (
    <>
      {shares?.data && (
        <PrivateShares
          isOwner
          {...{
            shares: shares.data,
            count: shares.count,
            onClear,
            onPlay,
            shouldSortAscending,
            setShouldSortAscending,
            sortBy,
            setSortBy,
            ...props,
          }}
        />
      )}
    </>
  )
}

OwnerSharesContainer.propTypes = {
  page: number.isRequired,
  rowsPerPage: number.isRequired,
  setAreSharesLoading: func.isRequired,
  deleteShare: func.isRequired,
  setFileToPlay: func.isRequired,
  getListenLink: func.isRequired,
}
