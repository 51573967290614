import React, { useState } from 'react'
import { arrayOf, string } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { MetadataContainer } from '#pages/vault/song/tabs/metadata/containers/metadata-container'
import { metadataTiles } from '#pages/vault/song/tabs/metadata/utils/constants'
import { LoseChangesWarningModal } from '#components/lose-changes-warning-modal'
import { useHasUnsavedChanges } from '../../../../context/hasUnsavedChanges'
import { hexToRgba } from '#utils/hexToRgba'

const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1050px;
  border-left: 1px solid
    ${({ theme: { colors } }) => hexToRgba(colors.default, 0.2)};
  border-right: 1px solid
    ${({ theme: { colors } }) => hexToRgba(colors.default, 0.2)};
  margin: 0 auto;
  min-height: calc(100vh - 182px);
`

const STilesContainer = styled.div`
  gap: 30px;
  padding: 80px 15px 0 15px;
  display: grid;
  grid-template-columns: repeat(2, 250px);
  grid-template-rows: repeat(2, 180px);
`

const STile = styled.div`
  width: 250px;
  height: 180px;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`

const SIcon = styled.img`
  margin-top: 35px;
`

const SText = styled.span`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 35px;
`

export const ChooseMetadataOption = ({ accessRoles }) => {
  const { t } = useTranslation('vault')
  const [isMetadataChosen, setIsMetadataChosen] = useState(false)
  const [chosenMetadataOption, setChosenMetadataOption] = useState('')
  const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = useState(false)

  const {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    whereToNavigate,
    setWhereToNavigate,
  } = useHasUnsavedChanges()

  const handleOpenMetadataOption = metadataOption => {
    setIsMetadataChosen(true)
    setChosenMetadataOption(metadataOption)
  }

  const handleSwitchingTabs = metadataOption => {
    if (hasUnsavedChanges) {
      setIsSaveWarningModalOpen(true)
      setWhereToNavigate(metadataOption)
    } else {
      setChosenMetadataOption(metadataOption)
    }
  }
  // eslint-disable-next-line no-shadow
  const handleWarningModalNavigation = whereToNavigate => {
    setWhereToNavigate(null)
    setHasUnsavedChanges(false)
    setChosenMetadataOption(whereToNavigate)
    setIsSaveWarningModalOpen(false)
  }

  return (
    <SWrapper>
      {isMetadataChosen ? (
        <MetadataContainer
          {...{
            chosenMetadataOption,
            setChosenMetadataOption: handleSwitchingTabs,
            accessRoles,
            setHasUnsavedChanges,
            hasUnsavedChanges,
          }}
        />
      ) : (
        <STilesContainer>
          {metadataTiles(accessRoles).map(
            ({ title, iconUrl, metadataOption }) => {
              return (
                <STile
                  key={title}
                  onClick={() => handleOpenMetadataOption(metadataOption)}
                >
                  <SIcon src={iconUrl} alt="" />
                  <SText>{t(title)}</SText>
                </STile>
              )
            }
          )}
        </STilesContainer>
      )}
      <LoseChangesWarningModal
        isSaveWarningModalOpen={isSaveWarningModalOpen}
        whereToNavigate={whereToNavigate}
        setIsSaveWarningModalOpen={setIsSaveWarningModalOpen}
        handleWarningModalNavigation={handleWarningModalNavigation}
      />
    </SWrapper>
  )
}

ChooseMetadataOption.propTypes = {
  accessRoles: arrayOf(string).isRequired,
}
