import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { StepWrapper } from '#pages/sell-collectibles/create-collectible/_components/step-wrapper'
import styled from 'styled-components'
import { BottomBar } from '#pages/sell-collectibles/create-collectible/_components/bottom-bar'
import { NftSummary } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/nft-summary'
import { IdentityVerification } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/identity-verification'
import { RightsVerification } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/rights-verification'
import {
  useIdentityVerification,
  useNftData,
} from '#pages/sell-collectibles/create-collectible/_hooks'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { isNftSubmitted } from '#pages/sell-collectibles/_utils'
import { NFT_STATUS } from '#pages/sell-collectibles/_utils/constants'
import { ErrorMessage } from '#components/error-message'
import { DeniedInfo } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/denied-info'
import {
  getStatusColor,
  getStatusLabel,
} from '#pages/sell-collectibles/create-collectible/_utils'
import { Success } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/success'
import { WalletModal } from '#pages/_components/create-collectible-button/components/wallet-modal'

const SContent = styled.div`
  padding: 0 2.5rem 2.5rem;
`

const SStatusWrapper = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  position: absolute;
  top: 3rem;
  right: 2.5rem;
`

const SStatus = styled.span`
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({ statusColor }) => statusColor};
`

export const ReviewStepContent = () => {
  const { t } = useTranslation('nftWizard')
  const [identityVerificationModal, setIdentityVerificationModal] =
    useState(false)
  const [rightsVerificationModal, setRightsVerificationModal] = useState(false)
  const [connectWalletModal, setConnectWalletModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [submitInProgress, setSubmitInProgress] = useState(false)

  const { personaVerified, isCompleted, ...verificationProps } =
    useIdentityVerification()

  const { submitForReview, assignWallet, error, wallet } = useNftData()
  const [submitted, setSubmitted] = useState(false)

  const {
    savedNft,
    readOnly,
    disableNavigation,
    setProcessCompleted,
    setCompletedStep,
    setDisableNavigation,
  } = useNftCreator()

  useEffect(() => {
    if (savedNft) {
      setSubmitted(isNftSubmitted(savedNft.status))
    }
  }, [savedNft])

  useEffect(() => {
    setDisableNavigation(
      identityVerificationModal ||
        submitted ||
        savedNft.status === NFT_STATUS.READY_FOR_MINTING
    )
  }, [submitted, savedNft, identityVerificationModal])

  const saveNft = async () => {
    setRightsVerificationModal(false)
    setSubmitInProgress(true)
    try {
      if (wallet && !savedNft.wallet) {
        await assignWallet()
      }

      await submitForReview(() => {
        setSubmitted(true)
        setProcessCompleted(true)
        setCompletedStep(2)
        setSuccessModal(true)
      })
    } finally {
      setSubmitInProgress(false)
    }
  }

  const onSubmitForReview = async () => {
    if (!wallet) setConnectWalletModal(true)
    else if (personaVerified) await setRightsVerificationModal(true)
    else setIdentityVerificationModal(true)
  }

  useEffect(() => {
    if (isCompleted) {
      setIdentityVerificationModal(false)
      setRightsVerificationModal(true)
    }
  }, [isCompleted])

  return (
    <StepWrapper
      title={t('steps.review.title')}
      step={3}
      loading={submitInProgress}
    >
      <>
        <SStatusWrapper>
          <Trans
            i18nKey="steps.review.status"
            ns="nftWizard"
            values={{ status: getStatusLabel(savedNft?.computedStatus) }}
          >
            <SStatus statusColor={getStatusColor(savedNft?.computedStatus)} />
          </Trans>
        </SStatusWrapper>
        <SContent>
          {error && <ErrorMessage errorMessage={t('error')} />}
          {savedNft?.status === NFT_STATUS.REVIEW_DENIED && <DeniedInfo />}
          <NftSummary />
          {identityVerificationModal ? (
            <IdentityVerification {...verificationProps} />
          ) : (
            !disableNavigation && (
              <BottomBar
                disabled={submitInProgress}
                disabledBack={submitInProgress}
                hideNextBtn={readOnly}
                nextBtnText={
                  submitInProgress
                    ? t('steps.review.submitInProgress')
                    : t('steps.review.submitForApproval')
                }
                onNextClick={onSubmitForReview}
              />
            )
          )}
          {rightsVerificationModal && (
            <RightsVerification
              isOpen={rightsVerificationModal}
              setIsOpen={setRightsVerificationModal}
              onContinue={saveNft}
            />
          )}
          <WalletModal
            setIsOpen={setConnectWalletModal}
            isOpen={connectWalletModal}
          />
          <Success isOpen={successModal} setIsOpen={setSuccessModal} />
        </SContent>
      </>
    </StepWrapper>
  )
}
