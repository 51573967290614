import React, { forwardRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { bool, func, string } from 'prop-types'

const useStyles = isIndeterminate =>
  makeStyles(theme => ({
    itemWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    checkbox: {
      appearance: 'none',
      width: 20,
      height: 20,
      background: theme.palette.color.black,
      border: `1px solid ${theme.palette.color.darkGrey66}`,
      borderRadius: '2px',
      color: theme.palette.color.white,
      position: 'relative',
      flexShrink: 0,
      '&:checked': !isIndeterminate && {
        '&::before': {
          content: '""',
          width: 7,
          height: 15,
          borderBottom: `3px solid ${theme.palette.color.white}`,
          borderRight: `3px solid ${theme.palette.color.white}`,
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(45deg)',
        },
      },
      '&::before': isIndeterminate && {
        content: '""',
        width: 12,
        height: 12,
        background: theme.palette.color.white,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      width: '100%',
      cursor: 'pointer',
    },
    icon: {
      width: 34,
      marginRight: 25,
    },
  }))()

export const CheckBoxItem = forwardRef(
  (
    {
      name,
      label,
      icon,
      register,
      value,
      checked,
      onChange,
      isIndeterminate,
      width,
    },
    ref
  ) => {
    const classes = useStyles(isIndeterminate)

    return (
      <div className={classes.itemWrapper} style={{ width }}>
        {label && (
          <label htmlFor={value} className={classes.label}>
            {icon && <img src={icon} className={classes.icon} alt={label} />}
            {label}
          </label>
        )}
        <input
          type="checkbox"
          className={classes.checkbox}
          id={value}
          name={name}
          {...(register(name) || ref)}
          value={value}
          checked={checked || false}
          onChange={onChange}
        />
      </div>
    )
  }
)

CheckBoxItem.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  register: func,
  label: string,
  icon: string,
  value: string,
  checked: bool,
  isIndeterminate: bool,
  width: string,
}

CheckBoxItem.defaultProps = {
  register: null,
  label: '',
  icon: '',
  value: '',
  checked: false,
  isIndeterminate: false,
  width: '100%',
}
