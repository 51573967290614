import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { ChooseProfileStepContainer } from '#pages/_modules/choose-profile-step/containers/choose-profile-step-container'
import { CreateProfileRedirect } from '#components/create-profile-redirect'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useWizard } from '#modules/forms/form-wizard/context'
import { useQuery } from '#hooks/useQuery'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { useError } from '#hooks/useError'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      width: '100%',
      height: 'calc(100vh - 260px)',
    },
  }
})

export const SelectProfile = () => {
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const classes = useStyles()
  const { incrementStep, setStoredFormState } = useWizard()
  const distroType = useQuery('type')
  const { error, setResponseError, clearError } = useError()
  const { actions, loading } = useWithAsyncAction({ setUp: DistroApi.setUp })

  const onSubmit = async values => {
    try {
      const response = await actions.setUp({
        profileId: values.parentId,
        class: distroType,
      })

      setStoredFormState({
        distroId: response?.data.id,
        parentId: values.parentId,
        parentFilesCount: values.parentFilesCount,
      })

      incrementStep()
      await navigate(
        `${NAVIGATION_PATHS.DISTRO_CREATE_NEW}?distroId=${response?.data.id}`,
        { replace: true }
      )
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <div className={classes.wrapper}>
      <ChooseProfileStepContainer
        roles={[ACCESS_ROLES.OWNER, ACCESS_ROLES.COLLABORATOR]}
        onStepSubmit={onSubmit}
      >
        <CreateProfileRedirect
          text={distroTranslation.t('ifYouDoNotSeeTheProfile')}
          linkText={t('createNewProfile')}
        />
      </ChooseProfileStepContainer>
      <CommonLoadingOverlay
        dialogOpen={loading.setUp}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </div>
  )
}
