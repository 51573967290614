import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const useUnreadNotifications = () => {
  const { data, error, ...rest } = useSWR(ENDPOINTS.NOTIFICATIONS_UNREAD)
  return {
    unreadNotifications: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
