import React, { useState } from 'react'
import { navigate, Link } from '@reach/router'

import { Notifications } from '#modules/notifications'
import { ConnectChat } from '#modules/connect-chat'
import { NAVIGATION_PATHS } from '#routes/routes'
import { SideNavigation } from './components/side-navigation'
import { NavigationButton } from './components/navigation-button'
import { getNavItems } from './utils/constants'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { hexToRgba } from '#utils/hexToRgba'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import { useUser } from '#hooks/useUser'
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SMenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme: { colors } }) => colors.default};
  height: 55px;
  width: 55px;
  min-width: unset;
  padding: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    display: flex;
  }
`

const SAppBar = styled.nav`
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0
    ${({ theme: { colors } }) => hexToRgba(colors.black, 0.32)};
  border: solid 1px ${({ theme: { colors } }) => colors.darkGrey};
  border-bottom: solid 2px ${({ theme: { colors } }) => colors.primary};
  background-color: ${({ theme: { colors } }) => colors.charcoal};
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 !important;
  z-index: 10000;
`

const SNavbarInner = styled.div`
  display: flex;
  height: 3.75rem;
  width: 100%;
  padding: 0 2rem;
`

const SNavBarLogo = styled.img`
  height: 1.4rem;
  margin-top: 0.5rem;
`

const SNavSectionLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
`

const SNavSectionCenter = styled.div`
  margin-left: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    visibility: hidden;
  }
`

const SNavSectionRight = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-shrink: 0;
  flex-grow: 0;
`

const SAvatarContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${({ avatar }) =>
    avatar
      ? ` url(${avatar}) center center / cover `
      : ` url(${DEFAULT_AVATAR}) `};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  cursor: pointer;
  padding: 12px;
`

const SIconGroup = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.md}) {
    visibility: hidden;
  }
`

export const TGNavBar = () => {
  const [sideNavOpen, setSideNavOpen] = useState(false)
  const {
    enableWalletConnect,
    enableBlog,
    enableConnect,
    enablePersona,
    enableGettingStarted,
  } = useFlags()
  const { user } = useUser()

  const avatar =
    user?.profileImageUrl && buildCloudFrontImgUrl(user.profileImageUrl)

  const toggleDrawer = () => {
    setSideNavOpen(!sideNavOpen)
  }

  const handleNavigate = () => {
    navigate(NAVIGATION_PATHS.PROFILE)
  }

  return (
    <SAppBar>
      {sideNavOpen && (
        <SideNavigation side="left" avatarUri={avatar} onClose={toggleDrawer} />
      )}
      <SNavbarInner>
        <SNavSectionLeft>
          <SMenuButton id="navMenuButton" onClick={toggleDrawer}>
            <img src="/assets/svg/ico-menu.svg" alt="Hamburger menu" />
          </SMenuButton>
          <SIconGroup>
            <Link to={NAVIGATION_PATHS.SELL_COLLECTIBLES}>
              <SNavBarLogo
                src="/assets/svg/ic-tunego-logo.svg"
                alt="TuneGO Logo"
              />
            </Link>
          </SIconGroup>
        </SNavSectionLeft>
        <SNavSectionCenter>
          {getNavItems({
            enableWalletConnect,
            enableBlog,
            enablePersona,
            enableGettingStarted,
          }).map(item => (
            <NavigationButton {...item} key={item.title} />
          ))}
        </SNavSectionCenter>
        <SNavSectionRight>
          {enableConnect && <ConnectChat />}
          <Notifications />
          <SAvatarContainer
            avatar={avatar}
            id="myAvatar"
            onClick={handleNavigate}
          />
        </SNavSectionRight>
      </SNavbarInner>
    </SAppBar>
  )
}
