import React, { useState, useEffect } from 'react'
import { string, arrayOf, shape, bool } from 'prop-types'
import { Typography } from '@material-ui/core'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { hexToRgba } from '#utils/hexToRgba'
import { formatPrice } from '#utils/formatPrice'
import { paymentCardInfo } from '#pages/profile/utils/helpers'
import { PAYMENT_STATUSES } from '#constants/paymentStatuses'
import { CHARGE_STATUSES } from '#constants/chargeStatuses'
import { Summary } from '#modules/order-summary/components/summary'
import { Order } from '#modules/order-summary/components/order'

const useStyles = maxWidth =>
  makeStyles(theme => ({
    summaryWrapper: {
      maxWidth,
      width: '100%',
    },
    wrapper: {
      border: `1px solid ${hexToRgba(theme.palette.color.default, 0.05)}`,
    },
    orderNumberWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    orderHeader: {
      fontSize: 21,
      textTransform: 'uppercase',
      marginRight: 'auto',
      fontFamily: 'AvenirNextLTPro-Bold, Arial, sans-serif',
    },
    orderNumber: {
      maxWidth: 280,
      marginLeft: 8,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
    },
    row: {
      height: 60,
      lineHeight: '60px',
      padding: '0 24px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
      borderTop: '0',
      borderBottom: '0',
    },
    orderRow: {
      background: hexToRgba(theme.palette.color.primary, 0.5),
      border: `1px solid ${hexToRgba(theme.palette.color.primary, 0.5)}`,
    },
    priceRow: {
      fontSize: 21,
      textTransform: 'uppercase',
      fontFamily: 'AvenirNextLTPro-Bold, Arial, sans-serif',
    },
    fontSize14: {
      fontSize: 14,
    },
    fontWeightBold: {
      fontWeight: 'bold',
    },
    checkoutLogos: {
      marginTop: 32,
    },
  }))()

export const OrderSummary = ({
  orderData: partialOrderData,
  proratedData,
  price,
  showPriceRowOnly,
  customPriceRowTitle,
  isProrated,
  showCard,
  showOrderNumber,
  payment,
  freePaymentStatus,
  maxWidth,
  showHorizontally,
}) => {
  const classes = useStyles(maxWidth)
  const { t } = useTranslation()

  const [isConfirmation, setIsConfirmation] = useState(false)

  useEffect(() => {
    if (freePaymentStatus) {
      setIsConfirmation(true)
    }
  }, [freePaymentStatus])

  useEffect(() => {
    if (payment?.status === PAYMENT_STATUSES.COMPLETED) {
      setIsConfirmation(true)
    }
  }, [payment])

  const showCardRow = showCard && isConfirmation

  const cardRow = () => {
    const card = payment?.charges?.find(
      ({ status }) => status === CHARGE_STATUSES.SUCCEEDED
    )?.card

    return {
      rowTitle: t('payment'),
      rowValue: paymentCardInfo(
        payment?.createdAt,
        card?.brand,
        card?.last4Digits
      ),
    }
  }

  const summaryData = [
    ...(isProrated ? proratedData : []),
    {
      rowTitle: customPriceRowTitle || t('price'),
      rowValue: formatPrice(price),
    },
  ]

  const orderData = [
    ...(partialOrderData ?? []),
    ...(showCardRow ? [cardRow()] : []),
  ]

  return (
    <div className={classes.summaryWrapper}>
      <div className={classes.wrapper}>
        {!showPriceRowOnly && (
          <>
            <div className={cn(classes.row, classes.orderRow)}>
              <Typography
                className={cn(classes.orderHeader, classes.fontWeightBold)}
              >
                {t('orderSummary')}
              </Typography>
              {isConfirmation && showOrderNumber && !freePaymentStatus && (
                <div className={classes.orderNumberWrapper}>
                  <Typography className={classes.fontSize14}>
                    {t('orderNumber')}
                  </Typography>
                  <div
                    className={cn(
                      classes.orderNumber,
                      classes.fontSize14,
                      classes.fontWeightBold
                    )}
                  >
                    {payment.externalPaymentId}
                  </div>
                </div>
              )}
            </div>
            <Order {...{ orderData, showHorizontally }} />
          </>
        )}
        <Summary {...{ summaryData }} />
      </div>
      {!isConfirmation && (
        <img
          className={classes.checkoutLogos}
          width="200px"
          src="/assets/images/checkout-logos.png"
          alt=""
        />
      )}
    </div>
  )
}

OrderSummary.propTypes = {
  payment: shape({}).isRequired,
  orderData: arrayOf(shape({ rowTitle: string, rowValue: string })),
  proratedData: arrayOf(shape({ rowTitle: string, rowValue: string })),
  price: string.isRequired,
  showOrderNumber: bool,
  showCard: bool,
  showPriceRowOnly: bool,
  customPriceRowTitle: string,
  isProrated: bool,
  maxWidth: string,
  freePaymentStatus: bool,
  showHorizontally: bool,
}

OrderSummary.defaultProps = {
  orderData: [{ rowTitle: '', rowValue: '' }],
  showOrderNumber: false,
  proratedData: null,
  showPriceRowOnly: false,
  customPriceRowTitle: null,
  isProrated: false,
  showCard: false,
  maxWidth: 'none',
  freePaymentStatus: false,
  showHorizontally: false,
}
