import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Logo } from './components/logo'
import { Description } from './components/description'
import { PrivateLink } from './components/private-link'
import { BottomIcons } from './components/bottom-icons'
import { NotificationsApi } from '#api/requests/notifications'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { PageWrapper } from '#modules/page-wrapper'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'

const useStyles = makeStyles(() => ({
  container: {
    background: 'url("/assets/images/connect-bg.png") no-repeat center fixed',
    backgroundSize: 'cover',
    minHeight: 'calc(100vh - 69px)',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
    textAlign: 'center',
    marginLeft: '15%',
  },
}))

export const LandingPage = () => {
  const classes = useStyles()
  const { t } = useTranslation('connect')
  const [sendInviteError, setSendInviteError] = useState('')
  const { actions, loading, errors } = useWithAsyncAction({
    sendInvite: NotificationsApi.sendAppInvite,
  })
  const sendInviteLink = async phoneNumber => {
    await actions.sendInvite(phoneNumber)
  }
  useEffect(() => {
    if (errors.sendInvite.message) {
      setSendInviteError(errors.sendInvite.message)
    }
  }, [errors])

  return (
    <PageWrapper isFullWidth showNavBar>
      <CommonLoadingOverlay
        message={t('sendingInviteLink')}
        dialogOpen={loading.sendInvite}
        error={sendInviteError}
        onErrorBackdropClick={() => setSendInviteError('')}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Logo />
          <Description />
          <PrivateLink sendInviteLink={sendInviteLink} />
          <BottomIcons />
        </div>
      </div>
    </PageWrapper>
  )
}
