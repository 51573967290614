import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = width =>
  makeStyles(theme => ({
    previewWrapper: {
      height: width,
      width,
      marginBottom: 10,
      position: 'relative',
    },
    image: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',

      '&:focus, &:active, &:visited': {
        outline: 'none',
      },
    },
    edit: {
      width: '100%',
      height: 60,
      fontSize: 18,
      lineHeight: '60px',
      fontFamily: theme.typography.bold,
      textAlign: 'center',
      textTransform: 'uppercase',
      backgroundColor: hexToRgba(theme.palette.color.black, 0.7),
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
  }))()

export const ArtworkPreview = ({ link, width }) => {
  const classes = useStyles(width)
  const { t } = useTranslation()

  return (
    <div className={classes.previewWrapper}>
      <img src={link} className={classes.image} alt="" />
      <div className={classes.edit}>{t('edit')}</div>
    </div>
  )
}

ArtworkPreview.propTypes = {
  link: string.isRequired,
  width: number.isRequired,
}
