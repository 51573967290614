import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { string } from 'prop-types'

const useStyles = makeStyles(theme => ({
  title: {
    margin: '40px 0',
    fontSize: 28,
    fontFamily: theme.typography.h2.fontFamily,
  },
}))

export const ArtistIdText = ({ text }) => {
  const { title } = useStyles()
  const { t } = useTranslation('distro')

  return <p className={title}>{t(`${text}`)}</p>
}

ArtistIdText.propTypes = {
  text: string.isRequired,
}
