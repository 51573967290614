import React, { useState, useEffect } from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { UsersApi } from '#api/requests/users'
import { UserDetails } from '#pages/profile/tabs/user-details/components/user-details'
import { ImageCropper } from '#components/image-cropper'
import { CROP_SHAPE_TYPES } from '#components/image-cropper/constants'
import { useUser } from '#hooks/useUser'
import styled from 'styled-components'

const SErrorWrapper = styled.p`
  margin-top: 0.5rem;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

export const UserDetailsContainer = ({
  handleSave,
  setLoading,
  setMessage,
}) => {
  const { t } = useTranslation('validation')
  const [preview, setPreview] = useState(null)
  const [imageToCrop, setImageToCrop] = useState(null)
  const [placeholder] = useState(null)
  const { user } = useUser()
  const {
    firstName,
    lastName,
    profileImageUrl,
    cityName,
    countryCode,
    placeName,
    lat,
    lng,
  } = user || {}
  const location = { cityName, countryCode, placeName, lat, lng }
  const [imageError, setImageError] = useState('')
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState({})

  const openCropperModal = () => setIsCropperModalOpen(true)
  const closeCropperModal = () => setIsCropperModalOpen(false)

  useEffect(() => {
    setPreview(profileImageUrl)
  }, [profileImageUrl])

  const triggerMutate = () => {
    mutate(ENDPOINTS.USERS_ME)
  }

  const updateProfileImage = async file => {
    setLoading(true)
    await UsersApi.manageUploadAvatar(file)
    triggerMutate()
    setImageError('')
    setLoading(false)
  }

  const onDropRejected = ([rejectedFile]) => {
    const { errors } = rejectedFile

    setImageError(errors?.[0]?.message || t('requestNoDescription'))
  }

  const onDrop = acceptedFiles => {
    try {
      setAcceptedFile(acceptedFiles[0])
      setImageToCrop(URL.createObjectURL(acceptedFiles[0]))
      openCropperModal()
    } catch (err) {
      setLoading(false)
      if (err.response?.data.message) {
        setMessage(err.response?.data.message)
      } else {
        setMessage(t('requestNoDescription'))
      }
    }
  }

  const handleSubmit = async ({ location: userLocation, ...rest }) => {
    const details = {
      ...userLocation,
      ...rest,
    }
    await handleSave(details)
    triggerMutate()
  }

  const errorMessage = imageError && <SErrorWrapper>{imageError}</SErrorWrapper>
  return (
    <>
      <UserDetails
        firstName={firstName}
        lastName={lastName}
        location={location}
        errorMessage={errorMessage}
        preview={preview}
        handleSave={handleSubmit}
        placeholder={placeholder}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
      />
      <ImageCropper
        cropShape={CROP_SHAPE_TYPES.ROUND}
        isOpen={isCropperModalOpen}
        updateImage={updateProfileImage}
        acceptedFile={acceptedFile}
        setPreview={setPreview}
        image={imageToCrop}
        onClose={closeCropperModal}
      />
    </>
  )
}

UserDetailsContainer.propTypes = {
  handleSave: func.isRequired,
  setLoading: func.isRequired,
  setMessage: func.isRequired,
}
