import React from 'react'
import { any, bool, string } from 'prop-types'
import styled from 'styled-components'

const SBaseLoader = styled.div`
  text-align: center;
  margin: 0 auto;
`

export const BaseLoader = ({ isLoading, text, className }) => {
  if (!isLoading) {
    return null
  }

  return (
    <SBaseLoader className={className}>
      <img
        src="/assets/gifs/loading.gif"
        alt="Loading"
        height={120}
        title="Loading"
      />
      <p>{text}</p>
    </SBaseLoader>
  )
}

BaseLoader.propTypes = {
  isLoading: bool.isRequired,
  text: string,
  className: any,
}

BaseLoader.defaultProps = {
  text: 'loading',
  className: '',
}
