import React from 'react'
import { AudioPlayer } from '@tunego/tunego-ui-components'
import { useSong } from '#pages/sell-collectibles/create-collectible/_hooks/use-song'
import { useArtwork } from '#pages/sell-collectibles/create-collectible/_hooks/use-artwork'
import styled from 'styled-components'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { NFT_FILES_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SAudioPlayer = styled(AudioPlayer)`
  box-sizing: border-box;
  width: 31.25rem;
  height: 31.25rem;
  border: none;

  button {
    border: none;
  }

  input {
    margin: 0;
  }
`

export const Player = () => {
  const { filesStepMethods } = useNftCreator()

  const values = filesStepMethods.getValues()

  const song = useSong(values[NFT_FILES_STEP_FIELDS.MUSIC])
  const artwork = useArtwork(values[NFT_FILES_STEP_FIELDS.ARTWORK])

  return <SAudioPlayer src={song} imgSrc={artwork} muted autoplay />
}
