import React from 'react'
import styled from 'styled-components'
import { Button } from '#components/button'
import { string, func } from 'prop-types'
import { MainHeader } from '#pages/_components/section-headers/main-header'
import { SecondaryHeader } from '#pages/_components/section-headers/secondary-header'

const SButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 1rem;
`

const SSecondaryHeader = styled(SecondaryHeader)`
  margin: 0;
`

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const BasicSection = ({ title, subtitle, text, btnText, onClick }) => (
  <SContent>
    <MainHeader>{title}</MainHeader>
    <SSecondaryHeader>{subtitle}</SSecondaryHeader>
    <p>{text}</p>
    <SButton onClick={onClick}>{btnText}</SButton>
  </SContent>
)

BasicSection.propTypes = {
  title: string.isRequired,
  subtitle: string.isRequired,
  text: string.isRequired,
  btnText: string.isRequired,
  onClick: func.isRequired,
}
