import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { TopBar } from '#pages/distro/past-releases/components/top-bar'
import { ListHeader } from '#pages/distro/past-releases/components/list-header'
import { ListContent } from '#pages/distro/past-releases/components/list-content'
import { usePastReleases } from '#hooks/swr/useDistro'
import { Breadcrumbs } from '#components/breadcrumbs'
import { BaseLoader } from '#components/loaders/base-loader'
import { PageWrapper } from '#modules/page-wrapper'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { getBreadcrumbs } from '#pages/distro/past-releases/utils/helpers'

export const PastReleases = () => {
  const location = useLocation()
  const { t } = useTranslation('distro')
  const defaultProfile = { name: t('allProfiles'), id: '' }
  const [profile, setProfile] = useState(
    location.state?.profile || defaultProfile
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const breadcrumbs = getBreadcrumbs(t, profile)
  const query = `?page=${page}&limit=${rowsPerPage}${
    profile.id ? `&profileId=${profile.id}` : ''
  }`

  const { pastReleases, isLoading } = usePastReleases(query)

  return (
    <PageWrapper showNavBar isFullWidth>
      <Breadcrumbs {...{ breadcrumbs }} />
      <ColumnWrapper>
        <TopBar setProfile={setProfile} profile={profile} />
        <ListHeader />
        <BaseLoader isLoading={isLoading} text={t('loadingMoreReleases')} />
        {pastReleases && (
          <ListContent
            {...{
              pastReleases: pastReleases.data,
              count: pastReleases.count,
              page,
              setPage,
              rowsPerPage,
              setRowsPerPage,
            }}
          />
        )}
      </ColumnWrapper>
    </PageWrapper>
  )
}
