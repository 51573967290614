import React from 'react'
import styled from 'styled-components'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal } from '#components/modal'

const SModalTitle = styled.h2`
  font-size: 30px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  padding-bottom: 30px;
  margin: 0;
  white-space: pre-line;
`

const SModalSubTitle = styled.div`
  font-size: 16px;
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  padding-bottom: 30px;
`

const SClickable = styled.div`
  color: ${({ theme: { colors } }) => colors.primary};
  font-size: 18px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  padding-bottom: 30px;
  text-decoration: underline;
  cursor: pointer;
`

export const LoseChangesWarningModal = props => {
  const { t } = useTranslation()
  const {
    isSaveWarningModalOpen,
    setIsSaveWarningModalOpen,
    handleWarningModalNavigation,
    whereToNavigate,
  } = props

  return (
    <Modal
      isOpen={isSaveWarningModalOpen}
      setIsOpen={setIsSaveWarningModalOpen}
    >
      <>
        <SModalTitle>{t('saveWarningModal.warningYouHaveNot')}</SModalTitle>
        <SModalSubTitle>
          {t('saveWarningModal.ifYouContinueNow')}
        </SModalSubTitle>
        <SClickable
          onClick={() => {
            setIsSaveWarningModalOpen(false)
          }}
        >
          {' '}
          &larr; {t('saveWarningModal.goBackAndSave')}
        </SClickable>
        <SClickable
          onClick={() => {
            handleWarningModalNavigation(whereToNavigate)
          }}
        >
          {t('saveWarningModal.continueAndLose')} &rarr;
        </SClickable>
      </>
    </Modal>
  )
}

LoseChangesWarningModal.propTypes = {
  isSaveWarningModalOpen: bool.isRequired,
  setIsSaveWarningModalOpen: func.isRequired,
  handleWarningModalNavigation: func.isRequired,
  whereToNavigate: string,
}

LoseChangesWarningModal.defaultProps = {
  whereToNavigate: null,
}
