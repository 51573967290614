import React from 'react'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { StepsContainer } from '#pages/distro/wizard/containers/steps-container'
import { PageWrapper } from '#modules/page-wrapper'
import { NAVIGATION_PATHS } from '#routes/routes'
import { DEFAULT_FORM_STATE } from '#pages/distro/wizard/utils/constants'
import { getFormNavigation } from '#pages/distro/wizard/utils/helpers'
import { WizardContainer } from '#modules/forms/form-wizard/containers/wizard-container'

const makeCustomStyles = () => {
  return {
    maxWidth: 1200,
  }
}

export const DistroCreateNew = () => {
  const { t } = useTranslation('distro')
  const params = useParams()

  return (
    <PageWrapper
      showGenericHeader={!Object.keys(params).length}
      makeCustomStyles={makeCustomStyles}
      withoutBorder
    >
      <WizardContainer
        navigationItems={getFormNavigation(t)}
        defaultState={DEFAULT_FORM_STATE}
        navigationExitString={NAVIGATION_PATHS.DISTRO}
      >
        <StepsContainer />
      </WizardContainer>
    </PageWrapper>
  )
}
