import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { hexToRgba } from '#utils/hexToRgba'
import { useWizard } from '#modules/forms/form-wizard/context'
import { trackNameGenreValidationSchema } from '#pages/promotion/_utils/validation'
import { PromotionInstructions } from '#pages/promotion/wizard/steps/select-song/components/promotion-instructions'
import { ChooseGenre } from '#pages/promotion/wizard/steps/select-song/components/choose-genre'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'
import { SelectedFileToast } from '#components/selected-file-toast'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { ProfileFilesTree } from '#modules/files-tree/profile-files-tree'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 6fr)',
  },
  fileTreeWrapper: {
    padding: '32px 48px',
  },
  genreForm: {
    maxWidth: 540,
    padding: 60,
  },
  selectedFileToast: {
    background: hexToRgba(theme.palette.color.primary, 0.5),
    fontSize: 14,
  },
}))

export const SelectSong = () => {
  const promotionTranslation = useTranslation('promotion')
  const classes = useStyles()
  const [innerStep, setInnerStep] = useState(1)
  const { setStoredFormState, storedFormState, incrementStep } = useWizard()
  const { selectedFileId, selectedFileName, parentId } = storedFormState
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      trackNameGenreValidationSchema(promotionTranslation.t)
    ),
  })

  const handleFileSelect = ({ id, name }) => {
    setStoredFormState({
      selectedFileId: id,
      selectedFileName: name,
    })
  }

  const onSubmit = async data => {
    const { trackName, genre } = data

    await setStoredFormState({ trackName, genre })
    await incrementStep()
  }

  const onNextButtonClick = () => {
    if (innerStep === 1) setInnerStep(2)
    if (innerStep === 2) handleSubmit(onSubmit)()
  }

  const onBackButtonClick = () => {
    if (innerStep === 2) {
      setInnerStep(1)
    }
  }

  return (
    <StepContainer
      {...{
        onNextButtonClick,
        onBackButtonClick,
        shouldDisableNextButton: !selectedFileId,
      }}
      isInnerStep={innerStep === 2}
      noFormStep
    >
      {selectedFileName && (
        <SelectedFileToast selectedFileName={selectedFileName} />
      )}
      {innerStep === 1 && (
        <div className={classes.gridContainer}>
          <PromotionInstructions {...{ parentId }} />
          <div className={classes.fileTreeWrapper}>
            <ProfileFilesTree
              title={promotionTranslation.t('selectTrack')}
              showFilesCondition={SHOW_FILES_CONDITION.PROMOTION}
              handleFileSelect={handleFileSelect}
              selectedFileId={selectedFileId}
              parentId={parentId}
              height="100%"
              width="100%"
              structureCategory={STRUCTURE_TYPE.PROFILE}
              roles={[ACCESS_ROLES.OWNER, ACCESS_ROLES.COLLABORATOR]}
            />
          </div>
        </div>
      )}
      {innerStep === 2 && (
        <form className={classes.genreForm}>
          <ChooseGenre {...{ register, control, errors }} />
        </form>
      )}
    </StepContainer>
  )
}
