import React, { useEffect, useState } from 'react'
import { string, func, shape } from 'prop-types'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { AddCollaboratorModal } from '#pages/vault/_modules/add-collaborator-modal'
import { useArtistStructureContent } from '#hooks/swr/useArtists'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { EmptyAlbumRow } from '../rows/empty-album-row'
import { FolderContentRow } from '#pages/vault/profile-content/components/rows/folder-content-row'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { useReload } from '#modules/page-wrapper/context'

export const FolderContent = ({
  parent,
  vaultId,
  handleDeleteModalOpen,
  handleDeleteModalClose,
  setIdOfActiveActionItem,
  setTypeOfCurrentAction,
  setStructureCategory,
  handleMoveSingle,
}) => {
  const { id: parentId } = parent
  const [typeOfCurrentNestedAction, setTypeOfCurrentNestedAction] =
    useState(null)
  const [idOfNestedActiveActionItem, setIdOfNestedActiveActionItem] = useState()
  const isInviteCollaboratorAction =
    typeOfCurrentNestedAction === ELLIPSIS_OPTIONS.INVITE_COLLABORATOR
  const query = `&withoutPagination=true&sortDirection=ASC`
  const { artistContent, isLoading, mutate } = useArtistStructureContent(
    parentId,
    query
  )
  const { shouldReload, reload } = useReload()

  useEffect(() => {
    reload(mutate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload])

  const isAlbumEmpty = !isLoading && artistContent?.count === 0

  const handleCloseNestedInlineAction = () => {
    setTypeOfCurrentNestedAction(null)
  }

  const shouldEnableStructureDeletion = roles => {
    return roles?.includes(ACCESS_ROLES.OWNER)
  }

  const onAddNewProject = () => {
    setTypeOfCurrentAction(ELLIPSIS_OPTIONS.ADD_INLINE)
    setIdOfActiveActionItem(parentId)
  }

  if (!artistContent) {
    return null
  }

  if (isAlbumEmpty) {
    return <EmptyAlbumRow onAddNewProject={onAddNewProject} />
  }

  if (isInviteCollaboratorAction) {
    return (
      <AddCollaboratorModal
        open={isInviteCollaboratorAction}
        handleClose={handleCloseNestedInlineAction}
        folderOrProject={STRUCTURE_TYPE.SONG}
        {...{ structureId: idOfNestedActiveActionItem }}
      />
    )
  }

  return artistContent?.data.map((row, index) => {
    return (
      <FolderContentRow
        key={row.id}
        {...{
          row,
          index,
          parent,
          vaultId,
          parentId,
          artistContent,
          setStructureCategory,
          handleDeleteModalOpen,
          handleDeleteModalClose,
          typeOfCurrentNestedAction,
          idOfNestedActiveActionItem,
          setTypeOfCurrentNestedAction,
          shouldEnableStructureDeletion,
          handleCloseNestedInlineAction,
          setIdOfNestedActiveActionItem,
          handleMoveSingle,
        }}
      />
    )
  })
}

FolderContent.propTypes = {
  parentId: string.isRequired,
  vaultId: string.isRequired,
  handleDeleteModalOpen: func.isRequired,
  handleDeleteModalClose: func.isRequired,
  setIdOfActiveActionItem: func.isRequired,
  setTypeOfCurrentAction: func.isRequired,
  setStructureCategory: func.isRequired,
  parent: shape({ id: string }).isRequired,
  handleMoveSingle: func.isRequired,
}
