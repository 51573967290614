import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SHeader = styled.div`
  background: ${({ theme: { colors } }) => colors.minorGrey};
  text-transform: uppercase;
  padding-left: 1.5rem;
  min-height: 60px;
  display: flex;
  align-items: center;
`

const SHeaderItem = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  font-family: ${({ theme: { typography } }) => typography.bold};
`

export const TableHeader = ({ headers }) => {
  const { t } = useTranslation('vault')

  return (
    <SHeader>
      {headers.map(({ width, name, justifyContent }) => (
        <SHeaderItem key={name} width={width} justifyContent={justifyContent}>
          {t(name)}
        </SHeaderItem>
      ))}
    </SHeader>
  )
}

TableHeader.propTypes = {
  headers: arrayOf(
    shape({
      name: string,
      width: string,
      justifyContent: string,
    })
  ).isRequired,
}
