import React from 'react'
import { RadioButton } from '#pages/_components/radio-button'
import styled from 'styled-components'

const SFolderRow = styled.div`
  display: flex;
  height: 3.75rem;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s linear;
  padding: 0 1rem;

  &:hover {
    background-color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_80};
  }
`

const SFolderIcon = styled.img`
  margin-right: 1.25rem;
  width: 1.25rem;
`

const SFolderName = styled.div`
  max-width: calc(100% - 2.5rem);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SRowContent = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
`

const SRadioButton = styled(RadioButton)`
  flex-shrink: 0;
  flex-grow: 0;
`

export const FolderRow = ({ selectedId, setSelectedId, id, name }) => {
  const onFolderRowClickHandler = () =>
    selectedId === id ? setSelectedId(null) : setSelectedId(id)

  return (
    <SFolderRow onClick={onFolderRowClickHandler}>
      <SRadioButton checked={selectedId === id} />
      <SRowContent>
        <SFolderIcon src="/assets/svg/ic-folder-blue.svg" alt="" />
        <SFolderName>{name}</SFolderName>
      </SRowContent>
    </SFolderRow>
  )
}
