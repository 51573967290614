import React from 'react'
import { number } from 'prop-types'
import { MARKET_RATING_KEYS } from '#pages/promotion/_utils/constants'
import { toKebabCase } from '#utils/toKebabCase'

export const MarketRatingChart = ({ rating }) => {
  const ratingName = toKebabCase(rating)

  return (
    <div>
      <img
        src={`/assets/images/target-${ratingName}.png`}
        alt={`ratingValue-${ratingName}`}
      />
    </div>
  )
}
MarketRatingChart.propTypes = {
  rating: number,
}

MarketRatingChart.defaultProps = {
  rating: MARKET_RATING_KEYS[0],
}
