import { ENDPOINTS } from '#api/endpoints'
import { EVENTS } from '#api/event'
import { SWR_EVENT_ACTION_RELOAD, useEventSWR } from '#hooks/swr/useEventSWR'

export const useUsersMe = (isAuthenticated = true) => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.SUBSCRIPTION_UPGRADED,
      EVENTS.USER_SUSPENDED,
      EVENTS.USER_UNSUSPENDED,
      EVENTS.USER_DATA_MODIFIED,
      // TODO limits events?
    ],
    eventAction: SWR_EVENT_ACTION_RELOAD,
    swrKey: isAuthenticated && ENDPOINTS.USERS_ME,
  })
  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
