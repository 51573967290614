export const ARTICLES = [
  {
    title: 'sellDigitalCollectibles',
    href: 'https://blog.tunego.com/category/sell-digital-collectibles',
    img: '/assets/images/learn-web3/sell-digital-collectibles.png',
  },
  {
    title: 'tipsAndBestPractices',
    href: 'https://blog.tunego.com/category/tips-best-practices-digital-collectibles',
    img: '/assets/images/learn-web3/tips-practices.png',
  },
  {
    title: 'gettingStarted',
    href: 'https://blog.tunego.com/category/getting-started-digital-collectibles',
    img: '/assets/images/learn-web3/getting-started.png',
  },
  {
    title: 'drops',
    href: 'https://blog.tunego.com/category/digital-collectible-drops',
    img: '/assets/images/learn-web3/drops.png',
  },
  {
    title: 'successStories',
    href: 'https://blog.tunego.com/category/digital-collectible-success-stories',
    img: '/assets/images/learn-web3/success-stories.png',
  },
  {
    title: 'creatorSpotlight',
    href: 'https://blog.tunego.com/category/digital-collectible-creator-spotlight/',
    img: '/assets/images/learn-web3/creator-spotlight.png',
  },
]
