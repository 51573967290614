import React from 'react'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { PricingTableActionRow } from '../pricing-table-action-row'
import { PricingTableRow } from '../pricing-table-row'
import {
  REVIEW_OPTIONS,
  TABLE_KEYS,
  TABLE_COLUMNS,
} from '#pages/promotion/_utils/constants'
import { usePaymentsPrices } from '#hooks/swr/usePayments'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    border: `1px solid ${theme.palette.color.darkGrey}`,
  },
}))

export const PricingTable = ({ onPlanSelect }) => {
  const { t } = useTranslation('promotion')
  const { prices } = usePaymentsPrices()
  const classes = useStyles()

  const getColValues = row =>
    REVIEW_OPTIONS.map((_, column) => TABLE_COLUMNS[column][row])

  return (
    <div className={classes.table}>
      <PricingTableActionRow
        rowName={t('featuresAndBenefits')}
        rowsArr={REVIEW_OPTIONS}
      />
      {TABLE_KEYS.map((key, row) => (
        <PricingTableRow
          key={key}
          title={key}
          index={row}
          colValues={getColValues(row)}
          prices={prices}
        />
      ))}
      <PricingTableActionRow
        {...{ onPlanSelect }}
        rowsArr={REVIEW_OPTIONS}
        isBottomRow
      />
    </div>
  )
}

PricingTable.propTypes = {
  onPlanSelect: func.isRequired,
}
