import React from 'react'
import { shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { UserImage } from '#components/user-image'
import styled from 'styled-components'

const useStyles = makeStyles(theme => {
  const fontFamily = theme.typography.avenirNextFont
  return {
    artistName: {
      fontSize: 24,
      fontFamily,
      fontWeight: 700,
    },
    idContainer: {
      fontFamily,
      fontSize: 18,
      fontWeight: 500,
      display: 'flex',
    },
    id: {
      fontFamily,
      fontSize: 18,
      color: theme.palette.primary.main,
      marginLeft: 10,
      fontWeight: 700,
      textDecoration: 'underline',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      gridColumn: '1 / -1',
    },
    idType: {
      textTransform: 'capitalize',
      marginRight: 8,
    },
    error: {
      color: theme.palette.color.warning,
    },
    bold: {
      fontFamily: theme.typography.bold,
    },
  }
})

const SUserImage = styled(UserImage)`
  margin-right: 30px;
`

export const ThumbnailAndArtist = ({ profileArtistIds }) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const artistName = profileArtistIds[0]?.name

  const avatarUrl = profileArtistIds?.find(({ avatar }) => avatar)?.avatar

  return (
    <div className={classes.wrapper}>
      <SUserImage userImageUrl={avatarUrl} size={90} />
      <div>
        <span className={classes.artistName}>{artistName}</span>
        {profileArtistIds.slice(0, 1).map(({ type, externalId }) => (
          <>
            <div className={classes.idContainer}>
              <span className={classes.idType}>{type}:</span>
              {externalId ? (
                <span className={classes.id}>{externalId}</span>
              ) : (
                <span className={`${classes.error} ${classes.bold}`}>
                  {t('notFound')}
                </span>
              )}
            </div>
            {!externalId && (
              <span className={classes.error}>{t('aNewArtistIdWillBe')}</span>
            )}
          </>
        ))}
      </div>
    </div>
  )
}

ThumbnailAndArtist.propTypes = {
  profileArtistIds: shape({}).isRequired,
}
