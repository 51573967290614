import React from 'react'
import { ThumbnailAndTitle } from '#pages/sell-collectibles/my-collectibles/_components/thumbnail-and-title'
import { formatPrice } from '#utils/formatPrice'
import { StatusButton } from '#pages/sell-collectibles/my-collectibles/_components/status-button'
import { formatDate } from '#utils/formatDate'
import { isNftSubmitted } from '#pages/sell-collectibles/_utils'
import { CollectibleActions } from '#pages/sell-collectibles/my-collectibles/_components/collectible-actions'

export const COLLECTIBLE_COLUMNS = t => [
  {
    key: 'collectible',
    label: t('list.collectible'),
    render: ({ title, thumbnail, collectibleSnapshot, status }) => {
      const collectibleTitle = isNftSubmitted(status)
        ? collectibleSnapshot.songName
        : title

      return (
        <ThumbnailAndTitle title={collectibleTitle} thumbnail={thumbnail} />
      )
    },
  },
  {
    key: 'price',
    label: t('list.price'),
    render: value =>
      formatPrice(value.price * 100, { freeText: '- -', trailingZeros: true }),
  },
  {
    key: 'totalUnits',
    label: t('list.editions'),
  },
  {
    key: 'rarity',
    label: t('list.rarity'),
  },
  {
    key: 'createdAt',
    label: t('list.dateCreated'),
    render: value => formatDate(value.createdAt),
  },
  {
    key: 'status',
    label: t('list.status'),
    render: ({ computedStatus }) => <StatusButton status={computedStatus} />,
    centered: true,
  },
  {
    key: 'options',
    label: '',
    noPadding: true,
    render: (nft, revalidate) => (
      <CollectibleActions nft={nft} revalidate={revalidate} />
    ),
  },
]

export const ACTION_TYPES = {
  delete: 'delete',
  edit: 'edit',
}

export const COLLECTIBLE_OPTIONS = t => [
  {
    title: t('editDraft'),
    actionType: ACTION_TYPES.edit,
  },
  {
    title: t('deleteDraft'),
    actionType: ACTION_TYPES.delete,
  },
]
