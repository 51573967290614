import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { func, string, arrayOf, number } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { BaseLoader } from '#components/loaders/base-loader'
import { ButtonRow } from '#pages/vault/song/tabs/metadata/components/button-row'
import { TableHeader } from '#pages/_components/table-header'
import { TABLE_HEADER } from '#pages/vault/song/tabs/metadata/utils/constants'

import { useContributors } from '#hooks/swr/useContributors'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ContributorsApi } from '#api/requests/contributors'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { buildUrl } from '#utils/buildUrl'
import { Pagination } from '#components/pagination'
import { ENDPOINTS } from '#api/endpoints'
import { ContributorsMetadataList } from '#pages/vault/song/tabs/metadata/components/contributors-metadata-list'

const useStyles = makeStyles(() => ({
  tableBody: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const ContributorsMetadata = ({
  accessRoles,
  handleOpenContributorModal,
  projectId,
  handleOpenEditContributorModal,
  pageIndex,
  setPageIndex,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const query = `?page=${pageIndex}&limit=${rowsPerPage}`
  const [error, setError] = useState('')
  const { contributors, isLoading } = useContributors(projectId, query)

  const { actions, loading, errors } = useWithAsyncAction({
    deleteContributor: ContributorsApi.deleteContributor,
    postReInviteContributor: ContributorsApi.postReInviteContributor,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  const triggerMutate = structureId =>
    mutate(
      `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS, {
        id: structureId,
      })}?page=${pageIndex}&limit=${rowsPerPage}`
    )

  useEffect(() => {
    if (errors.deleteContributor) {
      handleSetError(errors.deleteContributor)
    }
    if (errors.postReInviteContributor) {
      handleSetError(errors.postReInviteContributor)
    }
  }, [errors, handleSetError])

  const isOwner = () => accessRoles?.includes(ACCESS_ROLES.OWNER)

  if (loading.deleteContributor || loading.postReInviteContributor || error) {
    return (
      <CommonLoadingOverlay
        message={
          loading.deleteContributor ||
          (loading.postReInviteContributor && t('loading'))
        }
        dialogOpen={
          loading.deleteContributor || loading.postReInviteContributor
        }
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return (
    <ColumnWrapper>
      {isOwner() && <ButtonRow {...{ handleOpenContributorModal }} />}
      <div>
        <TableHeader headerItems={TABLE_HEADER} />
        <div className={classes.tableBody}>
          <BaseLoader isLoading={isLoading} text={t('loading')} />
          <ContributorsMetadataList
            contributors={contributors?.data}
            isOwner={isOwner()}
            projectId={projectId}
            triggerMutate={triggerMutate}
            actions={actions}
            handleOpenEditContributorModal={handleOpenEditContributorModal}
          />
        </div>
        <Pagination
          count={contributors?.count}
          page={pageIndex}
          rowsPerPage={rowsPerPage}
          onChangePage={setPageIndex}
          onChangeRowsPerPage={nextRowsPerPage => {
            setRowsPerPage(nextRowsPerPage)
            setPageIndex(0)
          }}
        />
      </div>
    </ColumnWrapper>
  )
}

ContributorsMetadata.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  projectId: string.isRequired,
  handleOpenContributorModal: func.isRequired,
  handleOpenEditContributorModal: func.isRequired,
  pageIndex: number.isRequired,
  setPageIndex: func.isRequired,
  rowsPerPage: number.isRequired,
  setRowsPerPage: func.isRequired,
}
