import { ConfirmationModal } from '#components/confirmation-modal'
import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  warningHeader: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: theme.palette.color.warning,
    textTransform: 'uppercase',
  },
  warningContent: {
    color: theme.palette.color.warning,
    marginBottom: '35px',
    fontWeight: 'bold',
  },
}))

export const StepExitWarningModalContainer = stepExitWarningModalProps => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      {...stepExitWarningModalProps}
      confirmationText={t('confirm')}
      cancelText={t('cancel')}
      isOwner
      isWarning
      isCancelButtonOutlined
    >
      <Typography variant="h2" className={classes.warningHeader}>
        {t('warning')}
      </Typography>

      <Typography>{t('stepExitWarningModal.youWillLose')}</Typography>
      <Typography className={classes.warningContent}>
        {t('stepExitWarningModal.doYouWishToContinue')}
      </Typography>
    </ConfirmationModal>
  )
}

StepExitWarningModalContainer.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
}
