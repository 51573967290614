import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { useLogin } from '#hooks/useLogin'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '5rem 10rem',
    width: '100%',
    background: theme.palette.color.black,
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5rem 0',
  },
  title: {
    padding: '0.5rem 0',
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '2.5rem',
  },
  description: {
    fontSize: '1.3rem',
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: '2rem',
    gap: '2rem',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const CreatorsCollectorsSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('home')
  const singUp = useLogin(true)

  const marketplaceRedirect = () =>
    navigate(process.env.REACT_APP_MARKETPLACE_URL)

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>
        {t('theWeb3')} {t('musicPlatform')}
      </span>
      <span className={classes.description}>
        {t('builtForCreatorsFansAnd')}
      </span>
      <div className={classes.buttonsWrapper}>
        <SButton onClick={singUp}>{t('creatorsStartHere')}</SButton>
        <SButton onClick={marketplaceRedirect}>
          {t('collectorsStartHere')}
        </SButton>
      </div>
    </div>
  )
}
