import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'
import { node, string } from 'prop-types'

const useStyles = makeStyles(() => {
  return {
    contentContainer: {
      minHeight: 'calc(100vh - 80px)',
      padding: '160px 0 80px 0',
    },
    wrapper: {
      width: '100%',
    },
  }
})

export const FormStepsWrapper = ({ children, className }) => {
  const classes = useStyles()

  return (
    <div className={cn([classes.contentContainer, className])}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  )
}

FormStepsWrapper.propTypes = {
  children: node.isRequired,
  className: string,
}

FormStepsWrapper.defaultProps = {
  className: '',
}
