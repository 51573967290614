import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  zeroNotificationsContainer: {
    textAlign: 'center',
    opacity: 0.25,
    marginTop: 24,
  },
  image: {
    width: '35px',
  },
}))

export const ZeroNotification = () => {
  const classes = useStyles()
  const { t } = useTranslation('notifications')

  return (
    <div className={classes.zeroNotificationsContainer}>
      <div className={classes.noneImageBox}>
        <img
          src="/assets/svg/ic-tunego.svg"
          className={classes.image}
          alt="TuneGo logo"
        />
      </div>
      <div className={classes.noneMessageBox}>
        <p className={classes.noneMessage}>{t('noNotifications')}</p>
      </div>
    </div>
  )
}
