import React from 'react'
import { makeStyles } from '@material-ui/core'
import { node, bool, func } from 'prop-types'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'

const useStyles = makeStyles(() => {
  return {
    wrapper: {
      paddingLeft: 64,
      paddingBottom: 112,
      minHeight: 'calc(100vh - 340px)',
    },
  }
})

export const ArtistIdStepWrapper = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <StepContainer {...props}>
      <div className={classes.wrapper}>{children}</div>
    </StepContainer>
  )
}

ArtistIdStepWrapper.propTypes = {
  onNextButtonClick: func.isRequired,
  shouldDisableNextButton: bool.isRequired,
  noBackButton: bool.isRequired,
  children: node,
}

ArtistIdStepWrapper.defaultProps = {
  children: null,
}
