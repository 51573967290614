import React from 'react'
import { oneOf, string, node } from 'prop-types'
import styled from 'styled-components'

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  white-space: pre-wrap;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};

  b {
    font-family: ${({
      theme: {
        tunego: { FONT },
      },
    }) => FONT.FAMILY.bold};
  }
`

const SWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  align-items: center;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['warning_15%']};
`

const SImg = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`

export const Error = ({ error }) => {
  return (
    <SWrapper>
      <SImg src="assets/svg/ic-warning.svg" alt="Warning icon" />
      <SText>{error}</SText>
    </SWrapper>
  )
}

Error.propTypes = {
  error: oneOf([string, node]).isRequired,
}
