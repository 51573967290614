import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { shape, number, string, arrayOf, func, bool } from 'prop-types'
import { capitalize } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ROLES } from '#constants/roles'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { UserImage } from '#components/user-image'
import { RowActionsButtons } from '#pages/_modules/collaborators-table/components/row-actions-buttons'

const useStyles = makeStyles(theme => ({
  row: {
    background: props =>
      props.index % 2
        ? theme.palette.color.minorGrey
        : theme.palette.background.default,
  },
  cellWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameWrapper: {
    marginLeft: 16,
  },
}))

export const MainTableRow = ({
  index,
  row,
  triggerMutate,
  projectId,
  isOwner,
  enableLeaveCollaborationModal,
  setCollaborator,
}) => {
  const classes = useStyles({ index })

  const {
    pendingName,
    user: { profileImageUrl, firstName, lastName, isOnboarded, id: userId },
    status,
    id,
    role,
  } = row

  const rolesDisplay = role
    .map(item => ROLES.find(roleOption => roleOption.value === item).label)
    .join(', ')

  return (
    <TableRow className={classes.row}>
      <TableCell component="th" scope="row">
        <div className={classes.cellWrapper}>
          <UserImage
            size={50}
            userImageUrl={
              profileImageUrl ? buildCloudFrontImgUrl(profileImageUrl) : ''
            }
            isRounded
          />
          <div className={classes.nameWrapper}>
            {isOnboarded ? `${firstName} ${lastName}` : pendingName}
          </div>
        </div>
      </TableCell>
      <TableCell>{rolesDisplay}</TableCell>
      <TableCell>{capitalize(status)}</TableCell>
      <TableCell align="right">
        <RowActionsButtons
          {...{
            isCollaborationOwner: isOwner,
            userId,
            projectId,
            triggerMutate,
            index,
            collaborator: row,
            setCollaborator,
            enableLeaveCollaborationModal,
            status,
            id,
          }}
        />
      </TableCell>
    </TableRow>
  )
}

MainTableRow.propTypes = {
  isOwner: bool.isRequired,
  index: number.isRequired,
  row: shape({
    user: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
    }),
    role: arrayOf(string.isRequired),
  }).isRequired,
  projectId: string.isRequired,
  triggerMutate: func.isRequired,
  enableLeaveCollaborationModal: func.isRequired,
  setCollaborator: func.isRequired,
}
