import React from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Modal } from '#components/modal'
import ClearIcon from '@material-ui/icons/Clear'

const SModal = styled(Modal)`
  justify-content: center;
`

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    top: 100,
    right: 50,
    cursor: 'pointer',
  },
}))

export const VideoModal = ({ src, isOpened, onClose }) => {
  const classes = useStyles()

  return (
    <SModal isOpen={isOpened} setIsOpen={() => {}}>
      <video width="640" controls>
        <source src={src} type="video/mp4" />
      </video>
      <span className={classes.closeIcon}>
        <ClearIcon onClick={onClose} />
      </span>
    </SModal>
  )
}

VideoModal.propTypes = {
  src: string.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
}
