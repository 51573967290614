import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { string } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'
import { PRICE_TYPE } from '#constants/priceTypes'
import { DISTRO_TYPES } from '#constants/distroTypes'
import { formatPrice } from '#utils/formatPrice'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    marginBottom: 30,
  },
  summaryBar: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '15px 30px',
    background: hexToRgba(theme.palette.color.primary, 0.5),
    border: `1px solid ${theme.palette.color.primary}`,
  },
  summaryItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '15px 30px',
    background: theme.palette.color.darkBlue09,

    '&:nth-of-type(even)': {
      background: hexToRgba(theme.palette.color.black, 0.05),
    },
  },
  typoTitle: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: theme.palette.color.default,
  },
  orderNumberWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  typoLabel: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.color.default,
  },
  typoLabel2: {
    marginLeft: 10,
    fontWeight: 700,
  },
}))

export const OrderSummary = ({
  artistName,
  releaseName,
  orderNumber,
  releaseType,
  paymentMethod,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const { priceOf } = useUser()

  const distroType =
    releaseType === DISTRO_TYPES.SINGLE ? t('single') : t('album')
  const priceType = releaseType
    ? PRICE_TYPE.DISTRO_SINGLE
    : PRICE_TYPE.DISTRO_ALBUM
  const price = priceOf(priceType)

  return (
    <div className={classes.wrapper}>
      <div className={classes.summaryBar}>
        <span className={classes.typoTitle}>{t('orderSummary')}</span>
        <div className={classes.orderNumberWrapper}>
          <span className={classes.typoLabel}>{t('orderNumber')}</span>
          <span
            className={cn(classes.typoLabel, {
              [classes.typoLabel2]: true,
            })}
          >
            {orderNumber}
          </span>
        </div>
      </div>
      <div>
        <div className={classes.summaryItem}>
          <span className={classes.typoLabel}>
            {distroTranslation.t('artistProfile')}:
          </span>
          <span
            className={cn(classes.typoLabel, {
              [classes.typoLabel2]: true,
            })}
          >
            {artistName}
          </span>
        </div>
        <div className={classes.summaryItem}>
          <span className={classes.typoLabel}>
            {distroTranslation.t('releaseName')}:
          </span>
          <span
            className={cn(classes.typoLabel, {
              [classes.typoLabel2]: true,
            })}
          >
            {releaseName}
          </span>
        </div>
        <div className={classes.summaryItem}>
          <span className={classes.typoLabel}>
            {distroTranslation.t('distributionType')}:
          </span>
          <span
            className={cn(classes.typoLabel, {
              [classes.typoLabel2]: true,
            })}
          >
            {distroType}
          </span>
        </div>
        <div className={classes.summaryItem}>
          <span className={classes.typoLabel}>
            {distroTranslation.t('payment')}
          </span>
          <span
            className={cn(classes.typoLabel, {
              [classes.typoLabel2]: true,
            })}
          >
            {paymentMethod}
          </span>
        </div>
      </div>
      <div className={classes.summaryBar}>
        <span className={classes.typoTitle}>
          {distroTranslation.t('orderTotal')}
        </span>
        <span className={classes.typoTitle}>{formatPrice(price?.value)}</span>
      </div>
    </div>
  )
}

OrderSummary.propTypes = {
  artistName: string.isRequired,
  releaseName: string.isRequired,
  orderNumber: string.isRequired,
  releaseType: string.isRequired,
  paymentMethod: string.isRequired,
}
