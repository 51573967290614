import React from 'react'
import { RadioCheckbox } from '#components/radio-checkbox'
import { FORM_FIELDS } from '#pages/subscriptions/_utils/constants'
import { formatCardBrand, formatCardNumber } from '#pages/profile/utils/helpers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  methodContainer: {
    marginBottom: 20,
  },
  infoLabel: {
    display: 'block',
    fontSize: 14,
    marginLeft: 40,
  },
}))

export const PaymentMethods = ({ paymentMethods, methodId, setMethodId }) => {
  const classes = useStyles()

  return paymentMethods.map(method => {
    const {
      id,
      billingName,
      brand,
      last4Digits,
      expirationMonth,
      expirationYear,
    } = method
    const expDate = `${expirationMonth}/${String(expirationYear).slice(2)}`

    return (
      <div key={id} className={classes.methodContainer}>
        <RadioCheckbox
          name={FORM_FIELDS.PAYMENT_METHODS_CHECKBOX_NAME}
          id={id}
          label={formatCardBrand(brand)}
          checked={methodId === id}
          customLabel={
            <span className={classes.customLabel}>{`${formatCardNumber(
              last4Digits
            )}`}</span>
          }
          isLabelBolded
          onChange={e => setMethodId(e.target.id)}
        />
        {methodId === id && (
          <>
            <span className={classes.infoLabel}>{billingName}</span>
            <span className={classes.infoLabel}>{expDate}</span>
          </>
        )}
      </div>
    )
  })
}
