import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  profileHeaderContainer: {
    display: 'flex',
    height: '70px',
    top: '64px',
    zIndex: 3,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    position: 'sticky',
    padding: '0 40px',
    width: '100%',
    backgroundColor: 'black',
  },
  profileHeaderName: {
    fontSize: '20px',
    lineHeight: '3.5',
    paddingLeft: '30px',
    fontWeight: 'bold',
  },
}))

export const Header = () => {
  const classes = useStyles()
  const { t } = useTranslation('profile')

  return (
    <div className={classes.profileHeaderContainer}>
      <div className={classes.profileHeaderName}>{t('myTuneGo')}</div>
    </div>
  )
}
