import { useContext } from 'react'
import { VaultContext } from '#contexts/vault'

export const useVault = () => {
  const context = useContext(VaultContext)

  if (!context) {
    throw new Error('useVault must be used within VaultProvider')
  }
  return context
}
