import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { bool, func, shape } from 'prop-types'
import cn from 'classnames'
import { hexToRgba } from '#utils/hexToRgba'
import { formatIntervalPrice } from '#pages/profile/utils/helpers'
import {
  BILLING_PERIOD,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_TYPES,
} from '#pages/profile/utils/constants'
import { SubscriptionPlanLimits } from '#pages/profile/tabs/subscription/components/subscription-plan-limits'
import { SubscriptionButtons } from '#pages/profile/tabs/subscription/components/subscription-buttons'
import { SubscriptionUsage } from '#pages/profile/tabs/subscription/components/subscription-usage'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 300,
    padding: '20px 30px',
    background: hexToRgba(theme.palette.color.black, 0.08),
  },
  current: {
    background: hexToRgba(theme.palette.color.primary, 0.5),
  },
  even: {
    background: hexToRgba(theme.palette.color.default, 0.08),
  },
  detailsWrapper: {
    width: 550,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    marginRight: 5,
  },
  monthlyPrice: {
    display: 'inline-block',
    fontWeight: 700,
    marginRight: 5,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

export const SubscriptionPlan = ({
  plan,
  setLevel,
  setAction,
  setPlanId,
  setIsModalOpened,
  isEven,
}) => {
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const classes = useStyles()
  const {
    subscription: { subscriptionPlan, billingPeriod },
  } = plan || {}
  const isNotFree = !subscriptionPlan?.free

  const { user } = useUser()
  const currentSubscription = user?.subscription

  const isCurrent =
    subscriptionPlan?.tierLevel ===
    currentSubscription?.subscriptionPlan.tierLevel

  const annualPrice = formatIntervalPrice(
    subscriptionPlan?.priceMap[SUBSCRIPTION_TYPES.YEARLY]?.value,
    false
  )

  const monthlyPrice = formatIntervalPrice(
    subscriptionPlan?.priceMap[SUBSCRIPTION_TYPES.MONTHLY]?.value,
    true
  )

  const isMonthly = billingPeriod === BILLING_PERIOD.MONTH

  return (
    <div
      className={cn(classes.container, {
        [classes.current]: isCurrent,
        [classes.even]: isEven,
      })}
    >
      <div className={classes.detailsWrapper}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>
            {`${t('tunego')} ${profileTranslation
              .t(SUBSCRIPTION_PLANS[subscriptionPlan?.tierLevel])
              .toUpperCase()}`}
          </span>
          {isNotFree && (
            <>
              <span className={classes.title}>
                {` - ${isMonthly ? monthlyPrice : annualPrice}`}
              </span>
              {!isCurrent && (
                <span>{profileTranslation.t('billedAnnually')}</span>
              )}
            </>
          )}
        </div>
        {isNotFree && !isCurrent && (
          <>
            <span className={classes.monthlyPrice}>{monthlyPrice}</span>
            <span>{profileTranslation.t('ifBilledMonthly')}</span>
          </>
        )}
        {isNotFree && currentSubscription?.billingPeriod && isCurrent && (
          <span className={classes.monthlyPrice}>
            {isMonthly
              ? profileTranslation.t('monthlyBilling')
              : profileTranslation.t('annualBilling')}
          </span>
        )}
        <SubscriptionPlanLimits subscriptionPlan={subscriptionPlan} />
        {/* TODO uncomment when Distro/promotion will be available/not hidden */}
        {/* <SubscriptionPlanPrices subscriptionPlan={subscriptionPlan} />*/}
      </div>
      <div className={classes.buttonsWrapper}>
        {isCurrent && <SubscriptionUsage usage={user?.quotas} />}
        <SubscriptionButtons
          planLevel={subscriptionPlan?.tierLevel}
          planId={subscriptionPlan?.id}
          setLevel={setLevel}
          setAction={setAction}
          setPlanId={setPlanId}
          setIsModalOpened={setIsModalOpened}
        />
      </div>
    </div>
  )
}

SubscriptionPlan.propTypes = {
  plan: shape({}).isRequired,
  setAction: func.isRequired,
  setLevel: func.isRequired,
  setPlanId: func.isRequired,
  setIsModalOpened: func.isRequired,
  isEven: bool,
}

SubscriptionPlan.defaultProps = {
  isEven: false,
}
