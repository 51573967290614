import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { number, shape, func } from 'prop-types'
import { validateNewArtistName } from '#pages/distro/_utils/validation'
import { ArtistIdInput } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-input'
import { FIELDS, ARTIST_ID_PLATFORM } from '#pages/distro/_utils/constants'

export const NewArtistIdFrom = ({
  setSelectedArtistIds,
  option,
  artistId,
  setOptionFn,
}) => {
  const name = FIELDS.NEW_ARTIST_NAME

  const { t } = useTranslation('distro')
  const {
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(validateNewArtistName(t, name)),
    mode: 'onChange',
  })

  const artistName = watch(name)

  useEffect(() => {
    if (option !== 2) {
      reset({ [name]: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option])

  useEffect(() => {
    if (option === 2 && artistName) {
      setSelectedArtistIds([
        { name: artistName, type: ARTIST_ID_PLATFORM.SPOTIFY },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistName])

  useEffect(() => {
    if (artistId?.option === 2) {
      const defaultValue = artistId.value[0].name
      reset({ [name]: defaultValue })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFocus = setOptionFn(2)

  return (
    <form>
      <ArtistIdInput {...{ register, errors, name, onFocus }} />
    </form>
  )
}

NewArtistIdFrom.propTypes = {
  setSelectedArtistIds: func.isRequired,
  option: number.isRequired,
  artistId: shape({}).isRequired,
  setOptionFn: func.isRequired,
}
