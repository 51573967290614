import React from 'react'
import { string, number, bool } from 'prop-types'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import styled from 'styled-components'

const SUserImage = styled.img`
  object-fit: cover;
  border-radius: ${({ isRounded }) => (isRounded ? '50%' : 0)};,
`

export const UserImage = ({ userImageUrl, size, isRounded, className }) => {
  const sizeInPx = `${size}px`

  return (
    <SUserImage
      className={className}
      width={sizeInPx}
      height={sizeInPx}
      src={userImageUrl || DEFAULT_AVATAR}
      isRounded={isRounded}
      alt=""
    />
  )
}

UserImage.propTypes = {
  userImageUrl: string,
  styles: string,
  size: number,
  isRounded: bool,
  className: string,
}

UserImage.defaultProps = {
  userImageUrl: '',
  styles: '',
  size: 30,
  isRounded: false,
  className: '',
}
