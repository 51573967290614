import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ALLOWED_PROFILES } from '#pages/mastering/wizard/steps/options-step/utils/constants'
import { hexToRgba } from '#utils/hexToRgba'
import { Hexagon } from '#pages/mastering/wizard/steps/options-step/components/hexagon'

const useStyles = makeStyles(theme => ({
  hexagon: {
    cursor: 'pointer',
    transition: 'all .2s linear',
    fill: hexToRgba(theme.palette.color.primary, 0.15),
    '&:hover': {
      fill: hexToRgba(theme.palette.color.primary, 0.5),
    },
  },
  text: {
    cursor: 'pointer',
    fontFamily: 'AvenirNext-Regular, Avenir Next, sans-serif',
    fontSize: '10px',
    letterSpacing: 0.003,
    fill: theme.palette.color.secondary,
    pointerEvents: 'none',
  },
}))

export const OptionsGrid = () => {
  const classes = useStyles()
  const [t] = useTranslation('mastering')

  return (
    <svg width="1048" height="757" viewBox="0 0 500 450">
      <g fill="none" fillRule="evenodd">
        <Hexagon
          d="M189.577 57.767l31.329 54.264-31.33 54.263H126.92l-31.33-54.263 31.33-54.264h62.658z"
          value={ALLOWED_PROFILES.less_loudness}
        />
        <Hexagon
          d="M189.577 166.088l31.329 54.263-31.33 54.264H126.92l-31.33-54.264 31.33-54.263h62.658z"
          value={ALLOWED_PROFILES.less_warmth}
        />
        <text className={classes.text} transform="translate(96.744 58.767)">
          <tspan x={40} y={55}>
            {t(`options.less_loudness`)}
          </tspan>
        </text>
        <text className={classes.text} transform="translate(96.744 58.767)">
          <tspan x={40} y={165}>
            {t(`options.less_warmth`)}
          </tspan>
        </text>
        <Hexagon
          d="M378.073 57.767l31.33 54.264-31.33 54.263h-62.658l-31.329-54.263 31.33-54.264h62.657z"
          value={ALLOWED_PROFILES.less_brightness}
        />
        <Hexagon
          d="M378.073 166.088l31.33 54.263-31.33 54.264h-62.658l-31.329-54.264 31.33-54.263h62.657z"
          value={ALLOWED_PROFILES.stereo_widening}
        />
        <Hexagon
          d="M378.073 274.173l31.33 54.263-31.33 54.263h-62.658l-31.329-54.263 31.33-54.263h62.657z"
          value={ALLOWED_PROFILES.less_mid}
        />
        <text className={classes.text} transform="translate(285.241 58.767)">
          <tspan x={42} y={275}>
            {t(`options.less_mid`)}
          </tspan>
        </text>
        <text className={classes.text} transform="translate(285.241 58.767)">
          <tspan x={40} y={55}>
            {t(`options.less_brightness`)}
          </tspan>
        </text>
        <text className={classes.text} transform="translate(285.241 58.767)">
          <tspan x={35} y={165}>
            {t(`options.stereo_widening`)}
          </tspan>
        </text>
        <g>
          <Hexagon
            d="M95.832 110.883l31.33 54.263-31.33 54.263H33.174L1.845 165.146l31.33-54.263h62.657z"
            value={ALLOWED_PROFILES.more_warmth}
          />
          <Hexagon
            d="M95.832 2.414l31.33 54.263-31.33 54.263H33.174L1.845 56.677l31.33-54.263h62.657z"
            value={ALLOWED_PROFILES.more_loudness}
          />
          <text
            className={classes.text}
            fill="#fff"
            transform="translate(3 3.414)"
          >
            <tspan x={45} y={58}>
              {t(`options.more_loudness`)}
            </tspan>
          </text>
          <text
            className={classes.text}
            fill="#fff"
            transform="translate(3 3.414)"
          >
            <tspan x={43} y={166}>
              {t(`options.more_warmth`)}
            </tspan>
          </text>
        </g>
        <g>
          <Hexagon
            d="M471.818 110.883l31.329 54.263-31.33 54.263H409.16l-31.33-54.263 31.33-54.263h62.658z"
            value={ALLOWED_PROFILES.less_bass}
          />
          <Hexagon
            d="M471.818 2.414l31.329 54.263-31.33 54.263H409.16l-31.33-54.263 31.33-54.263h62.658z"
            value={ALLOWED_PROFILES.more_bass}
          />
          <text
            className={classes.text}
            fill="#fff"
            transform="translate(378.985 3.414)"
          >
            <tspan x={40} y={55}>
              {t(`options.more_bass`)}
            </tspan>
          </text>
          <text className={classes.text} transform="translate(378.985 3.414)">
            <tspan x={40} y={165}>
              {t(`options.less_bass`)}
            </tspan>
          </text>
        </g>
        <g>
          <Hexagon
            d="M283.836 3.836l31.33 54.263-31.33 54.264h-62.658L189.85 58.099l31.33-54.263h62.657z"
            value={ALLOWED_PROFILES.more_brightness}
          />
          <Hexagon
            d="M283.836 220.898l31.33 54.263-31.33 54.264h-62.658L189.85 275.16l31.33-54.263h62.657z"
            value={ALLOWED_PROFILES.more_mid}
          />
          <text
            className={classes.text}
            fill="#fff"
            transform="translate(191.004 4.836)"
          >
            <tspan x={42} y={59}>
              {t(`options.more_brightness`)}
            </tspan>
          </text>
          <text
            className={classes.text}
            fill="#fff"
            transform="translate(191.004 4.836)"
          >
            <tspan x={40} y={270}>
              {t(`options.more_mid`)}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}
