import React from 'react'
import styled from 'styled-components'
import { bool, node, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BaseLoader } from '#components/loaders/base-loader'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  padding: 30px 50px;
`

export const TabWrapper = ({ isLoading, children, className }) => {
  const { t } = useTranslation('vault')

  return (
    <ColumnWrapper>
      {isLoading ? (
        <BaseLoader isLoading={isLoading} text={t('songFilesAreBeingLoaded')} />
      ) : (
        <SWrapper className={className}>{children}</SWrapper>
      )}
    </ColumnWrapper>
  )
}

TabWrapper.propTypes = {
  isLoading: bool.isRequired,
  children: node.isRequired,
  className: string,
}
