import React, { useEffect, useState } from 'react'
import { func, string, bool, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { PAYMENT_STATUSES } from '#constants/paymentStatuses'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { CheckoutSuccess } from '#modules/checkout/components/checkout-success'
import { getSuccessData } from '#modules/checkout/utils/helpers'
import { PaymentFormContainer } from '#modules/payments/containers/payment-form-container'
import { DIFFERENT_PAYMENT_METHOD } from '#pages/subscriptions/_utils/constants'
import { PaymentsApi } from '#api/requests/payments'
import { CHECKOUT_TYPE } from '#modules/checkout/utils/constants'

export const CheckoutContainer = ({
  handlePayment: handlePaymentFinalPart,
  type,
  onPaymentSuccess,
  payment,
  freePaymentStatus,
  checkoutSuccess,
  setCheckoutSuccess,
  showHorizontally,
}) => {
  const translationType = {
    [CHECKOUT_TYPE.MASTERING]: 'mastering',
    [CHECKOUT_TYPE.DISTRO]: 'distro',
    [CHECKOUT_TYPE.PROMOTION]: 'promotion',
  }
  const { t } = useTranslation(translationType[type])
  const commonTranslation = useTranslation()
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false)
  const [error, setError] = useState('')
  const stopLoading = () => setIsPaymentInProgress(false)

  const { actions, loading } = useWithAsyncAction({
    setPaymentMethodAsDefault: PaymentsApi.setPaymentMethodAsDefault,
    addNewCard: PaymentsApi.addNewCard,
  })

  useEffect(() => {
    if (freePaymentStatus) {
      setIsPaymentInProgress(false)
      setCheckoutSuccess(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freePaymentStatus])

  useEffect(() => {
    if (payment) {
      if (payment.status === PAYMENT_STATUSES.COMPLETED) {
        setIsPaymentInProgress(false)
        if (onPaymentSuccess) {
          onPaymentSuccess()
        }
        setCheckoutSuccess(true)
      } else if (payment.status === PAYMENT_STATUSES.REJECTED) {
        // TODO ? display error somewhere
        setIsPaymentInProgress(false)
        setCheckoutSuccess(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPaymentSuccess, payment])

  const handlePayment = async ({
    methodId,
    stripe,
    cardElement,
    nameOnCard,
    defaultMethod,
  }) => {
    setIsPaymentInProgress(true)
    try {
      let paymentMethodId = methodId
      if (methodId === DIFFERENT_PAYMENT_METHOD) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        })

        paymentMethodId = paymentMethod.id
        const {
          data: { clientSecret },
        } = await actions.addNewCard(paymentMethod.id)

        if (clientSecret) {
          const response = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })

          if (defaultMethod) {
            await actions.setPaymentMethodAsDefault(paymentMethod.id)
          }

          if (response.error) {
            setIsPaymentInProgress(false)
            setError(response.error.message)
          }
        }
      }

      await handlePaymentFinalPart({
        stopLoading,
        setError,
        paymentMethodId,
        stripe,
      })
    } catch (err) {
      setIsPaymentInProgress(false)
      setError(err.response?.data?.message)
    }
  }

  const clearErrors = () => {
    setError('')
  }

  const showLoader =
    isPaymentInProgress ||
    error ||
    loading.setPaymentMethodAsDefault ||
    loading.addNewCard

  const { buttonText, onClick, congratulationsMessage } = getSuccessData(
    type,
    t
  )

  return (
    <>
      {showLoader && (
        <CommonLoadingOverlay
          message={commonTranslation.t('payments.pleaseWaitWhileYourPayment')}
          dialogOpen={isPaymentInProgress}
          error={error}
          onErrorBackdropClick={clearErrors}
        />
      )}
      {checkoutSuccess ? (
        <CheckoutSuccess
          {...{ buttonText, onClick, congratulationsMessage, showHorizontally }}
        />
      ) : (
        <PaymentFormContainer {...{ handlePayment }} />
      )}
    </>
  )
}

CheckoutContainer.defaultProps = {
  checkoutSuccess: false,
  setCheckoutSuccess: null,
  onPaymentSuccess: null,
  showHorizontally: false,
  freePaymentStatus: false,
  payment: shape({}),
}

CheckoutContainer.propTypes = {
  handlePayment: func.isRequired,
  type: string.isRequired,
  checkoutSuccess: bool,
  setCheckoutSuccess: func,
  onPaymentSuccess: func,
  showHorizontally: bool,
  freePaymentStatus: bool,
  payment: null,
}
