import React from 'react'
import styled from 'styled-components'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { makeStyles } from '@material-ui/core'

const useStyles = onBackButtonClick =>
  makeStyles(theme => ({
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 80,
      borderTop: `2px solid ${theme.palette.color.primary}`,
    },
    defaultButtonWrapperClassName: {
      display: 'flex',
      width: '100%',
      maxWidth: 1150,
      margin: '0 auto',
      justifyContent: onBackButtonClick ? '' : 'flex-end',
    },
    fixed: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.color.black,
    },
  }))()

const SButtonNext = styled(Button)`
  color: ${({ theme: { colors } }) => colors.default};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  min-width: 120px;
  height: 40px;
  border-radius: 10px;
  text-transform: uppercase;
`

const SButtonBack = styled(SButtonNext)`
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.primary};
  margin-right: auto;
`

export const BottomButtonsNavigation = ({
  onBackButtonClick,
  onNextButtonClick,
  customNextTitle,
  customBackTitle,
  className,
  shouldDisableNextButton,
  buttonWrapperClassName,
  onBackButtonType = 'button',
  onNextButtonType = 'button',
  fixed,
}) => {
  const classes = useStyles(onBackButtonClick)
  const { t } = useTranslation()

  return (
    <div
      className={`${classes.buttonsWrapper} ${className} ${
        fixed && classes.fixed
      }`}
    >
      <div
        className={`${classes.defaultButtonWrapperClassName} ${buttonWrapperClassName}`}
      >
        {onBackButtonClick && (
          <SButtonBack
            type={onBackButtonType}
            onClick={() => onBackButtonClick()}
          >
            {customBackTitle || t('back')}
          </SButtonBack>
        )}
        {onNextButtonClick && (
          <SButtonNext
            type={onNextButtonType}
            disabled={shouldDisableNextButton}
            onClick={() => onNextButtonClick()}
          >
            {customNextTitle || t('next')}
          </SButtonNext>
        )}
      </div>
    </div>
  )
}

BottomButtonsNavigation.propTypes = {
  onBackButtonClick: func,
  onNextButtonClick: func,
  customNextTitle: string,
  customBackTitle: string,
  className: string,
  shouldDisableNextButton: bool,
  buttonWrapperClassName: string,
  onBackButtonType: string,
  onNextButtonType: string,
  fixed: bool,
}

BottomButtonsNavigation.defaultProps = {
  onBackButtonClick: null,
  onNextButtonClick: null,
  className: null,
  customNextTitle: '',
  customBackTitle: '',
  shouldDisableNextButton: false,
  buttonWrapperClassName: '',
  onBackButtonType: 'button',
  onNextButtonType: 'button',
  fixed: false,
}
