import React from 'react'
import { func, arrayOf, string, shape, node } from 'prop-types'
import { Menu, MenuItem, Button } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  menu: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
    '& .MuiMenu-list': {
      background: theme.palette.color.primary,
      color: theme.palette.color.darkBlue09,
    },
    '& .MuiMenuItem-root': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: 14,
      marginBottom: 20,
      marginTop: 10,

      '&:hover': {
        background: theme.palette.color.darkBlue09,
        color: theme.palette.color.default,
      },
    },
  },
  itemDisabled: {
    cursor: 'auto',
    opacity: 0.5,
  },
}))

export const EllipsisMenu = ({
  onChoose,
  options,
  activeIconColor,
  customOption,
  onMenuOpen,
  onMenuClose,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    if (onMenuOpen) onMenuOpen()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    if (onMenuClose) onMenuClose()
    setAnchorEl(null)
  }

  const iconColor = anchorEl && activeIconColor ? activeIconColor : 'primary'

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color={iconColor} />
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <div>
          {options.map(option => {
            return (
              <MenuItem
                key={option.title}
                classes={
                  !!option.disabled && {
                    root: classes.itemDisabled,
                  }
                }
                onClick={() => {
                  if (!option.disabled) {
                    onChoose(option.actionType)
                    handleClose()
                  }
                }}
              >
                {option.title}
              </MenuItem>
            )
          })}

          <span onClick={handleClose}>{customOption}</span>
        </div>
      </Menu>
    </div>
  )
}

EllipsisMenu.propTypes = {
  onChoose: func.isRequired,
  options: arrayOf(
    shape({
      title: string,
      actionType: string,
    })
  ).isRequired,
  onMenuOpen: func,
  onMenuClose: func,
  activeIconColor: string,
  customOption: node,
}

EllipsisMenu.defaultProps = {
  onMenuOpen: null,
  onMenuClose: null,
  activeIconColor: null,
}
