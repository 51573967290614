import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { MASTER_STATUS } from '#pages/mastering/wizard/utils/constants'

const SButton = styled(Button)`
  height: unset;
  padding: 0;
`

export const StatusButton = ({ status }) => {
  const { t } = useTranslation('mastering')

  const renderStatus = masterStatus => {
    switch (masterStatus) {
      case MASTER_STATUS.PREVIEW_PROCESSING:
        return <span>{t(`preview-processing`)}</span>
      case MASTER_STATUS.MASTER_PROCESSING:
        return <span>{t(`mastering`)}</span>
      case MASTER_STATUS.PREVIEW_READY:
        return <span>{t(`ready`)}</span>
      case MASTER_STATUS.MASTER_READY:
        return (
          <SButton>
            <img src="/assets/svg/ic-check-blue.svg" alt="" width="25px" />
          </SButton>
        )
      default:
        return null
    }
  }

  return renderStatus(status)
}

StatusButton.propTypes = {
  status: string.isRequired,
}
