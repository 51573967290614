import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import styled, { css } from 'styled-components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, instanceOf, bool, func } from 'prop-types'
import { ProfileImageDropzone } from '#pages/vault/_modules/profile-form/components/profile-image-dropzone'
import { ProfileActionArea } from '#pages/vault/_modules/profile-form/components/profile-action-area'
import { ProfileButtons } from '#pages/vault/_modules/profile-form/components/profile-buttons'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'

const SContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ modal }) =>
    !modal &&
    css`
      max-width: 80%;
      padding-top: 4rem;
    `};

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 2rem 0 0;
    border: none;
  }
`

const SInnerWrapper = styled.div`
  margin: auto;
  display: inline-block;
`

const SFieldsWrapper = styled.div`
  height: fit-content;
  position: relative;
  display: flex;
  gap: 2.5rem;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.sm}) {
    flex-direction: column-reverse;
    max-width: 100%;
    justify-content: center;
    padding: 0;
    border: none;
  }
`

const SColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const STitle = styled.h1`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
    modal,
  }) => (modal ? FONT.SIZE['3xl'] : FONT.SIZE['4xl'])};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    modal,
  }) => (modal ? COLORS.primary_50 : COLORS.neutral_0)};
  margin: ${({ editPage }) => (editPage ? '2.5rem' : 0)} 0 0;
  padding: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SSubTitle = styled.h2`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.xl};
  margin: 1rem 0 2rem;
  font-weight: 400;
`

export const ProfileForm = ({
  title,
  subTitle,
  submitButtonText,
  validationSchema,
  hasPreview,
  previewFile,
  profileName,
  bio,
  handleCancel,
  handlePreview,
  handleCreate,
  handleDeleteFile,
  isSending,
  loadingMessage,
  error,
  setError,
  isImageUpdated,
  modal,
}) => {
  const [readyToCreate, setReadyToCreate] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const watchName = watch('profileName')
  const watchBio = watch('bio')

  useEffect(() => {
    if (profileName || bio) {
      reset({
        profileName,
        bio,
      })
    }
  }, [profileName, bio])

  useEffect(() => {
    if (
      (watchName !== profileName && isDirty) ||
      isImageUpdated ||
      watchBio !== bio
    ) {
      setReadyToCreate(true)
    } else {
      setReadyToCreate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchName, isImageUpdated, readyToCreate, watchBio])

  return (
    <>
      {(isSending || error) && (
        <CommonLoadingOverlay
          {...{ error }}
          dialogOpen={isSending}
          message={loadingMessage}
          onErrorBackdropClick={() => {
            setError('')
          }}
        />
      )}
      <SContainer modal={modal}>
        <form onSubmit={handleSubmit(handleCreate)}>
          <SInnerWrapper>
            <STitle modal={modal}>{title}</STitle>
            <SSubTitle>{subTitle}</SSubTitle>

            <SFieldsWrapper>
              <SColWrapper>
                <ProfileActionArea
                  {...{
                    errors,
                    inputDefaultValues: { profileName, bio },
                    inputRef: register,
                  }}
                />
              </SColWrapper>
              <ProfileImageDropzone
                {...{
                  handlePreview,
                  previewFile,
                  hasPreview,
                  handleDeleteFile,
                }}
              />
            </SFieldsWrapper>
          </SInnerWrapper>
          <ProfileButtons
            submitButtonTranslation={submitButtonText}
            readyToCreate={readyToCreate}
            handleCancel={handleCancel}
            hideCancel={modal}
          />
        </form>
      </SContainer>
    </>
  )
}

ProfileForm.propTypes = {
  title: string,
  subTitle: string,
  submitButtonText: string,
  imageCtaText: string,
  validationSchema: instanceOf(yup).isRequired,
  inputName: string,
  hasPreview: bool,
  previewFile: string,
  profileName: string,
  bio: string,
  isSending: bool,
  error: string,
  loadingMessage: string.isRequired,
  handleCancel: func.isRequired,
  handlePreview: func.isRequired,
  handleCreate: func.isRequired,
  handleDeleteFile: func.isRequired,
  setError: func.isRequired,
  isImageUpdated: bool.isRequired,
  modal: bool,
}

ProfileForm.defaultProps = {
  inputName: null,
  hasPreview: null,
  previewFile: null,
  bio: null,
  profileName: null,
  isSending: null,
  error: null,
  title: null,
  subTitle: null,
  submitButtonText: null,
  imageCtaText: null,
}
