import React from 'react'
import { useTranslation } from 'react-i18next'
import { SPLITS_COLUMNS } from '#pages/sell-collectibles/create-collectible/_constants/splits'
import styled from 'styled-components'

const STHead = styled.thead`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
`

const SHeader = styled.th`
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  padding: 1.25rem 2rem;
  text-align: left;
  line-height: 1.25rem;
`

export const TableHeader = () => {
  const { t } = useTranslation('nftWizard')

  return (
    <STHead>
      <tr>
        {SPLITS_COLUMNS(t).map(({ label, value }) => (
          <SHeader key={value}>{label}</SHeader>
        ))}
      </tr>
    </STHead>
  )
}
