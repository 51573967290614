import React from 'react'
import styled from 'styled-components'
import { arrayOf, func, node, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loadProfiles } from '#utils/loadProfiles'
import { AutoComplete } from '#components/inputs/auto-complete'
import { Button } from '#components/button'
import { CreateProfileRedirect } from '#components/create-profile-redirect'
import { parentValidationSchema } from '#pages/promotion/_utils/validation'
import { makeStyles } from '@material-ui/core/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles(() => ({
  selectProfile: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 48,
    width: 580,
    margin: '0 auto',
  },
  selectProfileHeader: {
    width: '100%',
    marginBottom: 32,
  },
  selectProfileAutocomplete: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  autocompleteWrapper: {
    width: 430,
    marginRight: 16,
  },
  redirectWrapper: {
    width: 350,
  },
}))

const SButton = styled(Button)`
  margin-top: 30px;
  width: 115px;
  text-transform: uppercase;
`

export const SelectProfile = ({
  subtitle,
  createNewProfileText,
  onSubmit,
  name,
  roles,
  children,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { useAltStructuresEndpoint } = useFlags()

  const {
    control,
    formState: { errors },
    formState,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(parentValidationSchema()),
  })

  const isSubmitDisabled = !formState.isValid && !watch(name)

  return (
    <div className={classes.selectProfile}>
      <div className={classes.selectProfileHeader}>
        <Typography variant="h2" gutterBottom>
          {t('selectProfile')}
        </Typography>
        <Typography>{subtitle}</Typography>
      </div>
      <div className={classes.selectProfileAutocomplete}>
        <div className={classes.autocompleteWrapper}>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange } }) => (
              <AutoComplete
                id="search_profile"
                errorMessage={errors[name]?.message}
                placeholder={t('search')}
                loadOptions={loadProfiles(roles, useAltStructuresEndpoint)}
                label={t('chooseProfile')}
                onChange={option => {
                  onChange(option)
                }}
              />
            )}
          />
        </div>
        <SButton disabled={isSubmitDisabled} onClick={handleSubmit(onSubmit)}>
          {t('select')}
        </SButton>
      </div>
      {children}
      <div className={classes.redirectWrapper}>
        {!!createNewProfileText && (
          <CreateProfileRedirect
            text={createNewProfileText}
            linkText={t('clickHere')}
            linkTextMore={t('toCreateNewProfile')}
          />
        )}
      </div>
    </div>
  )
}

SelectProfile.propTypes = {
  subtitle: string.isRequired,
  onSubmit: func.isRequired,
  createNewProfileText: string,
  name: string,
  roles: arrayOf(string),
  children: node,
}

SelectProfile.defaultProps = {
  createNewProfileText: '',
  name: 'parent',
  roles: [],
  children: undefined,
}
