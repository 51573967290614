import React from 'react'
import { makeStyles } from '@material-ui/core'
import { string, node } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Element } from 'react-scroll'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = description =>
  makeStyles(() => ({
    rowWrapper: {
      marginBottom: 48,
      width: '100%',
    },
    titleWrapper: {
      marginBottom: description ? 24 : 0,
      width: '100%',
    },
    descriptionWrapper: {
      marginBottom: 24,
      width: '100%',
    },
  }))()

export const ReviewRowElement = ({ title, description, children, refName }) => {
  const { t } = useTranslation('promotion')
  const classes = useStyles(description)

  return (
    <div className={classes.rowWrapper}>
      <Element name={refName || ''}>
        <div>
          <div className={classes.titleWrapper}>
            <Text type={TEXT_TYPE.SUBTITLE1}>{title && t(title)}</Text>
          </div>
          {description && (
            <div className={classes.descriptionWrapper}>
              <span>{t(description)}</span>
            </div>
          )}
          {children}
        </div>
      </Element>
    </div>
  )
}

ReviewRowElement.propTypes = {
  title: string,
  description: string,
  refName: string,
  children: node.isRequired,
}

ReviewRowElement.defaultProps = {
  title: '',
  description: '',
  refName: '',
}
