import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { InputPhone } from '#components/inputs/input-phone'
import { navigate } from '@reach/router'
import { DownloadButton } from './components/download-button'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 400,
    alignSelf: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.color.darkGrey33,
    height: 52,
    border: `1px solid ${theme.palette.color.darkGrey33}`,
    width: '100%',
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    textAlign: 'left',
  },
}))

export const PrivateLink = () => {
  const [value, setValue] = useState()
  const { t } = useTranslation('connect')
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.label}>{t('testYourselfAPrivate')}</div>
      <div className={classes.inputContainer}>
        <InputPhone
          country="us"
          placeholder={t('enterPhoneNumber')}
          value={value}
          onChange={setValue}
        />
      </div>
      <div
        onClick={() => {
          // FIXME: will navigate to coming soon page until the connect app is released
          // sendInviteLink(`+${value}`)
          navigate(NAVIGATION_PATHS.CONNECT_COMING_SOON)
          setValue('')
        }}
      >
        <DownloadButton />
      </div>
    </div>
  )
}

PrivateLink.propTypes = {
  sendInviteLink: func.isRequired,
}
