import React from 'react'
import styled from 'styled-components'
import { func, string, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { DatePicker } from '#components/date-picker'
import { BaseInput } from '#components/inputs/base-input'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { SHARE_TYPES } from '#pages/vault/song/tabs/files/utils/constants'

const SButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme: { colors } }) => colors.darkGrey};
  border-radius: 10px;
  width: 165px;
  margin: 20px 0;
`

const SBoldItalicText = styled.span`
  font-weight: bold;
  font-style: italic;
  word-break: break-word;
`

const SSharesContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0.5rem;
  width: 100%;
`

const SButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
`

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SButtonSend = styled(Button)`
  margin-right: 20px;
  text-transform: uppercase;
`

const DISABLED_BUTTON_PROPS = {
  variant: VARIANTS.TRANSPARENT,
}

export const DeterminePlaysGranting = ({
  shareType,
  setShareType,
  onClose,
  songName,
  register,
  control,
  errors,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const determineBtnProps = buttonShareType => {
    if (shareType !== buttonShareType) {
      return DISABLED_BUTTON_PROPS
    }
    return {}
  }

  return (
    <>
      <SButtonContainer>
        <SButton
          {...determineBtnProps(SHARE_TYPES.plays)}
          onClick={() => setShareType(SHARE_TYPES.plays)}
        >
          {vaultTranslation.t('plays')}
        </SButton>
        <SButton
          {...determineBtnProps(SHARE_TYPES.days)}
          onClick={() => setShareType(SHARE_TYPES.days)}
        >
          {vaultTranslation.t('days')}
        </SButton>
      </SButtonContainer>
      <span>
        {shareType === SHARE_TYPES.plays
          ? vaultTranslation.t('howManyPlays')
          : vaultTranslation.t('howLong')}
        <SBoldItalicText>{` ${songName}`}</SBoldItalicText>
      </span>
      <SSharesContainer>
        {shareType === SHARE_TYPES.plays ? (
          <BaseInput
            name="maxAccessCount"
            inputRef={register}
            customWidth={80}
            defaultValue={1}
            type="number"
            errorMessage={errors.maxAccessCount?.message}
          />
        ) : (
          <Controller
            name="expiresAt"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                value={value}
                minDate={new Date()}
                label={vaultTranslation.t('endDate')}
                errorMessage={errors.expiresAt?.message}
                isBolded
                onChange={date => onChange(date)}
              />
            )}
          />
        )}
        <SButtonsWrapper>
          <SButtonSend type="submit">{t('send')}</SButtonSend>
          <SButton variant={VARIANTS.CANCEL} onClick={onClose}>
            {t('cancel')}
          </SButton>
        </SButtonsWrapper>
      </SSharesContainer>
    </>
  )
}

DeterminePlaysGranting.propTypes = {
  shareType: string.isRequired,
  songName: string.isRequired,
  setShareType: func.isRequired,
  onClose: func.isRequired,
  register: func.isRequired,
  control: func.isRequired,
  getValues: func.isRequired,
  errors: shape().isRequired,
}
