import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { number, func, bool } from 'prop-types'
import { useUploadManager } from '../../context'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '16px auto 15px 15px',
    columnGap: 20,
    alignItems: 'center',
    padding: '16px 20px',
    backgroundColor: theme.palette.color.darkBlue09,
  },
  bold: {
    fontFamily: theme.typography.bold,
  },
  iconWrapper: {
    cursor: 'pointer',
  },
}))

export const UploaderHeader = () => {
  const { isMinimized, toggleManager, closeManager, uploadingFilesInProgress } =
    useUploadManager()
  const { t } = useTranslation('vault')
  const classes = useStyles()
  const uploadingFilesCount = uploadingFilesInProgress.length

  return (
    <div className={classes.header}>
      <img
        src="/assets/svg/ic-upload-v2.svg"
        width="17px"
        height="17px"
        alt=""
      />
      <Typography className={classes.bold}>
        {t('uploadingFiles', { uploadingFilesCount })}
      </Typography>
      <div className={classes.iconWrapper} onClick={toggleManager}>
        {isMinimized ? (
          <img
            src="/assets/svg/ic-chevron-up.svg"
            width="15px"
            height="15px"
            alt=""
          />
        ) : (
          <img
            src="/assets/svg/ic-chevron-down.svg"
            width="15px"
            height="15px"
            alt=""
          />
        )}
      </div>
      <div className={classes.iconWrapper} onClick={closeManager}>
        <img
          src="/assets/svg/ic-close-bold.svg"
          width="14px"
          height="14px"
          alt=""
        />
      </div>
    </div>
  )
}

UploaderHeader.propTypes = {
  count: number.isRequired,
  handleToggleOpen: func.isRequired,
  isOpen: bool.isRequired,
}
