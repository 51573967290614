import React, { useMemo } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'
import { sortBy } from 'lodash'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { Button } from '#components/button'
import { useSystem } from '#hooks/useSystem'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 290,
  },
  header: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.color.default,
    marginBottom: 15,
  },
  storesWrapper: {
    paddingTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  storeItem: {
    fontSize: 12,
    lineHeight: '21px',
    width: '50%',

    '&:nth-of-type(even)': {
      paddingLeft: 20,
    },
  },
}))

const SButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  border-radius: 10px;
  margin-top: 15px;
  text-transform: uppercase;
`

export const MusicStores = ({ isPreview }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStep, storedFormState } = useWizard()

  const { settings } = useSystem()
  const distroStores = useMemo(
    () => sortBy(settings?.distroStores || [], 'order'),
    [settings]
  )

  const handleStepChange = () => setStep(5)

  const storesToDisplay = storedFormState[FIELDS.MUSIC_STORES]?.map(store => {
    return distroStores
      .filter(distroStore => distroStore.externalName === store)
      .map((distroStore, i) => {
        return (
          <span
            key={generateUniqueKey(distroStore, i)}
            className={classes.storeItem}
          >
            {distroStore.displayName}
          </span>
        )
      })
  })

  return (
    <div className={classes.wrapper}>
      <span className={classes.header}>{t('musicStores')}</span>
      <div className={classes.storesWrapper}>{storesToDisplay}</div>
      {isPreview && (
        <SButton onClick={handleStepChange}>{t('editStores')}</SButton>
      )}
    </div>
  )
}

MusicStores.propTypes = {
  isPreview: bool.isRequired,
}
