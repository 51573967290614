import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ARTWORK_DIMENSIONS,
  ARTWORK_RATIO,
  MAX_ANIMATED_ARTWORK_SIZE,
  MAX_STATIC_ARTWORK_SIZE,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { bool, string } from 'prop-types'
import { Requirements } from '#pages/sell-collectibles/create-collectible/_components/requirements'

export const ArtworkRequirements = props => {
  const { t } = useTranslation('nftWizard')

  const requirements = [
    {
      i18nKey: 'steps.files.artworkDimensions',
      values: { ratio: ARTWORK_RATIO, dimensions: ARTWORK_DIMENSIONS },
    },
    {
      i18nKey: 'steps.files.artworkStaticFiles',
      values: { size: MAX_STATIC_ARTWORK_SIZE },
    },
    {
      i18nKey: 'steps.files.artworkAnimatedFiles',
      values: { size: MAX_ANIMATED_ARTWORK_SIZE },
    },
  ]

  return (
    <Requirements
      requirements={requirements}
      title={t('steps.files.artworkRequirements')}
      {...props}
    />
  )
}

ArtworkRequirements.propTypes = {
  showFooterNote: bool,
  className: string,
  primary: bool,
}

ArtworkRequirements.defaultProps = {
  showFooterNote: true,
  primary: false,
}
