import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  NFT_FILES_STEP_DEFAULT_VALUES,
  NFT_FILES_STEP_FIELDS,
  nftFilesSchema,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { useTranslation } from 'react-i18next'

export const useFilesStep = () => {
  const { t } = useTranslation('nftWizard')

  const methods = useForm({
    resolver: yupResolver(nftFilesSchema(t)),
    defaultValues: NFT_FILES_STEP_DEFAULT_VALUES,
    mode: 'all',
  })

  const setPersona = ({ name, id, avatarUrl, filesCount }) => {
    const persona = {
      label: name,
      value: id,
      avatarUrl,
      filesCount,
    }

    methods.setValue(NFT_FILES_STEP_FIELDS.PERSONA, persona, {
      shouldDirty: true,
    })
  }

  const resetFiles = () => methods.reset(NFT_FILES_STEP_DEFAULT_VALUES)

  const setInitialFilesAndGetTrack = nft => {
    const {
      profile: { name, id, avatarUrl, filesCount },
      files,
      imageId,
    } = nft

    const persona = {
      label: name,
      value: id,
      avatarUrl,
      filesCount,
    }

    const [track] = files
      .filter(file => file.type === 'track')
      .map(({ file }) => file)

    methods.reset({
      [NFT_FILES_STEP_FIELDS.ARTWORK]: {
        id: imageId,
      },
      [NFT_FILES_STEP_FIELDS.MUSIC]: track,
      [NFT_FILES_STEP_FIELDS.PERSONA]: persona,
    })

    return track
  }

  return {
    methods,
    resetFiles,
    setPersona,
    setInitialFilesAndGetTrack,
  }
}
