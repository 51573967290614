import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { func, number } from 'prop-types'
import cn from 'classnames'
import { hexToRgba } from '#utils/hexToRgba'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    borderTop: `1px solid ${theme.palette.color.darkGrey}`,
    borderBottom: `1px solid ${theme.palette.color.darkGrey}`,
  },
  tab: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.color.darkGrey,
    backgroundColor: theme.palette.color.black,
    cursor: 'pointer',
  },
  name: {
    fontWeight: 700,
  },
  selected: {
    backgroundColor: hexToRgba(theme.palette.color.primary, 0.15),
    color: theme.palette.color.white,
  },
  middleTab: {
    borderLeft: `1px solid ${theme.palette.color.darkGrey}`,
    borderRight: `1px solid ${theme.palette.color.darkGrey}`,
  },
}))

const TABS = [
  { name: 'trackDetails', step: 1 },
  { name: 'contributors', step: 2 },
  { name: 'featuredArtists', step: 3 },
]

export const AddSongMetadataTabMenu = ({ metadataStep, setMetadataStep }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  const tabsToRender = TABS.map((tab, index) => (
    <div
      key={generateUniqueKey(tab, index)}
      className={cn(classes.tab, {
        [classes.selected]: metadataStep === tab.step,
        [classes.middleTab]: index === 1,
      })}
      onClick={() => setMetadataStep(index + 1)}
    >
      <span className={classes.name}>{t(tab.name)}</span>
    </div>
  ))

  return <div className={classes.container}>{tabsToRender}</div>
}

AddSongMetadataTabMenu.propTypes = {
  metadataStep: number.isRequired,
  setMetadataStep: func.isRequired,
}
