import React from 'react'
import { PaymentContainer } from '#pages/subscriptions/_containers/payment-container'
import { PageWrapper } from '#modules/page-wrapper'

const pageWrapperStyles = () => {
  return {
    maxWidth: 1200,
  }
}

export const Payment = () => {
  return (
    <PageWrapper makeCustomStyles={pageWrapperStyles} showNavBar showFooter>
      <PaymentContainer />
    </PageWrapper>
  )
}
