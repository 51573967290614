import React from 'react'
import { bool, func, node, string } from 'prop-types'
import styled from 'styled-components'

const SRadioWrapper = styled.div`
  display: flex;
  align-items: ${({ isVertical }) => (isVertical ? 'flex-start' : 'center')};
`

const SRadioInput = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: ${({ theme: { colors } }) => colors.black};
  border: 1px solid ${({ hasError }) =>
    hasError
      ? ({ theme: { colors } }) => colors.error
      : ({ theme: { colors } }) => colors.darkGrey66};
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.white};
  position: relative;
  margin-top: ${({ isVertical }) => (isVertical ? '16px' : 0)};,

  ${({ checked }) =>
    checked &&
    `
    &:checked {
      &::before {
        content: '';
        width: 7px;
        height: 15px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        position: absolute;;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      },
    },
  `}
`

const SLabelWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};,
  cursor: pointer;
`

const SLabel = styled.label`
  text-transform: capitalize;
  margin-left: 10px;
  font-size: ${({ isVertical }) => (isVertical ? '18px' : '14px')};
  font-family: ${({ isLabelBolded }) =>
    isLabelBolded
      ? ({ theme: { typography } }) => typography.bold
      : ({ theme: { typography } }) => typography.fontFamily};
`

export const RadioCheckbox = ({
  register,
  id,
  name,
  onChange,
  checked,
  hasError,
  disabled,
  label,
  customLabel,
  isVertical,
  isLabelBolded,
  className,
}) => {
  return (
    <SRadioWrapper className={className} isVertical={isVertical}>
      <SRadioInput
        type="radio"
        disabled={disabled}
        isVertical={isVertical}
        hasError={hasError}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        {...(register && register(name))}
      />
      <SLabelWrapper isVertical={isVertical}>
        {label && (
          <SLabel htmlFor={id} isLabelBolded={isLabelBolded}>
            {label} {customLabel}
          </SLabel>
        )}
      </SLabelWrapper>
    </SRadioWrapper>
  )
}

RadioCheckbox.propTypes = {
  name: string.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  onChange: func,
  checked: bool,
  disabled: bool,
  hasError: bool,
  customLabel: node,
  register: func,
  isVertical: bool,
  isLabelBolded: bool,
}

RadioCheckbox.defaultProps = {
  onChange: null,
  checked: false,
  disabled: false,
  hasError: false,
  customLabel: null,
  register: null,
  isVertical: false,
  isLabelBolded: false,
}
