import React, { createContext, useContext, useEffect, useState } from 'react'
import * as fcl from '@onflow/fcl'
import { node } from 'prop-types'

const BlockchainContext = createContext()

export const useBlockchainAccount = () => {
  const context = useContext(BlockchainContext)

  if (context === undefined) {
    throw new Error(
      'BlockchainContext must be used within a BlockchainProvider'
    )
  }

  return context
}

export const BlockchainProvider = ({ children }) => {
  const [blockchainAccount, setBlockchainAccount] = useState({
    loggedIn: null,
    addr: null,
  })

  useEffect(() => {
    fcl.currentUser.subscribe(setBlockchainAccount)

    return () => {
      setBlockchainAccount({
        loggedIn: null,
        addr: null,
      })
    }
  }, [setBlockchainAccount])

  useEffect(() => {
    const saveWallet = () => {
      if (blockchainAccount.addr) {
        setBlockchainAccount(prev => ({
          ...prev,
        }))
      }
    }

    saveWallet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchainAccount.addr])

  return (
    <BlockchainContext.Provider
      value={{
        blockchainAddress: blockchainAccount.addr,
        isWalletConnected: !!blockchainAccount.loggedIn,
        connectWallet: fcl.logIn,
        disconnectWallet: fcl.unauthenticate,
        changeWallet: fcl.reauthenticate,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  )
}

BlockchainProvider.propTypes = {
  children: node.isRequired,
}
