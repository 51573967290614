import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { StepSubHeader } from '#pages/sell-collectibles/create-collectible/_components/step-subheader'
import { DateInput } from '#pages/sell-collectibles/create-collectible/_components/steps/details/_components/date_input'
import { Divider } from '#pages/sell-collectibles/create-collectible/_components/divider'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SUnderline = styled.u`
  text-decoration: underline;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const ReleaseDate = () => {
  const { t } = useTranslation('nftWizard')

  const { resetField } = useFormContext()

  useEffect(() => {
    resetField(NFT_DETAILS_STEP_FIELDS.RELEASE_DATE)
  }, [])

  return (
    <>
      <StepSubHeader title={t('steps.details.releaseDate')} />
      <SText>
        <Trans t={t} i18nKey="steps.details.releaseDateInfo">
          <SUnderline />
        </Trans>
      </SText>
      <DateInput />
      <Divider />
    </>
  )
}
