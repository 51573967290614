import React from 'react'
import styled, { css } from 'styled-components'
import { arrayOf, bool, func, shape, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, Button, ButtonTheme } from '@tunego/tunego-ui-components'

const SModal = styled(Modal)`
  h2 {
    margin: 0;
  }
`

const STitle = styled.span`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  font-family: inherit;
`

const SButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
`

const SText = styled.p`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SWarningText = styled.p`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

const SBlocked = styled.p`
  ${({
    bold,
    theme: {
      tunego: { COLORS, FONT },
    },
  }) =>
    bold
      ? css`
          font-family: ${FONT.FAMILY.bold};
        `
      : css`
          color: ${COLORS.neutral_50};
        `};
`

const SButtonCancel = styled(Button)`
  flex-grow: 0;
`

const SButtonDelete = styled(Button)`
  flex-grow: 1;
`

export const OverwriteFilesModal = ({
  open,
  handleClose,
  handleConfirm,
  files,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const filesToOverwrite = files.filter(file => !file.isUsedInQuicklisting)
  const blockedFiles = files.filter(file => file.isUsedInQuicklisting)

  return (
    <SModal
      isOpen={open}
      title={<STitle>{t('warning')}</STitle>}
      closeButton
      onClose={handleClose}
    >
      <div>
        {blockedFiles.length > 0 && (
          <SBlocked bold={filesToOverwrite.length === 0}>
            {vaultTranslation.t('theFollowingFilesAreUsedInQuicklisting', {
              files: blockedFiles.map(({ file }) => ` ${file.name}`),
            })}
          </SBlocked>
        )}
        {filesToOverwrite.length > 0 && (
          <div>
            <SText>
              {vaultTranslation.t('theFollowingFilesExist', {
                files: filesToOverwrite.map(({ file }) => ` ${file.name}`),
              })}
            </SText>
            <SWarningText>
              {vaultTranslation.t('doYouWantToUpload')}
            </SWarningText>
          </div>
        )}
        <SButtonWrapper center={filesToOverwrite.length === 0}>
          <SButtonCancel
            buttonTheme={ButtonTheme.Secondary_Warning}
            onClick={handleClose}
          >
            {t('cancel')}
          </SButtonCancel>
          {filesToOverwrite.length > 0 && (
            <SButtonDelete
              buttonTheme={ButtonTheme.Warning}
              type="submit"
              onClick={handleConfirm}
            >
              {t('confirm')}
            </SButtonDelete>
          )}
        </SButtonWrapper>
      </div>
    </SModal>
  )
}

OverwriteFilesModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleConfirm: func.isRequired,
  files: arrayOf(shape({ file: object })).isRequired,
}
