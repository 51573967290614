import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useWizard } from '#modules/forms/form-wizard/context'
import { ReleaseDetailsComponent } from '#pages/distro/wizard/steps/release-details/components/release-details-component'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { releaseDetailsValidationSchema } from '#pages/distro/_utils/validation'
import { formatDate } from '#utils/formatDate'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { FIELDS, CONTENT_TYPE_OPTIONS } from '#pages/distro/_utils/constants'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useError } from '#hooks/useError'

export const ReleaseDetailss = () => {
  const { t } = useTranslation('distro')
  const { setStoredFormState, incrementStep, storedFormState } = useWizard()
  const { error, setResponseError, clearError } = useError()

  const { actions, loading } = useWithAsyncAction({
    addReleaseMetadata: DistroApi.addReleaseMetadata,
    addImageFile: DistroApi.addImageFile,
  })
  // eslint-disable-next-line consistent-return
  const customSubmitAction = async data => {
    const {
      releasedBefore,
      releaseCopyrightOwner,
      releaseCopyrightYear,
      artWorkCopyrightOwner,
      artWorkCopyrightYear,
      explicitContent,
      labelName,
      primaryArtistName,
      previousReleaseDate,
      genre,
      secondaryGenre,
      releaseName,
      releaseVersion,
      artworkExplicit,
      upc,
      clearArtwork,
      noLogos,
      noOtherText,
      textMatches,
      recordingYear,
    } = data
    const formattedToday = formatDate()
    const formattedRelease = formatDate(releasedBefore)
    const isReleaseAfterToday = dayjs(formattedRelease).isAfter(formattedToday)

    if (isReleaseAfterToday) return null

    try {
      if (storedFormState[FIELDS.ARTWORK_FILE]) {
        await actions.addImageFile(
          storedFormState[FIELDS.DISTRO_ID],
          storedFormState[FIELDS.ARTWORK_FILE]
        )
      }

      await actions.addReleaseMetadata(storedFormState[FIELDS.DISTRO_ID], {
        releaseCopyrightOwner,
        releaseCopyrightYear: releaseCopyrightYear.value,
        artworkCopyrightOwner: artWorkCopyrightOwner,
        artworkCopyrightYear: artWorkCopyrightYear.value,
        explicitContent: explicitContent.value,
        labelName,
        previousReleaseDate,
        primaryArtistName,
        primaryGenreId: genre.value,
        secondaryGenreId: secondaryGenre.value,
        releaseName,
        releaseVersion: releaseVersion.value,
        artworkExplicit:
          artworkExplicit.value === CONTENT_TYPE_OPTIONS[0].value,
        upc,
        attestArtwork: clearArtwork && noLogos && noOtherText && textMatches,
        recordingYear: recordingYear.value,
      })

      setStoredFormState({ ...data })
      incrementStep()
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <StepContainer
      customSubmitAction={customSubmitAction}
      resolver={yupResolver(releaseDetailsValidationSchema(t))}
    >
      <ReleaseDetailsComponent />
      <CommonLoadingOverlay
        dialogOpen={loading.addImageFile || loading.addReleaseMetadata}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </StepContainer>
  )
}
