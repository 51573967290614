import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { arrayOf, bool, shape, string, object, node } from 'prop-types'

const SWrapper = styled.div`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

const STitle = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  margin-top: 0;
  font-family: ${({ theme: { typography } }) => typography.bold};
  ${({ primary, theme: { colors } }) =>
    primary &&
    css`
      color: ${colors.primary};
    `};
`

const SText = styled.p`
  line-height: 1.5;
  margin: 0;
`

const SNote = styled(SText)`
  margin-top: 1.5rem;
`

const SBold = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin-right: 0.2rem;
`

export const Requirements = ({
  className,
  showFooterNote,
  primary,
  requirements,
  title,
  children,
}) => {
  const { t } = useTranslation('nftWizard')
  return (
    <SWrapper className={className}>
      <STitle primary={primary}>{title}</STitle>

      {requirements.map(({ i18nKey, values }) => (
        <SText key={i18nKey}>
          <Trans i18nKey={i18nKey} ns="nftWizard" values={values}>
            <SBold />
          </Trans>
        </SText>
      ))}

      {children}

      {showFooterNote && <SNote>{t('requirementsNote')}</SNote>}
    </SWrapper>
  )
}

Requirements.propTypes = {
  showFooterNote: bool,
  className: string,
  primary: bool,
  title: string.isRequired,
  requirements: arrayOf(
    shape({
      i18nKey: string.isRequired,
      values: object,
    })
  ),
  children: node,
}

Requirements.defaultProps = {
  showFooterNote: true,
  primary: false,
  requirements: [],
}
