import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SelectProfile } from '#pages/nft/wizard/steps/select-profile'
import { FormStepsWrapper } from '#modules/forms/components/form-steps-wrapper'
import { useQuery } from '#hooks/useQuery'
import { SelectReviewType } from '#pages/promotion/wizard/steps/select-review-type'
import { SelectSong } from '#pages/promotion/wizard/steps/select-song'
import { SelectArtistProfile } from '#pages/promotion/wizard/steps/select-artist-profile'
import { Checkout } from '#pages/promotion/wizard/steps/checkout'

const useStyles = makeStyles(theme => {
  return {
    borderedStep: {
      border: `1px solid ${theme.palette.color.darkGrey}`,
    },
  }
})

export const WizardStepsContainer = () => {
  const { step, setStep, setStoredFormState } = useWizard()
  const classes = useStyles()

  const type = useQuery('type')
  const artistId = useQuery('artistId')

  const setWizardStep = () => {
    if (type && artistId) {
      setStep(3)
      setStoredFormState({ planType: type, parentId: artistId })
    } else if (type && !artistId) {
      setStep(2)
      setStoredFormState({ planType: type })
    } else if (!type && !artistId) {
      setStep(1)
      setStoredFormState()
    }
  }

  useEffect(() => {
    setWizardStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, artistId])

  const renderStep = () => {
    switch (step) {
      case 1:
        return <SelectReviewType />
      case 2:
        return <SelectArtistProfile />
      case 3:
        return <SelectSong />
      case 4:
      case 5:
        return <Checkout />
      default:
        return <SelectProfile />
    }
  }

  return (
    <FormStepsWrapper className={step === 1 ? '' : classes.borderedStep}>
      {renderStep()}
    </FormStepsWrapper>
  )
}
