import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { shape } from 'prop-types'
import { RadarChart } from '#pages/promotion/report/components/radar-chart'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  elementAnalysisChartContainer: {
    height: '600px',
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
  },
})

export const ElementAnalysis = ({ data }) => {
  const classes = useStyles()
  return (
    <ReviewRowElement
      refName="elementAnalysisEl"
      title="elementAnalysis"
      description="thisAnalysisProvidesInsight"
    >
      <div className={classes.elementAnalysisChartContainer} width={1}>
        <RadarChart
          data={data}
          ticks={[0, 20, 40, 60, 80, 100]}
          domain={[0, 100]}
          showBarLabels
          showDotLabels
        />
      </div>
    </ReviewRowElement>
  )
}

ElementAnalysis.propTypes = {
  data: shape({}).isRequired,
}
