import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { FilesApi } from '#api/requests/files'
import { DeleteModal } from '#pages/_components/delete-modal'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { useError } from '#hooks/useError'
import { useReload } from '#modules/page-wrapper/context'
import { Button, ButtonSize, ButtonTheme } from '@tunego/tunego-ui-components'
import { TOOLTIP_THEME } from '#components/tooltip/tooltipStyle'
import { Tooltip } from '#components/tooltip'

const SWrapper = styled.div`
  flex-grow: 1;
  padding-left: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
`

export const CheckboxActionButtons = ({
  checkedFiles,
  setCheckedFiles,
  accessRoles,
}) => {
  const { t } = useTranslation('vault')
  const [isBulkRemoveModalOpen, setIsBulkRemoveModalOpen] = useState(false)
  const [isBulkRemovalLoading, setIsBulkRemovalLoading] = useState(false)
  const { error, setError, setResponseError, clearError } = useError()

  const { setReloading } = useReload()

  const enableBulkRemoveModal = () => setIsBulkRemoveModalOpen(true)
  const disableBulkRemoveModal = () => setIsBulkRemoveModalOpen(false)

  const { actions, anyError } = useWithAsyncAction({
    getFileDownloadLink: FilesApi.getFileDownloadLink,
    deleteFile: FilesApi.deleteFile,
  })

  const handleSetError = useCallback(err => setError(err.message), [setError])

  useEffect(() => {
    if (anyError) {
      handleSetError(anyError)
    }
  }, [anyError, handleSetError])

  const isUserStructureOwner = accessRoles.some(role =>
    [ACCESS_ROLES.OWNER].includes(role)
  )

  const handleFilesRemove = async () => {
    try {
      setIsBulkRemovalLoading(true)
      await Promise.all(checkedFiles.map(file => actions.deleteFile(file.id)))
    } catch (err) {
      setResponseError(err)
    }

    setReloading()
    setCheckedFiles([])
    disableBulkRemoveModal()
    setIsBulkRemovalLoading(false)
  }

  const downloadFiles = fileLinks => {
    const downloadNext = i => {
      if (i >= fileLinks.length) {
        return
      }

      const a = document.createElement('a')
      a.href = fileLinks[i]

      if ('download' in a) {
        a.download = fileLinks[i]
      }

      ;(document.body || document.documentElement).appendChild(a)

      if (a.click) {
        a.click()
      }

      a.parentNode.removeChild(a)

      setTimeout(() => {
        downloadNext(i + 1)
      }, 1000)
    }
    downloadNext(0)
  }

  const handleFilesDownload = async () => {
    const filesRequests = checkedFiles.map(file => {
      return actions.getFileDownloadLink(file.id, true)
    })

    try {
      const response = await Promise.all(filesRequests)
      const responseLinks = response.map(res => {
        return res.data.link
      })
      downloadFiles(responseLinks)
    } catch (err) {
      setResponseError(err)
    }
  }

  const disableDelete = checkedFiles.some(file => file.isUsedInQuicklisting)

  return (
    <>
      {!isArrayEmpty(checkedFiles) && (
        <SWrapper>
          {isUserStructureOwner && (
            <Tooltip
              disabled={!disableDelete}
              text={t('fileUsedInDrop')}
              theme={TOOLTIP_THEME.WARNING}
            >
              <Button
                buttonSize={ButtonSize.Sm}
                buttonTheme={ButtonTheme.Warning}
                disabled={disableDelete}
                onClick={enableBulkRemoveModal}
              >
                {t('deleteFiles')}
              </Button>
            </Tooltip>
          )}
          <Button buttonSize={ButtonSize.Sm} onClick={handleFilesDownload}>
            {t('downloadFiles')}
          </Button>
          <DeleteModal
            handleClose={disableBulkRemoveModal}
            open={isBulkRemoveModalOpen}
            handleDelete={handleFilesRemove}
            firstSpan={t('areYouSureYouWantDelete', {
              structure: t('files(s)'),
            })}
            secondSpan={t('thisActionIsPermanent')}
            thirdSpan={t('pleaseConfirmThatYouWantDeleteStructure', {
              structure: `${t('files(s)')}.`,
            })}
            deleteBtnText={t('yesPermanentlyDeleteStructure', {
              structure: t('files(s)'),
            })}
          />
        </SWrapper>
      )}
      <CommonLoadingOverlay
        error={error}
        dialogOpen={isBulkRemovalLoading}
        message={t('deletingFiles')}
        isOwner={isUserStructureOwner}
        onErrorBackdropClick={clearError}
      />
    </>
  )
}
