import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'

export const useFingerPrints = (structureId, query = '') => {
  const { data, error, ...rest } = useSWR(
    `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_FINGERPRINTS, {
      structureId,
    })}${query}`
  )
  return {
    fingerprints: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
