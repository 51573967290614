/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import { TermsWrapper } from '#pages/_components/terms-wrapper'
import styled from 'styled-components'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  padding: 0 24px 70px;

  li {
    margin: 0.625rem 0;
  }

  b {
    font-family: ${({
      theme: {
        tunego: { FONT },
      },
    }) => FONT.FAMILY.bold};
  }

  a {
    color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  }
`

export const PrivacyPolicyText = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TermsWrapper>
      <SWrapper>
        <h1>TuneGO Privacy Policy</h1>
        <p>
          <span>Revised: May 15, 2024</span>
        </p>
        <p>
          <span>This TuneGO Privacy Policy (the &ldquo;</span>
          <b>Privacy Policy</b>
          <span>
            &rdquo;) serve as a legal agreement between TuneGO, Inc., a Delaware
            Corporation, (&ldquo;
          </span>
          <b>TuneGO</b>
          <span>,&rdquo; </span>
          <b>we</b>
          <span>,&rdquo; &ldquo;</span>
          <b>our</b>
          <span>,&rdquo; &ldquo;</span>
          <b>us</b>
          <span>
            &rdquo;) and you, the visitor, with respect to how we collect, use,
            protect, and disclose information and data when you use any of our
            Services, Site(s) or App(s). Unless otherwise defined in this
            Privacy Policy, capitalized terms shall have the definition as
            proscribed to them in our Terms of Use (the &ldquo;Terms&rdquo;).
            &nbsp;For clarification and avoidance of doubt, when in this Privacy
            Policy we use the term &ldquo;TuneGO&rdquo;, &ldquo;we&rdquo;,
            &ldquo;our&rdquo;, or &ldquo;us&rdquo;, we are referring to TuneGO,
            Inc., any and all affiliates and/or subsidiaries of TuneGO Inc., the
            TuneGO marketplace located at Marketplace.TuneGO.com, and any and
            all proprietary TuneGO mobile applications available on either iOS
            or Android. &nbsp;
          </span>
        </p>
        <p>
          <span>
            Your information privacy is important to us. We provide this Privacy
            Policy to explain how we collect, use, protect, and disclose
            information and data when you use TuneGO.com and websites, other
            sites to which these Terms are applied and subdomains of the
            foregoing (collectively, the &quot;
          </span>
          <span>Site</span>
          <span>
            &quot;) and any TuneGO mobile application, other content,
            applications, features, functionality, information and services
            offered by TuneGO, and any other time you interact with us
            (collectively, the &quot;
          </span>
          <span>Services</span>
          <span>
            &quot;). This Privacy Policy also explains your choices for managing
            your information preferences, including opting out of certain uses
            of your Personal Information (defined below). This Privacy Policy
            applies to all users of the Site and Services. The Site and Services
            are provided by TuneGO, Inc. (&quot;
          </span>
          <span>Company</span>
          <span>&quot; &quot;</span>
          <span>we</span>
          <span>&quot; &quot;</span>
          <span>our</span>
          <span>&quot; &quot;</span>
          <span>us</span>
          <span>&quot;).</span>
        </p>
        <p>
          <span>
            BY USING THE SITE OR SERVICES, YOU ARE CONSENTING TO THIS PRIVACY
            POLICY. PLEASE READ IT CAREFULLY.
          </span>
        </p>
        <p>
          <b>Personal Information We Collect</b>
        </p>
        <p>
          <span>We collect the following categories and types of &quot;</span>
          <b>Personal Information</b>
          <span>&quot;:</span>
        </p>
        <ul>
          <li>
            <b>Contact Information</b>
            <span>
              : your first and last name, mailing address, email address, and
              phone number;
            </span>
          </li>
          <li>
            <b>Other identifying information</b>
            <span>
              : IP address, social media usernames, avatars, passwords and other
              security information for authentication and access;
            </span>
          </li>
          <li>
            <b>Financial Information</b>
            <span>
              : credit card, debit card, digital wallet, public key and bank
              account information;
            </span>
          </li>
          <li>
            <b>Demographic information</b>
            <span>
              : gender, age, employment information and salary information;
            </span>
          </li>
          <li>
            <b>Geolocation data</b>
            <span>:</span>
          </li>
          <li>
            <b>Internet or other electronic activity</b>
            <span>
              : your browsing and click history, including information about how
              you navigate within our Site and Services and which elements of
              our Site and Services you use the most;
            </span>
          </li>
          <li>
            <b>Commercial information</b>
            <span>
              : products and services purchased or viewed on our Site;
            </span>
          </li>
          <li>
            <b>Audio and visual information</b>
            <span>: your photos, avatar images; and</span>
          </li>
          <li>
            <b>Inferences drawn from the categories described above</b>
            <span>
              &nbsp;in order to create a profile about you to reflect your
              preferences, characteristics, behavior and attitude.
            </span>
          </li>
        </ul>
        <p>
          <b>How We Use Personal Information</b>
        </p>
        <p>
          <span>
            We use your Personal Information for the following categories of
            use:
          </span>
        </p>
        <ul>
          <li>
            <b>Transactional Purposes</b>
            <span>
              : We use your contact information, financial information, and
              commercial information to:
            </span>
            <ul>
              <li>
                <span>
                  Receive, process, confirm, send and track your order or
                  subscription; and
                </span>
              </li>
              <li>
                <span>
                  Communicate with you about your order, subscription or
                  registration.
                </span>
              </li>
            </ul>
          </li>

          <li>
            <b>Analytical Purposes</b>
            <span>
              : We use your other identifying information, internet activity and
              browsing history, commercial information, demographic information,
              and geolocation data to analyze preferences, trends and
              statistics.
            </span>
          </li>
          <li>
            <b>Marketing and Promotional Purposes</b>
            <span>
              : We use your contact information, commercial information,
              demographic information, internet or other electronic activity,
              geolocation data, and inferences to:
            </span>
            <ul>
              <li>
                <span>
                  Inform you of our new products, services and offers;
                </span>
              </li>
              <li>
                <span>Provide you with targeted advertising;</span>
              </li>
              <li>
                <span>Run contests, promotions and sweepstakes; and</span>
              </li>
              <li>
                <span>
                  Provide you with other information from and about our Company,
                  including personalized marketing communications.
                </span>
              </li>
            </ul>
          </li>

          <li>
            <b>Maintenance and Improvement of the Site and Services</b>
            <span>
              : We use your contact information, commercial information, and
              internet activity and browsing history to:
            </span>
            <ul>
              <li>
                <span>
                  Provide you with the Site and Services, including to send you
                  alerts about your account;
                </span>
              </li>
              <li>
                <span>Handle your customer services requests; and</span>
              </li>
              <li>
                <span>
                  Help us diagnose technical and service problems and administer
                  our stores, the Site, and the Services.
                </span>
              </li>
            </ul>
          </li>

          <li>
            <b>Review and content creation purposes</b>
            <span>
              : We use your contact information, commercial information, and
              audio and visual information to enable reviews of our products and
              to display content that you have created and allowed us to display
              on our Site and Services and on social media.
            </span>
          </li>
          <li>
            <b>Security and Fraud Prevention</b>
            <span>
              : We use your contact information, other identifying information,
              commercial information, financial information, geolocation data,
              internet activity and browsing history, and inferences to protect
              the Site and services, our Company, and others and to prevent
              fraud, theft and misconduct.
            </span>
          </li>
        </ul>
        <p>
          <b>Sources of Personal Information</b>
        </p>
        <p>
          <span>
            We collect Personal Information from the following sources:
          </span>
        </p>
        <ul>
          <li>
            <b>We collect information directly from you</b>
            <span>
              . We collect contact and demographic information directly from
              you. We also collect financial information from you.
            </span>
          </li>
          <li>
            <b>We collect information about you from third parties</b>
            <span>
              . We collect your Personal Information from third parties when
              necessary to provide you the Services (e.g., to collect your
              digital wallet address, or when you are invited to use the
              Services by other users).
            </span>
          </li>
          <li>
            <b>We collect information from you passively</b>
            <span>
              . We collect internet or other electronic activity passively using
              tools like browser cookies. This activity is further described in
              the Cookies and Advertising and Online Tracking sections below.
            </span>
          </li>
        </ul>
        <p>
          <b>Categories of Third Parties We Share Personal Information With</b>
        </p>
        <p>
          <i>Service Providers</i>
        </p>
        <p>
          <span>
            From time to time, we may establish a business relationship with
            other businesses whom we believe trustworthy and who have confirmed
            that their privacy practices are consistent with ours (&quot;
          </span>
          <b>Service Providers</b>
          <span>
            &quot;). For example, we may contract with Service Providers to
            provide certain services, such as hosting and maintenance, data
            storage and management, customer support, and marketing and
            promotions. We only provide our Service Providers with the
            information necessary for them to perform these services on our
            behalf. Each Service Provider must agree to use reasonable security
            procedures and practices, appropriate to the nature of the
            information involved, in order to protect your Personal Information
            from unauthorized access, use, or disclosure. Service Providers are
            prohibited from using Personal Information other than as specified
            by us.
          </span>
        </p>
        <p>
          <i>Our Affiliates</i>
        </p>
        <p>
          <span>
            We may share Personal Information with businesses controlling,
            controlled by, or under common control with our Company.
          </span>
        </p>
        <p>
          <i>Our Customers and Partners</i>
        </p>
        <p>
          <span>Part of providing the Services </span>
          <span>
            involves enhancing your experience by facilitating connections with
            our trusted partners, which include larger customers, creators,
            labels, catalog owners, and other stakeholders in the digital
            collectible ecosystem.
          </span>
        </p>
        <p>
          <span>
            In certain instances, we may share your personal information with
            these partners to enrich your experience and offer you tailored
            opportunities. The types of personal information we may share
            include:
          </span>
        </p>
        <ul>
          <li>
            <b>Email address: &nbsp;</b>
            <span>
              To allow our partners to communicate directly with you, providing
              updates, offers, and information related to the digital
              collectibles you&#39;ve interacted with (e.g., followed,
              purchased, sold, or claimed/collected);
            </span>
            <span>&nbsp;and</span>
          </li>
          <li>
            <b>Username and name: </b>
            <span>
              To personalize the communication and ensure that the information
              you receive is relevant to your interests and interactions on our
              platform.
            </span>
          </li>
        </ul>
        <p>
          <span>This </span>
          <span>
            sharing enables our partners to send you emails directly, ensuring
            that you&#39;re informed about the latest developments, offers, and
            opportunities that are most relevant to your interests in digital
            collectibles.
          </span>
        </p>
        <p>
          <b>Corporate Transactions</b>
        </p>
        <p>
          <span>
            If our Company is merged, acquired, or sold, or in the event of a
            transfer of some or all of our assets, we may disclose or transfer
            Personal Information in connection with such transaction. You will
            have the opportunity to opt out of any such transfer if, in our
            discretion, it will result in the handling of your Personal
            Information in a way that differs materially from this Privacy
            Policy.
          </span>
        </p>
        <p>
          <b>Compliance with Laws and Law Enforcement</b>
        </p>
        <p>
          <span>
            We cooperate with government and law enforcement officials and
            private parties to enforce and comply with the law. We may disclose
            Personal Information and any other information about you to
            government or law enforcement officials or private parties if, in
            our discretion, we believe it is necessary or appropriate in order
            to respond to legal requests (including court orders and subpoenas),
            to protect the safety, property, or rights of our company or of any
            third party, to prevent or stop any illegal, unethical, or legally
            actionable activity, or to comply with the law.
          </span>
        </p>
        <p>
          <b>Your Choices and Consent</b>
        </p>
        <p>
          <span>Your privacy </span>
          <span>
            is paramount, and the sharing of your information with our partners
            is conducted with your consent and in accordance with applicable
            laws and regulations. You have the right to opt out of this sharing
            at any time by adjusting your privacy settings or contacting our
            support team. We will always provide clear options for managing your
            preferences regarding data sharing.
          </span>
        </p>
        <p>
          <b>Safeguarding Your Information</b>
        </p>
        <p>
          <span>
            We take stringent measures to ensure that your personal information
            is protected. Our partners are carefully selected and bound by
            confidentiality agreements to ensure that they provide a level of
            data protection that aligns with our privacy standards. We also
            implement a range of technical, administrative, and physical
            safeguards designed to protect your personal information against
            unauthorized access, disclosure, alteration, and destruction.
          </span>
        </p>
        <p>
          <b>Changes to Our Sharing Practices</b>
        </p>
        <p>
          <span>
            We may update this section of our Privacy Policy from time to time
            to reflect changes in our practices or for other operational, legal,
            or regulatory reasons. If we make significant changes, before they
            become effective.
          </span>
        </p>
        <p>
          <b>Cookies</b>
        </p>
        <p>
          <span>
            We use cookies (a small text file placed on your computer to
            identify your computer and web browser) and may use anonymous
            identifiers (a random string of characters that is used for the same
            purposes as a cookie). We use cookies and other similar technologies
            to analyze use of and improve the Site and Services and as described
            in the Advertising and Online Tracking Section of this Privacy
            Policy. Most web browsers are initially set up to accept cookies.
            You can reset your web browser to refuse all cookies or to indicate
            when a cookie is being sent, however, certain features of the Site
            or Services may not work if you delete or disable cookies. Some of
            our Service Providers (defined below) may use their own cookies,
            anonymous identifiers, or other tracking technology in connection
            with the services they perform on our behalf.
          </span>
        </p>
        <p>
          <span>
            We use Google Analytics on the Site and Services to analyze how
            users use the Site and Services, and to provide advertisements to
            you on other websites. For more information about how to opt out of
            having your information used by Google Analytics, visit{' '}
          </span>
          <span>
            <a href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1718111079340913&amp;usg=AOvVaw3zHjYQLdy6yIMkI56P3rkk">
              https://tools.google.com/dlpage/gaoptout/
            </a>
          </span>
          <span>.</span>
        </p>
        <p>
          <span>
            We also use a Hotjar to better understand our users&rsquo; needs and
            to optimize our Services. &nbsp;Hotjar is a technology service that
            helps us better understand our users&rsquo; experience (eg how much
            time users spend on which pages, links they choose to click, what
            users like and don&rsquo;t like, etc.) and this enables us to build
            and maintain our service with user feedback. &nbsp;Hotjar uses
            cookies and other technologies to collect data on our users&rsquo;
            behavior and devices. &nbsp;This includes a device&rsquo;s IP
            address (processed during your session and stored in de-identified
            form), device screen size, device type (unique device identifiers),
            browser information, geographic location (country only), and the
            preferred language used to display our website. &nbsp;Hotjar stores
            this information on our behalf in a pseudonymized user profile.
            &nbsp;Hotjar is contractually forbidden to sell any of the data
            collected on our behalf. &nbsp;
          </span>
        </p>
        <p>
          <span>
            For further details, you may refer to the &lsquo;about Hotjar&rsquo;
            section of Hotjar&rsquo;s support site.
          </span>
        </p>
        <p>
          <b>Advertising and Online Tracking</b>
        </p>
        <p>
          <span>
            We may allow third-party companies to serve ads and collect certain
            information when you visit the Site and Services. These companies
            may use certain information (e.g. click stream information, web
            browser type, time and date, subject of advertisements clicked or
            scrolled over) during your visits to the Site and other websites in
            order to provide advertisements about goods and services likely to
            be of interest to you. These companies typically use a cookie to
            collect this information. Our systems do not recognize browser
            &quot;Do Not Track&quot; signals, but several of our Service
            Providers who utilize these cookies on our Site enable you to opt
            out of targeted advertising practices. To learn more about these
            advertising practices or to opt out of this type of advertising, you
            can visit www.networkadvertising.org or www.aboutads.info/choices/.
            We also provide you with additional tools to opt out of marketing
            from us. You can learn about this in the &quot;Managing Your
            Information Preferences&quot; section of this Privacy Policy.
          </span>
        </p>
        <p>
          <b>Use of Geolocation and Push Notifications</b>
        </p>
        <p>
          <i>Geolocation Data Collection and Use</i>
        </p>
        <p>
          <span>
            Our applications are designed to enhance your experience by
            utilizing geolocation data to bring you closer to digital
            collectibles available in your vicinity. To provide this feature,
            our apps will request your permission to access your device&rsquo;s
            geolocation in both the foreground and background.
          </span>
        </p>
        <p>
          <span>The purpose of accessing your geolocation data includes:</span>
        </p>
        <ul>
          <li>
            <b>Identifying Availability</b>
            <span>
              : To determine if you are in an area where a digital collectible
              is available to be claimed.
            </span>
          </li>
          <li>
            <b>Proximity Alerts</b>
            <span>
              : To send you notifications when you enter a geographical area
              where digital collectibles are available for you to claim,
              ensuring that you don&#39;t miss out on any opportunities.
            </span>
          </li>
        </ul>
        <p>
          <span>
            By enabling geolocation services, you allow us to offer you a more
            personalized and engaging experience with our digital collectible
            platform.
          </span>
        </p>
        <p>
          <i>Push Notifications</i>
        </p>
        <p>
          <span>
            In conjunction with geolocation data, we use push notifications to
            inform you of digital collectibles in your immediate area. Upon
            downloading our application, you will be asked to grant permission
            for us to send these notifications to your device.
          </span>
        </p>
        <p>
          <span>The notifications serve the following purposes:</span>
        </p>
        <ul>
          <li>
            <b>Timely Updates</b>
            <span>
              : To alert you in real-time about digital collectibles as you
              enter a designated area.
            </span>
          </li>
          <li>
            <b>Engagement</b>
            <span>
              : To enhance your interaction with the platform by providing
              relevant information and reminders related to the digital
              collectibles.
            </span>
          </li>
        </ul>
        <p>
          <span>Managing Your Permissions</span>
        </p>
        <p>
          <span>
            You are in control of your data. You can manage your geolocation and
            push notification permissions at any time through your
            device&rsquo;s settings. Disabling these permissions may affect the
            functionality and the interactive features offered by our app
            related to geolocation-based collectibles.
          </span>
        </p>
        <p>
          <b>Commitment to Privacy</b>
        </p>
        <p>
          <span>
            We are committed to protecting your privacy and handling your data
            transparently. The geolocation data and the use of push
            notifications are integral to providing our service and are handled
            in strict compliance with our privacy policy and relevant laws. We
            do not share your precise geolocation data with partners without
            your explicit consent.
          </span>
        </p>
        <p>
          <span>
            We encourage you to review our full privacy policy to understand our
            data practices, your rights, and how we protect your information.
          </span>
        </p>
        <p>
          <b>Managing Your Information Preferences</b>
        </p>
        <p>
          <span>
            You can review, correct, update, or change your Personal Information
            or opt out of receiving certain e-mails by changing the relevant
            settings in your account or by e-mailing us at support@tunego.com.
            You can also opt out of receiving marketing e-mails from us by
            following the link provided at the bottom of all marketing e-mails
            you receive from us. You are able to opt out of receiving marketing
            e-mails from us, however, you cannot opt out of receiving all
            e-mails from us, such as e-mails about the status of your account.
            If you have questions or concerns regarding this Privacy Policy,
            please e-mail us at that same address.
          </span>
        </p>
        <p>
          <b>Be Careful When You Share Information with Others</b>
        </p>
        <p>
          <span>
            Please be aware that whenever you share information on any public
            area of the Site or Services, that information may be accessed by
            others. In addition, please remember that when you share information
            in any other communications with third parties, that information may
            be passed along or made public by others. This means that anyone
            with access to such information can potentially use it for any
            purpose, including sending unsolicited communications.
          </span>
        </p>
        <p>
          <b>Security</b>
        </p>
        <p>
          <span>
            We maintain physical, electronic, and procedural safeguards to
            protect the confidentiality and security of information transmitted
            to us. However, no data transmission over the Internet or other
            network can be guaranteed to be 100% secure. As a result, while we
            strive to protect information transmitted on or through the Site or
            Services, we cannot and do not guarantee the security of any
            information you transmit on or through the Site or Services, and you
            do so at your own risk.
          </span>
        </p>
        <p>
          <b>Links</b>
        </p>
        <p>
          <span>
            Our Site and Services may contain links to other websites or allow
            others to send you such links. A link to a third party&rsquo;s
            website does not mean that we endorse it or that we are affiliated
            with it. We do not exercise control over third-party websites. You
            access such third-party websites or content at your own risk. You
            should always read the privacy policy of a third-party website
            before providing any information to the website.
          </span>
        </p>
        <p>
          <b>Children&rsquo;s Privacy</b>
        </p>
        <p>
          <span>
            The Site and Services are intended for users who are 18 years old or
            older. We do not knowingly collect Personal Information from
            children under the age of 13. If we become aware that we have
            inadvertently received Personal Information from a child under the
            age of 13, we will delete such information from our records.
          </span>
        </p>
        <p>
          <b>Processing in the United States</b>
        </p>
        <p>
          <span>
            Please be aware that your Personal Information and communications
            may be transferred to and maintained on servers or databases located
            outside your state, province, or country. If you are located outside
            of the United States, please be advised that we process and store
            all information in the United States. The laws in the United States
            may not be as protective of your privacy as those in your location.
            By using the Site or Services, you are agreeing to the collection,
            use, transfer, and disclosure of your Personal Information and
            communications will be governed by the applicable laws in the United
            States.
          </span>
        </p>
        <p>
          <b>Notice to California Residents/Your California Privacy Rights</b>
        </p>
        <p>
          <span>
            To opt out of sharing your Personal Information with third parties
            for their direct marketing purposes, please e-mail us at
            support@tunego.com and clearly state your request, including your
            name, mailing address, e-mail address and phone number.
          </span>
        </p>
        <p>
          <b>
            Special Note for Users in the European Union (&quot;EU&quot;), the
            United Kingdom (&quot;UK&quot;), and Switzerland&mdash;Your Rights
            and Choices
          </b>
        </p>
        <p>
          <span>The data controller for the Site and Services is:</span>
        </p>
        <p>
          <span>TuneGO, Inc.</span>
        </p>
        <p>
          <span>2505 Anthem Village Dr. #E283</span>
        </p>
        <p>
          <span>Henderson, NV 89052</span>
        </p>
        <p>
          <span>
            We use the information we collect about you to pursue the following
            purposes, which are necessary for the performance of the contract
            between us to provide you with the products or services you
            requested or which we consider in our legitimate interests:
          </span>
        </p>
        <ul>
          <li>
            <span>To enable you to register to the Site and Services;</span>
          </li>
          <li>
            <span>
              To send you transaction-related emails or push notifications;
            </span>
          </li>
          <li>
            <span>
              To offer you the features for which you provide information;
            </span>
          </li>
          <li>
            <span>
              To handle any request you may have to our consumer services such
              as resolving your problem or question, manage any complaints or
              deal with any feedback you may provide us;
            </span>
          </li>
          <li>
            <span>
              For statistical analysis of aggregated data to help us segmenting
              our offering and develop our products and services;
            </span>
          </li>
          <li>
            <span>
              To better understand the way in which the Site and Services are
              working, to offer better experience to you when using the Site and
              Services, to create statistics in relation to the use of the Site
              and Services, and our other services and offerings in general; and
            </span>
          </li>
          <li>
            <span>
              To help us diagnose technical and service problems and administer
              the Site and Services.
            </span>
          </li>
        </ul>
        <p>
          <span>Based on your voluntary consent, we also use:</span>
        </p>
        <ul>
          <li>
            <span>
              your browsing data to better understand your profile, interests,
              preferences to enhance your user experience and facilitate your
              experience and offer you relevant information and promotional
              materials and interest-based advertising; and
            </span>
          </li>
          <li>
            <span>
              your Personal Information and we may combine these with
              third-party data to send you personalized emails and push
              notifications tailored to you and your interests.
            </span>
          </li>
        </ul>
        <p>
          <span>
            In order to comply with our legal obligations, we may also use some
            of your Personal Information to detect and prevent illegal uses,
            abuse, spam, fraud, security incidents and other harmful activity or
            government or court orders.
          </span>
        </p>
        <p>
          <span>
            You may have certain rights under EU, UK or Swiss data protection
            legislation with respect to your Personal Information, such as the
            right to:
          </span>
        </p>
        <ul>
          <li>
            <span>
              request access to and be provided with information on the
              processing of your Personal Information;
            </span>
          </li>
          <li>
            <span>
              request corrections of your Personal Information if such Personal
              Information proves inaccurate or incomplete;
            </span>
          </li>
          <li>
            <span>
              request your Personal Information to be deleted (i) if it is no
              longer necessary for the purposes described in this Privacy
              Policy, (ii) should you decide to withdraw your consent where you
              gave it, (iii) should you object to its processing for direct
              marketing purposes, (iv) should you identify that it would have
              been unlawfully processed, (v) should we be under a legal
              obligation to erase it to comply with EU law, the law of an EU
              country, Switzerland or the UK, and always provided we are not
              under a legal obligation to keep it;
            </span>
          </li>
          <li>
            <span>
              restrict the processing of your Personal Information (i) if you
              contest its accuracy; (ii) if the processing is unlawful and you
              oppose the erasure; (iii) if it is no longer needed for the
              purposes set out in this Privacy Policy but you require it for the
              establishment, exercise or defense of a legal claim, (iv) if you
              object to profiling;
            </span>
          </li>
          <li>
            <span>
              object to the processing of your Personal Information for direct
              marketing purposes or to any processing which we would have based
              on our legitimate interest (e.g. profiling);
            </span>
          </li>
          <li>
            <span>
              receive Personal Information which you provided to us in a
              structured, commonly used and machine-readable format and to
              transmit it to another provider provided it is processed based on
              your consent or our contract; and
            </span>
          </li>
          <li>
            <span>
              lodge a complaint with your relevant data protection authority.
            </span>
          </li>
        </ul>
        <p>
          <span>
            If you wish to exercise any of your above rights, please reach out
            to us by any of the ways mentioned in the &quot;Managing Your
            Information Preferences&quot; section of this Privacy Policy.
          </span>
        </p>
        <p>
          <span>
            Regarding the transfer of Personal Information to countries outside
            the EU, Switzerland, or the UK, we have entered, or will enter, into
            so-called &quot;Standard Contractual Clauses&quot; approved by the
            applicable data protection authorities for international transfer of
            personal information to controllers or processors established in
            third counties as the case may be, when appropriate.
          </span>
        </p>
        <p>
          <span>
            The principle that we apply is that your Personal Information will
            be deleted when it is no longer necessary for the purpose for which
            it was collected.
          </span>
        </p>
        <p>
          <b>Privacy Policy Changes</b>
        </p>
        <p>
          <span>
            We may change this Privacy Policy from time to time. If we decide to
            change this Privacy Policy, we will inform you by posting the
            revised Privacy Policy on the Site. Those changes will go into
            effect on the &quot;Revised&quot; date shown in the revised Privacy
            Policy. By continuing to use the Site or Services, you are
            consenting to the revised Privacy Policy.
          </span>
        </p>
        <p>
          <span>Contact Us</span>
        </p>
        <p>
          <span>
            If you have any questions or concerns, you may contact us at{' '}
            <a href="mailto:support@tunego.com">support@tunego.com</a>.
          </span>
        </p>
        <p>
          <b>
            PLEASE PRINT A COPY OF THIS PRIVACY POLICY FOR YOU RECORDS AND
            PLEASE CHECK THE SITE FREQUENTLY FOR ANY CHANGES.
          </b>
        </p>
      </SWrapper>
    </TermsWrapper>
  )
}
