import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { string } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => {
  return {
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '60px',
      width: '150px',
      border: `2px solid ${theme.palette.color.primary}`,
      backgroundColor: hexToRgba(theme.palette.color.primary, 0.15),
      transition: '0.3s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: hexToRgba(theme.palette.color.primary, 0.5),
      },
    },
    input: {
      appearance: 'none',
      position: 'absolute',
      '&:checked + label': {
        backgroundColor: hexToRgba(theme.palette.color.primary, 0.5),
      },
    },
  }
})

export const StyleTile = ({ name }) => {
  const classes = useStyles()
  const [t] = useTranslation('mastering')

  const { register } = useFormContext()

  return (
    <>
      <input
        type="radio"
        id={name}
        className={classes.input}
        {...register('genre')}
        value={name}
      />
      <label htmlFor={name} className={classes.label}>
        {t(`styles.${name}`)}
      </label>
    </>
  )
}

StyleTile.propTypes = {
  name: string.isRequired,
}
