import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { NAVIGATION_PATHS } from '#routes/routes'
import { BaseLoader } from '#components/loaders/base-loader'
import { Pagination } from '#components/pagination'
import { usePromotions } from '#hooks/swr/usePromotions'
import { ReportsList } from '../../components/reports-list'
import { TableHeader } from '#modules/table/components/table-header'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { CustomSelect } from '#components/inputs/custom-select'
import { Breadcrumbs } from '#components/breadcrumbs'
import { useGetProfiles } from '#components/inputs/utils'
import { PROFILE_TYPES } from '#constants/profileTypes'
import {
  promotionBreadcrumbs,
  HEADERS,
} from '#pages/promotion/_utils/constants'
import { hexToRgba } from '#utils/hexToRgba'
import { isArrayEmpty } from '#utils/isArrayEmpty'

const useStyles = makeStyles(theme => ({
  columnWrapper: {
    maxWidth: 1050,
    minHeight: 'calc(100vh - 130px)',
    margin: '0 auto',
    position: 'relative',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 80,
    background: theme.palette.color.darkBlue03,
    flexGrow: 1,
  },
  info: {
    color: hexToRgba(theme.palette.color.default, 0.4),
    fontSize: '36px',
    fontWeight: 700,
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
  },
}))

const SButton = styled(Button)`
  height: 30px;
  margin-right: 28px;
  margin-left: auto;
`

const SCustomSelect = styled(CustomSelect)`
  width: 300px;
  margin-left: 28px;
`

export const ReportsListContainer = () => {
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { t } = useTranslation('promotion')
  const classes = useStyles()
  const profiles = useGetProfiles(PROFILE_TYPES.PROMOTION)
  const [profileId, setProfileId] = useState(profiles[0].value)
  const { promotions, isLoading } = usePromotions(
    `?sortBy=updatedAt&sortDirection=DESC&page=${pageIndex}&limit=${rowsPerPage}&profileId=${profileId}`
  )
  const breadcrumbs = promotionBreadcrumbs(t)
  const handleNewPromotion = () => navigate(NAVIGATION_PATHS.PROMOTION_NEW)
  const onChangeRowsPerPageHandler = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPageIndex(0)
  }

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={classes.columnWrapper}>
        <div className={classes.topBar}>
          <SCustomSelect
            options={profiles}
            defaultValue={profiles[0]}
            isForProfiles
            onChange={setProfileId}
          />
          <SButton
            variant={VARIANTS.NEW_BLUE_SPECIAL}
            onClick={handleNewPromotion}
          >
            + {t('addNewPromotion')}
          </SButton>
        </div>
        {isArrayEmpty(promotions?.data) ? (
          <span className={classes.info}>{t('youHaventCreated')}</span>
        ) : (
          <>
            <TableHeader headings={HEADERS} />
            <ReportsList reviews={promotions.data} />
            <Pagination
              count={promotions.count}
              page={pageIndex}
              rowsPerPage={rowsPerPage}
              onChangePage={setPageIndex}
              onChangeRowsPerPage={onChangeRowsPerPageHandler}
            />
          </>
        )}
      </div>
      <BaseLoader isLoading={isLoading} text={t('reportsLoading')} />
    </>
  )
}
