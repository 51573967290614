import * as yup from 'yup'

export const NFT_FILES_STEP_FIELDS = {
  PERSONA: 'persona',
  MUSIC: 'music',
  ARTWORK: 'artwork',
}

export const NFT_FILES_STEP_DEFAULT_VALUES = {
  [NFT_FILES_STEP_FIELDS.PERSONA]: null,
  [NFT_FILES_STEP_FIELDS.MUSIC]: null,
  [NFT_FILES_STEP_FIELDS.ARTWORK]: null,
}

export const NFT_DETAILS_STEP_FIELDS = {
  NAME: 'songName',
  SONGWRITERS: 'songwriters',
  MIXED_BY: 'mixedBy',
  ARTWORK_BY: 'artworkBy',
  PRODUCERS: 'producers',
  MASTERED_BY: 'masteredBy',
  DESCRIPTION: 'songDescription',
  SCARCITY: 'scarcity',
  PRICE: 'price',
  RELEASE_DATE: 'release_date',
}

export const NFT_DETAILS_METADATA_FIELDS = [
  NFT_DETAILS_STEP_FIELDS.NAME,
  NFT_DETAILS_STEP_FIELDS.SONGWRITERS,
  NFT_DETAILS_STEP_FIELDS.MIXED_BY,
  NFT_DETAILS_STEP_FIELDS.ARTWORK_BY,
  NFT_DETAILS_STEP_FIELDS.PRODUCERS,
  NFT_DETAILS_STEP_FIELDS.MASTERED_BY,
  NFT_DETAILS_STEP_FIELDS.DESCRIPTION,
]

export const NFT_DETAILS_STEP_DEFAULT_VALUES = {
  [NFT_DETAILS_STEP_FIELDS.NAME]: null,
  [NFT_DETAILS_STEP_FIELDS.SONGWRITERS]: null,
  [NFT_DETAILS_STEP_FIELDS.MIXED_BY]: null,
  [NFT_DETAILS_STEP_FIELDS.ARTWORK_BY]: null,
  [NFT_DETAILS_STEP_FIELDS.PRODUCERS]: null,
  [NFT_DETAILS_STEP_FIELDS.MASTERED_BY]: null,
  [NFT_DETAILS_STEP_FIELDS.DESCRIPTION]: null,
  [NFT_DETAILS_STEP_FIELDS.SCARCITY]: null,
  [NFT_DETAILS_STEP_FIELDS.PRICE]: null,
  [NFT_DETAILS_STEP_FIELDS.RELEASE_DATE]: null,
}

export const SINGLE_FIELD_NAME = 'single'

export const NFT_SPLITS_STEP_FIELDS = {
  // TODO
}

export const ACCEPTED_MUSIC_FORMATS = ['mp3', 'wav']

export const MAX_MUSIC_FILE_SIZE = '15Mb'
export const MAX_WAV_FILE_SIZE = '100Mb'

export const MUSIC_BIT_RATE = '320kbps'

export const ARTWORK_RATIO = '1:1'

export const ARTWORK_MIN_SIDE_LENGTH = 600
export const ARTWORK_DIMENSIONS = `${ARTWORK_MIN_SIDE_LENGTH} x ${ARTWORK_MIN_SIDE_LENGTH}`

export const MAX_STATIC_ARTWORK_SIZE = '15Mb'

export const MAX_ANIMATED_ARTWORK_SIZE = '25Mb'

export const MIN_PRICE = 3

export const MAX_PRICE = 100

export const MIN_SCARCITY = 1
export const MAX_SCARCITY = 100

export const MAX_DESCRIPTION = 1000
export const MAX_TEXT = 255

export const MAX_TITLE_LENGTH = 100

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const detailsTextValidationSchema = t =>
  yup
    .string()
    .trim()
    .max(MAX_TEXT, t('validation.maxLength', { length: MAX_TEXT }))
    .nullable()
    .required(t('validation.required'))

const personaValidationSchema = t =>
  yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string().required(t('validation.required')),
      parentId: yup.string(),
    })
    .required('validation.required')

export const nftFilesSchema = t =>
  yup.object().shape({
    [NFT_FILES_STEP_FIELDS.PERSONA]: personaValidationSchema(t),
    [NFT_FILES_STEP_FIELDS.MUSIC]: yup
      .object()
      .shape({
        id: yup.string().required(t('validation.required')),
        previewStatus: yup
          .string()
          .oneOf(['done'], t('validation.fileHasToBeProcessed')),
        hasMetadata: yup
          .bool()
          .oneOf([true], t('validation.fileHasToBeProcessed')),
        isNftAllowed: yup
          .bool()
          .oneOf([true], t('validation.fileHasToMeetNftRequirements')),
        name: yup.string(),
      })
      .nullable()
      .required(t('validation.required')),
    [NFT_FILES_STEP_FIELDS.ARTWORK]: yup
      .object()
      .shape({
        id: yup.string().required(t('validation.required')),
        name: yup.string(),
        previewStatus: yup
          .string()
          .oneOf(['done'], t('validation.fileHasToBeProcessed')),
        hasMetadata: yup
          .bool()
          .oneOf([true], t('validation.fileHasToBeProcessed')),
        isNftImageAllowed: yup
          .bool()
          .oneOf([true], t('validation.fileHasToMeetNftRequirements')),
      })
      .nullable()
      .required(t('validation.required')),
  })

export const nftDetailsSchema = (t, minReleaseDate) =>
  yup.object().shape({
    [NFT_DETAILS_STEP_FIELDS.NAME]: detailsTextValidationSchema(t)
      .max(
        MAX_TITLE_LENGTH,
        t('validation.maxLength', {
          length: MAX_TITLE_LENGTH,
        })
      )
      .required(t('validation.required')),
    [NFT_DETAILS_STEP_FIELDS.PRODUCERS]: detailsTextValidationSchema(t),
    [NFT_DETAILS_STEP_FIELDS.MASTERED_BY]: detailsTextValidationSchema(t),
    [NFT_DETAILS_STEP_FIELDS.SONGWRITERS]: detailsTextValidationSchema(t),
    [NFT_DETAILS_STEP_FIELDS.MIXED_BY]: detailsTextValidationSchema(t),
    [NFT_DETAILS_STEP_FIELDS.ARTWORK_BY]: detailsTextValidationSchema(t),
    [NFT_DETAILS_STEP_FIELDS.DESCRIPTION]: yup
      .string()
      .trim()
      .max(
        MAX_DESCRIPTION,
        t('validation.maxLength', { length: MAX_DESCRIPTION })
      )
      .required(t('validation.required'))
      .nullable(),
    [NFT_DETAILS_STEP_FIELDS.PRICE]: yup
      .number()
      .positive(t('validation.mustBePositiveNumber'))
      .test('decimals', t('validation.maxTwoDecimals'), val => {
        if (val) {
          const [, decimals] = `${val}`.split('.')

          return decimals ? decimals.length <= 2 : true
        }

        return true
      })
      .typeError(t('validation.validPrice'))
      .min(MIN_PRICE, t('validation.minPrice', { price: MIN_PRICE }))
      .max(MAX_PRICE, t('validation.maxPrice', { price: MAX_PRICE }))
      .nullable(),
    [NFT_DETAILS_STEP_FIELDS.SCARCITY]: yup
      .number()
      .positive(t('validation.mustBePositiveNumber'))
      .integer(t('validation.mustBeInteger'))
      .typeError(t('validation.validScarcity'))
      .min(
        MIN_SCARCITY,
        t('validation.minScarcity', { scarcity: MIN_SCARCITY })
      )
      .max(
        MAX_SCARCITY,
        t('validation.maxScarcity', { scarcity: MAX_SCARCITY })
      )
      .nullable(),
    [NFT_DETAILS_STEP_FIELDS.RELEASE_DATE]: yup
      .date()
      .min(
        minReleaseDate,
        t('validation.minDate', { date: minReleaseDate.toLocaleString() })
      )
      .required(t('validation.required'))
      .nullable(),
  })

export const RIGHTS_VERIFICATION_FIELDS = {
  MUSIC_RIGHTS: 'music_rights',
  ARTWORK_RIGHTS: 'artwork_rights',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
}

export const rightsVerificationSchema = yup.object().shape({
  [RIGHTS_VERIFICATION_FIELDS.MUSIC_RIGHTS]: yup.bool().nullable().required(),
})
