import React from 'react'
import { node } from 'prop-types'
import styled from 'styled-components'

const SHeadingBarWrapper = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 1000000;
  height: 64px;
  top: 67px;
  left: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.darkGrey33};
  background: ${({ theme: { colors } }) => colors.black};
`

const SHeading = styled.div`
  font-family: ${({ theme: { typography } }) => typography.bold};
  max-width: 1200px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  font-size: 21px;
`

export const HeadingBar = ({ children }) => {
  return (
    <SHeadingBarWrapper>
      <SHeading>{children}</SHeading>
    </SHeadingBarWrapper>
  )
}

HeadingBar.propTypes = {
  children: node.isRequired,
}
