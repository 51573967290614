import { useEffect, useState } from 'react'
import { useLocalStorage } from '#hooks/useLocalStorage'

const checkAuth = localStorageData => {
  if (!localStorageData) return false

  const decoded = JSON.parse(localStorageData)
  const now = Date.now() / 1000

  return !(now > decoded?.access_token_expiry ?? 0)
}

export const useIsAuth = () => {
  const [isLocalStorageAuth] = useLocalStorage('auth')
  const [value, setValue] = useState(checkAuth(isLocalStorageAuth))

  useEffect(() => {
    setValue(checkAuth(isLocalStorageAuth))
  }, [isLocalStorageAuth])

  return value
}
