import { useEffect, useState } from 'react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'

export const useStripeFormValidation = elements => {
  const [cardNumberError, setCardNumberError] = useState(null)
  const [cardExpiryError, setCardExpiryError] = useState(null)
  const [cardCvcError, setCardCvcError] = useState(null)

  const [cardNumberCompleted, setCardNumberCompleted] = useState(false)
  const [expDateCompleted, setExpDateCompleted] = useState(false)
  const [cvcCompleted, setCvcCompleted] = useState(false)

  const [areFieldsValid, setAreFieldsValid] = useState(false)

  useEffect(() => {
    /* eslint-disable */
    elements?.getElement(CardNumberElement)?.on('change', event => {
      setCardNumberError(event.error?.message)
      setCardNumberCompleted(!!event.complete && !event.error)
    })

    elements?.getElement(CardExpiryElement)?.on('change', event => {
      setCardExpiryError(event.error?.message)
      setExpDateCompleted(!!event.complete && !event.error)
    })

    elements?.getElement(CardCvcElement)?.on('change', event => {
      setCardCvcError(event.error?.message)
      setCvcCompleted(!!event.complete && !event.error)
    })
    /* eslint-enable */
  })

  useEffect(() => {
    if (!cardNumberCompleted || !cvcCompleted || !expDateCompleted) {
      setAreFieldsValid(false)
    } else {
      setAreFieldsValid(true)
    }
  }, [cardNumberCompleted, cvcCompleted, expDateCompleted])

  return {
    cardNumberError,
    cardExpiryError,
    cardCvcError,
    areFieldsValid,
  }
}
