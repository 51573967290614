import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useWizard } from '#modules/forms/form-wizard/context'
import { createImageUrl } from '#pages/nft/_utils/helpers'
import { ACCEPTED_FILE_TYPES } from '#constants/acceptedFileTypes'
import { FIELDS } from '#pages/distro/_utils/constants'
import { createImageValidator } from '#utils/createImageValidator'
import { ImageUpload } from '#components/image-upload'
import { useError } from '#hooks/useError'

export const Artwork = () => {
  const { t } = useTranslation('distro')
  const { error, setError, clearError } = useError()
  const { setStoredFormState, storedFormState } = useWizard()
  const { artworkFile, artwork } = storedFormState
  const [artworkUrl, setArtworkUrl] = useState('')

  const {
    register,
    unregister,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  useEffect(() => {
    register(FIELDS.ARTWORK)

    return () => {
      unregister(FIELDS.ARTWORK)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (artworkFile !== null) {
      setArtworkUrl(createImageUrl(artworkFile))
      setValue(FIELDS.ARTWORK, createImageUrl(artworkFile))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkFile])

  useEffect(() => {
    if (artwork) {
      setArtworkUrl(artwork)
      setValue(FIELDS.ARTWORK, artwork)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artwork])

  const validateImage = createImageValidator(
    ACCEPTED_FILE_TYPES.IMAGES,
    t,
    1400,
    1400,
    10,
    true,
    6000,
    6000
  )

  const handleFileDrop = async fileToUpload => {
    const { isValid, message } = await validateImage(fileToUpload[0])

    if (!isValid) {
      setError(message)
      return
    }

    setArtworkUrl(createImageUrl(fileToUpload[0]))
    setValue(FIELDS.ARTWORK, createImageUrl(fileToUpload[0]))
    clearError()

    setStoredFormState({ artworkFile: fileToUpload[0] })
  }

  return (
    <ImageUpload
      artworkUrl={artworkUrl}
      handleFileDrop={handleFileDrop}
      title={t('uploadArtWork')}
      watch={watch}
      errors={errors}
      error={error}
      width={250}
    />
  )
}
