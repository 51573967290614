import React from 'react'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BasicSection } from '#pages/connect-wallet/_components/basic-section'
import { TransactionLoader } from '#pages/connect-wallet/_components/transaction-loader'
import { SETUP_STATUS } from '#pages/connect-wallet/_hooks/use-royalty-setup'

export const SetupDapperWallet = ({ onSetup, loading, setupStatus }) => {
  const { t } = useTranslation('connectWallet')

  const LOADER_TEXT = {
    [SETUP_STATUS.AwaitingDapper]: t('confirmTransactionInDapperPopup'),
    [SETUP_STATUS.InProgress]: t('handlingRequest'),
  }

  return loading ? (
    <TransactionLoader text={LOADER_TEXT[setupStatus]} />
  ) : (
    <BasicSection
      btnText={t('setup')}
      text={t('nextStep')}
      title={t('success')}
      subtitle={t('walletConnected')}
      onClick={onSetup}
    />
  )
}

SetupDapperWallet.propTypes = {
  onSetup: func.isRequired,
  loading: bool,
  setupStatus: string,
}
