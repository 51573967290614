import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const useEmailVerification = (auth0Id, refreshInterval) => {
  const { data, error, ...rest } = useSWR(
    `${ENDPOINTS.CHECK_EMAIL_VERIFICATION}?userId=${auth0Id}`,
    {
      refreshInterval,
    }
  )

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
