import React from 'react'
import styled, { css } from 'styled-components'
import { PARTNERS, SOCIALS } from '#pages/home/_utils/constants'
import { useTranslation } from 'react-i18next'

const SWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 6rem 0;
  gap: 12rem;
  flex-wrap: wrap;
`

const SInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SImg = styled.img`
  flex-shrink: 1;
  max-width: 45rem;
`

const SLogo = styled.img`
  height: 2rem;
`

const SCopyright = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.xs};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  opacity: 0.3;
  margin: 1.5rem 0 2rem;
`

const listStyle = css`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SPartners = styled.ul`
  ${listStyle};
  margin: 0;
  gap: 2.5rem;
`

const SSocials = styled.ul`
  ${listStyle};
  gap: 1.5rem;
  border-top: 1px solid;
  width: 18rem;
  padding-top: 2.5rem;
  margin: 2.5rem 0 0;
  opacity: 0.3;
`

export const FooterSection = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()

  return (
    <SWrapper>
      <SInnerWrapper>
        <SLogo src="assets/svg/ic-tunego-logo.svg" alt="TuneGO Logo" />
        <SCopyright> {t('footer.copyright', { year })}</SCopyright>
        <SPartners>
          {PARTNERS.map(({ name, logo, url }, i) => (
            <li key={i}>
              <a href={url} target="_blank">
                <img src={logo} alt={`${name} logo`} />
              </a>
            </li>
          ))}
        </SPartners>

        <SSocials>
          {SOCIALS.map(({ name, logo, url }, i) => (
            <li key={i}>
              <a href={url} target="_blank">
                <img src={logo} alt={name} />
              </a>
            </li>
          ))}
        </SSocials>
      </SInnerWrapper>
      <SImg src="assets/images/home-screens.png" alt="" />
    </SWrapper>
  )
}
