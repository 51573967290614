import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { DISTRO_STATUS } from '#pages/distro/_utils/constants'
import { Button } from '#components/button'

const useStyles = makeStyles(() => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  span: {
    lineHeight: '60px',
  },
}))

const SButton = styled(Button)`
  height: unset;
  padding: 0;
  border-radius: 100%;
`

export const DistroStatusButton = ({ status }) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  const renderStatus = distroStatus => {
    switch (distroStatus) {
      case DISTRO_STATUS.DENIED:
        return <span className={classes.uppercase}>{t(`denied`)}</span>
      case DISTRO_STATUS.IN_PROGRESS:
        return <span className={classes.span}>{t(`in-progress`)}</span>
      case DISTRO_STATUS.PENDING:
        return <span className={classes.span}>{t(`pending`)}</span>
      case DISTRO_STATUS.UNPAID:
        return <span className={classes.span}>{t(`unpaid`)}</span>
      case DISTRO_STATUS.COMPLETE:
        return (
          <SButton>
            <img src="/assets/svg/ic-check-blue.svg" alt="" width="25px" />
          </SButton>
        )
      default:
        return null
    }
  }

  return renderStatus(status)
}

DistroStatusButton.propTypes = {
  status: string.isRequired,
}
