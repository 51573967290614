import React, { useCallback, useEffect, useState } from 'react'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import {
  COLLABORATOR_ACTION_TYPES,
  MENU_OPTIONS,
} from '#pages/vault/song/tabs/collaborators/utils/constants'
import { Button } from '@material-ui/core'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CollaboratorsApi } from '#api/requests/collaborators'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { EditCollabModal } from '#pages/_modules/collaborators-table/components/edit-collab-modal'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(() => ({
  acceptBtn: {
    backgroundImage: 'url("/assets/images/edit-icon.png")',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 18,
  },
  exitIcon: {
    cursor: 'pointer',
    paddingTop: '5px',
    marginRight: '10px',
  },
}))

export const RowActionsButtons = ({
  userId,
  projectId,
  triggerMutate,
  isCollaborationOwner,
  index,
  collaborator,
  setCollaborator,
  enableLeaveCollaborationModal,
  status,
  id,
}) => {
  const { t } = useTranslation()
  const { user: loggedUser } = useUser()
  const isAccountOwner = userId === loggedUser.id
  const [error, setError] = useState('')
  const handleSetError = useCallback(err => setError(err.message), [])
  const classes = useStyles({ index })
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const showLeaveCollaborationOption = !isCollaborationOwner && isAccountOwner
  const { actions, loading, errors } = useWithAsyncAction({
    postCollaboratorReInvite: CollaboratorsApi.postCollaboratorReInvite,
    deleteCollaborator: CollaboratorsApi.deleteCollaborator,
  })

  const disableEditModal = () => setIsEditModalOpen(false)
  const clearError = () => setError('')

  useEffect(() => {
    if (errors.postCollaboratorReInvite) {
      handleSetError(errors.postCollaboratorReInvite)
    }
    if (errors.deleteCollaborator) {
      handleSetError(errors.deleteCollaborator)
    }
  }, [errors, handleSetError])

  const resendInvite = async id => {
    await actions.postCollaboratorReInvite(projectId, id)
    triggerMutate()
  }

  const onExitCollaborationClickHandler = () => {
    setCollaborator(collaborator)
    enableLeaveCollaborationModal()
  }

  const cancelInvite = async id => {
    await actions.deleteCollaborator({
      structureId: projectId,
      collaboratorId: id,
    })
    triggerMutate()
  }

  const determineMenuAction = (actionType, id) => {
    switch (actionType) {
      case COLLABORATOR_ACTION_TYPES.resendInvite: {
        return resendInvite(id)
      }
      case COLLABORATOR_ACTION_TYPES.cancelInvite: {
        return cancelInvite(id)
      }
      default: {
        return null
      }
    }
  }

  const renderEllipsisMenu = (status, id) => {
    switch (status) {
      case 'pending':
        return (
          <EllipsisMenu
            options={MENU_OPTIONS}
            onChoose={actionType => {
              determineMenuAction(actionType, id)
            }}
          />
        )
      case 'accepted':
        return (
          <Button
            className={classes.acceptBtn}
            onClick={() => setIsEditModalOpen(prev => !prev)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {isCollaborationOwner && renderEllipsisMenu(status, id)}
      {showLeaveCollaborationOption && (
        <img
          src="/assets/svg/logout.svg"
          className={classes.exitIcon}
          width="25px"
          alt="Logout"
          onClick={onExitCollaborationClickHandler}
        />
      )}

      <EditCollabModal
        open={isEditModalOpen}
        handleClose={disableEditModal}
        collaborator={collaborator}
        projectId={projectId}
        triggerMutate={triggerMutate}
      />
      <CommonLoadingOverlay
        message={
          loading.postCollaboratorReInvite ||
          (loading.deleteCollaborator && t('loading'))
        }
        dialogOpen={
          loading.postCollaboratorReInvite || loading.deleteCollaborator
        }
        error={error}
        onErrorBackdropClick={clearError}
      />
    </>
  )
}
