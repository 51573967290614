import React, { useEffect, useState } from 'react'
import { shape } from 'prop-types'
import { DetailsContainer } from '#pages/distro/past-release-details/containers/details-container'
import { useWizard } from '#modules/forms/form-wizard/context'
import { resolvePastRelease } from '#pages/distro/wizard/utils/helpers'

export const PastReleaseContainer = ({ pastRelease }) => {
  const { dispatch } = useWizard()
  const [isDataSet, setIsDataSet] = useState(false)

  useEffect(() => {
    resolvePastRelease(dispatch, pastRelease)
    setIsDataSet(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastRelease])

  if (!isDataSet) return null

  return <DetailsContainer />
}

PastReleaseContainer.propTypes = {
  pastRelease: shape({}).isRequired,
}
