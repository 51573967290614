import React, { useState } from 'react'
import { node } from 'prop-types'

import { GlobalAudioPlayerContext } from './context'
import { GlobalAudioPlayerContainer } from './containers/global-audio-player-container'

export const GlobalAudioPlayerProvider = ({ children }) => {
  const [fileInfo, setFileInfo] = useState(null)
  const [offset, setOffset] = useState(0)

  return (
    <GlobalAudioPlayerContext.Provider
      value={{
        setFileInfo,
        fileInfo,
        offset,
        setOffset,
      }}
    >
      {children}
      <GlobalAudioPlayerContainer />
    </GlobalAudioPlayerContext.Provider>
  )
}

GlobalAudioPlayerProvider.propTypes = {
  children: node.isRequired,
}
