import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { bool, string } from 'prop-types'

const useStyles = makeStyles(() => ({
  releaseDetailsItem: {
    marginBottom: 15,
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '14px',
    margin: 0,
    marginBottom: 5,
  },
  bold: {
    fontWeight: 700,
  },
}))

export const ReleaseDetailsItem = ({ label, value, isBolded }) => {
  const classes = useStyles()

  return (
    <div className={classes.releaseDetailsItem}>
      <span className={`${classes.label}`}>{label}</span>
      <p className={`${classes.label} ${isBolded && classes.bold}`}>
        {value || '--'}
      </p>
    </div>
  )
}

ReleaseDetailsItem.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  isBolded: bool,
}

ReleaseDetailsItem.defaultProps = {
  isBolded: false,
}
