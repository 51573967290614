import React from 'react'
import { arrayOf, string } from 'prop-types'
import { useParams } from '@reach/router'
import { CollaboratorsTable } from '#pages/_modules/collaborators-table'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'

export const Collaborators = ({ accessRoles }) => {
  const params = useParams()
  const { projectId } = params

  return (
    <ColumnWrapper>
      <CollaboratorsTable {...{ projectId, accessRoles }} />
    </ColumnWrapper>
  )
}

Collaborators.propTypes = {
  accessRoles: arrayOf(string).isRequired,
}
