import React from 'react'
import { PageWrapper } from '#modules/page-wrapper'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { STEPS } from '#pages/getting-started/_utils/constants'
import { Step } from '#pages/getting-started/_components/step'

const SContent = styled.div`
  padding: 5rem;
`

const SHeader = styled.h1`
  text-transform: uppercase;
  margin: 0;
  font-size: ${({ theme: { fontSize } }) => fontSize['5xl']};
  line-height: 1.2;
  font-family: ${({ theme: { typography } }) => typography.bold};
`

const SSubtitle = styled.h2`
  text-transform: uppercase;
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin-bottom: 4rem;
`

const SSteps = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`

const SBold = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
`

export const GettingStarted = () => {
  const { t } = useTranslation('gettingStarted')

  return (
    <PageWrapper showNavBar>
      <SContent>
        <SHeader>{t('title')}</SHeader>
        <SSubtitle>{t('subtitle')}</SSubtitle>

        <SSteps>
          {STEPS.map((step, index) => (
            <Step
              key={index}
              title={t(`step${index + 1}.title`)}
              text={
                <Trans t={t} i18nKey={`step${index + 1}.text`}>
                  <SBold />
                </Trans>
              }
              buttonText={t(`step${index + 1}.button`)}
              index={index + 1}
              {...step}
            />
          ))}
        </SSteps>
      </SContent>
    </PageWrapper>
  )
}
