import React, { useState, useEffect } from 'react'
import { arrayOf, shape, string, elementType } from 'prop-types'
import { useParams, navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { NAVIGATION_PATHS } from '#routes/routes'
import { buildUrl } from '#utils/buildUrl'
import { Tabs } from '#components/tabs'
import { LoseChangesWarningModal } from '#components/lose-changes-warning-modal'
import { HasUnsavedChangesContext } from '../../context/hasUnsavedChanges'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  tabsWrapper: {
    padding: '0 25px',
    width: '100%',
    borderBottom: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
  },
}))

export const TabbedViewContainer = ({ tabs }) => {
  const classes = useStyles()

  const { projectTab, artistId, projectId } = useParams()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(null)
  const [whereToNavigate, setWhereToNavigate] = useState(null)

  useEffect(() => {
    if (tabs.length && !tabs.some(tab => tab?.value === projectTab)) {
      navigate(
        buildUrl(NAVIGATION_PATHS.SONG_TAB, {
          artistId,
          projectId,
          projectTab: tabs[0]?.value,
        }),
        { replace: true }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.length])

  const determineRenderedComponent = () =>
    tabs.find(tab => tab?.value === projectTab)?.component

  const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = useState(false)

  const onChangeTab = value => {
    const tabNavUrl = buildUrl(NAVIGATION_PATHS.SONG_TAB, {
      artistId,
      projectId,
      projectTab: value,
    })
    if (hasUnsavedChanges) {
      setWhereToNavigate(tabNavUrl)
      setIsSaveWarningModalOpen(true)
    } else {
      navigate(tabNavUrl)
    }
  }
  // eslint-disable-next-line no-shadow
  const handleWarningModalNavigation = whereToNavigate => {
    setIsSaveWarningModalOpen(false)
    setHasUnsavedChanges(false)
    setWhereToNavigate(null)
    navigate(whereToNavigate)
  }

  return (
    <HasUnsavedChangesContext.Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges,
        whereToNavigate,
        setWhereToNavigate,
      }}
    >
      <div className={classes.tabsWrapper}>
        <Tabs tabs={tabs} activeTab={projectTab} onChangeTab={onChangeTab} />
      </div>
      {determineRenderedComponent()}
      <LoseChangesWarningModal
        isSaveWarningModalOpen={isSaveWarningModalOpen}
        whereToNavigate={whereToNavigate}
        setIsSaveWarningModalOpen={setIsSaveWarningModalOpen}
        handleWarningModalNavigation={handleWarningModalNavigation}
      />
    </HasUnsavedChangesContext.Provider>
  )
}

TabbedViewContainer.propTypes = {
  tabs: arrayOf(shape({ tab: string, component: elementType })).isRequired,
}
