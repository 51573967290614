import React from 'react'
import {
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { WizardProvider } from '#modules/forms/form-wizard/context'
import { Navigation } from '#modules/forms/form-wizard/components/navigation'
import { NAVIGATION_PATHS } from '#routes/routes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wizardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const WizardContainer = ({
  children,
  navigationItems,
  showNavigation,
  defaultState,
  navigationExitString,
}) => {
  const classes = useStyles()
  const showWizardNavigation = !!navigationItems.length && showNavigation

  return (
    <WizardProvider {...{ defaultState }}>
      <div className={classes.wizardContainer}>
        {showWizardNavigation && (
          <Navigation
            navigationItems={navigationItems}
            navigationExitString={navigationExitString}
          />
        )}
        {children}
      </div>
    </WizardProvider>
  )
}

WizardContainer.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  navigationItems: arrayOf(shape({ stepNumber: number, text: string })),
  showNavigation: bool,
  navigationExitString: string,
  defaultState: shape({
    step: number,
    storedFormState: object,
  }),
}

WizardContainer.defaultProps = {
  navigationItems: [],
  showNavigation: true,
  defaultState: null,
  navigationExitString: NAVIGATION_PATHS.HOME,
}
