import { useCallback, useEffect, useState } from 'react'

export const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }, [isClient])

  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize())
    }
    if (isClient) {
      window.addEventListener('resize', handleResize)
    }
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  return windowSize
}
