import React, { createContext, useContext, useReducer } from 'react'
import { arrayOf, node, number, object, oneOfType, shape } from 'prop-types'

export const WizardContext = createContext({
  state: {},
  dispatch: () => null,
  incrementStep: () => null,
  decrementStep: () => null,
  setStep: () => null,
  setStoredFormState: () => null,
  setDistroSongFiles: () => null,
  clearStoredFormState: () => null,
  isStoredFormStateEmpty: false,
})

export const useWizard = () => {
  const context = useContext(WizardContext)

  if (!context) {
    throw new Error('useWizard must be used within WizardProvider')
  }
  return context
}

export const ACTION_TYPES = {
  SET_STORED_FORM_STATE: 'SET_STORED_FORM_STATE',
  INCREMENT_STEP: 'INCREMENT_STEP',
  DISCARD_CHANGES: 'DISCARD_CHANGES',
  DECREMENT_STEP: 'DECREMENT_STEP',
  CLEAR_STORED_FORM_STATE: 'CLEAR_STORED_FORM_STATE',
  SET_STEP: 'SET_STEP',
  SET_DISTRO_SONG_FILES: 'SET_DISTRO_SONG_FILES',
}

const defaultFormState = {
  storedFormState: {},
  step: 1,
}

const reducer =
  (defaultStoredFormState = defaultFormState.storedFormState) =>
  (state, action) => {
    switch (action.type) {
      case ACTION_TYPES.SET_DISTRO_SONG_FILES:
        return {
          ...state,
          storedFormState: {
            ...state.storedFormState,
            songFiles: [...state.storedFormState.songFiles, action.payload],
          },
        }
      case ACTION_TYPES.SET_STORED_FORM_STATE:
        return {
          ...state,
          storedFormState: {
            ...state.storedFormState,
            ...action.payload,
          },
        }
      case ACTION_TYPES.CLEAR_STORED_FORM_STATE:
        return {
          ...state,
          storedFormState: defaultStoredFormState,
        }
      case ACTION_TYPES.INCREMENT_STEP:
        return {
          ...state,
          step: state.step + 1,
          previousFormState: state.storedFormState,
        }
      case ACTION_TYPES.DISCARD_CHANGES: {
        return {
          ...state,
          storedFormState: state.previousFormState,
        }
      }
      case ACTION_TYPES.DECREMENT_STEP:
        return {
          ...state,
          step: state.step - 1,
          previousFormState: state.storedFormState,
        }
      case ACTION_TYPES.SET_STEP:
        return {
          ...state,
          step: action.payload,
        }
      default:
        return state
    }
  }

export const WizardProvider = ({ children, defaultState }) => {
  const [state, dispatch] = useReducer(
    reducer(defaultState?.storedFormState),
    defaultState || defaultFormState
  )

  const isStoredFormStateEmpty = Object.keys(state.storedFormState).length === 0
  const incrementStep = () => dispatch({ type: ACTION_TYPES.INCREMENT_STEP })
  const decrementStep = () => dispatch({ type: ACTION_TYPES.DECREMENT_STEP })
  const setStep = payload => dispatch({ type: ACTION_TYPES.SET_STEP, payload })
  const setStoredFormState = payload =>
    dispatch({ type: ACTION_TYPES.SET_STORED_FORM_STATE, payload })
  const setDistroSongFiles = payload =>
    dispatch({ type: ACTION_TYPES.SET_DISTRO_SONG_FILES, payload })
  const clearStoredFormState = () =>
    dispatch({ type: ACTION_TYPES.CLEAR_STORED_FORM_STATE })

  const value = {
    incrementStep,
    decrementStep,
    setStep,
    setStoredFormState,
    state,
    storedFormState: state.storedFormState,
    step: state.step,
    dispatch,
    isStoredFormStateEmpty,
    setDistroSongFiles,
    clearStoredFormState,
  }

  return (
    <WizardContext.Provider {...{ value }}> {children} </WizardContext.Provider>
  )
}

WizardProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  defaultState: shape({
    step: number,
    storedFormState: object,
  }),
}

WizardProvider.defaultProps = {
  defaultState: null,
}
