import React from 'react'
import { makeStyles } from '@material-ui/core'

export const TEXT_TYPE = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  SUBTITLE1: 'subtitle1',
  SUBTITLE2: 'subtitle2',
  BODY2: 'body2',
}

const useStyles = (align, color) =>
  makeStyles(({ typography, palette }) => ({
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    h5: typography.h5,
    subtitle1: typography.subtitle1,
    subtitle2: typography.subtitle2,
    body2: typography.body2,
    text: {
      textAlign: align ?? 'left',
      display: 'block',
      color: palette.color[color] ?? color,
    },
  }))()

export const Text = ({
  children,
  className,
  type = TEXT_TYPE.H2,
  align,
  color,
}) => {
  const classes = useStyles(align, color)
  return (
    <span className={`${classes[type]} ${className} ${classes.text}`}>
      {children}
    </span>
  )
}
