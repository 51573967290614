import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { bool, func, shape } from 'prop-types'
import { CustomSelect } from '#components/inputs/custom-select'
import { DatePicker } from '#components/date-picker'
import { BaseInput } from '#components/inputs/base-input'
import { trackMetadataFields } from '#pages/vault/song/tabs/metadata/utils/constants'
import { TabWrapper } from '#pages/vault/song/tabs/metadata/components/tab-wrapper'
import {
  isFormDirty,
  msToTime,
  timeToMs,
} from '#pages/vault/song/tabs/metadata/utils/helpers'

const SCustomSelect = styled(CustomSelect)`
  width: 100%;
  text-transform: capitalize;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SDatePicker = styled(DatePicker)`
  margin-left: ${({ isPreviouslyReleaseDate }) =>
    isPreviouslyReleaseDate ? 'auto' : 0};
`

const fieldNames = {
  recordingDate: 'recordingDate',
  previousReleaseDate: 'previousReleaseDate',
  isPreviouslyReleased: 'isPreviouslyReleased',
  trackDuration: 'trackDuration',
}

export const TrackMetadata = ({
  isLoading,
  setHasUnsavedChanges,
  formattedFields,
}) => {
  const { t } = useTranslation('vault')
  const [recordingDateValue, setRecordingDateValue] = useState(
    new Date(formattedFields.recordingDate)
  )
  const [previousReleaseDateValue, setPreviousReleaseDateValue] = useState(
    new Date(formattedFields.previousReleaseDate)
  )
  const [trackLength, setTrackLength] = useState(null)
  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    reset,
    formState: { errors, isDirty: isCurrentFormDirty, dirtyFields },
  } = useFormContext()
  const changes = watch()

  const isTrackFormDirty = () => {
    return isFormDirty({
      dirtyFields,
      formattedFields,
      values: getValues(),
    })
  }

  useEffect(() => {
    setHasUnsavedChanges(isTrackFormDirty)
  }, [isCurrentFormDirty, setHasUnsavedChanges, changes])

  /* eslint-disable */
  useEffect(() => {
    const trackDuration = getValues(fieldNames.trackDuration)
    if (typeof trackDuration === 'string') {
      setTrackLength(timeToMs(trackDuration))
    } else {
      setTrackLength(trackDuration)
    }
  })
  /* eslint-enable */

  useEffect(() => {
    if (trackLength) {
      setValue(fieldNames.trackDuration, msToTime(trackLength))
    }
  }, [setValue, trackLength])

  const onDatePickerChange = (date, name, onChangeFn) => {
    if (date === 'Invalid Date') {
      onChangeFn(null)
    } else {
      if (name === fieldNames.recordingDate) {
        setRecordingDateValue(date)
      }
      if (name === fieldNames.previousReleaseDate) {
        setPreviousReleaseDateValue(date)
      }
      onChangeFn(date)
    }
  }

  const isPreviouslyReleased = watch(fieldNames.isPreviouslyReleased)
  useEffect(() => {
    if (!isPreviouslyReleased?.value) {
      setValue(fieldNames.previousReleaseDate, null)
    }
  }, [reset, setValue, isPreviouslyReleased])

  return (
    <TabWrapper isLoading={isLoading}>
      {trackMetadataFields.map(
        ({ name, type, isReadOnly, options, mask, defaultValueKey }) => {
          if (type === 'input') {
            return (
              <SBaseInput
                key={name}
                isReadOnly={isReadOnly}
                inputRef={register}
                name={name}
                label={t(`${name}`)}
                errorMessage={errors[`${name}`]?.message}
                mask={mask}
                control={control}
                defaultValue={formattedFields[defaultValueKey]}
              />
            )
          }
          if (type === 'calendar') {
            const isPreviouslyReleaseDate =
              name === fieldNames.previousReleaseDate
            return (
              <Controller
                key={name}
                name={name}
                control={control}
                defaultValue={new Date(formattedFields[defaultValueKey])}
                render={({ field: { onChange } }) => (
                  <SDatePicker
                    disabled={
                      name === fieldNames.recordingDate
                        ? false
                        : !isPreviouslyReleased?.value
                    }
                    value={
                      name === fieldNames.recordingDate
                        ? recordingDateValue
                        : previousReleaseDateValue
                    }
                    label={t(`${name}`)}
                    errorMessage={errors[`${name}`]?.message}
                    isPreviouslyReleaseDate={isPreviouslyReleaseDate}
                    onChange={date => onDatePickerChange(date, name, onChange)}
                    {...(isPreviouslyReleaseDate && { maxDate: new Date() })}
                  />
                )}
              />
            )
          }
          return (
            <Controller
              key={name}
              name={name}
              defaultValue={formattedFields[defaultValueKey]}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SCustomSelect
                  id={`${name}_select`}
                  options={options}
                  value={value}
                  label={t(`${name}`)}
                  errorMessage={errors[`${name}`]?.message}
                  onChange={onChange}
                />
              )}
            />
          )
        }
      )}
    </TabWrapper>
  )
}

TrackMetadata.propTypes = {
  isLoading: bool.isRequired,
  setHasUnsavedChanges: func.isRequired,
  formattedFields: shape({}).isRequired,
}
