import React from 'react'
import { arrayOf, string } from 'prop-types'

import { ChooseMetadataOption } from '#pages/vault/song/tabs/metadata/components/choose-metadata-option'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MetadataContainer } from '#pages/vault/song/tabs/metadata/containers/metadata-container'
import { metadataOptions } from '#pages/vault/song/tabs/metadata/utils/constants'
import { useHasUnsavedChanges } from '#pages/vault/song/context/hasUnsavedChanges'

export const Metadata = ({ accessRoles }) => {
  const { enableAdditionalMetadata } = useFlags()

  const { hasUnsavedChanges, setHasUnsavedChanges } = useHasUnsavedChanges()

  return enableAdditionalMetadata ? (
    <ChooseMetadataOption {...{ accessRoles }} />
  ) : (
    <MetadataContainer
      {...{ accessRoles, hasUnsavedChanges, setHasUnsavedChanges }}
      chosenMetadataOption={metadataOptions.collectibles}
      hideTabs
    />
  )
}

Metadata.propTypes = {
  accessRoles: arrayOf(string).isRequired,
}
