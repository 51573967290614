import React, { useState } from 'react'
import styled from 'styled-components'
import { shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { AddSingleModal } from '#pages/vault/profile-content/components/add-single-modal'
import { AddPackModal } from '#pages/vault/profile-content/components/add-pack-modal'
import { useParams } from '@reach/router'

const STopBar = styled.div`
  width: 100%;
  height: 60px;
  background: ${({ theme: { colors } }) => colors.darkBlue03};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SButton = styled(Button)`
  height: 30px;
`

export const TopButtonBar = ({ parent }) => {
  const { t } = useTranslation('vault')
  const { accessRoles, name } = parent || {}
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false)
  const [isPackModalOpen, setIsPackModalOpen] = useState(false)

  const isUserStructureOwner = accessRoles?.some(role =>
    [ACCESS_ROLES.OWNER].includes(role)
  )

  const shouldEnableCreateFolderOrProject = accessRoles?.some(role =>
    [ACCESS_ROLES.OWNER, ACCESS_ROLES.COLLABORATOR].includes(role)
  )

  const { id: parentId } = useParams()

  if (shouldEnableCreateFolderOrProject) {
    return (
      <STopBar>
        <SButton
          variant={VARIANTS.NEW_BLUE_SPECIAL}
          onClick={() => setIsSingleModalOpen(true)}
        >
          + {t('newSong')}
        </SButton>
        <SButton
          variant={VARIANTS.NEW_BLUE_SPECIAL}
          onClick={() => setIsPackModalOpen(true)}
        >
          + {t('newAlbum')}
        </SButton>

        <AddSingleModal
          parentId={parentId}
          isOpen={isSingleModalOpen}
          setIsOpen={setIsSingleModalOpen}
          isOwner={isUserStructureOwner}
          structureName={name}
        />
        <AddPackModal
          isOpen={isPackModalOpen}
          parentId={parentId}
          setIsOpen={setIsPackModalOpen}
        />
      </STopBar>
    )
  }
  return null
}

TopButtonBar.propTypes = {
  parent: shape({}),
}

TopButtonBar.defaultProps = {
  parent: null,
}
