import React from 'react'
import { func, arrayOf, string } from 'prop-types'
import styled, { css } from 'styled-components'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Button } from '@tunego/tunego-ui-components'

const SDropZone = styled.div`
  outline: none;
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({
    theme: {
      tunego: { COLORS },
    },
    isDragActive,
  }) =>
    isDragActive
      ? css`
          box-shadow: 0 0 20px 0 ${COLORS.primary_50};
          border: 2px dashed ${COLORS.primary_50};
        `
      : css`
          border: 2px dashed ${COLORS.neutral_70};
        `}
`
export const SelectFile = ({ setFiles, accept, validator, onDropRejected }) => {
  const { t } = useTranslation('nftWizard')

  return (
    <Dropzone
      multiple={false}
      accept={accept}
      validator={file => validator(file, t)}
      onDropAccepted={setFiles}
      onDropRejected={onDropRejected}
    >
      {({ getInputProps, getRootProps, isDragActive, open }) => (
        <SDropZone
          {...getRootProps({
            onClick: event => {
              event.stopPropagation()
            },
          })}
          isDragActive={isDragActive}
        >
          <p>{t('steps.files.dropFile')}</p>{' '}
          <Button onClick={open}>{t('steps.files.selectFile')}</Button>
          <input {...getInputProps()} />
        </SDropZone>
      )}
    </Dropzone>
  )
}

SelectFile.propTypes = {
  setFiles: func,
  accept: arrayOf(string),
  onDropRejected: func,
  validator: func,
}
