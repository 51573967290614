import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const useSubscriptionsPlans = () => {
  const { data, error, ...rest } = useSWR(ENDPOINTS.SUBSCRIPTIONS_PLANS)
  return {
    plans: data?.data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
