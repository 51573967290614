import React from 'react'
import { func, string } from 'prop-types'
import { useArtistStructureContentPerRoles } from '#hooks/swr/useArtists'
import { TreeSelect } from '#components/tree-select'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { StructureApi } from '#api/requests/structure'
import { PackIcon, SingleIcon } from '#icons'
import styled, { css } from 'styled-components'
import { BaseLoader } from '#components/loaders/base-loader'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'launchdarkly-react-client-sdk'

const containerStyle = css`
  height: 26.25rem;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_80};
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_100};
  width: 100%;
  margin-bottom: 2.5rem;
`

const SContainer = styled.div`
  ${containerStyle};
  display: flex;
  align-items: center;
  justify-content: center;
`

const STreeSelect = styled(TreeSelect)`
  ${containerStyle};
  overflow-y: scroll;
`

const SText = styled.p`
  padding: 2rem;
  text-align: center;
`
export const SingleTree = ({ parentId, onChange }) => {
  const { t } = useTranslation('nftWizard')
  const { useAltStructuresEndpoint } = useFlags()

  const { artistContent, isLoading } = useArtistStructureContentPerRoles({
    parentId,
    query: '&all=true',
    roles: [ACCESS_ROLES.OWNER],
  })

  if (isLoading)
    return (
      <SContainer>
        <BaseLoader text={t('steps.files.loadingSingles')} isLoading />
      </SContainer>
    )

  if (!artistContent?.data.length)
    return (
      <SContainer>
        <SText>{t('steps.files.createSingle')}</SText>
      </SContainer>
    )

  const formatOptions = ({ id, category, name }) => ({
    label: name,
    value: id,
    isExpandable: category === STRUCTURE_TYPE.ALBUM,
    icon: category === STRUCTURE_TYPE.ALBUM ? <PackIcon /> : <SingleIcon />,
  })

  const fetchChildItems = async parentId => {
    const { data } = await StructureApi.getStructureContent(
      `?parentId=${parentId}&withoutPagination=true`,
      useAltStructuresEndpoint
    )

    return (data?.data || []).map(formatOptions)
  }

  return (
    <STreeSelect
      options={artistContent?.data.map(formatOptions)}
      fetchItems={fetchChildItems}
      onChange={onChange}
    />
  )
}

SingleTree.propTypes = {
  parentId: string.isRequired,
  onChange: func.isRequired,
}
