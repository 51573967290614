import React, { useEffect, useState } from 'react'
import { string, func, bool, shape, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FilesTree } from '#modules/files-tree'
import { ErrorMessage } from '#components/error-message'
import { RequiredInfo } from '#pages/sell-collectibles/create-collectible/_components/required-info'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { PlaySong } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/play-song'
import { MusicRequirements } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/music-requirements'
import { UploadFile } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file'
import { TreeHeader } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/tree_header'
import {
  audioFileValidator,
  isFileReady,
} from '#pages/sell-collectibles/create-collectible/_utils'
import { useFile } from '#hooks/swr/useFiles'

const SContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
`

const SFileTreeWrapper = styled.div`
  width: 33.75rem;
  max-height: 33.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
`

const SRightContent = styled.div`
  flex-grow: 1;
`

const SMusicRequirements = styled(MusicRequirements)`
  max-width: 25rem;
`

export const MusicFileSelect = ({
  parentId,
  name,
  value,
  onFileSelect,
  error,
  accept,
  hasPersonaSingle,
}) => {
  const { t } = useTranslation('nftWizard')
  const [reloadCount, setReloadCount] = useState(0)
  const { file } = useFile(value?.id)

  useEffect(() => {
    if (file && isFileReady(file)) {
      // Update selected file info to validate isNftAllowed flag after processing
      onFileSelect(file)
    }
  }, [file])

  const { readOnly } = useNftCreator()

  const onUpload = () => {
    setReloadCount(reloadCount + 1)
  }

  return (
    <SContentWrapper>
      {parentId && (
        <div>
          <SFileTreeWrapper>
            <TreeHeader
              title={t('steps.files.singlesPacks')}
              parentId={parentId}
              name={name}
            />
            <FilesTree
              selectedFileId={value?.id}
              handleFileSelect={onFileSelect}
              parentId={parentId}
              disabled={readOnly}
              reloadCount={reloadCount}
              showFilesCondition={SHOW_FILES_CONDITION.AUDIO}
              disableSelect={file => !file.isNftAllowed}
              disabledTooltipText={t('steps.files.nftNotAllowed')}
              showProcessing
            />
          </SFileTreeWrapper>
          {error && (
            <ErrorMessage
              errorMessage={
                error?.message ||
                error?.fileSuffix?.message ||
                error?.id?.message
              }
            />
          )}
        </div>
      )}
      <SRightContent>
        <PlaySong file={value} profileId={parentId} />
        <SMusicRequirements primary />
        {!readOnly && (
          <>
            <RequiredInfo i18nKey="steps.files.selectMusicFile" />
            <UploadFile
              parentId={parentId}
              accept={accept}
              hasPersonaSingle={hasPersonaSingle}
              validator={audioFileValidator}
              onUpload={onUpload}
              onFileSelect={onFileSelect}
            />
          </>
        )}
      </SRightContent>
    </SContentWrapper>
  )
}

MusicFileSelect.propTypes = {
  parentId: string.isRequired,
  name: string,
  value: shape({
    id: string,
    name: string,
    fileSuffix: string,
    hasMetadata: bool,
    previewStatus: string,
    isNftAllowed: bool,
  }),
  onFileSelect: func,
  error: shape({
    message: string,
  }),
  accept: arrayOf(string),
  hasPersonaSingle: bool,
}
