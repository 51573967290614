import React, { createContext, useEffect } from 'react'
import { node } from 'prop-types'
import { useSettings } from '#hooks/swr/useSettings'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { maintenanceSwitch } from '../globals/helpers/maintenance'

export const SystemContext = createContext({
  settings: {},
  isLoaded: false,
  isLoading: false,
  isError: false,
  maintenanceMode: false,
})

const { Provider } = SystemContext

export const SystemProvider = ({ children }) => {
  const { settings, isLoading, isError } = useSettings()
  const { maintenance: maintenanceMode } = useFlags()

  useEffect(() => {
    if (maintenanceMode?.enabled) {
      maintenanceSwitch(maintenanceMode, true)
    }
  }, [maintenanceMode])

  const value = {
    settings,
    isLoaded: !!settings,
    isLoading,
    isError,
    maintenanceMode,
  }
  return <Provider {...{ value }}>{children}</Provider>
}

SystemProvider.propTypes = {
  children: node.isRequired,
}
