import React from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseInput } from '#components/inputs/base-input'
import { bool, node, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { PriceInput } from '#components/price-input'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { StepSubHeader } from '#pages/sell-collectibles/create-collectible/_components/step-subheader'

const SWrapper = styled.div`
  max-width: 33.75rem;
  margin-bottom: 2rem;
`

const inputStyle = css`
  width: 25rem;

  input[type='number'] {
    text-align: left;
  }
`

const SPriceInput = styled(PriceInput)`
  ${inputStyle}
`

const SBaseInput = styled(BaseInput)`
  ${inputStyle}
`

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  margin-bottom: 1rem;
`

export const FieldSection = ({ title, text, isPrice, name, label }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const { readOnly } = useNftCreator()

  const inputProps = {
    inputRef: register,
    errorMessage: errors[name]?.message,
    control,
    required: true,
    disabled: readOnly,
    name,
    label,
  }

  return (
    <SWrapper>
      <StepSubHeader title={title} />
      <SText>{text}</SText>
      {isPrice ? (
        <SPriceInput {...inputProps} />
      ) : (
        <SBaseInput type="number" {...inputProps} />
      )}
    </SWrapper>
  )
}

FieldSection.propTypes = {
  title: string,
  text: node,
  label: string,
  name: string,
  isPrice: bool,
}
