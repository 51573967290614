import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    background: theme.palette.color.minorGrey,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '24px',
    minHeight: '60px',
  },
  header: {
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
  },
}))

export const TableHeader = ({ headings }) => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.headerWrapper}>
      {headings.map(({ nameTranslationKey, width, justifyContent }) => (
        <div
          key={nameTranslationKey}
          className={classes.header}
          style={{
            justifyContent: justifyContent || 'none',
            width,
          }}
        >
          {t(`${nameTranslationKey}`)}
        </div>
      ))}
    </div>
  )
}

TableHeader.propTypes = {
  headings: arrayOf(
    shape({
      nameTranslationKey: string,
      width: string,
    })
  ).isRequired,
}
