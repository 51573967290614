import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
    borderTop: `1px solid ${theme.palette.color.primary}`,
  },
}))

const SButton = styled(Button)`
  margin-right: 10px;
  text-transform: uppercase;
`

const SButtonTwo = styled(Button)`
  text-transform: uppercase;
`

export const FooterSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <SButton onClick={() => navigate(NAVIGATION_PATHS.CREATE_PROFILE)}>
        {t('createNewProfile')}
      </SButton>
      <SButtonTwo onClick={() => navigate(NAVIGATION_PATHS.VAULT_PROFILES)}>
        {t('viewMyProfiles')}
      </SButtonTwo>
    </div>
  )
}
