import React from 'react'
import { makeStyles } from '@material-ui/core'
import { func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SUBSCRIPTION_PERIOD } from '#pages/profile/utils/constants'
import { RadioCheckbox } from '#components/radio-checkbox'

const useStyles = makeStyles(() => ({
  radiosWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    width: 350,
  },
  radioLabel: {
    marginLeft: 10,
  },
}))

export const SubscriptionRadioButtons = ({
  subscriptionPeriod,
  setSubscriptionPeriod,
  monthlyPrice,
  annualPrice,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('profile')

  return (
    <div className={classes.radiosWrapper}>
      <RadioCheckbox
        id="annualBilling"
        name="billing"
        label={t('annualBilling')}
        customLabel={<span className={classes.radioLabel}>{annualPrice}</span>}
        checked={subscriptionPeriod === SUBSCRIPTION_PERIOD.YEAR}
        isVertical
        isLabelBolded
        onChange={() => setSubscriptionPeriod(SUBSCRIPTION_PERIOD.YEAR)}
      />
      <RadioCheckbox
        id="monthlyBilling"
        name="billing"
        label={t('monthlyBilling')}
        customLabel={<span className={classes.radioLabel}>{monthlyPrice}</span>}
        checked={subscriptionPeriod === SUBSCRIPTION_PERIOD.MONTH}
        isVertical
        isLabelBolded
        onChange={() => setSubscriptionPeriod(SUBSCRIPTION_PERIOD.MONTH)}
      />
    </div>
  )
}

SubscriptionRadioButtons.propTypes = {
  subscriptionPeriod: string.isRequired,
  setSubscriptionPeriod: func.isRequired,
  monthlyPrice: string.isRequired,
  annualPrice: string.isRequired,
}
