import React from 'react'
import { func, string, arrayOf } from 'prop-types'
import { StructureTree } from '#modules/choose-file-tree'
import { SectionTitle } from '#components/section-title'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  titleWrapper: {
    marginBottom: '2rem',
  },
}))

export const ProfileFilesTree = ({ title, ...structureTreeProps }) => {
  const classes = useStyles()

  return (
    <div>
      {title && (
        <div className={classes.titleWrapper}>
          <SectionTitle>{title}</SectionTitle>
        </div>
      )}
      <StructureTree {...structureTreeProps} />
    </div>
  )
}

ProfileFilesTree.propTypes = {
  handleFileSelect: func.isRequired,
  selectedFileId: string.isRequired,
  parentId: string.isRequired,
  title: string,
  height: string.isRequired,
  width: string.isRequired,
  structureCategory: string.isRequired,
  showFilesCondition: string,
  roles: arrayOf(ACCESS_ROLES),
}

ProfileFilesTree.defaultProps = {
  title: '',
  showFilesCondition: '',
  roles: [],
}
