import React from 'react'
import { makeStyles } from '@material-ui/core'
import { string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    width: 350,
  },
  label: {
    fontSize: 18,
    fontWeight: 700,
  },
}))

export const SubscriptionModalInfo = ({
  monthlyPrice,
  annualPrice,
  isMonthVisible,
  isAnnualVisible,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('profile')

  return (
    <div className={classes.wrapper}>
      {isAnnualVisible && (
        <div>
          <span className={classes.label}>{t('annualBilling')}</span>{' '}
          {annualPrice}
        </div>
      )}
      {isMonthVisible && (
        <div>
          <span className={classes.label}>{t('monthlyBilling')}</span>{' '}
          {monthlyPrice}
        </div>
      )}
    </div>
  )
}

SubscriptionModalInfo.propTypes = {
  monthlyPrice: string.isRequired,
  annualPrice: string.isRequired,
  isMonthVisible: bool.isRequired,
  isAnnualVisible: bool.isRequired,
}
