import React from 'react'
import { arrayOf, shape, string, objectOf } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  header: {
    background: theme.palette.color.minorGrey,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingLeft: '1.5rem',
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  headerItem: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: 14,
  },
}))

export const Header = ({ headers }) => {
  const classes = useStyles()

  return (
    <div className={classes.header}>
      {headers.map(header => (
        <div
          key={header.name}
          className={classes.headerItem}
          style={{
            textAlign: header.textAlign,
            paddingRight: header.paddingRight,
            width: header.desktopWidth,
          }}
        >
          {header.name}
        </div>
      ))}
    </div>
  )
}

Header.propTypes = {
  headers: arrayOf(
    shape({
      name: string,
      width: objectOf(string),
    })
  ).isRequired,
}
