import React, { useMemo, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import cn from 'classnames'
import { string, func, arrayOf, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import * as dayjs from 'dayjs'
import {
  collectiblesMetadataValidationSchema,
  generalMetadataValidationSchema,
  trackMetadataValidationSchema,
} from '#pages/vault/song/tabs/metadata/utils/validation'

import {
  metadataTiles,
  metadataOptions,
  collectiblesMetadataFields,
} from '#pages/vault/song/tabs/metadata/utils/constants'
import { Button } from '#components/button'
import { GeneralMetadata } from '#pages/vault/song/tabs/metadata/components/general-metadata'
import { TrackMetadata } from '#pages/vault/song/tabs/metadata/components/track-metadata'
import { ContributorsMetadata } from '#pages/vault/song/tabs/metadata/components/contributors-metadata'
import { ArtistApi } from '#api/requests/artist'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { AddContributorModal } from '#pages/_modules/add-contributor-modal'
import { EditContributorContainer } from '#pages/vault/song/tabs/metadata/containers/edit-contributor-container'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { hexToRgba } from '#utils/hexToRgba'
import {
  prepareDataForMetadataForm,
  reduceFalsyToNullValues,
  timeToMs,
} from '#pages/vault/song/tabs/metadata/utils/helpers'
import { CollectiblesMetadata } from '#pages/vault/song/tabs/metadata/components/collectibles-metadata'

const useStyles = makeStyles(theme => ({
  metadataHeaderWrapper: {
    borderBottom: `1px solid ${theme.palette.color.darkGrey33}`,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  metadataHeader: {
    width: 1050,
    margin: '0 auto',
    border: `1px solid ${theme.palette.color.darkGrey33}`,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  metadataOption: {
    cursor: 'pointer',
    width: '33%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 150,
    },
  },
  centralTab: {
    borderLeft: `1px solid ${theme.palette.color.darkGrey33}`,
    borderRight: `1px solid ${theme.palette.color.darkGrey33}`,
    width: '34%',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
    },
  },
  activeTab: {
    background: hexToRgba(theme.palette.color.eden05, 0.33),
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      color: theme.palette.color.primary,
    },
  },
}))

const SButton = styled(Button)`
  width: 180px;
  min-width: 120px;
  font-size: 16px;
  margin-left: auto;
  margin-right: 35px;
  text-transform: uppercase;
`

export const MetadataContainer = ({
  accessRoles,
  chosenMetadataOption,
  setChosenMetadataOption,
  setHasUnsavedChanges,
  hasUnsavedChanges,
  hideTabs,
}) => {
  const [isAddContributorModalOpen, setIsAddContributorModalOpen] =
    useState(false)
  const [isEditContributorModalOpen, setIsEditContributorModalOpen] =
    useState(false)
  const [idOfActiveContributor, setIdOfActiveContributor] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [error, setError] = useState('')

  const determineFormResolver = () => {
    switch (chosenMetadataOption) {
      case metadataOptions.general:
        return yupResolver(generalMetadataValidationSchema)
      case metadataOptions.track:
        return yupResolver(trackMetadataValidationSchema)
      case metadataOptions.collectibles:
        return yupResolver(collectiblesMetadataValidationSchema)
      default:
        return null
    }
  }

  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const classes = useStyles()
  const { artistId, projectId } = useParams()

  const { actions, errors, loading } = useWithAsyncAction({
    patchArtistSong: ArtistApi.patchArtistSong,
  })

  const {
    project,
    isLoading: isStructureContentLoading,
    mutate: mutateProject,
  } = useArtistStructure(projectId)
  const { project: primaryArtist, isLoading: isPrimaryArtistLoading } =
    useArtistStructure(artistId)

  const methods = useForm({
    resolver: determineFormResolver(),
    shouldUnregister: true,
  })

  const formattedFields = useMemo(
    () =>
      prepareDataForMetadataForm({
        ...project,
        primaryArtist,
      }),
    [project, primaryArtist]
  )

  useEffect(() => {
    methods.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenMetadataOption])

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.patchArtistSong) {
      handleSetError(errors.patchArtistSong)
    }
  }, [errors.patchArtistSong, handleSetError])

  const handleMetadataFormSubmit = async ({ ...values }) => {
    const replacedValues = {
      ...reduceFalsyToNullValues(values),
      ...(values.copyrightYear && {
        copyrightYear: parseInt(values.copyrightYear, 10),
      }),
      ...(values.bpm && {
        bpm: parseInt(values.bpm, 10),
      }),
      ...(values.trackDuration && {
        trackDuration: timeToMs(values.trackDuration).toString(),
      }),
      ...(values.recordingDate && {
        recordingDate: dayjs(values.recordingDate).format(),
      }),
      ...(values.previousReleaseDate && {
        previousReleaseDate: dayjs(values.previousReleaseDate).format(),
      }),
      ...(values.collectible && {
        collectible: reduceFalsyToNullValues(
          _.omit(
            values.collectible,
            Object.values(collectiblesMetadataFields)
              .filter(field => field.omit)
              .map(field => field.name)
          )
        ),
      }),
    }

    // eslint-disable-next-line no-unused-vars
    const { isPreviouslyReleased, ...replacedValuesRest } = replacedValues

    const replace = await actions.patchArtistSong(projectId, replacedValuesRest)
    const data = prepareDataForMetadataForm({
      ...project,
      ...replace.data,
      primaryArtist,
    })
    methods.reset(data)
    await mutateProject({ ...project, ...replace.data })
  }

  const handleOpenContributorModal = () => {
    setIsAddContributorModalOpen(true)
  }

  const handleCloseContributorModal = () => {
    setIsAddContributorModalOpen(false)
  }

  const handleOpenEditContributorModal = contributorId => {
    setIsEditContributorModalOpen(true)
    setIdOfActiveContributor(contributorId)
  }

  const handleCloseEditContributorModal = () => {
    setIsEditContributorModalOpen(false)
    setIdOfActiveContributor(null)
  }

  const shouldShowSaveMetadataButton = () =>
    accessRoles?.includes(ACCESS_ROLES.OWNER) ||
    accessRoles?.includes(ACCESS_ROLES.COLLABORATOR)

  const isLoading = isStructureContentLoading || isPrimaryArtistLoading

  if (loading.patchArtistSong || error) {
    return (
      <CommonLoadingOverlay
        message={vaultTranslation.t('songIsBeingCreated')}
        dialogOpen={loading.patchArtistSong}
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleMetadataFormSubmit)}>
        <div className={classes.metadataHeaderWrapper}>
          <div className={classes.metadataHeader}>
            {!hideTabs &&
              metadataTiles(accessRoles).map(({ title, metadataOption }) => (
                <span
                  key={metadataOption}
                  className={cn(classes.metadataOption, {
                    [classes.activeTab]:
                      chosenMetadataOption === metadataOption,
                  })}
                  onClick={() => {
                    setChosenMetadataOption(metadataOption)
                  }}
                >
                  {vaultTranslation.t(title)}
                </span>
              ))}
            {shouldShowSaveMetadataButton() &&
              chosenMetadataOption !== metadataOptions.contributors && (
                <SButton type="submit" disabled={!hasUnsavedChanges}>
                  {t('save')}
                </SButton>
              )}
          </div>
        </div>
        {chosenMetadataOption === metadataOptions.collectibles && (
          <CollectiblesMetadata
            isLoading={isLoading}
            setHasUnsavedChanges={setHasUnsavedChanges}
            formattedFields={formattedFields}
          />
        )}
        {chosenMetadataOption === metadataOptions.general && (
          <GeneralMetadata
            isLoading={isLoading}
            setHasUnsavedChanges={setHasUnsavedChanges}
            formattedFields={formattedFields}
          />
        )}
        {chosenMetadataOption === metadataOptions.contributors && (
          <ContributorsMetadata
            {...{
              accessRoles,
              projectId,
              handleOpenContributorModal,
              handleOpenEditContributorModal,
              pageIndex,
              setPageIndex,
              rowsPerPage,
              setRowsPerPage,
            }}
          />
        )}
        {chosenMetadataOption === metadataOptions.track && (
          <TrackMetadata
            isLoading={isLoading}
            setHasUnsavedChanges={setHasUnsavedChanges}
            formattedFields={formattedFields}
          />
        )}
      </form>
      <AddContributorModal
        isOpened={isAddContributorModalOpen}
        handleClose={handleCloseContributorModal}
        defaultRole={null}
        {...{ pageIndex, rowsPerPage }}
      />
      <EditContributorContainer
        {...{
          projectId,
          isEditContributorModalOpen,
          handleCloseEditContributorModal,
          idOfActiveContributor,
          pageIndex,
          rowsPerPage,
        }}
      />
    </FormProvider>
  )
}

MetadataContainer.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  chosenMetadataOption: string.isRequired,
  setChosenMetadataOption: func,
  setHasUnsavedChanges: func.isRequired,
  hasUnsavedChanges: bool.isRequired,
  hideTabs: bool,
}
