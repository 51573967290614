import React from 'react'
import { func, shape, string, bool, node } from 'prop-types'
import { RadioButton } from '#pages/_components/radio-button'
import styled, { css } from 'styled-components'
import { ROW_HEIGHT } from '#components/tree-select/_constants'

const getBgColor = (isEven, isNested) => {
  if (isEven) return isNested ? 'primary_10%' : 'neutral_100'
  return isNested ? 'primary_5%' : 'neutral_95'
}

const SRow = styled.li`
  display: flex;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    isEven,
    isNested,
  }) => COLORS[getBgColor(isEven, isNested)]};
  padding: 0 1rem;
  height: ${ROW_HEIGHT}px;
  align-items: center;
  cursor: pointer;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
`

const SIconWrapper = styled.div`
  ${({
    theme: {
      tunego: { COLORS },
    },
    isNested,
  }) =>
    isNested
      ? css`
          color: ${COLORS.neutral_0};
          padding-left: 1.5rem;
        `
      : css`
          color: ${COLORS.primary_50};
        `};

  padding-right: 0.5rem;
  display: flex;
  align-items: center;
`

export const Row = ({ option, onSelect, isSelected, isEven, isNested }) => {
  return (
    <SRow isEven={isEven} isNested={isNested} onClick={() => onSelect(option)}>
      <RadioButton checked={isSelected} />
      <SIconWrapper isNested={isNested}>{option.icon}</SIconWrapper>
      {option.label}
    </SRow>
  )
}

Row.propTypes = {
  option: shape({
    label: string,
    value: string,
    isExpandable: bool,
    icon: node,
  }).isRequired,
  onSelect: func,
  isSelected: bool,
  isEven: bool,
  isNested: bool,
}
