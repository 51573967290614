import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNfts } from '#hooks/swr/useNfts'
import { CustomSelect } from '#components/inputs/custom-select'
import { Pagination } from '#components/pagination'
import { HeadingBar } from '#components/heading-bar'
import { CollectibleTable } from '#pages/sell-collectibles/my-collectibles/_components/collectible-table'
import { useGetProfiles } from '#components/inputs/utils'
import { CreateCollectibleButton } from '#pages/_components/create-collectible-button'
import { ButtonTheme } from '@tunego/tunego-ui-components'

const SListContainer = styled.div`
  margin: 4rem 0;
`

const STopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background: ${({ theme: { colors } }) => colors.darkBlue03};
  flex-grow: 1;
`

const SCreateCollectibleButton = styled(CreateCollectibleButton)`
  height: 2.5rem;
  margin-right: 1.75rem;
  margin-left: auto;
  padding: 0 2.5rem;
  text-transform: none;
`

const SCustomSelect = styled(CustomSelect)`
  width: 18.75rem;
  margin-left: 1.25rem;
`

export const CollectibleListContainer = () => {
  const { t } = useTranslation('sellCollectibles')

  const profiles = useGetProfiles('nft')

  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [profileId, setProfileId] = useState(profiles[0].value)
  const query = `?page=${pageIndex}&limit=${rowsPerPage}&profileId=${profileId}`
  const { nfts, count, isLoading, revalidate } = useNfts(query)

  const onChangeRowPerPageHandler = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPageIndex(0)
  }

  return (
    <SListContainer>
      <HeadingBar>{t('list.title')}</HeadingBar>
      <STopBar>
        <SCustomSelect
          id="persona_select"
          options={profiles}
          defaultValue={profiles[0]}
          isForProfiles
          onChange={profile => setProfileId(profile.value)}
        />
        <SCreateCollectibleButton buttonTheme={ButtonTheme.Secondary}>
          + {t('list.addNew')}
        </SCreateCollectibleButton>
      </STopBar>
      {nfts && (
        <CollectibleTable
          nfts={nfts}
          query={query}
          isLoading={isLoading}
          revalidate={revalidate}
        />
      )}
      <Pagination
        count={count || 10}
        page={pageIndex}
        rowsPerPage={rowsPerPage}
        onChangePage={setPageIndex}
        onChangeRowsPerPage={onChangeRowPerPageHandler}
      />
    </SListContainer>
  )
}
