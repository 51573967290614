import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FilesTree } from '#modules/files-tree'
import { RequiredInfo } from '#pages/sell-collectibles/create-collectible/_components/required-info'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { ErrorMessage } from '#components/error-message'
import { arrayOf, func, shape, string, bool } from 'prop-types'
import { UploadFile } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file'
import { TreeHeader } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/tree_header'
import { ArtworkPreview } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/artwork-preview'
import { ArtworkRequirements } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/artwork-requirements'
import {
  artworkFileValidator,
  isFileReady,
} from '#pages/sell-collectibles/create-collectible/_utils'
import { useFile } from '#hooks/swr/useFiles'

const SContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
`

const SFileTreeWrapper = styled.div`
  width: 33.75rem;
  max-height: 33.75rem;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
`

const SRightContent = styled.div`
  flex-grow: 1;
`

const SArtworkRequirements = styled(ArtworkRequirements)`
  max-width: 25rem;
`

export const ArtworkFileSelect = ({
  parentId,
  name,
  value,
  onFileSelect,
  error,
  accept,
  hasPersonaSingle,
}) => {
  const { t } = useTranslation('nftWizard')
  const [reloadCount, setReloadCount] = useState(0)
  const { file } = useFile(value?.id)

  const { readOnly } = useNftCreator()

  useEffect(() => {
    if (file && isFileReady(file)) {
      // Update selected file info to validate isNftImageAllowed flag after processing
      onFileSelect(file)
    }
  }, [file])

  const onUpload = () => {
    setReloadCount(reloadCount + 1)
  }

  return (
    <SContentWrapper>
      {parentId && (
        <div>
          <SFileTreeWrapper>
            <TreeHeader
              title={t('steps.files.singlesPacks')}
              parentId={parentId}
              name={name}
            />
            <FilesTree
              selectedFileId={value?.id}
              handleFileSelect={onFileSelect}
              parentId={parentId}
              disabled={readOnly}
              reloadCount={reloadCount}
              showFilesCondition={SHOW_FILES_CONDITION.ARTWORK}
              disableSelect={file => !file.isNftImageAllowed}
              disabledTooltipText={t('steps.files.nftNotAllowed')}
              showProcessing
            />
          </SFileTreeWrapper>
          {error && (
            <ErrorMessage errorMessage={error?.message || error?.id?.message} />
          )}
        </div>
      )}
      <SRightContent>
        <ArtworkPreview file={value} />
        <SArtworkRequirements primary />
        {!readOnly && (
          <>
            <RequiredInfo i18nKey="steps.files.selectArtworkFile" />
            <UploadFile
              parentId={parentId}
              accept={accept}
              hasPersonaSingle={hasPersonaSingle}
              validator={artworkFileValidator}
              isImage
              onUpload={onUpload}
              onFileSelect={onFileSelect}
            />
          </>
        )}
      </SRightContent>
    </SContentWrapper>
  )
}

ArtworkFileSelect.propTypes = {
  parentId: string.isRequired,
  name: string,
  value: shape({
    id: string,
    name: string,
  }),
  onFileSelect: func,
  error: shape({
    message: string,
  }),
  accept: arrayOf(string),
  hasPersonaSingle: bool,
}
