import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'
import { Button } from '#components/button'
import { useWizard } from '#modules/forms/form-wizard/context'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  tracksWrapper: {
    width: 250,
    marginRight: 35,
  },
  header: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.color.default,
    marginBottom: 15,
  },
  trackItem: {
    display: 'inline-block',
    fontSize: 14,
    lineHeight: '21px',
    width: '100%',
  },
}))

const SButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  border-radius: 10px;
  margin-top: 15px;
  text-transform: uppercase;
`

export const TrackListing = ({ isPreview }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStep, storedFormState } = useWizard()

  const handleStepChange = () => setStep(3)

  return (
    <div className={classes.tracksWrapper}>
      <span className={classes.header}>{t('trackListing')}</span>
      {storedFormState?.songFiles.map((song, index) => (
        <span
          key={generateUniqueKey(song, index)}
          className={classes.trackItem}
        >{`${index + 1}. ${song.songName}`}</span>
      ))}
      {isPreview && (
        <SButton onClick={handleStepChange}>{t('editTracks')}</SButton>
      )}
    </div>
  )
}

TrackListing.propTypes = {
  isPreview: bool.isRequired,
}
