import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { func, arrayOf, shape, bool } from 'prop-types'
import { RowWrapper } from '#pages/vault/song/tabs/splits/components/row-wrapper'
import { ColumnInRow } from '#pages/vault/song/tabs/splits/components/column-in-row'
import { sumColumnTotal } from '#pages/vault/song/tabs/splits/utils/helpers'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { HEADERS } from '#pages/vault/song/tabs/splits/utils/constants'

const SStyledButton = styled(Button)`
  width: 5rem;
  height: 2.5rem;
  padding: 0 0.625rem;
  margin-left: 12.5rem;
  text-transform: uppercase;
`

const SEditRow = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding-left: 2.5rem;
  padding-bottom: 1rem;
`

const SColumnInRow = styled(ColumnInRow)`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: ${({ theme: { typography } }) => typography.bold};
`

export const TotalRow = ({
  tempSplitIncomes,
  isAcceptanceView,
  openEditIncomeModal,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  return (
    <>
      <RowWrapper isSumTotal>
        <SColumnInRow width={HEADERS[0].width} my={2} alignItems="flex-start">
          {vaultTranslation.t('totalMustEqual')}
        </SColumnInRow>
        <ColumnInRow width={HEADERS[1].width} justifyContent="center">
          <span>
            {`${sumColumnTotal(tempSplitIncomes, 'masterIncomeToDisplay')}%`}
          </span>
        </ColumnInRow>
        <SColumnInRow justifyContent="center" width={HEADERS[2].width}>
          {`${sumColumnTotal(tempSplitIncomes, 'publishingToDisplay')}%`}
        </SColumnInRow>
      </RowWrapper>
      <SEditRow>
        {!isAcceptanceView && (
          <SStyledButton
            variant={VARIANTS.NEW_BLUE_SPECIAL}
            onClick={openEditIncomeModal}
          >
            {t('edit')}
          </SStyledButton>
        )}
      </SEditRow>
    </>
  )
}

TotalRow.propTypes = {
  tempSplitIncomes: arrayOf(shape()).isRequired,
  isAcceptanceView: bool,
  openEditIncomeModal: func.isRequired,
}

TotalRow.defaultProps = {
  isAcceptanceView: false,
}
