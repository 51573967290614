import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useUnreadNotifications } from '#hooks/swr/useNotifications'
import { NotificationsListContainer } from '#modules/notifications/containers/notifications-list-container'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { NotificationsApi } from '#api/requests/notifications'
import { NotificationIcon } from '#modules/notifications/components/notification-icon'
import { socketInstance } from '#api/socketio'
import { NotificationsToastContainer } from '#modules/notifications/containers/notifications-toast-container'

export const Notifications = () => {
  const [isOpened, setIsOpened] = useState(false)
  const { unreadNotifications, mutate: mutateUnread } = useUnreadNotifications()
  const [wsNotification, setWsNotification] = useState(null)
  const toastContainerRef = useRef()

  useEffect(() => {
    const callback = data => {
      const formattedWsNotification = {
        ...data,
        senderUser: { ...data.sender },
      }
      setWsNotification(formattedWsNotification)
      if (toastContainerRef.current)
        toastContainerRef.current.addNotification(formattedWsNotification)
      mutateUnread(unread => ({ ...unread, count: (unread?.count ?? 0) + 1 }))
    }
    socketInstance.initialize()
    socketInstance.socket.on('notification', callback)
    return () => {
      socketInstance.socket.off('notification', callback)
    }
  }, [mutateUnread])

  const { actions, loading } = useWithAsyncAction({
    markAsRead: NotificationsApi.markAsRead,
    markAllAsRead: NotificationsApi.markAllAsRead,
    getNotifications: NotificationsApi.getNotifications,
    getSingleNotification: NotificationsApi.getSingleNotification,
  })

  const handleSetIsOpen = useCallback(
    isOpen => {
      setWsNotification(null)
      setIsOpened(isOpen)
    },
    [setIsOpened]
  )

  const markAsReadProxy = async (id, data) => {
    if (toastContainerRef.current)
      toastContainerRef.current.clearNotification(id)
    await actions.markAsRead(id, data)
  }
  const markAllAsReadProxy = async () => {
    if (toastContainerRef.current) toastContainerRef.current.clearAll()
    await actions.markAllAsRead()
  }

  return (
    <>
      <NotificationsToastContainer
        ref={toastContainerRef}
        markAsRead={actions.markAsRead}
      />
      <div>
        <NotificationIcon
          badgeContent={unreadNotifications?.count ?? 0}
          setIsOpened={setIsOpened}
          isOpened={isOpened}
        />
        {isOpened && (
          <NotificationsListContainer
            {...{
              setIsOpened: handleSetIsOpen,
              markAsRead: markAsReadProxy,
              markAllAsRead: markAllAsReadProxy,
              getSingleNotification: actions.getSingleNotification,
              getNotifications: actions.getNotifications,
              triggerUnreadNotificationsMutate: mutateUnread,
              wsNotification,
              isOpened,
              isLoading: loading.getNotifications,
            }}
          />
        )}
      </div>
    </>
  )
}
