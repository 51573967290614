import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { func, string, bool } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { FilesApi } from '#api/requests/files'
import { BaseInput } from '#components/inputs/base-input'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { fileNameValidationSchema } from '#pages/vault/song/utils/validation'
import { DeleteModal } from '#pages/_components/delete-modal'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { useError } from '#hooks/useError'
import { Tooltip } from '#components/tooltip'
import { TOOLTIP_THEME } from '#components/tooltip/tooltipStyle'

const SEditContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_25%']};
  padding: 1rem 1.5rem 0.5rem;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
`

const SButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  padding-left: 0;

  @media screen and (min-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.md}) {
    padding-left: 0.75rem;
  }
`

const SButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

const STooltip = styled(Tooltip)`
  width: 100%;
`

export const EditOrRemoveContainer = ({
  name,
  id,
  handleCloseInlineAction,
  shouldEnableDeletion,
  disableDelete,
}) => {
  const { setFileInfo, fileInfo } = useGlobalAudioPlayer()

  const { error, setError, clearError } = useError()
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const {
    register,
    handleSubmit,
    errors: formErrors,
    formState,
  } = useForm({
    resolver: yupResolver(fileNameValidationSchema(vaultTranslation.t)),
  })
  const { actions, anyLoading, anyError } = useWithAsyncAction({
    updateFile: FilesApi.updateFile,
    deleteFile: FilesApi.deleteFile,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (anyError) {
      handleSetError(anyError)
    }
  }, [anyError, handleSetError])

  const onSubmit = async values => {
    await actions.updateFile(id, values)
    handleCloseInlineAction()
    if (fileInfo?.fileId === id) {
      setFileInfo({ ...fileInfo, fileName: values.name })
    }
  }

  const onDelete = async () => {
    try {
      await actions.deleteFile(id)
      handleCloseInlineAction()
    } catch (err) {
      setIsDeleteModalOpened(false)
    }
  }

  return (
    <>
      <SEditContainer onSubmit={handleSubmit(onSubmit)}>
        <BaseInput
          customBg="black"
          name="name"
          errorMessage={formErrors?.name?.message}
          inputRef={register}
          defaultValue={name}
        />
        <SButtons>
          <SButton type="submit" disabled={!formState.isDirty}>
            {t('save')}
          </SButton>
          <SButton
            variant={VARIANTS.CANCEL_BLACK}
            onClick={handleCloseInlineAction}
          >
            {t('cancel')}
          </SButton>
          {shouldEnableDeletion && (
            <STooltip
              disabled={!disableDelete}
              text={vaultTranslation.t('cannotDeleteFile')}
              theme={TOOLTIP_THEME.WARNING}
            >
              <SButton
                variant={VARIANTS.WARNING}
                disabled={disableDelete}
                onClick={() => {
                  setIsDeleteModalOpened(true)
                }}
              >
                {vaultTranslation.t('deleteFile')}
              </SButton>
            </STooltip>
          )}
        </SButtons>
      </SEditContainer>
      <DeleteModal
        handleClose={handleCloseInlineAction}
        open={isDeleteModalOpened}
        handleDelete={onDelete}
        firstSpan={vaultTranslation.t('areYouSureYouWantDelete', {
          structure: vaultTranslation.t('file'),
        })}
        secondSpan={vaultTranslation.t('thisActionIsPermanent')}
        thirdSpan={vaultTranslation.t('pleaseConfirmYouWantDelete', {
          structure: `${vaultTranslation.t('file')}.`,
        })}
        deleteBtnText={vaultTranslation.t('yesPermanentlyDeleteStructure', {
          structure: vaultTranslation.t('file'),
        })}
      />
      <CommonLoadingOverlay
        message={vaultTranslation.t('fileIsBeingUpdated')}
        dialogOpen={anyLoading}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </>
  )
}

EditOrRemoveContainer.propTypes = {
  shouldEnableDeletion: bool.isRequired,
  handleCloseInlineAction: func,
  name: string.isRequired,
  id: string.isRequired,
  disableDelete: bool,
}

EditOrRemoveContainer.defaultProps = {
  handleCloseInlineAction: () => {},
}
