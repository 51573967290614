import React, { useEffect } from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { DetailsForm } from '#pages/sell-collectibles/create-collectible/_components/steps/details/_components/details-form'
import { StepSubHeader } from '#pages/sell-collectibles/create-collectible/_components/step-subheader'
import { Divider } from '#pages/sell-collectibles/create-collectible/_components/divider'
import { NFT_DETAILS_METADATA_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'
import { WarningIcon } from '#icons'
import { Tooltip } from '#components/tooltip'
import styled from 'styled-components'
import { TOOLTIP_THEME } from '#components/tooltip/tooltipStyle'

const SHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const SWarningIcon = styled(WarningIcon)`
  margin-bottom: 1rem;
`

export const Details = ({ structureId }) => {
  const { t } = useTranslation('nftWizard')
  const { formState, resetField } = useFormContext()

  const { project, isLoading } = useArtistStructure(structureId)

  useEffect(() => {
    if (project) {
      NFT_DETAILS_METADATA_FIELDS.filter(
        field => !formState.dirtyFields[field]
      ).forEach(field => {
        resetField(field, {
          defaultValue: project.collectible[field],
        })
      })
    }
  }, [project])

  return (
    <>
      <SHeaderWrapper>
        <StepSubHeader title={t('steps.details.addDetails')} />
        <Tooltip
          text={t('steps.details.changesInVault')}
          theme={TOOLTIP_THEME.WARNING}
        >
          <SWarningIcon />
        </Tooltip>
      </SHeaderWrapper>
      {!isLoading && <DetailsForm />}
      <Divider />
    </>
  )
}

Details.propTypes = {
  structureId: string,
}
