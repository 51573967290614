import { string, number, func } from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '#icons'
import styled, { css } from 'styled-components'
import { CANCEL_STATUS } from '#modules/upload-manager/constants'
import { ErrorMessage } from '#components/error-message'

const SRow = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
`

const SNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 1;
  ${({ cancelled }) =>
    cancelled &&
    css`
      opacity: 0.7;
    `}
`

const SUploadingProgressWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SUploadingProgress = styled.p`
  margin-left: auto;
  margin-right: 1.25rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};

  word-break: break-all;
`

const SErrorMessage = styled(ErrorMessage)`
  grid-column: 1 / 2;
`

const SButton = styled.button`
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
`

const SCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
`

export const UploaderItem = ({ file, onCancel }) => {
  const { t } = useTranslation('vault')
  const { progress, name } = file
  const [cancelStatus, setCancelStatus] = useState(CANCEL_STATUS.None)

  const handleCancel = () => {
    try {
      if (cancelStatus === CANCEL_STATUS.InProgress) {
        return
      }
      setCancelStatus(CANCEL_STATUS.InProgress)

      onCancel(file)

      setCancelStatus(CANCEL_STATUS.Success)
    } catch {
      setCancelStatus(CANCEL_STATUS.Error)
    }
  }

  const showDisableButton = [CANCEL_STATUS.Error, CANCEL_STATUS.None].includes(
    cancelStatus
  )

  return (
    <SRow>
      <SNameWrapper cancelled={cancelStatus === CANCEL_STATUS.Success}>
        <p>{name}</p>
        <SUploadingProgressWrapper>
          <SUploadingProgress>
            {t('uploadingProgress')}
            {progress.toFixed(1)}%
          </SUploadingProgress>
        </SUploadingProgressWrapper>
        {cancelStatus === CANCEL_STATUS.Error && (
          <SErrorMessage errorMessage={t('cancelError')} />
        )}
      </SNameWrapper>
      {showDisableButton && (
        <SButton onClick={handleCancel}>
          <SCloseIcon />
        </SButton>
      )}
    </SRow>
  )
}

UploaderItem.propTypes = {
  filename: string.isRequired,
  progress: number.isRequired,
  onCancel: func.isRequired,
}
