import React from 'react'
import { node, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  row: {
    minHeight: 60,
    padding: '8px 0px 16px 24px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  lighter: {
    background: theme.palette.color.darkGrey0B,
  },
  isPreviewReady: {
    background: hexToRgba(theme.palette.color.primary, 0.25),
  },
}))

export const Row = ({ children, isLighter, isPreviewReady }) => {
  const classes = useStyles()

  return (
    <div
      className={cn(classes.row, {
        [classes.lighter]: isLighter,
        [classes.isPreviewReady]: isPreviewReady,
      })}
    >
      {children}
    </div>
  )
}

Row.propTypes = {
  isLighter: bool.isRequired,
  children: node.isRequired,
  isPreviewReady: bool,
}

Row.defaultProps = {
  isPreviewReady: false,
}
