import React from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal, Button, ButtonTheme } from '@tunego/tunego-ui-components'

const SModal = styled(Modal)`
  width: 525px;

  h2 {
    margin: 0;
  }
`

const SHeader = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`

const SText = styled.p`
  margin: 0;
`

const SBoldText = styled(SText)`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SWarningText = styled(SBoldText)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

const SButton = styled(Button)`
  margin-top: 3rem;
  align-self: center;
`

export const DeleteModal = ({
  open,
  handleClose,
  handleDelete,
  deleteBtnText,
  firstSpan,
  secondSpan,
  thirdSpan,
}) => {
  const { t } = useTranslation()

  return (
    <SModal
      isOpen={open}
      open={open}
      header={true}
      title={<SHeader>{t('warning')}</SHeader>}
      closeButton
      onClose={handleClose}
    >
      <SBoldText>{firstSpan}</SBoldText>
      <SWarningText>{secondSpan}</SWarningText>
      <SText>{thirdSpan}</SText>
      <SButton buttonTheme={ButtonTheme.Warning} onClick={handleDelete}>
        {deleteBtnText}
      </SButton>
    </SModal>
  )
}

DeleteModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleDelete: func.isRequired,
  deleteBtnText: string.isRequired,
  firstSpan: string,
  secondSpan: string,
  thirdSpan: string,
}

DeleteModal.defaultProps = {
  firstSpan: '',
  secondSpan: '',
  thirdSpan: '',
}
