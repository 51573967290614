import React from 'react'
import { Link } from '@reach/router'
import { bool, string } from 'prop-types'
import { isCurrentUri, isExternalUri } from '../../utils/helpers'
import styled, { css } from 'styled-components'

const navLinkStyle = css`
  display: inline-flex;
  color: ${({ theme: { colors } }) => colors.default};
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0px;
  text-transform: none;
  border-radius: 0px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
`

const buttonLabelStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
`

const SNavlinkWrapper = styled.div`
  ${navLinkStyle};
`

const SLink = styled(Link)`
  ${navLinkStyle};
`

const SLinkTitle = styled.span`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  opacity: 1;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  color: ${({ theme: { colors }, selected }) =>
    selected ? colors.primary : colors.default};
`

const SButtonLabel = styled.div`
  ${buttonLabelStyle};
`

const SButtonLabelLink = styled.a`
  ${buttonLabelStyle};
`

export const NavigationButton = ({ link, title, disabled }) => {
  const currentLink = isCurrentUri(link)

  const externalLink = isExternalUri(link)

  return (
    <SNavlinkWrapper id={`topnav${title}`}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {disabled ? (
        <SButtonLabel>
          <SLinkTitle>{title}</SLinkTitle>
        </SButtonLabel>
      ) : externalLink ? (
        <SButtonLabelLink href={link} target="_blank" rel="noopener noreferrer">
          <SLinkTitle>{title}</SLinkTitle>
        </SButtonLabelLink>
      ) : (
        <SLink to={link}>
          <SLinkTitle selected={currentLink}>{title}</SLinkTitle>
        </SLink>
      )}
    </SNavlinkWrapper>
  )
}

NavigationButton.propTypes = {
  link: string.isRequired,
  title: string.isRequired,
  disabled: bool,
}

NavigationButton.defaultProps = {
  disabled: false,
}
