import React from 'react'
import { node } from 'prop-types'
import { LandingPage } from './landing-page'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export { LandingPage, Routes }
