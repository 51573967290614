import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SelectProfileComponent } from '#modules/select-profile-v2'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { selectProfileValidationSchema } from '#pages/nft/_utils/validation'
import { FORM_FIELDS, PROFILE_STATUS } from '#pages/nft/_utils/constants'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { StructureApi } from '#api/requests/structure'
import { ArtistApi } from '#api/requests/artist'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { EmptyVaultInfo } from '#pages/_components/empty-vault-info'
import { useIsVaultEmpty } from '#hooks/useIsVaultEmpty'
import { NoFilesForNft } from '#pages/nft/wizard/components/no-files-for-nft'
import { useError } from '#hooks/useError'

export const SelectProfile = () => {
  const { t } = useTranslation()
  const nftTranslation = useTranslation('nft')
  const { setStoredFormState, clearStoredFormState, storedFormState } =
    useWizard()
  const [isProfileSelected, setProfileSelected] = useState(false)
  const [profileStatus, setProfileStatus] = useState('')
  const { error, setResponseError, clearError } = useError()
  const roles = [ACCESS_ROLES.OWNER]

  const { actions, loading } = useWithAsyncAction({
    getArtist: ArtistApi.getArtists,
    getProfileContent: StructureApi.getStructureContent,
  })
  const isEmptyVault = useIsVaultEmpty(actions.getArtist)
  const isNextStepButtonDisabled =
    !isProfileSelected || profileStatus || loading.getProfileContent

  const areFilesProperForNft = profiles => {
    return profiles.some(profile => !!profile.filesCount?.nft)
  }

  const onChange = async profile => {
    setProfileStatus('')
    setProfileSelected(!!profile)

    const profileId = profile?.value
    const filesCount = profile?.filesCount

    if ((filesCount?.all || 0) < 1) {
      setProfileStatus(PROFILE_STATUS.NO_DATA)
      return
    }
    if (storedFormState.profileId && storedFormState.profileId !== profileId) {
      clearStoredFormState()
    }

    setStoredFormState({ profileId, profileName: profile?.label })

    try {
      const { data } = await actions.getProfileContent(`?parentId=${profileId}`)

      if (data?.count === 0) {
        setProfileStatus(PROFILE_STATUS.NO_DATA)
      } else if (!areFilesProperForNft(data?.data)) {
        setProfileStatus(PROFILE_STATUS.NO_PROPER_DATA)
      }
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <StepContainer
      shouldDisableNextButton={isNextStepButtonDisabled}
      resolver={yupResolver(selectProfileValidationSchema(nftTranslation.t))}
      customBackTitle={t('cancel')}
    >
      {isEmptyVault ? (
        <EmptyVaultInfo />
      ) : (
        <SelectProfileComponent
          subtitle="selectTheProfileThatContains"
          handleChange={onChange}
          name={FORM_FIELDS.PROFILE}
          roles={roles}
          createNewProfileText="ifYouDoNotSeeTheProfile"
        >
          <NoFilesForNft profileStatus={profileStatus} />
        </SelectProfileComponent>
      )}
      <CommonLoadingOverlay error={error} onErrorBackdropClick={clearError} />
    </StepContainer>
  )
}
