import React from 'react'
import { string, number, func } from 'prop-types'
import { LinearProgress, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TABLE_HEADER } from '#pages/vault/song/tabs/files/utils/constants'
import { SongFileProcessing } from '#pages/distro/wizard/steps/add-song-files/components/song-file-processing'
import { FILE_STATUS } from '#pages/distro/_utils/constants'
import { LoadingIndicator } from '#components/loaders/loading-indicator'

const useStyles = makeStyles(theme => ({
  progressRow: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.color.primary,
    minHeight: 60,
    padding: '0 26px',
  },
  progressFileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '1rem',
    width: TABLE_HEADER[1].width,
  },
  colorPrimary: {
    background: theme.palette.color.darkBlue09,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.color.default,
  },
  error: {
    color: theme.palette.color.errorRed,
    marginLeft: 'auto',
  },
  flex: {
    flex: 1,
  },
}))

export const FilesListItemInProgress = ({ name, progress, onDelete }) => {
  const classes = useStyles()
  const { t } = useTranslation('validation')

  const handleDelete = () => {
    if (onDelete) {
      onDelete(name)
    }
  }

  if (progress === FILE_STATUS.PROCESSING) {
    return (
      <SongFileProcessing name={name} marginLeft="auto" onDelete={handleDelete}>
        <LoadingIndicator />
      </SongFileProcessing>
    )
  }

  if (progress === FILE_STATUS.ERROR) {
    return (
      <SongFileProcessing name={name} marginLeft="none" onDelete={handleDelete}>
        <span className={classes.error}>{t('fileDoesNot')}</span>
      </SongFileProcessing>
    )
  }

  return (
    progress < 100 && (
      <div className={classes.progressRow}>
        <div className={classes.progressFileName}>{name}</div>
        <div className={classes.flex}>
          <LinearProgress
            classes={{
              colorPrimary: classes.colorPrimary,
              barColorPrimary: classes.barColorPrimary,
            }}
            variant="determinate"
            value={progress}
          />
        </div>
      </div>
    )
  )
}

FilesListItemInProgress.propTypes = {
  name: string.isRequired,
  progress: number.isRequired,
  onDelete: func,
}

FilesListItemInProgress.defaultProps = {
  onDelete: null,
}
