import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { bool, number, func, string, shape } from 'prop-types'
import { DraggingTrigger } from '#pages/distro/wizard/steps/add-song-files/components/dragging-trigger'
import { MetadataInfoContainer } from '#pages/distro/wizard/steps/add-song-files/containers/metadata-info-container'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { hexToRgba } from '#utils/hexToRgba'
import { FIELDS } from '#pages/distro/_utils/constants'
import { useWizard } from '#modules/forms/form-wizard/context'

const useStyles = makeStyles(theme => {
  return {
    itemContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      position: 'relative',
      padding: '8px 5px 8px 50px',
      height: 60,
      width: '100%',
    },
    index: {
      fontSize: '2rem',
      fontFamily: '"AvenirNextLTPro-Bold"',
      color: theme.palette.color.default,
      marginRight: 20,
      opacity: 0.15,
    },
    dragButton: {
      width: 100,
      marginRight: 20,
    },
    nameWrapper: {
      width: 550,
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    menuWrapper: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      width: 350,
      marginLeft: 'auto',
    },
    isOpened: {
      justifyContent: 'flex-end',
    },
    isOpenedMenu: {
      backgroundColor: hexToRgba(theme.palette.color.primary, 0.5),
    },
  }
})

const SButton = styled(Button)`
  border: none;
  opacity: ${({ isMetadataMenuVisible }) => (isMetadataMenuVisible ? 1 : 0.33)};
`

export const SongFileMenu = ({
  fileId,
  index,
  itemProvided,
  name,
  onClick,
  onDeleteClick,
  isReadyForDistribution,
  isMetadataMenuVisible,
}) => {
  const classes = useStyles()

  const { setStoredFormState, storedFormState } = useWizard()

  const handleCancel = () => {
    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === fileId
          ? { ...file, isMetadataFormOpened: false }
          : { ...file }
      ),
    })
  }

  return (
    <div
      className={`${classes.itemContainer} ${
        isMetadataMenuVisible && classes.isOpenedMenu
      }`}
    >
      <span className={classes.index}>{index + 1}</span>
      <div className={classes.dragButton}>
        <DraggingTrigger {...itemProvided.dragHandleProps} />
      </div>
      <div className={classes.nameWrapper}>
        <p className={classes.name}>{name}</p>
      </div>
      <div
        className={`${classes.menuWrapper} ${
          isMetadataMenuVisible && classes.isOpened
        }`}
      >
        <MetadataInfoContainer
          isReadyForDistribution={isReadyForDistribution}
          isMetadataMenuVisible={isMetadataMenuVisible}
          onClick={onClick}
          onCancel={handleCancel}
        />
        <SButton
          variant={VARIANTS.TRANSPARENT}
          isMetadataMenuVisible={isMetadataMenuVisible}
          onClick={onDeleteClick}
        >
          <img
            src="/assets/svg/ic-trash.svg"
            width="20px"
            height="20px"
            alt="Delete icon"
          />
        </SButton>
      </div>
    </div>
  )
}

SongFileMenu.propTypes = {
  fileId: string.isRequired,
  index: number.isRequired,
  itemProvided: shape({}).isRequired,
  name: string.isRequired,
  isReadyForDistribution: bool.isRequired,
  onDeleteClick: func.isRequired,
  onClick: func.isRequired,
  isMetadataMenuVisible: bool.isRequired,
}
