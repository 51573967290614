import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { userDetailsValidationSchema } from '#pages/profile/utils/validation'
import { BaseInput } from '#components/inputs/base-input'
import { hexToRgba } from '#utils/hexToRgba'
import { Button } from '#components/button'
import { Text } from '#components/text'

const SButton = styled(Button)`
  width: 294px;
  margin: 2rem 0;
`

const SActionContainer = styled.div`
  margin: auto;
  border: 1px solid ${({ theme: { colors } }) => hexToRgba(colors.default, 0.7)};
  display: flex;
  align-items: center;
  padding-top: 60px;
  flex-direction: column;
  height: 100vh;
  max-width: 1051px;
`

const SInputGroup = styled.div`
  display: flex;
  margin-top: 1.25rem;
`

const SFirstNameInput = styled.div`
  margin-right: 2.5rem;
`

const SSubTitle = styled(Text)`
  font-weight: 500;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

const STitleGroup = styled.div`
  max-width: 630px;
  width: 100%;
`

export const WelcomeToTunego = ({ createAccount }) => {
  const { t } = useTranslation()
  const onboardingTranslation = useTranslation('onboarding')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema(onboardingTranslation.t)),
  })

  return (
    <SActionContainer>
      <STitleGroup>
        <Text>{onboardingTranslation.t('welcome')}</Text>
        <SSubTitle>{onboardingTranslation.t('letsGetThisParty')}</SSubTitle>
      </STitleGroup>
      <form onSubmit={handleSubmit(createAccount)}>
        <SInputGroup>
          <SFirstNameInput>
            <BaseInput
              label={onboardingTranslation.t('firstName')}
              placeholder={onboardingTranslation.t('firstName')}
              errorMessage={errors.firstName?.message}
              name="firstName"
              inputRef={register}
              isBolded
            />
          </SFirstNameInput>
          <BaseInput
            label={onboardingTranslation.t('lastName')}
            placeholder={onboardingTranslation.t('lastName')}
            errorMessage={errors.lastName?.message}
            name="lastName"
            inputRef={register}
            isBolded
          />
        </SInputGroup>
        <SButton type="submit" id="welcomeCreateAccountButton">
          {t('letsGo')}
        </SButton>
      </form>
    </SActionContainer>
  )
}

WelcomeToTunego.propTypes = {
  createAccount: func.isRequired,
}
