import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { bool, func, string } from 'prop-types'
import { Button } from '#components/button'

const useStyles = ({ reverse, isLast }) =>
  makeStyles(() => ({
    sectionWrapper: {
      display: 'flex',
      wrap: 'wrap',
      justifyContent: 'space-between',
      flexDirection: reverse ? 'row-reverse' : 'row',
      alignContent: 'center',
      alignItems: 'center',
      maxWidth: 1200,
      margin: '0 auto',
      marginBottom: isLast ? '10rem' : '5rem',
      padding: '0 8px',
    },
    sectionHeader: {
      fontSize: 40,
      fontFamily: 'AvenirNextLTPro-Bold',
      component: 'h2',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      lineHeight: '42px',
    },
    sectionDescription: {
      fontSize: 18,
      marginBottom: 24,
      lineHeight: '24px',
    },
    gridText: {
      display: 'flex',
      flexDirection: 'column',
      margin: '1rem 0 1rem',
      maxWidth: 600,
    },
    gridImage: {
      display: 'flex',
      alignItems: 'center',
    },
    image: {
      width: '100%',
      height: 'auto',
      maxHeight: '860px',
      maxWidth: '440px',
    },
    span: {
      fontFamily: 'AvenirNextLTPro-Bold',
      textTransform: 'uppercase',
    },
    none: {
      display: 'none',
    },
    flex: {
      display: 'flex',
    },
  }))()

const SButton = styled(Button)`
  width: fit-content;
  text-transform: uppercase;
`

const SButtonTwo = styled(SButton)`
  margin-left: 20px;
`

export const InfoSection = ({
  image,
  titleTranslationKey,
  descriptionTranslationKey,
  buttonTextTranslationKey,
  buttonTextTranslationKey2,
  reverse,
  onButtonClick,
  onButtonClick2,
  isLast,
}) => {
  const isInLimitedMaintenance = window.LIMITED_MAINTENANCE

  const classes = useStyles({ reverse, isLast })
  const { t } = useTranslation('home')

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.gridText}>
        <h2 className={classes.sectionHeader}>{t(titleTranslationKey)}</h2>
        <p className={classes.sectionDescription}>
          {t(descriptionTranslationKey)}
        </p>
        <div
          className={`${isInLimitedMaintenance ? classes.none : classes.flex}`}
        >
          <SButton onClick={onButtonClick}>
            <span className={classes.span}>{t(buttonTextTranslationKey)}</span>
          </SButton>
          {buttonTextTranslationKey2 && (
            <SButtonTwo onClick={onButtonClick2}>
              <span className={classes.span}>
                {t(buttonTextTranslationKey2)}
              </span>
            </SButtonTwo>
          )}
        </div>
      </div>
      <img src={image} alt="" className={classes.image} />
    </div>
  )
}

InfoSection.propTypes = {
  image: string.isRequired,
  titleTranslationKey: string.isRequired,
  icon: string.isRequired,
  titleIcon: string.isRequired,
  subtitleTranslationKey: string.isRequired,
  descriptionTranslationKey: string.isRequired,
  buttonTextTranslationKey: string.isRequired,
  reverse: bool,
  onButtonClick: func.isRequired,
  onButtonClick2: func,
}

InfoSection.defaultProps = {
  reverse: false,
  onButtonClick2: () => {},
}
