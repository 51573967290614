import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { DetailsContainer } from '#pages/distro/past-release-details/containers/details-container'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useError } from '#hooks/useError'
import { TEXT_TYPE, Text } from '#components/text'
const useStyles = makeStyles(() => ({
  headerWrapper: {
    paddingLeft: 30,
  },
  header: {
    fontSize: 22,
    margin: '20px 0',
    width: '100%',
  },
}))

export const ReviewAndSubmit = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { incrementStep, storedFormState } = useWizard()
  const { error, clearError, setResponseError } = useError()

  const { actions, loading } = useWithAsyncAction({
    submitForApproval: DistroApi.submitForApproval,
  })

  const handleSubmit = async () => {
    try {
      await actions.submitForApproval(storedFormState[FIELDS.DISTRO_ID])
      incrementStep()
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <StepContainer
      customNextTitle={t('submitForDistribution')}
      customSubmitAction={handleSubmit}
    >
      <div className={classes.headerWrapper}>
        <Text type={TEXT_TYPE.H2} className={classes.header}>
          {t('reviewAndSubmit')}
        </Text>
        <span>{t('pleaseVerifyTheFollowing')}</span>
      </div>
      <DetailsContainer isPreview />
      <CommonLoadingOverlay
        dialogOpen={loading.submitForApproval}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </StepContainer>
  )
}
