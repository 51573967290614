import React from 'react'
import { arrayOf, node, oneOfType } from 'prop-types'
import { Home } from './landing-page'
import { CreatePromotion } from './wizard/steps'
import { Report } from './report'
import { Reports } from './reports'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
}

export { CreatePromotion, Home, Report, Reports, Routes }
