import React from 'react'
import { TopSection } from './sections/top-section'
import { SecuritySection } from './sections/security-section'
import { BottomIconsSection } from './sections/bottom-icons-section'
import { MainContentSection } from './sections/main-content-section'
import { FooterSection } from './sections/footer-section'
import { PageWrapper } from '#modules/page-wrapper'

export const LandingPage = () => {
  return (
    <PageWrapper showNavBar isFullWidth>
      <TopSection />
      <SecuritySection />
      <MainContentSection />
      <BottomIconsSection />
      <FooterSection />
    </PageWrapper>
  )
}
