import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  CAMERA,
  DEFAULT_PIXEL_RATIO,
  LIGHT,
  MATERIAL,
  PARTICLES,
  ROTATION_SPEED,
  SPHERE,
} from '#utils/constants'
import * as THREE from 'three'

const SParticles = styled.canvas`
  position: absolute;
  z-index: -1;
  transform: scale(2);
  opacity: 30%;
`

const initializeCanvas = () => {
  const canvas = document.querySelector('canvas.webgl')
  const scene = new THREE.Scene()
  const geometry = new THREE.SphereBufferGeometry(
    SPHERE.RADIUS,
    SPHERE.WIDTH_SEGMENTS,
    SPHERE.HEIGHT_SEGMENTS
  )
  const particlesGeometry = new THREE.BufferGeometry()
  const sizes = { width: window.innerWidth, height: window.innerHeight }
  const aspectRatio = sizes.width / sizes.height
  const camera = new THREE.PerspectiveCamera(
    CAMERA.FOV,
    aspectRatio,
    CAMERA.NEAR,
    CAMERA.FAR
  )
  const renderer = canvas
    ? new THREE.WebGLRenderer({ canvas, alpha: true })
    : null
  const clock = new THREE.Clock()

  const setPosition = () => {
    const posArray = Float32Array.from(
      { length: PARTICLES.COUNT },
      () =>
        (Math.random() - PARTICLES.Z_OFFSET) *
        (Math.random() * PARTICLES.SPREAD)
    )

    particlesGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(posArray, 3)
    )
  }

  const getMaterials = () => {
    const material = { size: MATERIAL.SIZE, color: MATERIAL.COLOR }
    const sphere = new THREE.PointsMaterial(material)
    const particles = new THREE.PointsMaterial(material)

    return {
      sphere,
      particles,
    }
  }

  const createSceneObjects = () => {
    const materials = getMaterials()

    const sphere = new THREE.Points(geometry, materials.sphere)
    const particlesMesh = new THREE.Points(
      particlesGeometry,
      materials.particles
    )

    return {
      sphere,
      particlesMesh,
    }
  }

  const { sphere, particlesMesh } = createSceneObjects()

  const setupObjects = () => scene.add(sphere, particlesMesh)

  const setupLightSettings = () => {
    const pointLight = new THREE.PointLight(LIGHT.COLOR, LIGHT.INTENSITY)
    pointLight.position.x = LIGHT.POSITION.x
    pointLight.position.y = LIGHT.POSITION.y
    pointLight.position.z = LIGHT.POSITION.z
    scene.add(pointLight)
  }

  const setupRendererSettings = () => {
    renderer?.setSize(sizes.width, sizes.height)
    renderer?.setPixelRatio(
      Math.min(window.devicePixelRatio, DEFAULT_PIXEL_RATIO)
    )
  }

  const injectResizeListener = () => {
    window.addEventListener('resize', () => {
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      setupRendererSettings()
    })
  }

  const setupCameraSettings = () => {
    camera.position.x = CAMERA.POSITION.x
    camera.position.y = CAMERA.POSITION.y
    camera.position.z = CAMERA.POSITION.z
    scene.add(camera)
  }

  const runClock = () => {
    const elapsedTime = clock.getElapsedTime()

    sphere.rotation.y = ROTATION_SPEED * elapsedTime
    particlesMesh.rotation.y = -ROTATION_SPEED * elapsedTime

    renderer?.render(scene, camera)

    window.requestAnimationFrame(runClock)
  }

  setPosition()
  setupObjects()
  setupLightSettings()
  injectResizeListener()
  setupCameraSettings()
  setupRendererSettings()
  runClock()
}

export const ParticleSphere = () => {
  useEffect(() => {
    initializeCanvas()
  }, [])

  return <SParticles className="webgl" />
}
