import React, { useState } from 'react'
import { number, func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  FIELDS,
  PERFORMANCE_TYPE,
  HEADER_TITLES,
} from '#pages/distro/_utils/constants'
import { Header } from '#pages/distro/wizard/steps/add-song-files/components/header'
import { AddSongMetadataWrapper } from '#pages/distro/wizard/steps/add-song-files/components/add-song-metadata-wrapper'
import { AddContributorModal } from '#pages/_modules/add-contributor-modal'
import { ButtonRow } from '#components/button-row'
import { ContributorsList } from '#pages/distro/wizard/steps/add-song-files/components/contributors-list'
import { useContributors } from '#hooks/swr/useContributors'
import { useWizard } from '#modules/forms/form-wizard/context'
import { BaseLoader } from '#components/loaders/base-loader'
import { checkIfHasRole } from '#pages/distro/_utils/helpers'
import { EditContributorContainer } from '#pages/vault/song/tabs/metadata/containers/edit-contributor-container'

export const ContributorsStep = ({
  songId,
  metadataStep,
  setMetadataStep,
  isOwner,
}) => {
  const { t } = useTranslation('distro')
  const { setStoredFormState, storedFormState } = useWizard()

  const [isAddContributorModalOpen, setIsAddContributorModalOpen] =
    useState(false)
  const [isEditContributorModalOpen, setIsEditContributorModalOpen] =
    useState(false)
  const [idOfActiveContributor, setIdOfActiveContributor] = useState(null)

  const [pageIndex] = useState(0)
  const [rowsPerPage] = useState(10)
  const [defaultRole, setDefaultRole] = useState(null)

  const selectedSong = storedFormState[FIELDS.SONG_FILES].find(
    item => item.id === songId || item.isMetadataFormOpened
  )

  const { contributors, isLoading, mutate } = useContributors(
    selectedSong?.id,
    ''
  )
  const { data } = contributors || {}

  const handleOpenContributorModal = () => {
    setIsAddContributorModalOpen(true)
  }

  const handleCloseContributorModal = () => {
    setDefaultRole(null)
    setIsAddContributorModalOpen(false)
  }

  const handleOpenEditContributorModal = contributorId => {
    setIsEditContributorModalOpen(true)
    setIdOfActiveContributor(contributorId)
  }

  const handleCloseEditContributorModal = () => {
    setIsEditContributorModalOpen(false)
    setIdOfActiveContributor(null)
  }

  const headerTitles =
    data?.length > 0 ? HEADER_TITLES : HEADER_TITLES.slice(0, 2)
  const hasComposer = checkIfHasRole(data, 'composer')
  const isInstrumental =
    selectedSong?.performanceLanguage === PERFORMANCE_TYPE.INSTRUMENTAL
  const hasLyricist = isInstrumental || checkIfHasRole(data, 'lyricist')

  const handleAddContributor = contributor => {
    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === selectedSong?.id
          ? {
              ...file,
              contributors: [...file.contributors, contributor],
            }
          : { ...file }
      ),
    })
  }

  const handleEditContributor = contributor => {
    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === selectedSong?.id
          ? {
              ...file,
              contributors: file.contributors.map(c =>
                c.id === contributor.id ? { ...c, ...contributor } : { ...c }
              ),
            }
          : { ...file }
      ),
    })
  }

  const handleDeleteContributor = contributorId => {
    setStoredFormState({
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
        file.id === selectedSong?.id
          ? {
              ...file,
              contributors: file.contributors.filter(
                contributor => contributor.id !== contributorId
              ),
            }
          : { ...file }
      ),
    })
  }

  return (
    <AddSongMetadataWrapper
      {...{ metadataStep, setMetadataStep }}
      shouldDisableNextButton={!hasComposer || !hasLyricist}
      onBackButtonClick={() => setMetadataStep(metadataStep - 1)}
      onNextButtonClick={() => setMetadataStep(metadataStep + 1)}
    >
      {isLoading ? (
        <BaseLoader isLoading={isLoading} />
      ) : (
        <>
          {isOwner && (
            <ButtonRow
              text={`+ ${t('addContributor')}`}
              onClick={handleOpenContributorModal}
            />
          )}
          <Header titles={headerTitles} />
          <ContributorsList
            contributors={data}
            setDefaultRole={setDefaultRole}
            openContributorModal={handleOpenContributorModal}
            openEditContributorModal={handleOpenEditContributorModal}
            mutateDistro={mutate}
            songId={selectedSong?.id}
            hasComposer={hasComposer}
            hasLyricist={hasLyricist}
            isOwner={isOwner}
          />
          <AddContributorModal
            isOpened={isAddContributorModalOpen}
            handleClose={handleCloseContributorModal}
            songId={selectedSong?.id}
            mutateDistro={mutate}
            handleAddContributor={handleAddContributor}
            defaultRole={defaultRole}
            isForDistro
            {...{ pageIndex, rowsPerPage }}
          />
          <EditContributorContainer
            {...{
              projectId: selectedSong?.id,
              isEditContributorModalOpen,
              handleCloseEditContributorModal,
              idOfActiveContributor,
              isForDistro: true,
              mutateDistro: mutate,
              handleDistroEditContributor: handleEditContributor,
              handleDeleteDistroContributor: handleDeleteContributor,
              pageIndex,
              rowsPerPage,
            }}
          />
        </>
      )}
    </AddSongMetadataWrapper>
  )
}

ContributorsStep.propTypes = {
  songId: string.isRequired,
  metadataStep: number.isRequired,
  setMetadataStep: func.isRequired,
  isOwner: bool.isRequired,
}
