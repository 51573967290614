import React from 'react'
import { node } from 'prop-types'
import { LandingPage } from './landing-page'
import { Masters } from './view-masters'
import { MasterNew } from './wizard'

const Routes = ({ children }) => <>{children}</>

Routes.propTypes = {
  children: node.isRequired,
}

export { LandingPage, MasterNew, Masters, Routes }
