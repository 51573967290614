import React, { useState } from 'react'
import styled from 'styled-components'
import { string, arrayOf, node, func, shape } from 'prop-types'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import { generateUniqueKey } from '#utils/generateUniqueKey'
import { Text } from '#components/text'

const SCard = styled.div`
  width: 205px;
  height: 245px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  background-color: ${({ theme: { colors } }) => colors.darkGrey1A};
  overflow: hidden;
`

const STitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`

const STitle = styled(Text)`
  align-items: flex-start;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

const SCardActions = styled.div`
  box-sizing: border-box;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0 0.6rem 0 0.9rem;

  ${({ menuOpen, theme: { colors } }) =>
    menuOpen &&
    `
    background-color: ${colors.primary};
  `};
`

const SActionArea = styled.button`
  display: flex;
  flex: 1;
  background-image: url('${({ img }) => img}');
  background-size: ${({ avatar }) =>
    avatar === DEFAULT_AVATAR ? '90%' : 'cover'};
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: ${({ theme: { colors } }) => colors.default};
    z-index: 1;
    transition: opacity 0.3s linear;
  }

  &:hover::after {
    opacity: 0.1;
  }
`

const STagLabelWrapper = styled.ul`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;
  margin: 0;
  padding: 5px;
`

const STagLabel = styled.li`
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-weight: 400;
  font-size: 10px;
  list-style: none;
  margin: 4px;
  padding: 1px 6px;
  background-color: ${({ theme: { colors } }) => colors.primaryMask};
`

const SEllipsis = styled.div`
  width: 2.5rem;

  color: ${({ menuOpen, theme: { colors } }) =>
    menuOpen ? colors.default : colors.primary};
`

export const Card = ({
  tags,
  title,
  imgSrc,
  children,
  options,
  onClick,
  onChooseOption,
  customOption,
}) => {
  const [ellipsisMenu, setEllipsisMenu] = useState(false)

  return (
    <SCard>
      {tags.length ? (
        <STagLabelWrapper>
          {tags.map((tag, index) => (
            <STagLabel key={generateUniqueKey(tag, index)}>{tag}</STagLabel>
          ))}
        </STagLabelWrapper>
      ) : null}
      <SActionArea img={imgSrc} onClick={onClick} />
      <SCardActions menuOpen={ellipsisMenu}>
        <STitleWrapper>
          <STitle>{title}</STitle>
          {options && (
            <SEllipsis menuOpen={ellipsisMenu}>
              <EllipsisMenu
                options={options}
                activeIconColor="inherit"
                customOption={customOption}
                onChoose={onChooseOption}
                onMenuOpen={() => setEllipsisMenu(true)}
                onMenuClose={() => setEllipsisMenu(false)}
              />
            </SEllipsis>
          )}
        </STitleWrapper>
      </SCardActions>
      {children}
    </SCard>
  )
}

Card.propTypes = {
  title: string.isRequired,
  tags: arrayOf(string),
  imgSrc: string.isRequired,
  onClick: func.isRequired,
  options: arrayOf(
    shape({
      title: string,
      actionType: string,
    })
  ),
  onChooseOption: func,
  children: node,
  customOption: node,
}

Card.defaultProps = {
  tags: [],
}
