import React from 'react'
import styled from 'styled-components'
import { bool, func, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { CustomSelect } from '#components/inputs/custom-select'
import { Modal } from '@tunego/tunego-ui-components'
import { BaseInput } from '#components/inputs/base-input'
import { AutoComplete } from '#components/inputs/auto-complete'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { FORM_FIELDS } from '#pages/_modules/add-contributor-modal/utils/constants'
import { addExistingUserValidationSchema } from '#pages/_modules/add-contributor-modal/utils/validation'
import { loadContactsList } from '#pages/vault/landing-page/utils/helpers'
import { PRO_OPTIONS } from '#constants/proOptions'
import { AddOffPlatformFooter } from '#pages/_components/add-off-platform-footer'
import { Checkbox } from '#components/checkbox'
import { useUser } from '#hooks/useUser'

const SRoleSelect = styled.div`
  margin: 1rem 0 0.5rem 0;
  width: 100%;
`

const SModal = styled(Modal)`
  width: 500px;

  h2 {
    margin: 0;
  }
`

const SForm = styled.form`
  width: 100%;
`

const SCustomSelect = styled(CustomSelect)`
  display: inline-flex;
  width: 48%;
  margin-right: 4%;
  flex-direction: column;
  margin-top: 8px;
`

const SBaseInputOne = styled(BaseInput)`
  display: inline-flex;
  flex-direction: column;
  width: 48%;
`

const SBaseInputTwo = styled(BaseInput)`
  margin-top: 10px;
  width: unset;
`

export const AddExistingUser = ({
  isOpened,
  handleClose,
  handleSwitchSides,
  handleInviteContributor,
  isForDistro,
  defaultRole,
  setChecked,
  checked,
}) => {
  const { t } = useTranslation('vault')

  const { user } = useUser()
  const hasDefaultRole =
    defaultRole !== null && Object.keys(defaultRole).length > 0

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
  } = useForm({
    resolver: yupResolver(addExistingUserValidationSchema(t, isForDistro)),
  })

  const roleSelectProps = hasDefaultRole ? { defaultValue: defaultRole } : null

  const handleCloseModal = () => {
    clearErrors()
    handleClose()
  }

  return (
    <SModal
      isOpen={isOpened}
      title={t(`addContributor`)}
      closeButton
      onClose={handleCloseModal}
    >
      <SForm onSubmit={handleSubmit(handleInviteContributor)}>
        <Controller
          name={FORM_FIELDS.CONTRIBUTOR_NAME}
          control={control}
          render={({ field: { onChange } }) => (
            <AutoComplete
              id="search_profile"
              placeholder={t(`searchUsers`)}
              label={t(`contributorName`)}
              loadOptions={loadContactsList(user)}
              errorMessage={errors[FORM_FIELDS.CONTRIBUTOR_NAME]?.message}
              onChange={({ value }) => {
                onChange(value)
              }}
            />
          )}
        />
        <SRoleSelect>
          <RolesSelect
            control={control}
            errorMessage={errors.role?.message}
            {...roleSelectProps}
          />
          <Checkbox
            name="addAsCollaborator"
            checked={checked}
            label={t('addAsCollaborator')}
            onChange={() => setChecked(!checked)}
          />
        </SRoleSelect>
        {!isForDistro && (
          <>
            <Controller
              name={FORM_FIELDS.PRO_ATTRIBUTE}
              control={control}
              render={({ field: { onChange } }) => (
                <SCustomSelect
                  id="pro_attribute"
                  options={PRO_OPTIONS}
                  label={t(`pro`)}
                  errorMessage={errors[FORM_FIELDS.PRO_ATTRIBUTE]?.message}
                  isBolded
                  isUpperCased
                  onChange={({ value }) => {
                    onChange(value)
                  }}
                />
              )}
            />
            <SBaseInputOne
              key={FORM_FIELDS.IPI_CAE_NUMBER}
              name={FORM_FIELDS.IPI_CAE_NUMBER}
              inputRef={register}
              label={t(`ipiCae`)}
              errorMessage={errors[FORM_FIELDS.IPI_CAE_NUMBER]?.message}
              isBolded
              isUpperCased
            />
          </>
        )}
        <SBaseInputTwo
          name={FORM_FIELDS.PUBLISHER}
          inputRef={register}
          label={t(`publisher`)}
          errorMessage={errors[FORM_FIELDS.PUBLISHER]?.message}
          isBolded
        />
        {!isForDistro && (
          <SBaseInputTwo
            name={FORM_FIELDS.ISNI}
            inputRef={register}
            label={t(`isni`)}
            errorMessage={errors[FORM_FIELDS.ISNI]?.message}
            isBolded
            isUpperCased
          />
        )}
        <AddOffPlatformFooter handleSwitchSides={handleSwitchSides} />
      </SForm>
    </SModal>
  )
}

AddExistingUser.propTypes = {
  isOpened: bool.isRequired,
  handleClose: func.isRequired,
  handleSwitchSides: func.isRequired,
  handleInviteContributor: func.isRequired,
  isForDistro: bool.isRequired,
  defaultRole: shape({}).isRequired,
  setChecked: func.isRequired,
  checked: bool.isRequired,
}
