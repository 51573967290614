import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.color.default,
    color: theme.palette.background.default,
    height: 350,
    textAlign: 'center',
  },
  header: {
    textTransform: 'uppercase',
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: 20,
    paddingTop: 40,
    fontSize: 40,
  },
  subHeader: {
    fontWeight: 'bold',
    marginBottom: 30,
    whiteSpace: 'pre-line',
  },
}))

const SButton = styled(Button)`
  margin: auto;
  text-transform: uppercase;
`

export const GenresLeftSide = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const masteringTranslation = useTranslation('mastering')

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {masteringTranslation.t('allGenresWelcome')}
      </div>
      <div className={classes.subHeader}>
        {masteringTranslation.t('anyStyleAnyGenre')}
      </div>
      <SButton
        onClick={() =>
          navigate(
            IS_DEV_QA
              ? NAVIGATION_PATHS.NEW_MASTERING
              : `${NAVIGATION_PATHS.MASTERING}/coming-soon`
          )
        }
      >
        {t('getStarted')}
      </SButton>
    </div>
  )
}
