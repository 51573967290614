import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontFamily: theme.typography.bold,
    margin: '30px auto',
    textAlign: 'center',
  },
}))

const SButton = styled(Button)`
  width: 120px;
  height: 30px;
  border-radius: 10px;
  margin: 30px 0;
  font-weight: 400;
`

export const ArtworkDropzone = ({ title = 'uploadArtWork' }) => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  return (
    <>
      <span className={classes.title}>{title}</span>
      <img src="/assets/svg/ic-upload.svg" width="29px" height="35px" alt="" />
      <SButton>{t('selectFile')}</SButton>
    </>
  )
}

ArtworkDropzone.propTypes = {
  title: string,
}

ArtworkDropzone.defaultProps = {
  title: 'uploadArtWork',
}
