import React from 'react'
import { FOOTER_LINKS } from './constants'
import styled from 'styled-components'
import { CONTACT_SUPPORT_HREF } from '#constants/contact'

const SFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 90px;
  width: 100%;
  padding: 1rem;
`

const SFooterLogo = styled.img`
  height: 24px;
  object-fit: contain;
  margin: 1rem;
`

const SSpan = styled.span`
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.secondary7B};
  padding: 0 1.5rem;
  position: relative;

  & > a {
    color: ${({ theme: { colors } }) => colors.secondary7B};
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 1px;
        height: 1rem;
        border: 1px solid ${({ theme: { colors } }) => colors.secondary7B};
      },
  },
`

const SFooterLinksWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: center;
`

export const Footer = () => {
  const tuneGoHelpUrl = 'https://help.tunego.com/'

  return (
    <SFooter>
      <SFooterLogo src="/assets/svg/tune-go-logo.svg" alt="" />
      <SSpan>
        Copyright {new Date().getFullYear()} TuneGO, Inc. All Rights Reserved.
      </SSpan>
      <SFooterLinksWrapper>
        {FOOTER_LINKS.map(({ url, title }) => (
          <SSpan key={url}>
            {url === tuneGoHelpUrl ? (
              <a href={CONTACT_SUPPORT_HREF}>{title}</a>
            ) : (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {title}
              </a>
            )}
          </SSpan>
        ))}
      </SFooterLinksWrapper>
    </SFooter>
  )
}
