import React from 'react'
import { func, string } from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { hexToRgba } from '#utils/hexToRgba'
import { ErrorMessage } from '#components/error-message'

const useStyles = makeStyles(theme => ({
  stripeContainer: {
    marginTop: 20,
    width: '100%',
    '& input[type="text"], & .StripeElement': {
      display: 'block',
      margin: '10px 0 5px',
      padding: '10px 14px',
      outline: 0,
      background: 'transparent',
      border: `1px solid ${hexToRgba(theme.palette.color.default, 0.33)}`,
    },
  },
  stripeElementLabel: {
    fontSize: '14px',
    margin: '0 0 25px',
  },
  stripeElementFlexLabel: {
    width: '46%',
  },
  customLabel: {
    fontSize: 14,
    marginLeft: 5,
  },
  nameInput: {
    width: '100%',
    color: theme.palette.color.default,
    caretColor: theme.palette.color.default,
  },
  cardsWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
}))

const useOptions = theme => ({
  style: {
    base: {
      width: '100%',
      fontSize: '14px',
      color: theme.palette.color.default,
      '::placeholder': {
        color: hexToRgba(theme.palette.color.default, 0.7),
      },
    },
    invalid: {
      color: theme.palette.color.error,
    },
  },
})

export const StripeFormInputs = ({
  cardNumberError,
  cardCvcError,
  cardExpiryError,
  nameOnCard,
  setNameOnCard,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const options = useOptions(theme)

  return (
    <div className={classes.stripeContainer}>
      <label className={classes.stripeElementLabel}>
        {t('payments.cardNumber')}
        <CardNumberElement options={options} />
        <ErrorMessage errorMessage={cardNumberError} />
      </label>
      <label className={classes.stripeElementLabel}>
        {t('payments.nameOnCard')}
        <input
          className={classes.nameInput}
          value={nameOnCard}
          type="text"
          onChange={e => setNameOnCard(e.target.value)}
        />
      </label>
      <div className={classes.cardsWrapper}>
        <label
          className={cn(
            classes.stripeElementLabel,
            classes.stripeElementFlexLabel
          )}
        >
          {t('payments.expirationDate')}
          <CardExpiryElement options={options} />
          <ErrorMessage errorMessage={cardExpiryError} />
        </label>
        <label
          className={cn(
            classes.stripeElementLabel,
            classes.stripeElementFlexLabel
          )}
        >
          {t('payments.cvc')}
          <CardCvcElement options={options} />
          <ErrorMessage errorMessage={cardCvcError} />
        </label>
      </div>
    </div>
  )
}

StripeFormInputs.propTypes = {
  cardNumberError: string,
  cardCvcError: string,
  cardExpiryError: string,
  nameOnCard: string.isRequired,
  setNameOnCard: func.isRequired,
}

StripeFormInputs.defaultProps = {
  cardNumberError: '',
  cardCvcError: '',
  cardExpiryError: '',
}
