import * as yup from 'yup'
import i18next from 'i18next'

export const profileValidationSchema = () => {
  return yup.object().shape({
    profile: yup
      .object()
      .nullable()
      .required(i18next.t('validation:requiredField')),
  })
}
