import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { func, oneOf } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'
import {
  PROFILE_PAGES,
  POSSIBLE_PROFILE_PAGES,
  PROFILE_NAVIGATION,
  VERSION_STRING,
} from '#pages/profile/utils/constants'
import { NavigationItem } from '#pages/profile/components/navigation-item'
import { generateUniqueKey } from '#utils/generateUniqueKey'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useStyles = makeStyles(theme => ({
  navigationContainer: {
    minWidth: '270px',
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
  },
  separator: {
    backgroundColor: hexToRgba(theme.palette.color.default, 0.2),
    marginBottom: 16,
    height: 1,
  },
  navLinks: {
    color: theme.palette.color.darkGrey80,
    fontSize: '16px',
    cursor: 'pointer',
  },
  currentPageLink: {
    color: theme.palette.color.default,
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bolder',
  },
  selectBox: {
    width: '250px',
  },
  smallSelect: {
    zIndex: '5',
    borderBottom: `1px solid ${theme.palette.color.darkGrey80}`,
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.palette.color.black,
    height: '60px',
    top: '134px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  version: {
    color: theme.palette.color.darkGrey80,
    textAlign: 'center',
    marginTop: 'auto',
    width: 170,
    wordBreak: 'break-word',
  },
}))

export const Navigation = ({ handleSelect, currentPage }) => {
  const classes = useStyles()
  const { t } = useTranslation('profile')
  const { enableSubscriptions, enablePayment } = useFlags()

  return (
    <div className={classes.navigationContainer}>
      {PROFILE_NAVIGATION(enableSubscriptions, enablePayment).map(
        (option, index) => (
          <NavigationItem
            key={generateUniqueKey(option, index)}
            {...{
              option,
              index,
              currentPage,
              handleSelect,
            }}
          />
        )
      )}
      {currentPage === PROFILE_PAGES.LOGOUT && (
        <div className={classes.version}>
          {t('version', {
            version: VERSION_STRING,
          })}
        </div>
      )}
    </div>
  )
}

Navigation.propTypes = {
  currentPage: oneOf(POSSIBLE_PROFILE_PAGES).isRequired,
  handleSelect: func.isRequired,
}
