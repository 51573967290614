import React from 'react'
import { makeStyles } from '@material-ui/core'
import { bool, func, number, shape } from 'prop-types'
import cn from 'classnames'
import {
  MIN_ROW_HEIGHT,
  TABLE_HEADER,
} from '#pages/vault/song/tabs/metadata/utils/constants'
import { UserImage } from '#components/user-image'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
  upperCased: {
    textTransform: 'uppercase',
  },
  centered: {
    textAlign: 'center',
    width: '100%',
  },
  odd: {
    background: theme.palette.color.darkGrey0B,
  },
  itemWrapper: {
    minHeight: MIN_ROW_HEIGHT,
    display: 'flex',
    paddingLeft: '1rem',
  },
  userColumn: {
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[0].width,
    paddingRight: '0.5rem',
    gap: '0.5rem',
  },
  rolesColumn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: TABLE_HEADER[1].width,
  },
  proColumn: {
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[2].width,
  },
  ipiCeaColumn: {
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[3].width,
    padding: '0 0.5rem',
  },
  publisherColumn: {
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[4].width,
    padding: '0 0.5rem',
  },
  isniColumn: {
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[5].width,
    padding: '0 0.5rem',
  },
  ellipsisMenuColumn: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    width: TABLE_HEADER[6].width,
  },
}))

export const ContributorsMetadataItem = ({
  contributor,
  isOwner,
  determineDisplayedRoles,
  renderEllipsisMenu,
  index,
}) => {
  const classes = useStyles()
  const {
    id,
    pendingName,
    role,
    proAttribute,
    ipiCaeNumber,
    publisher,
    isni,
    status,
    user: { isOnboarded, firstName, lastName, profileImageUrl },
  } = contributor

  return (
    <div
      className={cn(classes.itemWrapper, {
        [classes.odd]: !isEven(index),
      })}
    >
      <div className={classes.userColumn}>
        <UserImage userImageUrl={profileImageUrl} />
        <span className={classes.ellipsis}>
          {isOnboarded ? `${firstName} ${lastName}` : pendingName}
        </span>
      </div>
      <div className={classes.rolesColumn}>{determineDisplayedRoles(role)}</div>
      <div className={classes.proColumn}>
        <span className={`${classes.upperCased} ${classes.centered}`}>
          {proAttribute || '--'}
        </span>
      </div>
      <div className={classes.ipiCeaColumn}>
        <span className={classes.centered}>{ipiCaeNumber || '--'}</span>
      </div>
      <div className={classes.publisherColumn}>
        <span className={`${classes.ellipsis} ${classes.centered}`}>
          {publisher || '--'}
        </span>
      </div>
      <div className={classes.isniColumn}>
        <span className={`${classes.ellipsis} ${classes.centered}`}>
          {isni || '--'}
        </span>
      </div>
      <div className={classes.ellipsisMenuColumn}>
        {isOwner && renderEllipsisMenu(status, id)}
      </div>
    </div>
  )
}

ContributorsMetadataItem.propTypes = {
  contributor: shape({}).isRequired,
  isOwner: bool.isRequired,
  determineDisplayedRoles: func.isRequired,
  renderEllipsisMenu: func.isRequired,
  index: number.isRequired,
}
