export const ACCEPTED_FILE_TYPES = {
  DOCUMENTS:
    'text/plain, application/zip, application/vnd.rar, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  IMAGES: 'image/jpeg, image/png',
  GIFS: 'image/gif',
  AUDIOS: 'audio/wav, audio/flac, audio/x-wav',
}

export const ACCEPTED_IMAGE_EXTENSIONS = ['png', 'jpeg']

export const ACCEPTED_IMAGE_TYPES = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
}

export const ACCEPTED_AUDIO_TYPES = {
  'audio/*': ['wav', 'flac', 'x-wav'],
}
