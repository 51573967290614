import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class StructureApi {
  static getStructureContent = (query, useAltStructuresEndpoint = false) =>
    getApiClient().get(
      `${
        useAltStructuresEndpoint
          ? ENDPOINTS.STRUCTURES_ALT
          : ENDPOINTS.STRUCTURES
      }${query}`
    )

  static getSingleStructure = id =>
    getApiClient().get(buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE, { id }))
}
