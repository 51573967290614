import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { bool } from 'prop-types'
import { useWizard } from '#modules/forms/form-wizard/context'
import { createImageUrl } from '#pages/nft/_utils/helpers'
import { Button } from '#components/button'
import { FIELDS } from '#pages/distro/_utils/constants'

const useStyles = makeStyles(theme => ({
  imgWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 25,
  },
  img: {
    width: 250,
    height: 250,
    marginRight: 35,
    objectFit: 'cover',
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  typoTitle: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '36px',
    color: theme.palette.color.default,
    marginBottom: 10,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    width: 285,
  },
  typoLabel: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.color.default,
    marginBottom: 15,
  },
  typoLabelSmall: {
    fontSize: 18,
    lineHeight: '18px',
    marginBottom: 5,
  },
  typoLabelSmall2: {
    fontWeight: 400,
  },
  buttonsWrapper: {
    display: 'flex',
    marginBottom: 50,
  },
}))

const SButton = styled(Button)`
  min-width: 150px;
  height: 30px;
  border-radius: 10px;
  text-transform: uppercase;
`

const SButtonTwo = styled(SButton)`
  margin-right: 135px;
`

export const ImageAndDetails = ({ isPreview }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStep, storedFormState } = useWizard()

  const imageUrl = createImageUrl(storedFormState?.artworkFile)

  return (
    <>
      <div className={classes.imgWrapper}>
        <img
          src={imageUrl || storedFormState[FIELDS.ARTWORK]}
          alt=""
          className={classes.img}
        />
        <div className={classes.imgDetails}>
          <div>
            <span className={classes.typoTitle}>
              {storedFormState[FIELDS.RELEASE_NAME]}
            </span>
            <span className={classes.typoLabel}>
              {storedFormState[FIELDS.PRIMARY_ARTIST_NAME]}
            </span>
          </div>
          <div>
            <span className={`${classes.typoLabel} ${classes.typoLabelSmall}`}>
              {t('estimateReleaseDate')}
            </span>
            <span
              className={`${classes.typoLabel} ${classes.typoLabelSmall} ${classes.typoLabelSmall2}`}
            >
              {dayjs(storedFormState[FIELDS.ESTIMATED_RELEASE_DATE]).format(
                'MMMM DD, YYYY'
              )}
            </span>
          </div>
          <div>
            <span className={`${classes.typoLabel} ${classes.typoLabelSmall}`}>
              {t('submittedOn')}
            </span>
            <span
              className={`${classes.typoLabel} ${classes.typoLabelSmall} ${classes.typoLabelSmall2}`}
            >
              {dayjs(storedFormState[FIELDS.CREATED_AT]).format(
                'MMMM DD, YYYY'
              )}
            </span>
          </div>
        </div>
      </div>
      {isPreview && (
        <div className={classes.buttonsWrapper}>
          <SButtonTwo onClick={() => setStep(4)}>{t('editArtwork')}</SButtonTwo>
          <SButton onClick={() => setStep(5)}>{t('editReleaseDate')}</SButton>
        </div>
      )}
    </>
  )
}

ImageAndDetails.propTypes = {
  isPreview: bool.isRequired,
}
