import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SUPPORTED_FILE_TYPES } from '#pages/distro/_utils/constants'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles({
  container: {
    margin: '30px 0 0',
  },
  formats: {
    fontWeight: 'bold',
    marginRight: '40px',
  },
  wrapper: {
    display: 'flex',
    marginTop: '20px',
  },
  kHz: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  bit: {
    fontSize: '12px',
  },
  supportedTypesWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
})

export const SupportedFileTypes = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  const supportedFileTypesToRender = SUPPORTED_FILE_TYPES.map((fileType, i) => (
    <div
      key={generateUniqueKey(fileType, i)}
      className={classes.supportedTypesWrapper}
    >
      <span className={classes.kHz}>{fileType.kHz}</span>
      <span className={classes.bit}>{fileType.bit}</span>
    </div>
  ))

  return (
    <div className={classes.container}>
      <span>{t('supportedFileTypes')}</span>
      <div className={classes.wrapper}>
        <span className={classes.formats}>{t('wavAndFlac')}</span>
        <div>{supportedFileTypesToRender}</div>
      </div>
    </div>
  )
}
