import { usePersona, usePersonaSession } from '#hooks/usePersona'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { UsersApi } from '#api/requests/users'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'

export const useIdentityVerification = () => {
  const { user } = useUser()
  const { personaInquiryId, personaVerified } = user || {}
  const { personaSessionToken } = usePersonaSession(personaInquiryId)
  const { error, setResponseError } = useError()

  const { actions } = useWithAsyncAction({
    verifyPersona: UsersApi.postVerifyPersona,
  })

  const { openClient, isReady, isCompleted, inProgress } = usePersona({
    config: {
      templateId: process.env.REACT_APP_PERSONA_PRIMARY_TEMPLATE_ID,
      personaInquiryId,
      sessionToken: personaSessionToken,
      environment: process.env.REACT_APP_PERSONA_ENV,
      handleVerify: actions.verifyPersona,
      setError: setResponseError,
    },
  })

  return {
    error,
    isReady,
    isCompleted,
    inProgress,
    personaVerified,
    openClient,
  }
}
