import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { string, arrayOf } from 'prop-types'
import { mutate } from 'swr'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { TableHeadRow } from '#pages/_modules/collaborators-table/components/table-head-row'
import { MainTableRow } from '#pages/_modules/collaborators-table/components/main-table-row'
import { AddCollaboratorModal } from '#pages/vault/_modules/add-collaborator-modal'
import { TopButtonBar } from '#pages/_modules/collaborators-table/components/top-button-bar'
import { BaseLoader } from '#components/loaders/base-loader'
import { useCollaborators } from '#hooks/swr/useCollaborators'
import { Pagination } from '#components/pagination'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { DeleteModal } from '#pages/_components/delete-modal'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CollaboratorsApi } from '#api/requests/collaborators'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    '& .MuiTableRow-head': {
      background: theme.palette.color.minorGrey,
      '& .MuiTableCell-head': {
        fontFamily: theme.typography.h2.fontFamily,
      },
    },
    '& .MuiTableCell-root': {
      border: 'none',
    },
  },
}))

export const CollaboratorsTable = ({ projectId, topBarTitle, accessRoles }) => {
  const [error, setError] = useState('')
  const [isAddCollabModalOpen, setIsAddCollabModalOpen] = useState(false)
  const [isLeaveCollaborationModalOpen, setIsLeaveCollaborationModalOpen] =
    useState(false)
  const [collaborator, setCollaborator] = useState(null)
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const classes = useStyles()
  const { t } = useTranslation('vault')
  const { actions } = useWithAsyncAction({
    deleteCollaborator: CollaboratorsApi.deleteCollaborator,
  })

  const query = `?page=${pageIndex}&limit=${rowsPerPage}`
  const { collaborators, isLoading } = useCollaborators(projectId, query)
  const { project: structure } = useArtistStructure(projectId)

  const handleCloseAddCollabModal = () => setIsAddCollabModalOpen(false)
  const closeLeaveCollaborationModal = () =>
    setIsLeaveCollaborationModalOpen(false)
  const enableLeaveCollaborationModal = () =>
    setIsLeaveCollaborationModalOpen(true)

  const triggerMutate = () => {
    const url = `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS, {
      id: projectId,
    })}${query}`
    mutate(url)
  }

  const isOwner = () => accessRoles?.includes(ACCESS_ROLES.OWNER)

  const leaveCollaboration = async () => {
    try {
      await actions.deleteCollaborator({
        structureId: projectId,
        collaboratorId: collaborator.id,
      })

      triggerMutate()
      closeLeaveCollaborationModal()
      navigate(NAVIGATION_PATHS.VAULT_PROFILES)
    } catch (err) {
      setError(err.response?.data?.message)
    }
  }

  return (
    <>
      <TableContainer>
        {isOwner() && (
          <TopButtonBar
            project={projectId}
            buttonText={`+ ${t('addCollaborator')}`}
            {...{ setIsAddCollabModalOpen, topBarTitle }}
          />
        )}
        <Table className={classes.table} aria-label="simple table">
          <TableHeadRow />
          <TableBody>
            {!isLoading &&
              collaborators?.data?.map((row, index) => (
                <MainTableRow
                  key={row.id}
                  {...{
                    index,
                    row,
                    triggerMutate,
                    projectId,
                    setCollaborator,
                    enableLeaveCollaborationModal,
                    isOwner: isOwner(),
                  }}
                />
              ))}
          </TableBody>
          <BaseLoader
            isLoading={isLoading}
            text={t('collaboratorsLoading')}
            isCentered
            isAbsolute
          />
        </Table>
        <Pagination
          count={collaborators?.count}
          page={pageIndex}
          rowsPerPage={rowsPerPage}
          onChangePage={nextPage => {
            setPageIndex(nextPage)
          }}
          onChangeRowsPerPage={nextRowsPerPage => {
            setRowsPerPage(nextRowsPerPage)
            setPageIndex(0)
          }}
        />
        <AddCollaboratorModal
          open={isAddCollabModalOpen}
          handleClose={handleCloseAddCollabModal}
          structureId={projectId}
          triggerMutate={triggerMutate}
          structureCategory={structure?.category}
        />
        <DeleteModal
          handleClose={closeLeaveCollaborationModal}
          open={isLeaveCollaborationModalOpen}
          handleDelete={leaveCollaboration}
          firstSpan={t('areYouSureYouWantToLeave')}
          secondSpan={t('thisActionIsPermanent')}
          thirdSpan={t('pleaseConfirmThatYouWantToLeave')}
          deleteBtnText={t('yesPermanentlyLeave')}
        />
      </TableContainer>
      <CommonLoadingOverlay
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    </>
  )
}

CollaboratorsTable.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  projectId: string.isRequired,
  topBarTitle: string,
}

CollaboratorsTable.defaultProps = {
  topBarTitle: '',
}
