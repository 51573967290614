import React from 'react'
import { node, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  row: {
    minHeight: '60px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '0.5rem 1.5rem 1rem 1.5rem',
  },
  odd: {
    background: theme.palette.color.darkGrey0B,
  },
}))

export const Row = ({ children, isOdd }) => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.row} ${cn({
        [classes.odd]: isOdd,
      })}`}
    >
      {children}
    </div>
  )
}

Row.propTypes = {
  isOdd: bool.isRequired,
  children: node.isRequired,
}
