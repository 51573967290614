import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { VideoBackground } from '#pages/_components/video-background'

const SLogo = styled.img`
  height: 3.125rem;
  margin-bottom: 2rem;

  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    height: 2.5rem;
  }
`

const STitle = styled.div`
  text-align: center;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: 2.5rem;
  margin-left: 1rem;
  margin-bottom: 2rem;

  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    font-size: 2rem;
    padding: 0 0.5rem;
  }
`

const SBold = styled.div`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  line-height: 100%;
`

const SButtonsWrapper = styled.div`
  margin: 0 auto;
  display: ${({ isInLimitedMaintenance }) =>
    isInLimitedMaintenance ? 'none' : 'flex'};
  gap: 1.5rem;

  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    flex-direction: column;
    align-items: center;
  }
`

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SButtonTwo = styled(Button)`
  text-transform: uppercase;
`

export const Background = () => {
  const { t } = useTranslation('home')
  const isInLimitedMaintenance = window.LIMITED_MAINTENANCE

  const marketplaceRedirect = () =>
    navigate(process.env.REACT_APP_MARKETPLACE_URL)
  const creatorsRedirect = () => navigate(process.env.REACT_APP_CREATORS_URL)

  return (
    <VideoBackground src="assets/mp4/home-bg.mp4" opacity={0.4}>
      <SLogo src="assets/svg/ic-tunego-logo.svg" alt="TuneGO Logo" />
      <STitle>
        <SBold>{t('poweringTheFutureOfMusic')}</SBold>
      </STitle>
      <SButtonsWrapper isLimitedMaintenance={isInLimitedMaintenance}>
        <SButton onClick={creatorsRedirect}>{t('creatorsStartHere')}</SButton>
        <SButtonTwo onClick={marketplaceRedirect}>
          {t('collectorsStartHere')}
        </SButtonTwo>
      </SButtonsWrapper>
    </VideoBackground>
  )
}
