import React from 'react'
import styled, { css } from 'styled-components'
import {
  bool,
  func,
  string,
  oneOfType,
  number,
  elementType,
  shape,
  node,
} from 'prop-types'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Label } from '#components/label'
import { ErrorMessage } from '#components/error-message'

const SBaseInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ customMarginBottom }) =>
    customMarginBottom ? `${customMarginBottom}px` : '8px'};
  width: ${({ customWidth }) => (customWidth ? `${customWidth}px` : '294px')};
`

const SBaseInputContainer = styled.div`
  display: flex;
  height: ${({ customHeight }) =>
    customHeight ? `${customHeight}px` : '40px'};
  border: ${({
    theme: {
      tunego: { COLORS },
    },
    errorMessage,
  }) => `1px solid ${errorMessage ? COLORS.warning_60 : COLORS.neutral_70}`};
`

const SBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  background-color: ${({ theme: { colors } }) => colors.darkGrey33};
`

export const inputMixin = css`
  text-transform: ${({ displayUppercase }) =>
    displayUppercase ? 'uppercase' : 'none'};
  width: 100%;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  border: none;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    customBg,
  }) => customBg || COLORS.neutral_90};
  padding-left: 15px;
  color: ${({ isReadOnly }) => `rgba(255, 255, 255, ${isReadOnly ? 0.5 : 1})`};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  outline: none;
  border-radius: ${({ isRounded }) => (isRounded ? '2px' : 'unset')};
  caret-color: ${({ noCaret }) => (noCaret ? 'transparent' : 'initial')};
  cursor: ${({ noCaret }) => (noCaret ? 'pointer' : 'initial')};
  text-overflow: ellipsis;

  &:-webkit-autofill {
    transition-delay: 9999s;
    transition-property: background-color, color;
  }

  &[type='number'] {
    -moz-appearance: textfield;
    text-align: left;
    padding-right: 15px;
  }

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1a1a inset !important;
  }
`

const SInput = styled.input`
  ${inputMixin}
`

const SInputMask = styled(InputMask)`
  ${inputMixin}
`

export const BaseInput = props => {
  const {
    label,
    name,
    className,
    placeholder,
    inputRef,
    errorMessage,
    customWidth,
    customHeight,
    customBg,
    customMarginBottom,
    isRounded,
    isBolded,
    isUpperCased,
    noCaret,
    isReadOnly,
    badgeComponent,
    control,
    mask,
    required,
    type,
    defaultValue,
    displayUppercase,
    hint,
    ...restInputProps
  } = props

  return (
    <SBaseInputWrapper
      className={className}
      {...{ customMarginBottom, customWidth }}
    >
      {label && (
        <Label {...{ name, label, isBolded, isUpperCased, required, hint }} />
      )}
      <SBaseInputContainer {...{ customHeight, errorMessage }}>
        {!!badgeComponent && <SBadge>{badgeComponent}</SBadge>}
        {mask ? (
          <Controller
            {...{ control, name, defaultValue }}
            render={({ field }) => <SInputMask {...field} mask={mask} />}
          />
        ) : (
          <SInput
            id={name}
            {...{
              type,
              name,
              placeholder,
              defaultValue,
              displayUppercase,
              customBg,
              isRounded,
              isReadOnly,
              noCaret,
            }}
            {...inputRef(name)}
            {...restInputProps}
          />
        )}
      </SBaseInputContainer>
      <ErrorMessage {...{ errorMessage }} />
    </SBaseInputWrapper>
  )
}

BaseInput.propTypes = {
  name: string.isRequired,
  label: oneOfType([string, node]),
  placeholder: string,
  errorMessage: string,
  customWidth: oneOfType([string, number]),
  customHeight: number,
  customBg: string,
  customMarginBottom: number,
  badgeComponent: node,
  isRounded: bool,
  inputRef: oneOfType([func, shape({ current: elementType })]),
  isBolded: bool,
  isUpperCased: bool,
  noCaret: bool,
  isReadOnly: bool,
  control: shape({}),
  mask: string,
  displayUppercase: bool,
  required: bool,
  type: string,
  defaultValue: oneOfType([string, number]),
  hint: string,
}

BaseInput.defaultProps = {
  label: '',
  placeholder: '',
  errorMessage: '',
  badgeComponent: null,
  customWidth: '',
  customHeight: null,
  customBg: '',
  customMarginBottom: 8,
  isRounded: false,
  inputRef: null,
  isBolded: false,
  isUpperCased: false,
  noCaret: false,
  isReadOnly: false,
  mask: '',
  displayUppercase: false,
  required: false,
  type: 'text',
  defaultValue: '',
}
