import { createContext, useContext } from 'react'

export const SongContext = createContext(null)

export const useSong = () => {
  const context = useContext(SongContext)
  if (!context) {
    throw new Error('useSong must be used within SongProvider')
  }
  return context
}
