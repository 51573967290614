import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import styled, { keyframes } from 'styled-components'
import { PageWrapper } from '#modules/page-wrapper'

import mobile from 'is-mobile'

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const SLoadingIndicator = styled.img`
  animation-name: ${rotateAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 60,
    textAlign: 'center',
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily,
    margin: 0,
    width: '100%',
    textAlign: 'center',
  },
  mobileVerified: {
    width: '100%',
    textAlign: 'center',
    padding: '0 8px',
  },
  appStoreButton: {
    width: 150,
    height: 50,
    margin: '0 8px',
  },
  appButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 80,
  },
  waitingSpinner: {
    width: 100,
    height: 100,
    margin: '0 auto',
    alignSelf: 'center',
  },
}))

export const LoginRedirectPage = () => {
  const classes = useStyles()
  const { t } = useTranslation('onboarding')
  const isMobile = mobile({ tablet: true })

  // FIXME - Leaving this page for now, even though its being completely hijacked
  // so that typeform business side flow doesn't have to change.  The original
  // intent was to direct to vault signup.
  useEffect(() => {
    window.location.href =
      process.env.REACT_APP_CREATOR_ONBOARDING_URL ||
      'https://creator-onboarding.tunego.com'
  }, [])
  return (
    <PageWrapper showGenericHeader>
      <div className={classes.wrapper}>
        {isMobile ? (
          <div>
            <p className={classes.title}>
              {t('Get Started on a Desktop Computer')}
            </p>
            <p className={classes.mobileVerified}>
              {t(
                'Please use a desktop computer to complete the verification process.'
              )}
            </p>
          </div>
        ) : (
          <div className={classes.waitingSpinner}>
            <SLoadingIndicator
              src="/assets/svg/ic-loading.svg"
              alt="Loading icon"
            />
          </div>
        )}
      </div>
    </PageWrapper>
  )
}
