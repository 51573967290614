import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.color.minorGrey,
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },
  albumColumn: {
    flex: 2,
    fontFamily: theme.typography.h2.fontFamily,
    paddingLeft: '1rem',
  },
  lastUpdateColumn: {
    marginLeft: '-1rem',
    flex: 1,
    fontFamily: theme.typography.h2.fontFamily,
  },
}))

export const TableHeader = () => {
  const { t } = useTranslation('vault')
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.albumColumn}>{t('albumSong')}</div>
      <div className={classes.lastUpdateColumn}>{t('lastUpdated')}</div>
    </div>
  )
}
