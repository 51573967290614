import React from 'react'
import { ImageWithBullets } from './components/image-with-bullets'
import { ImageWithDescription } from './components/image-with-description'
import {
  FINGERPRINT,
  METADATA,
  NEXT_LEVEL,
} from '#pages/vault/landing-page/utils/constants'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    maxWidth: '1400px',
    margin: 'auto',
  },
}))

export const MainContentSection = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <ImageWithDescription item={FINGERPRINT} />
      <ImageWithBullets item={NEXT_LEVEL} />
      <ImageWithDescription item={METADATA} />
    </div>
  )
}
