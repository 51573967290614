import React from 'react'
import styled from 'styled-components'
import { func, objectOf, string } from 'prop-types'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from '#components/inputs/custom-select'
import { BaseInput } from '#components/inputs/base-input'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SORTED_GENRES } from '#pages/promotion/wizard/utils/constants'

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

export const ChooseGenre = ({ register, control, errors }) => {
  const { t } = useTranslation('promotion')
  const { storedFormState } = useWizard()

  return (
    <>
      <SBaseInput
        label={t('songName')}
        name="trackName"
        inputRef={register}
        errorMessage={errors.trackName?.message}
        defaultValue={storedFormState.trackName}
      />
      <Controller
        key="genre"
        name="genre"
        control={control}
        defaultValue={storedFormState?.genre}
        render={({ field: { onChange, value } }) => (
          <CustomSelect
            options={SORTED_GENRES}
            placeholder={`${t('selectGenre')}...`}
            label={t('genre')}
            value={value}
            errorMessage={errors.genre?.message || errors.genre?.value?.message}
            onChange={onChange}
          />
        )}
      />
    </>
  )
}

ChooseGenre.propTypes = {
  register: func.isRequired,
  control: func.isRequired,
  errors: objectOf(string),
}

ChooseGenre.defaultProps = {
  errors: {},
}
