import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { capitalize, makeStyles } from '@material-ui/core'
import { bool, func, shape, string } from 'prop-types'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { formatCardBrand, formatCardNumber } from '#pages/profile/utils/helpers'

const useStyles = (isDefault, bgColor) =>
  makeStyles(theme => ({
    methodItem: {
      padding: '1.875rem',
      backgroundColor: isDefault
        ? theme.palette.color.primary60
        : theme.palette[bgColor],
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  }))()

const SButton = styled(Button)`
  margin-bottom: 10px;
  margin-right: unset;
  width: 250px;
  text-transform: uppercase;
`

export const PaymentMethodItem = ({
  methodData,
  isDefault,
  isEven,
  setAsDefault,
  deleteCard,
}) => {
  const { t } = useTranslation('profile')
  const { brand, billingName, last4Digits, expirationYear, expirationMonth } =
    methodData

  const expDate = `${expirationMonth}/${String(expirationYear).slice(2)}`
  const bgColor = isEven ? 'color.darkGrey0B' : 'color.black'
  const cardBrand = formatCardBrand(brand)
  const classes = useStyles(isDefault, bgColor)

  return (
    <div className={classes.methodItem}>
      <div>
        <p>{capitalize(cardBrand)}</p>
        <p>{formatCardNumber(last4Digits)}</p>
        <p>{billingName}</p>
        <p>{expDate}</p>
      </div>
      <div>
        {!isDefault && (
          <SButton variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={setAsDefault}>
            {t('setAsDefault')}
          </SButton>
        )}
        {deleteCard && (
          <SButton variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={deleteCard}>
            {t('deleteThisCard')}
          </SButton>
        )}
      </div>
    </div>
  )
}

PaymentMethodItem.propTypes = {
  methodData: shape({
    brand: string.isRequired,
    funding: string.isRequired,
    billingName: string.isRequired,
    last4Digits: string.isRequired,
    expDate: string.isRequired,
  }).isRequired,
  setAsDefault: func.isRequired,
  deleteCard: func,
  isDefault: bool,
  isEven: bool,
}

PaymentMethodItem.defaultProps = {
  isDefault: false,
  isEven: false,
  deleteCard: null,
}
