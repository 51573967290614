import React from 'react'
import styled from 'styled-components'
import { func, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { PRO_OPTIONS } from '#constants/proOptions'
import { CustomSelect } from '#components/inputs/custom-select'
import { BaseInput } from '#components/inputs/base-input'
import { FORM_FIELDS } from '#pages/vault/_modules/add-collaborator-modal/constants'

const SCustomSelect = styled(CustomSelect)`
  display: inline-flex;
  width: 48%;
  margin-right: 4%;
  flex-direction: column;
  margin-top: 0.5rem;
`

const SNarrowInput = styled(BaseInput)`
  display: inline-flex;
  flex-direction: column;
  width: 48%;
`

const SBaseInput = styled(BaseInput)`
  margin-top: 0.625rem;
  width: unset;
`

export const ContributorForm = ({ control, register, errors }) => {
  const { t } = useTranslation('vault')

  return (
    <div>
      <Controller
        name={FORM_FIELDS.PRO_ATTRIBUTE}
        control={control}
        render={({ field: { onChange } }) => (
          <SCustomSelect
            id="pro_attribute"
            options={PRO_OPTIONS}
            label={t(`pro`)}
            errorMessage={errors[FORM_FIELDS.PRO_ATTRIBUTE]?.message}
            isBolded
            isUpperCased
            onChange={({ value }) => {
              onChange(value)
            }}
          />
        )}
      />
      <SNarrowInput
        key={FORM_FIELDS.IPI_CAE_NUMBER}
        name={FORM_FIELDS.IPI_CAE_NUMBER}
        inputRef={register}
        label={t(`ipiCae`)}
        errorMessage={errors[FORM_FIELDS.IPI_CAE_NUMBER]?.message}
        isBolded
        isUpperCased
      />
      <SBaseInput
        name={FORM_FIELDS.PUBLISHER}
        inputRef={register}
        label={t(`publisher`)}
        errorMessage={errors[FORM_FIELDS.PUBLISHER]?.message}
        isBolded
      />
      <SBaseInput
        name={FORM_FIELDS.ISNI}
        inputRef={register}
        label={t(`isni`)}
        errorMessage={errors[FORM_FIELDS.ISNI]?.message}
        isBolded
        isUpperCased
      />
    </div>
  )
}

ContributorForm.propTypes = {
  control: shape({}).isRequired,
  register: func.isRequired,
  errors: shape({}),
}
