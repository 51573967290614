import { hexToRgba } from '#utils/hexToRgba'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'

const flowIcon = {
  backgroundImage: `url("/assets/svg/ic-flow.svg")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  marginTop: 2,
  content: '" "',
  position: 'absolute',
  height: 20,
  width: 20,
  top: 12,
  right: 10,
}

export const selectCustomStyles = (theme, { errorMessage, hasNftStyles }) => ({
  container: provided => ({
    ...provided,
    width: '100%',
    background: theme.colors.darkGrey1A,
    fontSize: hasNftStyles ? '16px' : '14px',
  }),

  control: (_, state) => {
    const border = hasNftStyles
      ? `1px solid ${theme.colors.darkGrey33}`
      : `1px solid ${theme.colors.darkGrey66}`

    return {
      height: hasNftStyles ? 50 : 40,
      display: 'flex',
      border: errorMessage ? `1px solid ${theme.colors.error}` : border,
      outline: 'none',
      boxShadow: 'none',
      ':before': {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        content: '" "',
        display:
          (!state.hasValue && state.menuIsOpen) ||
          state.isFocused ||
          state.hasValue
            ? 'none'
            : 'block',
        height: 15,
        width: 15,
        position: 'absolute',
      },
      '&:hover': {
        border: `1px solid ${theme.colors.darkGreyB3}`,
      },
      ':after': hasNftStyles && state.hasValue ? flowIcon : {},
    }
  },
  menu: () => ({
    background: theme.colors.darkGrey1A,
    border: hasNftStyles
      ? `1px solid ${theme.colors.darkGrey33}`
      : `1px solid ${theme.colors.darkGrey66}`,
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  }),
  menuList: () => ({
    padding: 0,
    maxHeight: hasNftStyles ? 150 : 180,
    overflow: 'auto',
  }),
  input: () => ({
    color: theme.colors.default,
  }),
  placeholder: () => ({
    opacity: 0.5,
    paddingLeft: 8,
  }),
  singleValue: () => ({
    color: theme.colors.default,
    paddingLeft: 8,
  }),
  option: (provided, state) => ({
    ...provided,
    color: hasNftStyles ? theme.colors.primary : theme.colors.default,
    background: state.isFocused
      ? theme.colors.darkGrey66
      : theme.colors.darkGrey1A,
    borderBottom: hasNftStyles
      ? `1px solid ${theme.colors.darkGrey33}`
      : `1px solid ${theme.colors.darkGrey66}`,
    height: hasNftStyles ? 50 : 40,
    lineHeight: hasNftStyles ? '30px' : '22px',
    position: 'relative',
    ':before': hasNftStyles ? flowIcon : {},
  }),
  dropdownIndicator: (_, state) => ({
    display: 'inline-block',
    color: theme.colors.default,
    marginRight: 10,
    '& svg': {
      transform: `rotateX(${
        state?.selectProps?.menuIsOpen ? '180deg' : '0deg'
      })`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

const imageStyles = {
  height: 25,
  width: 25,
  top: 7,
  left: 9,
}

export const selectCustomStylesProfiles = (theme, { errorMessage }) => ({
  container: provided => ({
    ...provided,
    width: '100%',
    background: theme.colors.darkGrey1A,
    fontSize: 14,
  }),
  control: (_, state) => {
    const avatarUrl = state?.selectProps?.value?.avatarUrl
    const inputValue = state?.selectProps?.inputValue
    const imageUrl =
      avatarUrl ||
      (!avatarUrl && state?.selectProps?.value?.value !== ''
        ? DEFAULT_AVATAR
        : null)
    const url = state.hasValue && imageUrl
    return {
      height: 40,
      display: 'flex',
      paddingLeft: url ? 30 : 0,
      border: errorMessage
        ? `1px solid ${theme.colors.error}`
        : `1px solid ${theme.colors.darkGrey66}`,
      outline: 'none',
      boxShadow: 'none',
      ':before': {
        backgroundImage: `url("${url}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: inputValue ? 'none' : 'block',
        position: 'absolute',
        ...imageStyles,
      },
      '&:hover': {
        border: `1px solid ${theme.colors.darkGreyB3}`,
      },
    }
  },
  clearIndicator: () => ({
    display: 'none',
  }),
  menu: () => ({
    background: theme.colors.darkGrey1A,
    border: `1px solid ${theme.colors.darkGrey66}`,
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  }),
  menuList: () => ({
    padding: 0,
    maxHeight: 220,
    overflow: 'auto',
  }),
  input: () => ({
    color: theme.colors.default,
    paddingLeft: 0,
    caretColor: 'transparent',
  }),
  placeholder: () => ({
    opacity: 0.5,
    position: 'absolute',
    left: 35,
  }),
  singleValue: () => ({
    color: theme.colors.default,
    paddingLeft: 8,
  }),
  option: (provided, state) => {
    const { avatarUrl, value } = state.data
    const imageUrl =
      avatarUrl || (!avatarUrl && value !== '' ? DEFAULT_AVATAR : null)
    return {
      ...provided,
      background: state.isFocused
        ? theme.colors.darkGrey66
        : theme.colors.darkGrey1A,
      borderBottom: `1px solid ${theme.colors.darkGrey66}`,
      position: 'relative',
      paddingLeft: 35,
      ':before': {
        backgroundImage: `url("${imageUrl}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        marginTop: 2,
        content: '" "',
        position: 'absolute',
        height: 20,
        width: 20,
        top: 5,
        left: 8,
      },
    }
  },
  dropdownIndicator: (_, state) => ({
    color: theme.colors.default,
    marginRight: 10,
    '& svg': {
      transform: `rotateX(${
        state?.selectProps?.menuIsOpen ? '180deg' : '0deg'
      })`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

export const customMultiselectStyles = (theme, { errorMessage }) => ({
  control: styles => ({
    ...styles,
    backgroundColor: theme.colors.darkGrey1A,
    border: errorMessage
      ? `1px solid ${theme.colors.error}`
      : `1px solid ${theme.colors.darkGrey66}`,
    minHeight: 40,
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${theme.colors.darkGreyB3}`,
    },
  }),
  menuList: styles => {
    return {
      ...styles,
      backgroundColor: theme.colors.darkGrey1A,
    }
  },
  option: styles => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: theme.colors.darkGrey66,
      },
    }
  },
  input: () => ({
    color: theme.colors.default,
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: hexToRgba(theme.colors.default, 0.0),
      div: {
        color: theme.colors.white,
      },
      'div:nth-of-type(2)': {
        cursor: 'pointer',
        '&:hover': {
          color: theme.colors.white,
          backgroundColor: theme.colors.primary,
        },
      },
    }
  },
})
