import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { string } from 'prop-types'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => ({
  errorWrapper: {
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '37.5rem',
    margin: '0 auto',
  },
  message: {
    textAlign: 'center',
  },
}))

const SButton = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.default};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.primary};
  min-width: 120px;
  height: 40px;
  border-radius: 10px;
  margin-top: 60px;
  text-transform: uppercase;
`

export const ErrorMessage = ({ message }) => {
  const { t } = useTranslation('mastering')
  const classes = useStyles()

  return (
    <div className={classes.errorWrapper}>
      <span className={classes.textAlign}>{message}</span>
      <SButton onClick={() => navigate(NAVIGATION_PATHS.VIEW_MASTERS)}>
        {t('viewMasters')}
      </SButton>
    </div>
  )
}

ErrorMessage.propTypes = {
  message: string.isRequired,
}
