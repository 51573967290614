export const DEFAULT_FORM_STATE = {
  storedFormState: {
    artistId: {
      value: [],
      option: 1,
    },
    distroId: '',
    parentId: '',
    songFiles: [],
    releaseName: '',
    genre: {},
    explicitContent: {},
    releaseCopyrightOwner: '',
    artWorkCopyrightOwner: '',
    releasedBefore: null,
    releaseCopyrightYear: {},
    artWorkCopyrightYear: {},
    estimatedReleaseDate: null,
    musicStores: [],
    deliveryRegions: [],
  },
  step: 1,
}
