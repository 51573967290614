import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { Tooltip } from '@material-ui/core'
import { number, string, shape } from 'prop-types'
import { formatDate } from '#utils/formatDate'
import { buildUrl } from '#utils/buildUrl'
import { NAVIGATION_PATHS } from '#routes/routes'
import { UserImage } from '#components/user-image'
import { DistroStatusButton } from '../status-button'
import { DISTRO_STATUS } from '#pages/distro/_utils/constants'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.color.black,
    height: 60,
    fontWeight: 500,
    lineHeight: 12,
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'none',
    cursor: 'pointer',
    fontSize: '1rem',

    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.color.darkGrey0B,
    },
  },
  date: {
    textAlign: 'left',
    width: 110,
    marginRight: 60,
    marginLeft: 28,
  },
  artist: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 240,
    height: 60,
    marginRight: 60,
  },
  release: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 240,
    height: 60,
    marginRight: 60,
  },
  tracks: {
    width: 60,
    height: 60,
    lineHeight: '60px',
    marginRight: 60,
    textAlign: 'center',
  },
  status: {
    width: 100,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrappedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const SUserImage = styled(UserImage)`
  margin-right: 20px;
`

export const ListItem = ({
  id,
  deliveryDate,
  releaseName,
  songCount,
  status,
  profile,
  artworkUrl,
}) => {
  const classes = useStyles()

  const handleRowClick = () => {
    switch (status) {
      case DISTRO_STATUS.PENDING:
      case DISTRO_STATUS.COMPLETE:
        navigate(buildUrl(NAVIGATION_PATHS.DISTRO_PAST_RELEASE_DETAILS, { id }))
        break
      case DISTRO_STATUS.IN_PROGRESS:
      case DISTRO_STATUS.UNPAID:
      case DISTRO_STATUS.DENIED:
        navigate(`${NAVIGATION_PATHS.DISTRO_CREATE_NEW}?distroId=${id}`)
        break
      default:
        break
    }
  }

  return (
    <div className={classes.wrapper} onClick={handleRowClick}>
      <span className={classes.date}>
        {deliveryDate && formatDate(deliveryDate)}
      </span>
      <div className={classes.artist}>
        <SUserImage userImageUrl={profile.avatarUrl} size={40} />
        <Tooltip title={profile.name}>
          <span className={classes.wrappedText}>{profile.name}</span>
        </Tooltip>
      </div>
      <div className={classes.release}>
        {artworkUrl && <SUserImage userImageUrl={artworkUrl} size={40} />}
        <Tooltip title={releaseName}>
          <span className={classes.wrappedText}>{releaseName}</span>
        </Tooltip>
      </div>
      <span className={classes.tracks}>{songCount}</span>
      <div className={classes.status}>
        <DistroStatusButton status={status} />
      </div>
    </div>
  )
}

ListItem.defaultProps = {
  deliveryDate: '',
  releaseName: '',
  songCount: null,
  artworkUrl: '',
}

ListItem.propTypes = {
  deliveryDate: string,
  profile: shape({
    name: string,
    avatarUrl: string,
  }).isRequired,
  id: string.isRequired,
  releaseName: string,
  songCount: number,
  status: string.isRequired,
  artworkUrl: string,
}
