import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'
import { S3Bucket } from '#api/requests/s3bucket'
import { getFileInfo } from '#utils/getFileInfo'

export class NftApi {
  static setUp = body => getApiClient().post(ENDPOINTS.NFT_SETUP, body)

  static addNftDetails = (nftId, body) =>
    getApiClient().patch(buildUrl(ENDPOINTS.NFT_DETAILS, { nftId }), body)

  static addFile = (nftId, fileId) =>
    getApiClient().post(buildUrl(ENDPOINTS.NFT_FILE, { nftId, fileId }))

  static addSplit = (nftId, body) =>
    getApiClient().post(buildUrl(ENDPOINTS.NFT_SPLIT, { nftId }), body)

  static addSplitOffPlatform = (nftId, body) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.NFT_SPLIT_OFF_PLATFORM, { nftId }),
      body
    )

  static updateSplits = (nftId, body) =>
    getApiClient().patch(buildUrl(ENDPOINTS.NFT_SPLITS_UPDATE, { nftId }), body)

  static deleteItem = (itemId, itemType) =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.NFT_DELETE_ITEM, { itemId, itemType })
    )

  static nftComplete = nftId =>
    getApiClient().patch(buildUrl(ENDPOINTS.NFT_COMPLETE, { nftId }), {})

  static assignWallet = (nftId, body) =>
    getApiClient().post(buildUrl(ENDPOINTS.NFT_WALLET, { nftId }), body)

  static mintNft = nftId =>
    getApiClient().patch(buildUrl(ENDPOINTS.NFT_MINT, { nftId }), {})

  static deleteNft = nftId =>
    getApiClient().delete(buildUrl(ENDPOINTS.NFT_NFT, { nftId }))

  static nftSplitAction = (splitId, action) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.NFT_SPLIT_ACTION, { splitId, action }),
      {}
    )

  static reinviteSplit = (splitId, body) => {
    getApiClient().post(
      buildUrl(ENDPOINTS.NFT_SPLIT_REINVITE, { splitId }),
      body
    )
  }

  static nftEditSplitRoles = (splitId, roles) =>
    getApiClient().patch(
      buildUrl(ENDPOINTS.NFT_EDIT_SPLIT_ROLES, { splitId }),
      { roles }
    )

  static nftEditOther = (otherId, data) =>
    getApiClient().patch(buildUrl(ENDPOINTS.NFT_EDIT_OTHER, { otherId }), {
      ...data,
    })

  static getItemLink = (itemId, itemType, save) => {
    const savePart = save ? '?save=true' : ''
    return getApiClient().get(
      buildUrl(ENDPOINTS.NFT_ITEM_LINK, { itemType, itemId }) + savePart
    )
  }

  static postImageUploadLink = (nftId, body) =>
    getApiClient().post(buildUrl(ENDPOINTS.NFT_IMAGE, { nftId }), body)

  static uploadImageHealthCheck = nftId =>
    getApiClient().get(buildUrl(ENDPOINTS.NFT_IMAGE_HEALTH_CHECK, { nftId }))

  static addImageFile = async (nftId, file) => {
    const { fileSuffix } = getFileInfo(file)
    const {
      data: { url, fields },
    } = await this.postImageUploadLink(nftId, {
      fileSuffix,
    })
    await S3Bucket.uploadImg({
      url,
      file,
      fields,
      withoutContentType: true,
    })
    await this.uploadImageHealthCheck(nftId)
  }

  static addImageFileFromVault = (nftId, fileId) =>
    getApiClient().post(buildUrl(ENDPOINTS.NFT_IMAGE_VAULT, { nftId }), {
      fileId,
    })
}
