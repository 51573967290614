import * as yup from 'yup'
import i18next from 'i18next'
import { FORM_FIELDS } from '#pages/nft/_utils/constants'

export const selectProfileValidationSchema = t =>
  yup.object().shape({
    [FORM_FIELDS.PROFILE]: yup
      .object()
      .nullable()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('userProfileName'),
        })
      ),
  })
