import React from 'react'
import { string, arrayOf, shape, bool } from 'prop-types'
import cn from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { hexToRgba } from '#utils/hexToRgba'
import { isEven } from '#utils/isEven'

const useStyles = showHorizontally =>
  makeStyles(theme => ({
    row: {
      height: 60,
      lineHeight: '60px',
      padding: '0 24px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
      borderTop: '0',
      borderBottom: '0',
    },
    rowTitle: {
      maxWidth: 175,
    },
    rowValue: {
      maxWidth: showHorizontally ? 975 : 475,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
    },
    even: {
      background: theme.palette.color.darkGrey0B,
    },
    fontSize14: {
      fontSize: 14,
    },
    fontWeightBold: {
      fontWeight: 'bold',
    },
  }))()

export const Order = ({ orderData, showHorizontally }) => {
  const classes = useStyles(showHorizontally)

  return (
    <>
      {orderData?.map(({ rowTitle, rowValue }, index) => (
        <div
          key={rowTitle}
          className={cn(classes.row, {
            [classes.even]: isEven(index),
          })}
        >
          <div
            className={cn(classes.fontSize14, classes.rowTitle)}
            style={{
              marginRight: rowTitle ? '8px' : 0,
            }}
          >{`${rowTitle}${rowTitle ? ':' : ''}`}</div>
          <div
            className={cn(
              classes.rowValue,
              classes.fontSize14,
              classes.fontWeightBold
            )}
          >
            {rowValue}
          </div>
        </div>
      ))}
    </>
  )
}

Order.propTypes = {
  orderData: arrayOf(shape({ rowTitle: string, rowValue: string })),
  showHorizontally: bool,
}

Order.defaultProps = {
  orderData: [],
  showHorizontally: false,
}
