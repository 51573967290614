import React, { useState } from 'react'
import { useParams } from '@reach/router'
import { useFingerPrints } from '#hooks/swr/useFingerPrints'
import { FingerPrintsList } from '#pages/vault/song/tabs/finger-print/components/finger-prints-list'

export const FingerPrint = () => {
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { projectId } = useParams()
  const { fingerprints, isLoading } = useFingerPrints(
    projectId,
    `?page=${pageIndex}&limit=${rowsPerPage}`
  )
  return (
    <FingerPrintsList
      fingerprints={fingerprints?.data}
      count={fingerprints?.count}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      pageIndex={pageIndex}
      setPageIndex={setPageIndex}
      isLoading={isLoading}
    />
  )
}
