import { useLocation } from '@reach/router'

export const useSearchParam = (queryParam, locationSearch) => {
  const search = new URLSearchParams(locationSearch)
  return search.get(queryParam)
}

export const useQuery = queryParam => {
  return useSearchParam(queryParam, useLocation().search)
}
