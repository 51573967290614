import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { FIELDS, FILE_STATUS } from '#pages/distro/_utils/constants'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SongFilesList } from '#pages/distro/wizard/steps/add-song-files/components/song-files-list'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { FilesApi } from '#api/requests/files'
import { FilesListItemInProgress } from '#modules/legacy-upload-manager/file-list-item-in-progress'
import { SongFileUploader } from '#pages/distro/wizard/steps/add-song-files/components/song-file-uploader'
import { FileTreeUploader } from '#pages/distro/wizard/steps/add-song-files/components/file-tree-uploader'
import { validateFiles } from '#pages/distro/_utils/validation'
import { hexToRgba } from '#utils/hexToRgba'
import { checkIfIsReadyForDistribution } from '#pages/distro/_utils/helpers'
import { socketInstance } from '#api/socketio'
import { EVENTS } from '#api/event'
import { PRICE_TYPE } from '#constants/priceTypes'
import { PriceChangeWarningModal } from '#pages/distro/wizard/steps/add-song-files/components/price-change-warning-modal'
import { FILE_PREVIEW_STATUS } from '#pages/vault/song/tabs/files/utils/constants'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: 'calc(100vh - 160px)',
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 100,
    borderLeft: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    borderRight: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0px 60px',
    marginTop: 60,
  },
  filesTreeWrapper: {
    width: 480,
    border: `1px solid ${theme.palette.color.primary}`,
  },
  uploadingFilesWrapper: {
    width: '100%',
  },
}))

export const AddSongFiles = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const { user } = useUser()
  const { state, setStoredFormState, setDistroSongFiles, incrementStep } =
    useWizard()
  const { storedFormState } = state
  const { error, setResponseError, setError, clearError } = useError()
  const [filesUploadInProgress, setFilesUploadInProgress] = useState([])
  const selectedSongs = storedFormState[FIELDS.SONG_FILES]

  const { actions, loading } = useWithAsyncAction({
    addSong: DistroApi.addSong,
    deleteSong: DistroApi.deleteSong,
    getFile: FilesApi.getFile,
  })

  const [isPriceChangeWarningModalOpen, setIsPriceChangeWarningModalOpen] =
    useState(false)

  const isNextStepButtonDisabled =
    !selectedSongs.length || !selectedSongs.every(checkIfIsReadyForDistribution)

  const enablePriceChangeWarningModal = () =>
    setIsPriceChangeWarningModalOpen(true)
  const disablePriceChangeWarningModal = () =>
    setIsPriceChangeWarningModalOpen(false)

  const selectedFilesIds = selectedSongs.map(file => file.fileId)

  const setSongFiles = values => {
    const valuesToSet = values.map((value, index) => ({
      ...value,
      order: index,
    }))

    setStoredFormState({ [FIELDS.SONG_FILES]: valuesToSet })
  }

  const findSongIdById = fileId => {
    const song = selectedSongs.find(file => file.fileId === fileId)
    return song?.songId
  }

  const handleFileDelete = async songId => {
    try {
      const songToDelete = selectedSongs.find(file => file.songId === songId)

      await actions.deleteSong(
        storedFormState[FIELDS.DISTRO_ID],
        songToDelete.songId
      )

      const filteredFiles = selectedSongs
        .filter(file => file.songId !== songId)
        .map((file, index) => ({ ...file, order: index }))

      setStoredFormState({ [FIELDS.SONG_FILES]: [...filteredFiles] })
    } catch (err) {
      setResponseError(err)
    }
  }

  const isFileReadyForDistribution = async fileId => {
    try {
      const { data: file } = await actions.getFile(fileId)
      if (file.previewStatus !== FILE_PREVIEW_STATUS.DONE) {
        setError(distroTranslation.t('selectedFileIsNotReady'))
        return false
      }
    } catch (err) {
      setResponseError(err)
      return false
    }

    return true
  }

  const handleFileSelect = async ({ id: fileId }) => {
    if (selectedFilesIds.includes(fileId)) {
      await handleFileDelete(findSongIdById(fileId))
    } else {
      try {
        if (!(await isFileReadyForDistribution(fileId))) return

        const response = await actions.addSong(
          storedFormState[FIELDS.DISTRO_ID],
          { fileId }
        )

        const selectedSong = {
          fileId,
          fileName: response.data.file.name,
          songId: response.data.id,
          isMetadataFormOpened: false,
          order: response.data.order,
          ...response.data.project,
        }

        setDistroSongFiles(selectedSong)
      } catch (err) {
        setResponseError(err)
      }
    }
  }

  const uploadProgress = (progressEvent, file) => {
    const progress = (progressEvent.loaded / progressEvent.total) * 100
    setFilesUploadInProgress(prevFiles => {
      return prevFiles
        .map(prevFile => {
          if (prevFile.name === file.name) {
            return {
              ...prevFile,
              progress,
            }
          }
          return prevFile
        })
        .filter(el => el.progress < 100)
    })
  }

  const waitForFileProcessing = fileId => {
    return new Promise((resolve, reject) => {
      const onFileData = data => {
        if (!data.bitDepth && !data.audioCodecs) {
          return
        }
        // eslint-disable-next-line no-use-before-define
        cleanup()
        resolve(data.isDistributionAllowed)
      }

      const onConnected = () => {
        FilesApi.getFile(fileId).then(onFileData).catch(reject)
      }
      const eventAction = eventData => {
        if (eventData.payload.id === fileId) {
          onFileData(eventData.payload)
        }
      }
      const cleanup = () => {
        socketInstance.off(`event.${EVENTS.FILE_UPDATED}`, eventAction)
        socketInstance.off('connected', onConnected)
      }
      socketInstance.on(`event.${EVENTS.FILE_UPDATED}`, eventAction)
      socketInstance.on('connected', onConnected)
      onConnected()
    })
  }

  const handleFileUpload = async file => {
    try {
      const { name } = file
      setFilesUploadInProgress(prevProjectFiles => [
        ...prevProjectFiles,
        { name, progress: 0 },
      ])
      const response = await FilesApi.manageUploadFile(
        storedFormState[FIELDS.PARENT_ID],
        file,
        uploadProgress,
        true
      )

      setFilesUploadInProgress(prevProjectFiles => [
        ...prevProjectFiles,
        { name, progress: FILE_STATUS.PROCESSING },
      ])

      const res = await waitForFileProcessing(response?.id)

      setFilesUploadInProgress(prevProjectFiles =>
        [...prevProjectFiles].filter(item => item.name !== name)
      )

      if (res) {
        await handleFileSelect(response?.id)
      } else {
        setFilesUploadInProgress(prevProjectFiles => [
          ...prevProjectFiles,
          { name, progress: FILE_STATUS.ERROR },
        ])
      }
    } catch (err) {
      setResponseError(err)
      setFilesUploadInProgress([])
    }
  }

  const handleFileDrop = files => {
    const { validFiles, errors } = validateFiles(files)

    if (errors.length > 0) {
      setError(errors)
    }

    validFiles.forEach(async file => {
      try {
        await handleFileUpload(file)
      } catch (err) {
        setResponseError(err)
      }
    })
  }

  const handleRemoveFileInProgress = fileName => {
    setFilesUploadInProgress(prevProjectFiles =>
      [...prevProjectFiles].filter(item => item.name !== fileName)
    )
  }

  const onStepSubmitHandler = () => {
    const isAlbumPriceQualified =
      selectedSongs.length > 1 &&
      user?.subscription?.subscriptionPlan?.priceMap[PRICE_TYPE.DISTRO_ALBUM]
        .value > 0

    if (isAlbumPriceQualified) {
      enablePriceChangeWarningModal()
      return
    }

    incrementStep()
  }

  return (
    <StepContainer
      shouldDisableNextButton={isNextStepButtonDisabled}
      customSubmitAction={onStepSubmitHandler}
      isDistroStepSubmit
    >
      <div className={classes.wrapper}>
        <div className={classes.columnWrapper}>
          <div className={classes.uploadingFilesWrapper}>
            {filesUploadInProgress.map(({ name, progress }, i) => (
              <FilesListItemInProgress
                key={generateUniqueKey(name, i)}
                name={name}
                progress={progress}
                onDelete={handleRemoveFileInProgress}
              />
            ))}
          </div>
          <SongFilesList
            songFiles={selectedSongs}
            setSongFiles={setSongFiles}
            setError={setResponseError}
            onDeleteSong={handleFileDelete}
          />
          <div className={classes.rowWrapper}>
            <SongFileUploader handleFileDrop={handleFileDrop} />
            <FileTreeUploader
              handleFileSelect={handleFileSelect}
              selectedFilesIds={selectedFilesIds}
              parentId={storedFormState[FIELDS.PARENT_ID]}
            />
          </div>
        </div>
        <CommonLoadingOverlay
          message={t('loading')}
          dialogOpen={loading.addSong}
          error={error}
          onErrorBackdropClick={clearError}
        />
      </div>
      <PriceChangeWarningModal
        isOpen={isPriceChangeWarningModalOpen}
        onClose={disablePriceChangeWarningModal}
      />
    </StepContainer>
  )
}
