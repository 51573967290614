import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PAYMENT_HEADERS } from '#pages/profile/utils/constants'
import { Row } from '#pages/_modules/private-shares/components/row'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerItem: {
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
    fontWeight: 'bold',
  },
}))

export const PaymentHistoryHeader = () => {
  const { t } = useTranslation('profile')
  const classes = useStyles()

  return (
    <Row isLighter>
      <div className={classes.header}>
        {PAYMENT_HEADERS.map(({ name, desktopWidth, headerProps }) => {
          return (
            <div
              key={name}
              className={classes.headerItem}
              style={{ width: desktopWidth }}
              {...headerProps}
            >
              {t(name)}
            </div>
          )
        })}
      </div>
    </Row>
  )
}
