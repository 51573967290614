import { buildUrl } from '#utils/buildUrl'
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'

export class MasteringApi {
  static masterFiles = file => getApiClient().post(ENDPOINTS.MASTERING, file)

  static postMasteringStripeIntent = (id, body) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.MASTERING_STRIPE_INTENT, { id }),
      body
    )

  static deleteMasteredFile = id =>
    getApiClient().delete(buildUrl(ENDPOINTS.MASTERING_GET_FILE, { id }))

  static getMasteredLink = (id, save) => {
    const savePart = save ? '?save=true' : ''
    return getApiClient().get(
      buildUrl(ENDPOINTS.MASTERING_GET_MASTERED_LINK, { id }) + savePart
    )
  }

  static getOriginalListenLink = id => {
    return getApiClient().get(
      buildUrl(ENDPOINTS.MASTERING_GET_ORIGINAL_PREVIEW_LINK, {
        id,
      })
    )
  }
}
