import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { array, oneOfType, object } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'

const sexIcons = {
  male: '/assets/images/male-icon.png',
  female: '/assets/images/female-icon.png',
}

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    paddingBottom: 48,
  },
  commentsWrapper: {
    width: '100%',
    margin: '24px 0',
  },
  singleCommentWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  imageAgeWrapper: {
    paddingRight: 24,
  },
  comment: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  ageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  ratingBox: {
    width: '20%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '60px',
    color: theme.palette.color.white,
    boxShadow: `inset 0 0 45px ${theme.palette.color.primary}`,
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    border: `1px solid ${theme.palette.color.primary}`,
    background: hexToRgba(theme.palette.color.primary, 0.25),
  },
  descriptionWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  descriptionBox: {
    border: `1px solid ${theme.palette.color.primary}`,
    backgroundColor: `${theme.palette.color.primary}`,
    width: '80%',
    height: '100%',
    display: 'flex',
    alignItem: 'center',
    flexWrap: 'wrap',
    padding: 16,
  },
  personLabel: {
    fontSize: '12px',
  },
  ageLabel: {
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
  },
  personImage: {
    marginBottom: '5px',
  },
}))

export const ReviewerComments = ({ data }) => {
  const classes = useStyles()
  const filteredData = Object.keys(data)
    .map((key, index) => ({ ...data[key], index }))
    .filter(obj => obj.comment)

  const commentElement = comment => {
    let commentText = comment.comment?.split('+').join(' ')
    try {
      commentText = commentText ? decodeURIComponent(commentText) : ''
    } catch (err) {
      return commentText
    }

    return (
      <div key={comment.index} className={classes.commentsWrapper}>
        <div className={classes.singleCommentWrapper}>
          <div className={classes.imageAgeWrapper}>
            <div className={classes.comment}>
              <div className={classes.imageWrapper}>
                <img
                  className={classes.personImage}
                  src={sexIcons[comment.gender === '1' ? 'male' : 'female']}
                  alt=""
                  width="23"
                  height="27"
                />
              </div>
              <div className={classes.ageWrapper}>
                <span className={`${classes.ageLabel} ${classes.personLabel}`}>
                  {comment.age}
                </span>
                {/* <span className={classes.personLabel}> // MISSING DATE
                    {comment.date}
                  </span> */}
              </div>
            </div>
          </div>
          <div className={classes.descriptionWrapper}>
            <div className={classes.descriptionBox}>
              <span>{commentText}</span>
            </div>
            <div className={classes.ratingBox}>{comment.score}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.mainWrapper}>
      {filteredData.map(comment => commentElement(comment))}
    </div>
  )
}

ReviewerComments.propTypes = {
  data: oneOfType([array, object]).isRequired,
}
