import { useEffect, useState } from 'react'
import i18next from 'i18next'

export const useWithAsyncAction = args => {
  let argsWithLoading = {}
  let argsWithError = {}

  Object.keys(args).forEach(key => {
    argsWithLoading = {
      ...argsWithLoading,
      [key]: false,
    }
    argsWithError = {
      ...argsWithError,
      [key]: false,
    }
  })

  const [loading, setLoading] = useState(argsWithLoading)
  const [errors, setErrors] = useState(argsWithError)
  const [anyLoading, setAnyLoading] = useState(false)
  const [anyError, setAnyError] = useState('')

  useEffect(() => {
    setAnyLoading(Object.values(loading).some(value => value))
    setAnyError(Object.values(errors).find(value => value))
  }, [loading, errors])

  const setLoadingState = (key, value) => {
    setLoading({
      ...argsWithLoading,
      [key]: value,
    })
  }

  const setErrorState = (key, value) => {
    setErrors({
      ...argsWithError,
      [key]: value,
    })
  }

  let argsWithExecute = {}
  Object.keys(args).forEach(key => {
    argsWithExecute = {
      ...argsWithExecute,
      [key]: async (data, param, additionalParam) => {
        try {
          setLoadingState(key, true)
          const response = await args[key](data, param, additionalParam)
          setLoadingState(key, false)
          setErrorState(key, false)
          return response
        } catch (err) {
          setLoadingState(key, false)
          const errorData = err?.response?.data || {
            message: err?.response
              ? i18next.t('validation:requestNoDescription')
              : i18next.t('validation:requestFailedMessage'),
          }
          setErrorState(key, errorData)
          try {
            if (!err.response)
              err.response = {
                data: { message: i18next.t('validation:requestFailedMessage') },
              }
            if (!err.response.data) err.response.data = {}
            if (!err.response.data.message)
              err.response.data.message = i18next.t(
                'validation:requestNoDescription'
              )
          } catch (err_) {
            //
          }
          throw err
        }
      },
    }
  })

  return { actions: argsWithExecute, loading, anyLoading, errors, anyError }
}
