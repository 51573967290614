import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { string, node, bool } from 'prop-types'
import { Button } from '#components/button'
import { Text, TEXT_TYPE } from '#components/text'

const DEFAULT_MAX_WIDTH = '1200px'

const useStyles = () =>
  makeStyles(theme => ({
    header: {
      fontSize: '50px',
      textTransform: 'uppercase',
      fontFamily: theme.typography.bold,
    },
  }))()

const SButton = styled(Button)`
  width: 300px;
  height: 60px;
  font-size: 23px;
  text-transform: uppercase;
`

const gradient = css`
  linear-gradient(
    to bottom,
    #00a0ff,
    #000000
  );
`

const SSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ bgUrl }) =>
    bgUrl &&
    css`
      background-image: url('${bgUrl}');
    `}
  ${({ bgUrl }) =>
    !bgUrl &&
    css`
      background-image: ${gradient};
    `}
  
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 10px;
  width: 100%;
`

const SImg = styled.img`
  width: 6rem;
  margin-bottom: 3rem;
`

const SHeaderWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`

const SDescription = styled.div`
  width: 100%;
  white-space: pre-line;
  text-align: center;
  margin-bottom: 3rem;
`

export const Section = ({
  header,
  subheaderComponent,
  getStartedUrl,
  bgUrl,
  iconUrl,
  isAlignedToLeft,
  getStartedButtonToLeft,
  buttonTextTranslationKey,
  noButton,
  className,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const textAlign = isAlignedToLeft ? 'left' : 'center'

  return (
    <SSection className={className} bgUrl={bgUrl}>
      {!!iconUrl && <SImg src={iconUrl} alt="" />}
      {!!header && (
        <SHeaderWrapper
          style={{
            maxWidth: DEFAULT_MAX_WIDTH,
            display: isAlignedToLeft ? '' : 'flex',
            textAlign,
          }}
        >
          <Text
            type={TEXT_TYPE.H1}
            className={classes.header}
            align="center"
            style={{ whiteSpace: 'pre-line' }}
          >
            {header}
          </Text>
        </SHeaderWrapper>
      )}
      <SDescription style={{ maxWidth: DEFAULT_MAX_WIDTH, textAlign }}>
        {subheaderComponent}
      </SDescription>
      <div style={{ width: getStartedButtonToLeft ? '100%' : 'fit-content' }}>
        {!noButton && (
          <SButton onClick={() => navigate(getStartedUrl)}>
            {t(buttonTextTranslationKey || 'getStarted')}
          </SButton>
        )}
      </div>
    </SSection>
  )
}

Section.propTypes = {
  header: string,
  bgUrl: string,
  subheaderComponent: node.isRequired,
  getStartedUrl: string.isRequired,
  iconUrl: string,
  isAlignedToLeft: bool,
  getStartedButtonToLeft: bool,
  buttonTextTranslationKey: string,
  noButton: bool,
  className: string,
}

Section.defaultProps = {
  header: '',
  iconUrl: '',
  bgUrl: '',
  isAlignedToLeft: false,
  getStartedButtonToLeft: false,
  buttonTextTranslationKey: '',
  noButton: false,
  className: '',
}
