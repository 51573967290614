import React from 'react'
import styled from 'styled-components'
import { func, shape } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { CustomSelect } from '#components/inputs/custom-select'
import { useArtists } from '#hooks/swr/useArtists'

const useStyles = makeStyles(theme => ({
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 60,
    background: theme.palette.color.darkBlue03,
    flexGrow: 1,
  },
}))

const SButton = styled(Button)`
  height: 30px;
  margin-right: 28px;
`

const SCustomSelect = styled(CustomSelect)`
  width: 365px;
  margin-left: 28px;
`

export const TopBar = ({ setProfile, profile }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { artists } = useArtists()

  const profiles = artists
    ? artists.data.map(artist => {
        return {
          label: artist.name,
          value: artist.id,
        }
      })
    : []

  const profileOptions = [
    {
      label: t('allProfiles'),
      value: '',
    },
    ...profiles,
  ]

  const handleNewDistribution = () => {
    navigate(NAVIGATION_PATHS.DISTRO_GET_STARTED)
  }

  const handleChange = e => {
    setProfile({ name: e.label, id: e.value })
  }

  return (
    <div className={classes.topBar}>
      <SCustomSelect
        defaultValue={{ label: profile.name, value: profile.id }}
        options={profileOptions}
        placeholder="All Personas"
        isBolded
        onChange={e => handleChange(e)}
      />
      <SButton
        variant={VARIANTS.NEW_BLUE_SPECIAL}
        onClick={handleNewDistribution}
      >
        + {t('addNewDistribution')}
      </SButton>
    </div>
  )
}

TopBar.propTypes = {
  setProfile: func.isRequired,
  profile: shape({}),
}

TopBar.defaultProps = {
  profile: {},
}
