import * as yup from 'yup'
import i18next from 'i18next'

export const structureNameValidationSchema = t => {
  return yup.object().shape({
    name: yup.string().required(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('structureName'),
      })
    ),
  })
}

export const addProjectValidationSchema = t => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('songName'),
        })
      ),
  })
}
