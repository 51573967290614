import React from 'react'
import { bool, func, number, shape } from 'prop-types'
import { makeStyles, capitalize, Tooltip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import dayjs from 'dayjs'
import dayjsDuration from 'dayjs/plugin/duration'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import {
  setDesktopHeaders,
  SHARE_STATUSES,
} from '#pages/_modules/private-shares/utils/constants'
import { ImageWithTextAndPlay } from '#pages/_modules/private-shares/components/image-with-text-and-play'
import { AcceptDeclineShare } from '#pages/_modules/private-shares/components/accept-decline-share'
import { formatDate } from '#utils/formatDate'
import { UserImage } from '#components/user-image'
import { isEven } from '#utils/isEven'
import styled from 'styled-components'

dayjs.extend(dayjsDuration)
dayjs.extend(dayjsRelativeTime)

const useStyles = makeStyles(theme => ({
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '16px 0',
  },
  itemRowEven: {
    background: theme.palette.color.darkGrey0B,
  },
  itemRowTransparent: {
    opacity: 0.2,
  },
  removeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1.2rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  wrappedText: {
    whiteSpace: 'nowrap',
    maxWidth: 150,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  centered: {
    textAlign: 'center',
    width: '100%',
  },
  active: {
    fontWeight: 700,
    color: theme.palette.color.primary,
  },
  userImageWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenterCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SUserImage = styled(UserImage)`
  margin-right: 10px;
`

export const SharesListItem = ({
  share,
  isPrivateShareRole,
  isCollaborator,
  onPlay,
  onClear,
  index,
  handleAcceptShare,
  handleRejectShare,
  isOwner,
}) => {
  const classes = useStyles()

  const {
    fileName,
    lastAccessedAt,
    status,
    id,
    recipientUser,
    senderUser,
    pendingName,
  } = share

  const showShareStatus =
    isOwner || isCollaborator || (!isOwner && status !== SHARE_STATUSES.PENDING)
  const showRemoveIcon =
    (status === SHARE_STATUSES.ACTIVE || status === SHARE_STATUSES.PENDING) &&
    onClear &&
    !isPrivateShareRole

  const countDaysLeft = date => {
    const msLeft = dayjs(date).valueOf() - dayjs().valueOf()
    const daysLeft = Math.ceil(msLeft / 1000 / 60 / 60 / 24)
    if (daysLeft <= 0) {
      return 0
    }
    return daysLeft
  }

  // TODO i18n
  const remainingString = item => {
    if (item.status === SHARE_STATUSES.EXPIRED) return null
    if (item.expirationType === 'count') {
      const plays = item.maxAccessCount - item.accessCount
      if (plays > 0) {
        return `${plays} plays`
      }
      return SHARE_STATUSES.EXPIRED
    }
    const daysLeft = countDaysLeft(item.expiresAt)
    if (daysLeft > 0) {
      return `${dayjs.duration(daysLeft, 'days').humanize()}`
    }
    return ''
  }

  const remaining = remainingString(share)

  const hasTransparency =
    [
      SHARE_STATUSES.EXPIRED,
      SHARE_STATUSES.REVOKED,
      SHARE_STATUSES.REVOKED,
    ].includes(status) || remaining === SHARE_STATUSES.EXPIRED

  const desktopHeaders = setDesktopHeaders(isOwner)
  const recipientUserName = recipientUser?.firstName
    ? `${recipientUser?.firstName} ${recipientUser?.lastName}`
    : pendingName
  const senderUserName = `${senderUser?.firstName} ${senderUser?.lastName}`

  const sharedUser = isOwner
    ? {
        name: recipientUserName,
        profileImageUrl: recipientUser?.profileImageUrl,
      }
    : { name: senderUserName, profileImageUrl: senderUser?.profileImageUrl }

  return (
    <div
      key={id}
      className={`${classes.itemRow} ${
        isEven(index) ? '' : classes.itemRowEven
      } ${hasTransparency ? classes.itemRowTransparent : ''}`}
    >
      <ImageWithTextAndPlay
        width={desktopHeaders[0].width}
        title={fileName}
        isPlayVisible={
          // TODO backend handled
          status === SHARE_STATUSES.ACTIVE &&
          remaining !== SHARE_STATUSES.EXPIRED &&
          !isCollaborator
        }
        share={share}
        onPlay={onPlay}
      />
      <div
        className={classes.userImageWrapper}
        style={{
          width: desktopHeaders[1].width,
        }}
      >
        <SUserImage userImageUrl={sharedUser.profileImageUrl} />
        <Tooltip title={sharedUser.name}>
          <span className={classes.wrappedText}>{sharedUser.name}</span>
        </Tooltip>
      </div>
      <div
        className={classes.flexCenter}
        style={{ width: desktopHeaders[2].width }}
      >
        <span className={`${classes.wrappedText} ${classes.centered}`}>
          {lastAccessedAt ? formatDate(lastAccessedAt) : ''}
        </span>
      </div>
      <div
        className={classes.flexCenter}
        style={{ width: desktopHeaders[3].width }}
      >
        <span className={`${classes.wrappedText} ${classes.centered}`}>
          {remaining}
        </span>
      </div>
      <div
        className={classes.flexCenterCenter}
        style={{ width: desktopHeaders[4].width }}
      >
        {showShareStatus ? (
          <span
            className={`${classes.wrappedText} ${classes.centered} ${
              status === SHARE_STATUSES.ACTIVE && classes.active
            }`}
          >
            {capitalize(status)}
          </span>
        ) : (
          <AcceptDeclineShare
            onAcceptShare={() => handleAcceptShare(id)}
            onDeclineShare={() => handleRejectShare(id)}
          />
        )}
      </div>
      <div
        className={classes.removeIcon}
        style={{
          width: desktopHeaders[5].width,
        }}
      >
        {showRemoveIcon && <ClearIcon onClick={() => onClear(id)} />}
      </div>
    </div>
  )
}

SharesListItem.propTypes = {
  share: shape({}).isRequired,
  onPlay: func.isRequired,
  isPrivateShareRole: bool.isRequired,
  index: number.isRequired,
  handleAcceptShare: func.isRequired,
  handleRejectShare: func.isRequired,
  isOwner: bool.isRequired,
  onClear: func,
}

SharesListItem.defaultProps = {
  onClear: null,
}
