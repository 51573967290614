import React from 'react'
import { arrayOf, node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

const SLogoLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SLoading = styled.div`
  font-size: 2rem;
`

export const LogoLoader = ({ children, className }) => {
  return (
    <SLogoLoader className={className}>
      <img src="/assets/gifs/loading.gif" width="25px" alt="TuneGo logo" />
      <SLoading>{children}</SLoading>
    </SLogoLoader>
  )
}

LogoLoader.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
}
