import React from 'react'
import { string, bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FILE_EXTENSIONS } from '#constants/fileExtensions'
import { FileListItemTag } from '#pages/vault/song/tabs/files/components/file-list-item-tag'
import styled, { css } from 'styled-components'

const SFileName = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  max-width: 100%;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `};

  ${({ notReady }) =>
    notReady &&
    css`
      pointer-events: none;
      opacity: 0.75;
      cursor: auto;
    `};
`

const SMasterWrapper = styled.div`
  display: flex;
  margin: 0 0.7rem;
  gap: 0.5rem;
`

const SMasterText = styled.span`
  font-size: 0.8rem;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  text-transform: uppercase;
  margin-top: 0.2rem;
`

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1.875rem 0 0.625rem;
  margin: 0.625rem 0;
`

export const NameColumn = ({
  isMaster,
  name,
  fileSuffix,
  isProcessing,
  hasError,
  onPlay,
  onPreview,
  onDownload,
  tags,
  // mediaInfo,
  // category,
  ...restBoxProps
}) => {
  const validateTranslation = useTranslation('validate')
  const vaultTranslation = useTranslation('vault')

  const additionalMsg = hasError
    ? validateTranslation.t('errorTranscoding')
    : ''
  // const showFileTags = !isArrayEmpty(tags)
  // const hasMediaInfo = mediaInfo.audioCodecs || mediaInfo.bitDepth
  // const showUnqualifiedMessage = !showFileTags && !isProcessing && hasMediaInfo
  // const showQualificationInProgressMessage =
  //   !hasMediaInfo && category === FILE_TYPE.AUDIO && !isProcessing

  const displayFileName = () => {
    if (FILE_EXTENSIONS.AUDIO.includes(fileSuffix) && !isMaster) {
      return (
        <SFileName
          notReady={isProcessing || hasError}
          pointer={FILE_EXTENSIONS.AUDIO.includes(fileSuffix)}
          onClick={onPlay}
        >
          {`${name} ${additionalMsg}`}
        </SFileName>
      )
    }

    if (
      [...FILE_EXTENSIONS.DIGITAL_ART, ...FILE_EXTENSIONS.VIDEO].includes(
        fileSuffix
      )
    ) {
      return (
        <SFileName pointer onClick={onPreview}>
          {name}
        </SFileName>
      )
    }

    return (
      <SFileName pointer onClick={onDownload}>
        {name}
      </SFileName>
    )
  }

  return (
    <SWrapper style={{ ...restBoxProps }}>
      {displayFileName()}
      {isMaster && (
        <SMasterWrapper>
          <SMasterText>{vaultTranslation.t('master')}</SMasterText>
          <img src="/assets/svg/ic-master-file.svg" alt="" />
        </SMasterWrapper>
      )}
      {tags.map((tag, index) => (
        <FileListItemTag key={index} tag={tag} />
      ))}
    </SWrapper>
  )
}

NameColumn.propTypes = {
  isMaster: bool.isRequired,
  name: string.isRequired,
  fileSuffix: string.isRequired,
  isProcessing: bool.isRequired,
  hasError: bool.isRequired,
  onPlay: func.isRequired,
  onPreview: func.isRequired,
  onDownload: func.isRequired,
}
