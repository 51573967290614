import React, { useState } from 'react'
import { StepWrapper } from '#pages/sell-collectibles/create-collectible/_components/step-wrapper'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import { SubmitStep } from '#pages/sell-collectibles/create-collectible/_components/step-submit'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { useNftData } from '#pages/sell-collectibles/create-collectible/_hooks/use-nft-data'
import { ErrorMessage } from '#components/error-message'
import { Details } from '#pages/sell-collectibles/create-collectible/_components/steps/details/_sections/details'
import {
  NFT_DETAILS_STEP_FIELDS,
  NFT_FILES_STEP_FIELDS,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { ReleaseDate } from '#pages/sell-collectibles/create-collectible/_components/steps/details/_sections/release-date'
import { ScarcityAndPrice } from '#pages/sell-collectibles/create-collectible/_components/steps/details/_sections/scarcity-and-price'
import styled from 'styled-components'

const SContent = styled.div`
  padding: 2.5rem;
`

export const DetailsStepContent = () => {
  const { t } = useTranslation('nftWizard')
  const [loading, setLoading] = useState(false)

  const {
    activeStep,
    setActiveStep,
    detailsStepMethods: methods,
    filesStepMethods,
  } = useNftCreator()

  const price = methods.watch(NFT_DETAILS_STEP_FIELDS.PRICE)
  const scarcity = methods.watch(NFT_DETAILS_STEP_FIELDS.SCARCITY)
  const formValid = methods.formState.isValid && price && scarcity

  const structureId = filesStepMethods.getValues(
    NFT_FILES_STEP_FIELDS.MUSIC
  ).projectId

  const { error, saveDetails } = useNftData()

  const onSubmit = async values => {
    setLoading(true)
    try {
      await saveDetails(structureId)

      methods.reset(values)

      setActiveStep(activeStep + 1)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepWrapper
      title={t('steps.details.title')}
      infoKey="steps.details.info"
      step={2}
      loading={loading}
    >
      <SContent>
        <FormProvider {...methods}>
          <Details structureId={structureId} />
          <ReleaseDate />
          <ScarcityAndPrice />
          <SubmitStep
            disabled={loading || !formValid}
            formFields={Object.values(NFT_DETAILS_STEP_FIELDS)}
            onSubmit={onSubmit}
          />
        </FormProvider>
        {error && <ErrorMessage errorMessage={t('error')} />}
      </SContent>
    </StepWrapper>
  )
}
