import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MetadataInfo } from '#pages/distro/wizard/steps/add-song-files/components/metadata-info'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'

const SButton = styled(Button)`
  width: 120px;
  text-transform: uppercase;
`

export const MetadataInfoContainer = ({
  onClick,
  onCancel,
  isReadyForDistribution,
  isMetadataMenuVisible,
}) => {
  const { t } = useTranslation()

  return isMetadataMenuVisible ? (
    <SButton variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={onCancel}>
      {t('cancel')}
    </SButton>
  ) : (
    <MetadataInfo
      isReadyForDistribution={isReadyForDistribution}
      onAddMetaData={onClick}
    />
  )
}

MetadataInfoContainer.propTypes = {
  onClick: func.isRequired,
  onCancel: func.isRequired,
  isReadyForDistribution: bool.isRequired,
  isMetadataMenuVisible: bool.isRequired,
}
