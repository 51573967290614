import React, { useEffect, useState } from 'react'
import { arrayOf, func, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { PaymentMethodItem } from '#pages/profile/tabs/payment-methods/components/payment-method-item'
import { MODAL_TYPES } from '#pages/profile/utils/constants'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  methodHeader: {
    width: '100%',
    padding: '0.5rem 1.8rem',
    fontFamily: theme.typography.bold,
    backgroundColor: theme.palette.color.darkGrey33,
  },
  defaultMethod: {
    backgroundColor: theme.palette.color.primary,
  },
}))

export const PaymentMethodsTable = ({ openModal, data }) => {
  const { t } = useTranslation('profile')
  const classes = useStyles()
  const [defaultMethod, setDefaultMethod] = useState(null)

  useEffect(() => {
    const defaultPaymentMethod = data.find(method => method.isDefault)
    setDefaultMethod(defaultPaymentMethod ?? null)
  }, [data])

  const handleOpenModal = (id, type) => () => {
    openModal(id, type)
  }

  return (
    <>
      <div className={`${classes.methodHeader} ${classes.defaultMethod}`}>
        {t('defaultPaymentMethod')}
      </div>
      {defaultMethod && (
        <PaymentMethodItem
          methodData={defaultMethod}
          setAsDefault={handleOpenModal(
            defaultMethod.id,
            MODAL_TYPES.SET_AS_DEFAULT
          )}
          isDefault
        />
      )}
      <div className={classes.methodHeader}>{t('otherPaymentMethods')}</div>
      {data.map((method, index) => {
        if (!method.isDefault) {
          return (
            <PaymentMethodItem
              key={method.id}
              methodData={method}
              isEven={isEven(index)}
              setAsDefault={handleOpenModal(
                method.id,
                MODAL_TYPES.SET_AS_DEFAULT
              )}
              deleteCard={handleOpenModal(
                method.id,
                MODAL_TYPES.DELETE_THIS_CARD
              )}
            />
          )
        }
        return null
      })}
    </>
  )
}

PaymentMethodsTable.propTypes = {
  data: arrayOf(shape({})).isRequired,
  openModal: func.isRequired,
}
