export const ACTION_TYPES = {
  edit: 'edit',
  cancel: 'cancel',
  cancelWarning: 'cancelWarning',
  resend: 'resend',
}

export const SPLIT_STATUSES = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  NEW: 'new',
}

export const getMenuOptions = (status, isDeletable, t) => {
  const baseOptions = [
    {
      title: t('editRoles'),
      actionType: ACTION_TYPES.edit,
    },
  ]
  if (status === SPLIT_STATUSES.PENDING || status === SPLIT_STATUSES.REJECTED) {
    baseOptions.push({
      title: t('resendInvite'),
      actionType: ACTION_TYPES.resend,
    })
  }
  if (isDeletable) {
    baseOptions.push({
      title: t('removeParticipant'),
      actionType: ACTION_TYPES.cancel,
    })
  } else {
    baseOptions.push({
      title: t('removeParticipant'),
      actionType: ACTION_TYPES.cancelWarning,
    })
  }
  return baseOptions
}

export const HEADERS = [
  { name: 'name', width: '25%' },
  {
    name: 'master',
    width: '15%',
    justifyContent: 'center',
  },
  { name: 'publishing', width: '15%', justifyContent: 'center' },
  { name: 'role', width: '25%', justifyContent: 'center' },
  { name: 'status', width: '20%' },
]
