import React, { useEffect } from 'react'
import { string, func, arrayOf, bool, number } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BaseLoader } from '#components/loaders/base-loader'
import { FileRow } from '#modules/choose-file-tree/components/file-row'
import { useArtistProjectFiles } from '#hooks/swr/useArtists'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'
import { EmptyContent } from '#modules/choose-file-tree/components/empty-content'
import { isEven } from '#utils/isEven'
import styled from 'styled-components'

const SLoaderWrapper = styled.div`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_5%']};
  padding-bottom: ${({ isLoading }) => (isLoading ? '0.5rem' : 0)};
`

export const FilesList = ({
  parentId,
  handleFileSelect,
  selectedFileId,
  selectedFilesIds,
  showFilesCondition,
  nested,
  isMainRowLast,
  storedFilesIds,
  disabled,
  disableSelect,
  disabledTooltipText,
  reloadCount,
  showProcessing,
}) => {
  const { t } = useTranslation('vault')
  const isImage =
    showFilesCondition === SHOW_FILES_CONDITION.IMAGE ||
    showFilesCondition === SHOW_FILES_CONDITION.NFT_IMAGE
  const query = `?withoutPagination=true&isHealthChecked=true${
    showFilesCondition ? `&${showFilesCondition}=true` : ''
  }`

  const { files, isLoading, mutate } = useArtistProjectFiles(
    { projectId: parentId },
    query
  )

  useEffect(() => {
    if (reloadCount > 0) mutate()
  }, [reloadCount])

  if (files?.data.length === 0) {
    return <EmptyContent type={t('song')} />
  }

  return (
    <>
      <SLoaderWrapper isLoading={isLoading}>
        <BaseLoader isLoading={isLoading} text={t('songFilesAreBeingLoaded')} />
      </SLoaderWrapper>

      {files?.data.map((file, index) => {
        const { id } = file

        const fileRowProps = {
          isEven: isEven(index),
          handleFileSelect,
          selectedFileId,
          selectedFilesIds,
          isImage,
          nested,
          isMainRowLast,
          parentId,
          isLastRow: index + 1 === files?.count,
          storedFilesIds,
          disabled: disabled || disableSelect(file),
          disabledTooltipText,
          showProcessing,
          file,
        }

        return <FileRow key={id} {...fileRowProps} />
      })}
    </>
  )
}

FilesList.propTypes = {
  parentId: string.isRequired,
  handleFileSelect: func.isRequired,
  selectedFileId: string,
  nested: bool,
  isMainRowLast: bool,
  count: number.isRequired,
  showFilesCondition: string,
  selectedFilesIds: arrayOf(string),
  storedFilesIds: arrayOf(string),
  disabled: bool,
  disableSelect: func,
  disabledTooltipText: string,
  reloadCount: number,
  showProcessing: bool,
}

FilesList.defaultProps = {
  selectedFileId: null,
  showFilesCondition: '',
  selectedFilesIds: null,
  nested: false,
  isMainRowLast: false,
  storedFilesIds: [],
  disableSelect: () => false,
  reloadCount: 0,
}
