import {
  BILLING_PERIOD,
  SUBSCRIPTION_PLANS,
} from '#pages/profile/utils/constants'
import {
  formatProratedAdjustment,
  paymentCardInfo,
} from '#pages/profile/utils/helpers'
import React from 'react'
import { PAYMENT_STATUSES } from '#constants/paymentStatuses'
import * as dayjs from 'dayjs'

export const getPaymentOrderData = ({
  payment,
  tierLevel,
  displayedPrice,
  planPeriod,
  isSwitchedTrue,
  currentSubscription,
  accountRenewalDate,
  isProratedTrue,
  classes,
  t,
}) => {
  const showPaymentRow =
    !isProratedTrue && payment?.status === PAYMENT_STATUSES.COMPLETED

  const paymentRows = [
    {
      rowTitle: t('payment'),
      rowValue: (
        <div className={`${classes.rowValueWrapper} ${classes.bold}`}>
          {paymentCardInfo(
            payment?.createdAt,
            payment?.card?.brand,
            payment?.card?.last4Digits
          )}
        </div>
      ),
    },
  ]

  const planTierRow = {
    rowTitle: '',
    rowValue: (
      <div className={classes.rowValueWrapper}>
        {t('tunego')}
        <div className={classes.bold} style={{ margin: '0 4px' }}>
          {t(SUBSCRIPTION_PLANS[tierLevel]).toUpperCase()}
        </div>
        {t('subscription')}
      </div>
    ),
  }

  const priceRow = {
    rowTitle: '',
    rowValue: (
      <div className={classes.rowValueWrapper}>
        <div className={classes.bold} style={{ marginRight: '4px' }}>
          {displayedPrice && displayedPrice.toLowerCase()}
        </div>
        {t('billed', {
          period: planPeriod && planPeriod,
        })}
      </div>
    ),
  }

  const renewalDateRow = {
    rowTitle: t('accountRenewalDate'),
    rowValue: (
      <div className={classes.bold}>
        {isSwitchedTrue
          ? dayjs(currentSubscription?.currentCycleEnd)
              .add(1, BILLING_PERIOD.YEAR)
              .format('MM/DD/YYYY')
          : accountRenewalDate}
      </div>
    ),
  }

  return [
    planTierRow,
    priceRow,
    renewalDateRow,
    ...(showPaymentRow ? paymentRows : []),
  ]
}
export const getPaymentProratedData = ({
  classes,
  isMonthly,
  tierLevel,
  displayedTotalPrice,
  adjustmentPrice,
  t,
}) => {
  const proratedTotalRow = isMonthly
    ? {}
    : {
        rowTitle: (
          <div className={classes.rowValueWrapper}>
            {t('tunego')}
            <div className={classes.bold} style={{ margin: '0 4px' }}>
              {t(SUBSCRIPTION_PLANS[tierLevel]).toUpperCase()}
            </div>
            {t('subscriptionPlanTotal')}
          </div>
        ),
        rowValue: displayedTotalPrice && displayedTotalPrice.toLowerCase(),
      }

  const prorateAdjustmentRow = {
    rowTitle: t('proratedAdjustment'),
    rowValue: formatProratedAdjustment(adjustmentPrice),
  }

  return [proratedTotalRow, prorateAdjustmentRow]
}
