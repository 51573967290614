import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, arrayOf, objectOf, string } from 'prop-types'
import { CircularGraph } from '#pages/promotion/report/components/circular-graph'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  demographicsContainer: {
    display: 'flex',
    padding: 16,
  },
  graphsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  smallCircleGraph: {
    minHeight: '400px',
    minWidth: '300px',
  },
})

export const ReviewerDemographics = ({
  dataSampleGroupAge,
  dataSampleGroupGender,
  total,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.demographicsContainer}>
      <ReviewRowElement
        refName="reviewerDemographicsEl"
        title="reviewerDemographics"
        description="wantToKnowTheAgeAndGender"
      >
        <div className={classes.graphsWrapper}>
          <div className={classes.smallCircleGraph}>
            <CircularGraph
              data={dataSampleGroupAge}
              total={total}
              unit="%"
              legendPosition="center"
              colorRange={['#00a2ff', '#006ba8', '#003f63']}
              legendXSpaceBetween={100}
              marginY={120}
              align="center"
              legendYMargin={60}
              showLabels
            />
          </div>
          <div className={classes.smallCircleGraph}>
            <CircularGraph
              total={total}
              data={dataSampleGroupGender}
              unit="%"
              legendPosition="center"
              colorRange={['#811784', '#154577']}
              legendXSpaceBetween={100}
              marginY={120}
              legendYMargin={60}
              align="center"
              showLabels
            />
          </div>
        </div>
      </ReviewRowElement>
    </div>
  )
}

ReviewerDemographics.propTypes = {
  dataSampleGroupAge: arrayOf(objectOf(string)).isRequired,
  dataSampleGroupGender: arrayOf(objectOf(string)).isRequired,
  total: number,
}

ReviewerDemographics.defaultProps = {
  total: null,
}
