import React from 'react'
import { makeStyles } from '@material-ui/core'
import { func } from 'prop-types'

const useStyles = makeStyles(() => ({
  iconWrapper: {
    cursor: 'pointer',
  },
}))

export const ReviewReadyIcon = ({ onClickHandler }) => {
  const classes = useStyles()

  return (
    <div className={classes.iconWrapper} onClick={onClickHandler}>
      <img src="/assets/svg/ic-check-blue.svg" alt="" width="25px" />
    </div>
  )
}

ReviewReadyIcon.propTypes = {
  onClickHandler: func.isRequired,
}
