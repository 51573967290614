import React from 'react'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { TABS } from './constants'
import { Collaborators } from '#pages/vault/song/tabs/collaborators'
import { Files } from '#pages/vault/song/tabs/files'
import { FingerPrint } from '#pages/vault/song/tabs/finger-print'
import { Splits } from '#pages/vault/song/tabs/splits'
import { PrivateShares } from '#pages/vault/song/tabs/private-shares'
import { Metadata } from '#pages/vault/song/tabs/metadata'
import { Lyrics } from '#pages/vault/song/tabs/lyrics'

export const allTabs = (roles, enableLyrics) => ({
  FILES: {
    label: 'files',
    component: <Files accessRoles={roles} />,
    value: TABS.FILES,
  },
  METADATA: {
    label: 'metadata',
    component: <Metadata accessRoles={roles} />,
    value: TABS.METADATA,
  },
  ...(enableLyrics && {
    LYRICS: {
      label: 'lyrics',
      component: <Lyrics />,
      value: TABS.LYRICS,
    },
  }),
  SPLITS: {
    label: 'splits',
    component: <Splits />,
    value: TABS.SPLITS,
  },
  COLLABORATORS: {
    label: 'collaborators',
    component: <Collaborators accessRoles={roles} />,
    value: TABS.COLLABORATORS,
  },
  PRIVATE_SHARES: {
    label: 'privateShares',
    component: <PrivateShares />,
    value: TABS.PRIVATE_SHARES,
  },
  FINGERPRINT: {
    label: 'fingerprints',
    component: <FingerPrint />,
    value: TABS.FINGERPRINT,
  },
})

export const generateTabs = (roles, enableLyrics) => {
  const tabs = allTabs(roles, enableLyrics)
  const ownerOrCollab =
    roles?.includes(ACCESS_ROLES.OWNER) ||
    roles?.includes(ACCESS_ROLES.COLLABORATOR)

  if (ownerOrCollab) {
    return [...Object.values(tabs)]
  }
  return [
    ...(roles?.includes(ACCESS_ROLES.CONTRIBUTOR) ? [tabs.METADATA] : []),
    ...(roles?.includes(ACCESS_ROLES.SPLIT) ? [tabs.SPLITS] : []),
    ...(roles?.includes(ACCESS_ROLES.PRIVATE_SHARE)
      ? [tabs.PRIVATE_SHARES]
      : []),
  ]
}
