import React, { useEffect } from 'react'
import { Router as ReachRouter } from '@reach/router'
import { ROUTES } from '#routes/routes'
import { Home } from '#pages/home'
import { PrivacyPolicy } from '#pages/privacy-policy'
import { TermsOfUse } from '#pages/terms-of-use'
import { DistroTerms } from '#pages/distro-terms'
import { ConfirmEmail } from '#pages/confirm-email'
import { DownloadMobileApp } from '#pages/download-mobile-app'
import { VerifyYourAccount } from '#pages/verify-your-account'
import { Onboarding } from '#pages/onboarding'
import { ComingSoon } from '#components/coming-soon'
import { ProtectedRoute } from './components/protected-route'
import { PublicRoute } from './components/public-route'
import { LoginRequired } from '#pages/home/login-required'
import { Maintenance } from '#pages/maintenance'
import { Profile } from '#pages/profile'
import { ConnectWallet } from '#pages/connect-wallet'
import { CreatorsVaultSetup } from '#pages/creators-vault-setup'
import * as SellCollectibles from '#pages/sell-collectibles'
import { LearnWeb3 } from '#pages/learn-web3'
import * as Artists from '#pages/vault'
import * as Promotion from '#pages/promotion'
import * as Distribution from '#pages/distro'
import * as Connect from '#pages/connect'
import * as VideoGo from '#pages/video-go'
import * as Mastering from '#pages/mastering'
import * as Subscriptions from '#pages/subscriptions'
import { GettingStarted } from '#pages/getting-started'
import { LoginRedirectPage } from '#pages/login-redirect-page'
import { OwnerAgreement } from '#pages/owner-agreement'
import { useLocalStorage } from '#hooks/useLocalStorage'
import { useSearchParam } from '#hooks/useQuery'
import { SOURCE_LOCAL_STORAGE_KEY, SOURCE_PARAM } from '#constants/source'

// TODO FIXME use IS_DEV to enable/disable routes? will this be enough to tree-shake components out?
export const Router = () => {
  const isProd = process.env.REACT_APP_ENV === 'prod'
  const sourceParam = useSearchParam(SOURCE_PARAM, window.location.search)
  const [, setSource] = useLocalStorage(SOURCE_LOCAL_STORAGE_KEY)

  useEffect(() => {
    if (sourceParam) setSource(sourceParam)
  }, [sourceParam])

  return (
    <ReachRouter>
      <Maintenance path={ROUTES.MAINTENANCE} />
      <PublicRoute path="/" component={Home} />
      <PublicRoute path={ROUTES.LOGIN_REQUIRED} component={LoginRequired} />
      {/* <PublicRoute path={MAIN_ROUTES.VIP_ENTRY} component={Home} isVip /> */}
      <TermsOfUse path={ROUTES.TERMS} />
      <PrivacyPolicy path={ROUTES.PRIVACY_POLICY} />
      <DistroTerms path={ROUTES.DISTRO_TERMS} />
      <OwnerAgreement path={ROUTES.OWNER_AGREEMENT} />
      <DownloadMobileApp path={ROUTES.DOWNLOAD_MOBILE_APP} />
      <CreatorsVaultSetup path={ROUTES.CREATORS_VAULT_SETUP} />
      <PublicRoute path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} />
      <PublicRoute path={ROUTES.VERIFY_EMAIL} component={VerifyYourAccount} />
      <PublicRoute path={ROUTES.VERIFY_EMAIL_MOBILE} component={Home} />
      <PublicRoute path={ROUTES.DIRECT_SIGNUP} component={LoginRedirectPage} />
      <ProtectedRoute path={ROUTES.ONBOARDING} component={Onboarding} />

      {/* PROFILE */}
      <ProtectedRoute component={Profile} path={ROUTES.PROFILE} />
      <ProtectedRoute
        component={GettingStarted}
        path={ROUTES.GETTING_STARTED}
      />
      <ProtectedRoute component={LearnWeb3} path={ROUTES.LEARN_WEB3} />
      {/* ARTISTS */}
      <Artists.Routes path={ROUTES.ARTISTS.MAIN} exact>
        <ProtectedRoute
          path={ROUTES.ARTISTS.PROFILES}
          component={Artists.Profiles}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.SONG}
          component={Artists.SongPage}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.SONG_TAB}
          component={Artists.SongPage}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.VAULT}
          component={Artists.LandingPage}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.EDIT_PROFILE}
          component={Artists.CreateProfile}
          isEdit
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.PROFILE_CONTENT}
          component={Artists.ProfileContent}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.PROFILE_COLLABORATORS}
          component={Artists.ArtistCollaborators}
        />
        <ProtectedRoute
          path={ROUTES.ARTISTS.CREATE_PROFILE}
          component={Artists.CreateProfile}
          isEdit={false}
        />
      </Artists.Routes>
      {/* DISTRO */}
      <Distribution.Routes path={ROUTES.DISTRO.MAIN} exact>
        <ProtectedRoute path="/" component={Distribution.LandingPage} />
        <ProtectedRoute component={ComingSoon} path="/coming-soon" />
        <ProtectedRoute
          path={ROUTES.DISTRO.GET_STARTED}
          component={Distribution.GetStarted}
        />
        <ProtectedRoute
          path={ROUTES.DISTRO.CREATE_NEW}
          component={Distribution.CreateNew}
        />
        <ProtectedRoute
          path={ROUTES.DISTRO.PAST_RELEASES}
          component={Distribution.PastReleases}
        />
        <ProtectedRoute
          path={ROUTES.DISTRO.PAST_RELEASE_DETAILS}
          component={Distribution.PastReleasesDetails}
        />
      </Distribution.Routes>
      {/* PROMOTION */}
      <Promotion.Routes path={ROUTES.PROMOTION.MAIN} exact>
        <ProtectedRoute component={Promotion.Home} path="/" />
        <ProtectedRoute component={ComingSoon} path="/coming-soon" />
        <ProtectedRoute
          path={ROUTES.PROMOTION.NEW_PROMOTION}
          component={Promotion.CreatePromotion}
        />
        <ProtectedRoute
          path={ROUTES.PROMOTION.REPORTS}
          component={Promotion.Reports}
        />
        <ProtectedRoute
          path={ROUTES.PROMOTION.REPORT}
          component={Promotion.Report}
        />
      </Promotion.Routes>
      {/* CONNECT */}
      <Connect.Routes path={ROUTES.CONNECT} exact>
        <ProtectedRoute component={Connect.LandingPage} path="/" />
        <ProtectedRoute component={ComingSoon} path="/coming-soon" />
      </Connect.Routes>
      {/* VIDEO GO */}
      <VideoGo.Routes path={ROUTES.VIDEO_GO} exact>
        <ProtectedRoute component={VideoGo.LandingPage} path="/" />
        <ProtectedRoute component={ComingSoon} path="/coming-soon" />
      </VideoGo.Routes>
      {/* MASTERING */}
      <Mastering.Routes path={ROUTES.MASTERING.MAIN} exact>
        {isProd ? (
          <>
            <ProtectedRoute component={ComingSoon} path="/" />
            <ProtectedRoute
              component={ComingSoon}
              path={ROUTES.MASTERING.NEW}
            />
            <ProtectedRoute
              component={ComingSoon}
              path={ROUTES.MASTERING.VIEW_MASTERS}
            />
          </>
        ) : (
          <>
            <ProtectedRoute component={Mastering.LandingPage} path="/" />
            <ProtectedRoute component={ComingSoon} path="/coming-soon" />
            <ProtectedRoute
              component={Mastering.MasterNew}
              path={ROUTES.MASTERING.NEW}
            />
            <ProtectedRoute
              component={Mastering.Masters}
              path={ROUTES.MASTERING.VIEW_MASTERS}
            />
          </>
        )}
      </Mastering.Routes>

      {/* Sell collectibles */}
      <SellCollectibles.Routes path={ROUTES.SELL_COLLECTIBLES.MAIN} exact>
        <ProtectedRoute
          component={SellCollectibles.SellCollectiblesLandingPage}
          path="/"
        />
        <ProtectedRoute
          component={SellCollectibles.CreateCollectible}
          path={ROUTES.SELL_COLLECTIBLES.CREATE}
        />
        <ProtectedRoute
          component={SellCollectibles.MyCollectibles}
          path={ROUTES.SELL_COLLECTIBLES.MY_COLLECTIBLES}
        />
      </SellCollectibles.Routes>

      {/* SUBSCRIPTION */}
      <Subscriptions.Routes path={ROUTES.SUBSCRIPTIONS.MAIN} exact>
        <ProtectedRoute
          path={ROUTES.SUBSCRIPTIONS.PAYMENT}
          component={Subscriptions.Payment}
        />
        <ProtectedRoute
          path={ROUTES.SUBSCRIPTIONS.PAYMENT_SUCCESS}
          component={Subscriptions.PaymentSuccess}
        />
      </Subscriptions.Routes>
      {/* CONNECT WALLET */}
      <ProtectedRoute component={ConnectWallet} path={ROUTES.CONNECT_WALLET} />
      <ProtectedRoute
        component={() => <ConnectWallet mobile />}
        path={ROUTES.CONNECT_WALLET_MOBILE}
      />
    </ReachRouter>
  )
}
