import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { FieldSection } from '#pages/sell-collectibles/create-collectible/_components/steps/details/field-section'
import { SummaryTable } from '#pages/sell-collectibles/create-collectible/_components/steps/details/summary-table'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
`

const SBold = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`
export const ScarcityAndPrice = () => {
  const { t } = useTranslation('nftWizard')

  return (
    <SWrapper>
      <div>
        <FieldSection
          title={t('steps.details.scarcity')}
          text={
            <Trans t={t} i18nKey="steps.details.scarcityInfo">
              <SBold />
            </Trans>
          }
          name={NFT_DETAILS_STEP_FIELDS.SCARCITY}
          label={t('steps.details.scarcityLabel')}
        />

        <FieldSection
          title={t('steps.details.price')}
          text={
            <Trans t={t} i18nKey="steps.details.priceInfo">
              <SBold />
            </Trans>
          }
          name={NFT_DETAILS_STEP_FIELDS.PRICE}
          label={t('steps.details.priceLabel')}
          isPrice
        />
      </div>
      <SummaryTable />
    </SWrapper>
  )
}
