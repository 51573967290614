import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { bool, func, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { BaseInput } from '#components/inputs/base-input'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { FeaturedArtistsApi } from '#api/requests/featuredArtists'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { featuredArtistsValidationSchema } from '#pages/distro/_utils/validation'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useError } from '#hooks/useError'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'block',
    fontSize: 32,
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: 20,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
}))

const SModal = styled(Modal)`
  width: 407px;
  min-height: 278px;
`

const SForm = styled.form`
  width: 100%;
`

const SButton = styled(Button)`
  width: 150px;
  margin-right: 0;
`

export const AddFeaturedArtistModal = ({ isOpened, setIsOpen, songId }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const { storedFormState, setStoredFormState } = useWizard()

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(featuredArtistsValidationSchema(distroTranslation.t)),
  })
  const { error, setResponseError, clearError } = useError()

  const { actions } = useWithAsyncAction({
    postFeaturedArtist: FeaturedArtistsApi.postFeaturedArtist,
  })

  const handleFormSubmit = async data => {
    try {
      const response = await actions.postFeaturedArtist(songId, data)

      setStoredFormState({
        [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
          file.id === songId
            ? {
                ...file,
                featuredArtists: [...file.featuredArtists, response.data],
              }
            : { ...file }
        ),
      })
      reset()
    } catch (err) {
      setResponseError(err)
    }

    setIsOpen(false)
  }

  if (error) {
    return (
      <CommonLoadingOverlay error={error} onErrorBackdropClick={clearError} />
    )
  }

  return (
    <SModal isOpen={isOpened} setIsOpen={setIsOpen}>
      <SForm onSubmit={handleSubmit(handleFormSubmit)}>
        <span className={classes.title}>
          {distroTranslation.t(`addFeaturedArtist`)}
        </span>
        <BaseInput
          label={distroTranslation.t('featuredArtistName')}
          name={FIELDS.SONG_FILE_METADATA_FEATURED_ARTISTS}
          inputRef={register}
          customWidth="100%"
          errorMessage={
            formErrors[FIELDS.SONG_FILE_METADATA_FEATURED_ARTISTS]?.message
          }
          isBolded
        />
        <div className={classes.buttons}>
          <SButton type="submit">{t(`add`)}</SButton>
          <SButton
            variant={VARIANTS.NEW_BLUE_SPECIAL}
            onClick={() => setIsOpen(false)}
          >
            {t(`cancel`)}
          </SButton>
        </div>
      </SForm>
    </SModal>
  )
}

AddFeaturedArtistModal.propTypes = {
  isOpened: bool.isRequired,
  setIsOpen: func.isRequired,
  songId: string.isRequired,
}
