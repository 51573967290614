import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { func, number, shape, string } from 'prop-types'
import { VARIANTS } from '#components/button/constants'
import { Button } from '#components/button'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.color.lighterBlue,
    minHeight: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 5px 5px 30px',
  },
  nameWrapper: {
    wordBreak: 'break-word',
    maxWidth: 1080,
  },
  isEven: {
    backgroundColor: theme.palette.color.darkBlue,
  },
}))

const SButton = styled(Button)`
  border: none;
  opacity: 0.5;
`

export const FeaturedArtistItem = ({ artist, handleDelete, index }) => {
  const classes = useStyles()
  const { name, id } = artist

  return (
    <div
      className={`${classes.container} ${isEven(index) ? classes.isEven : ''}`}
    >
      <span className={classes.nameWrapper}>{name}</span>
      <SButton variant={VARIANTS.TRANSPARENT} onClick={() => handleDelete(id)}>
        <img
          src="/assets/svg/ic-trash.svg"
          width="20px"
          height="20px"
          alt="Delete icon"
        />
      </SButton>
    </div>
  )
}

FeaturedArtistItem.propTypes = {
  artist: shape({
    name: string.isRequired,
    id: string.isRequired,
  }).isRequired,
  handleDelete: func.isRequired,
  index: number.isRequired,
}
