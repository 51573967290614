import React from 'react'
import { string, arrayOf, shape } from 'prop-types'
import cn from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { hexToRgba } from '#utils/hexToRgba'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  priceRow: {
    fontSize: 21,
    textTransform: 'uppercase',
    fontFamily: 'AvenirNextLTPro-Bold, Arial, sans-serif',
  },
  summaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${hexToRgba(theme.palette.color.primary, 0.5)}`,
    background: hexToRgba(theme.palette.color.primary, 0.5),
  },
  summaryRow: {
    justifyContent: 'space-between',
    background: hexToRgba(theme.palette.color.default, 0.05),
  },
  summaryRowEven: {
    background: hexToRgba(theme.palette.color.black, 0.05),
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  row: {
    height: 60,
    lineHeight: '60px',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
    borderTop: '0',
    borderBottom: '0',
  },
}))

export const Summary = ({ summaryData }) => {
  const classes = useStyles()

  return (
    <div className={classes.summaryWrapper}>
      {summaryData?.map(({ rowTitle, rowValue }, index) => {
        if (rowValue) {
          return (
            <div
              key={rowTitle}
              className={cn(classes.summaryRow, classes.row, {
                [classes.summaryRowEven]: isEven(index),
              })}
            >
              <div
                className={cn({
                  [classes.fontWeightBold]: index !== 0,
                  [classes.priceRow]: index === summaryData.length - 1,
                })}
              >
                {rowTitle}
              </div>
              <div
                className={cn(classes.fontWeightBold, {
                  [classes.priceRow]: index === summaryData.length - 1,
                })}
              >
                {rowValue}
              </div>
            </div>
          )
        }
        return ''
      })}
    </div>
  )
}

Summary.propTypes = {
  summaryData: arrayOf(shape({ rowTitle: string, rowValue: string })),
}

Summary.defaultProps = {
  summaryData: [],
}
