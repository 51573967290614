/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { TermsWrapper } from '#pages/_components/terms-wrapper'
import styled from 'styled-components'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  line-height: 1.5;
  padding: 0 24px 70px;
  font-family: ${({ theme: { typography } }) => typography.avenirNextFont};
  font-weight: 400;
`

const SHeading = styled.h2`
  font-size: 18px;
`

const SList = styled.ul`
  list-style: none;
`

const SListItem = styled.li`
  margin-top: 10px;
`

export const OwnerAgreementText = () => {
  return (
    <TermsWrapper>
      <SWrapper>
        <h1>DIGITAL COLLECTIBLE OWNER AGREEMENT</h1>
        <p>
          <span>This &quot;</span>
          <b>Owner Agreement</b>
          <span>&quot; is by and between TuneGO, Inc. (&quot;</span>
          <b>Licensor</b>
          <span>
            &quot;) and the individual or entity that owns the Digital
            Collectible (as defined below) (the &quot;
          </span>
          <b>Owner</b>
          <span>
            &quot;), and is effective as of the date ownership of the Digital
            Collectible is transferred to the Owner (the &quot;
          </span>
          <b>Effective Date</b>
          <span>&quot;).</span>
        </p>
        <p>
          <span>
            OWNER ACKNOWLEDGES RECEIPT AND UNDERSTANDING OF THIS OWNER
            AGREEMENT, AND AGREES TO BE BOUND BY ITS TERMS, IN THEIR ENTIRETY.{' '}
          </span>
          <b>
            OWNER&#39;S ACCEPTANCE OF THE DIGITAL COLLECTIBLE SHALL BE DEEMED
            ACCEPTANCE OF AND CONSENT TO BE GOVERNED BY THE TERMS OF THIS
            AGREEMENT. IF OWNER DOES NOT AGREE TO BE BOUND BY THESE TERMS, THE
            LICENSE CONTEMPLATED HEREUNDER SHALL AUTOMATICALLY TERMINATE.
          </b>
        </p>
        <p>
          <span>
            In consideration of the premises and the mutual covenants set forth
            below and for other good and valuable consideration, the receipt and
            sufficiency of which is acknowledged, and intending to be bound, the
            parties agree as follows:
          </span>
        </p>
        <SHeading>1. Licenses &amp; Restrictions</SHeading>
        <SList>
          <SListItem>
            1.1. <u>Digital Collectible</u>
            <span>. The &quot;</span>
            <b>Digital Collectible</b>
            <span>
              &quot; sold or otherwise transferred to Owner pursuant to this
              Owner Agreement is a non-fungible token, ownership of which is
              registered on a blockchain. The Digital Collectible is associated
              with a single instance of a digital art or media file, or multiple
              pieces of digital artwork or media files licensed as a collection
              (each a &quot;
            </span>
            <b>Digital Object</b>
            <span>
              &quot;). As detailed below, Owner may own the Digital Collectible,
              but shall have only a limited license to the Digital Object, all
              subject to the terms and conditions of this Owner Agreement.
            </span>
          </SListItem>
          <SListItem>
            1.2. <u>Digital Object</u>
            <span>
              . The Digital Object is subject to copyright and other
              intellectual property protections, which rights are and shall
              remain owned by Licensor or third parties.
            </span>
          </SListItem>
          <SListItem>
            1.3. <u>License</u>
            <span>
              . Upon a valid transfer of the Digital Collectible to Owner,
              Licensor grants Owner a limited, non-sublicensable, license to
              use, publish, and display the Digital Object during the Term,
              subject to Owner&#39;s compliance with the terms and conditions of
              this Owner Agreement, including without limitation, the
              restrictions in Section 1.4 below, solely for the following
              purposes: (a) for his/her/its own personal, non-commercial use;
              (b) to list the Digital Object for resale or trade as part of an
              Digital Collectible marketplace that permits the purchase and sale
              of the Digital Collectible, provided that the marketplace
              cryptographically verifies each Digital Collectible owner&#39;s
              rights to display the Digital Object associated with the Digital
              Collectible to ensure that only the actual owner can display the
              Digital Object; and/or (c) as part of a third party website or
              application that permits the inclusion, involvement, or
              participation of Digital Collectibles, provided that the
              website/application cryptographically verifies each Digital
              Collectible owner&#39;s rights to display the Digital Object
              associated with the Digital Collectible to ensure that only the
              actual owner can display the Digital Object (the &quot;
            </span>
            <b>Owner License</b>
            <span>
              &quot;). The Owner license is non-transferable and non-
              sublicensable, except that it will automatically transfer in
              connection with a Permitted Transfer (defined below) of the
              Digital Collectible. Upon expiration of the Term or breach of any
              condition of this Owner Agreement by Owner, all license rights
              shall immediately terminate.
            </span>
          </SListItem>
          <SListItem>
            1.4. <u>License Restrictions</u>
            <span>
              . The Digital Object provided pursuant to this Owner Agreement is
              licensed, not sold, and Owner receives no title to or ownership of
              the Digital Object or any associated intellectual property rights
              thereto. Without limiting the foregoing, the license to the
              Digital Object does not grant Owner any right, title or interest
              in or to the individual layered files, traits or digital works
              associated with same (collectively, the &quot;
            </span>
            <b>Owner License</b>
            <span>
              &quot;). Except for the express license granted to Owner by the
              Owner License, no other rights (express or implied) to the Digital
              Object are granted and all rights that are not specifically
              granted to Owner are reserved by Licensor. This includes, but is
              not limited to, the intellectual property rights, and publicity
              rights, associated with the images, names, logos, Layered Files,
              trademarks, the ability to mint 3D, voxel or other versions of the
              Digital Collectible, the smart contract code, or anything else not
              specifically granted by the Owner License. Without limiting the
              generality of the foregoing, Owner shall not, nor authorize,
              permit or assist any third party to, do or attempt to do any of
              the following: (a) copy, modify, create derivative works from, or
              distribute the Digital Object or the Digital Collectible; (b)
              display the Digital Object other than as expressly licensed in
              Section 1.3; (c) use the Digital Collectible or Digital Object to
              advertise, market, or sell a product and/or service; (d)
              incorporate the Digital Collectible or Digital Object in videos or
              other media; (e) sell merchandise incorporating the Digital
              Collectible or Digital Object; (f) attempt to register any
              trademark, copyright, or otherwise acquire additional intellectual
              property rights in or to the Digital Object; (g) impose a fee or
              require other consideration for another to view the Digital
              Object; (h) use the Digital Object for its or any third
              party&#39;s commercial benefit; (i) use the Digital Object to
              create, sell or attempt to create or sell any new cryptographic
              token; or (j) fractionalize, pool, rent or stake the Digital
              Collectible or Digital Object, unless done so in compliance with
              all applicable laws, rules or regulations. Moreover, Owner agrees
              not to: (i) remove any copyright or other legal notices associated
              with the Digital Collectible or Digital Object; or (ii) remove or
              alter any metadata of the Digital Collectible, including without
              limitation, any link or other reference to this Owner Agreement.
              Failure to comply with the conditions set forth in Sections 1.3
              and 1.4 hereof constitutes a material breach of this Owner
              Agreement.
            </span>
          </SListItem>
          <SListItem>
            1.5. <u>Transfers</u>
            <span>
              . Subject to the transfer restrictions set forth herein, and
              payment to Licensor of the Resale Royalty (defined below), Owner
              may transfer ownership of the Digital Collectible, including
              without limitation, the Owner License to a third-party (a &quot;
            </span>
            <b>Secondary Acquirer</b>
            <span>
              &quot;), provided that: (a) Owner has not breached this Owner
              Agreement prior to the transfer; (b) the Owner License has not
              otherwise expired or terminated for any reason; (c) Owner notifies
              the Secondary Acquirer that any sale of the Digital Collectible by
              the Secondary Acquirer shall be subject to the Resale Royalty,
              such notice being automatic provided the sale is conducted on our
              digital marketplace located at marketplace.tunego.com and
              accessible via any corresponding TuneGO proprietary mobile
              application (altogether the &ldquo;Marketplace&rdquo;); (d) such
              transfer is conducted through a marketplace or other platform that
              cryptographically verifies that the Owner is the actual, current
              owner of the Digital Collectible; (e) such transfer complies with
              all applicable laws, rules, regulations, regulatory guidance, and
              terms of the marketplace or other platform on which such sale or
              transfer takes place; and (f) Owner ensures that the Secondary
              Acquirer is made aware of this Owner Agreement and agrees to be
              bound by its obligations and restrictions (a &quot;
            </span>
            <b>Permitted Transfer</b>
            <span>
              &quot;). Upon conclusion of a Permitted Transfer including
              acceptance of this Owner Agreement, Secondary Acquirer shall be
              deemed the Owner for purpose of this Owner Agreement. In no case
              shall the Owner License granted herein, or the Digital Object
              alone, be transferrable apart from ownership of the Digital
              Collectible. For clarity, only a single individual/entity may own
              the Digital Collectible at any time, and only that
              individual/entity possesses the corresponding Owner License.
              &nbsp;As used herein, the term &quot;Resale Royalty&quot; means
              the royalty amount Licensor shall receive for any sale of the
              Digital Collectible by the Owner, currently equal to 5% of the
              purchase price but subject to change from time to time at
              Licensor&rsquo;s election, taken from the Owner&#39;s or any
              Secondary Acquirer&#39;s (who becomes an Owner) sale proceeds.
              Owner shall pay any and all transaction fees, payment transfer
              fees and taxes (other than taxes on Licensor&#39;s net income)
              associated with the Permissible Transfer and shall not deduct any
              such amounts from the amount paid to Licensor as the Resale
              Royalty. To the extent a smart contract associated with the
              Digital Collectible is programmed to automatically transfer the
              Resale Royalty to Licensor, payment shall be deemed made when and
              if such Resale Royalty is actually paid to Licensor.
            </span>
          </SListItem>
        </SList>
        <SHeading>2. Warranty; Disclaimers and Assumption of Risk.</SHeading>
        <SList>
          <SListItem>
            2.1. <u>Owner Warranty</u>
            <span>
              . Owner represents and warrants that Owner: (a) is at least the
              age of majority in Owner&#39;s state of residence (which is
              typically 18 in most U.S. states) and has the legal capacity to
              enter into this Owner Agreement; (b) will use and interact with
              the Digital Collectible, including the Digital Object associated
              therewith, only for lawful purposes and in accordance with this
              Owner Agreement and all applicable laws, rules and regulations;
              and (c) will not use the Digital Collectible, including the
              Digital Object associated therewith, to violate any law,
              regulation or ordinance or any right of Licensor or any third
              party, including without limitation, any right of privacy,
              publicity, copyright, trademark, and/or patent.
            </span>
          </SListItem>
          <SListItem>
            2.2. <u>DISCLAIMER; ASSUMPTION OF RISK.</u>
            <span>
              &nbsp;THE DIGITAL COLLECTIBLE AND ASSOCIATED DIGITAL OBJECT ARE
              PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot;
              BASIS WITHOUT WARRANTY OR SUPPORT OF ANY KIND. TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, LICENSOR AND ITS OFFICERS,
              EMPLOYEES, MEMBERS, DIRECTORS, SHAREHOLDERS, PARENTS,
              SUBSIDIARIES, AFFILIATES, AGENTS, AND LICENSORS (COLLECTIVELY,
              &quot;COVERED PARTIES&quot;) DISCLAIM ANY AND ALL WARRANTIES,
              CONDITIONS, AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS,
              IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT
              AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF
              DEALING OR USAGE OF TRADE.
            </span>
            <p>
              <span>
                WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, AND TO THE
                MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, LICENSOR MAKES NO
                WARRANTY THAT THE DIGITAL COLLECTIBLE OR THE ASSOCIATED DIGITAL
                OBJECT WILL MEET OWNER&#39;S REQUIREMENTS OR BE AVAILABLE ON AN
                UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. FURTHER, LICENSOR
                PROVIDES NO WARRANTY REGARDING, AND WILL HAVE NO RESPONSIBILITY
                FOR, ANY CLAIM ARISING OUT OF: (A) A MODIFICATION OF THE DIGITAL
                COLLECTIBLE OR ASSOCIATED DIGITAL OBJECT MADE BY ANYONE OTHER
                THAN LICENSOR, UNLESS LICENSOR APPROVES SUCH MODIFICATION IN
                WRITING IN ADVANCE; (B) OWNER&#39;S MISUSE OF OR
                MISREPRESENTATION REGARDING THE DIGITAL COLLECTIBLE OR
                ASSOCIATED DIGITAL OBJECT; (C) THE FLUCTUATION IN VALUE OF THE
                DIGITAL COLLECTIBLE; AND/OR (D) ANY TECHNOLOGY THAT FAILS TO
                PERFORM OR CEASES TO EXIST.
              </span>
            </p>
            <p>
              <span>
                FURTHER, OWNER UNDERSTANDS AND AGREES THAT OWNERSHIP OF, AND
                TRANSACTIONS INVOLVING, THE DIGITAL COLLECTIBLE MAY REQUIRE OR
                RELY ON THIRD-PARTY TECHNOLOGY AND/OR PLATFORMS, INCLUDING
                WITHOUT LIMITATION DIGITAL WALLETS, BLOCKCHAINS OR RELATED
                TECHNOLOGY, SMART CONTRACTS, INTERNET SERVICE PROVIDERS,
                MARKETPLACES, EQUIPMENT OR OTHER INFRASTRUCTURE (COLLECTIVELY,
                &quot;THIRD PARTY TECHNOLOGY&quot;). OWNER UNDERSTANDS AND
                AGREES THAT THE EXISTENCE AND PERFORMANCE OF THIRD PARTY
                TECHNOLOGY ARE OUTSIDE OF LICENSOR&#39;S CONTROL. TO THE MAXIMUM
                EXTENT PERMISSIBLE UNDER APPLICABLE LAW, LICENSOR DISCLAIMS ALL
                LIABILITY FOR THE USE, UNAVAILABILITY, OR FAILURE OF ANY THIRD
                PARTY TECHNOLOGY.
              </span>
            </p>
            <p>
              <span>
                THE DIGITAL COLLECTIBLES ARE INTENDED AS COLLECTIBLE ITEMS FOR
                INDIVIDUAL ENJOYMENT ONLY. THEY ARE NOT MEANT AS INVESTMENT
                VEHICLES. LICENSOR MAKES ABSOLUTELY NO PROMISE OR GUARANTEE THAT
                THE DIGITAL COLLECTIBLES WILL INCREASE IN VALUE, OR MAINTAIN THE
                SAME VALUE AS THE AMOUNT OWNER PAID TO PURCHASE SAME. OWNER
                UNDERSTANDS AND AGREES THAT THE DIGITAL COLLECTIBLES HAVE NO
                INHERENT MONETARY VALUE, AND THEY SHOULD BE TREATED AS NOTHING
                MORE THAN COLLECTIBLES WITH POTENTIAL FUTURE VALUE OR LACK
                THEREOF. PLEASE BE AWARE THAT THE PRICES OF DIGITAL COLLECTIBLES
                ARE EXTREMELY VOLATILE, AND PRICE FLUCTUATIONS IN ETH COULD
                IMPACT THE PRICE OF DIGITAL COLLECTIBLES BOTH POSITIVELY AND
                NEGATIVELY. GIVEN THIS VOLATILITY, DIGITAL COLLECTIBLES SHOULD
                NOT BE CONSIDERED INVESTMENTS. OWNER ASSUMES ALL RISKS IN
                CONNECTION THEREWITH. NO INFORMATION AND/OR CONTENT MADE
                AVAILABLE BY AND/OR THROUGH THE SITE OR OTHERWISE BY LICENSOR IS
                OR SHOULD BE CONSIDERED ADVICE OR AN INVITATION TO ENTER INTO AN
                AGREEMENT FOR ANY INVESTMENT PURPOSE. FURTHER, NO ELEMENT OF THE
                DIGITAL COLLECTIBLES QUALIFIES OR IS INTENDED TO BE AN OFFERING
                OF SECURITIES IN ANY JURISDICTION, NOR DOES IT CONSTITUTE AN
                OFFER OR AN INVITATION TO PURCHASE SHARES, SECURITIES OR OTHER
                FINANCIAL PRODUCTS. OWNER IS SOLELY AND EXCLUSIVELY RESPONSIBLE
                FOR ASSURING THAT THE PURCHASE AND SALE OF DIGITAL COLLECTIBLES,
                AND THE USE OF CRYPTOCURRENCIES, COMPLIES THE WITH LAWS AND
                REGULATIONS IN OWNER&#39;S JURISDICTION. OWNER ASSUMES ALL RISKS
                ASSOCIATED WITH USING AN INTERNET-BASED CRYPTOCURRENCY
                INCLUDING, BUT NOT LIMITED TO, THE RISK OF HARDWARE, SOFTWARE
                AND INTERNET CONNECTIONS, THE RISK OF MALICIOUS SOFTWARE
                INTRODUCTION, AND THE RISK THAT THIRD PARTIES MAY OBTAIN
                UNAUTHORIZED ACCESS TO INFORMATION STORED WITHIN OWNER&#39;S
                WALLET. DIGITAL COLLECTIBLES, CRYPTOCURRENCIES AND BLOCKCHAIN
                TECHNOLOGY ARE RELATIVELY NEW AND THE REGULATORY LANDSCAPE IS
                UNSETTLED. NEW REGULATIONS COULD NEGATIVELY IMPACT SUCH
                TECHNOLOGIES WHICH MAY, IN TURN, IMPACT THE VALUE OF OWNER&#39;S
                DIGITAL COLLECTIBLES. OWNER UNDERSTANDS AND ACCEPTS ALL RISK IN
                THAT REGARD. WITHOUT LIMITING THE FOREGOING, OWNER ASSUMES ALL
                RESPONSIBILITY FOR ANY ADVERSE EFFECTS OF DISRUPTIONS OR OTHER
                ISSUES IMPACTING THE APPLICABLE CRYPTOCURRENCY AND/OR
                BLOCKCHAIN.
              </span>
            </p>
          </SListItem>
        </SList>
        <SHeading>3. Limitation of Liability; Indemnity.</SHeading>
        <SList>
          <SListItem>
            3.1. <u>Dollar Cap</u>
            <span>
              . TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COVERED
              PARTIES&#39; CUMULATIVE LIABILITY UNDER THIS OWNER AGREEMENT, FROM
              ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE
              LIMITED TO AND WILL NOT EXCEED THE GREATER OF: (A) THE AMOUNT PAID
              TO LICENSOR FOR THE INITIAL SALE OF THE DIGITAL COLLECTIBLE; OR
              (B) ONE HUNDRED DOLLARS ($100).
            </span>
          </SListItem>
          <SListItem>
            3.2. <u>Excluded Damages</u>
            <span>
              . TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              WILL THE COVERED PARTIES BE LIABLE UNDER ANY CLAIM ARISING OUT OF
              OR RELATED TO THIS OWNER AGREEMENT FOR ANY INDIRECT, INCIDENTAL,
              CONSEQUENTIAL, OR OTHER NONDIRECT DAMAGES OF ANY KIND OR FOR ANY
              SPECIAL, PUNITIVE OR SIMILAR DAMAGES OR EXPENSES (INCLUDING,
              WITHOUT LIMITATION, LOST PROFITS OR OTHER ECONOMIC LOSS, LOST
              REIMBURSEMENTS, LOST DATA, OR LOST SAVINGS), EVEN IF THE
              APPLICABLE COVERED PARTY(IES) WAS/WERE ADVISED OF THE POSSIBILITY
              OF THE OCCURRENCE OF SUCH DAMAGES.
            </span>
          </SListItem>
          <SListItem>
            3.3. <u>Clarifications &amp; Disclaimers</u>
            <span>
              . TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE
              LIABILITIES LIMITED BY THIS SECTION 3 APPLY: (a) TO LIABILITY FOR
              NEGLIGENCE; (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN
              CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN
              IF THE APPLICABLE COVERED PARTY(IES) IS/ARE ADVISED IN ADVANCE OF
              THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH
              DAMAGES WERE FORESEEABLE; AND (d) EVEN IF OWNER&#39;S REMEDIES
              FAIL OF THEIR ESSENTIAL PURPOSE. WITHOUT LIMITING THE GENERALITY
              OF THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
              LAW, THE COVERED PARTIES WILL NOT BE LIABLE TO OWNER FOR, ANY USE
              OF THE DIGITAL COLLECTIBLE, INCLUDING BUT NOT LIMITED TO ANY
              LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS
              FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
              MISTYPED WALLET ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III)
              CORRUPTED CRYPTOCURRENCY WALLET FILES; (IV) UNAUTHORIZED ACCESS TO
              THE DIGITAL COLLECTIBLE AND/OR ASSOCIATED DIGITAL OBJECT; (V) ANY
              UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION
              THE USE OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF
              ATTACK AGAINST A BLOCKCHAIN NETWORK UNDERLYING THE DIGITAL
              COLLECTIBLE AND/OR ASSOCIATED DIGITAL OBJECT; (VI) OWNER&#39;S
              FAILURE TO MAINTAIN THE SECURITY OF ITS WALLET&#39;S PRIVATE KEY;
              (VII) ANY UNINTENDED TRANSFER OR LOSS OF THE DIGITAL COLLECTIBLE
              OR ASSOCIATED DIGITAL OBJECT; (VIII) THE FAILURE TO REALIZE ANY
              SPECIFIC INVESTMENT INCOME, REVENUE OR OTHER FINANCIAL OUTCOME;
              AND (IX) OWNER&#39;S INABILITY TO DEMONSTRATE OWNERSHIP AND/OR
              CONTROL OF THE DIGITAL COLLECTIBLE. If applicable law limits the
              application of the provisions of this Section 4, Licensor&#39;s
              liability will be limited to the maximum extent permissible.
            </span>
          </SListItem>
          <SListItem>
            3.4. <u>Indemnity</u>
            <span>
              . Owner shall indemnify, defend and hold harmless the Covered
              Parties from and against any and all claims, demands, causes of
              action, liabilities, damages, penalties, losses, fines, costs and
              expenses (including reasonable attorneys&#39; fees, legal costs
              and court costs, which shall be reimbursed as incurred) arising
              out of, related to, or in connection with or alleging: (a)
              Owner&#39;s breach of any provision contained in this Owner
              Agreement, including but not limited to Owner&#39;s failure to
              comply with the licensing conditions set forth in Section 1
              hereof; (b) Owner&#39;s use of the Digital Collectible in any way
              that violates any applicable laws, rules or regulations; (c)
              Owner&#39;s unauthorized transfer, or purported transfer, of the
              Digital Collectible; or (d) Owner&#39;s improper and/or
              unauthorized exercise of any license rights in and/or to an
              Digital Collectible.
            </span>
          </SListItem>
        </SList>
        <SHeading>4. Term &amp; Termination.</SHeading>
        <SList>
          <SListItem>
            <span>
              4.1. Term. This Owner Agreement shall continue until terminated
              pursuant to the terms of this Owner Agreement (the &quot;
            </span>
            <b>Term</b>
            <span>&quot;).</span>
          </SListItem>
          <SListItem>
            4.2. <u>Termination for Transfer</u>
            <span>
              . Except for a Permitted Transfer effectuated in accordance with
              the terms of this Owner Agreement, including without limitation,
              Section 1.5 hereof, this Owner Agreement shall automatically
              terminate upon any purported transfer by the Owner. For clarity,
              any transfer of the Digital Collectible, other than a Permitted
              Transfer, terminates the Owner Agreement, including, without
              limitation, the Owner License, in its entirety.
            </span>
          </SListItem>
          <SListItem>
            4.3. <u>Termination for Cause</u>
            <span>
              . Licensor may terminate this Owner Agreement for Owner&#39;s
              material breach of any term of the Agreement by written notice
              specifying in detail the nature of the breach, effective in thirty
              (30) days unless the Owner first cures such breach, or effective
              immediately if the breach is not subject to cure.
            </span>
          </SListItem>
          <SListItem>
            4.4. <u>Effects of Termination</u>
            <span>
              . Upon termination of this Owner Agreement, Owner shall cease all
              use of the Digital Object and delete, destroy, or return all
              copies of the Digital Object in its possession or control. Owner
              acknowledges that any use of the Digital Object upon termination
              of the Owner License shall be deemed an intellectual property
              infringement. Any provision of this Owner Agreement that must
              survive to fulfill its essential purpose will survive termination
              or expiration, including without limitations Sections 3, 4 and 6.
            </span>
          </SListItem>
        </SList>
        <SHeading>5. Miscellaneous.</SHeading>
        <SList>
          <SListItem>
            5.1. <u>Independent Contractors</u>
            <span>
              . The parties are independent contractors and shall so represent
              themselves in all regards. Neither party is the agent of the
              other, and neither may make commitments on the other&#39;s behalf.
            </span>
          </SListItem>
          <SListItem>
            5.2. <u>Force Majeure</u>
            <span>
              . No delay, failure, or default, other than a failure to pay fees
              when due, will constitute a breach of this Owner Agreement to the
              extent caused by acts of war, terrorism, hurricanes, earthquakes,
              epidemics, other acts of God or of nature, strikes or other labor
              disputes, riots or other acts of civil disorder, embargoes,
              government orders responding to any of the foregoing, or other
              causes beyond the performing party&#39;s reasonable control.
            </span>
          </SListItem>
          <SListItem>
            5.3. <u>Assignment &amp; Successors</u>
            <span>
              . This Owner Agreement is not assignable or transferable by Owner,
              except pursuant to a Permitted Transfer of ownership of the
              Digital Collectible to a Secondary Acquirer, in which case the
              terms and conditions hereof will transfer and be binding upon and
              will inure to the benefit of the parties and their permitted
              successors and assigns. Any purported assignment or transfer by
              Owner in violation of this Section 5.3 is null and void.
            </span>
          </SListItem>
          <SListItem>
            5.4. <u>Severability</u>
            <span>
              . To the extent permitted by applicable law, the parties waive any
              provision of law that would render any clause of this Owner
              Agreement invalid or otherwise unenforceable in any respect. If
              any provision of this Owner Agreement is held to be invalid or
              otherwise unenforceable, such provision will be interpreted to
              fulfill its intended purpose to the maximum extent permitted by
              applicable law, and the remaining provisions of this Owner
              Agreement will continue in full force and effect.
            </span>
          </SListItem>
          <SListItem>
            5.5. <u>No Waiver</u>
            <span>
              . Neither party will be deemed to have waived any of its rights
              under this Owner Agreement by lapse of time or by any statement or
              representation other than by an authorized representative in an
              explicit written waiver. No waiver of a breach of this Owner
              Agreement will constitute a waiver of any other breach of this
              Owner Agreement.
            </span>
          </SListItem>
          <SListItem>
            5.6. <u>Choice of Law &amp; Jurisdiction</u>
            <span>
              . This Owner Agreement is governed by Delaware law and both
              parties submit to the exclusive jurisdiction of the state and
              federal courts located in Dover, Delaware and waive any right to
              challenge personal jurisdiction or venue.
            </span>
          </SListItem>
          <SListItem>
            5.7. <u>Entire Agreement</u>
            <span>
              . This Owner Agreement sets forth the entire agreement of the
              parties and supersedes all prior or contemporaneous writings,
              negotiations, and discussions with respect to its subject matter.
              Neither party has relied upon any such prior or contemporaneous
              communications.
            </span>
          </SListItem>
          <SListItem>
            5.8. <u>Amendment</u>
            <span>
              . This Owner Agreement may not be amended in any way except
              through a written agreement by authorized representatives of the
              Licensor and the current owner of the Digital Collectible.
            </span>
          </SListItem>
          <SListItem>
            5.9. <u>Headings</u>
            <span>
              . All section headings and captions have been inserted for
              convenience only and shall not affect the interpretation of the
              Agreement.
            </span>
          </SListItem>
        </SList>
      </SWrapper>
    </TermsWrapper>
  )
}
