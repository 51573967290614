import React from 'react'
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core'
import { string, bool, number } from 'prop-types'

const useStyles = makeStyles(theme => {
  const { palette, breakpoints, typography } = theme
  return {
    navigationItem: ({ stepsLength }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      width: `${100 / stepsLength}%`,
    }),
    logoIcon: {
      width: 15,
      marginBottom: 8,
    },
    navigationItemText: ({ isActive }) => ({
      color: isActive ? palette.color.primary : palette.color.darkGrey,
      fontSize: 14,
      fontFamily: isActive ? typography.bold : typography.fontFamily,
      textTransform: 'uppercase',
      [breakpoints.down('sm')]: {
        color: palette.color.secondary7B,
      },
    }),
    navigationMobileStepText: {
      color: palette.color.primary,
      fontSize: 14,
    },
    navigationItemFlex: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 16,
      width: '100%',
    },
  }
})

export const NavigationItem = ({ children, isActive, step, stepsLength }) => {
  const classes = useStyles({ stepsLength, isActive })
  const { breakpoints } = useTheme()
  const sm = useMediaQuery(breakpoints.down('sm'))

  if (sm && !isActive) return null

  return (
    <div className={classes.navigationItem}>
      <Hidden smDown>
        {isActive && (
          <img
            className={classes.logoIcon}
            src="/assets/images/tunego-static-icon.png"
            alt="TuneGO logo"
          />
        )}
      </Hidden>
      <div className={classes.navigationItemFlex}>
        {sm && (
          <>
            <Typography
              className={classes.navigationMobileStepText}
              variant="subtitle2"
            >
              Step {step} of {stepsLength}
            </Typography>
            &nbsp;
          </>
        )}
        <Typography className={classes.navigationItemText} variant="subtitle2">
          {sm && `- `}
          {children}
        </Typography>
      </div>
    </div>
  )
}

NavigationItem.propTypes = {
  children: string.isRequired,
  isActive: bool.isRequired,
  step: number.isRequired,
  stepsLength: number.isRequired,
}
