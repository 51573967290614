/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { S3Bucket } from './s3bucket'
import { getFileInfo } from '#utils/getFileInfo'

export class UsersApi {
  static getUsers = data => {
    return getApiClient().get(ENDPOINTS.USERS, data)
  }

  static getMyContacts = data => {
    return getApiClient().get(`${ENDPOINTS.USERS}?myContacts=true`, data)
  }

  static patchUsersMe = data => getApiClient().patch(ENDPOINTS.USERS_ME, data)

  static postUsersMeOnboard = () => getApiClient().post(ENDPOINTS.ONBOARDING)

  static postUsersMeUploadLink = (fileSuffix, fileSize) =>
    getApiClient().post(ENDPOINTS.UPLOAD_LINK, { fileSuffix, fileSize })

  static uploadAvatarHealthCheck = (uploadId, fileSuffix) =>
    getApiClient().post(ENDPOINTS.UPLOAD_HEALTH_CHECK, { uploadId, fileSuffix })

  static manageOnboard = async data => {
    try {
      await this.patchUsersMe(data)
      await this.postUsersMeOnboard()
    } catch (err) {
      throw err
    }
  }

  static manageUploadAvatar = async file => {
    const { fileSuffix, fileSize } = getFileInfo(file)
    try {
      const {
        data: { url, fields, uploadId },
      } = await this.postUsersMeUploadLink(fileSuffix, fileSize)
      await S3Bucket.uploadImg({
        url,
        file,
        fields,
      })
      await this.uploadAvatarHealthCheck(uploadId, fileSuffix)
    } catch (err) {
      throw err
    }
  }

  static putNewPassword = data =>
    getApiClient().put(ENDPOINTS.NEW_PASSWORD, data)

  static postVerifyPersona = data =>
    getApiClient().post(ENDPOINTS.PERSONA_VERIFY, data)

  static patchNftConfirmInfo = () =>
    getApiClient().patch(ENDPOINTS.CONFIRM_NFT_INFO)

  static setUserSource = data => getApiClient().post(ENDPOINTS.SOURCE, data)
}
