import React, { useEffect, useState } from 'react'
import { useFileCheck } from '#hooks/swr/useFileCheck'
import { arrayOf, shape, string } from 'prop-types'
import styled from 'styled-components'
import { FileCheck } from '#pages/vault/song/tabs/files/components/file-check'
import { useTranslation } from 'react-i18next'
import { ButtonSize } from '@tunego/tunego-ui-components'
import { useReload } from '#modules/page-wrapper/context'
import { CreateCollectibleButton } from '#pages/_components/create-collectible-button'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { FILE_PREVIEW_STATUS } from '#pages/vault/song/tabs/files/utils/constants'

const SWrapper = styled.div`
  display: flex;
  height: 3.75rem;
  padding: 0 1.25rem 0 2.5rem;
  align-items: center;
  justify-content: space-between;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_100};
`

const SInnerWrapper = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  flex-grow: 1;
`

export const FileChecks = ({ id, profileId, accessRoles, files }) => {
  const { shouldReload } = useReload()
  const { fileChecks, isLoading, mutate } = useFileCheck(id)
  const { t } = useTranslation('vault')
  const [processingInProgress, setProcesingInProgress] = useState(false)

  const filesProcessing = files.some(file =>
    [FILE_PREVIEW_STATUS.PROCESSING, FILE_PREVIEW_STATUS.PENDING].includes(
      file.previewStatus
    )
  )

  useEffect(() => {
    if (filesProcessing) {
      setProcesingInProgress(true)
    } else if (processingInProgress) {
      mutate()

      setProcesingInProgress(false)
    }
  }, [filesProcessing])

  const {
    containsValidTrack,
    containsValidArtwork,
    containsAnyTrack,
    containsAnyArtwork,
  } = fileChecks || {}

  const enableDrop = containsValidTrack && containsValidArtwork

  useEffect(() => {
    if (shouldReload) {
      mutate()
    }
  }, [shouldReload])

  return (
    <SWrapper>
      <SInnerWrapper>
        {!isLoading && (
          <>
            <FileCheck
              isValid={containsValidTrack}
              text={t('audioFilesRequired')}
              tooltipText={
                containsAnyTrack
                  ? t('audioTooltip')
                  : t('addAudioFileThatMeetsRequirements')
              }
            />
            <FileCheck
              isValid={containsValidArtwork}
              text={t('artworkRequired')}
              tooltipText={
                containsAnyArtwork
                  ? t('artworkTooltip')
                  : t('addArtworkFileThatMeetsRequirements')
              }
            />
          </>
        )}
      </SInnerWrapper>
      {accessRoles.includes(ACCESS_ROLES.OWNER) && (
        <CreateCollectibleButton
          buttonSize={ButtonSize.Sm}
          disabled={!enableDrop}
          profileId={profileId}
        >
          {t('scheduleDrop')}
        </CreateCollectibleButton>
      )}
    </SWrapper>
  )
}

FileChecks.propTypes = {
  id: string.isRequired,
  profileId: string.isRequired,
  accessRoles: arrayOf(string).isRequired,
  files: arrayOf(
    shape({
      previewStatus: string,
    })
  ).isRequired,
}
