import { useAuth0 } from '@auth0/auth0-react'
import { getRedirectParams } from '#utils/getRedirectParams'
import { isMobileWalletConnect } from '#utils/isMobileWalletConnect'

export const useLogin = (signup = false) => {
  const { loginWithRedirect } = useAuth0()

  const params = isMobileWalletConnect
    ? {
        redirectUri: window.location.href,
      }
    : getRedirectParams({
        ...(signup && { initialScreenTab: 'signup' }),
      })

  return () => loginWithRedirect(params)
}
