import React from 'react'
import styled from 'styled-components'
import { arrayOf, bool, number, shape, string } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'
import { BreadcrumbItem } from '#components/breadcrumbs/breadcrumb-item'

const SBreadcrumbsWrapper = styled.div`
  width: 100%;
  position: sticky;
  z-index: 100;
  top: 64px;
  left: 0;
  min-height: 60px;
  height: fit-content;
  border-bottom: 1px solid ${hexToRgba('#fff', 0.2)};
  background-color: ${({ theme: { colors } }) => colors.black};
`

const SBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  max-width: 1050px;
  min-height: 60px;
  margin: 0 auto;
`

export const Breadcrumbs = ({ breadcrumbs, disabled, className }) => {
  return (
    <SBreadcrumbsWrapper>
      <SBreadcrumbs className={className}>
        {breadcrumbs.map(breadcrumb => {
          return (
            <BreadcrumbItem
              key={breadcrumb.title}
              disabled={disabled}
              {...breadcrumb}
            />
          )
        })}
      </SBreadcrumbs>
    </SBreadcrumbsWrapper>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: arrayOf(shape({ title: string.isRequired }).isRequired)
    .isRequired,
  disabled: bool,
  className: number,
}

Breadcrumbs.defaultProps = {
  disabled: false,
  className: '',
}
