import React from 'react'
import { useTranslation } from 'react-i18next'
import { func, shape, string, arrayOf, bool } from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { IconSection } from './IconSection'

const useStyles = makeStyles(() => ({
  icons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1.5rem 0',
  },
}))

export const SubHeader = ({
  iconSectionData,
  headerTextTranslationKey,
  isMastering,
}) => {
  const classes = useStyles()
  const masteringTranslation = useTranslation('mastering')
  const videogoTranslation = useTranslation('videogo')

  const t = isMastering ? masteringTranslation.t : videogoTranslation.t

  return (
    <div>
      <div>{t(headerTextTranslationKey)}</div>
      <div className={classes.icons}>
        {iconSectionData.map(section => (
          <IconSection
            key={t(section.title)}
            iconToDisplay={section.icon}
            mainHeaderText={t(section.headerTranslationKey)}
            subHeaderText={t(section.subHeaderTranslationKey)}
          />
        ))}
      </div>
    </div>
  )
}

SubHeader.propTypes = {
  iconSectionData: arrayOf(
    shape({
      icon: string,
      title: string,
      header: string,
      subHeader: string,
    })
  ),
  containerStyles: func,
  headerTextTranslationKey: string,
  isMastering: bool,
}

SubHeader.defaultProps = {
  iconSectionData: [{ icon: '', title: '', header: '', subHeader: '' }],
  containerStyles: () => {},
  headerTextTranslationKey: '',
  isMastering: false,
}
