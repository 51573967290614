import React, { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useTranslation } from 'react-i18next'
import { useWallet } from '#hooks/swr/useWallet'
import { Button, ButtonSize } from '@tunego/tunego-ui-components'
import { useWalletSetup } from '#hooks/useWalletSetup'
import { WalletModal } from './components/wallet-modal'
import { useAuth0 } from '@auth0/auth0-react'
import { TYPEFORM_URL } from '#pages/sell-collectibles/landing-page/_utils/constants'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { oneOf, string, node, bool } from 'prop-types'

export const CreateCollectibleButton = ({
  children,
  className,
  disabled,
  profileId,
  ...buttonProps
}) => {
  const { t } = useTranslation('sellCollectibles')
  const { enableQuickListing, enableWalletConnect } = useFlags()

  const { user } = useAuth0()

  const { wallet, isLoading } = useWallet()
  const { isRoyaltyChecked, loading: royaltyCheckInProgress } = useWalletSetup(
    wallet?.address
  )
  const [modal, setModal] = useState(false)

  const onClick = () => {
    if (enableWalletConnect && !(wallet && isRoyaltyChecked)) {
      setModal(true)
    } else if (enableQuickListing) {
      const params = profileId ? `?personaId=${profileId}` : ''

      navigate(`${NAVIGATION_PATHS.CREATE_COLLECTIBLES}${params}`)
    } else window.open(`${TYPEFORM_URL}?email=${user?.email}`, '_blank')
  }

  return (
    <>
      <Button
        className={className}
        disabled={disabled || isLoading || royaltyCheckInProgress}
        {...buttonProps}
        onClick={onClick}
      >
        {children || t('sellYourCollectibles')}
      </Button>
      <WalletModal setIsOpen={setModal} isOpen={modal} />
    </>
  )
}

CreateCollectibleButton.propTypes = {
  children: oneOf([string, node]),
  buttonTheme: string,
  buttonSize: string,
  disabled: bool,
  className: string,
  profileId: string,
}

CreateCollectibleButton.defaultProps = {
  buttonSize: ButtonSize.Md,
}
