import React from 'react'
import { array, func, node, oneOfType } from 'prop-types'
import { useWizard } from '#modules/forms/form-wizard/context'
import { ChooseProfileStep } from '#pages/_modules/choose-profile-step'

export const ChooseProfileStepContainer = ({
  onStepSubmit,
  children,
  roles,
}) => {
  const { setStoredFormState } = useWizard()

  const onSubmit = values => {
    const payload = {
      parentId: values?.profile?.value,
      parentFilesCount: values?.profile?.filesCount,
    }

    setStoredFormState(payload)

    if (onStepSubmit) onStepSubmit(payload)
  }

  return (
    <ChooseProfileStep roles={roles} onSubmit={onSubmit}>
      {children}
    </ChooseProfileStep>
  )
}

ChooseProfileStepContainer.propTypes = {
  onStepSubmit: func,
  children: node,
  roles: oneOfType([array, null]),
}

ChooseProfileStepContainer.defaultProps = {
  onStepSubmit: null,
  children: null,
  roles: null,
}
