import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { number, func, arrayOf, object } from 'prop-types'
import { Pagination } from '#components/pagination'
import { ListItem } from '#pages/distro/past-releases/components/list-item'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.color.minorGrey,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.h2.fontFamily,
  },
}))

export const ListContent = ({
  pastReleases,
  count,
  page,
  setPage,
  setRowsPerPage,
  rowsPerPage,
}) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.wrapper}>
        {pastReleases.map(distro => (
          <ListItem key={distro.id} {...distro} />
        ))}
      </div>
      <Pagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={setPage}
        onChangeRowsPerPage={nextRowsPerPage => {
          setRowsPerPage(nextRowsPerPage)
          setPage(0)
        }}
      />
    </>
  )
}

ListContent.propTypes = {
  pastReleases: arrayOf(object).isRequired,
  count: number.isRequired,
  page: number.isRequired,
  setPage: func.isRequired,
  rowsPerPage: number.isRequired,
  setRowsPerPage: func.isRequired,
}
