import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { shape } from 'prop-types'
import { formatBytes } from '#pages/profile/utils/helpers'

const useStyles = makeStyles(() => ({
  quotasWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 20,
    width: '100%',
  },
  quota: {
    fontWeight: 700,
  },
  bullet: {
    margin: '0 7px',
  },
}))

export const SubscriptionPlanLimits = ({ subscriptionPlan }) => {
  const { t } = useTranslation('profile')
  const classes = useStyles()

  const planQuotas = subscriptionPlan && [
    {
      limit: subscriptionPlan?.limits['project-count']?.limit,
      text: 'songs',
    },
    {
      limit: formatBytes(subscriptionPlan?.limits['files-size']?.limit),
      text: 'storage',
    },
    {
      limit: subscriptionPlan?.limits['collaborator-count']?.limit || '',
      text: subscriptionPlan?.limits['collaborator-count']?.limit
        ? 'vaultCollaborators'
        : 'unlimitedVaultCollaborators',
    },
  ]

  const displayQuotas = planQuotas?.map((item, index) => {
    return (
      <>
        <span className={classes.quota}>{`${item.limit} ${t(item.text)}`}</span>
        {index < planQuotas.length - 1 && (
          <span className={classes.bullet}>&#8226;</span>
        )}
      </>
    )
  })

  return <div className={classes.quotasWrapper}>{displayQuotas}</div>
}

SubscriptionPlanLimits.propTypes = {
  subscriptionPlan: shape({}).isRequired,
}
