import React from 'react'
import { string } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'
import styled from 'styled-components'

const SDropzoneOverlay = styled.div`
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  padding: 8px;
  background: ${hexToRgba('#000', 0.4)};
  text-align: center;
  font-size: 20px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  line-height: 60px;
`

export const DropzoneOverlay = ({ description, className }) => {
  return (
    <SDropzoneOverlay className={className}>{description}</SDropzoneOverlay>
  )
}

DropzoneOverlay.propTypes = {
  description: string.isRequired,
  className: string,
}
