import '#modules/blockchain/flow/config'

import React, { useEffect, useState } from 'react'
// import '@tunego/tunego-ui-components/dist/styles/globalStyles.css'
import './App.css'
import jwtDecode from 'jwt-decode'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { CookiesProvider } from 'react-cookie'
import { bool, func } from 'prop-types'
import { SWRConfig, cache } from 'swr'
import mobile from 'is-mobile'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Auth0Api } from '#api/auth0'
import { Router } from '#routes/index'
import { GlobalErrorBoundary } from '#modules/global-error-boundary'
import { theme } from '#theme/theme'
import { swrFetcher } from '#api/api'
import { NAVIGATION_PATHS, ROUTES } from '#routes/routes'
import { UserProvider } from '#contexts/user'
import { GlobalAudioPlayerProvider } from '#modules/global-audio-player-provider'
import { ReloadContextProvider } from '#modules/page-wrapper/context'

// global styles for Audio player - must be placed after Audio player's imports to overwrite styles
import '#components/./file-audio-player/fileaudioplayer.scss'

import { API_URL } from '#api/api/static'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { socketInstance } from '#api/socketio'
import { useLocalStorage } from '#hooks/useLocalStorage'
import { getParam } from '#utils/getParam'
import { SystemProvider } from '#contexts/system'
import { BlockchainProvider } from '#modules/blockchain'
import { UploadManagerProvider } from '#modules/upload-manager/context'
import { MuiThemeProvider } from 'material-ui'
import { saveAndRedirectToMaintenancePage } from '../globals/helpers/maintenance'
import Theme from '#theme/index'
import { useAnalytics } from '#hooks/useAnalytics'
import { VaultProvider } from '#contexts/vault'
import { isMobileWalletConnect } from '#utils/isMobileWalletConnect'
import { UsersApi } from '#api/requests/users'
import { SOURCE_LOCAL_STORAGE_KEY } from '#constants/source'

// Early logout to prevent unnecessary backend calls and redirects
let wasAuthCleared = false
try {
  const auth = localStorage.getItem('auth')
  if (auth) {
    const decoded = JSON.parse(auth)
    const now = Date.now() / 1000
    if (now > decoded?.access_token_expiry ?? 0) {
      localStorage.removeItem('auth')
      cache.clear()
      wasAuthCleared = true
    }
  }
} catch (err) {
  // ignore
}
const maintenance = localStorage.getItem('maintenance')
if (maintenance) {
  saveAndRedirectToMaintenancePage(true)
}
export const App = props => {
  const { t } = useTranslation()
  const { redirectAfterLogin } = props
  const [mainError, setMainError] = useState(null)
  const [wasLogin, setWasLogin] = useState(false)
  const [source] = useLocalStorage(SOURCE_LOCAL_STORAGE_KEY)

  useAnalytics()

  const {
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0()
  if (wasAuthCleared) {
    wasAuthCleared = false
    logout({ localOnly: true })
    navigate(NAVIGATION_PATHS.HOME)
  }

  const getIdToken = async () => {
    const data = await getIdTokenClaims({
      audience: API_URL,
      scope: 'read:current_user',
    })
    // eslint-disable-next-line no-underscore-dangle
    return data.__raw
  }

  const getAccessToken = () => {
    return getAccessTokenSilently({
      audience: API_URL,
      scope:
        'offline_access openid profile email address phone read:current_user',
    })
  }

  const [isLocalStorageAuth, setIsLocalStorageAuth] = useLocalStorage('auth')
  useEffect(() => {
    const error = getParam('error')
    const errorDescription = getParam('error_description')

    if (error) {
      const [, auth0userId] = errorDescription.split(': ')
      if (auth0userId && errorDescription.startsWith('User not verified')) {
        UsersApi.setUserSource({ externalId: auth0userId, source })
        navigate(NAVIGATION_PATHS.VERIFY_EMAIL, {
          state: { userId: auth0userId },
        })
      } else {
        setMainError(errorDescription)
      }
    }
    if (isAuthenticated && isLocalStorageAuth && !wasLogin) {
      setWasLogin(true)
    }

    if (isAuthenticated && !isLocalStorageAuth && !error && !wasLogin) {
      setWasLogin(true)

      // eslint-disable-next-line no-inner-declarations
      const getTokens = async () => {
        const accessToken = await getAccessToken()
        const accessTokenDecoded = jwtDecode(accessToken)
        const accessTokenExpiry = accessTokenDecoded.exp
        const idToken = await getIdToken()
        const {
          data: { isOnboarded },
        } = await Auth0Api.loginApiRequest(idToken)
        setIsLocalStorageAuth(
          JSON.stringify({
            id_token: idToken,
            access_token: accessToken,
            access_token_expiry: accessTokenExpiry,
          })
        )
        if (isMobileWalletConnect) return

        if (redirectAfterLogin) {
          navigate(redirectAfterLogin)
        } else if (isOnboarded) {
          navigate(NAVIGATION_PATHS.SELL_COLLECTIBLES)
        } else {
          const origin = getParam('origin')
          const dispatchedOrigin = origin === null ? 'direct' : origin
          navigate(`${NAVIGATION_PATHS.ONBOARDING}?origin=${dispatchedOrigin}`)
        }
      }

      getTokens().then(() => {
        socketInstance.initialize()
      })
    } else if (isAuthenticated) {
      socketInstance.initialize()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLocalStorageAuth])

  useEffect(() => {
    if (
      mobile({ tablet: true }) &&
      window.location.pathname !== ROUTES.CONFIRM_EMAIL &&
      window.location.pathname !== ROUTES.VERIFY_EMAIL_MOBILE &&
      window.location.pathname !== ROUTES.VERIFY_EMAIL &&
      window.location.pathname !== ROUTES.CREATORS_VAULT_SETUP &&
      window.location.pathname !== ROUTES.DIRECT_SIGNUP &&
      window.location.pathname !== ROUTES.TERMS &&
      window.location.pathname !== ROUTES.OWNER_AGREEMENT &&
      window.location.pathname !== ROUTES.PRIVACY_POLICY &&
      !isMobileWalletConnect
    ) {
      navigate(NAVIGATION_PATHS.DOWNLOAD_MOBILE_APP, {
        state: {
          isVip: window.location.pathname === ROUTES.VIP_ENTRY,
        },
      })
    }
  }, [])
  const isAppLoading = (isAuthenticated && !isLocalStorageAuth) || isLoading

  return (
    <Theme>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider>
          <CookiesProvider>
            <CssBaseline />
            <SWRConfig
              value={{
                fetcher: swrFetcher,
              }}
            >
              <SystemProvider>
                <UserProvider>
                  <GlobalErrorBoundary>
                    <GlobalAudioPlayerProvider>
                      <ReloadContextProvider>
                        <UploadManagerProvider>
                          <BlockchainProvider>
                            <VaultProvider>
                              <Router />
                            </VaultProvider>
                          </BlockchainProvider>
                        </UploadManagerProvider>
                      </ReloadContextProvider>
                    </GlobalAudioPlayerProvider>
                  </GlobalErrorBoundary>
                </UserProvider>
              </SystemProvider>
            </SWRConfig>
            <CommonLoadingOverlay
              dialogOpen={isAppLoading}
              error={mainError}
              message={t('loadingTunego')}
            />
          </CookiesProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Theme>
  )
}

App.propTypes = {
  isDevMode: bool.isRequired,
  redirectAfterLogin: func.isRequired,
}
