import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { func, bool, string } from 'prop-types'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'

const SButtonGroup = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 10px;
  width: 100%;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
`

const SButton = styled(Button)`
  width: 130px;
  margin-right: 0;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
`

export const ProfileButtons = ({
  submitButtonTranslation,
  handleCancel,
  readyToCreate,
  hideCancel,
}) => {
  const { t } = useTranslation()

  return (
    <SButtonGroup centered={hideCancel}>
      <SButton type="submit" disabled={!readyToCreate}>
        {submitButtonTranslation}
      </SButton>
      {!hideCancel && (
        <SButton variant={VARIANTS.NEW_BLUE_SPECIAL} onClick={handleCancel}>
          {t('cancel')}
        </SButton>
      )}
    </SButtonGroup>
  )
}

ProfileButtons.propTypes = {
  handleCancel: func.isRequired,
  readyToCreate: bool.isRequired,
  submitButtonTranslation: string.isRequired,
  hideCancel: bool,
}
