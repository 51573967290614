import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Hero } from '#components/hero'
import { BolderTranslation } from '#components/bolder-translation'
import { NAVIGATION_PATHS } from '#routes/routes'

export const TopSection = () => {
  const { DISTRO_GET_STARTED, DISTRO_PAST_RELEASES } = NAVIGATION_PATHS
  const { t } = useTranslation('distro')

  return (
    <Hero
      header={t('sellYourMusic')}
      subheaderComponent={
        <Trans
          t={t}
          i18nKey="keep100ofYourSales"
          components={[<BolderTranslation key={0} />]}
        />
      }
      buttonOneUrl={DISTRO_GET_STARTED}
      buttonTwoUrl={DISTRO_PAST_RELEASES}
      buttonOneText={t('getStarted')}
      buttonTwoText={t('viewPastReleases')}
      bgUrl="/assets/images/distro-sales-background.png"
    />
  )
}
