import React, { useState } from 'react'
import { arrayOf, shape, string, bool, func, node } from 'prop-types'
import { ExpandableRow } from '#components/tree-select/_components/expandable-row'
import { Row } from '#components/tree-select/_components/row'
import styled from 'styled-components'

const SList = styled.ul`
  list-style: none;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  margin: 0;
  padding: 0;
  width: 100%;
`
export const TreeSelect = ({ options, fetchItems, className, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(null)
  const [expandedOption, setExpandedOption] = useState(null)

  const onSelect = option => {
    setSelectedOption(option)
    onChange(option)
  }

  const onExpand = option => {
    setExpandedOption(option.value === expandedOption?.value ? null : option)
  }

  return (
    <SList className={className}>
      {options.map((option, i) =>
        option.isExpandable ? (
          <ExpandableRow
            option={option}
            fetchItems={fetchItems}
            selectedOption={selectedOption}
            isEven={i % 2 === 0}
            isExpanded={expandedOption?.value === option.value}
            onExpand={onExpand}
            onSelect={onSelect}
          />
        ) : (
          <Row
            option={option}
            isSelected={option.value === selectedOption?.value}
            isEven={i % 2 === 0}
            onSelect={onSelect}
          />
        )
      )}
    </SList>
  )
}

TreeSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string,
      value: string,
      isExpandable: bool,
      icon: node,
    })
  ).isRequired,
  fetchItems: func,
  className: string,
  onChange: func,
}
