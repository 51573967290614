import React, { useState, useEffect } from 'react'
import { string, bool, func, arrayOf, shape } from 'prop-types'
import { RowWithLine } from '#modules/choose-file-tree/components/row-with-line'

import styled, { css } from 'styled-components'
import { RadioButton } from '#pages/_components/radio-button'
import { Tooltip } from '#components/tooltip'
import { LoadingIndicator } from '#components/loaders/loading-indicator'
import { FILE_PREVIEW_STATUS } from '#pages/vault/song/tabs/files/utils/constants'

const SImage = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 10px;
  margin-left: 65px;
  object-fit: cover;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

const SInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  ${({ marginLeft, nested }) => {
    let offset = 65
    if (!marginLeft) offset += 50
    if (nested) offset += 40

    return css`
      ${marginLeft && `margin-left: 65px`};
      width: calc(100% - ${offset}px);
      ${!nested && `padding-right: 2rem`}
    `
  }}
`

const STooltip = styled(Tooltip)`
  width: 100%;
`

const SFileName = styled.div`
  flex-grow: 1;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({
        theme: {
          tunego: { COLORS },
        },
      }) => COLORS.neutral_60};
    `}
`

const SRadioButton = styled(RadioButton)`
  flex-grow: 0;
  flex-shrink: 0;
`

const SLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  right: 0.5rem;
  top: 1.2rem;
`

export const FileRow = ({
  isEven,
  handleFileSelect,
  selectedFileId,
  selectedFilesIds,
  isImage,
  nested,
  isLastRow,
  isMainRowLast,
  storedFilesIds,
  parentId,
  disabledTooltipText,
  disabled,
  showProcessing,
  file,
}) => {
  const [isFileSelected, setIsFileSelected] = useState(false)
  const { name, id, thumbnail, previewStatus, hasMetadata } = file

  useEffect(() => {
    setIsFileSelected(selectedFileId === id || selectedFilesIds?.includes(id))
  }, [selectedFileId, selectedFilesIds, id])

  const isFileAlreadySelected = storedFilesIds && storedFilesIds?.includes(id)

  const onClick = () => {
    if (!disabled) handleFileSelect(file, parentId)
  }

  const showProcessingIndicator =
    showProcessing &&
    ([FILE_PREVIEW_STATUS.PENDING, FILE_PREVIEW_STATUS.PROCESSING].includes(
      previewStatus
    ) ||
      !hasMetadata)

  return (
    <RowWithLine
      disabled={disabled}
      isEven={isEven}
      isLastRow={nested ? isMainRowLast && isLastRow : isLastRow}
    >
      <STooltip
        text={disabledTooltipText}
        disabled={!disabled || !disabledTooltipText || showProcessingIndicator}
      >
        <RowWithLine
          isLastRow={isLastRow}
          isEven={isEven}
          nested={nested}
          disabled={disabled}
          isFileAlreadySelected={isFileAlreadySelected}
          onClick={onClick}
        >
          {isImage && thumbnail && (
            <SImage
              src={thumbnail}
              disabled={disabled}
              alt="PreviewContainer"
            />
          )}
          <SInnerWrapper marginLeft={!isImage} nested={nested}>
            <SRadioButton checked={isFileSelected} disabled={disabled} />
            <SFileName
              nested={nested}
              disabled={disabled}
              withThumbnail={isImage && thumbnail}
            >
              {name}
            </SFileName>
            {showProcessingIndicator && <SLoadingIndicator />}
          </SInnerWrapper>
        </RowWithLine>
      </STooltip>
    </RowWithLine>
  )
}

FileRow.propTypes = {
  isEven: bool.isRequired,
  isImage: bool.isRequired,
  handleFileSelect: func.isRequired,
  selectedFileId: string,
  selectedFilesIds: arrayOf(string),
  isLastRow: bool,
  isMainRowLast: bool,
  nested: bool,
  storedFilesIds: arrayOf(string),
  parentId: string,
  disabled: bool,
  disabledTooltipText: string,
  showProcessing: bool,
  file: shape({
    name: string,
    id: string,
    thumbnail: string,
    fileSuffix: string,
    previewStatus: string,
    hasMetadata: bool,
  }),
}

FileRow.defaultProps = {
  selectedFileId: null,
  selectedFilesIds: null,
  thumbnail: null,
  isLastRow: false,
  isMainRowLast: false,
  nested: false,
  storedFilesIds: [],
}
