import React from 'react'
import { UnauthenticatedPageWrapper } from '#modules/unauthenticated-page-wrapper'
import { PrivacyPolicyText } from '#pages/privacy-policy/components/privacy-policy-text'

export const PrivacyPolicy = () => {
  return (
    <UnauthenticatedPageWrapper>
      <PrivacyPolicyText />
    </UnauthenticatedPageWrapper>
  )
}
