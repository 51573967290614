import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Section } from '#components/section'
import { NAVIGATION_PATHS } from '#routes/routes'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 32,
  },
}))

export const BlogsAndPublicationsSection = () => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  return (
    <Section
      header={t('musicBlogsAndPublications')}
      getStartedUrl={NAVIGATION_PATHS.PROMOTION_NEW}
      bgUrl="/assets/images/blogs-and-publications.png"
      subheaderComponent={
        <>
          <div className={classes.header}>
            {t('blogsAndMusicPublicationsAreSome')}
          </div>
          <p>{t('didYouKnowMany')}</p>
        </>
      }
    />
  )
}

BlogsAndPublicationsSection.propTypes = {
  redirectLink: string.isRequired,
}
