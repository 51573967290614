import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from '@material-ui/core'
import { shape } from 'prop-types'
import { Auth0Api } from '#api/auth0'
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEmailVerification } from '#hooks/swr/useEmailVerification'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

const SVerifyWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SVerifyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const SVerifyYourAccount = styled.div`
  padding: 1rem;
  font-size: 1.25rem;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SYouMustVerify = styled.div`
  white-space: pre-line;
`

const SEmailWrapper = styled.div`
  margin: 1rem 0;
`

const SEmail = styled.div`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_70};
  font-size: 1rem;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  padding: 1.25rem;
`

const SIssueWrapper = styled.div`
  width: 100%;
  white-space: pre-line;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  padding: 1.25rem;
  text-align: center;
`

const SBold = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const VerifyYourAccount = props => {
  const { t } = useTranslation('onboarding')
  const [sending, setSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const { logout } = useAuth0()
  const [trialsCount, setTrialsCount] = useState(0)
  const { verificationPollingInterval } = useFlags()

  const {
    location: {
      state: { userId },
    },
  } = props

  const clearCookies = () => {
    localStorage.clear()
    logout({ returnTo: window.location.origin })
  }

  const { data } = useEmailVerification(userId, verificationPollingInterval)

  useEffect(() => {
    if (data?.emailVerified) navigate(NAVIGATION_PATHS.HOME)
  }, [data])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (emailSent) setEmailSent(false)
    }, 30000)
    return () => clearTimeout(timer)
  }, [emailSent])

  const handleResendEmail = async () => {
    if (sending) return
    try {
      setSending(true)
      await Auth0Api.emailResendRequest(userId)
      setEmailSent(true)
      setTrialsCount(trials => trials + 1)
    } finally {
      setSending(false)
    }
  }

  return (
    <SVerifyWrapper>
      <SVerifyContent>
        <img src="/assets/svg/ic-tunego-logo.svg" width="200px" alt="" />
        <SVerifyYourAccount>{t('verifyYourAccount')}</SVerifyYourAccount>
        <SYouMustVerify>{t('youMustVerifyYourEmailTo')}</SYouMustVerify>
        <SEmailWrapper>
          {emailSent || trialsCount >= 3 ? (
            <SEmail>{t('emailSent')}</SEmail>
          ) : (
            <Link
              underline="always"
              color="primary"
              component="button"
              variant="body2"
              onClick={handleResendEmail}
            >
              {t('clickHereToResend')}
            </Link>
          )}
        </SEmailWrapper>
      </SVerifyContent>
      <SIssueWrapper>
        <SBold>{t('havingIssues')}</SBold>{' '}
        <Link
          href="mailTo:support@tunego.com"
          underline="always"
          color="primary"
          variant="button"
        >
          {t('clickHere')}
        </Link>{' '}
        {t('toSpeakWithOurRockstar')}{' '}
        <Link
          underline="always"
          color="primary"
          variant="button"
          onClick={clearCookies}
        >
          {t('clickHere')}
        </Link>{' '}
        {t('to')}
        {t('clearYourTuneGoCookies')}
      </SIssueWrapper>
    </SVerifyWrapper>
  )
}

VerifyYourAccount.propTypes = {
  location: shape({}).isRequired,
}
