import React, { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, bool, string, number } from 'prop-types'
import * as d3 from 'd3'
import { useWindowSize } from '#hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  circleLabels: {
    fontSize: '18px',
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    fill: theme.palette.color.white,
  },
  legendLabels: {
    fontSize: '14px',
    fill: theme.palette.color.white,
  },
  lagendLabelFirst: {
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    fill: theme.palette.color.white,
  },
}))

export const CircularGraph = ({
  data,
  showLabels,
  unit = '',
  legendPosition = 'right',
  colorRange = [],
  align = 'center',
  legendYSpaceBetween = 40,
  legendXSpaceBetween = 140,
  legendYMargin = 30,
  legendXMargin = 70,
  marginY = 120,
  marginX = 80,
  total,
}) => {
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()

  const [chartSize, setChartSize] = useState(null)
  const totalNum =
    total ||
    data.reduce((sum, obj) => {
      // eslint-disable-next-line no-param-reassign,no-return-assign
      return (sum += Number(obj[Object.keys(obj)[0]]))
    }, 0)
  const preparedData = data.map(obj => ({
    key: Object.keys(obj)[0],
    value: ((obj[Object.keys(obj)[0]] / totalNum) * 100).toFixed(0),
  }))
  const calculateWithMargin = (value, margin) => value - 2 * margin

  const svgContainerRefMeasured = useCallback(
    node => {
      if (node !== null) {
        svgContainerRef.current = node
        setChartSize([
          calculateWithMargin(node.parentElement.offsetWidth, marginX),
          calculateWithMargin(node.parentElement.offsetHeight, marginY),
        ])
      }
    },
    [marginX, marginY]
  )

  useEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(
          svgContainerRef.current.parentElement.offsetWidth,
          marginX
        ),
        calculateWithMargin(
          svgContainerRef.current.parentElement.offsetHeight,
          marginY
        ),
      ]

    setChartSize(getContainerSize())
  }, [windowSize, marginX, marginY])

  const draw = () => {
    if (!chartSize) {
      return
    }

    // reset
    d3.select(svgContainerRef.current).select('svg').remove()

    // radius
    const radius = Math.min(chartSize[0], chartSize[1]) * (2 / 3)

    // color scale from props
    const colorScale = d3
      .scaleOrdinal()
      .domain(preparedData.sort((a, b) => b.value - a.value).map(d => d.key))
      .range(colorRange)

    // position helper consts - needed for calculate right place
    // eslint-disable-next-line no-shadow
    const resolveXCenter = align => {
      if (align === 'left') {
        return radius
      }
      if (align === 'right') {
        return chartSize[0] - radius
      }
      return chartSize[0] / 2
    }
    const xCenter = resolveXCenter(align)

    const yCenter = chartSize[1] / 2
    const willRightLegendFit =
      legendPosition === 'right' && chartSize[0] - (xCenter + radius) >= 250
    const willLeftLegendFit =
      legendPosition === 'left' && xCenter + radius >= 450

    // calculate legend X cords
    const legendPositionX = () => {
      if (legendPosition === 'right') {
        return willRightLegendFit ? xCenter + radius + legendXMargin : xCenter
      }
      if (legendPosition === 'left') {
        return willLeftLegendFit
          ? xCenter - radius - legendXMargin - 200
          : xCenter
      }
      return xCenter - (preparedData.length / 2) * legendXSpaceBetween
    }

    // calculate legend Y cords
    const legendPositionY = () => {
      if (legendPosition === 'right' || legendPosition === 'left') {
        return willRightLegendFit || willLeftLegendFit
          ? 0
          : yCenter + radius + legendYMargin
      }
      return yCenter + radius + legendYMargin
    }

    // calculate tips iteration cords
    const calculateLegendTipsPosition = (d, i) => {
      if (legendPosition === 'right' || legendPosition === 'left') {
        if (willRightLegendFit || willLeftLegendFit) {
          return `translate(0, ${yCenter - 50 + legendYSpaceBetween * i})`
        }
        return `translate(${-70}, ${legendYSpaceBetween * i})`
      }
      return `translate(${legendXSpaceBetween * i}, 0)`
    }

    // SVG Chart init
    const chart = d3
      .select(svgContainerRef.current)
      .append('svg')
      .attr('width', chartSize[0] + 2 * marginX)
      .attr('height', chartSize[1] + 2 * marginY)
      .append('g')
      .attr('transform', `translate(${marginX}, ${marginY / 2})`)

    // pie chart init
    const pie = d3
      .pie()
      .value(d => d.value)
      .sort((a, b) => b.value - a.value)

    // prepare data
    const dataReady = pie(preparedData)

    // arc with radius
    const chartArc = d3
      .arc()
      .innerRadius(0.6 * radius)
      .outerRadius(radius)

    // arc used for legend cords
    const labelArcs = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius + 80)

    chart
      .selectAll()
      .data(dataReady)
      .enter()
      .append('path')
      .attr('d', chartArc)
      .attr('fill', d => colorScale(d.data.key))
      .attr('stroke', 'black')
      .attr('transform', `translate(${xCenter}, ${yCenter})`)
      .style('stroke-width', '2px')

    const text = chart
      .append('g')
      .attr('transform', `translate(${xCenter}, ${yCenter})`)
      .selectAll('text')
      .data(dataReady)
      .join('text')
      .attr('transform', d => `translate(${labelArcs.centroid(d)})`)
      .attr('text-anchor', 'middle')

    if (showLabels) {
      text
        .selectAll('tspan')
        .data(d => [d.data.type, `${d.data.value}${unit}`])
        .join('tspan')
        .attr('x', 0)
        .attr('class', classes.circleLabels)
        .attr('dy', (d, i) => (i ? '1em' : 0))
        .text(d => d)
    }

    const legend = chart
      .append('g')
      .attr('class', 'legend')
      .attr(
        'transform',
        `translate(${legendPositionX()}, ${legendPositionY()})`
      )

    const lg = legend
      .selectAll('g')
      .data(dataReady)
      .enter()
      .append('g')
      .attr('class', 'legendGroup')
      .attr('transform', (d, i) => calculateLegendTipsPosition(d, i))

    lg.append('rect')
      .attr('fill', d => {
        return colorScale(d.data.key)
      })
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', 20)
      .append('title')
      .html(d => d.data[1])

    lg.append('text')
      .attr('x', 17.5 + 10)
      .attr('y', 15)
      .attr(
        'class',
        (d, i) =>
          `${i === 0 && classes.lagendLabelFirst} ${classes.legendLabels}`
      )
      .text(d => d.data.key)
  }

  useEffect(() => {
    draw() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSize, data, showLabels, unit])

  return (
    <div
      ref={svgContainerRefMeasured}
      id="chartWrapper"
      className={classes.chartWrapper}
    />
  )
}

CircularGraph.propTypes = {
  data: arrayOf(string).isRequired,
  showLabels: bool,
  unit: string,
  legendPosition: string,
  colorRange: arrayOf(string).isRequired,
  align: string,
  legendYSpaceBetween: number,
  total: number,
  legendXSpaceBetween: number,
  legendYMargin: number,
  legendXMargin: number,
  marginX: number,
  marginY: number,
}

CircularGraph.defaultProps = {
  showLabels: false,
  unit: '',
  legendPosition: '',
  align: '',
  legendYSpaceBetween: 0,
  total: 0,
  legendXSpaceBetween: 0,
  legendYMargin: 0,
  legendXMargin: 0,
  marginX: 0,
  marginY: 0,
}
