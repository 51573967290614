/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { TermsWrapper } from '#pages/_components/terms-wrapper'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    color: '#fff',
    fontSize: '18px',
    lineHeight: '1.5',
    paddingBottom: '70px',
    fontFamily: '"AvenirNextLTPro-Regular", "Arial", sans-serif',
    fontWeight: 400,
  },
  header: {
    textAlign: 'left',
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    fontSize: '32px',
    margin: '22px 0',
  },
  subheader: {
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 16,
  },
  list: {
    '& li': {
      textAlign: 'left',
    },
  },
  sublist: {
    paddingLeft: 40,
  },
  subsublist: {
    paddingLeft: 40,
  },
  bold: {
    fontFamily: 'AvenirNextLTPro-Bold',
    fontWeight: 700,
  },
  paragraph: {
    marginTop: 16,
  },
  underline: {
    textDecoration: 'underline',
  },
}))

export const DistroTermsText = () => {
  const classes = useStyles()

  return (
    <TermsWrapper>
      <div className={classes.wrapper}>
        <h1 id="tunego-terms-of-use" className={classes.header}>
          TuneGO Distro100 Service Terms and Conditions
        </h1>
        <span className={classes.subheader}>
          <strong>Posted: June 10, 2022</strong>
        </span>
        <p>
          Please read these TuneGO Distro100 Terms and Conditions (this "
          <em>
            <strong>Agreement</strong>
          </em>
          ") carefully, as it constitutes a legal agreement between you and
          TuneGO, Inc. ("
          <em>
            <strong>TuneGO</strong>
          </em>
          ,"{' '}
          <em>
            <strong>we</strong>
          </em>
          ," "
          <em>
            <strong>our</strong>
          </em>
          ," "
          <em>
            <strong>us</strong>
          </em>
          ", etc.) This Agreement is incorporated into and forms part of the
          TuneGO Terms of Service ("
          <em>
            <Link to={NAVIGATION_PATHS.TERMS}>Terms of Service</Link>
          </em>
          "). All capitalized terms not defined in this Agreement are defined in
          the Terms of Service. Your use of the TuneGO Distro100 digital
          distribution service (the "
          <em>
            <strong>Distro100 Services</strong>
          </em>
          ") is governed by and subject to this Agreement, the Terms of Service
          and the TuneGO Privacy Policy ("
          <em>
            <Link to={NAVIGATION_PATHS.PRIVACY_POLICY}>Privacy Policy</Link>
          </em>
          ").{' '}
        </p>
        <p>
          <strong className={classes.bold}>
            BY CLICKING "I AGREE" OR BY USING THE DISTRO100 SERVICES, YOU AGREE
            TO THIS AGREEMENT AND CREATE A BINDING AND LEGALLY ENFORCEABLE
            CONTRACT BETWEEN YOU AND US, WHETHER YOU ARE ACTING IN YOUR
            INDIVIDUAL CAPACITY OR AS THE AUTHORIZED REPRESENTATIVE FOR AN
            ARTIST, BAND, GROUP OR COMPANY, IN WHICH CASE "YOU" SHALL REFER TO
            THE ARTIST, BAND, GROUP OR COMPANY ON WHOSE BEHALF YOU ARE ACTING
            AND AUTHORIZED TO ACT. PLEASE READ THIS AGREEMENT CAREFULLY AND
            CONSULT WITH YOUR BUSINESS AND LEGAL ADVISORS BEFORE CLICKING "I
            AGREE." IF YOU DO NOT ACCEPT THIS AGREEMENT, THE TERMS OF SERVICE
            AND THE PRIVACY POLICY IN THEIR ENTIRETY, YOU ARE NOT PERMITTED TO
            ACCESS OR USE THE DISTRO100 SERVICES.
          </strong>
        </p>
        <p>
          TuneGO reserves the sole right at any time to modify, discontinue or
          terminate the Distro100 Services, or modify this Agreement, the{' '}
          <Link to={NAVIGATION_PATHS.TERMS}>"Terms of Service"</Link> and the{' '}
          <Link to={NAVIGATION_PATHS.PRIVACY_POLICY}>"Privacy Policy"</Link>{' '}
          without notice, so we recommend that you check back periodically for
          changes. By continuing to use or access the Site, App and/or Distro100
          Services after TuneGO makes and posts any such modification, you agree
          to be legally bound by the revised terms and conditions.
        </p>
        <ol type="1">
          <li>
            <p>
              <strong className={classes.bold}>Grant of Rights.</strong>
            </p>
            <p>
              Subject to the terms and conditions in this Agreement, the
              Distro100 Service enables you to submit your musical works and
              related content to TuneGO for distribution on certain online music
              services (e.g., iTunes, Spotify, etc.). As used in this Agreement,
              the term "Content" means the sound recordings, video content and
              audiovisual works, and the musical works embodied in any of the
              foregoing, and any album related artwork, photos, liner notes,
              metadata and other material related to any of the foregoing, that,
              in each case, you have provided to TuneGO, either directly or via
              a third party acting on your behalf. You will have the opportunity
              to select, from a list presented to you on your TuneGO account,
              the territories in which you want to make the Content available
              (the selected territories, collectively, the "Territory
              collectively, the "Consumer Services").
            </p>
            <p>
              You irrevocably grant to TuneGO the right and license, during the
              Term (as defined in Section 8 below) and throughout the Territory,
              to:
            </p>
            <ol type="A" className={classes.subsublist}>
              <li>
                <p>
                  supply, stream, perform, communicate to the public, make
                  available, display, synchronize, distribute, reproduce and
                  otherwise exploit the Content on the Consumer Services,
                  including by means of permanent digital downloads, temporary
                  digital downloads, so-called "conditional" downloads (whether
                  tethered to a device, time limited, play limited or
                  otherwise), interactive streaming and non-interactive
                  streaming, cloud services and any other means or media
                  (whether now known or existing in the future) without the
                  payment of any fees or royalties to any third party that may
                  have any rights in and to the Content (including songwriters,
                  composers, music publishers, performing artist(s), record
                  labels or performing rights organizations and unions or
                  guilds);
                </p>
              </li>
              <li>
                <p>
                  receive payments, administer and distribute payments and
                  royalties in relation to any and all income related to the
                  exploitation and/or performance of the Content on the Consumer
                  Services;
                </p>
              </li>
              <li>
                <p>
                  create digital master files and reproduce, convert, adapt,
                  encode and transcribe the Content and perform metadata
                  corrections (including spelling, genre correction) where
                  necessary; to store the Content in digital form on storage
                  servers for the purposes of creating different compressed
                  versions using different codecs; and to create specific ID
                  files, including through fingerprinting, to identify the
                  Content on the Consumer Services' servers;
                </p>
              </li>
              <li>
                <p>
                  to use the name(s), photographs and likenesses, artwork,
                  images, biographical and other information provided by you
                  (e.g., regarding the artists, bands, producers and/or
                  songwriters, whose performances or works are embodied in any
                  of the Content, etc.), in any marketing materials for, or
                  otherwise in connection with, the sale, promotion, and
                  advertising of the Content under the terms of this Agreement
                  and in connection with TuneGO's general business;
                </p>
              </li>
              <li>
                <p>
                  release, advertise, and/or sell electronic files or equivalent
                  electronic form(s) of the Content and to permit others to do
                  so under the trademark "TuneGO", "Distro100" or under any
                  trademark used by TuneGO;
                </p>
              </li>
              <li>
                <p>
                  the right to market, promote, and advertise the Content as
                  available for purchase or license, in any and all media,
                  whether now known or hereafter developed;
                </p>
              </li>
              <li>
                <p>
                  to make and perform clips of the Content up to ninety (90)
                  seconds in length via streaming or download free of charge, to
                  promote the Content (including the sale or other exploitation
                  thereof) and/or the applicable artists or performers thereof;
                  and{' '}
                </p>
              </li>
              <li>
                <p>
                  the right to sublicense any and all of the foregoing rights to
                  the TuneGO Partners and/or otherwise authorize them to perform
                  any one or more of the activities specified above. As used in
                  this Agreement, "TuneGO Partner" means any third party
                  licensee that we may authorize to conduct the marketing,
                  distribution, licensing, and sale or other use of the Content
                  pursuant to the terms of this Agreement. In relation to the
                  rights, remedies, and benefits granted hereunder, and the
                  representations and warranties in this Agreement, the term
                  "TuneGO Partners" is deemed to include the TuneGO Partners'
                  licensees and each of their respective successors and assigns.
                </p>
              </li>
            </ol>
            <p>
              For clarity, the rights granted above are granted to TuneGO on an
              exclusive basis as they relate to the specific Consumer Services
              that you select for distribution (as described below). For
              example, if you select a particular Consumer Service for
              distribution of certain Content, you may not license (or authorize
              a third party to license) that same Content to that same Consumer
              Service.
            </p>
          </li>
          <li>
            <p>
              <strong className={classes.bold}>
                Your Account and Content.
              </strong>
            </p>
            <p>
              Subject to our rights hereunder or under any prior agreement
              between you and us, as between you and us, you own all right,
              title, and interest in and to the Content and all copyrights and
              equivalent rights embodied therein.
            </p>
            <p>
              You agree that the Content will be provided to TuneGO in the
              formats required by TuneGO and will otherwise comply with TuneGO's
              technical requirements and specifications (as may be updated by
              TuneGO in its sole discretion), which will be made available to
              you via your Distro100 account. TuneGO, in its sole discretion,
              reserves the right to reject any materials (including, without
              limitation, sound recordings, images and/or artwork) that you
              submit. You are solely responsible for uploading into your
              Distro100 account accurate and complete metadata for all Content,
              as well as album artwork, liner notes and all other information
              and materials required for distribution. It is your responsibility
              to deliver all necessary information, metadata, songs, graphic
              files or any other information or music in the format required.
              You acknowledge that any obligation of TuneGO to furnish any
              services hereunder will be subject to its receipt of all necessary
              materials.{' '}
            </p>
            <p>
              You agree that you will not use the Distro100 Services to
              transmit, route, provide connections to or store any material that
              infringes copyrighted works or otherwise violates or promotes the
              violation of the intellectual property rights of any third party.
              You acknowledge and agree that TuneGO may disable access to and/or
              terminate your account and/or remove or disable access to any
              Content in the event that TuneGO receives any claim that, if true,
              would constitute a violation of your representations and
              warranties hereunder. Please see the Copyright Policy (which is
              set forth in the{' '}
              <Link to={NAVIGATION_PATHS.TERMS}>Terms of Service</Link>) for
              further information.{' '}
            </p>
          </li>
          <li>
            <p className={classes.bold}>
              <strong className={classes.bold}>Third-party Obligations.</strong>
            </p>
            <p>
              You will be solely responsible for securing, maintaining and
              paying for any and all clearances or licenses required in the
              Territory for the use of the Content as contemplated herein,
              including, without limitation, with respect to any musical
              compositions and sound recordings embodied in the Content, any and
              all digital phonorecord delivery, mechanical, public performance
              and any other licenses and/or clearances required in the Territory
              from musical composition copyright owners or their agents in
              connection with TuneGO's and any of its licensee's exploitation of
              rights hereunder, as well as royalties due to artists, producers
              and other persons who performed in the making of the Content.
              Without limiting the foregoing, you represent, warrant and
              covenant that you (i) have and will maintain the full right and
              authority to enter into this Agreement and grant the rights set
              forth herein without the consent of any third parties (including
              any music publisher and/or record label), free from any claims and
              encumbrances; (ii) will be solely responsible for procuring and
              maintaining all third party rights, licenses and clearances
              associated with the exploitation of the Content, including public
              performance rights, rights and licenses which inure or may inure
              to the benefit of composers, authors and publishers of music;
              (iii) will timely pay any royalties, fees and other sums due in
              connection with the exploitation of rights granted hereunder to
              any applicable third party rights holders (including, without
              limitation, mechanical royalties, amounts due to any performing
              rights organization, publishers, co-publishers, artists (featured
              and non-featured), writers, co-writers, authors, co-authors,
              copyright owners and co-owners, producers, engineers, and any
              other record royalty participants); and (iv) will be solely
              responsible for all payments that may be required under collective
              bargaining agreements applicable to you or any third party (e.g.,
              to unions or guilds such as AFM or AFTRA) or pursuant to any
              statutory schemes including residuals or re-use fees. You agree
              that the amount payable to you under this Agreement is inclusive
              of any so called "artist royalties" that might otherwise be
              required to be paid for sales or exploitations pursuant to the
              applicable laws of any jurisdiction and for any public
              performances, public displays or communications to the public of
              the sound recordings and musical works constituting the Content.
            </p>
          </li>
          <li>
            <p className={classes.bold}>
              <strong className={classes.bold}>Payments.</strong>
            </p>
            <p>
              Provided that you are not in material breach of this Agreement,
              TuneGO will pay you one-hundred percent (100%) of Net Receipts as
              set forth in this Section 4. "Net Receipts" means amounts actually
              received by TuneGO from third parties in connection with the sale
              or other exploitation of the Content on the Consumer Services,
              less any applicable taxes.
              <br />
              <br /> The Net Receipts owed to you hereunder will be credited to
              your TuneGO account. Once payment has been credited to your TuneGO
              account, you will be able to withdraw all or any portion of such
              funds at your discretion. Any bank charges, payment processing
              fees or other amounts charged in connection with such withdrawal
              and transfer of funds will be deducted from the amount payable to
              you. In order to withdraw funds from your account, the total
              amount in your account must exceed the amount of all such
              withdrawal/payment transfer charges. If you have any objection to
              any accounting statement we provide, you have one year from the
              date the statement is rendered to raise such objection and
              commence a lawsuit, otherwise you waive your right to object
              and/or bring a lawsuit. You hereby waive any longer statute of
              limitations that may be permitted by law.
              <br />
              <br /> The Net Receipts payable to you hereunder will be pooled in
              an interest-bearing bank account with the Net Receipts of other
              TuneGO customers. You agree that you will not receive interest or
              other earnings on the Net Receipts that TuneGO handles as your
              agent and places in such pooled account. In consideration for your
              use of the Distro100 Services, you irrevocably transfer and assign
              to TuneGO any ownership right that you may have in any interest
              that may accrue on Net Receipts held in such pooled account. In
              addition to or instead of earning interest on such pooled account,
              TuneGO may receive a reduction in fees or expenses charged for
              banking services by the banks that hold the Net Receipts payable
              to you hereunder. You agree that our payment obligations to you
              hereunder are subject to our receipt of any tax documentation
              reasonably requested by us (e.g., W-9).
              <br />
              <br /> If we have a good faith belief that your account has been
              subjected to and/or involved in fraudulent or infringing
              activities, we reserve the right to stop crediting Net Receipts to
              your account and block your ability to withdraw funds until the
              matter is resolved to our reasonable satisfaction. You agree that
              such revenues will be forfeited by you if we determine, in our
              good faith discretion, that they are the result of fraud and/or
              infringement. In addition to our other remedies, we may deduct
              from any monies otherwise payable to you hereunder any costs
              incurred by us (including legal fees and expenses) in connection
              with any fraudulent and/or infringing activities caused by your or
              your affiliates actions or omissions. You acknowledge that certain
              Consumer Services may have their own policies related to fraud and
              suspected fraudulent activities, and you agree that it is your
              responsibility to read and abide by such policies, if any. In
              addition, in the event that TuneGO receives any claim which, if
              true, would constitute a breach of any of your representations,
              warranties or agreements hereunder (including without limitation,
              any claim that the Content or the exploitation thereof infringes,
              misappropriates or violates any intellectual property right, right
              of publicity, privacy or other right of any third party), TuneGO
              may freeze any and all Net Receipts accrued or accruing to your
              TuneGO account, and that such monies will be forfeited by you if
              TuneGO determines, in its good faith discretion, that such monies
              are the result of fraud and/or infringement.
              <br />
              <br />
              If you owe us any amounts (as a consequence of the Terms of
              Service or otherwise), we have the right to deduct all or a
              portion of such amounts from any monies otherwise owed to you
              hereunder.
            </p>
          </li>
          <li>
            <p>
              <strong className={classes.bold}>
                Representations and Warranties; Disclaimer.
              </strong>
            </p>
            <p>You represent and warrant to TuneGO that:</p>
            <ol type="A" className={classes.subsublist}>
              <li>
                <p>
                  you are at least eighteen (18) years of age, you have the
                  right and authority to enter into this Agreement and to grant
                  to TuneGO all of the rights specified herein;
                </p>
              </li>
              <li>
                <p>
                  you have not entered into, and shall not during the Term enter
                  into, any agreement with any third party that conflicts with
                  the terms of this Agreement, or prohibits or impairs the
                  performance of your obligations hereunder and/or the exercise
                  of TuneGO's rights hereunder;
                </p>
              </li>
              <li>
                <p>
                  the Content, your use of the Distro100 Services and the
                  performance of your obligations hereunder shall comply with
                  all applicable laws, rules and regulations;
                </p>
              </li>
              <li>
                <p>
                  you own or control all of the necessary rights in the Content
                  required in order to grant the rights, licenses, and
                  permissions herein, and you have the full right, power, and
                  authority to act on behalf of any and all owners of any right,
                  title or interest in and to the Content and are authorized to
                  provide the Content to TuneGO for the uses specified in this
                  Agreement; without limiting the foregoing, if you are acting
                  on behalf of an artist, band, group or other person or entity,
                  you represent and warrant that you are fully authorized to
                  enter into this Agreement on behalf of such artist, band,
                  group or other person or entity and to grant all of the rights
                  and assume and fulfill all of the obligations, covenants, and
                  representations and warranties set forth in this Agreement;
                </p>
              </li>
              <li>
                <p>
                  you have permission to use the name and likeness of each
                  identifiable individual person whose name or likeness is
                  contained or used within the Content, and to use such
                  individual's identifying or personal information (to the
                  extent such information is used or contained in the Content)
                  as contemplated by this Agreement;
                </p>
              </li>
              <li>
                <p>
                  the use or other exploitation of the Content, including, but
                  not limited to, any musical works embodied in your sound
                  recordings, by TuneGO and TuneGO Partners as contemplated by
                  this Agreement will not infringe, misappropriate or violate
                  any intellectual or other proprietary rights of any third
                  party, including, without limitation, any rights of privacy or
                  publicity, and TuneGO shall have the right to exploit the same
                  in any manner hereunder free from adverse claim and without
                  any obligation to make any payment of any nature to any person
                  or entity other than the amounts payable to you hereunder;
                </p>
              </li>
              <li>
                <p>
                  you have not assigned any of the rights in and to the sound
                  recordings embodied in the Content to any record label or
                  other third party that obtained exclusive rights in and to
                  such sound recordings.
                </p>
              </li>
            </ol>
            <p>
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, THE
              DISTRO100 SERVICES ARE MADE AVAILABLE ON AN "AS IS" AND "AS
              AVAILABLE" BASIS, WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY
              KIND, EXPRESS OR IMPLIED, AND TUNEGO, THE TUNEGO PARTNERS AND EACH
              OF THEIR RESPECTIVE AFFILIATES, PARTNERS, LICENSORS AND SUPPLIERS
              HEREBY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR
              IMPLIED, STATUTORY OR OTHERWISE. Except as specifically set forth
              in this Agreement, TuneGO shall have no obligations to you.
              Without limiting the generality of the foregoing, you agree that
              TuneGO is not required to accept Content submitted by you and does
              not guarantee exploitation or sales of the Content. TuneGO may in
              its sole discretion decline to engage in business with any given
              Consumer Service, and TuneGO does not guarantee that any
              particular Consumer Service will accept or exploit the Content.
              You understand and agree that TuneGO shall not be liable for any
              actual or potential lost revenue due to a delay or failure to make
              the Content available via third party services, carriers,
              websites, or other platforms.
            </p>
          </li>
          <li>
            <p>
              <strong className={classes.bold}>Indemnification.</strong>:
            </p>
            <p>
              You shall indemnify and hold TuneGO and its affiliates and each of
              their respective directors, members, officers, employees, agents
              and representatives (collectively, the "TuneGO Parties") harmless
              from any damage, loss, cost or expense (including without
              limitation, attorneys' fees and costs) incurred in connection with
              any third-party claim, demand or action ("Claim") brought against
              any of the TuneGO Parties that arises in any way out of or in
              connection with your breach or alleged breach of this Agreement.
              TuneGO will have the right to control the defense, settlement, and
              resolution of any such Claim at your sole expense. You may not
              settle or otherwise resolve any such Claim without TuneGO's
              express written permission. Without limiting its other rights and
              remedies hereunder or at law or in equity, TuneGO shall have the
              right, in its sole discretion, to remove or disable access to the
              Content that is the subject of such claim (as applicable), and/or
              to withhold payment of any monies due hereunder in an amount
              reasonably related to such Claim and TuneGO's potential liability
              and/or expenses.
            </p>
          </li>
          <li>
            <p className={classes.bold}>
              <strong className={classes.bold}>Distro100 Data.</strong>
            </p>
            <p>
              You expressly consent to TuneGO's collection of data relating to
              the Content and its exploitation hereunder (including, without
              limitation, song title, names of artists and other contributors,
              sales information, stream counts and other usage data)
              (collectively, the "Distro100 Data"), and grant TuneGO (and its
              licensees) a perpetual, irrevocable, transferable, sub-licensable
              and assignable right to use the Distro100 Data to provide, improve
              and enhance the Distro100 Services and/or any other Services, and
              for data analysis and market research purposes in connection with
              TuneGO's business, including for benchmarking purposes and
              providing market reports, analyses and studies to third parties.
              For the avoidance of doubt, to the extent any of the Distro100
              Data includes any of your personal data, the foregoing rights of
              TuneGO shall be subject to the exercise of any rights you may have
              with respect to such personal data pursuant to any applicable data
              privacy or protection laws. Further information regarding your
              rights as they relate to your personal data can be found in our{' '}
              <Link to={NAVIGATION_PATHS.PRIVACY_POLICY}>Privacy Policy</Link>.
            </p>
          </li>
          <li>
            <p className={classes.bold}>
              <strong className={classes.bold}>Term; Termination.</strong>
            </p>
            <p>
              This Agreement, as amended by any posted revision or modification
              hereto, is effective as of the date you click "I agree" or
              otherwise use the Distro100 Services (whichever occurs earlier)
              and shall remain in full force and effect until your account is
              terminated by you or TuneGO (the "Term"). You may terminate your
              account at any time, for any reason, by contacting TuneGO at
              support@tunego.com. You agree that TuneGO may suspend or terminate
              your account and/or your access to the Distro100 Services at any
              time in its sole discretion, with or without notice to you.
              Notwithstanding anything to the contrary herein, even after your
              account is terminated, any provision of this Agreement that,
              either by its terms or to give effect to its meaning, must
              survive, including, including, without limitation, Sections 5 - 11
              and any indemnifications, warranties and limitations of liability
              contained herein, shall survive the cancellation, expiration or
              termination of this Agreement. TuneGO shall not be deemed to be in
              breach of this Agreement unless you have given TuneGO written
              notice of the breach and TuneGO has failed to cure such breach
              within thirty (30) days after receipt of such notice. In no event
              will any breach entitle you to rescind the rights granted pursuant
              to this Agreement.
            </p>
          </li>
          <li>
            <p className={classes.bold}>
              <strong className={classes.bold}>Limitation of Liability.</strong>
            </p>
            <p>
              TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL TUNEGO BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL,
              INCIDENTAL, INDIRECT, PUNITIVE OR SPECIAL DAMAGES (INCLUDING
              DAMAGES RELATING TO LOST PROFITS, LOST DATA OR CONTENT OR LOSS OF
              GOODWILL) ARISING OUT OF, RELATING TO OR CONNECTED WITH THE USE OF
              THE SERVICES, BASED ON ANY CAUSE OF ACTION, EVEN IF ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW,
              IN NO EVENT WILL THE AGGREGATE LIABILITY OF TUNEGO, ITS
              AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS,
              RELATING TO THE SERVICES EXCEED $100.
              <br />
              <br />
              YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A
              REASONABLE ALLOCATION OF RISK. THIS ALLOCATION OF RISK AND THE
              DISCLAIMER OF WARRANTIES HEREIN ARE A FUNDAMENTAL ELEMENT OF THIS
              AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
              INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES. IN SUCH AN EVENT, THIS
              LIMITATION WILL NOT APPLY TO YOU TO THE EXTENT PROHIBITED BY LAW.
            </p>
          </li>
          <li>
            <p>
              <strong className={classes.bold}>
                Governing Law and Dispute Resolution.
              </strong>
            </p>
            <p className={classes.bold}>
              PLEASE READ THIS SECTION CAREFULLY - IT MAY SIGNIFICANTLY AFFECT
              YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
              COURT.
            </p>
            <p>
              This Agreement shall be governed by and interpreted in accordance
              with the laws of the State of Nevada, U.S.A without reference to
              its choice or conflicts of law principles. Any dispute, claim, or
              controversy relating in any way to this Agreement (including
              interpretation or application of this section and questions of
              arbitrability), to your use of any Services, or to any products or
              services sold or distributed by TuneGO or through tunego.com will
              be resolved by binding arbitration, rather than in court, provided
              that each party retains the right to bring an individual action in
              small claims court or to seek injunctive or other equitable relief
              in a court of competent jurisdiction to prevent infringement,
              misappropriation, or violation of a party's copyrights,
              trademarks, trade secrets, patents, or other intellectual property
              rights.
              <br />
              <br />
              We each agree that any dispute resolution proceedings will be
              conducted on an individual basis only, and not in a class,
              consolidated, or representative action. YOU ACKNOWLEDGE AND AGREE
              THAT YOU AND TUNEGO ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY
              OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS ACTION OR REPRESENTATIVE PROCEEDING.
              <br />
              <br />
              The arbitration will be conducted by a single arbitrator, governed
              by the Commercial Arbitration Rules and the Supplementary
              Procedures for Consumer Related Disputes (collectively, "AAA
              Rules") of the American Arbitration Association ("AAA"), as
              modified by this Agreement, and administered by the AAA. The AAA
              Rules and fee information are available at{' '}
              <a href="www.adr.org" target="_blank">
                www.adr.org
              </a>
              . All hearings shall be held in Las Vegas, Nevada.
            </p>
          </li>
          <li>
            <p>
              <strong className={classes.bold}>Miscellaneous.</strong>
            </p>
            <p>
              This Agreement, together with the{' '}
              <Link to={NAVIGATION_PATHS.TERMS}>Terms of Service</Link> and the{' '}
              <Link to={NAVIGATION_PATHS.PRIVACY_POLICY}>Privacy Policy</Link>{' '}
              contain the entire understanding and agreement between you and
              TuneGO concerning the Distro100 Services and your use thereof, and
              supersede any and all prior or inconsistent understandings
              relating to the Distro100 Services and your use thereof. If any
              provision of this Agreement is held to be illegal, invalid or
              unenforceable, it shall not affect any other provisions and this
              Agreement shall be deemed amended to the extent necessary to make
              it legal, valid and enforceable. Any provision which must survive
              in order to allow us to enforce its meaning shall survive the
              termination of this Agreement; however, no action arising out of
              this Agreement or your use of the Distro100 Services, regardless
              of form or the basis of the claim, may be brought by you more than
              one year after the cause of action has arisen (or if multiple
              causes, from the date the first such cause arose). There are no
              third party beneficiaries to this Agreement. Any failure by TuneGO
              to exercise or enforce any right or provision of this Agreement
              will not operate as a waiver of such right or provision. You may
              not assign any of your rights under this Agreement, and any such
              attempt will be void. TuneGO may assign its rights to any of its
              affiliates or subsidiaries, or to any successor in interest of any
              business associated with the Distro100 Services.
            </p>
          </li>
        </ol>
        <p>
          <strong>
            Please print a copy of these Terms for your records and PLEASE check
            the Site frequently for any changes to these Terms.
          </strong>
        </p>
      </div>
    </TermsWrapper>
  )
}
