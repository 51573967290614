export const ENDPOINTS = {
  // USER
  USERS: 'users',
  USERS_ME: '/users/me',
  ONBOARDING: '/users/me/onboard',
  NEW_PASSWORD: '/users/me/password',
  UPLOAD_LINK: '/users/me/upload/link',
  UPLOAD_HEALTH_CHECK: '/users/me/upload/health/check',
  PERSONA_VERIFY: '/users/verify-persona',
  PERSONA_SESSION: '/users/persona-session',
  CONFIRM_NFT_INFO: '/users/confirm-nft-info',
  SOURCE: '/users/source',

  // STRUCTURES
  STRUCTURES: '/structures',
  STRUCTURES_ALT: '/structures/alt',
  STRUCTURES_STRUCTURE: '/structures/:id',
  STRUCTURES_STRUCTURE_COUNT: '/structures/:id/structures-count',
  STRUCTURES_CHECK_FILES: '/structures/:id/project/files-check',
  STRUCTURES_STRUCTURE_DUPLICATE: '/structures/:id/duplicate',
  STRUCTURES_STRUCTURE_MOVE_PROJECT:
    '/structures/:structureId/project/move/:newParentId',
  STRUCTURES_STRUCTURE_FILENAME:
    '/structures/:structureId/files/name/:fileName',
  STRUCTURE_AVATAR: '/structures/:id/image',
  STRUCTURE_UPLOAD_LINK: '/structures/:id/upload/image/link',
  STRUCTURE_UPLOAD_LINK_HEALTH_CHECK:
    '/structures/:id/upload/image/health/check',
  STRUCTURES_STRUCTURE_SONG: '/structures/:id/project',
  STRUCTURES_STRUCTURE_SONG_FILES: '/structures/:id/project/files',
  STRUCTURES_STRUCTURE_CONTRIBUTORS: '/structures/:id/contributors',
  STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR:
    '/structures/:structureId/contributors/:contributorId',
  STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR_REINVITE:
    '/structures/:structureId/contributors/:contributorId/reinvite',
  STRUCTURES_STRUCTURE_CONTRIBUTORS_ACCEPT_CONTRIBUTION:
    '/structures/:structureId/contributors/:contributorId/accept',
  STRUCTURES_STRUCTURE_CONTRIBUTORS_REJECT_CONTRIBUTION:
    '/structures/:structureId/contributors/:contributorId/reject',
  STRUCTURES_STRUCTURE_CONTRIBUTORS_INVITE:
    '/structures/:id/contributors/invite',
  STRUCTURES_STRUCTURE_COLLABORATORS: '/structures/:id/collaborators',
  STRUCTURES_STRUCTURE_COLLABORATORS_INVITE:
    '/structures/:id/collaborators/invite',
  STRUCTURES_STRUCTURE_COLLABORATORS_COLLABORATOR_REINVITE:
    '/structures/:structureId/collaborators/:collaborationId/reinvite',
  STRUCTURES_STRUCTURE_COLLABORATORS_ACCEPT_COLLABORATION:
    '/structures/:structureId/collaborators/:collaborationId/accept',
  STRUCTURES_STRUCTURE_COLLABORATORS_REJECT_COLLABORATION:
    '/structures/:structureId/collaborators/:collaborationId/reject',
  STRUCTURES_STRUCTURE_COLLABORATORS_COLLABORATOR:
    '/structures/:structureId/collaborators/:collaboratorId',
  STRUCTURES_STRUCTURE_SET_MASTER:
    '/structures/:structureId/project/file/:fileId/set/master',
  STRUCTURES_STRUCTURE_SPLIT: '/structures/:structureId/splits',
  STRUCTURES_STRUCTURE_FINGERPRINTS: '/structures/:structureId/fingerprints',
  STRUCTURES_STRUCTURE_SHARES: '/structures/:structureId/shares',
  STRUCTURES_STRUCTURE_SHARES_RECEIVED:
    '/structures/:structureId/shares/received',
  STRUCTURES_STRUCTURE_SHARES_INVITE: '/structures/:structureId/shares/invite',
  STRUCTURES_PROFILES_FOR: '/structures/profiles/for/:type/list',
  STRUCTURES_STRUCTURE_FEATURED_ARTISTS:
    '/structures/:structureId/featuredArtists',
  STRUCTURES_STRUCTURE_FEATURED_ARTISTS_ARTIST:
    '/structures/:structureId/featuredArtists/:featuredArtistId',
  STRUCTURES_DEFAULT_ARTIST_IDS: '/structures/:profileId/default/artistIds',
  STRUCTURES_NFT_FILES_COUNT: '/structures/:structureId/nft-files-count',

  // FILES
  FILES: '/files',
  FILES_FILE: '/files/:id',
  FILE_UPLOAD_LINK: '/files/:id/upload/link',
  FILE_UPLOAD_LINK_HEALTH_CHECK: '/files/:id/health/check',
  FILE_DOWNLOAD_LINK: '/files/:fileId/download/link',
  FILE_LISTEN_LINK: '/files/:fileId/download/preview',
  FILE_HEARTBEAT: '/files/:fileId/heartbeat',
  FILE_MULTIPART_UPLOAD: '/files/:fileId/multipart-upload',
  FILE_DELETE_MULTIPART_UPLOAD: '/files/:fileId/multipart-upload/:uploadId',
  FILE_SIGN_PART: '/files/:fileId/multipart-upload/sign-part',
  FILE_PARTS_LIST: '/files/:fileId/multipart-upload/parts-list/:uploadId',
  FILE_MULTI_UPLOAD_COMPLETE:
    '/files/:fileId/multipart-upload/complete/:uploadId',

  // GENRES
  GENRES: '/genres',

  // NOTIFICATIONS
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_NOTIFICATION: '/notifications/:notificationId',
  NOTIFICATIONS_UNREAD: '/notifications/unread',
  NOTIFICATIONS_NOTIFICATION_READ: '/notifications/:id/read',
  NOTIFICATIONS_READ_ALL: '/notifications/readAll',
  NOTIFICATIONS_APP_INVITE: '/notifications/app-invite',

  // SPLITS
  SPLITS: '/splits',
  SPLITS_INVITE: '/splits/invite',
  SPLITS_SPLIT: '/splits/:splitId',
  SPLITS_SPLIT_REINVITE: '/splits/:splitId/reinvite',
  SPLITS_SPLIT_APPROVE: '/splits/:splitId/approve',
  SPLITS_SPLIT_REJECT: '/splits/:splitId/reject',

  // SHARES
  SHARES_SHARE: '/shares/:shareId',
  SHARES_ALL_RECEIVED: '/shares/received',
  SHARES_SHARE_ACCESS: '/shares/:shareId/access',
  SHARES_SHARE_ACCEPT: '/shares/:shareId/accept',
  SHARES_SHARE_REVOKE: '/shares/:shareId/revoke',

  // CONNECT
  CONNECT_TOKEN: '/connect/token',
  CONNECT_PROVIDER: '/connect/provider',

  // PROMOTION
  PROMOTION: '/promotions',
  PROMOTION_STRIPE_INTENT: '/promotions/:promotionId/stripe-intent',
  PROMOTION_LISTEN_LINK: '/promotions/:promotionId/download/preview',

  // PAYMENTS
  PAYMENTS: '/payments',
  PAYMENTS_PAYMENT: '/payments/:paymentId',
  PAYMENTS_PRICES: '/payments/prices',
  PAYMENT_METHODS: '/payments/payment-methods',
  PAYMENT_METHODS_SET_AS_DEFAULT:
    '/payments/payment-methods/:paymentMethodId/set-default',
  PAYMENT_METHODS_DELETE: '/payments/payment-methods/:paymentMethodId',

  // MASTERING
  MASTERING: '/mastered-files',
  MASTERING_GET_FILE: '/mastered-files/:id',
  MASTERING_GET_MASTERED_LINK: '/mastered-files/:id/master/link',
  MASTERING_GET_PREVIEW_LINK: '/mastered-files/:id/preview/link',
  MASTERING_GET_ORIGINAL_LINK: '/mastered-files/:id/original/link',
  MASTERING_GET_ORIGINAL_PREVIEW_LINK:
    '/mastered-files/:id/original-preview/link',
  MASTERING_GET_WAVEFORM: '/mastered-files/:id/:type/waveform/link',
  MASTERING_GET_FILES: '/mastered-files',
  MASTERING_STRIPE_INTENT: '/mastered-files/:id/payment/stripe',
  MASTERING_PROCESSING_STATUS: '/mastered-files/processing-limit-status',

  // DISTRO
  TEMPORARY_DISTRO: '/tdistro',
  DISTRO_SETUP: '/distro',
  DISTRO_ADD_SONG: '/distro/:distroId/song',
  DISTRO_DELETE_SONG: '/distro/:distroId/song/:songId',
  DISTRO_ARTIST: '/distro/:platform/artists?name=:name',
  SET_DISTRO_ARTIST: '/distro/:distroId/artistIds',
  DISTRO_ORDER_SONG: '/distro/:distroId/song/:songId/order',
  DISTRO_SINGLE_DISTRO: '/distro/:distroId',
  DISTRO_DELIVERY_DETAILS: '/distro/:distroId/delivery',
  DISTRO_IMAGE_LINK: '/distro/:distroId/upload/image/link',
  DISTRO_IMAGE_HEALTH_CHECK: '/distro/:distroId/upload/image/health/check',
  DISTRO_SUBMIT_FOR_APPROVAL: '/distro/:distroId/submit-for-approval',
  DISTRO_CREATE_PAYMENT: '/distro/payment',

  // NFT
  NFT_SETUP: '/nft',
  NFT_NFT: 'nft/:nftId',
  NFT_DETAILS: '/nft/:nftId/details',
  NFT_IMAGE: '/nft/:nftId/image',
  NFT_IMAGE_VAULT: '/nft/:nftId/image/from-vault',
  NFT_IMAGE_HEALTH_CHECK: '/nft/:nftId/image/health/check',
  NFT_FILE: '/nft/:nftId/file/:fileId',
  NFT_SPLIT: '/nft/:nftId/split',
  NFT_SPLIT_REINVITE: '/nft/split/:splitId/reinvite',
  NFT_SPLIT_OFF_PLATFORM: '/nft/:nftId/split/invite',
  NFT_SPLITS_UPDATE: 'nft/:nftId/split/values',
  NFT_SPLIT_ACTION: '/nft/split/:splitId/value/:action',
  NFT_EDIT_SPLIT_ROLES: '/nft/split/:splitId/roles',
  NFT_EDIT_OTHER: '/nft/other/:otherId',
  NFT_DELETE_ITEM: '/nft/:itemType/:itemId',
  NFT_COMPLETE: '/nft/:nftId/complete',
  NFT_WALLET: '/nft/:nftId/wallet',
  NFT_MINT: '/nft/:nftId/mint',
  NFT_ITEM_LINK: '/nft/:itemType/:itemId/download/link',
  NFT_MARKET_FEES: 'nft/market-fees',
  RESTORE_NFT: '/nft/:nftId/restore',

  // SUBSCRIPTIONS
  SUBSCRIPTIONS_PLANS: '/subscriptions/plans',
  SUBSCRIPTIONS_CURRENT: '/subscriptions/current',
  SUBSCRIPTIONS_UPGRADE: '/subscriptions/upgrade',
  SUBSCRIPTIONS_PREVIEW_UPGRADE: '/subscriptions/preview-upgrade',

  // SYSTEM
  SYSTEM_SETTINGS: '/system/settings',

  // WALLETS
  WALLETS: '/wallets',
  WALLETS_CHECK_ROYALTY_RECEIVERS: '/wallets/check-royalty-receivers',
  WALLETS_GET_ROYALTY_SETUP_TX_CODE: '/wallets/get-royalty-setup-code',

  // AUTH
  LOGIN: '/auth/login',
  RESEND_EMAIL: '/auth/send-verify-email',
  CHECK_EMAIL_VERIFICATION: '/auth/check-email-verification',
}
