import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const useWallet = () => {
  const { data, error, ...rest } = useSWR(ENDPOINTS.WALLETS)

  return {
    wallet: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
