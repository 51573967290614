import { NAVIGATION_PATHS } from '#routes/routes'
import { buildUrl } from '#utils/buildUrl'

export const getNavigation = ({ parent, setIsFolderCollaboratorView, t }) => {
  return [
    {
      link: NAVIGATION_PATHS.VAULT_PROFILES,
      title: t('myVault'),
    },
    {
      link: buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { id: parent?.id }),
      title: parent?.name,
      onClick: () => setIsFolderCollaboratorView(false),
    },
  ]
}
