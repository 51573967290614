import axios from 'axios'

export class S3Bucket {
  static uploadImg = ({
    url,
    fields,
    file,
    headers,
    onUploadProgress,
    withoutContentType = false,
  }) => {
    const formData = new FormData()
    formData.append('key', fields.key)
    if (!withoutContentType) {
      formData.append('Content-Type', file.type)
    }
    for (const field in fields) {
      if (field !== 'key') {
        formData.append(field, fields[field])
      }
    }
    formData.append('file', file)

    return axios({
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      url,
      onUploadProgress,
    })
  }
}
