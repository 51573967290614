import { createMuiTheme } from '@material-ui/core/styles'

export const highestZIndex = 9999999
export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#000000',
    },
    color: {
      black: '#000000',
      default: '#ffffff',
      primary: '#00a0ff',
      primary25: '#255f95',
      primary60: '#004F80',
      primary00: '#002134',
      primary80: '#005080',
      secondary: '#fafafa',
      secondary7B: '#7b8180',
      charcoal: '#1c1e1e',
      charcoal1C: '#1c1c1c',
      charcoal16: '#161616',
      darkGrey: '#2b2d2d',
      darkGreyB3: '#b3b3b3',
      darkGrey84: '#848484',
      darkGrey80: '#808080',
      darkGrey1A: '#1a1a1a',
      darkGrey33: '#333333',
      darkGrey22: '#222222',
      darkGrey66: '#666666',
      darkGrey12: '#121212',
      darkGrey0B: '#0B0B0B',
      darkGrey25: '#252525',
      darkGrey39: '#393A3B',
      error: '#B77207',
      warning: '#ff7c00',
      errorRed: '#FF0000',
      minorGrey: '#141414',
      white: '#dbdbdb',
      darkBlue: '#030917',
      darkBlue09: '#091220',
      darkBlue03: '#030a19',
      darkBlue39: '#091C39',
      lighterBlue: '#0f1826',
      azureRadiance: '#00A0FF',
      mineShaft: '#373737',
      eden: '#11405C',
      eden17: '#174E73',
      eden1D: '#1D5E8B',
      eden05: '#055f63',
      downriver: '#092946',
      downriver0F: '#0F385E',
      downriver15: '#154977',
      downriver16: '#163e64',
      downriver1D: '#1d4e7e',
      codGray: '#0D0D0D',
      purple: '#FF00EE',
    },
    primary: {
      main: '#00a0ff',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#B77207',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '32px',
        fontSize: '1rem',
      },
    },
    MuiFormControl: {
      root: {
        outline: '#116467',
        marginTop: '20px',
        marginBottom: '10px',
        cursor: 'pointer',
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        height: '1.1875em',
      },
    },
    MuiDialog: {
      paperFullScreen: {
        backgroundColor: '#000000',
      },
    },
  },
  typography: {
    avenirNextFont: 'AvenirNext-Regular, Avenir Next, sans-serif',
    fontFamily: '"AvenirNextLTPro-Regular", "Arial", sans-serif',
    bold: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    h2: {
      fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
      fontSize: '32px',
    },
    h3: {
      fontSize: '22px',
      lineHeight: 1.5,
      marginBottom: '10px',
    },
    h4: {
      fontSize: '16px',
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
      fontSize: '14px',
      lineHeight: 1.5,
    },
    subtitle1: {
      fontSize: '1.3rem',
      fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    },
    subtitle2: {
      fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    },
    body2: {
      fontSize: '1rem',
    },
    button: {
      fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
      textTransform: 'unset',
      fontSize: '1rem',
    },
    useNextVariants: true,
    overline: {
      textTransform: 'uppercase',
      fontSize: '22px',
      color: '#2b2d2d',
      fontFamily: '"AvenirNextLTPro-Bold"',
    },
  },
  zIndex: {
    heading: 10,
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: highestZIndex + 2,
    snackbar: highestZIndex + 101,
    tooltip: highestZIndex + 201,
  },
  sizes: {
    navBarHeight: 64,
  },
})
