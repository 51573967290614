import React from 'react'
import { makeStyles } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const useStyles = makeStyles(theme => ({
  playIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: '50%',
    background: theme.palette.color.primary,
  },
  playIcon: {
    height: 15,
    width: 15,
  },
}))

export const PlayIcon = () => {
  const classes = useStyles()

  return (
    <div className={classes.playIconContainer}>
      <PlayArrowIcon
        classes={{
          root: classes.playIcon,
        }}
      />
    </div>
  )
}
