import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

const SErrorMessage = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.error};
  margin: 5px 0;
  text-transform: none;
`

export const ErrorMessage = ({ errorMessage, className }) => {
  return errorMessage ? (
    <SErrorMessage className={className}>{errorMessage}</SErrorMessage>
  ) : null
}

ErrorMessage.propTypes = {
  errorMessage: oneOfType([string, node]).isRequired,
  className: string,
}

ErrorMessage.defaultProps = {
  className: '',
}
