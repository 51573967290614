import React from 'react'
import { makeStyles } from '@material-ui/core'
import { arrayOf, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.color.charcoal,
    height: 60,
    alignItems: 'center',
    paddingLeft: 30,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}))

export const Header = ({ titles }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  const titlesToRender = titles.map((title, index) => (
    <div key={generateUniqueKey(title, index)} className={classes.title}>
      {t(title)}
    </div>
  ))

  return <div className={classes.container}>{titlesToRender}</div>
}

Header.propTypes = {
  titles: arrayOf(string).isRequired,
}
