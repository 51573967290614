import * as yup from 'yup'
import i18next from 'i18next'
// auth0 password validation rules:
// https://auth0.com/docs/connections/database/password-strength#password-policies
// at least 8 characters including at least 3 of the following 4 types of characters:
// a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).
const AUTH0_PASSWORD_REGEX =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
const AUTH0_PASSWORD_REGEX_MSG =
  'Password must have at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
const AUTH0_PASSWORD_MIN_LENGTH = 8
const AUTH0_PASSWORD_MIN_LENGTH_MSG =
  'Password must have at least 8 characters.'

const getPasswordValidationSchema = fieldName =>
  yup.object().shape({
    [fieldName || 'password']: yup
      .string()
      .required('Password cannot be empty.')
      .min(AUTH0_PASSWORD_MIN_LENGTH, AUTH0_PASSWORD_MIN_LENGTH_MSG)
      .matches(AUTH0_PASSWORD_REGEX, AUTH0_PASSWORD_REGEX_MSG),
  })

const getPasswordMatchesValidationSchema = ({ fieldName, matchesToField }) =>
  yup.object().shape({
    [fieldName]: yup
      .string()
      .required('Password cannot be empty.')
      .min(AUTH0_PASSWORD_MIN_LENGTH, AUTH0_PASSWORD_MIN_LENGTH_MSG)
      .oneOf([yup.ref(matchesToField), null], 'Passwords must match'),
  })

export const changePasswordValidationSchema = yup
  .mixed()
  .concat(getPasswordValidationSchema('newPassword'))
  .concat(
    getPasswordMatchesValidationSchema({
      fieldName: 'repeatedNewPassword',
      matchesToField: 'newPassword',
    })
  )

export const createProfileValidationSchema = t => {
  const nameField = t('profileName')
  const bioField = t('bio')

  return yup.object().shape({
    profileName: yup
      .string()
      .trim()
      .min(
        1,
        i18next.t('validation:pleaseProvideAtLeastOneCharacter', {
          field: nameField,
        })
      )
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: nameField,
        })
      ),
    bio: yup
      .string()
      .trim()
      .min(
        1,
        i18next.t('validation:pleaseProvideAtLeastOneCharacter', {
          field: bioField,
        })
      )
      .max(
        240,
        i18next.t('validation:maximumLength', {
          field: bioField,
          number: 240,
        })
      ),
  })
}

export const proPublishingValidationSchema = t => {
  return yup.object().shape({
    ipiCaeNumber: yup
      .string()
      .trim()
      .test(
        i18next.t('validation:acceptOnlyDigits'),
        i18next.t('validation:onlyDigits', {
          field: t(`ipiCaeNumber`),
        }),
        value => {
          if (value) {
            return /^\d+$/.test(value)
          }
          return true
        }
      )
      .min(
        9,
        i18next.t('validation:minimumLength', {
          field: t(`ipiCaeNumber`),
          number: 9,
        })
      )
      .max(
        11,
        i18next.t('validation:maximumLength', {
          field: t(`ipiCaeNumber`),
          number: 11,
        })
      ),
  })
}

const firstNameValidationSchema = t => {
  return yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('firstName'),
        })
      )
      .nullable()
      .strict(true),
  })
}

const lastNameValidationSchema = t => {
  return yup.object().shape({
    lastName: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('lastName'),
        })
      )
      .nullable()
      .strict(true),
  })
}

const locationValidationSchema = () =>
  yup
    .object()
    .nullable()
    .shape({
      location: yup.object().nullable().shape({
        cityName: yup.string().trim().nullable(),
        countryCode: yup.string().trim().nullable(),
      }),
    })

export const userDetailsValidationSchema = t => {
  return yup
    .mixed()
    .concat(firstNameValidationSchema(t))
    .concat(lastNameValidationSchema(t))
    .concat(locationValidationSchema(t))
}
