import React from 'react'
import { makeStyles } from '@material-ui/core'
import * as dayjs from 'dayjs'
import { useTranslation, Trans } from 'react-i18next'
import { object, shape } from 'prop-types'
import { navigate } from '@reach/router'
import { OrderSummary } from '#modules/order-summary'
import { formatIntervalPrice } from '#pages/profile/utils/helpers'
import {
  PROFILE_PAGES,
  BILLING_PERIOD,
  SUBSCRIPTION_PLANS,
} from '#pages/profile/utils/constants'
import { useSubscriptionsPlans } from '#hooks/swr/useSubscription'
import { CheckoutSuccess } from '#pages/subscriptions/_components/checkout-success'
import { HeadingBar } from '#components/heading-bar'
import { NAVIGATION_PATHS } from '#routes/routes'
import {
  getPaymentSuccessOrderData,
  getPaymentSuccessProratedData,
} from '#pages/subscriptions/_containers/payment-success-container/utils/helpers'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display: 'flex',
    marginTop: 64,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  successWrapper: {
    width: 600,
    paddingTop: 60,
    minHeight: 960,
    borderRight: `1px solid ${theme.palette.color.darkGrey33}`,
    position: 'relative',
  },
  orderWrapper: {
    margin: 60,
    width: '100%',
  },
  rowValueWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  bold: {
    fontWeight: 700,
    display: 'inline',
  },
  label: {
    paddingLeft: '0.2rem',
  },
}))

export const PaymentSuccessContainer = props => {
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const classes = useStyles()
  const { user } = useUser()
  const currentSubscription = user?.subscription.subscription

  const {
    location: {
      state: {
        payment,
        planId,
        billingPeriod,
        adjustmentPrice,
        isSwitchedTrue,
        isProratedTrue,
      },
    },
  } = props

  const { plans } = useSubscriptionsPlans()
  const chosenPlan = plans?.find(plan => plan.id === planId)
  const { tierLevel } = chosenPlan || {}
  const planName = t(SUBSCRIPTION_PLANS[tierLevel])
  const isMonthly = billingPeriod === BILLING_PERIOD.MONTH
  const displayedPrice = formatIntervalPrice(payment.amount, isMonthly)
  const displayedTotalPrice = formatIntervalPrice(payment.amount, true)
  const accountRenewalDate = dayjs().add(1, billingPeriod).format('MM/DD/YYYY')

  const orderData = getPaymentSuccessOrderData({
    classes,
    t: profileTranslation.t,
    tierLevel,
    displayedPrice,
    billingPeriod,
    isSwitchedTrue,
    currentSubscription,
    accountRenewalDate,
  })

  const proratedData = getPaymentSuccessProratedData({
    isMonthly,
    classes,
    t: profileTranslation.t,
    tierLevel,
    displayedTotalPrice,
    adjustmentPrice,
  })

  const handleNavigate = () => {
    navigate(NAVIGATION_PATHS.PROFILE, {
      state: { tab: PROFILE_PAGES.SUBSCRIPTION },
    })
  }

  return (
    <>
      <HeadingBar>{profileTranslation.t('tuneGoSubscription')}</HeadingBar>
      <div className={classes.contentWrapper}>
        <div className={`${classes.successWrapper} ${classes.column}`}>
          <CheckoutSuccess
            congratulationsTitle={profileTranslation.t(
              'yourSubscriptionIsActive'
            )}
            congratulationsMessage={
              <Trans
                {...{
                  t: profileTranslation.t,
                  i18nKey: 'andThankYouForSupporting',
                  components: [<b key={0} />],
                  values: {
                    plan: planName,
                    date: accountRenewalDate,
                  },
                }}
              />
            }
            buttonText={profileTranslation.t('viewSubscriptions')}
            onClick={handleNavigate}
          />
        </div>
        <div className={`${classes.orderWrapper} ${classes.column}`}>
          <OrderSummary
            {...{ payment }}
            price={payment.amount}
            orderData={orderData}
            proratedData={proratedData}
            customPriceRowTitle={isProratedTrue ? t('totalPrice') : t('total')}
            isProrated={isProratedTrue}
            showOrderNumber
            showCard
          />
        </div>
      </div>
    </>
  )
}

PaymentSuccessContainer.propTypes = {
  location: shape({
    state: object,
  }).isRequired,
}
