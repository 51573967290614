import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { func, shape, bool } from 'prop-types'

import { hexToRgba } from '#utils/hexToRgba'

import { HeaderNavigation } from './components/header-navigation'

const useStyles = makeStyles(theme => ({
  outerWrapper: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.default,
    zIndex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: hexToRgba(theme.palette.color.default, 0.1),
    padding: '0 32px',
    minHeight: 80,
  },
  closeIcon: {
    cursor: 'pointer',
  },
}))

export const Header = ({
  setIsOpened,
  activeDialog,
  isChooseFileViewOpened,
  onBackClick,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.outerWrapper}>
      <div className={classes.header}>
        <img
          src="/assets/svg/ic-connect-horizontal.svg"
          width="auto"
          height="25px"
          alt="Connect"
        />
        <img
          src="/assets/svg/ic-close-white.svg"
          width="auto"
          height="21px"
          className={classes.closeIcon}
          alt="Close"
          onClick={() => setIsOpened(false)}
        />
      </div>
      {!!activeDialog && (
        <HeaderNavigation
          activeDialog={activeDialog}
          isChooseFileViewOpened={isChooseFileViewOpened}
          onBackClick={onBackClick}
        />
      )}
    </div>
  )
}

Header.propTypes = {
  setIsOpened: func.isRequired,
  onBackClick: func.isRequired,
  activeDialog: shape({}).isRequired,
  isChooseFileViewOpened: bool.isRequired,
}
