import React from 'react'
import { number, string, shape } from 'prop-types'
import { COLLECTIBLE_COLUMNS } from '#pages/sell-collectibles/my-collectibles/_utils/constants'
import styled from 'styled-components'
import { CollectibleCell } from '#pages/sell-collectibles/my-collectibles/_components/collectible-cell'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'

const SRow = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.black};
  height: 3.75rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-weight: 500;
  line-height: 1;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: none;
  cursor: pointer;

  &:nth-of-type(even) {
    background-color: ${({ theme: { colors } }) => colors.darkGrey0B};
  }
`
export const CollectibleRow = ({ nft, revalidate }) => {
  const { t } = useTranslation('sellCollectibles')

  return (
    <SRow
      onClick={() =>
        navigate(`${NAVIGATION_PATHS.CREATE_COLLECTIBLES}?nftId=${nft.id}`)
      }
    >
      {COLLECTIBLE_COLUMNS(t).map(column => (
        <CollectibleCell
          key={`cell-${column.key}-${nft.id}`}
          nft={nft}
          column={column}
          revalidate={revalidate}
        />
      ))}
    </SRow>
  )
}

CollectibleRow.propTypes = {
  nft: shape({
    id: string,
    imageId: string,
    packsCount: number,
    status: string,
    title: string,
    totalUnits: number,
  }).isRequired,
}
