import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import { SharesApi } from '#api/requests/shares'
import { PrivateShares } from '#pages/_modules/private-shares'
import { useAllReceivedShares } from '#hooks/swr/useShares'
import { BaseLoader } from '#components/loaders/base-loader'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { getSortingQuery } from '#utils/getSortingQuery'

const useStyles = makeStyles(() => ({
  privateSharesContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '3.125rem',
  },
}))

export const PrivateSharesContainer = () => {
  const { t } = useTranslation('profile')
  const [page, setPage] = useState(0)
  const classes = useStyles()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [shouldSortAscending, setShouldSortAscending] = useState(true)
  const [sortBy, setSortBy] = useState('')
  const { setFileInfo } = useGlobalAudioPlayer()
  const query = `?page=${page}&limit=${rowsPerPage}${getSortingQuery(
    sortBy,
    shouldSortAscending
  )}`
  const { shares, isLoading, revalidate, mutate } = useAllReceivedShares(query)
  const { actions } = useWithAsyncAction({
    accessShare: SharesApi.accessShare,
  })

  const onPlay = async share => {
    const {
      data: { link },
    } = await actions.accessShare(share.id)
    setFileInfo({
      shareId: share.id,
      uri: link,
      fileName: share.fileName,
      projectName: `${share.profile.name} · ${share.project.name}`,
      suffix: 'mp3',
    })
    revalidate()
  }

  return (
    <div className={classes.privateSharesContainer}>
      <BaseLoader isLoading={isLoading} text={t('privateSharesLoading')} />
      {shares?.data && (
        <PrivateShares
          {...{
            page,
            rowsPerPage,
            isAllRecivedSharesView: true,
            setPage,
            setRowsPerPage,
            count: shares.count,
            shares: shares.data,
            onPlay,
            shouldSortAscending,
            setShouldSortAscending,
            sortBy,
            setSortBy,
            mutate,
          }}
        />
      )}
    </div>
  )
}
