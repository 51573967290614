import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { useWizard } from '#modules/forms/form-wizard/context'
import { DistroApi } from '#api/requests/distro'
import { PaymentCheckout } from '#modules/checkout'
import { CHECKOUT_TYPE } from '#modules/checkout/utils/constants'
import { PRICE_TYPE } from '#constants/priceTypes'
import { DISTRO_TYPES } from '#constants/distroTypes'
import { capitalizeWords } from '#utils/capitalizeWords'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { DetailsContainer } from '#pages/distro/past-release-details/containers/details-container'
import { PAYMENT_STATUSES } from '#constants/paymentStatuses'
import { CheckoutSuccess } from '#modules/checkout/components/checkout-success'
import { OrderSummary } from '#modules/order-summary'
import { getSuccessData } from '#modules/checkout/utils/helpers'
import { usePayment } from '#hooks/swr/usePayments'
import { CURRENCY } from '#constants/currency'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(() => ({
  orderSummaryWrapper: {
    marginTop: '1.875rem',
  },
}))

export const Checkout = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const [paymentId, setPaymentId] = useState(null)
  const [freePaymentStatus, setFreePaymentStatus] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const { priceOf } = useUser()
  const { storedFormState } = useWizard()
  const { distroId, parentId, releaseName, songFiles, subscription } =
    storedFormState
  const { actions } = useWithAsyncAction({
    createSubscription: DistroApi.createSubscription,
  })
  const { project: structure } = useArtistStructure(parentId)
  const { payment } = usePayment(paymentId)

  const isSingleDistro = songFiles.length === 1

  const distroType = isSingleDistro ? DISTRO_TYPES.SINGLE : DISTRO_TYPES.ALBUM
  const priceType = isSingleDistro
    ? PRICE_TYPE.DISTRO_SINGLE
    : PRICE_TYPE.DISTRO_ALBUM
  const price = priceOf(priceType)

  const orderData = [
    {
      rowTitle: t('artistProfile'),
      rowValue: structure?.name,
    },
    {
      rowTitle: t('releaseName'),
      rowValue: releaseName,
    },
    {
      rowTitle: t('distributionType'),
      rowValue: capitalizeWords(distroType),
    },
  ]

  const orderSummaryProps = {
    orderData,
    price: price?.value,
    showOrderNumber: true,
    showCard: true,
  }

  const type = CHECKOUT_TYPE.DISTRO

  const handlePayment = async ({ paymentMethodId, setError, stopLoading }) => {
    try {
      setPaymentSuccess(false)
      const {
        // eslint-disable-next-line no-shadow
        data: { initialPayment, subscription },
      } = await actions.createSubscription({
        amount: price.value,
        currency: CURRENCY.USD,
        distroId,
        paymentMethodId,
      })

      if (
        price?.value === 0 &&
        subscription?.status === PAYMENT_STATUSES.ACTIVE
      ) {
        setPaymentSuccess(true)
        setFreePaymentStatus(true)
        stopLoading()
        return
      }

      if (
        initialPayment?.id &&
        initialPayment?.status === PAYMENT_STATUSES.COMPLETED
      ) {
        setPaymentId(initialPayment?.id)
        if (initialPayment.status === PAYMENT_STATUSES.COMPLETED) {
          setPaymentSuccess(true)
          stopLoading()
        } else if (initialPayment.status === PAYMENT_STATUSES.REJECTED) {
          setPaymentSuccess(false)
          stopLoading()
        }
      }
    } catch (err) {
      setError(err.response?.data?.message)
      stopLoading()
    }
  }

  const onPaymentSuccess = () => {
    setPaymentSuccess(true)
  }

  const { buttonText, onClick, congratulationsMessage } = getSuccessData(
    type,
    t
  )

  if (subscription?.status === PAYMENT_STATUSES.ACTIVE) {
    return (
      <>
        <CheckoutSuccess
          congratulationsMessage={congratulationsMessage}
          buttonText={buttonText}
          showHorizontally
          onClick={onClick}
        />
        <div className={classes.orderSummaryWrapper}>
          <OrderSummary
            {...{
              ...orderSummaryProps,
              payment,
              freePaymentStatus: true,
              showCard: !freePaymentStatus,
            }}
          />
        </div>
        <DetailsContainer />
      </>
    )
  }

  return (
    <PaymentCheckout
      {...{
        orderSummaryProps,
        handlePayment,
        onPaymentSuccess,
        type,
        paymentId,
        freePaymentStatus,
      }}
      horizontal
    >
      {paymentSuccess && <DetailsContainer />}
    </PaymentCheckout>
  )
}

Checkout.propTypes = {
  isSuccess: bool.isRequired,
  setIsSuccess: func.isRequired,
}
