import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { arrayOf, shape, string } from 'prop-types'
import {
  FIELDS,
  RELEASE_DETAILS_FIELDS,
  RELEASE_VERSION,
} from '#pages/distro/_utils/constants'
import { explicitContent } from '#pages/vault/song/tabs/metadata/utils/constants/explicitContent'
import { wasPreviouslyReleased } from '#pages/vault/song/tabs/metadata/utils/constants/wasPreviouslyReleased'
import { createYears } from '#pages/distro/_utils/helpers'
import { useWizard } from '#modules/forms/form-wizard/context'
import { ReleaseDetailsFormControlledFields } from '../release-details-form-controlled-fields'
import { ReleaseDetailsFormField } from '../release-details-form-field'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const selectContainerStyles = () => ({
  width: '100%',
})

const dateContainerStyles = () => ({
  width: '100%',
})

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.color.darkBlue09,
  },
  wrapper: {
    padding: '0 30px 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    alignItems: 'baseline',
  },
}))

export const ReleaseDetailsForm = ({ genres, profileName }) => {
  const classes = useStyles()
  const { storedFormState } = useWizard()

  const {
    register,
    clearErrors,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext()

  const isPreviouslyReleased = watch(FIELDS.IS_PREVIOUSLY_RELEASED)

  useEffect(() => {
    if (
      !storedFormState[FIELDS.RELEASE_NAME] &&
      storedFormState[FIELDS.SONG_FILES].length === 1
    ) {
      setValue(
        FIELDS.RELEASE_NAME,
        storedFormState[FIELDS.SONG_FILES][0]?.songName
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isPreviouslyReleased?.value === false) {
      clearErrors(FIELDS.PREVIOUSLY_RELEASED_DATE)
      setValue(FIELDS.PREVIOUSLY_RELEASED_DATE, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviouslyReleased])

  const selectOptions = {
    [FIELDS.GENRE]: genres,
    [FIELDS.SECONDARY_GENRE]: genres,
    [FIELDS.RELEASE_VERSION]: RELEASE_VERSION,
    [FIELDS.EXPLICIT_CONTENT]: explicitContent,
    [FIELDS.IS_PREVIOUSLY_RELEASED]: wasPreviouslyReleased,
    [FIELDS.RECORDING_YEAR]: createYears(),
    [FIELDS.RELEASE_COPYRIGHT_YEAR]: createYears(),
    [FIELDS.ARTWORK_COPYRIGHT_YEAR]: createYears(),
  }

  return (
    <div className={classes.container}>
      <div className={classes.fields}>
        {genres &&
          RELEASE_DETAILS_FIELDS.map((field, i) => (
            <ReleaseDetailsFormField
              key={generateUniqueKey(field, i)}
              {...{
                field,
                selectOptions,
                control,
                register,
                selectContainerStyles,
                dateContainerStyles,
                errors,
                profileName,
              }}
            />
          ))}
      </div>
      <ReleaseDetailsFormControlledFields
        {...{
          control,
          selectOptions,
          selectContainerStyles,
          errors,
          isPreviouslyReleased,
          dateContainerStyles,
        }}
      />
    </div>
  )
}

ReleaseDetailsForm.propTypes = {
  genres: arrayOf(shape({})).isRequired,
  profileName: string.isRequired,
}
