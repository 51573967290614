import React, { useEffect } from 'react'
import { oneOfType, element, func } from 'prop-types'
import { navigate } from '@reach/router'

import { NAVIGATION_PATHS } from '#routes/routes'

export const PublicRoute = ({ component: Component, ...props }) => {
  useEffect(() => {
    const isAuth = localStorage.getItem('auth')
    if (isAuth) {
      navigate(NAVIGATION_PATHS.SELL_COLLECTIBLES)
    }
  }, [])
  return <Component {...props} />
}

PublicRoute.propTypes = {
  component: oneOfType([element, func]).isRequired,
}
