import React, { useState } from 'react'
import { func, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'
import { StructureTree } from '#modules/choose-file-tree'
import { Button } from '#components/button'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  btnWrapper: {
    background: theme.palette.color.darkGrey12,
    borderTop: `1px solid ${hexToRgba(theme.palette.color.default, 0.33)}`,
    height: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerWrapper: {
    overflowY: 'scroll',
    display: 'flex',
    flex: 1,
    height: 500,
  },
}))

export const ChooseFile = ({
  handleSelectFile,
  structureId,
  structureCategory,
}) => {
  const [selectedFileId, setSelectedFileId] = useState(null)
  const classes = useStyles()
  const { t } = useTranslation('connect')

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.innerWrapper}>
        <StructureTree
          parentId={structureId}
          structureCategory={structureCategory}
          handleFileSelect={({ id }) => setSelectedFileId(id)}
          selectedFileId={selectedFileId}
          width="100%"
          noBorder
        />
      </div>
      <div className={classes.btnWrapper}>
        <Button
          disabled={!selectedFileId}
          onClick={() => handleSelectFile(selectedFileId)}
        >
          {t('selectFile')}
        </Button>
      </div>
    </div>
  )
}

ChooseFile.propTypes = {
  handleSelectFile: func.isRequired,
  structureId: string.isRequired,
  structureCategory: string.isRequired,
}
