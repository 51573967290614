/**
 * Applies mask in hash form e.g mask('XXAAA2232123', '##-###-##-#####') returns XX-AAA-22-32123
 * @param value - string value on which the mask will be applied
 * @param pattern - mask pattern in hash form
 */

export const applyMask = (value, pattern) => {
  let i = 0
  const v = value.toString()
  return pattern.replace(/#/g, () => v[i++] || '')
}
