import React, { useEffect, useState } from 'react'
import { func, shape, string, bool, node } from 'prop-types'
import styled from 'styled-components'
import { ExpandableRowContent } from '#components/tree-select/_components/expandable-row-content'
import { ROW_HEIGHT } from '#components/tree-select/_constants'

const SExpandableRow = styled.li`
  display: flex;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    isEven,
  }) => (isEven ? COLORS.neutral_100 : COLORS.neutral_95)};
  width: 100%;
  flex-direction: column;
`

const SExpandButton = styled.button`
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
    isExpanded,
  }) => (isExpanded ? COLORS.primary_70 : 'transparent')};
  padding: 0 1rem 0 3.5rem;
  height: ${ROW_HEIGHT}px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.lg};
  text-align: left;
`

const SLabel = styled.div`
  overflow: hidden;
  max-width: calc(100% - 5.25rem);
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 0.5rem;
`

const SIconWrapper = styled.div`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    isExpanded,
  }) => (isExpanded ? COLORS.neutral_0 : COLORS.primary_50)};
  display: flex;
  align-items: center;
`

export const ExpandableRow = ({
  option,
  fetchItems,
  selectedOption,
  isExpanded,
  isEven,
  onExpand,
  onSelect,
}) => {
  const [loadingItems, setLoadingItems] = useState(false)
  const [items, setItems] = useState([])
  const [error, setError] = useState(false)

  const loadItems = async () => {
    try {
      setLoadingItems(true)
      const data = await fetchItems(option.value)

      setItems(data)
    } catch {
      setError(true)
    } finally {
      setLoadingItems(false)
    }
  }
  useEffect(() => {
    if (isExpanded && !items?.length) loadItems()
  }, [isExpanded])

  return (
    <SExpandableRow isEven={isEven}>
      <SExpandButton
        type="button"
        isExpanded={isExpanded}
        onClick={() => onExpand(option)}
      >
        <SIconWrapper isExpanded={isExpanded}>{option.icon}</SIconWrapper>
        <SLabel>{option.label}</SLabel>
      </SExpandButton>
      {isExpanded && (
        <ExpandableRowContent
          items={items}
          selectedOption={selectedOption}
          isLoading={loadingItems}
          error={error}
          onSelect={onSelect}
        />
      )}
    </SExpandableRow>
  )
}

ExpandableRow.propTypes = {
  option: shape({
    label: string,
    value: string,
    isExpandable: bool,
    icon: node,
  }).isRequired,
  fetchItems: func,
  onSelect: func,
  selectedOption: shape({
    label: string,
    value: string,
    isExpandable: bool,
    icon: node,
  }),
  isEven: bool,
  isExpanded: bool,
  onExpand: func,
}
