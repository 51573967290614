import React from 'react'
import { makeStyles } from '@material-ui/core'
import { arrayOf, shape, string, number, func, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import { BaseLoader } from '#components/loaders/base-loader'
import { Header } from '#pages/vault/song/tabs/finger-print/components/header'
import { Pagination } from '#components/pagination'
import { HEADERS } from '#pages/vault/song/tabs/finger-print/utils/constants'
import { determineMostRecent } from '#utils/determineMostRecent'
import { printDate } from '#utils/printDate'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { UserImage } from '#components/user-image'
import { Row } from '#pages/vault/song/tabs/finger-print/components/row'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  name: {
    fontWeight: 'bold',
    paddingLeft: '1rem',
  },
  date: {
    textAlign: 'right',
    color: theme.palette.color.primary,
  },
  dateColumn: {
    width: HEADERS[2].desktopWidth,
  },
  msg: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: HEADERS[1].desktopWidth,
  },
  userImage: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1rem',
  },
  imageColumn: {
    flex: 1,
    width: HEADERS[0].desktopWidth,
  },
}))

export const FingerPrintsList = ({
  fingerprints,
  rowsPerPage,
  setRowsPerPage,
  pageIndex,
  setPageIndex,
  isLoading,
  count,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <ColumnWrapper>
      <Header headers={HEADERS} />
      {fingerprints &&
        fingerprints.map((print, index) => {
          return (
            <Row key={print.id} isOdd={!isEven(index)}>
              <div className={classes.imageColumn}>
                <div className={classes.userImage}>
                  <UserImage
                    userImageUrl={print?.creatorUser?.profileImageUrl}
                  />
                  <span className={classes.name}>{print.userName}</span>
                </div>
              </div>
              <Tooltip title={print.message}>
                <div className={classes.msg}>{print.message}</div>
              </Tooltip>
              <Tooltip
                title={printDate(print.createdAt)}
                placement="bottom-end"
              >
                <div className={classes.dateColumn}>
                  <div className={classes.date}>
                    {determineMostRecent(print.createdAt)}
                  </div>
                </div>
              </Tooltip>
            </Row>
          )
        })}
      <Pagination
        count={count}
        page={pageIndex}
        rowsPerPage={rowsPerPage}
        onChangePage={setPageIndex}
        onChangeRowsPerPage={nextRowsPerPage => {
          setRowsPerPage(nextRowsPerPage)
          setPageIndex(0)
        }}
      />
      <BaseLoader isLoading={isLoading} text={t('fingerPrintsLoading')} />
    </ColumnWrapper>
  )
}

FingerPrintsList.propTypes = {
  fingerprints: arrayOf(
    shape({
      name: string,
      mostRecent: string,
      activity: string,
    })
  ).isRequired,
  rowsPerPage: number.isRequired,
  pageIndex: number.isRequired,
  setRowsPerPage: func.isRequired,
  setPageIndex: func.isRequired,
  isLoading: bool.isRequired,
  count: number.isRequired,
}
