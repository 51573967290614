import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import common from './en/common.json'
import home from './en/home.json'
import onboarding from './en/onboarding.json'
import vault from './en/vault.json'
import nft from './en/nft.json'
import distro from './en/distro.json'
import promotion from './en/promotion.json'
import mastering from './en/mastering.json'
import profile from './en/profile.json'
import connect from './en/connect.json'
import videogo from './en/videogo.json'
import validation from './en/validation.json'
import notifications from './en/notifications.json'
import connectWallet from './en/connect-wallet.json'
import creatorsVaultSetup from './en/creators-vault-setup.json'
import sellCollectibles from './en/sell-collectibles.json'
import learnWeb3 from './en/learn-web3.json'
import nftWizard from './en/nft-wizard.json'
import gettingStarted from './en/getting-started.json'

const resources = {
  en: {
    common,
    home,
    onboarding,
    vault,
    nft,
    distro,
    promotion,
    mastering,
    profile,
    connect,
    videogo,
    validation,
    notifications,
    creatorsVaultSetup,
    connectWallet,
    sellCollectibles,
    learnWeb3,
    nftWizard,
    gettingStarted,
  },
}

export const getCommonConfig = isDevMode => ({
  resources,
  defaultNS: 'common',
  fallbackLng: 'en',
  debug: isDevMode,
  interpolation: {
    escapeValue: false,
  },
})

export const i18nInit = isDevMode =>
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(getCommonConfig(isDevMode))
