import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import {
  getStatusColor,
  getStatusLabel,
} from '#pages/sell-collectibles/create-collectible/_utils'

const SStatus = styled.div`
  text-transform: uppercase;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-align: center;
  white-space: nowrap;
  color: ${({ color }) => color};
`

export const StatusButton = ({ status }) => {
  return (
    <SStatus color={getStatusColor(status)}>{getStatusLabel(status)}</SStatus>
  )
}

StatusButton.propTypes = {
  status: string.isRequired,
}
