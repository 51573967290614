import React, { useState } from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeFormInputs } from '#modules/payments/components/stripe-form-inputs'
import { Button } from '#components/button'
import { useStripeFormValidation } from '#hooks/useStripeFormValidation'
import { StripeIcons } from '#modules/payments/components/stripe-icons'
import { hexToRgba } from '#utils/hexToRgba'

const SButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
`

const SCancelButton = styled(Button)`
  color: ${({ theme: { colors } }) => colors.primary};
  border: none;
  background-color: transparent;
  text-decoration: underline;
  text-transform: unset;
  margin: 0;
`

const useStyles = makeStyles(theme => ({
  modalContainer: {
    backgroundColor: hexToRgba(theme.palette.color.black, 0.7),
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '1000',
  },
  modalWrapper: {
    maxWidth: 450,
    minHeight: 550,
    maxHeight: 650,
    overflowY: 'auto',
    padding: '1.5rem',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    position: 'absolute',
    backgroundColor: theme.palette.color.black,
    border: `2px solid ${theme.palette.color.primary}`,
  },
  modalTitle: {
    width: 310,
    fontSize: 22,
    textAlign: 'center',
    fontWeight: 700,
    marginBottom: 10,
  },
  stripeForm: {
    width: 310,
  },
  formButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const AddPaymentMethodModal = ({ onClose, onSubmit }) => {
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const elements = useElements()
  const stripe = useStripe()
  const classes = useStyles()
  const [nameOnCard, setNameOnCard] = useState('')

  const { cardNumberError, cardCvcError, cardExpiryError, areFieldsValid } =
    useStripeFormValidation(elements)

  const submitButtonDisabled = !areFieldsValid || !nameOnCard

  const onClickSubmit = () =>
    onSubmit(stripe, elements.getElement(CardNumberElement), nameOnCard)

  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalWrapper}>
        <div className={classes.modalTitle}>
          {profileTranslation.t('addNewPaymentMethod').toUpperCase()}
        </div>
        <div className={classes.stripeForm}>
          <StripeFormInputs
            {...{
              cardNumberError,
              cardCvcError,
              cardExpiryError,
              nameOnCard,
              setNameOnCard,
            }}
          />
          <StripeIcons isReversed />
          <div className={classes.formButtons}>
            <SButton disabled={submitButtonDisabled} onClick={onClickSubmit}>
              {profileTranslation.t('addPaymentMethod')}
            </SButton>
            <SCancelButton onClick={onClose}>{t('cancel')}</SCancelButton>
          </div>
        </div>
      </div>
    </div>
  )
}

AddPaymentMethodModal.propTypes = {
  onClose: func.isRequired,
  onSubmit: func.isRequired,
}
