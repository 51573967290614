/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { initSentry } from '#config/sentry'
import { API_URL } from '#api/api/static'
import { App } from './app'
import { i18nInit } from './locales/i18n'

let isDevMode = false

if (IS_DEV === true) {
  isDevMode = true
} else {
  initSentry()
}

// eslint-disable-next-line no-console
console.log('isDevMode', isDevMode)
// eslint-disable-next-line no-console
console.log('environment', APP_ENV)
i18nInit(isDevMode)

export const AppWrapper = () => {
  const [redirect, setRedirect] = useState(null)
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_API_URL}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={API_URL}
      scope="offline_access openid profile email address phone read:current_user"
      useRefreshTokens
      onRedirectCallback={setRedirect}
    >
      <App redirectAfterLogin={redirect} isDevMode={isDevMode} />
    </Auth0Provider>
  )
}

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
})(AppWrapper)

ReactDOM.render(<LDProvider />, document.getElementById('root'))
