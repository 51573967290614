import React from 'react'
import { TopSection } from '#pages/distro/landing-page/sections/top-section'
import { DistributionSection } from '#pages/distro/landing-page/sections/distribution-section'
import { StoresSection } from '#pages/distro/landing-page/sections/stores-section'
import { RoyaltiesSection } from '#pages/distro/landing-page/sections/royalties-section'
import { DiscoverSection } from '#pages/distro/landing-page/sections/discover-section'
import { PageWrapper } from '#modules/page-wrapper'
import { SingleOrAlbumChoose } from '#pages/distro/_components/single-or-album-choose'

export const LandingPage = () => {
  return (
    <PageWrapper isFullWidth showNavBar>
      <TopSection />
      <DistributionSection />
      <StoresSection />
      <RoyaltiesSection />
      <DiscoverSection />
      <SingleOrAlbumChoose />
    </PageWrapper>
  )
}
