import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { string } from 'prop-types'

const SLoader = styled.img`
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
`

const SText = styled.p`
  text-align: center;
`

export const TransactionLoader = ({ text }) => {
  const { t } = useTranslation('connectWallet')

  return (
    <>
      <SLoader src="/assets/gifs/loading.gif" alt="loading" />
      <SText>{text || t('confirmTransactionInDapperPopup')}</SText>
    </>
  )
}

TransactionLoader.propTypes = {
  text: string,
}
