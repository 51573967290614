import React from 'react'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { EditRow } from '#pages/vault/profile-content/components/rows/edit-row'
import { DuplicateRowInline } from '#pages/vault/profile-content/components/rows/duplicate-row'
import { MainTableRow } from '#pages/vault/profile-content/components/rows/main-table-row'
import { useVault } from '#hooks/useVault'

export const ContentTableRow = ({
  row,
  index,
  parent,
  parentId,
  typeOfCurrentAction,
  idOfActiveActionItem,
  setStructureCategory,
  shouldEnableStructureDeletion,
  handleCloseInlineAction,
  handleDeleteModalOpen,
  handleDeleteModalClose,
  setTypeOfCurrentAction,
  setIdOfActiveActionItem,
  handleAddEditCollaborators,
  handleMoveSingle,
}) => {
  const { id, category, name, childrenUpdatedAt, accessRoles } = row
  const isActiveActionItem = idOfActiveActionItem === id

  const isEditRow =
    typeOfCurrentAction === ELLIPSIS_OPTIONS.EDIT_INLINE && isActiveActionItem
  const isDuplicateRow =
    typeOfCurrentAction === ELLIPSIS_OPTIONS.DUPLICATE_INLINE &&
    isActiveActionItem

  const { expandedStructureId } = useVault()

  switch (true) {
    case isEditRow:
      return (
        <EditRow
          key={name + id}
          {...{
            shouldEnableDeletion: shouldEnableStructureDeletion(accessRoles),
            name,
            id,
            index,
            parentId,
            category,
            handleCloseInlineAction,
            handleDeleteModalOpen,
            handleDeleteModalClose,
            isSelected: expandedStructureId === id,
          }}
        />
      )
    case isDuplicateRow:
      return (
        <DuplicateRowInline
          key={name + id}
          {...{
            name,
            id,
            parentId,
            category,
            handleCloseInlineAction,
          }}
        />
      )
    default:
      return (
        <MainTableRow
          {...{
            accessRoles,
            category,
            name,
            updatedAt: childrenUpdatedAt,
            index,
            id,
            parent,
            typeOfCurrentAction,
            setTypeOfCurrentAction,
            idOfActiveActionItem,
            setIdOfActiveActionItem,
            handleCloseInlineAction,
            handleAddEditCollaborators,
            setStructureCategory,
            handleMoveSingle,
          }}
        />
      )
  }
}
