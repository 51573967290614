import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { navigate } from '@reach/router'
import { shape } from 'prop-types'
import styled from 'styled-components'
import { ROUTES } from '#routes/routes'
import { Button } from '#components/button'
import { ParticleSphere } from '#pages/home/default-homepage/sections/default-welcome-section/components/particleSphere'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { mobileAppLink } from '#utils/mobileAppLink'
import {
  Background,
  VideoContainer,
  Video,
  Particles,
  Content,
  LogoWrapper,
  Logo,
  Title,
  Bold,
  BuiltFor,
  ButtonsWrapper,
  SButton,
} from '#pages/download-mobile-app/styled'

const SLink = styled.a`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.default};
  text-decoration: none;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  white-space: pre;
  text-align: center;
  margin-top: 40px;
`

export const DownloadMobileApp = ({ location }) => {
  const { t } = useTranslation('home')
  const { loginWithRedirect } = useAuth0()
  const { allowSignups } = useFlags()
  const REDIRECT_URL = window.location.origin

  const marketplaceRedirect = () =>
    navigate(process.env.REACT_APP_MARKETPLACE_URL)

  const creatorsRedirect = () => navigate(process.env.REACT_APP_CREATORS_URL)
  const signupRedirect = () => navigate(process.env.REACT_APP_MOBILE_APP_URL)

  const vaultUserRedirect = allowSignups ? signupRedirect : creatorsRedirect

  useEffect(() => {
    if (location.state?.isVip) {
      loginWithRedirect({
        initialScreenTab: 'signup',
        prompt: 'login',
        redirectUri: `${REDIRECT_URL}${ROUTES.VERIFY_EMAIL_MOBILE}`,
      })
    }
  }, [REDIRECT_URL, location, loginWithRedirect])

  const video = useRef()
  useEffect(() => {
    video.current.play()
  })

  return (
    <Background>
      <VideoContainer>
        <Video ref={video} id="myVideo" autoPlay muted loop>
          <source src="assets/mp4/main-bg.mp4" type="video/mp4" />
        </Video>
      </VideoContainer>
      <Particles>
        <ParticleSphere />
        <Content>
          <LogoWrapper>
            <Logo src="/assets/svg/tune-go-logo.svg" alt="TuneGo" />
          </LogoWrapper>
          <Title>
            <Bold>{t('poweringTheFutureOfMusic')}</Bold>
          </Title>
          <BuiltFor>{t('builtForCreatorsFansAnd')}</BuiltFor>
          <ButtonsWrapper>
            <SButton onClick={vaultUserRedirect}>
              {t('creatorsStartHere')}
            </SButton>
            <Button onClick={marketplaceRedirect}>
              {t('collectorsStartHere')}
            </Button>
          </ButtonsWrapper>
          <SLink href={mobileAppLink()} target="_blank">
            {t('loginToApp')}
          </SLink>
        </Content>
      </Particles>
    </Background>
  )
}

DownloadMobileApp.propTypes = {
  location: shape({}).isRequired,
}
