import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { COLLECTIBLE_COLUMNS } from '#pages/sell-collectibles/my-collectibles/_utils/constants'

const STableHeader = styled.thead`
  background-color: ${({ theme: { colors } }) => colors.minorGrey};
  height: 3.75rem;
`

const SHeader = styled.th`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  padding: 0 1.25rem;
  white-space: nowrap;
`

export const ListHeader = () => {
  const { t } = useTranslation('sellCollectibles')

  return (
    <STableHeader>
      <tr>
        {COLLECTIBLE_COLUMNS(t).map(({ label, key, centered }) => (
          <SHeader key={`header=${key}`} centered={centered}>
            {label}
          </SHeader>
        ))}
      </tr>
    </STableHeader>
  )
}
