import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '#components/button'

import { deleteLocalStorageValue } from '#utils/localStorage'
import { useBlockchainAccount } from '#modules/blockchain'
import { useUploadManager } from '#modules/upload-manager/context'

const SButton = styled(Button)`
  margin: 3.75rem;
  text-transform: uppercase;
`

export const Logout = () => {
  const { t } = useTranslation('profile')
  const { logout } = useAuth0()
  const { disconnectWallet } = useBlockchainAccount()
  const { cancelAllUploads } = useUploadManager()

  const clearStorageAndLogout = async () => {
    await cancelAllUploads()
    disconnectWallet()
    deleteLocalStorageValue('auth')
    logout({ returnTo: window.location.origin })
  }

  return (
    <SButton onClick={() => clearStorageAndLogout()}>
      {t('logoutOfMyAccount')}
    </SButton>
  )
}
