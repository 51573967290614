import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { arrayOf, string, shape, func, bool } from 'prop-types'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import {
  selectCustomStyles,
  selectCustomStylesProfiles,
  customMultiselectStyles,
} from '#components/inputs/constants/customSelectStyles'
import { Label } from '#components/label'
import { ErrorMessage } from '#components/error-message'
import { usePopperReactSelect } from '#hooks/usePopperReactSelect'

const SCustomSelect = styled.div``

export const CustomSelect = ({
  label,
  errorMessage,
  options,
  onChange,
  className,
  isMulti,
  isBolded,
  isUpperCased,
  required,
  isForProfiles,
  placeholder,
  hasNftStyles,
  menuPosition,
  id,
  ...props
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const displayedPlaceholder = placeholder || t('selectPlaceholder')
  const [menuOpened, setMenuOpened] = useState(false)

  const baseStyles = isMulti
    ? customMultiselectStyles(theme, { errorMessage })
    : isForProfiles
    ? selectCustomStylesProfiles(theme, { errorMessage })
    : selectCustomStyles(theme, { errorMessage, hasNftStyles })

  const { styles } = usePopperReactSelect({
    id,
    opened: menuOpened,
    baseStyles,
  })

  return (
    <SCustomSelect className={className}>
      {label && (
        <Label
          name={label}
          label={label}
          isBolded={isBolded}
          isUpperCased={isUpperCased}
          required={required}
        />
      )}
      <Select
        isMulti={isMulti}
        className="basic-single"
        classNamePrefix={id}
        styles={styles}
        options={options}
        placeholder={displayedPlaceholder}
        menuPosition={menuPosition}
        onChange={onChange}
        onMenuOpen={() => setMenuOpened(true)}
        onMenuClose={() => setMenuOpened(false)}
        {...props}
      />
      {!!errorMessage && <ErrorMessage errorMessage={errorMessage} />}
    </SCustomSelect>
  )
}

CustomSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  onChange: func.isRequired,
  errorMessage: string,
  className: string,
  isMulti: bool,
  label: string,
  isBolded: bool,
  isUpperCased: bool,
  required: bool,
  isForProfiles: bool,
  placeholder: string,
  hasNftStyles: bool,
  menuPosition: string,
  id: string.isRequired,
}

CustomSelect.defaultProps = {
  label: '',
  isMulti: false,
  errorMessage: null,
  className: '',
  isBolded: false,
  isUpperCased: false,
  required: false,
  isForProfiles: false,
  placeholder: '',
  hasNftStyles: false,
}
