export const ERRORS = {
  409: 'youHaveNoAccessToContent',
}

export const ApiError = {
  None: 'none',
  General: 'general',
  NotFound: 'notFound',
  BadRequest: 'badRequest',
  Unauthorized: 'unauthorized',
  Forbidden: 'forbidden',
  Timeout: 'requestTimeout',
  TooManyRequests: 'tooManyRequests',
  InternalServerError: 'internalServerError',
  BadGateway: 'badGateway',
  ServiceUnavailable: 'serviceUnavailable',
  Conflict: 'conflict',
}

export const ErrorCodesMap = {
  [ApiError.BadRequest]: 400,
  [ApiError.Unauthorized]: 401,
  [ApiError.Forbidden]: 403,
  [ApiError.NotFound]: 404,
  [ApiError.Timeout]: 408,
  [ApiError.Conflict]: 409,
  [ApiError.TooManyRequests]: 429,
  [ApiError.InternalServerError]: 500,
  [ApiError.BadGateway]: 502,
  [ApiError.ServiceUnavailable]: 503,
}
