import React, { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { string, shape, number, func, bool } from 'prop-types'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SongFileMetadataContainer } from '#pages/distro/wizard/steps/add-song-files/containers/song-file-metadata-container'
import { SongFileMenu } from '#pages/distro/wizard/steps/add-song-files/components/song-file-menu'
import { FIELDS, PERFORMANCE_TYPE } from '#pages/distro/_utils/constants'
import { useContributors } from '#hooks/swr/useContributors'
import { checkIfHasRole } from '#pages/distro/_utils/helpers'

export const SongFile = ({ file, index, onDeleteSong }) => {
  const { setStoredFormState, storedFormState } = useWizard()

  const {
    id,
    primaryGenre,
    explicitContent,
    performanceLanguage,
    name,
    songId,
    isMetadataFormOpened,
  } = file
  const [selectedFileId, setSelectedFileId] = useState()
  const [persistedData, setPersistedData] = useState({})

  const { contributors } = useContributors(id, '')
  const { data } = contributors || {}
  const hasComposer = checkIfHasRole(data, 'composer')
  const isInstrumental = performanceLanguage === PERFORMANCE_TYPE.INSTRUMENTAL
  const hasLyricist = isInstrumental || checkIfHasRole(data, 'lyricist')

  const isReadyForDistribution =
    !!(primaryGenre && explicitContent) && hasComposer && hasLyricist

  const handleFileSelect = () => {
    const selected = storedFormState[FIELDS.SONG_FILES].find(
      item => item.id === id
    )
    setSelectedFileId(selected?.id)

    setStoredFormState({
      // eslint-disable-next-line no-shadow
      [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file => ({
        ...file,
        isMetadataFormOpened: id === selected?.id,
      })),
    })
  }

  const handleFileDelete = () => onDeleteSong(songId)

  return (
    <Draggable key={id} draggableId={songId} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <SongFileMenu
            fileId={id}
            index={index}
            isReadyForDistribution={isReadyForDistribution}
            itemProvided={provided}
            name={name}
            isMetadataMenuVisible={isMetadataFormOpened}
            onClick={handleFileSelect}
            onDeleteClick={handleFileDelete}
          />
          {isMetadataFormOpened && (
            <SongFileMetadataContainer
              selectedFileId={selectedFileId}
              persistedData={persistedData}
              setPersistedData={setPersistedData}
            />
          )}
        </div>
      )}
    </Draggable>
  )
}

SongFile.propTypes = {
  file: shape({
    id: string,
    primaryArtistName: string,
    songName: string,
    primaryGenre: string,
    explicitContent: string,
    performanceLanguage: string,
    name: string,
    songId: string,
    isMetadataFormOpened: bool,
  }).isRequired,
  index: number.isRequired,
  onDeleteSong: func.isRequired,
}
