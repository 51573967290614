export const yesNoYES = {
  label: 'Yes',
  value: true,
}
export const yesNoNO = {
  label: 'No',
  value: false,
}

export const wasPreviouslyReleased = [yesNoYES, yesNoNO]
