import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { bool, func, shape, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { CustomDropzone } from '#components/custom-dropzone'
import { BaseInput } from '#components/inputs/base-input'
import { LocationSearch } from '#components/inputs/location-search'
import { userDetailsValidationSchema } from '#pages/profile/utils/validation'
import { Button } from '#components/button'
import { ACCEPTED_IMAGE_TYPES } from '#constants/acceptedFileTypes'
import { DropzoneOverlay } from '#components/custom-dropzone/DropzoneOverlay'
import { DEFAULT_AVATAR } from '#constants/defaultAvatar'
import { avatarValidator } from '#pages/onboarding/wizard/utils/avatar-validator'
import { AvatarRequirements } from '#pages/_components/avatar-requirements'
import { Tooltip } from '#components/tooltip'

const SForm = styled.form`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: none;
  margin: 60px 100px 40px;

  &::-webkit-scrollbar {
    display: none;
  }
`

const SCustomDropzone = styled(CustomDropzone)`
  border-radius: 50% !important;
  border: none !important;
  box-shadow: none !important;
  position: relative;
  height: 250px;
  width: 250px;
  margin-bottom: 10px;
  background-image: ${({ placeholder, avatarPreview }) =>
    placeholder
      ? `url(${placeholder})`
      : avatarPreview
      ? `url(${avatarPreview})`
      : `url(${DEFAULT_AVATAR})`};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`

const SButton = styled(Button)`
  margin-top: 2rem;
  text-transform: uppercase;
`

const STooltip = styled(Tooltip)`
  width: 250px;
`

const SDropzoneOverlay = styled(DropzoneOverlay)`
  ${({ add }) =>
    add &&
    css`
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 2.25rem;
    `};
`

export const UserDetails = ({
  handleSave,
  onDrop,
  onDropRejected,
  errorMessage,
  preview,
  lastName,
  firstName,
  location,
  placeholder,
}) => {
  const { t } = useTranslation()
  const validationTranslation = useTranslation('validation')
  const profileTranslation = useTranslation('profile')
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(userDetailsValidationSchema(profileTranslation.t)),
  })

  useEffect(() => {
    reset({ firstName, lastName, location })
  }, [firstName, lastName, location, reset])

  const onSubmit = async (data, e) => {
    await handleSave(data, e)
    reset(data)
  }

  const avatarPreview = preview ? buildCloudFrontImgUrl(preview) : ''

  return (
    <SForm onSubmit={handleSubmit(onSubmit)}>
      <STooltip text={<AvatarRequirements />}>
        <SCustomDropzone
          accept={ACCEPTED_IMAGE_TYPES}
          validator={file => avatarValidator(file, validationTranslation.t)}
          placeholder={placeholder}
          avatarPreview={avatarPreview}
          onDropAccepted={onDrop}
          onDropRejected={onDropRejected}
        >
          <SDropzoneOverlay
            add={!avatarPreview}
            description={t(avatarPreview ? 'edit' : 'upload')}
          />
        </SCustomDropzone>
      </STooltip>
      {errorMessage}
      <BaseInput
        defaultValue={firstName}
        name="firstName"
        label={profileTranslation.t('firstName')}
        inputRef={register}
        errorMessage={errors.firstName?.message}
      />
      <BaseInput
        defaultValue={lastName}
        name="lastName"
        label={profileTranslation.t('lastName')}
        inputRef={register}
        errorMessage={errors.lastName?.message}
      />
      <LocationSearch
        label={profileTranslation.t('location')}
        placeholder="Search"
        error={!!errors.location}
        errorMessage={errors.location?.message}
        name="location"
        inputRef={register}
        setValue={setValue}
        defaultValue={location}
      />
      <SButton type="submit" disabled={!isDirty}>
        {t('save')}
      </SButton>
    </SForm>
  )
}

UserDetails.propTypes = {
  handleSave: func.isRequired,
  onDrop: func.isRequired,
  onDropRejected: func.isRequired,
  preview: string,
  placeholder: string,
  lastName: string,
  firstName: string,
  errorMessage: bool.isRequired,
  location: shape({
    cityName: string,
    countryCode: string,
    placeName: string,
    lat: string,
    lng: string,
  }).isRequired,
}

UserDetails.defaultProps = {
  placeholder: null,
  preview: '',
  firstName: '',
  lastName: '',
}
