import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useParams } from '@reach/router'
import { saveAs } from 'file-saver'
import { mutate } from 'swr'
import { yupResolver } from '@hookform/resolvers/yup'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { TextArea } from '#components/inputs/textarea'
import { ArtistApi } from '#api/requests/artist'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { buildUrl } from '#utils/buildUrl'
import { ENDPOINTS } from '#api/endpoints'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { lyricsValidationSchema } from '#pages/vault/song/utils/validation'
import { Text, TEXT_TYPE } from '#components/text'

const SButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

const SButtonDownload = styled(Button)`
  padding: 0;
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
`

const useStyles = makeStyles(() => ({
  header: {
    fontSize: 22,
  },
  lyricsForm: {
    padding: '2rem',
  },
  lyricsContainer: {
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
  },
  saveLyricsButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  textArea: {
    marginRight: '4.5rem',
    flex: 1,
    maxWidth: 480,
    height: 460,
  },
}))

const LYRICS_FIELD_NAME = 'lyrics'
const isEmpty = value => {
  if (!value) return true
  return value.trim().length === 0
}

export const Lyrics = () => {
  const [error, setError] = useState('')
  const { t } = useTranslation('vault')
  const classes = useStyles()
  const { projectId } = useParams()
  const { project, isLoading } = useArtistStructure(projectId)
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      [LYRICS_FIELD_NAME]: project.lyrics || '',
    },
    resolver: yupResolver(lyricsValidationSchema(t)),
  })

  const {
    actions,
    loading,
    errors: asyncErrors,
  } = useWithAsyncAction({
    patchArtistSong: ArtistApi.patchArtistSong,
  })

  const handleSetError = useCallback(err => setError(err.message), [])
  useEffect(() => {
    handleSetError(asyncErrors.patchArtistSong)
  }, [asyncErrors, handleSetError])

  useEffect(() => {
    setValue(LYRICS_FIELD_NAME, project?.lyrics)
  }, [project, setValue])

  const triggerMutate = () => {
    const url = `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE, {
      id: projectId,
    })}`
    mutate(url)
  }

  const onSubmit = async values => {
    await actions.patchArtistSong(projectId, values)
    triggerMutate()
    reset(values)
  }

  const handleDownload = () => {
    const blob = new Blob([getValues(LYRICS_FIELD_NAME)], {
      type: 'text/plain;charset=utf-8',
    })
    saveAs(blob, `${project.name}-lyrics.txt`)
  }

  if (loading.patchArtistSong || error) {
    return (
      <CommonLoadingOverlay
        message={t('lyricsSaving')}
        dialogOpen={loading.patchArtistSong}
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  return (
    <ColumnWrapper>
      <BaseLoader isLoading={isLoading} text={t('lyricsLoading')} />
      {!isLoading && (
        <form className={classes.lyricsForm} onSubmit={handleSubmit(onSubmit)}>
          <Text type={TEXT_TYPE.H2} className={classes.header}>
            {t('lyrics')}
          </Text>
          <div className={classes.lyricsContainer}>
            <div className={classes.textArea}>
              <TextArea
                name={LYRICS_FIELD_NAME}
                inputRef={register}
                placeholder={t('addYourLyrics')}
                errorMessage={errors.lyrics?.message}
              />
            </div>
            <div className={classes.saveLyricsButton}>
              <SButton type="submit" disabled={!isDirty}>
                {t('saveLyrics')}
              </SButton>
              {(isDirty || !isEmpty(getValues(LYRICS_FIELD_NAME))) && (
                <SButtonDownload
                  variant={VARIANTS.NEW_BLUE_SPECIAL}
                  disabled={isDirty}
                  onClick={handleDownload}
                >
                  {t('downloadLyrics')}
                </SButtonDownload>
              )}
            </div>
          </div>
        </form>
      )}
    </ColumnWrapper>
  )
}
