export const SHOW_FILES_CONDITION = {
  PROMOTION: 'isPromotionAllowed',
  MASTERING: 'isMasteringAllowed',
  DISTRO: 'isDistributionAllowed',
  AUDIO: 'isAudio',
  ARTWORK: 'isArtwork',
  VIDEO: 'isVideo',
  IMAGE: 'isImage',
  DIGITAL_ART: 'isDigitalArt',
  NFT: 'isNftAllowed',
  NFT_IMAGE: 'isNftImageAllowed',
}
