import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BolderTranslation } from '#components/bolder-translation'
import { OutlinedElement } from '#pages/distro/_components/outlined-element'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'
import { ROYALTIES_IMAGES } from '#pages/distro/landing-page/utils/constatns'
import { Text } from '#components/text'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: '2.5rem',
    background: 'url("/assets/images/royalties-background.png")',
    width: '100%',
    padding: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  royaltiesImages: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
    padding: '2rem 0',
  },
  royaltiesImage: {
    height: '8rem',
    paddingBottom: '3rem',
  },
  header: {
    fontSize: '50px',
    textTransform: 'uppercase',
    flexWrap: 'no-wrap',
    textAlign: 'center',
    paddingBottom: '4rem',
  },
  description: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '3rem',
    fontSize: '1.5rem',
    padding: '0 2rem',
    whiteSpace: 'pre-line',
  },
  simpleLabel: {
    fontFamily: 'AvenirNextLTPro-Bold',
    margin: 'auto',
    display: 'flex',
    position: 'relative',
    width: 'fit-content',
  },
  simpleLabelImg: {
    position: 'absolute',
    bottom: -10,
  },
  outlineBoxes: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: '4rem',
    maxWidth: '72.5rem',
  },
  outlineTitle: {
    fontFamily: 'AvenirNextLTPro-Bold',
    fontSize: '1.6rem',
  },
  outlineDescription: {
    margin: 0,
    fontSize: '1.2rem',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const RoyaltiesSection = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Text className={classes.header}>{t('collectRoyaltiesFrom')}</Text>
      <div className={classes.royaltiesImages}>
        {ROYALTIES_IMAGES.map(name => (
          <img
            key={name}
            className={classes.royaltiesImage}
            alt=""
            src={`/assets/images/company-icons/${name}.png`}
          />
        ))}
      </div>
      <div className={classes.description}>
        <Trans
          t={t}
          i18nKey="thereAreBillionsOfUsers"
          components={Array.from({ length: 7 }, (_, i) => (
            <BolderTranslation key={i} />
          ))}
        />
        <div className={classes.simpleLabel}>
          <span>{t('itIsSimple')}</span>
          <img
            src="/assets/svg/shortRedLine.svg"
            width="100%"
            className={classes.simpleLabelImg}
            alt=""
          />
        </div>
      </div>
      <div className={classes.outlineBoxes}>
        <OutlinedElement header={t('free')}>
          <span className={classes.outlineTitle}>
            {t('unlimitedMonetization')}
          </span>
          <p className={classes.outlineDescription}>{t('noAdditionalCost')} </p>
        </OutlinedElement>
        <OutlinedElement header={t('80percent')}>
          <span className={classes.outlineTitle}>
            {t('revenueFromSocials')}
          </span>
          <p className={classes.outlineDescription}>
            {t('keep80ofTheRevenue')}
          </p>
        </OutlinedElement>
      </div>
      <SButton onClick={() => navigate(NAVIGATION_PATHS.DISTRO_GET_STARTED)}>
        {t('getStarted')}
      </SButton>
    </div>
  )
}

RoyaltiesSection.propTypes = {
  getStartedUrl: string.isRequired,
}
