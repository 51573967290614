import React, { useEffect, useState, useRef } from 'react'
import { func, node, oneOfType, shape, arrayOf, string, bool } from 'prop-types'
import styled from 'styled-components'
import { inputMixin } from '#components/inputs/base-input'
import { Label } from '#components/label'
import { SearchIcon, ArrowDownIcon } from '#icons'
import { Dropdown } from '#components/inputs/search-autocomplete/_components/dropdown'
import { useOnClickOutside } from '@tunego/tunego-ui-components'

const SWrapper = styled.div`
  position: relative;
`

const SInputWrapper = styled.div`
  height: 3rem;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
`

const SInput = styled.input`
  ${inputMixin};
  flex-grow: 1;
  flex-shrink: 1;
`

export const SearchAutocomplete = ({
  name,
  label,
  placeholder,
  items,
  isLoading,
  showImg,
  onChange,
  onSelect,
}) => {
  const [query, setQuery] = useState('')
  const [selectedOption, setSelectedOption] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const autocomplete = useRef()

  useOnClickOutside(autocomplete, () => {
    setShowDropdown(false)
  })

  useEffect(() => {
    if (query !== selectedOption?.label) setSelectedOption(null)
  }, [query])

  useEffect(() => {
    setShowDropdown(!!query && (items.length > 0 || isLoading))
  }, [items, isLoading])

  const onInput = e => {
    const inputValue = e.target?.value || ''

    setQuery(inputValue)
    if (onChange) onChange(inputValue)
  }

  const handleSelectOption = option => {
    setQuery(option.label)
    setSelectedOption(option)
    if (onSelect) onSelect(option)
    setShowDropdown(false)
  }

  const onFocus = () => {
    if (query.length > 0 && !selectedOption && items.length > 0)
      setShowDropdown(true)
  }

  return (
    <SWrapper ref={autocomplete}>
      <Label {...{ name, label }} />
      <SInputWrapper>
        <SearchIcon />
        <SInput
          type="text"
          value={query}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          onInput={onInput}
          onFocus={onFocus}
        />
        <ArrowDownIcon />
      </SInputWrapper>
      {showDropdown && (
        <Dropdown
          items={items}
          isLoading={isLoading}
          showImg={showImg}
          onSelect={handleSelectOption}
        />
      )}
    </SWrapper>
  )
}

SearchAutocomplete.propTypes = {
  loadItems: func.isRequired,
  onChange: func,
  onSelect: func,
  name: string.isRequired,
  label: oneOfType([string, node]),
  placeholder: string,
  items: arrayOf(
    shape({ label: string, img: string, value: string, disabled: bool })
  ),
  isLoading: bool,
  showImg: bool,
}

SearchAutocomplete.defaultProps = {
  placeholder: 'Search',
}
