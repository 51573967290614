import React, { useEffect } from 'react'
import { node, arrayOf, oneOfType, bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { TGNavBar } from './components/page-navigation-bar'
import { PageFooter } from './components/page-footer'
import { GenericHeader } from './components/generic-header'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { hexToRgba } from '#utils/hexToRgba'
import { UploadManager } from '#modules/upload-manager'

const useStyles = ({
  withoutBorder,
  isFullWidth,
  headerHeight,
  isPlayerShown,
  makeCustomStyles,
}) =>
  makeStyles(theme => ({
    container: {
      maxWidth: isFullWidth ? '' : 1050,
      width: '100%',
      height: '100%',
      margin: 'auto',
      flexShrink: 0,
      minHeight: `calc(100vh - ${headerHeight})`,
      paddingBottom: isPlayerShown ? '100px' : 0,
      border: withoutBorder
        ? 'none'
        : `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
      borderTop: 'none',
      '@media(max-width: 700px)': {
        border: 'none',
        maxWidth: '100%',
      },
      ...makeCustomStyles(theme),
    },
  }))()

export const PageWrapper = ({
  children,
  showFooter,
  showNavBar,
  showGenericHeader,
  headerWithoutBorder,
  withoutBorder,
  isFullWidth,
  blackHeader,
  makeCustomStyles,
}) => {
  let headerHeight = '67px'
  if (showGenericHeader) {
    headerHeight = '60px'
  }
  if (!showGenericHeader && !showNavBar) {
    headerHeight = '0px'
  }
  const { fileInfo } = useGlobalAudioPlayer()
  const classes = useStyles({
    withoutBorder,
    isFullWidth,
    headerHeight,
    isPlayerShown: !!fileInfo,
    makeCustomStyles,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {showNavBar && <TGNavBar />}
      {showGenericHeader && (
        <GenericHeader
          isBlackBackground={blackHeader}
          shouldShowBorder={!headerWithoutBorder}
          height={headerHeight}
        />
      )}
      <div className={classes.container}>
        <div>{children}</div>
      </div>
      <UploadManager />
      {showFooter && <PageFooter />}
    </>
  )
}

PageWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  showFooter: bool,
  showNavBar: bool,
  showGenericHeader: bool,
  headerWithoutBorder: bool,
  withoutBorder: bool,
  isFullWidth: bool,
  blackHeader: bool,
  makeCustomStyles: func,
}
PageWrapper.defaultProps = {
  showFooter: false,
  showNavBar: false,
  showGenericHeader: false,
  headerWithoutBorder: false,
  withoutBorder: false,
  isFullWidth: false,
  blackHeader: false,
  makeCustomStyles: () => ({}),
}
