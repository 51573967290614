import React from 'react'
import { number, func } from 'prop-types'
import TablePagination from '@material-ui/core/TablePagination'

export const Pagination = ({
  count,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  return (
    // nested inside a standalone table to avoid DOM nesting validation errors
    <table style={{ width: '100%' }}>
      <tfoot>
        <tr>
          {count > 9 && (
            <TablePagination
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
              onChangePage={(_, nextPage) => {
                onChangePage(nextPage)
              }}
              onChangeRowsPerPage={event => {
                onChangeRowsPerPage(parseInt(event.target.value, 10))
              }}
            />
          )}
        </tr>
      </tfoot>
    </table>
  )
}

Pagination.propTypes = {
  count: number.isRequired,
  page: number.isRequired,
  onChangeRowsPerPage: func.isRequired,
  onChangePage: func.isRequired,
  rowsPerPage: number.isRequired,
}
