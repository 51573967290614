import * as yup from 'yup'
import i18next from 'i18next'

const role = t =>
  yup
    .array()
    .required(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('role'),
      })
    )
    .typeError(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('role'),
      })
    )

export const addExistingUserSplitValidationSchema = t => {
  return yup.object().shape({
    user: yup
      .object()
      .nullable()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('user'),
        })
      ),
    role: role(t),
  })
}

export const addSplitOffPlatformValidationSchema = t => {
  return yup.object().shape({
    pendingName: yup.string().required(
      i18next.t('validation:mustNotBeEmpty', {
        field: t('name'),
      })
    ),
    email: yup
      .string()
      .email(i18next.t('validation:validEmailAddress'))
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('email'),
        })
      ),
    role: role(t),
  })
}

export const editRoleValidationSchema = t => {
  return yup.object().shape({
    role: role(t),
  })
}
