import { ACCEPTED_IMAGE_EXTENSIONS } from '#constants/acceptedFileTypes'
import {
  MAX_IMAGE_SIZE_DISPLAY,
  MAX_IMAGE_SIZE_IN_BYTES,
} from '#pages/profile/utils/constants'

export const avatarValidator = (file, t) => {
  const { type, size } = file

  if (size > MAX_IMAGE_SIZE_IN_BYTES) {
    return {
      message: t('maxFileSizeExceeded', {
        maxSize: MAX_IMAGE_SIZE_DISPLAY,
      }),
    }
  }

  if (
    !ACCEPTED_IMAGE_EXTENSIONS.map(extension => `image/${extension}`).includes(
      type
    )
  ) {
    return {
      message: t('acceptedFiles', {
        extensions: ACCEPTED_IMAGE_EXTENSIONS.join(', '),
      }),
    }
  }

  return null
}
