import { globalHistory, navigate } from '@reach/router'
import { isString } from 'lodash'
import { NAVIGATION_PATHS } from '#routes/routes'

export const isOnMaintenancePage = () => {
  return globalHistory.location.pathname === NAVIGATION_PATHS.MAINTENANCE
}

export const saveAndRedirectToMaintenancePage = redirect => {
  if (!isOnMaintenancePage()) {
    window.tabMaintenanceBack = globalHistory.location.pathname
    localStorage.setItem('maintenanceBack', globalHistory.location.pathname)

    if (redirect) {
      navigate(NAVIGATION_PATHS.MAINTENANCE, { replace: true })
    }
  }
}

export const maintenanceSwitch = (maintenance, redirect, overwrite) => {
  if (maintenance) {
    const hadData = localStorage.getItem('maintenance')

    if (!hadData || overwrite) {
      const save = isString(maintenance)
        ? maintenance
        : JSON.stringify(maintenance)
      localStorage.setItem('maintenance', save)
    }

    saveAndRedirectToMaintenancePage(redirect)
    return
  }

  const back =
    window.tabMaintenanceBack ?? localStorage.getItem('maintenanceBack')

  localStorage.removeItem('maintenance')
  localStorage.removeItem('maintenanceBack')
  delete window.tabMaintenanceBack

  if (isOnMaintenancePage() && redirect) {
    navigate(back ?? NAVIGATION_PATHS.HOME, { replace: true })
  }
}
