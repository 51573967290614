import { ArtistApi } from '#api/requests/artist'

export const loadProfiles =
  (roles = null, useAltStructuresEndpoint = false) =>
  inputValue => {
    const params = {
      ...(inputValue && { partialName: inputValue }),
      ...(roles && { roles }),
    }

    return ArtistApi.getArtists({ params }, useAltStructuresEndpoint).then(
      ({ data }) => {
        return data.data.map(d => {
          return {
            label: d.name,
            value: d.id,
            avatarUrl: d.avatarUrl,
            filesCount: d.filesCount,
          }
        })
      }
    )
  }
