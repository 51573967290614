export const trackVersion = [
  {
    label: 'Acapella',
    value: 'acapella',
  },
  {
    label: 'Acoustic',
    value: 'acoustic',
  },
  {
    label: 'Bonus Track Version',
    value: 'bonus-track-version',
  },
  {
    label: 'Instrumental',
    value: 'instrumental',
  },
  {
    label: 'Karaoke',
    value: 'karaoke',
  },
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Radio Edit',
    value: 'radio-edit',
  },
  {
    label: 'Remix',
    value: 'remix',
  },
]
