import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'

export const usePromotions = (query = '') => {
  const { data, error, ...rest } = useSWR(`${ENDPOINTS.PROMOTION}${query}`)
  return {
    promotions: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useSinglePromotion = promotionId => {
  const { data, error, ...rest } = useSWR(
    `${ENDPOINTS.PROMOTION}/${promotionId}`
  )
  return {
    promotion: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
