import React from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
`

export const ColumnInRow = ({ children, width, className, ...boxProps }) => {
  return (
    <SWrapper className={className} width={width} {...boxProps}>
      {children}
    </SWrapper>
  )
}

ColumnInRow.propTypes = {
  children: node.isRequired,
  width: string.isRequired,
  className: string,
}
