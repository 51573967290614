import React from 'react'
import { bool, string, func, shape } from 'prop-types'
import { Tooltip, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { PlayIcon } from '#pages/_modules/private-shares/components/play-icon'

const useStyles = makeStyles(() => ({
  textAndPlayWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  image: {
    width: 30,
    height: 30,
    marginRight: 8,
    objectFit: 'cover',
  },
  title: {
    fontSize: 14,
  },
  wrappedText: {
    whiteSpace: 'nowrap',
    maxWidth: 140,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  play: {
    cursor: 'pointer',
    marginRight: 10,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
  },
}))

export const ImageWithTextAndPlay = ({
  width,
  title,
  isPlayVisible,
  onPlay,
  share,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.textAndPlayWrapper} style={{ width }}>
      {isPlayVisible && (
        <div
          className={classes.play}
          onClick={() => {
            onPlay(share)
          }}
        >
          <PlayIcon />
        </div>
      )}
      <div className={classes.ellipsis}>
        <Tooltip title={title}>
          <div className={cn(classes.wrappedText, classes.title)}>{title}</div>
        </Tooltip>
      </div>
    </div>
  )
}

ImageWithTextAndPlay.propTypes = {
  width: string.isRequired,
  title: string.isRequired,
  isPlayVisible: bool.isRequired,
  onPlay: func.isRequired,
  share: shape({}).isRequired,
}
