import React from 'react'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Hero } from '#components/hero'

export const GetPromotedSection = () => {
  const { t } = useTranslation('promotion')

  return (
    <Hero
      header={t('getPromotedTo7000')}
      subheaderComponent={t('tvFilmVideGames')}
      buttonOneUrl={NAVIGATION_PATHS.PROMOTION_NEW}
      buttonTwoUrl={NAVIGATION_PATHS.PROMOTION_REPORTS}
      buttonOneText={t('getStarted')}
      buttonTwoText={t('viewCrowdReports')}
      bgUrl="/assets/images/bg.png"
    />
  )
}
