import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  description: {
    fontSize: 18,
  },
  priceWrapper: {
    display: 'flex',
    color: theme.palette.color.primary,
    fontFamily: theme.typography.h2.fontFamily,
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  price: {
    fontSize: 50,
  },
  video: {
    lineHeight: 2.8,
    fontSize: 30,
  },
  unlock: {
    display: 'flex',
    fontFamily: theme.typography.h2.fontFamily,
    justifyContent: 'center',
    fontSize: 18,
  },
}))

export const PricingSubHeader = () => {
  const { t } = useTranslation('videogo')
  const classes = useStyles()

  return (
    <div>
      <p className={classes.description}>
        {t('simpleStraightForwardAffordable')}
      </p>
      <span className={classes.description}>{t('oneTimePayment')}</span>
      <div className={classes.priceWrapper}>
        <span className={classes.price}>{t('price89')}</span>
        <div className={classes.video}>
          {' / '}
          {t('video')}
        </div>
      </div>
      <div className={classes.unlock}>
        {t('unlockUnlimitedVideoSubmission')}
      </div>
    </div>
  )
}
