import React from 'react'
import { string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { NFT_FILES_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'
import { StepSubHeader } from '#pages/sell-collectibles/create-collectible/_components/step-subheader'
import { Divider } from '#pages/sell-collectibles/create-collectible/_components/divider'
import { MusicFileSelect } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/music-file-select'
import { ACCEPTED_AUDIO_FORMATS } from '#pages/sell-collectibles/create-collectible/_constants/upload'

export const Music = ({ parentId, name, hasPersonaSingle }) => {
  const { t } = useTranslation('nftWizard')
  const { control } = useFormContext()

  return (
    <>
      <StepSubHeader title={t('steps.files.createOrSelectMusic')} />

      <Controller
        name={NFT_FILES_STEP_FIELDS.MUSIC}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const onFileSelect = file => {
            onChange(file)
          }

          return (
            <MusicFileSelect
              parentId={parentId}
              name={name}
              value={value}
              error={error}
              accept={ACCEPTED_AUDIO_FORMATS}
              hasPersonaSingle={hasPersonaSingle}
              onFileSelect={onFileSelect}
            />
          )
        }}
      />
      <Divider />
    </>
  )
}

Music.propTypes = {
  parentId: string.isRequired,
  name: string,
  hasPersonaSingle: bool,
}
