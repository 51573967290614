import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { STORES } from '../../utils/constants'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  description: {
    fontWeight: 700,
    fontSize: 18,
  },
  storesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 24,
  },
  store: {
    margin: '24px 40px',
    width: 120,
  },
}))

export const DistributeSubHeader = () => {
  const { t } = useTranslation('videogo')
  const classes = useStyles()

  return (
    <div>
      <div className={classes.description}>{t('itsSimpleWithVideoGo')}</div>
      <div className={classes.storesWrapper}>
        {STORES.map((store, i) => (
          <img
            key={generateUniqueKey(store.img, i)}
            src={store.img}
            className={classes.store}
            alt="store"
          />
        ))}
      </div>
    </div>
  )
}
