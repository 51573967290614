import React from 'react'
import { Button, ButtonTheme, Modal } from '@tunego/tunego-ui-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem 2rem;
`

const SHeader = styled.h3`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['3xl']};
  margin-bottom: 0;
`

const SButton = styled(Button)`
  align-self: center;
  margin-top: 1.5rem;
`

export const PersonaWarningModal = ({ isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation('nftWizard')

  return (
    <Modal isOpen={isOpen} header={false} onClose={onClose}>
      <SContent>
        <SHeader>{t('steps.files.warning')}</SHeader>
        <p>{t('steps.files.choosingDifferentPersona')}</p>
        <SButton buttonTheme={ButtonTheme.Warning} onClick={onConfirm}>
          {t('steps.files.confirmChange')}
        </SButton>
      </SContent>
    </Modal>
  )
}

PersonaWarningModal.propTypes = {
  isOpen: bool,
  onClose: func,
  onConfirm: func,
}
