import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const SThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SThumbnail = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
  object-fit: cover;
`

const SNoThumbnail = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
`

const STitle = styled.span`
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-weight: 700;
  display: block;
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ThumbnailAndTitle = ({ title, thumbnail }) => {
  return (
    <SThumbnailWrapper>
      {thumbnail ? <SThumbnail src={thumbnail} alt="" /> : <SNoThumbnail />}
      <STitle>{title || '- -'}</STitle>
    </SThumbnailWrapper>
  )
}

ThumbnailAndTitle.propTypes = {
  title: string.isRequired,
  thumbnail: string,
}
