import React from 'react'
import styled from 'styled-components'
import { string, arrayOf, any, bool, func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const useStyles = ({ isBottomRow }) =>
  makeStyles(({ palette }) => {
    return {
      pricingTableRow: {
        display: 'grid',
        gridTemplateColumns: '4fr 8fr',
        fontFamily: 'AvenirNextLTPro-Bold',
        background: 'rgba(255,255,255, 0.1)',
        borderTop: !isBottomRow && `1px solid ${palette.color.darkGrey}`,
        padding: '25px 20px',
      },
      pricingTableHeaderWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 4fr)',
      },
      pricingTableHeader: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 25px',

        '& button': {
          margin: '15px auto 0 auto',
        },
      },
      title: {
        fontFamily: 'AvenirNextLTPro-Bold',
        flexGrow: 1,
        textAlign: 'center',
      },
      smallTuneGoIcon: {
        height: '18px',
        marginRight: 8,
      },
    }
  })()

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const PricingTableActionRow = ({
  rowName,
  rowsArr,
  isBottomRow,
  onPlanSelect,
}) => {
  const classes = useStyles({ isBottomRow })
  const { t } = useTranslation('promotion')

  const tableActionCell = (name, value) => (
    <div key={name} className={classes.pricingTableHeader}>
      <span className={classes.title}>
        <img
          className={classes.smallTuneGoIcon}
          src="/assets/images/tunego-static-icon.png"
          alt="TuneGO logo"
        />
        {t(name)}
      </span>
      {isBottomRow && (
        <SButton onClick={() => onPlanSelect(value)}>{t('getStarted')}</SButton>
      )}
    </div>
  )

  return (
    <div className={classes.pricingTableRow}>
      <div>{rowName}</div>
      <div className={classes.pricingTableHeaderWrapper}>
        {rowsArr.map(({ label, value }) => tableActionCell(label, value))}
      </div>
    </div>
  )
}

PricingTableActionRow.propTypes = {
  rowName: string,
  rowsArr: arrayOf(any).isRequired,
  isBottomRow: bool,
  onPlanSelect: func,
}

PricingTableActionRow.defaultProps = {
  isBottomRow: false,
  rowName: '',
  onPlanSelect: () => {},
}
