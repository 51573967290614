import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { func, bool, string, shape } from 'prop-types'
import {
  CONTRIBUTOR_ACTION_TYPES,
  MENU_OPTIONS,
  CONTRIBUTOR_STATUS,
} from '#pages/vault/song/tabs/metadata/utils/constants'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { ROLES } from '#constants/roles'
import { ContributorsMetadataItem } from '#pages/vault/song/tabs/metadata/components/contributors-metadata-item'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  acceptBtn: {
    backgroundImage: 'url("/assets/images/edit-icon.png")',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: 18,
  },
  contributors: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const ContributorsMetadataList = ({
  contributors,
  isOwner,
  projectId,
  triggerMutate,
  handleOpenEditContributorModal,
  actions,
}) => {
  const classes = useStyles()
  const determineDisplayedRoles = roles =>
    roles
      .map(item => {
        return ROLES.filter(contributorRole => contributorRole.value === item)
      })
      .flat()
      .map(({ label }, i) => (
        <span key={generateUniqueKey(label, i)}>{label}</span>
      ))

  const resendInvite = async id => {
    await actions.postReInviteContributor(projectId, id)
    triggerMutate(projectId)
  }

  const cancelInvite = async id => {
    await actions.deleteContributor(projectId, id)
    triggerMutate(projectId)
  }

  const determineMenuAction = (actionType, id) => {
    switch (actionType) {
      case CONTRIBUTOR_ACTION_TYPES.resendInvite: {
        return resendInvite(id)
      }
      case CONTRIBUTOR_ACTION_TYPES.cancelInvite: {
        return cancelInvite(id)
      }
      default: {
        return null
      }
    }
  }

  const renderEllipsisMenu = (status, id) => {
    switch (status) {
      case CONTRIBUTOR_STATUS.PENDING:
        return (
          <EllipsisMenu
            options={MENU_OPTIONS}
            onChoose={actionType => {
              determineMenuAction(actionType, id)
            }}
          />
        )
      case CONTRIBUTOR_STATUS.ACCEPTED:
        return (
          <Button
            className={classes.acceptBtn}
            onClick={() => handleOpenEditContributorModal(id)}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={classes.contributors}>
      {contributors?.map((contributor, index) => (
        <ContributorsMetadataItem
          key={contributor.id}
          contributor={contributor}
          isOwner={isOwner}
          determineDisplayedRoles={determineDisplayedRoles}
          renderEllipsisMenu={renderEllipsisMenu}
          index={index}
        />
      ))}
    </div>
  )
}

ContributorsMetadataList.propTypes = {
  contributors: shape([{}]).isRequired,
  isOwner: bool.isRequired,
  projectId: string.isRequired,
  triggerMutate: func.isRequired,
  actions: shape({}).isRequired,
  handleOpenEditContributorModal: func.isRequired,
}
