import React from 'react'
import styled from 'styled-components'
import { func } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { AutoComplete } from '#components/inputs/auto-complete'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { ModalHeader } from '#pages/vault/song/tabs/splits/components/modals/_components/modal-header/ModalHeader'
import { addExistingUserSplitValidationSchema } from '#pages/vault/song/tabs/splits/utils/validation'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { hexToRgba } from '#utils/hexToRgba'
import { loadContactsList } from '#pages/vault/landing-page/utils/helpers'
import { useUser } from '#hooks/useUser'

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SButtonCancel = styled(Button)`
  color: ${hexToRgba('#fff', 0.3)};
  text-transform: uppercase;
`

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr',
    gap: '1rem',
  },
  span: {
    fontSize: 14,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.color.primary,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: 0,
    whiteSpace: 'pre',
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1.5rem',
  },
  inviteThemInfo: {
    textAlign: 'right',
  },
}))

export const InviteExistingSide = ({
  onClose,
  setIsFirstModalSide,
  addSplit,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const classes = useStyles()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      addExistingUserSplitValidationSchema(vaultTranslation.t)
    ),
  })

  const { user } = useUser()

  return (
    <>
      <ModalHeader>{vaultTranslation.t('addSplit')}</ModalHeader>
      <form onSubmit={handleSubmit(addSplit)}>
        <div className={classes.inputs}>
          <Controller
            name="user"
            control={control}
            render={({ field: { onChange } }) => (
              <AutoComplete
                id="search_contacts"
                placeholder={t('search')}
                label={vaultTranslation.t('searchContacts')}
                loadOptions={loadContactsList(user)}
                errorMessage={errors?.user?.message}
                onChange={onChange}
              />
            )}
          />
          <RolesSelect control={control} errorMessage={errors.role?.message} />
        </div>
        <div className={classes.grid}>
          <SButton type="submit">{t('add')}</SButton>
          <SButtonCancel variant={VARIANTS.DARK_GREY} onClick={onClose}>
            {t('cancel')}
          </SButtonCancel>
          <div className={classes.inviteThemInfo}>
            <span>{vaultTranslation.t('dontSeeWho')}</span>
            <span
              className={classes.span}
              onClick={() => setIsFirstModalSide(false)}
            >
              {vaultTranslation.t('inviteThem')}
            </span>
          </div>
        </div>
      </form>
    </>
  )
}

InviteExistingSide.propTypes = {
  onClose: func.isRequired,
  setIsFirstModalSide: func.isRequired,
  addSplit: func.isRequired,
}
