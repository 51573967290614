import React from 'react'
import { PaymentSuccessContainer } from '#pages/subscriptions/_containers/payment-success-container'
import { PageWrapper } from '#modules/page-wrapper'

const pageWrapperStyles = () => {
  return {
    maxWidth: 1200,
  }
}

export const PaymentSuccess = props => {
  return (
    <PageWrapper makeCustomStyles={pageWrapperStyles} showNavBar showFooter>
      <PaymentSuccessContainer {...props} />
    </PageWrapper>
  )
}
