import React, { useEffect } from 'react'
import styled from 'styled-components'
import { bool, func, arrayOf, shape } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { BaseInput } from '#components/inputs/base-input'
import { CustomSelect } from '#components/inputs/custom-select'
import { DeleteModal } from '#pages/_components/delete-modal'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { EDIT_CONTRIBUTOR_FIELDS } from '#pages/vault/song/tabs/metadata/utils/constants'
import { editContributorValidationSchema } from '#pages/_modules/add-contributor-modal/utils/validation'
import { hexToRgba } from '#utils/hexToRgba'
import { PRO_OPTIONS } from '#constants/proOptions'
import { ROLES } from '#constants/roles'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const STitle = styled.h2`
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize['3xl']};
  margin-bottom: 1rem;
`

const SButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2.25rem;
`

const SRolesSelect = styled.div`
  margin-bottom: 0.5rem;
`

const SLeftButtonsWrapper = styled.div`
  display: flex;
`

const SModal = styled(Modal)`
  width: 38.75rem;
`

const SForm = styled.form`
  width: 100%;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
`

const SButtonDelete = styled(Button)`
  padding: 0 2.25rem;
  text-transform: uppercase;
`

const SButtonCancel = styled(Button)`
  width: 7.25rem;
  margin-left: 2.25rem;
  color: ${hexToRgba('#fff', 0.3)};
`

const SCustomSelect = styled(CustomSelect)`
  margin-bottom: 0.5rem;
`

export const EditContributorModal = ({
  isDeleteContributorModalOpen,
  isEditContributorModalOpen,
  handleCloseEditContributorModal,
  handleOpenDeleteModal,
  handleCloseDeleteModal,
  handleDeleteContributor,
  handleEditContributor,
  contributor,
  isForDistro,
}) => {
  const inputWidth = '541'
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(
      editContributorValidationSchema(vaultTranslation.t, isForDistro)
    ),
  })

  useEffect(() => {
    reset()
    contributor &&
      [
        ...EDIT_CONTRIBUTOR_FIELDS.VAULT,
        ...EDIT_CONTRIBUTOR_FIELDS.DISTRO,
      ].forEach(({ name: fieldName }) => {
        if (fieldName === 'contributorName') {
          const userName = contributor.user.isOnboarded
            ? `${contributor.user.firstName} ${contributor.user.lastName}`
            : contributor.pendingName
          setValue(fieldName, userName)
        } else {
          setValue(fieldName, contributor[fieldName])
        }
      })
  }, [contributor, reset, setValue])

  const editFormFields = isForDistro
    ? EDIT_CONTRIBUTOR_FIELDS.DISTRO
    : [
        ...EDIT_CONTRIBUTOR_FIELDS.VAULT,
        ...EDIT_CONTRIBUTOR_FIELDS.DISTRO,
      ].sort((a, b) => (a.order > b.order ? 1 : -1))

  return (
    <>
      <SModal
        isOpen={isEditContributorModalOpen}
        setIsOpen={handleCloseEditContributorModal}
      >
        <SForm onSubmit={handleSubmit(handleEditContributor)}>
          <div>
            <STitle>{vaultTranslation.t(`editContributor`)}</STitle>
            <div>
              {contributor &&
                editFormFields.map(
                  ({ name, label, type, inputType, options }, i) => {
                    const isUpperCasedLabel = [
                      'isni',
                      'proAttribute',
                      'ipiCaeNumber',
                    ].includes(name)

                    if (type === 'multiselect') {
                      const defaultValue = contributor?.role?.map(item => ({
                        label: ROLES.find(role => role?.value === item)?.label,
                        value: item,
                      }))

                      return (
                        <SRolesSelect key={generateUniqueKey(name, i)}>
                          <RolesSelect
                            defaultValue={defaultValue}
                            control={control}
                            errorMessage={errors.role?.message}
                          />
                        </SRolesSelect>
                      )
                    }
                    if (type === 'select') {
                      return (
                        <Controller
                          key={name}
                          name={name}
                          control={control}
                          render={({ field: { onChange } }) => (
                            <SCustomSelect
                              id={`${name}_select`}
                              options={options}
                              customWidth={inputWidth}
                              label={vaultTranslation.t(`${label}`)}
                              defaultValue={PRO_OPTIONS.find(
                                ({ value }) => contributor[name] === value
                              )}
                              errorMessage={errors[`${name}`]?.message}
                              isUpperCased={isUpperCasedLabel}
                              isBolded
                              onChange={({ value }) => onChange(value)}
                            />
                          )}
                        />
                      )
                    }
                    return (
                      <SBaseInput
                        key={name}
                        disabled={
                          contributor.user.isOnboarded &&
                          name === 'contributorName'
                        }
                        inputRef={register}
                        name={name}
                        type={inputType}
                        label={vaultTranslation.t(`${label}`)}
                        errorMessage={errors[`${name}`]?.message}
                        isUpperCased={isUpperCasedLabel}
                        isBolded
                      />
                    )
                  }
                )}
            </div>
            <SButtonsContainer>
              <SLeftButtonsWrapper>
                <Button type="submit" isUppercase>
                  {t('save')}
                </Button>
                <SButtonCancel
                  variant={VARIANTS.DARK_GREY}
                  onClick={handleCloseEditContributorModal}
                >
                  {t('cancel')}
                </SButtonCancel>
              </SLeftButtonsWrapper>
              <SButtonDelete
                variant={VARIANTS.WARNING}
                onClick={handleOpenDeleteModal}
              >
                {vaultTranslation.t('deleteContributor')}
              </SButtonDelete>
            </SButtonsContainer>
          </div>
        </SForm>
      </SModal>
      <DeleteModal
        handleClose={handleCloseDeleteModal}
        open={isDeleteContributorModalOpen}
        handleDelete={handleDeleteContributor}
        firstSpan={vaultTranslation.t('areYouSureYouWantToDelete')}
        secondSpan={vaultTranslation.t('thisActionIsPermanent')}
        thirdSpan={vaultTranslation.t('pleaseConfirmThatYouWant')}
        deleteBtnText={vaultTranslation.t('yesPermanentlyDeleteContributor', {
          structure: 'file',
        })}
      />
    </>
  )
}

EditContributorModal.propTypes = {
  isEditContributorModalOpen: bool.isRequired,
  handleCloseEditContributorModal: func.isRequired,
  isDeleteContributorModalOpen: bool.isRequired,
  handleOpenDeleteModal: func.isRequired,
  handleCloseDeleteModal: func.isRequired,
  handleDeleteContributor: func.isRequired,
  handleEditContributor: func.isRequired,
  contributor: arrayOf(shape({})).isRequired,
  isForDistro: bool.isRequired,
}
