import React, { useState } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { func, bool, string } from 'prop-types'
import { hexToRgba } from '#utils/hexToRgba'
import { ErrorMessage } from '#components/error-message'
import { Label } from '#components/label'
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  ACCEPTED_IMAGE_TYPES,
} from '#constants/acceptedFileTypes'
import { Button } from '#components/button'
import { ImageCropper } from '#components/image-cropper'

const SButton = styled(Button)`
  border: none;
  background-color: transparent;
  margin-right: 0;
  padding: 10px;
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
  z-index: 10;
`

const SImageDropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SDropzone = styled.div`
  height: 207px;
  width: 205px;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.darkBlue03};
  margin-bottom: 10px;
  border: 1px solid
    ${({ errorMessage }) =>
      errorMessage
        ? ({ theme: { colors } }) => colors.error
        : `${hexToRgba('#fff', 0.2)}`};

  &:hover {
    cursor: pointer;
  }
`

const SPreviewBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const SPreviewHolder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const SPreviewImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const SDropzoneButton = styled.div`
  background: ${hexToRgba('#000', 0.7)};
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  text-align: center;
  align-items: center;
  text-transform: uppercase;

  span {
    line-height: 50px;
    font-size: 16px;
    font-family: ${({ theme: { typography } }) => typography.bold};
  }
`

const SImageHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  position: absolute;
  height: 155px;
  width: 100%;
  top: 0;
`

export const ImageDropzone = props => {
  const {
    handlePreview,
    previewFile,
    placeholderImage,
    handleDeleteFile,
    label,
    name,
    required,
    errorMessage,
  } = props
  const { t } = useTranslation()

  const [preview, setPreview] = useState('')
  const [imageToCrop, setImageToCrop] = useState('')
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState(null)
  const [dropError, setDropError] = useState('')
  const previewFileUrl =
    previewFile instanceof File ? URL.createObjectURL(previewFile) : previewFile

  const openCropperModal = () => setIsCropperModalOpen(true)
  const closeCropperModal = () => setIsCropperModalOpen(false)

  const onDrop = acceptedFile => {
    if (acceptedFile[0]) {
      setDropError('')
      setImageToCrop(URL.createObjectURL(acceptedFile[0]))
      setAcceptedFile(acceptedFile[0])
      openCropperModal()
    }
  }

  const handleDelete = () => {
    if (handleDeleteFile) handleDeleteFile()
    setPreview('')
  }

  return (
    <SImageDropzoneWrapper>
      <Label label={label} name={name} required={required} isBolded />
      {previewFile && (
        <SButton onClick={handleDelete}>
          <img
            src="/assets/svg/ic-close-bold.svg"
            width="14px"
            alt="Close icon"
          />
        </SButton>
      )}
      <Dropzone
        accept={ACCEPTED_IMAGE_TYPES}
        onDropAccepted={acceptedFile => onDrop(acceptedFile)}
        onDropRejected={() =>
          setDropError(
            t('fileDoesNotMeetRequirements', {
              types: ACCEPTED_IMAGE_EXTENSIONS.join(', '),
            })
          )
        }
      >
        {({ getRootProps, getInputProps }) => (
          <SDropzone {...getRootProps()} errorMessage={errorMessage}>
            <input {...getInputProps()} />
            {preview || previewFile ? (
              <SPreviewBox>
                <SPreviewHolder>
                  <SPreviewImage src={preview || previewFileUrl} alt={label} />
                  <SDropzoneButton>
                    <span>{t('edit')}</span>
                  </SDropzoneButton>
                </SPreviewHolder>
              </SPreviewBox>
            ) : (
              <>
                <SImageHolder>
                  {placeholderImage && (
                    <img src={placeholderImage} width="100px" alt={label} />
                  )}
                </SImageHolder>
                <SDropzoneButton>
                  <span>{t('add')}</span>
                </SDropzoneButton>
              </>
            )}
          </SDropzone>
        )}
      </Dropzone>
      <ErrorMessage errorMessage={errorMessage || dropError} />
      <ImageCropper
        isOpen={isCropperModalOpen}
        updateImage={handlePreview}
        acceptedFile={acceptedFile}
        setPreview={setPreview}
        image={imageToCrop}
        onClose={closeCropperModal}
      />
    </SImageDropzoneWrapper>
  )
}

ImageDropzone.propTypes = {
  handlePreview: func.isRequired,
  handleDeleteFile: func.isRequired,
  label: string.isRequired,
  name: string.isRequired,
  placeholderImage: string,
  previewFile: string,
  errorMessage: string,
  required: bool,
}

ImageDropzone.defaultProps = {
  placeholderImage: '',
  previewFile: '',
  required: false,
  errorMessage: '',
}
