import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const SButton = styled(Button)`
  width: 240px;
  margin: 48px auto auto;
  text-transform: uppercase;
`

export const DownloadButton = () => {
  const { t } = useTranslation('connect')

  return <SButton>{t('sendLink')}</SButton>
}
