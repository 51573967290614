import React, { useEffect } from 'react'
import { StepHeader } from '#pages/sell-collectibles/create-collectible/_components/step-header'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import styled from 'styled-components'
import { string, func, bool } from 'prop-types'
import { ErrorMessage } from '#components/error-message'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'

const SOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_100};
  z-index: 10;
  padding-top: 5rem;
`

const SContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 55.625rem;
`

const SContainer = styled.div`
  display: flex;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.secondary_80};
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
`

const SImg = styled.img`
  max-width: 445px;
  height: 100%;
  object-fit: cover;
`

const SInnerContent = styled.div`
  padding: 2.5rem;
`

const SBold = styled.b`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SSubTitle = styled.h3`
  text-transform: uppercase;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.xl};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SButton = styled(Button)`
  text-transform: uppercase;
  margin: 2rem 0 2.5rem;
`

export const IdentityVerification = ({ error, openClient, inProgress }) => {
  const { t } = useTranslation('nftWizard')
  const { setDisableNavigation } = useNftCreator()

  useEffect(() => {
    setDisableNavigation(true)

    return () => setDisableNavigation(false)
  }, [])

  return (
    <SOverlay>
      <SContent>
        <div>
          <StepHeader title={t('idVerification')} />
          <SText>
            <Trans i18nKey="idVerificationInfo" t={t}>
              <SBold />
            </Trans>
          </SText>
          <SButton disabled={inProgress} onClick={openClient}>
            {inProgress ? t('waitForVerification') : t('verifyYourIdentity')}
          </SButton>

          {error && <ErrorMessage errorMessage={error} />}
        </div>

        <SContainer>
          <SImg src="assets/images/persona-verification.jpg" alt="" />
          <SInnerContent>
            <SSubTitle>{t('whyDoWeRequireVerification')}</SSubTitle>
            <SText>{t('creativeRightsProtectionInfo')}</SText>
          </SInnerContent>
        </SContainer>
      </SContent>
    </SOverlay>
  )
}

IdentityVerification.propTypes = {
  error: string,
  openClient: func,
  inProgress: bool,
}
