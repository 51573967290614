import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { useEventSWR } from '#hooks/swr/useEventSWR'
import { EVENTS } from '#api/event'

export const useFile = id => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [`${EVENTS.FILE_UPDATED}.${id}`, `${EVENTS.FILE_REMOVED}.${id}`],
    swrKey: id
      ? buildUrl(ENDPOINTS.FILES_FILE, {
          id,
        })
      : null,
  })
  return {
    file: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
