import React from 'react'
import { StripeFormInputs } from '#modules/payments/components/stripe-form-inputs'
import { StripeIcons } from '#modules/payments/components/stripe-icons'
import { Checkbox } from '#components/checkbox'
import { FORM_FIELDS } from '#pages/subscriptions/_utils/constants'
import { useTranslation } from 'react-i18next'
import { useStripeFormValidation } from '#hooks/useStripeFormValidation'

export const StripeForm = ({
  elements,
  nameOnCard,
  setNameOnCard,
  defaultMethodToSave,
  setDefaultMethodToSave,
}) => {
  const { t } = useTranslation()

  const { cardNumberError, cardCvcError, cardExpiryError } =
    useStripeFormValidation(elements)

  return (
    <>
      <StripeFormInputs
        {...{
          cardNumberError,
          cardCvcError,
          cardExpiryError,
          nameOnCard,
          setNameOnCard,
        }}
      />
      <StripeIcons />
      <Checkbox
        name={FORM_FIELDS.DEFAULT_METHOD_TO_SAVE}
        checked={defaultMethodToSave}
        label={t('payments.setAsDefaultMethod')}
        onChange={() => setDefaultMethodToSave(!defaultMethodToSave)}
      />
    </>
  )
}
