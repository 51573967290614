import React from 'react'
import styled from 'styled-components'
import { Background } from './Background'

const SWrapper = styled.div`
  height: calc(100vh - 60px);
  padding-bottom: 3.75rem;
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 2px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};

  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    height: 100vh;
    align-items: center;
  }
`

const SInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const DefaultWelcomeSection = () => {
  return (
    <SWrapper>
      <SInnerWrapper>
        <Background />
      </SInnerWrapper>
    </SWrapper>
  )
}
