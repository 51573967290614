export const UPLOAD_STATUS = {
  SELECT_FILE: 'selectFile',
  CROP_IMAGE: 'cropImage',
  SELECT_SINGLE: 'selectSingle',
  PROCESSING_IN_PROGRESS: 'processingInProgress',
  FILE_ALREADY_EXISTS: 'fileAlreadyExists',
}

export const MODAL_HEADERS = t => ({
  [UPLOAD_STATUS.SELECT_FILE]: t('steps.files.uploadFile'),
  [UPLOAD_STATUS.SELECT_SINGLE]: t('steps.files.assignToSingle'),
  [UPLOAD_STATUS.CROP_IMAGE]: t('steps.files.cropImage'),
  [UPLOAD_STATUS.FILE_ALREADY_EXISTS]: t('steps.files.fileNameAlreadyExists'),
})

export const FILE_CHECK_DEBOUNCE = 1000

export const ACCEPTED_AUDIO_FORMATS = [
  'audio/mp3',
  'audio/flac',
  'audio/mp4',
  'audio/wav',
  'audio/mpeg',
]

export const ACCEPTED_IMAGE_FORMATS = ['image/jpeg', 'image/png', 'image/gif']

export const DROPZONE_TYPE_ERROR = 'File type must be'
