import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { countries } from 'countries-list'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { EstimatedReleaseDate } from '#pages/distro/wizard/steps/delivery-and-release-date/components/estimated-release-date'
import { MusicStoresForm } from '#pages/distro/wizard/steps/delivery-and-release-date/components/music-stores-form'
import { TerritoriesForm } from '#pages/distro/wizard/steps/delivery-and-release-date/components/territories-form'
import { Recommendation } from '#pages/distro/wizard/steps/delivery-and-release-date/components/recommendation'
import { deliveryAndReleaseDateValidationSchema } from '#pages/distro/_utils/validation'
import { useWizard } from '#modules/forms/form-wizard/context'
import { formatDate } from '#utils/formatDate'
import { getCountriesCodes } from '#pages/distro/_utils/helpers'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { FIELDS } from '#pages/distro/_utils/constants'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'

export const DeliveryAndReleaseDate = () => {
  const { t } = useTranslation('distro')
  const { setStoredFormState, incrementStep, storedFormState } = useWizard()
  const [error, setError] = useState()

  const { actions, loading } = useWithAsyncAction({
    updateDeliveryDetails: DistroApi.updateDeliveryDetails,
  })

  const [checkedCountry, setCheckedCountry] = useState(
    Object.fromEntries(
      Object.entries(countries).map(([key]) =>
        storedFormState.deliveryRegions?.includes(key)
          ? [key, true]
          : [key, false]
      )
    )
  )
  // eslint-disable-next-line consistent-return
  const customSubmitAction = async data => {
    const { deliveryDate, musicStores } = data
    const formattedFuture = dayjs().add(14, 'day').format('MM/DD/YYYY')
    const formattedDeliveryDate = formatDate(deliveryDate)
    const isDeliveryBefore = dayjs(formattedDeliveryDate).isBefore(
      formattedFuture
    )
    const checkedCountries = getCountriesCodes(checkedCountry)

    const formData = {
      deliveryDate: new Date(deliveryDate).toISOString(),
      musicStores,
      deliveryRegions: checkedCountries,
    }

    if (isDeliveryBefore) return null

    try {
      await actions.updateDeliveryDetails(
        storedFormState[FIELDS.DISTRO_ID],
        formData
      )

      setStoredFormState({ ...formData })
      incrementStep()
    } catch (err) {
      setError(err.response?.data?.message)
    }
  }

  return (
    <StepContainer
      customSubmitAction={customSubmitAction}
      resolver={yupResolver(
        deliveryAndReleaseDateValidationSchema(
          t,
          storedFormState.deliveryRegions.length > 0
        )
      )}
    >
      <EstimatedReleaseDate />
      <MusicStoresForm />
      <Recommendation />
      <TerritoriesForm
        checkedCountry={checkedCountry}
        setCheckedCountry={setCheckedCountry}
      />
      <CommonLoadingOverlay
        dialogOpen={loading.updateDeliveryDetails}
        error={error}
        onErrorBackdropClick={() => setError('')}
      />
    </StepContainer>
  )
}
