import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormContext } from 'react-hook-form'
import { RELEASE_DETAILS_CONFIRMATIONS } from '#pages/distro/_utils/constants'
import { Checkbox } from '#components/checkbox'
import { useWizard } from '#modules/forms/form-wizard/context'
import { generateUniqueKey } from '#utils/generateUniqueKey'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(() => ({
  container: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const Confirmations = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()
  const { storedFormState } = useWizard()

  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className={classes.container}>
      <Text type={TEXT_TYPE.SUBTITLE2}>{t('pleaseConfirm')}</Text>
      {RELEASE_DETAILS_CONFIRMATIONS.map(({ name, label }, i) => (
        <Checkbox
          key={generateUniqueKey(name, i)}
          register={register}
          label={t(label)}
          name={name}
          hasError={errors[name]?.message}
          checked={storedFormState[name]}
        />
      ))}
    </div>
  )
}
