import React from 'react'
import { bool, func, number } from 'prop-types'
import { Badge } from '#components/badge'
import styled, { css } from 'styled-components'

const activeStyle = css`
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.primary};
`

const SNavIcon = styled.div`
  cursor: pointer;
  padding: 0;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  ${({ active }) => active && activeStyle};
`

export const ConnectIcon = ({ setIsOpened, isOpened, unreadCount }) => {
  return (
    <SNavIcon active={isOpened} onClick={() => setIsOpened(true)}>
      <Badge text={unreadCount}>
        <img
          src={
            isOpened
              ? '/assets/svg/ic-connect.svg'
              : '/assets/svg/ic-connect-on.svg'
          }
          width="20px"
          height="20px"
          alt=""
        />
      </Badge>
    </SNavIcon>
  )
}

ConnectIcon.propTypes = {
  setIsOpened: func.isRequired,
  isOpened: bool.isRequired,
  unreadCount: number,
}

ConnectIcon.defaultProps = {
  unreadCount: 0,
}
