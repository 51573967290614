import React from 'react'
import { TableHeader } from '#pages/sell-collectibles/create-collectible/_components/steps/splits/_components/splits-table/_components/table-header'
import styled from 'styled-components'
import { TableFooter } from '#pages/sell-collectibles/create-collectible/_components/steps/splits/_components/splits-table/_components/table-footer'
import { TableBody } from '#pages/sell-collectibles/create-collectible/_components/steps/splits/_components/splits-table/_components/table-body'

const STable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const SplitsTable = () => {
  return (
    <STable>
      <TableHeader />
      <TableBody />
      <TableFooter />
    </STable>
  )
}
