import React from 'react'
import {
  func,
  oneOfType,
  elementType,
  shape,
  bool,
  string,
  node,
} from 'prop-types'
import { Label } from '#components/label'
import { ErrorMessage } from '#components/error-message'
import styled from 'styled-components'
import { theme } from '@tunego/tunego-ui-components'

const STextAreaContainer = styled.div`
  height: 100%;
  position: relative;
`

const STextArea = styled.textarea`
  width: 100%;
  height: 100%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  outline: none;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
  resize: none;
  padding: 1rem;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SErrorMessage = styled(ErrorMessage)`
  margin: 0 0 0.625rem;
  width: 100%;
`

const SHint = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.xs};
  opacity: 0.5;
`

export const TextArea = ({
  inputRef,
  isBolded,
  errorMessage,
  label,
  name,
  required,
  customBorder,
  hint,
  className,
  ...restProps
}) => {
  const getBorderColor = () => {
    const {
      tunego: { COLORS },
    } = theme

    if (errorMessage) {
      return COLORS.warning_60
    }

    return customBorder ?? COLORS.neutral_70
  }

  return (
    <STextAreaContainer className={className}>
      {label && (
        <Label
          name={name}
          label={label}
          isBolded={isBolded}
          required={required}
        />
      )}
      {hint && <SHint>{hint}</SHint>}
      <STextArea
        borderColor={getBorderColor()}
        {...inputRef(name)}
        name={name}
        {...restProps}
      />
      <SErrorMessage errorMessage={errorMessage} />
    </STextAreaContainer>
  )
}

TextArea.propTypes = {
  inputRef: oneOfType([func, shape({ current: elementType })]),
  label: oneOfType([string, node]),
  isBolded: bool,
  errorMessage: string,
  errorMessageStyles: func,
  name: string.isRequired,
  required: bool,
  customBorder: string,
  hint: string,
}

TextArea.defaultProps = {
  inputRef: null,
  label: '',
  isBolded: false,
  errorMessage: '',
  errorMessageStyles: () => ({}),
  required: false,
}
