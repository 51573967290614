import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  separator: {
    backgroundColor: hexToRgba(theme.palette.color.default, 0.2),
    marginBottom: 16,
    height: 1,
  },
  navLinks: {
    color: theme.palette.color.darkGrey80,
    fontSize: '16px',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  currentPageLink: {
    color: theme.palette.color.default,
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bolder',
    marginBottom: '1rem',
  },
}))

export const NavigationItem = ({
  option,
  index,
  currentPage,
  handleSelect,
}) => {
  const { t } = useTranslation('profile')
  const classes = useStyles()
  const isFirstElement = index === 0
  const activeClass =
    currentPage === option.id ? classes.currentPageLink : classes.navLinks

  return (
    <>
      <div
        key={option.id}
        id={`${option.id}Tab`}
        className={activeClass}
        // eslint-disable-next-line react/no-unknown-property
        mb={2}
        onClick={() => handleSelect(option.id)}
      >
        {t(option.label)}
      </div>
      {isFirstElement && <div className={classes.separator} />}
    </>
  )
}
