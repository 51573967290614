import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SWrapper = styled.div`
  height: calc(100vh - 18rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`

const STitle = styled.h1`
  font-size: ${({ theme: { fontSize } }) => fontSize['5xl']};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
  margin-bottom: 0;
`

const SText = styled.p`
  max-width: 45rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
`

export const EmptyProfile = () => {
  const { t } = useTranslation('vault')

  return (
    <SWrapper>
      <STitle>{t('getStarted')}</STitle>
      <SText>{t('noSongsInProfile')}</SText>
    </SWrapper>
  )
}
