import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { countries } from 'countries-list'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { func, shape } from 'prop-types'
import { TerritoriesItems } from '#pages/distro/wizard/steps/delivery-and-release-date/components/territories-items'
import { TerritoriesSwitcher } from '#pages/distro/wizard/steps/delivery-and-release-date/components/territories-switcher'
import { SelectAll } from '#pages/distro/wizard/steps/delivery-and-release-date/components/select-all'
import { FIELDS } from '#pages/distro/_utils/constants'
import { BaseLoader } from '#components/loaders/base-loader'
import { useWizard } from '#modules/forms/form-wizard/context'
import {
  territoryConfig,
  getCountriesCodes,
} from '#pages/distro/_utils/helpers'
import { ContentWrapper } from '#pages/distro/wizard/steps/delivery-and-release-date/components/content-wrapper'
import { useSystem } from '#hooks/useSystem'
import makeStyles from '@material-ui/core/styles/makeStyles'

const territories = Object.keys(territoryConfig)

const useStyles = makeStyles(() => ({
  territoriesWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  territoriesItems: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '2.5rem',
  },
}))

export const TerritoriesForm = ({ checkedCountry, setCheckedCountry }) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStoredFormState, storedFormState } = useWizard()

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext()
  const { settings, isLoading } = useSystem()
  const distroCountries = settings?.distroCountries || []
  const territoriesFiltered = useMemo(() => {
    return Object.fromEntries(
      Object.entries(territoryConfig).map(([key, value]) => [
        key,
        value.filter(country => distroCountries.includes(country.code)),
      ])
    )
  }, [distroCountries])
  const [selectedTerritory, setSelectedTerritory] = useState(territories[0])

  const calcTerritoryValue = useCallback(
    (territory, currentCountries) => {
      let result = 0
      territoriesFiltered[territory].forEach(country => {
        if (currentCountries[country.code]) result++
      })
      if (result === 0) return false
      if (result < territoriesFiltered[territory].length) return null
      return true
    },
    [territoriesFiltered]
  )
  const [checkedTerritory, setCheckedTerritory] = useState(() =>
    Object.fromEntries(
      Object.entries(territoryConfig).map(([key]) => [
        key,
        calcTerritoryValue(key, checkedCountry),
      ])
    )
  )

  const handleCheckCountry = useCallback(
    (country, value) => {
      if (value) clearErrors(FIELDS.COUNTRIES)

      const newCheckedCountries = { ...checkedCountry, [country.code]: value }
      const checkedCountries = getCountriesCodes(newCheckedCountries)

      setStoredFormState({ deliveryRegions: checkedCountries })
      setCheckedCountry(newCheckedCountries)
      setCheckedTerritory({
        ...checkedTerritory,
        [country.continent]: calcTerritoryValue(
          country.continent,
          newCheckedCountries
        ),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedTerritory, calcTerritoryValue, checkedCountry]
  )

  const handleCheckTerritory = useCallback(
    (territory, value) => {
      if (value) clearErrors(FIELDS.COUNTRIES)

      const patchCheckedCountries = Object.fromEntries(
        territoriesFiltered[territory].map(country => [country.code, value])
      )
      const newCheckedCountries = {
        ...checkedCountry,
        ...patchCheckedCountries,
      }
      const checkedCountries = getCountriesCodes(newCheckedCountries)

      setStoredFormState({ deliveryRegions: checkedCountries })
      setCheckedCountry(newCheckedCountries)
      setCheckedTerritory({
        ...checkedTerritory,
        [territory]: value,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [territoriesFiltered, checkedTerritory, checkedCountry]
  )

  const selectAll = value => {
    if (value) clearErrors(FIELDS.COUNTRIES)

    setCheckedCountry(
      Object.fromEntries(Object.entries(countries).map(([key]) => [key, value]))
    )
    const checkedCountries = getCountriesCodes(countries)

    setStoredFormState({ deliveryRegions: value ? checkedCountries : [] })
    setCheckedTerritory(
      Object.fromEntries(
        Object.entries(territoryConfig).map(([key]) => [key, value])
      )
    )
  }

  useEffect(() => {
    const values = storedFormState[FIELDS.COUNTRIES]
    setValue(FIELDS.COUNTRIES, values)
  })

  const isSelectDisabled =
    Object.keys(checkedCountry).filter(k => checkedCountry[k] === true)
      .length === storedFormState?.deliveryRegionsLength

  const isDeselectDisabled = storedFormState?.deliveryRegions.length === 0

  return isLoading ? (
    <BaseLoader isLoading={isLoading} />
  ) : (
    <ContentWrapper>
      <SelectAll
        title={t('deliverToTheFollowingTerritories')}
        isSelectDisabled={isSelectDisabled}
        isDeselectDisabled={isDeselectDisabled}
        error={errors[FIELDS.COUNTRIES]?.message}
        onSelectAll={() => selectAll(true)}
        onDeselectAll={() => selectAll(false)}
      />
      <div className={classes.territoriesWrapper}>
        <TerritoriesSwitcher
          selectedTerritory={selectedTerritory}
          setSelectedTerritory={setSelectedTerritory}
          territories={territories}
          territoriesFiltered={territoriesFiltered}
          checkTerritory={handleCheckTerritory}
          checkedTerritory={checkedTerritory}
        />
        <div className={classes.territoriesItems}>
          <TerritoriesItems
            selectedTerritory={selectedTerritory}
            countries={territoriesFiltered[selectedTerritory]}
            checkedCountry={checkedCountry}
            register={register}
            onChange={handleCheckCountry}
          />
        </div>
      </div>
    </ContentWrapper>
  )
}

TerritoriesForm.propTypes = {
  checkedCountry: shape({}).isRequired,
  setCheckedCountry: func.isRequired,
}
