import React from 'react'
import { makeStyles, Typography, Tooltip } from '@material-ui/core'
import { func, shape, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'

import { hexToRgba } from '#utils/hexToRgba'

import { determineAvatar } from '../../../../utils/helpers'

const useStyles = makeStyles(theme => ({
  roomHeader: {
    background: hexToRgba(theme.palette.color.primary, 0.5),
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 24,
  },
  title: {
    fontSize: 21,
    marginLeft: 30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300,
  },
  backIcon: {
    cursor: 'pointer',
  },
  flex: {
    display: 'flex',
  },
}))

export const HeaderNavigation = ({
  onBackClick,
  activeDialog,
  isChooseFileViewOpened,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const decodedName = decodeURIComponent(activeDialog.name)

  return (
    <div className={classes.roomHeader}>
      <div className={classes.flex}>
        <img
          className={classes.backIcon}
          src="/assets/svg/ic-chevron-left.svg"
          alt="Arrow left"
          onClick={onBackClick}
        />
        <Tooltip {...{ title: decodedName }}>
          <Typography className={classes.title}>
            {isChooseFileViewOpened ? t('back') : decodedName}
          </Typography>
        </Tooltip>
      </div>
      {!isChooseFileViewOpened && (
        <img
          src={determineAvatar(activeDialog.data?.category, activeDialog.photo)}
          width="80px"
          height="80px"
          alt="Avatar"
        />
      )}
    </div>
  )
}

HeaderNavigation.propTypes = {
  isChooseFileViewOpened: bool.isRequired,
  activeDialog: shape({}).isRequired,
  onBackClick: func.isRequired,
}
