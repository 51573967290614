import * as yup from 'yup'
import i18next from 'i18next'

export const ISRC_PATTERN = '##-###-##-#####'

export const fileNameValidationSchema = t => {
  const field = t('fileName')

  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .min(
        1,
        i18next.t('validation:pleaseProvideAtLeastOneCharacter', {
          field,
        })
      )
      .required(
        t('validation:mustNotBeEmpty', {
          field,
        })
      ),
  })
}

export const lyricsValidationSchema = t => {
  return yup.object().shape({
    lyrics: yup.string().trim().nullable().max(10000, t('maxLyricsLength')),
  })
}

export const packValidationSchema = yup.object().shape({
  pack: yup.string().nullable(),
})
