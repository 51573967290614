import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { VARIANTS } from '#pages/profile/utils/constants'
import { ContactSupport } from '#pages/profile/components/contact-support'
import { useSubscriptionsPlans } from '#hooks/swr/useSubscription'
import { BaseLoader } from '#components/loaders/base-loader'
import { SubscriptionBar } from '#pages/profile/tabs/subscription/components/subscription-bar'
import { SubscriptionPlan } from '#pages/profile/tabs/subscription/components/subscription-plan'
import { SubscriptionModal } from '#pages/profile/tabs/subscription/components/subscription-modal'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { SubscriptionsApi } from '#api/requests/subscriptions'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { isEven } from '#utils/isEven'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'
import { generateUniqueKey } from '#utils/generateUniqueKey'
import styled from 'styled-components'

const SBaseLoader = styled(BaseLoader)`
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const useStyles = makeStyles(() => ({
  subscriptionContainer: {
    width: '100%',
  },
}))

export const SubscriptionContainer = () => {
  const { t } = useTranslation('profile')
  const { user } = useUser()
  const classes = useStyles()
  const currentSubscription = user?.subscription.subscriptionPlan
  const { plans, isLoading } = useSubscriptionsPlans()

  const [level, setLevel] = useState(0)
  const [action, setAction] = useState('')
  const [planId, setPlanId] = useState('')
  const [isModalOpened, setIsModalOpened] = useState(false)

  const { actions, errors, loading } = useWithAsyncAction({
    postUpgradeSubscription: SubscriptionsApi.postUpgradeSubscription,
  })
  const { error, setError, clearError } = useError()

  useEffect(() => {
    if (errors.postUpgradeSubscription) {
      setError(errors.postUpgradeSubscription.message)
    }
  }, [errors, setError])

  const otherPlans = plans
    ?.map(plan => {
      return {
        subscription: {
          subscriptionPlan: { ...plan },
        },
      }
    })
    .filter(
      plan => plan.subscription.subscriptionPlan.id !== currentSubscription?.id
    )
    .map((plan, index) => (
      <SubscriptionPlan
        key={generateUniqueKey(plan, index)}
        plan={plan}
        setLevel={setLevel}
        setAction={setAction}
        setPlanId={setPlanId}
        setIsModalOpened={setIsModalOpened}
        isEven={isEven(index)}
      />
    ))

  const reversedOtherPlans = currentSubscription?.free
    ? otherPlans
    : otherPlans?.reverse()

  return (
    <div className={classes.subscriptionContainer}>
      <SBaseLoader isLoading={isLoading} />
      {!isLoading && (
        <>
          <SubscriptionBar
            text={t('currentSubscriptions')}
            variant={VARIANTS.CURRENT}
          />
          <SubscriptionPlan
            plan={{ subscription: user?.subscription }}
            setLevel={setLevel}
            setAction={setAction}
            setPlanId={setPlanId}
            setIsModalOpened={setIsModalOpened}
          />
          <SubscriptionBar text={t('otherPlans')} variant={VARIANTS.OTHER} />
          {reversedOtherPlans}
          <ContactSupport />
        </>
      )}
      <SubscriptionModal
        level={level}
        action={action}
        planId={planId}
        isOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        actions={actions}
      />
      <CommonLoadingOverlay
        dialogOpen={loading.postUpgradeSubscription}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </div>
  )
}
