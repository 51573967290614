export const HEADER_NAMES = {
  fileName: 'File name',
  sharedWith: 'Shared With',
  sharedBy: 'Shared By',
  shareDate: 'Share date',
  lastPlayed: 'Last played',
  daysPlaysRemaining: 'remaining',
  status: 'Status',
  fileShared: 'File Name',
}

export const setDesktopHeaders = isOwner => {
  return [
    {
      name: HEADER_NAMES.fileName,
      label: 'fileName',
      width: '20%',
      sortBy: 'fileName',
    },
    {
      name: isOwner ? HEADER_NAMES.sharedWith : HEADER_NAMES.sharedBy,
      label: isOwner ? 'sharedWith' : 'sharedBy',
      width: '20%',
      sortBy: '',
    },
    {
      name: HEADER_NAMES.lastPlayed,
      label: 'lastPlayed',
      width: '15%',
      sortBy: 'lastAccessedAt',
    },
    {
      name: HEADER_NAMES.daysPlaysRemaining,
      label: 'remaining',
      width: '15%',
      sortBy: 'expiresAt',
    },
    {
      name: HEADER_NAMES.status,
      label: 'status',
      width: '25%',
      sortBy: 'status',
    },
    { name: '', width: '5%' },
  ]
}

export const SHARE_STATUSES = {
  PENDING: 'pending',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  REVOKED: 'revoked',
  REJECTED: 'rejected',
}

export const SHARE_EXPIRATION_TYPES = {
  DATE: 'date',
  COUNT: 'count',
}
