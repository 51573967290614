import React, { useEffect, useState } from 'react'
import { StepWrapper } from '#pages/sell-collectibles/create-collectible/_components/step-wrapper'
import { useTranslation } from 'react-i18next'
import { Persona } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_sections/persona'
import { FormProvider } from 'react-hook-form'
import { NFT_FILES_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'
import { SubmitStep } from '#pages/sell-collectibles/create-collectible/_components/step-submit'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { Music } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_sections/music'
import { useNftData } from '#pages/sell-collectibles/create-collectible/_hooks/use-nft-data'
import { Artwork } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_sections/artwork'
import { ErrorMessage } from '#components/error-message'
import { PersonaWarningModal } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/persona-warning-modal'
import styled from 'styled-components'
import { useArtistStructureCount } from '#hooks/swr/useArtists'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

const SContent = styled.div`
  padding: ${({ paddingBottom }) =>
    paddingBottom ? `2.5rem 2.5rem 12rem` : '2.5rem'};
`

export const FilesStepContent = () => {
  const { t } = useTranslation('nftWizard')
  const [loading, setLoading] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [personaChanged, setPersonaChanged] = useState(false)

  const {
    nftId,
    setCompletedStep,
    activeStep,
    setActiveStep,
    filesStepMethods: methods,
    clearWizard,
  } = useNftCreator()

  const { error, saveAudio, saveArtwork, init, deleteDraft } = useNftData()

  const personaField = methods.watch(NFT_FILES_STEP_FIELDS.PERSONA)
  const musicField = methods.watch(NFT_FILES_STEP_FIELDS.MUSIC)
  const artworkField = methods.watch(NFT_FILES_STEP_FIELDS.ARTWORK)
  const [prevPersona, setPrevPersona] = useState(null)

  const { data: singleData } = useArtistStructureCount(
    personaField?.value,
    STRUCTURE_TYPE.SONG
  )

  const hasPersonaSingle = singleData?.count > 0

  const allFieldsFilled = !!(
    personaField?.value &&
    musicField?.id &&
    artworkField?.id
  )

  useEffect(() => {
    if (personaChanged && prevPersona?.value !== personaField?.value) {
      if (musicField?.id) methods.setValue(NFT_FILES_STEP_FIELDS.MUSIC, null)
      if (artworkField?.id)
        methods.setValue(NFT_FILES_STEP_FIELDS.ARTWORK, null)

      setPrevPersona(personaField)
    }
  }, [personaChanged, personaField])

  const saveFiles = async (values, clear = false) => {
    if (nftId && !clear) {
      await Promise.all([
        saveArtwork(nftId, values[NFT_FILES_STEP_FIELDS.ARTWORK]),
        saveAudio(nftId, values[NFT_FILES_STEP_FIELDS.MUSIC]),
      ])
    } else {
      await init(
        values[NFT_FILES_STEP_FIELDS.PERSONA].value,
        values[NFT_FILES_STEP_FIELDS.MUSIC],
        values[NFT_FILES_STEP_FIELDS.ARTWORK]
      )

      setCompletedStep(0)
    }

    setPersonaChanged(false)
    methods.reset(values)

    setActiveStep(activeStep + 1)
  }

  const confirmPersonaChange = async () => {
    setLoading(true)
    setWarningModal(false)

    try {
      await deleteDraft()

      clearWizard()

      await saveFiles(methods.getValues(), true)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async values => {
    setLoading(true)
    try {
      if (personaChanged && nftId) setWarningModal(true)
      else await saveFiles(values)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepWrapper
      title={t('steps.files.title')}
      infoKey="steps.files.info"
      step={1}
      loading={loading}
    >
      <SContent paddingBottom={!personaField?.value}>
        <FormProvider {...methods}>
          <Persona setPersonaChanged={setPersonaChanged} />
          {personaField?.value && (
            <>
              <Music
                parentId={personaField.value}
                name={personaField.label}
                hasPersonaSingle={hasPersonaSingle}
              />
              <Artwork
                parentId={personaField.value}
                hasPersonaSingle={hasPersonaSingle}
              />
            </>
          )}
          <SubmitStep
            disabled={!allFieldsFilled || loading}
            formFields={Object.values(NFT_FILES_STEP_FIELDS)}
            onSubmit={onSubmit}
          />
        </FormProvider>
        {error && <ErrorMessage errorMessage={t('error')} />}
        <PersonaWarningModal
          isOpen={warningModal}
          onClose={() => setWarningModal(false)}
          onConfirm={confirmPersonaChange}
        />
      </SContent>
    </StepWrapper>
  )
}
