import { useCallback, useEffect, useState } from 'react'
import { setLocalStorageValue } from '#utils/localStorage'

export const useLocalStorage = key => {
  const [value, setValue] = useState(localStorage.getItem(key))
  useEffect(() => {
    const listener = () => {
      const newValue = localStorage.getItem(key)
      if (value === newValue) return
      setValue(newValue)
    }
    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [value, key])
  const returnSetValue = useCallback(
    newValue => {
      if (value === newValue) return
      setLocalStorageValue(key, newValue)
    },
    [key, value]
  )
  return [value, returnSetValue]
}
