import React from 'react'
import { shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '40px',
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: '45%',
    whiteSpace: 'pre-line',
    padding: '30px',
  },
}))

export const ImageWithDescription = props => {
  const { item } = props
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <img src={item.image} width={800} alt="" />
      <div className={classes.content}>
        <div className={classes.header}>{t(item.header)}</div>
        <div className={classes.description}>{t(item.description)}</div>
      </div>
    </div>
  )
}

ImageWithDescription.propTypes = {
  item: shape({
    image: string.isRequired,
    header: string.isRequired,
    description: string.isRequired,
  }).isRequired,
}
