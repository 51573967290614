import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { AutoComplete } from '#components/inputs/auto-complete'
import { createCollaboratorValidationSchema } from '#pages/vault/_modules/add-collaborator-modal/utils/validation'
import { loadContactsList } from '#pages/vault/landing-page/utils/helpers'
import { AddOffPlatformFooter } from '#pages/_components/add-off-platform-footer'
import { Checkbox } from '#components/checkbox'
import { ContributorForm } from '#pages/vault/_modules/add-collaborator-modal/components/contributor-form'
import { FORM_FIELDS } from '#pages/vault/_modules/add-collaborator-modal/constants'
import { Modal } from '@tunego/tunego-ui-components'

const SForm = styled.form`
  width: 100%;
  outline: none;
  max-height: 80vh;
  overflow: auto;
`

const SAutocompleteWrapper = styled.div`
  margin-bottom: 1rem;
`

const SModal = styled(Modal)`
  width: 500px;

  h2 {
    margin: 0;
  }
`

export const SideOne = props => {
  const {
    open,
    handleClose,
    handleSwitchSides,
    handleInviteCollaborator,
    setChecked,
    checked = false,
    isProject,
  } = props
  const { t } = useTranslation('vault')

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCollaboratorValidationSchema(t)),
  })

  return (
    <SModal
      isOpen={open}
      title={t(`addCollaborator`)}
      closeButton
      onClose={handleClose}
    >
      <SForm onSubmit={handleSubmit(handleInviteCollaborator)}>
        <div>
          <SAutocompleteWrapper>
            <Controller
              name={FORM_FIELDS.USER}
              control={control}
              render={({ field: { onChange } }) => (
                <AutoComplete
                  id="search_users"
                  placeholder={t(`searchUsers`)}
                  label={t('userName')}
                  loadOptions={loadContactsList()}
                  errorMessage={errors?.user?.message}
                  onChange={({ value }) => {
                    onChange(value)
                  }}
                />
              )}
            />
          </SAutocompleteWrapper>
          <RolesSelect control={control} errorMessage={errors?.role?.message} />
          {isProject && (
            <>
              <Checkbox
                name="addAsContributor"
                checked={checked}
                label={t('addAsContributor')}
                onChange={() => setChecked(!checked)}
              />
              {checked && (
                <ContributorForm
                  control={control}
                  register={register}
                  errors={errors}
                />
              )}
            </>
          )}
        </div>
        <AddOffPlatformFooter handleSwitchSides={handleSwitchSides} />
      </SForm>
    </SModal>
  )
}

SideOne.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  handleSwitchSides: func.isRequired,
  handleInviteCollaborator: func.isRequired,
  setChecked: func.isRequired,
  checked: bool.isRequired,
  isProject: bool.isRequired,
}
