import React from 'react'
import { number, func, string, bool, shape, arrayOf } from 'prop-types'
import { Menu, Button } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { FolderMenu } from '#pages/vault/profile-content/components/ellipsis-menu/components/folder-menu'
import { SongMenu } from '#pages/vault/profile-content/components/ellipsis-menu/components/song-menu'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

const useStyles = makeStyles(theme => ({
  Menu: {
    '& .MuiPaper-rounded': {
      borderRadius: '0px',
    },
    '& .MuiMenu-list': {
      background: theme.palette.color.primary,
      color: theme.palette.color.darkBlue09,
    },
    '& .MuiMenuItem-root': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: 14,
      marginBottom: 10,
      marginTop: 10,

      '&:hover': {
        background: theme.palette.color.darkBlue09,
        color: theme.palette.color.default,
      },
    },
  },
}))

export const EllipsisMenu = ({
  name,
  accessRoles,
  idOfActiveActionItem,
  isSubRow,
  index,
  folderOrSong,
  id,
  setTypeOfCurrentAction,
  setIdOfActiveActionItem,
  handleAddEditCollaborators,
  setStructureCategory,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('vault')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isActive = id === idOfActiveActionItem

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const addInlineOption = {
    type: ELLIPSIS_OPTIONS.ADD_INLINE,
    title: t(`addSongToAlbum`),
  }

  const moveSong = {
    type: ELLIPSIS_OPTIONS.MOVE_SONG,
    title: t(`moveSong`),
  }

  const editInlineOption = structureType => {
    return {
      type: ELLIPSIS_OPTIONS.EDIT_INLINE,
      title: t(`edit${structureType}`),
    }
  }

  const inviteCollaboratorOption = structureType => {
    return {
      type: ELLIPSIS_OPTIONS.INVITE_COLLABORATOR,
      title: t(`inviteTo${structureType}`),
    }
  }

  const addEditCollaboratorsOption = () => {
    return {
      type: ELLIPSIS_OPTIONS.ADD_EDIT_COLLABORATORS,
      title: t('addEditCollaborators'),
      option: () =>
        handleAddEditCollaborators({
          id,
          name,
          accessRoles,
        }),
    }
  }

  const viewCollaboratorsOption = () => {
    return {
      type: ELLIPSIS_OPTIONS.VIEW_COLLABORATORS,
      title: t('viewCollaborators'),
      option: () =>
        handleAddEditCollaborators({
          id,
          name,
          accessRoles,
        }),
    }
  }

  const determineEllipsisMenuFolderOptions = () => {
    switch (true) {
      case accessRoles?.includes(ACCESS_ROLES.OWNER):
        return [
          addInlineOption,
          editInlineOption('Folder'),
          addEditCollaboratorsOption(),
        ]
      case accessRoles?.includes(ACCESS_ROLES.COLLABORATOR):
        return [addInlineOption, viewCollaboratorsOption()]
      default:
        return []
    }
  }

  const determineEllipsisMenuSongOptions = () => {
    switch (true) {
      case accessRoles?.includes(ACCESS_ROLES.OWNER):
        return [
          editInlineOption('Project'),
          inviteCollaboratorOption('Project'),
          moveSong,
        ]
      case accessRoles?.includes(ACCESS_ROLES.COLLABORATOR):
        return [editInlineOption('ProjectWithoutRemoval')]
      default:
        return []
    }
  }

  const folderOptions = determineEllipsisMenuFolderOptions()
  const songOptions = determineEllipsisMenuSongOptions()
  const displayOptionsMenu = !!folderOptions.length || !!songOptions.length

  if (!displayOptionsMenu) {
    return null
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon color={isActive || isSubRow ? 'default' : 'primary'} />
      </Button>
      <Menu
        className={classes.Menu}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        onClose={handleClose}
      >
        <div>
          {folderOrSong === STRUCTURE_TYPE.ALBUM ? (
            <FolderMenu
              {...{
                index,
                folderOrSong,
                handleClose,
                folderOptions,
                id,
                setTypeOfCurrentAction,
                setIdOfActiveActionItem,
                setStructureCategory,
              }}
            />
          ) : (
            <SongMenu
              {...{
                index,
                folderOrSong,
                handleClose,
                songOptions,
                id,
                setTypeOfCurrentAction,
                setIdOfActiveActionItem,
                setStructureCategory,
              }}
            />
          )}
        </div>
      </Menu>
    </div>
  )
}

EllipsisMenu.propTypes = {
  project: shape({}),
  accessRoles: arrayOf(string).isRequired,
  iconColor: string,
  index: number,
  mainRowIndex: number,
  folderOrSong: string,
  isSubRow: bool,
  subRowIndex: number,
  id: string.isRequired,
  idOfActiveActionItem: string,
  setTypeOfCurrentAction: func.isRequired,
  setIdOfActiveActionItem: func.isRequired,
  name: string,
  handleAddEditCollaborators: func.isRequired,
  setStructureCategory: func.isRequired,
}
EllipsisMenu.defaultProps = {
  project: null,
  idOfActiveActionItem: null,
  isSubRow: false,
  iconColor: '',
  index: null,
  mainRowIndex: null,
  folderOrSong: '',
  subRowIndex: null,
  name: '',
}
