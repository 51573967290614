import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(() => ({
  wrapper: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 25,
    maxWidth: 250,
  },
  listHeader: {
    paddingBottom: 10,
  },
  list: {
    listStyleType: 'disc',
    margin: '0',
    padding: '0',
    paddingBottom: 15,

    '& li': {
      marginLeft: 15,
      paddingBottom: 5,
    },
  },
}))

export const ArtworkRequirements = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  return (
    <div className={classes.wrapper}>
      <Text type={TEXT_TYPE.SUBTITLE2} className={classes.listHeader}>
        {t('artworkImageRequirements')}
      </Text>
      <ul className={classes.list}>
        <li>{t('thePixelHeightAndWidth')}</li>
        <li>{t('imageMustBeSmaller')}</li>
        <li>{t('supportedImageTypes')}</li>
      </ul>
      <Text type={TEXT_TYPE.SUBTITLE2} className={classes.listHeader}>
        {t('toAvoidReleaseBeingRejected')}
      </Text>
      <ul className={classes.list}>
        <li>{t('socialMediaLogos')}</li>
        <li>{t('brandLogos')}</li>
        <li>{t('textOtherThanArtistNames')}</li>
      </ul>
    </div>
  )
}
