import React from 'react'
import { string, func, arrayOf, shape } from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FIELDS } from '#pages/distro/_utils/constants'
import { CheckBoxItem } from '#pages/distro/wizard/steps/delivery-and-release-date/components/checkbox-item'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(() => ({
  item: {
    height: 50,
    width: '100%',
  },
}))

export const TerritoriesItems = ({
  onChange,
  countries,
  checkedCountry,
  register,
}) => {
  const classes = useStyles()

  return countries.map((country, index) => (
    <div key={generateUniqueKey(country, index)} className={classes.item}>
      <CheckBoxItem
        key={country.code}
        register={register}
        label={country.name}
        index={index}
        name={FIELDS.COUNTRIES}
        checked={checkedCountry[country.code]}
        value={country.code}
        onChange={event => onChange(country, event.target.checked)}
      />
    </div>
  ))
}

TerritoriesItems.propTypes = {
  selectedTerritory: string.isRequired,
  onChange: func.isRequired,
  countries: arrayOf(
    shape({
      code: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  checkedCountry: shape({}).isRequired,
  register: func.isRequired,
}
