import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'

export const useContributors = (structureId, query) => {
  const { data, error, ...rest } = useSWR(
    `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS, {
      id: structureId,
    })}${query}`
  )

  return {
    contributors: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useSingleContributor = (
  shouldFetch,
  structureId,
  contributorId
) => {
  const { data, error, ...rest } = useSWR(
    shouldFetch &&
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_CONTRIBUTORS_CONTRIBUTOR, {
        structureId,
        contributorId,
      })
  )

  return {
    contributor: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
