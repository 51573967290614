import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { arrayOf, number, bool, shape, string } from 'prop-types'
import { navigate, useLocation } from '@reach/router'
import { NavigationItem } from '../navigation-item'
import { NAVIGATION_PATHS } from '#routes/routes'
import { StepExitWarningModalContainer } from '#modules/forms/form-wizard/containers/step-exit-warning-modal-container'
import { useWizard } from '#modules/forms/form-wizard/context'

const useStyles = makeStyles(theme => {
  const { palette } = theme
  return {
    navigationContainer: {
      zIndex: 3,
      backgroundColor: palette.background.default,
      position: 'fixed',
      borderBottom: `2px solid ${palette.color.primary}`,
      top: 0,
      left: 0,
      width: '100%',
    },
    navigationFlexCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    navigationLogo: {
      marginTop: 48,
      marginBottom: 32,
      width: 110,
    },
    navigationItemsWrapper: {
      display: 'flex',
    },
  }
})

export const Navigation = ({ navigationItems, navigationExitString }) => {
  const classes = useStyles()
  const location = useLocation()
  const { step, isStoredFormStateEmpty } = useWizard()

  const [isExitWarningModalOpen, setIsExitWarningModalOpen] = useState(false)
  const showExitWarningModal =
    !isStoredFormStateEmpty &&
    location.pathname.includes(NAVIGATION_PATHS.PROMOTION_NEW)

  const closeExitWarningModal = () => setIsExitWarningModalOpen(false)
  const openExitWarningModal = () => setIsExitWarningModalOpen(true)
  const onStepExitModalConfirmHandler = () => navigate(navigationExitString)

  const onLogoClickHandler = () => {
    if (!navigationExitString) {
      return {}
    }

    if (showExitWarningModal) {
      openExitWarningModal()
      return
    }

    navigate(navigationExitString)
  }

  return (
    <div className={classes.navigationContainer}>
      <div className={classes.navigationFlexCenter}>
        <img
          className={classes.navigationLogo}
          src="/assets/svg/ic-tunego-logo.svg"
          alt="TuneGO logo"
          style={{ cursor: navigationExitString ? 'pointer' : '' }}
          onClick={onLogoClickHandler}
        />
      </div>
      <div className={classes.navigationItemsWrapper}>
        {navigationItems.map(({ stepNumber, text }) => (
          <NavigationItem
            key={stepNumber}
            stepsLength={navigationItems.length}
            isActive={step === stepNumber}
            step={stepNumber}
          >
            {text}
          </NavigationItem>
        ))}
      </div>
      <StepExitWarningModalContainer
        isOpened={isExitWarningModalOpen}
        onClose={closeExitWarningModal}
        onConfirm={onStepExitModalConfirmHandler}
      />
    </div>
  )
}

Navigation.propTypes = {
  step: number.isRequired,
  navigationItems: arrayOf(shape({ stepNumber: number, text: string }))
    .isRequired,
  navigationExitString: string,
  isStoredFormStateEmpty: bool,
}

Navigation.defaultProps = {
  navigationExitString: undefined,
}
