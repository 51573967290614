import React from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { FeaturedArtistsApi } from '#api/requests/featuredArtists'
import { FeaturedArtistItem } from '#pages/distro/wizard/steps/add-song-files/components/featured-artist-item'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useWizard } from '#modules/forms/form-wizard/context'
import { FIELDS } from '#pages/distro/_utils/constants'
import { useError } from '#hooks/useError'
import { generateUniqueKey } from '#utils/generateUniqueKey'

export const FeaturedArtistsList = ({ artists, songId }) => {
  const { error, setResponseError, clearError } = useError()
  const { setStoredFormState, storedFormState } = useWizard()

  const { actions } = useWithAsyncAction({
    deleteFeaturedArtist: FeaturedArtistsApi.deleteFeaturedArtist,
  })

  const handleArtistDelete = async artistId => {
    try {
      await actions.deleteFeaturedArtist(songId, artistId)

      setStoredFormState({
        [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
          file.id === songId
            ? {
                ...file,
                featuredArtists: file.featuredArtists.filter(
                  artist => artist.id !== artistId
                ),
              }
            : { ...file }
        ),
      })
    } catch (err) {
      setResponseError(err)
    }
  }

  const displayFeaturedArtists = artists?.map((artist, index) => (
    <FeaturedArtistItem
      key={generateUniqueKey(artist, index)}
      artist={artist}
      handleDelete={handleArtistDelete}
      index={index}
    />
  ))

  if (error) {
    return (
      <CommonLoadingOverlay error={error} onErrorBackdropClick={clearError} />
    )
  }

  return <>{displayFeaturedArtists}</>
}

FeaturedArtistsList.propTypes = {
  artists: arrayOf(
    shape({
      name: string.isRequired,
      id: string.isRequired,
    })
  ).isRequired,
  songId: string.isRequired,
}
