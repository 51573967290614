import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { bool, func, shape, string, arrayOf } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Hidden } from '@material-ui/core'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { BaseInput } from '#components/inputs/base-input'
import { DeleteModal } from '#pages/_components/delete-modal'
import { ROLES } from '#constants/roles'
import { RolesSelect } from '#pages/vault/_modules/roles-select'
import { CollaboratorsApi } from '#api/requests/collaborators'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { editCollaboratorRoleValidationSchema } from '#pages/_modules/collaborators-table/utils/validation'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 32,
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: 16,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 6fr',
    marginTop: 20,
  },
  boxGroup: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  form: {
    outline: 'none',
  },
}))

const SButton = styled(Button)`
  width: 116px;
  text-transform: uppercase;
`

const SDeleteButton = styled(Button)`
  padding: 0 20px;
  text-transform: uppercase;
`

export const EditCollabModal = ({
  open,
  handleClose,
  projectId,
  collaborator,
  triggerMutate,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const [error, setError] = useState('')
  const [isDeleteCollaboratorModalOpen, setIsDeleteCollaboratorModalOpen] =
    useState(false)

  const currentRoles = collaborator.role.map(item => ({
    value: ROLES.find(roleOption => roleOption.value === item).value,
    label: ROLES.find(roleOption => roleOption.value === item).label,
  }))

  const inputWidth = '541'
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(editCollaboratorRoleValidationSchema),
    defaultValues: { role: currentRoles },
  })

  const {
    actions,
    loading,
    errors: asyncErrors,
  } = useWithAsyncAction({
    patchCollaborator: CollaboratorsApi.patchCollaborator,
    deleteCollaborator: CollaboratorsApi.deleteCollaborator,
  })

  const handleOpenDeleteModal = () => {
    setIsDeleteCollaboratorModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteCollaboratorModalOpen(false)
  }

  const handleEditCollaborator = async data => {
    const structureId = projectId
    const collaboratorId = collaborator.id
    const { role } = data
    const listOfRoles = role.map(item => item.value)
    const newRole = {
      role: listOfRoles,
    }
    await actions.patchCollaborator({
      structureId,
      newRole,
      collaboratorId,
    })
    triggerMutate()
    handleClose()
  }

  const deleteCollaborator = async () => {
    const structureId = projectId
    const collaboratorId = collaborator.id
    await actions.deleteCollaborator({
      structureId,
      collaboratorId,
    })
    triggerMutate()
    handleClose()
    handleCloseDeleteModal()
  }

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (asyncErrors.updateFile) {
      handleSetError(asyncErrors.updateFile)
    }
    if (asyncErrors.deleteFile) {
      handleSetError(asyncErrors.deleteFile)
    }
  }, [asyncErrors, handleSetError])

  if (loading.patchCollaborator || loading.deleteCollaborator || error) {
    return (
      <CommonLoadingOverlay
        message={vaultTranslation.t('collaboratorsLoading')}
        dialogOpen={loading.patchCollaborator || loading.deleteCollaborator}
        error={error}
        onErrorBackdropClick={() => {
          setError('')
        }}
      />
    )
  }

  const userFullName =
    collaborator.pendingName ||
    `${collaborator.user.firstName} ${collaborator.user.lastName}`

  return (
    <>
      <Modal
        isOpen={open}
        setIsOpen={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleEditCollaborator)}
        >
          <div>
            <div className={classes.title}>
              {vaultTranslation.t('editCollaborator')}
            </div>
            <div>
              <BaseInput
                label={vaultTranslation.t('userName')}
                name="userName"
                placeholder={vaultTranslation.t('searchUsers')}
                inputRef={register}
                customWidth={inputWidth}
                defaultValue={userFullName}
                errorMessage={errors?.userName?.message}
                isBolded
                isReadOnly
              />
              <RolesSelect
                control={control}
                errorMessage={errors?.role?.message}
                defaultValue={currentRoles}
              />
            </div>
            <div className={classes.grid}>
              <SButton type="submit">{t('save')}</SButton>
              <SButton
                variant={VARIANTS.NEW_BLUE_SPECIAL}
                type="button"
                onClick={handleClose}
              >
                {t('cancel')}
              </SButton>
              <div className={classes.boxGroup}>
                <SDeleteButton
                  variant={VARIANTS.WARNING}
                  type="button"
                  onClick={handleOpenDeleteModal}
                >
                  <Hidden xsDown>
                    {vaultTranslation.t('deleteCollaborator')}
                  </Hidden>
                  <Hidden smUp>{t('delete')}</Hidden>
                </SDeleteButton>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <DeleteModal
        handleClose={handleCloseDeleteModal}
        open={isDeleteCollaboratorModalOpen}
        handleDelete={deleteCollaborator}
        firstSpan={vaultTranslation.t('areYouSureYouWantToDeleteCollaborator')}
        secondSpan={vaultTranslation.t('thisActionIsPermanent')}
        thirdSpan={vaultTranslation.t('pleaseConfirmThatYouWantToDelete')}
        deleteBtnText={vaultTranslation.t('yesPermanentlyDelete')}
      />
    </>
  )
}

EditCollabModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  projectId: string.isRequired,
  collaborator: shape({
    user: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
    }),
    id: string.isRequired,
    role: arrayOf(string.isRequired),
  }).isRequired,
  triggerMutate: func.isRequired,
}
