import { useEffect, useState } from 'react'
import { ACCESS_ROLES } from '#constants/accessRoles'

export const useIsVaultEmpty = getArtist => {
  const [isEmptyVault, setIsEmptyVault] = useState(false)
  const roles = [ACCESS_ROLES.OWNER, ACCESS_ROLES.COLLABORATOR]

  useEffect(() => {
    const getArtistProfiles = async () => {
      const { data } = await getArtist({
        roles,
      })

      if (data?.count === 0) {
        setIsEmptyVault(true)
      }
    }

    getArtistProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isEmptyVault
}
