import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Section } from '#components/section'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled from 'styled-components'

const useStyles = makeStyles(() => ({
  description: {
    fontSize: 18,
    margin: 0,
  },
  flexWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  boldDescription: {
    fontFamily: 'AvenirNextLTPro-Bold',
    fontSize: '18px',
    margin: 0,
  },
  underlinedWrapper: {
    fontFamily: 'AvenirNextLTPro-Bold',
    position: 'relative',
    marginLeft: 8,
    fontSize: '18px',
  },
  img: {
    position: 'absolute',
    bottom: -10,
    left: 0,
  },
}))

const SSection = styled(Section)`
  padding-bottom: 54px;
  background-image: none;
`

export const PromoteSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <SSection
      header={t('youUsedToNeedALabel')}
      getStartedUrl={NAVIGATION_PATHS.PROMOTION_NEW}
      iconUrl="/assets/images/megaphone.png"
      subheaderComponent={
        <>
          <p className={classes.description}>{t('tuneGoPromotesYourMusic')}</p>
          <div className={classes.flexWrapper}>
            <p className={classes.boldDescription}>{t('ourMissionIsTo')} </p>
            <div className={classes.underlinedWrapper}>
              <span>{t('itsSimple')}</span>
              <img
                className={classes.img}
                src="/assets/svg/longRedLine.svg"
                width="100%"
                alt=""
              />
            </div>
          </div>
        </>
      }
    />
  )
}

PromoteSection.propTypes = {
  redirectLink: string.isRequired,
}
