import React, { useState, useEffect, useCallback } from 'react'
import { navigate, useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'
import { createProfileValidationSchema } from '#pages/profile/utils/validation'
import { ProfileForm } from '#pages/vault/_modules/profile-form'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { ArtistApi } from '#api/requests/artist'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { buildCloudFrontImgUrl } from '#utils/buildCloudFrontImgUrl'
import { DeleteModal } from '#pages/_components/delete-modal'
import { PageWrapper } from '#modules/page-wrapper'
import { buildUrl } from '#utils/buildUrl'

export const CreateProfile = ({ isEdit }) => {
  const { id } = useParams()
  const { project, mutate } = useArtistStructure(id)
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')

  const [error, setError] = useState('')
  const [hasPreview, setHasPreview] = useState(false)
  const [previewFile, setPreviewFile] = useState('')
  const [isImageUpdated, setIsImageUpdated] = useState(false)
  const [isImageDeleted, setIsImageDeleted] = useState(false)
  const [existingProfileName, setExistingProfileName] = useState('')
  const [existingBio, setExistingBio] = useState('')
  const [isModalOpened, setIsModalOpened] = useState(false)

  const { actions, anyLoading, anyError } = useWithAsyncAction({
    postArtistProfile: ArtistApi.postArtistProfile,
    patchArtistProfile: ArtistApi.patchArtistProfile,
    manageUploadAvatar: ArtistApi.manageUploadAvatar,
    deleteProfileAvatar: ArtistApi.deleteArtistAvatar,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (anyError) {
      handleSetError(anyError)
    }
    if (project?.avatarUrl) {
      setPreviewFile(buildCloudFrontImgUrl(project.avatarUrl))
    }
    if (project?.name) {
      setExistingProfileName(project.name)
    }
    if (project?.bio) {
      setExistingBio(project.bio)
    }
  }, [anyError, handleSetError, project])

  const handleCreate = async ({ profileName, bio }) => {
    const {
      // eslint-disable-next-line no-shadow
      data: { id },
    } = await actions.postArtistProfile({
      name: profileName,
      bio,
    })
    if (previewFile) {
      await actions.manageUploadAvatar(id, previewFile)
    }
    navigate(buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { id }))
  }

  const handleEdit = async ({ profileName, bio }) => {
    await actions.patchArtistProfile(id, { name: profileName, bio })
    if (previewFile && isImageUpdated && !isImageDeleted) {
      await actions.manageUploadAvatar(id, previewFile)
    }
    navigate(buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, { id }))
  }

  const handleCancel = () => {
    navigate(NAVIGATION_PATHS.VAULT_PROFILES)
  }

  const handlePreview = file => {
    setPreviewFile(file)
    setHasPreview(true)
    setIsImageUpdated(true)
    setIsImageDeleted(false)
  }

  const handleOpenModal = () => {
    setIsModalOpened(true)
  }

  const handleDeleteFile = async () => {
    if (isEdit) {
      await actions.deleteProfileAvatar(id)
    }

    mutate()
    setPreviewFile('')
    setHasPreview(false)
    setIsImageUpdated(true)
    setIsImageDeleted(true)
    setIsModalOpened(false)
  }

  const title = isEdit ? 'editProfile' : 'createNewProfile'

  return (
    <PageWrapper showNavBar isFullWidth>
      <ProfileForm
        title={vaultTranslation.t(title)}
        subTitle={vaultTranslation.t('enterArtist')}
        submitButtonText={t('save')}
        validationSchema={createProfileValidationSchema(vaultTranslation.t)}
        loadingMessage={vaultTranslation.t('editingArtistProfile')}
        {...{
          handleCancel,
          handlePreview,
          handleCreate: isEdit ? handleEdit : handleCreate,
          handleDeleteFile: handleOpenModal,
          setError,
          hasPreview,
          previewFile,
          profileName: existingProfileName,
          bio: existingBio,
          isSending: anyLoading,
          error,
          isImageUpdated,
        }}
      />
      <DeleteModal
        handleClose={() => setIsModalOpened(false)}
        open={isModalOpened}
        handleDelete={handleDeleteFile}
        firstSpan={vaultTranslation.t('areYouSureToDelete')}
        secondSpan={vaultTranslation.t('thisActionIsPermanent')}
        thirdSpan={vaultTranslation.t('pleaseConfirm')}
        deleteBtnText={vaultTranslation.t('yesPermanentlyDeleteImage')}
      />
    </PageWrapper>
  )
}

CreateProfile.propTypes = {
  isEdit: bool.isRequired,
}
