import { MB_AS_BYTES, BYTES_IN_KB, GB_AS_BYTES } from '#constants/maxFileSize'

export const convertBytesToMb = bytes => ({
  size: (bytes / MB_AS_BYTES).toFixed(1),
  unit: 'Mb',
})

export const convertBytesToGb = size => {
  const bytes = typeof size === 'string' ? parseInt(size, 10) : size

  return {
    size: (bytes / GB_AS_BYTES).toFixed(1),
    unit: 'Gb',
  }
}
export const convertBytesToKb = bytes => ({
  size:
    bytes < BYTES_IN_KB ? bytes.toFixed(0) : (bytes / BYTES_IN_KB).toFixed(0),
  unit: bytes < BYTES_IN_KB ? 'B' : 'kB',
})

export const convertBytesAndDetermineUnit = size => {
  const bytes = typeof size === 'string' ? parseInt(size, 10) : size

  return bytes > MB_AS_BYTES ? convertBytesToMb(bytes) : convertBytesToKb(bytes)
}
