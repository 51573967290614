import React from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import { string, func } from 'prop-types'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(theme => ({
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: 60,
    padding: '0 20px',
    background: theme.palette.color.darkBlue03,
    flexGrow: 1,
  },
  topBarTitle: {
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    width: 720,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const SButton = styled(Button)`
  height: 30px;
`

export const TopButtonBar = ({
  buttonText,
  topBarTitle,
  setIsAddCollabModalOpen,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.topBar}>
      <Text type={TEXT_TYPE.H2} className={classes.topBarTitle}>
        {topBarTitle}
      </Text>
      <SButton
        variant={VARIANTS.NEW_BLUE_SPECIAL}
        onClick={() => setIsAddCollabModalOpen(true)}
      >
        {buttonText}
      </SButton>
    </div>
  )
}

TopButtonBar.propTypes = {
  setIsAddCollabModalOpen: func.isRequired,
  buttonText: string.isRequired,
  topBarTitle: string,
}

TopButtonBar.defaultProps = {
  topBarTitle: '',
}
