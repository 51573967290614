import { createContext, useContext } from 'react'

export const HasUnsavedChangesContext = createContext({
  hasUnsavedChanges: null,
  whereToNavigate: null,
  setHasUnsavedChanges: () => {},
  setWhereToNavigate: () => {},
})

export const useHasUnsavedChanges = () => {
  const context = useContext(HasUnsavedChangesContext)
  if (!context) {
    throw new Error(
      'useHasUnsavedChanges must be used within HasUnsavedChangesProvider'
    )
  }
  return context
}
