import { DEFAULT_AVATAR } from '#constants/defaultAvatar'

export const determineAvatar = (category, avatarUrl) => {
  if (category === 'profile') {
    return avatarUrl || DEFAULT_AVATAR
  }
  if (category === 'folder') {
    return '/assets/svg/ic-folder-blue.svg'
  }
  if (category === 'project') {
    return '/assets/svg/ic-tunego-blue.svg'
  }
  return ''
}
