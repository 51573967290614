import { useForm } from 'react-hook-form'

export const useSplitsStep = () => {
  // const { t } = useTranslation('nftWizard')

  const methods = useForm({
    // TODO: splits validation schema
    // resolver: yupResolver(nftDetailsSchema(t, minReleaseDate)),
    // defaultValues: NFT_DETAILS_STEP_DEFAULT_VALUES,
    mode: 'all',
  })

  return {
    methods,
  }
}
