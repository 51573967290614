import dayjs from 'dayjs'

const MINUTE = 1000 * 60
const HOUR = MINUTE * 60
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = WEEK * 4
const YEAR = 365 * DAY

export const determineMostRecent = time => {
  const timeDifference = dayjs().valueOf() - dayjs(time).valueOf()

  if (timeDifference < MINUTE) {
    return 'Just now'
  }
  if (timeDifference < HOUR) {
    return `${(timeDifference / MINUTE).toFixed(0)} min ago`
  }
  if (timeDifference < DAY) {
    return `${(timeDifference / HOUR).toFixed(0)} hours ago`
  }
  if (timeDifference < 2 * DAY) {
    return 'Yesterday'
  }
  if (timeDifference < WEEK) {
    return `${(timeDifference / DAY).toFixed(0)} days ago`
  }
  if (timeDifference < MONTH) {
    return `${(timeDifference / WEEK).toFixed(0)} week(s) ago`
  }
  if (timeDifference < YEAR) {
    return `${(timeDifference / MONTH).toFixed(0)} month(s) ago`
  }
  return `${(timeDifference / YEAR).toFixed(0)} year(s) ago`
}
