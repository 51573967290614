import React, { useState } from 'react'
import { Button, ButtonSize, ButtonTheme } from '@tunego/tunego-ui-components'
import { useTranslation } from 'react-i18next'
import { AddSingleModal } from '#pages/vault/profile-content/components/add-single-modal'
import { string } from 'prop-types'

export const AddSingle = ({ parentId, name }) => {
  const { t } = useTranslation('nftWizard')
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false)

  return (
    <>
      <Button
        buttonTheme={ButtonTheme.Secondary}
        buttonSize={ButtonSize.Sm}
        onClick={() => setIsSingleModalOpen(true)}
      >
        {t('steps.files.newSingle')}
      </Button>
      <AddSingleModal
        parentId={parentId}
        isOpen={isSingleModalOpen}
        setIsOpen={setIsSingleModalOpen}
        structureName={name}
        isOwner
      />
    </>
  )
}

AddSingle.propTypes = {
  parentId: string,
  name: string,
}
