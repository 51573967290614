import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { PRICE_TYPE } from '#constants/priceTypes'
import { DistroTypeCard } from '#pages/distro/_components/single-or-album-choose/components/distro-type-card'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '50px',
    textTransform: 'uppercase',
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  singleOrAlbum: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.color.primary}, ${theme.palette.background.default})`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '7rem 1rem',
  },
  singleOrAlbumContent: {
    width: '100%',
    maxWidth: '68rem',
  },
  cardsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  headerWrapper: {
    textAlign: 'center',
    marginBottom: '1.5rem',
  },
}))

export const SingleOrAlbumChoose = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')

  const singleRedirect = () =>
    navigate(`${NAVIGATION_PATHS.DISTRO_CREATE_NEW}?type=single`)
  const albumRedirect = () =>
    navigate(`${NAVIGATION_PATHS.DISTRO_CREATE_NEW}?type=album`)

  return (
    <div className={classes.singleOrAlbum}>
      <div className={classes.singleOrAlbumContent}>
        <div className={classes.headerWrapper}>
          <Text type={TEXT_TYPE.H2} className={classes.header}>
            {distroTranslation.t('areYouSelling')}
          </Text>
        </div>
        <div className={classes.cardsWrapper}>
          <DistroTypeCard
            title={t('single')}
            buttonText={distroTranslation.t('sellYourSingle')}
            price={PRICE_TYPE.DISTRO_SINGLE}
            onClick={singleRedirect}
          />

          <DistroTypeCard
            title={t('album')}
            buttonText={distroTranslation.t('sellYourAlbum')}
            price={PRICE_TYPE.DISTRO_ALBUM}
            onClick={albumRedirect}
          />
        </div>
      </div>
    </div>
  )
}
