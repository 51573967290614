import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 32,
    fontFamily: theme.typography.h2.fontFamily,
    margin: 0,
    width: '100%',
  },
  subTitle: {
    marginBottom: 20,
    display: 'inline-block',
    width: '100%',
  },
}))

export const Heading = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <>
      <span className={classes.title}>{t('privateShare')}</span>
      <span className={classes.subTitle}>{t('sendPrivateShares')}</span>
    </>
  )
}
