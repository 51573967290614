export const STEPS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  FIFTH: 5,
  SIXTH: 6,
  SEVENTH: 7,
}

export const FIELDS = {
  NEW_ARTIST_NAME: 'newArtistName',
  SEARCH_ARTIST_NAME: 'serachArtistName',
  PARENT_ID: 'parentId',
  DISTRO_ID: 'distroId',
  ARTIST_PROFILE_ID: 'artistProfileId',
  ARTIST_ID: 'artistId',
  NEW_ARTIST_PROFILE: 'newArtistProfile',
  EXISTING_SONG_FILE: 'existingSongFile',
  SONG_FILES: 'songFiles',
  SONG_FILE_METADATA_PRIMARY_ARTIST_NAME: 'primaryArtistName',
  SONG_FILE_METADATA_SONG_NAME: 'songName',
  SONG_FILE_METADATA_ISRC: 'isrc',
  SONG_FILE_METADATA_GENRE: 'primaryGenreId',
  SONG_FILE_METADATA_SECONDARY_GENRE: 'secondaryGenreId',
  SONG_FILE_METADATA_PERFORMANCE_LANGUAGE: 'performanceLanguage',
  SONG_FILE_METADATA_TITLE_LANGUAGE: 'titleLanguage',
  SONG_FILE_METADATA_EXPLICIT_CONTENT: 'explicitContent',
  SONG_FILE_METADATA_TRACK_VERSION: 'trackVersion',
  SONG_FILE_METADATA_FEATURED_ARTISTS: 'name',
  SONG_FILE_METADATA_CONTRIBUTORS: 'metadataContributors',
  SONG_FILE_METADATA_LYRICS: 'lyrics',
  SONG_FILE_METADATA_RECORDING_DATE: 'metadataRecordingDate',
  SONG_FILE_METADATA_COPYRIGHT_OWNER: 'metadataCopyrightOwner',
  SONG_FILE_METADATA_COPYRIGHT_YEAR: 'metadataCopyrightYear',
  RELEASE_NAME: 'releaseName',
  RELEASE_VERSION: 'releaseVersion',
  ARTWORK_COPYRIGHT_YEAR: 'artWorkCopyrightYear',
  ARTWORK_COPYRIGHT_OWNER: 'artWorkCopyrightOwner',
  SONG_COPYRIGHT_YEAR: 'songCopyrightYear',
  SONG_COPYRIGHT_OWNER: 'songCopyrightOwner',
  RELEASE_COPYRIGHT_YEAR: 'releaseCopyrightYear',
  RELEASE_COPYRIGHT_OWNER: 'releaseCopyrightOwner',
  SECONDARY_GENRE: 'secondaryGenre',
  GENRE: 'genre',
  RECORDING_YEAR: 'recordingYear',
  RELEASED_BEFORE: 'releasedBefore',
  IS_PREVIOUSLY_RELEASED: 'isPreviouslyReleased',
  PREVIOUSLY_RELEASED_DATE: 'previousReleaseDate',
  ORIGINAL_RELEASE_DATE: 'originalReleaseDate',
  EXPLICIT_CONTENT: 'explicitContent',
  TITLE_LANGUAGE: 'titleLanguage',
  UPC: 'upc',
  LABEL_NAME: 'labelName',
  PRIMARY_ARTIST_NAME: 'primaryArtistName',
  ARTWORK_EXPLICIT: 'artworkExplicit',
  ARTWORK: 'artwork',
  ESTIMATED_RELEASE_DATE: 'deliveryDate',
  MUSIC_STORES: 'musicStores',
  COUNTRIES: 'deliveryRegions',
  RELEASE_FLOW: 'releaseFlow',
  HOLDER: 'holder',
  HAS_VOCALS: 'hasVocals',
  TEXT_MATCHES: 'textMatches',
  NO_OTHER_TEXT: 'noOtherText',
  NO_LOGOS: 'noLogos',
  CLEAR_ARTWORK: 'clearArtwork',
  ARTWORK_FILE: 'artworkFile',
  PROBLEMS: 'problems',
  PROBLEMS_TEMP: 'problemsTemp',
  CREATED_AT: 'createAt',
  SUBSCRIPTION: 'subscription',
}

export const TERRITORY = {
  AFRICA: {
    code: ['AF'],
    name: 'Africa',
  },
  AMERICAS: {
    code: ['NA', 'SA'],
    name: 'Americas',
  },
  ASIA: {
    code: ['AS'],
    name: 'Asia',
  },
  EUROPE: {
    code: ['EU'],
    name: 'Europe',
  },
  OCEANIA: {
    code: ['OC'],
    name: 'Oceania',
  },
}

export const FILE_STATUS = {
  PROCESSING: -1,
  ERROR: -2,
}

export const SUPPORTED_FILE_TYPES = [
  {
    kHz: '192 kHz',
    bit: '24 or 32 bit',
  },
  {
    kHz: '176.4 kHz',
    bit: '24 or 32 bit',
  },
  {
    kHz: '96 kHz',
    bit: '24 or 32 bit',
  },
  {
    kHz: '88.2 kHz',
    bit: '24 or 32 bit',
  },
  {
    kHz: '48 kHz',
    bit: '16, 24 or 32 bit',
  },
  {
    kHz: '44.1 kHz',
    bit: '16, 24 or 32 bit',
  },
]

export const HAS_VOCALS_OPTIONS = [
  { value: 'yes', label: 'YES' },
  { value: 'no', label: 'NO' },
]

export const SONG_METADATA_FIELDS = [
  {
    name: FIELDS.SONG_FILE_METADATA_PRIMARY_ARTIST_NAME,
    label: 'primaryArtistName',
    required: true,
    type: 'input',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_SONG_NAME,
    label: 'songName',
    required: true,
    type: 'input',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_ISRC,
    label: 'isrc',
    required: false,
    type: 'input',
    mask: 'aa-***-99-99999',
    displayUppercase: true,
  },
  {
    name: FIELDS.SONG_FILE_METADATA_GENRE,
    label: 'genre',
    placeholder: 'selectGenre',
    required: true,
    type: 'select',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_SECONDARY_GENRE,
    label: 'secondaryGenre',
    placeholder: 'selectGenre',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_EXPLICIT_CONTENT,
    label: 'explicitContent',
    placeholder: '',
    required: true,
    type: 'select',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_TITLE_LANGUAGE,
    label: 'titleLanguage',
    placeholder: 'selectLanguage',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.SONG_FILE_METADATA_TRACK_VERSION,
    label: 'trackVersion',
    placeholder: 'selectVersion',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.HAS_VOCALS,
    label: 'hasVocals',
    placeholder: '',
    required: false,
    type: 'select',
  },
]

export const PERFORMANCE_TYPE = {
  INSTRUMENTAL: 'Instrumental',
}

export const HEADER_TITLES = ['name', 'role', 'joined']

export const RELEASE_DETAILS_FIELDS = [
  {
    name: FIELDS.RELEASE_NAME,
    label: 'releaseName',
    required: true,
    type: 'input',
  },
  {
    name: FIELDS.RELEASE_VERSION,
    label: 'releaseVersion',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.PRIMARY_ARTIST_NAME,
    label: 'primaryArtist',
    required: false,
    type: 'input',
  },
  {
    name: FIELDS.LABEL_NAME,
    label: 'labelName',
    required: false,
    type: 'input',
  },
  {
    name: FIELDS.GENRE,
    label: 'genre',
    placeholder: 'selectGenre',
    required: true,
    type: 'select',
  },
  {
    name: FIELDS.SECONDARY_GENRE,
    label: 'secondaryGenre',
    placeholder: 'selectGenre',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.EXPLICIT_CONTENT,
    label: 'explicitContent',
    required: true,
    type: 'select',
  },
  {
    name: FIELDS.UPC,
    label: 'upc',
    required: false,
    type: 'input',
  },
  {
    name: FIELDS.RECORDING_YEAR,
    label: 'recordingYear',
    required: false,
    type: 'select',
  },
  {
    name: FIELDS.RELEASE_COPYRIGHT_OWNER,
    label: 'releaseCopyrightOwner',
    required: true,
    type: 'input',
  },
  {
    name: FIELDS.ARTWORK_COPYRIGHT_OWNER,
    label: 'artWorkCopyrightOwner',
    required: true,
    type: 'input',
  },
  {
    name: FIELDS.IS_PREVIOUSLY_RELEASED,
    label: 'previouslyReleased',
    required: true,
    type: 'select',
  },
]

export const RELEASE_DETAILS_CONFIRMATIONS = [
  {
    name: FIELDS.TEXT_MATCHES,
    label: 'theTextOnTheArtworkMatches',
  },
  {
    name: FIELDS.NO_OTHER_TEXT,
    label: 'thereIsNoOtherText',
  },
  {
    name: FIELDS.NO_LOGOS,
    label: 'thereAreNoLogos',
  },
  {
    name: FIELDS.CLEAR_ARTWORK,
    label: 'theArtworkImageIsCrisp',
  },
]

export const CONTENT_TYPE_OPTIONS = [
  {
    label: 'yes',
    value: 'yes',
  },
  {
    label: 'no',
    value: 'no',
  },
]

export const RELEASE_VERSION = [
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'Instrumental',
    value: 'instrumental',
  },
  {
    label: 'Acoustic',
    value: 'acoustic',
  },
  {
    label: 'Acapella',
    value: 'acapella',
  },
  {
    label: 'Remix',
    value: 'remix',
  },
  {
    label: 'Radio Edit',
    value: 'radio-edit',
  },
  {
    label: 'Karaoke',
    value: 'karaoke',
  },
  {
    label: 'Bonus Track Version',
    value: 'bonus-track-version',
  },
  {
    label: 'Expanded',
    value: 'expanded',
  },
  {
    label: 'Deluxe Edition',
    value: 'deluxe-edition',
  },
]

export const ARTIST_ID_OPTION = {
  DEFAULT: 1,
  NEW: 2,
  FROM_PLATFORM: 3,
}

export const RADIO_BUTTON_PROPS = {
  [ARTIST_ID_OPTION.DEFAULT]: {
    type: ARTIST_ID_OPTION.DEFAULT,
    title: 'useThisArtistId',
  },
  [ARTIST_ID_OPTION.NEW]: {
    type: ARTIST_ID_OPTION.NEW,
    title: 'createNewArtistId',
  },
  [ARTIST_ID_OPTION.FROM_PLATFORM]: {
    type: ARTIST_ID_OPTION.FROM_PLATFORM,
    title: 'useAdifferentArtistId',
  },
}

export const ARTIST_ID_PLATFORM = {
  SPOTIFY: 'spotify',
}

export const DISTRO_STATUS = {
  IN_PROGRESS: 'inProgress',
  PENDING: 'pending',
  UNPAID: 'unpaid',
  DENIED: 'denied',
  COMPLETE: 'complete',
}

export const SALES_IMAGES = [
  { light: 'spotify-light', dark: 'spotify' },
  { light: 'apple-light', dark: 'apple' },
  { light: 'amazon-light', dark: 'amazon' },
  { light: 'tiktok-light', dark: 'tiktok' },
  { light: 'pandora', dark: 'pandora' },
  { light: 'youtube-light', dark: 'youtube' },
  { light: 'instagram-light', dark: 'instagram' },
  { light: 'facebook', dark: 'facebook' },
]
