import React from 'react'
import { string, arrayOf, shape, bool } from 'prop-types'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ROLES } from '#constants/roles'
import { CustomSelect } from '#components/inputs/custom-select'

export const RolesSelect = ({
  control,
  errorMessage,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation('vault')
  const selectProps =
    defaultValue !== null && Object.keys(defaultValue).length > 0
      ? { defaultValue: [defaultValue].flat() }
      : null

  return (
    <Controller
      name="role"
      control={control}
      defaultValue={selectProps?.defaultValue}
      render={({ field: { onChange } }) => (
        <CustomSelect
          id="roles_select"
          defaultValue={selectProps?.defaultValue}
          closeMenuOnSelect={false}
          options={ROLES}
          placeholder={t('selectRoles')}
          label={t('roles')}
          errorMessage={errorMessage}
          menuPortalTarget={document.body}
          isMulti
          onChange={onChange}
          {...props}
        />
      )}
    />
  )
}

RolesSelect.propTypes = {
  control: shape({}).isRequired,
  errorMessage: string,
  defaultValue: arrayOf(shape({ label: string, value: string })),
  className: string,
  isBolded: bool,
  menuPosition: bool,
}

RolesSelect.defaultProps = {
  errorMessage: '',
  defaultValue: [],
  isBolded: true,
}
