import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from '#components/section-title'
import { useWizard } from '#modules/forms/form-wizard/context'
import { WaveformPlayer } from '#components/waveform-player'
import { useMasteredFile } from '#hooks/swr/useMasteredFile'

const useStyles = makeStyles(theme => {
  return {
    boldText: {
      fontWeight: 'bold',
    },
    editSettings: {
      color: theme.palette.color.primary,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    waveform: {
      margin: '4rem 0',
    },
    settingsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    successMessage: {
      padding: '1rem',
      textAlign: 'center',
    },
  }
})

const SSectionTitle = styled(SectionTitle)`
  color: ${({ theme: { colors } }) => colors.primary};
`

export const ProcessedView = props => {
  const [t] = useTranslation('mastering')
  const classes = useStyles()

  const { decrementStep, setStoredFormState } = useWizard()

  const onEditSettings = () => {
    decrementStep()
    setStoredFormState({ masteredId: '' })
  }

  const { masteredFileId, ...urlProps } = props

  const { file } = useMasteredFile(masteredFileId)

  const profiles = file?.profile?.map(pro => t(`options.${pro}`)).join(', ')

  return (
    <>
      <div className={classes.successMessage}>
        <SSectionTitle>{t('boom')}</SSectionTitle>
        <p>{t('yourTrackWasMastered')}</p>
      </div>
      <div className={classes.waveform}>
        <WaveformPlayer {...urlProps} />
      </div>
      <div className={classes.settingsWrapper}>
        <span className={classes.boldText}>{t('masteringSettings')}</span>
        <span>{t(`styles.${file?.genre}`)}</span>
        <span>{profiles}</span>
        <a className={classes.editSettings} onClick={onEditSettings}>
          {t('editSettings')}
        </a>
      </div>
    </>
  )
}

ProcessedView.propTypes = {
  masteredTrack: string,
  originalTrack: string,
  masteredFileId: string,
}

ProcessedView.defaultProps = {
  masteredTrack: '',
  originalTrack: '',
  masteredFileId: '',
}
