import { useEffect, useState } from 'react'
import { WalletsApi } from '#api/requests/wallets'
import * as fcl from '@onflow/fcl'
import { useError } from '#hooks/useError'
import { useTranslation } from 'react-i18next'
import { isTransactionRejectedByAuthorizer } from '#pages/connect-wallet/utils/is-transaction-rejected-by-authorizer'

export const SETUP_STATUS = {
  None: 'none',
  AwaitingDapper: 'awaitingDapper',
  InProgress: 'inProgress',
}
export const useRoyaltySetup = (requireSetup, callback) => {
  const [loadingTx, setLoadingTx] = useState(false)
  const [setupInProgress, setSetupInProgress] = useState(false)
  const [setupStatus, setSetupStatus] = useState(SETUP_STATUS.None)

  const [royaltySetupCode, setRoyaltySetupCode] = useState(null)
  const { error, setError } = useError()
  const { t } = useTranslation('connectWallet')

  useEffect(() => {
    if (requireSetup) {
      setLoadingTx(true)
      WalletsApi.getRoyaltySetupTxCode()
        .then(({ data }) => {
          setRoyaltySetupCode(data.code)
        })
        .catch(() => {
          setError(t('fetchSetupError'))
        })
        .finally(() => {
          setLoadingTx(false)
        })
    }
  }, [requireSetup])

  const runSetup = async () => {
    setSetupInProgress(true)
    setSetupStatus(SETUP_STATUS.AwaitingDapper)

    try {
      const txId = await fcl.mutate({
        cadence: royaltySetupCode,
        proposer: fcl.currentUser,
        payer: fcl.currentUser,
        limit: 1000,
      })

      setSetupStatus(SETUP_STATUS.InProgress)

      await fcl.tx(txId).onceSealed()

      await callback()
    } catch (err) {
      if (!isTransactionRejectedByAuthorizer(err)) setError(t('setupError'))
    } finally {
      setSetupInProgress(false)
      setSetupStatus(SETUP_STATUS.None)
    }
  }

  return {
    royaltySetupCode,
    loadingTx,
    setupInProgress,
    runSetup,
    error,
    setupStatus,
  }
}
