import React from 'react'
import { Intro } from '#pages/sell-collectibles/landing-page/_sections/intro'
import { Roadmap } from '#pages/sell-collectibles/landing-page/_sections/roadmap'
import { PageWrapper } from '#modules/page-wrapper'

export const SellCollectiblesLandingPage = () => {
  return (
    <PageWrapper showNavBar isFullWidth>
      <Intro />
      <Roadmap />
    </PageWrapper>
  )
}
