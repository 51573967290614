import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '@tunego/tunego-ui-components'
import { SingleTree } from '#pages/sell-collectibles/create-collectible/_components/steps/files/_components/upload-file/_components/single-tree'

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 2rem;
`

export const SelectSingle = ({ parentId, onUpload }) => {
  const { t } = useTranslation('nftWizard')

  const [singleId, setSingleId] = useState(null)

  const handleUpload = () => {
    onUpload(singleId)
    setSingleId(null)
  }

  return (
    <SWrapper>
      <SingleTree
        parentId={parentId}
        onChange={single => setSingleId(single.value)}
      />

      <Button disabled={!singleId} onClick={handleUpload}>
        {t('steps.files.upload')}
      </Button>
    </SWrapper>
  )
}

SelectSingle.propTypes = {
  parentId: string.isRequired,
  onUpload: func,
}
