import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CONTACT_SUPPORT_HREF } from '#constants/contact'

const SContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.black};
  height: 5rem;
  padding: 0 1.875rem;
`

const SText = styled.span`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  font-size: 1rem;
  margin-right: 1.875rem;
`

const SContactLink = styled.a`
  width: 17.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 0.625rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  color: ${({ theme: { colors } }) => colors.default};
  padding: 0 1.25rem;
  outline: none;
  overflow: hidden;
  font-family: ${({ theme: { typography } }) => typography.bold};
  background-color: ${({ theme: { colors } }) => colors.primary};
  border: none;
  text-decoration: none;
`

export const ContactSupport = () => {
  const { t } = useTranslation('profile')

  return (
    <SContainer>
      <SText>{t('contactSupportForEvenBiggerPlans')}</SText>
      <SContactLink href={CONTACT_SUPPORT_HREF}>
        {t('contactSupport')}
      </SContactLink>
    </SContainer>
  )
}
