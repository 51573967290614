import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { mutate } from 'swr'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, func } from 'prop-types'
import { structureNameValidationSchema } from '#pages/vault/profile-content/utils/validation'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { BaseInput } from '#components/inputs/base-input'
import { ENDPOINTS } from '#api/endpoints'
import { ArtistApi } from '#api/requests/artist'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { useError } from '#hooks/useError'

const useStyles = makeStyles(theme => ({
  duplicateInlineWrapper: {
    height: 148,
    background: theme.palette.color.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  buttonsWrapper: {
    marginLeft: 20,
    display: 'flex',
  },
}))

const SButton = styled(Button)`
  height: 48px;
  width: 45%;
  max-width: 145px;
  margin-right: 12px;
  white-space: nowrap;
  text-transform: uppercase;
`

export const DuplicateRowInline = ({
  name,
  id,
  handleCloseInlineAction,
  parentId,
}) => {
  const { t } = useTranslation()
  const { error, setError, clearError } = useError()
  const vaultTranslation = useTranslation('vault')
  const {
    register,
    handleSubmit,
    errors: formErrors,
  } = useForm({
    resolver: yupResolver(structureNameValidationSchema(vaultTranslation.t)),
  })
  const classes = useStyles()

  const { actions, loading, errors } = useWithAsyncAction({
    duplicateArtistStructure: ArtistApi.duplicateArtistStructure,
  })

  const handleSetError = useCallback(err => {
    setError(err.message)
  }, [])

  useEffect(() => {
    if (errors.duplicateArtistStructure) {
      handleSetError(errors.duplicateArtistStructure)
    }
  }, [errors, handleSetError])

  const defaultInputValue = `${name} - (COPY)`

  const handleDuplicateStructure = async values => {
    const data = {
      ...values,
    }
    await actions.duplicateArtistStructure(id, data)
    await mutate(`${ENDPOINTS.STRUCTURES}?parentId=${parentId}`)
    handleCloseInlineAction()
  }

  if (loading.duplicateArtistStructure || error) {
    return (
      <CommonLoadingOverlay
        message={vaultTranslation.t('structureIsBeingDuplicated')}
        dialogOpen={loading.duplicateArtistStructure}
        error={error}
        onErrorBackdropClick={clearError}
      />
    )
  }

  return (
    <form
      className={classes.duplicateInlineWrapper}
      onSubmit={handleSubmit(handleDuplicateStructure)}
    >
      <BaseInput
        name="name"
        errorMessage={formErrors.name?.message}
        defaultValue={defaultInputValue}
        inputRef={register}
      />
      <div className={classes.buttonsWrapper}>
        <SButton>{vaultTranslation.t('duplicate')}</SButton>
        <SButton
          variant={VARIANTS.TRANSPARENT}
          onClick={() => handleCloseInlineAction()}
        >
          {t('cancel')}
        </SButton>
      </div>
    </form>
  )
}

DuplicateRowInline.propTypes = {
  name: string.isRequired,
  id: string.isRequired,
  parentId: string.isRequired,
  handleCloseInlineAction: func.isRequired,
}
