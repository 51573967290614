import React from 'react'
import { string, node, func, bool } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from '@tunego/tunego-ui-components'

const SButton = styled(Button)`
  align-self: center;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 26.25rem;
`

const SHeader = styled.h2`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['3xl']};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  margin: 0;
`

export const VaultModalContainer = ({
  title,
  text,
  children,
  disabled,
  btnText,
  onSubmit,
}) => {
  const { t } = useTranslation('vault')

  return (
    <SForm onSubmit={onSubmit}>
      <SHeader>{title}</SHeader>
      {text && <p>{text}</p>}
      {children}
      <SButton disabled={disabled} type="submit">
        {btnText || t('createButton')}
      </SButton>
    </SForm>
  )
}

VaultModalContainer.propTypes = {
  title: string,
  text: string,
  children: node,
  onSubmit: func,
  disabled: bool,
  btnText: string,
}
