import React from 'react'
import { number, string, oneOfType } from 'prop-types'
import { PassionRatingGraph } from '../passion-rating-graph'
import { MarketRatingChart } from '../market-rating-chart'
import { ReviewRowElement } from '../reviewer-row-element'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  marketingRatingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const PassionTargetMarketingRating = ({
  passionRating,
  inGenreClass,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.marketingRatingContainer}>
      <ReviewRowElement
        refName="passionTargetMarketingEl"
        title="passionRating"
        description="thePassionRatingIndicatesHow"
      >
        <PassionRatingGraph rating={passionRating} />
      </ReviewRowElement>
      <ReviewRowElement
        title="targetMarketRating"
        description="targetMarketRatingIndicates"
      >
        <MarketRatingChart rating={inGenreClass} />
      </ReviewRowElement>
    </div>
  )
}

PassionTargetMarketingRating.propTypes = {
  passionRating: oneOfType([number, string]).isRequired,
  inGenreClass: oneOfType([number, string]).isRequired,
}
