import React from 'react'
import { string, func } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles(theme => ({
  containerClass: {
    height: '100%',
  },
  inputClass: {
    height: '100% !important',
    border: 'none !important',
    background: `${theme.palette.color.darkGrey1A} !important`,
    color: `${theme.palette.color.default} !important`,
    outline: 'none !important',
  },
  buttonClass: {
    border: 'none !important',
    borderRight: `1px solid ${theme.palette.color.darkGrey33} !important`,
    background: `${theme.palette.color.darkGrey1A} !important`,
    color: `${theme.palette.color.default} !important`,
    outline: 'none !important',
    '& .selected-flag': {
      background: `${theme.palette.color.darkGrey1A} !important`,
    },
    '& .arrow': {
      borderTop: `4px solid ${theme.palette.color.default} !important`,
    },
    '& .arrow.up': {
      borderTop: `4px solid ${theme.palette.color.default} !important`,
      borderBottom: `none !important`,
    },
    '& :hover': {
      color: `${theme.palette.color.default} !important`,
    },
  },
  dropdownClass: {
    border: `1px solid ${theme.palette.color.darkGrey33} !important`,
    background: `${theme.palette.color.darkGrey1A} !important`,
    color: `${theme.palette.color.default} !important`,
    textAlign: 'left !important',
    '& .highlight': {
      background: `${theme.palette.color.darkGrey80} !important`,
      color: `${theme.palette.color.default} !important`,
    },
    '& :hover': {
      background: `${theme.palette.color.darkGrey80} !important`,
      color: `${theme.palette.color.default} !important`,
    },
  },
}))

export const InputPhone = ({ country, placeholder, value, onChange }) => {
  const classes = useStyles()

  return (
    <PhoneInput
      country={country}
      placeholder={placeholder}
      value={value}
      containerClass={classes.containerClass}
      inputClass={classes.inputClass}
      buttonClass={classes.buttonClass}
      dropdownClass={classes.dropdownClass}
      onChange={phone => onChange(phone)}
    />
  )
}

InputPhone.propTypes = {
  country: string.isRequired,
  placeholder: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
}
