import React from 'react'
import { DefaultWelcomeSection } from '#pages/home/default-homepage/sections/default-welcome-section'
import { HomeNavigation } from '#pages/home/_components/home-navigation'
import { FooterSection } from '#pages/home/_sections/footer-section'
import { useLogin } from '#hooks/useLogin'

export const DefaultHomepage = () => {
  const signupRedirect = useLogin(true)

  return (
    <>
      <HomeNavigation onButtonClick={signupRedirect} />
      <DefaultWelcomeSection />
      <FooterSection />
    </>
  )
}
