import React, { useState } from 'react'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'
import { ProfileForm } from '#pages/vault/_modules/profile-form'
import { createProfileValidationSchema } from '#pages/profile/utils/validation'
import { Modal } from '#components/modal'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ArtistApi } from '#api/requests/artist'
import { StructureApi } from '#api/requests/structure'

const SButton = styled(Button)`
  margin-top: 2.25rem;
  flex-shrink: 0;
  flex-grow: 0;
`

const SModal = styled(Modal)`
  padding: 2rem 2.5rem;
  overflow: auto;
  max-height: 100vh;
`

const SErrorMessage = styled.p`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
`

export const CreatePersona = ({ onCreate, isOpen, setIsOpen }) => {
  const { t } = useTranslation('nftWizard')
  const vaultTranslation = useTranslation('vault')

  const [error, setError] = useState('')
  const [hasPreview, setHasPreview] = useState(false)
  const [previewFile, setPreviewFile] = useState('')
  const [isImageUpdated, setIsImageUpdated] = useState(false)

  const { actions, anyLoading } = useWithAsyncAction({
    postArtistProfile: ArtistApi.postArtistProfile,
    patchArtistProfile: ArtistApi.patchArtistProfile,
    manageUploadAvatar: ArtistApi.manageUploadAvatar,
    deleteProfileAvatar: ArtistApi.deleteArtistAvatar,
    getArtistProfile: StructureApi.getSingleStructure,
  })

  const handleClose = () => {
    setIsOpen(false)
    setPreviewFile('')
    setError('')
  }

  const handleCreate = async ({ profileName, bio }) => {
    try {
      const {
        data: { id, name, filesCount },
      } = await actions.postArtistProfile({
        name: profileName,
        bio,
      })

      let avatarUrl

      if (previewFile) {
        await actions.manageUploadAvatar(id, previewFile)

        const { data } = await actions.getArtistProfile(id)

        // eslint-disable-next-line prefer-destructuring
        avatarUrl = data.avatarUrl
      }

      onCreate({
        label: name,
        value: id,
        avatarUrl,
        filesCount,
      })

      handleClose()
    } catch (err) {
      const message = t(
        err?.response?.status === 409
          ? 'steps.files.personaAlreadyExists'
          : 'steps.files.personaGeneralError'
      )
      setError(message)
    }
  }

  const handlePreview = file => {
    setPreviewFile(file)
    setHasPreview(true)
    setIsImageUpdated(true)
  }

  const handleDeleteFile = () => {
    setPreviewFile('')
    setHasPreview(false)
    setIsImageUpdated(false)
  }

  return (
    <>
      <SButton
        buttonTheme={ButtonTheme.Secondary}
        onClick={() => setIsOpen(true)}
      >
        {t('steps.files.createPersona')}
      </SButton>
      <SModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeBtn
        onClose={handleClose}
      >
        <ProfileForm
          title={vaultTranslation.t('createNewProfile')}
          subTitle={vaultTranslation.t('enterArtist')}
          submitButtonText={t('save')}
          validationSchema={createProfileValidationSchema(vaultTranslation.t)}
          loadingMessage={vaultTranslation.t('savingNewPersona')}
          handleCancel={handleClose}
          {...{
            handlePreview,
            handleCreate,
            setError,
            hasPreview,
            previewFile,
            isSending: anyLoading,
            error,
            isImageUpdated,
            handleDeleteFile,
          }}
          modal
        />
        {error && <SErrorMessage>{error}</SErrorMessage>}
      </SModal>
    </>
  )
}

CreatePersona.propTypes = {
  onCreate: func,
  isOpen: bool,
  setIsOpen: func,
}
