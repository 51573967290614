import React from 'react'
import { arrayOf, shape, string, objectOf, func, bool } from 'prop-types'
import { MIN_ROW_HEIGHT } from '#pages/vault/song/tabs/files/utils/constants'
import { ChevronIcon } from '#pages/vault/song/tabs/files/components/chervron-icon'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const SHeader = styled.div`
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 1rem;
  min-height: ${MIN_ROW_HEIGHT}px;
  display: flex;
`

const SHeaderItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SSortingChevron = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5625rem;
  height: 1.5625rem;
  cursor: pointer;
  margin-left: 0.3125rem;

  ${({
    active,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    active &&
    css`
      border-bottom: 1px solid ${COLORS.neutral_0};
    `}
`

const SChevronIcon = styled(ChevronIcon)`
  ${({ ascending }) =>
    ascending &&
    css`
      transform: rotate(180deg);
      border-bottom: none;
    `}
`

export const TableHeader = ({
  headerItems,
  setShouldSortAscending,
  sortBy,
  setSortBy,
  setPageIndex,
  shouldSortAscending,
  isUploadInProgress,
}) => {
  const { t } = useTranslation('vault')

  return (
    <SHeader>
      {headerItems.map(({ name, width, padding, sortBy: headerSortBy }) => (
        <SHeaderItem key={name} style={{ width, padding }}>
          {t(name)}
          <SSortingChevron active={sortBy === headerSortBy}>
            <SChevronIcon
              width={22}
              height={22}
              isActive={sortBy === headerSortBy}
              ascending={shouldSortAscending && sortBy === headerSortBy}
              onClick={() => {
                if (!isUploadInProgress) {
                  setPageIndex(0)
                  setSortBy(headerSortBy)
                  setShouldSortAscending(prevState => !prevState)
                }
              }}
            />
          </SSortingChevron>
        </SHeaderItem>
      ))}
    </SHeader>
  )
}

TableHeader.propTypes = {
  headerItems: arrayOf(
    shape({
      name: string,
      width: objectOf(string),
    })
  ).isRequired,
  setShouldSortAscending: func.isRequired,
  sortBy: string.isRequired,
  setSortBy: func.isRequired,
  setPageIndex: func.isRequired,
  shouldSortAscending: bool,
  isUploadInProgress: bool.isRequired,
}

TableHeader.defaultProps = {
  shouldSortAscending: false,
}
