import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import {
  useStripe,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useStripeFormValidation } from '#hooks/useStripeFormValidation'
import { CompletePurchaseButton } from '#modules/payments/components/complete-purchase-button'
import { usePaymentMethods } from '#hooks/swr/usePayments'
import { RadioCheckbox } from '#components/radio-checkbox'
import {
  DIFFERENT_PAYMENT_METHOD,
  FORM_FIELDS,
} from '#pages/subscriptions/_utils/constants'
import { BaseLoader } from '#components/loaders/base-loader'
import { PaymentMethods } from '#pages/subscriptions/_components/payment-methods'
import { StripeForm } from '#pages/subscriptions/_components/stripe-form'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import styled from 'styled-components'

const SBaseLoader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const useStyles = makeStyles(() => ({
  stripeContainer: {
    maxWidth: 330,
    width: '100%',
    marginBottom: 100,
  },
  customLabel: {
    fontSize: 14,
    marginLeft: 5,
  },
  stripeForm: {
    marginLeft: 30,
  },
}))

export const PaymentForm = ({ handlePurchaseSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const [methodId, setMethodId] = useState()
  const [defaultMethodToSave, setDefaultMethodToSave] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')
  const { paymentMethods, isLoading } = usePaymentMethods()
  const { areFieldsValid } = useStripeFormValidation(elements)

  const isDifferentPaymentMethod = methodId === DIFFERENT_PAYMENT_METHOD
  const isNameOnCardValid = nameOnCard.trim().length
  const validateForm = isDifferentPaymentMethod
    ? areFieldsValid && isNameOnCardValid
    : methodId
  const isFormValid = paymentMethods?.length > 0 ? validateForm : areFieldsValid

  useEffect(() => {
    if (paymentMethods) {
      const defaultMethod = paymentMethods.find(method => method.isDefault)
      if (defaultMethod) {
        setMethodId(defaultMethod.id)
      }
    }
  }, [paymentMethods])

  const onCompletePurchaseClickHandler = () => {
    const method =
      paymentMethods?.length > 0 ? methodId : DIFFERENT_PAYMENT_METHOD
    const cardElement = elements.getElement(CardNumberElement)

    return handlePurchaseSubmit({
      methodId: method,
      stripe,
      cardElement,
      defaultMethodToSave,
      nameOnCard,
    })
  }

  if (isLoading) {
    return <SBaseLoader text="" isLoading />
  }

  const renderStripeForm = () => {
    return (
      <StripeForm
        {...{
          elements,
          nameOnCard,
          setNameOnCard,
          defaultMethodToSave,
          setDefaultMethodToSave,
        }}
      />
    )
  }

  return (
    <div className={classes.stripeContainer}>
      {isArrayEmpty(paymentMethods) ? (
        renderStripeForm()
      ) : (
        <>
          <PaymentMethods {...{ paymentMethods, methodId, setMethodId }} />
          <RadioCheckbox
            name={FORM_FIELDS.PAYMENT_METHODS_CHECKBOX_NAME}
            id={FORM_FIELDS.DIFFERENT_PAYMENT_METHOD}
            checked={isDifferentPaymentMethod}
            label={t('payments.useADifferentMethod')}
            isLabelBolded
            onChange={e => setMethodId(e.target.id)}
          />
          {isDifferentPaymentMethod && (
            <div className={classes.stripeForm}>{renderStripeForm()}</div>
          )}
        </>
      )}
      <CompletePurchaseButton
        isFormValid={isFormValid}
        handlePurchaseSubmit={onCompletePurchaseClickHandler}
      />
    </div>
  )
}

PaymentForm.propTypes = {
  handlePurchaseSubmit: func.isRequired,
}
