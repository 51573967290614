import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, objectOf, string } from 'prop-types'
import { HorizontalBarGraph } from '#pages/promotion/report/components/horizontal-bar-graph'
import { ReviewRowElement } from '../reviewer-row-element'

const useStyles = makeStyles({
  horizontalGraphContainer: {
    width: '100%',
    minHeight: '250px',
    minWidth: '300px',
    margin: '0 auto',
  },
})

export const MarketPotential = ({ data }) => {
  const classes = useStyles()
  return (
    <ReviewRowElement
      refName="marketPotentialEl"
      title="marketPotential"
      description="marketPotentialIsTheMost"
    >
      <div className={classes.horizontalGraphContainer}>
        <HorizontalBarGraph
          data={data}
          xUnit="%"
          xDomain={[0, 90]}
          showBarLabels
        />
      </div>
    </ReviewRowElement>
  )
}

MarketPotential.propTypes = {
  data: arrayOf(objectOf(string)).isRequired,
}
