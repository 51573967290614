import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { string } from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { BolderTranslation } from '#components/bolder-translation'
import { SalesImages } from '../../../_components/sales-images'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Text } from '#components/text'

const useStyles = makeStyles(theme => ({
  wrapper: {
    color: theme.palette.color.black,
    backgroundColor: theme.palette.color.default,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5rem 1rem',
  },
  header: {
    fontSize: '50px',
    textTransform: 'uppercase',
  },
  description: {
    whiteSpace: 'pre-line',
    marginBottom: '3.5rem',
    maxWidth: '70rem',
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
  },
  title: {
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
}))

const SButton = styled(Button)`
  text-transform: uppercase;
`

export const StoresSection = () => {
  const { t } = useTranslation('distro')
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Text className={classes.header}>{t('howToSell')}</Text>
      </div>
      <div className={classes.description}>
        <Trans
          t={t}
          i18nKey="itsSimpleWithDistro100"
          components={[<BolderTranslation key={0} />]}
        />
      </div>
      <SalesImages />
      <div>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.DISTRO_GET_STARTED)}>
          {t('getStarted')}
        </SButton>
      </div>
    </div>
  )
}

StoresSection.propTypes = {
  getStartedUrl: string.isRequired,
}
