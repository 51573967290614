import React from 'react'
import styled from 'styled-components'
import { bool, func, string } from 'prop-types'
import ClearIcon from '@material-ui/icons/Clear'
import { Modal } from '#components/modal'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    top: 100,
    right: 50,
    cursor: 'pointer',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 1140,
    height: 810,
  },
  image: {
    objectFit: 'contain',
    maxWidth: 1140,
  },
}))

const SModal = styled(Modal)`
  justify-content: center;
  border: none;
  background-color: unset;
  max-width: 1140px;
`

export const DigitalArtModal = ({ src, isOpened, onClose }) => {
  const classes = useStyles()

  return (
    <SModal isOpen={isOpened} setIsOpen={() => {}}>
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={src} alt="" />
      </div>
      <span className={classes.closeIcon}>
        <ClearIcon onClick={onClose} />
      </span>
    </SModal>
  )
}

DigitalArtModal.propTypes = {
  src: string.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
}
