import React from 'react'
import { bool, string } from 'prop-types'
import styled, { css } from 'styled-components'
import { TunegoIcon, CheckIcon } from '#icons'
const SStep = styled.li`
  width: 100%;
  height: 100%;
`

const getWrapperStyle = (
  theme,
  { isActive, isCompleted, isUnlocked, isDisabled }
) => {
  switch (true) {
    case isDisabled:
      return css`
        color: ${theme.tunego.COLORS.neutral_70};
        justify-content: flex-end;
      `
    case isActive:
      return css`
        font-family: ${theme.tunego.FONT.FAMILY.bold};
        color: ${theme.tunego.COLORS.neutral_0};
        justify-content: space-between;
      `
    case isCompleted:
      return css`
        color: ${theme.tunego.COLORS.neutral_0};
        justify-content: space-between;
      `
    case isUnlocked:
      return css`
        color: ${theme.tunego.COLORS.neutral_0};
        justify-content: space-between;
        opacity: 0.6;
      `
    default:
      return css`
        color: ${theme.tunego.COLORS.neutral_70};
        justify-content: flex-end;
      `
  }
}

const SWrapper = styled.button`
  border: none;
  width: 100%;
  height: 100%;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  transition: all 0.3s linear;

  ${({ theme, isCompleted, isActive, isUnlocked, isDisabled }) =>
    getWrapperStyle(theme, { isActive, isCompleted, isUnlocked, isDisabled })}
`

const STunegoIcon = styled(TunegoIcon)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
`

const SOpaqueTunegoIcon = styled(TunegoIcon)`
  opacity: 0.3;
`

const SSpan = styled.span`
  font-weight: inherit;
  font-family: inherit;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`
export const Step = ({
  label,
  isActive,
  isCompleted,
  isUnlocked,
  isDisabled,
}) => {
  const getIcon = () => {
    if (isActive) return <STunegoIcon />
    if (isCompleted) return <CheckIcon />
    if (isUnlocked)
      return <SOpaqueTunegoIcon src="assets/svg/ic-tunego-blue.svg" />

    return null
  }
  return (
    <SStep>
      <SWrapper
        isActive={isActive}
        isCompleted={isCompleted}
        isUnlocked={isUnlocked}
        isDisabled={isDisabled}
      >
        {getIcon()}
        <SSpan>{label}</SSpan>
      </SWrapper>
    </SStep>
  )
}

Step.propTypes = {
  isActive: bool,
  label: string.isRequired,
  isCompleted: bool,
  isUnlocked: bool,
  isDisabled: bool,
}
