import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ACCEPTED_MUSIC_FORMATS,
  MAX_MUSIC_FILE_SIZE,
  MUSIC_BIT_RATE,
} from '#pages/sell-collectibles/create-collectible/_constants/form'

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const STitle = styled.p`
  opacity: 0.33;
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  margin-top: 0;
  font-family: ${({ theme: { typography } }) => typography.bold};
`

const SText = styled.p`
  opacity: 0.33;
  line-height: 1.5;
  margin: 0;
`

const SBold = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin-right: 0.2rem;
`
export const MusicRequirements = () => {
  const { t } = useTranslation('nftWizard')
  return (
    <SContainer>
      <STitle>{t('steps.files.audioRequirements')}</STitle>

      <SText>
        <Trans
          t={t}
          i18nKey="steps.files.bitRate"
          values={{ bitRate: MUSIC_BIT_RATE }}
        >
          <SBold />
        </Trans>
      </SText>
      <SText>
        <SBold>
          {ACCEPTED_MUSIC_FORMATS.map(format => format.toUpperCase()).join(
            ', '
          )}
        </SBold>
        -<SBold>{MAX_MUSIC_FILE_SIZE}</SBold>
        {t('steps.files.audioMaxFileSize')}
      </SText>
    </SContainer>
  )
}
