import React from 'react'
import { node, bool, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  row: {
    minHeight: 60,
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  isLighter: {
    background: theme.palette.color.darkGrey0B,
  },
  transparent: {
    opacity: 0.2,
  },
}))

export const Row = ({ children, isLighter, hasTransparency, alignItems }) => {
  const classes = useStyles()

  return (
    <div
      className={cn({
        [classes.row]: true,
        [classes.isLighter]: isLighter,
        [classes.transparent]: hasTransparency,
      })}
      style={{ alignItems }}
    >
      {children}
    </div>
  )
}

Row.propTypes = {
  isLighter: bool,
  children: node.isRequired,
  hasTransparency: bool,
  alignItems: string,
}

Row.defaultProps = {
  hasTransparency: false,
  isLighter: false,
  alignItems: 'center',
}
