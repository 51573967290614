import React from 'react'
import { Element } from 'react-scroll'
import { makeStyles } from '@material-ui/core/styles'
import { number, string, oneOfType, shape } from 'prop-types'
import { TrackRatingCard } from '#pages/promotion/report/components/track-rating-card'
import { PlayIcon } from '#pages/_modules/private-shares/components/play-icon'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { PromotionApi } from '#api/requests/promotion'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: 48,
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  playBtn: {
    cursor: 'pointer',
    marginRight: 20,
  },
  songTitle: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 'bold',
    color: theme.palette.color.primary,
    fontFamily: theme.typography.h2.fontFamily,
  },
}))

export const TrackRating = ({ promotionId, songName, file, trackRating }) => {
  const classes = useStyles()
  const { actions } = useWithAsyncAction({
    getListenLink: PromotionApi.getListenLink,
  })
  const { setFileInfo } = useGlobalAudioPlayer()

  const handlePlay = async promoId => {
    const {
      data: { link },
    } = await actions.getListenLink(promoId)
    setFileInfo({
      promotionId: promoId,
      profileId: file?.profile?.id,
      folderId: file?.folder?.id,
      projectId: file?.project?.id,
      suffix: file?.fileSuffix,
      uri: link,
      fileName: songName,
      projectName: `${file?.profile?.name} · ${songName}`,
    })
  }

  return (
    <div>
      <Element name="trackRatingEl">
        <div className={classes.wrapper}>
          <div className={classes.headerWrapper}>
            <div
              className={classes.playBtn}
              onClick={() => {
                handlePlay(promotionId)
              }}
            >
              <PlayIcon />
            </div>
            <span className={classes.songTitle}>{songName}</span>
          </div>
          <TrackRatingCard rating={trackRating} />
        </div>
      </Element>
    </div>
  )
}

TrackRating.propTypes = {
  promotionId: string.isRequired,
  songName: string.isRequired,
  file: shape({}).isRequired,
  trackRating: oneOfType([number, string]).isRequired,
}
