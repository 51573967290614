import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'
import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { hexToRgba } from '#utils/hexToRgba'
import { NAVIGATION_PATHS } from '#routes/routes'
import { CONTACT_SUPPORT_HREF } from '#constants/contact'

const useStyles = makeStyles(theme => ({
  footerSec: {
    background:
      theme.palette.type === 'light'
        ? theme.palette.color.default
        : theme.palette.color.black,
    borderTop:
      theme.palette.type === 'light'
        ? hexToRgba(theme.palette.color.black, 0.08)
        : hexToRgba(theme.palette.color.default, 0.2),
    '& footer': {
      width: '100%',
    },
    '& a': {
      color: theme.palette.color.default,
      textDecoration: 'none',
    },
  },
  footer: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    paddingLeft: '24px',
    bottom: 0,
    height: '65px',
    width: '100%',
    borderTop:
      theme.palette.type === 'light'
        ? `1px solid ${hexToRgba(theme.palette.color.black, 0.08)}`
        : `2px solid ${theme.palette.color.primary}`,
    background:
      theme.palette.type === 'light'
        ? theme.palette.color.default
        : theme.palette.color.black,
    zIndex: 2,
    '& ul': {
      listStyle: 'none',
      background: theme.palette.color.black,
      padding: '0',
      margin: '0',
      '& li': {
        float: 'left',
        fontSize: '13px',
        fontWeight: '500',
        letterSpacing: '0.04px',
        color:
          theme.palette.type === 'light'
            ? theme.palette.color.black
            : theme.palette.color.secondary7B,
        '& a': {
          color: theme.palette.color.secondary7B,
          textDecoration: 'underscore',
        },
        '& span': {
          width: '1px',
          height: '17px',
          border: `solid 1px ${theme.palette.color.secondary7B}`,
          marginLeft: '16px',
          marginRight: '16px',
          color:
            theme.palette.type === 'light'
              ? theme.palette.color.default
              : theme.palette.color.black,
        },
      },
      '& li:last-of-type': {
        '& span': {
          display: 'none',
        },
      },
    },
  },
  mobileFooterText: {
    display: 'flex',
    padding: '10px',
    alignItems: 'left',
    justifyContent: 'left',
  },
  contactSupport: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export const PageFooter = () => {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Hidden smDown>
        <ul>
          <li>
            {t('footer.copyright', { year })}
            <span />
          </li>
          <li>
            <Link to={NAVIGATION_PATHS.TERMS}>
              {t('footer.termsAndConditions')}
            </Link>
            <span />
          </li>
          <li>
            <Link to={NAVIGATION_PATHS.PRIVACY_POLICY}>
              {t('footer.privacyPolicy')}
            </Link>
            <span />
          </li>
          <li>
            <a className={classes.contactSupport} href={CONTACT_SUPPORT_HREF}>
              {t('footer.contactSupport')}
              <span />
            </a>
          </li>
        </ul>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileFooterText}>
          &copy;
          {t('footer.allRightsReserved', { year })}
        </div>
      </Hidden>
    </footer>
  )
}
