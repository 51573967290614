import React from 'react'
import styled from 'styled-components'
import { MainHeader } from '#pages/_components/section-headers/main-header'
import { SecondaryHeader } from '#pages/_components/section-headers/secondary-header'
import { useTranslation } from 'react-i18next'
import { CreateCollectibleButton } from '#pages/_components/create-collectible-button'
import { VideoBackground } from '#pages/_components/video-background'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Button, ButtonSize } from '@tunego/tunego-ui-components'
import { navigate } from '@reach/router'
import { useNfts } from '#hooks/swr/useNfts'

const SWrapper = styled.div`
  height: calc(100vh - 3.7rem);
`

const SContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const SButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const SText = styled.p`
  margin: 0 0 2rem;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
`

export const Intro = () => {
  const { t } = useTranslation('sellCollectibles')

  const { count, isLoading } = useNfts()

  const showMyCollectiblesButton = !isLoading && count > 0

  return (
    <SWrapper>
      <VideoBackground src="assets/mp4/clubdancing.mp4" opacity={0.6}>
        <SContentWrapper>
          <MainHeader>{t('sellYourCollectibles')}</MainHeader>
          <SecondaryHeader>{t('sellDigitalMusicCollectibles')}</SecondaryHeader>
          <SText>{t('advantages')}</SText>
          <SButtonWrapper>
            <CreateCollectibleButton />
            {showMyCollectiblesButton && (
              <Button
                buttonSize={ButtonSize.Md}
                onClick={() => navigate(NAVIGATION_PATHS.MY_COLLECTIBLES)}
              >
                {t('viewMyCollectibles')}
              </Button>
            )}
          </SButtonWrapper>
        </SContentWrapper>
      </VideoBackground>
    </SWrapper>
  )
}
