import React, { useState, useEffect } from 'react'
import { bool, func, string, shape, oneOfType, elementType } from 'prop-types'
import Axios from 'axios'
import { hexToRgba } from '#utils/hexToRgba'
import { Text, TEXT_TYPE } from '#components/text'
import styled from 'styled-components'

const SLocationInputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`

const SLocationInput = styled.input`
  width: 18.375rem;
  height: 2.5rem;
  border: ${({ theme: { colors }, error }) =>
    `1px solid  ${error ? colors.error : hexToRgba(colors.default, 0.33)}`};
  background-color: ${({ theme: { colors } }) => colors.darkGrey1A};
  padding-left: ${({ search }) => (search === '' ? '2.5rem' : '1rem')};
  padding-right: 0.95rem;
  color: ${({ theme: { colors } }) => colors.default};
  margin-right: 2.5rem;
  font-family: ${({ theme: { typography } }) => typography.fontFamily};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};

  outline: none;
  
  &:-webkit-autofill {
    transitionDelay: 9999s;
    transitionProperty: background-color, color,
  },
`

const SLocationInputLabel = styled.label`
  margin-bottom: 0.625rem;
  font-family: ${({ theme: { typography }, isBolded }) =>
    isBolded ? typography.bold : typography.fontFamily};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

const SLocationBox = styled.div`
  width: 18.375rem;
  border: ${({ theme: { colors } }) =>
    `1px solid ${hexToRgba(colors.default, 0.33)}`};
  background-color: ${({ theme: { colors } }) => colors.darkGrey1A};
  padding: 0.75rem 1rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  color: ${({ theme: { colors } }) => colors.default};
  margin-right: 2.5rem;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  outline: none;

  &:hover {
  background-color: ${({ theme: { colors } }) => colors.darkGrey33};
  cursor: pointer
},
`

const SSearchIcon = styled.div`
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  display: ${({ search }) => (search === '' ? 'block' : 'none')};
  
  img {
    width: 1.25rem;
    margin-right: 1rem;
  },
`

const SErrorText = styled(Text)`
  padding-top: 0.25rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
`

const SInputFieldContainer = styled.div`
  position: relative;
`

const SDropdownContainer = styled.div`
  position: absolute;
  top: 4.3rem;
  z-index: 2;
`

export const LocationSearch = props => {
  const {
    label,
    placeholder,
    error,
    setValue,
    name,
    isBolded,
    defaultValue,
    noDefault,
    inputRef,
    errorMessage,
    clearErrors,
    ...restInputProps
  } = props
  const [value, setInnerValue] = useState()
  const [locations, setLocations] = useState([])
  const [search, setSearch] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)

  const processValue = val => {
    const newValue = val.split(' ')
    let requestParam = ' '
    for (let i = 0; i < newValue.length; i++) {
      if (i === newValue.length - 1) {
        requestParam += newValue[i]
      } else {
        requestParam += `${newValue[i]}%20`
      }
    }
    return requestParam
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (search) {
        const processed = processValue(search)
        const res = await Axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${processed}.json?types=country,region,postcode,district,place,locality,neighborhood&access_token=${process.env.REACT_APP_MAPBOX_ID}`
        )
        setLocations(res.data.features)
      } else {
        setValue(name, null)
        setInnerValue(null)
      }
    }, 250)
    return () => clearTimeout(timer)
  }, [search])

  const selectLocation = index => {
    let countryCode = ''
    if (locations[index].context) {
      const newValue = locations[index].context
      for (const value of newValue) {
        const startsWithCountry = value.id.startsWith('country')
        if (startsWithCountry) {
          countryCode = value.short_code
        }
      }
    } else {
      countryCode = locations[index].properties.short_code
    }
    const locValue = {
      cityName: locations[index].text,
      placeName: locations[index].place_name,
      countryCode,
      lng: `${locations[index].center[0]}`,
      lat: `${locations[index].center[1]}`,
    }
    setValue(name, locValue, { shouldDirty: true })
    setShowDropdown(false)
    setInnerValue(locValue.placeName)
    if (noDefault) {
      clearErrors('location')
    }
  }

  const handle = val => {
    setSearch(val)
    setInnerValue(val)
    setShowDropdown(!!val)
  }
  const defaultPlaceName = defaultValue?.placeName
  return (
    <SLocationInputContainer>
      <SLocationInputLabel isBolded={isBolded} htmlFor={label}>
        {label}
      </SLocationInputLabel>
      <SInputFieldContainer>
        <SLocationInput
          {...{ error, search }}
          type="text"
          name="location.placeName"
          {...inputRef('location.placeName', {
            onChange: e => handle(e.target.value),
          })}
          data-testid="locationSearch"
          {...(!!value && { value })}
          placeholder={placeholder}
          autoComplete="off"
          {...(!noDefault && { defaultValue: defaultPlaceName })}
          {...restInputProps}
        />
        <SSearchIcon {...{ search }}>
          <img src="/assets/svg/searchIcon.svg" alt="" />
        </SSearchIcon>
        {!!error && (
          <SErrorText type={TEXT_TYPE.BODY2} color="error">
            {errorMessage}
          </SErrorText>
        )}
      </SInputFieldContainer>
      {showDropdown && (
        <SDropdownContainer>
          {locations.map((location, index) => (
            <SLocationBox
              key={location.place_name}
              onClick={() => {
                selectLocation(index)
              }}
            >
              {location.place_name}
            </SLocationBox>
          ))}
        </SDropdownContainer>
      )}
    </SLocationInputContainer>
  )
}

LocationSearch.propTypes = {
  label: string.isRequired,
  placeholder: string.isRequired,
  error: bool.isRequired,
  setValue: func.isRequired,
  name: string.isRequired,
  isBolded: bool,
  defaultValue: shape({
    placeName: string,
  }),
  inputRef: oneOfType([func, shape({ current: elementType })]),
  errorMessage: string,
  noDefault: bool,
  clearErrors: func,
}

LocationSearch.defaultProps = {
  isBolded: false,
  inputRef: null,
  errorMessage: '',
  defaultValue: shape({
    placeName: '',
  }),
  noDefault: false,
  clearErrors: null,
}
