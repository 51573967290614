import React from 'react'
import { PageWrapper } from '#modules/page-wrapper'
import { CollectibleListContainer } from '#pages/sell-collectibles/my-collectibles/_containers/collectible-list-container'

export const MyCollectibles = () => {
  return (
    <PageWrapper showNavBar showFooter>
      <CollectibleListContainer />
    </PageWrapper>
  )
}
