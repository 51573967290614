import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ACCEPTED_MUSIC_FORMATS,
  MAX_MUSIC_FILE_SIZE,
  MUSIC_BIT_RATE,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { bool, string } from 'prop-types'
import { Requirements } from '#pages/sell-collectibles/create-collectible/_components/requirements'

const SText = styled.p`
  line-height: 1.5;
  margin: 0;
`

const SBold = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin-right: 0.2rem;
`

export const MusicRequirements = props => {
  const { t } = useTranslation('nftWizard')

  return (
    <Requirements title={t('steps.files.audioRequirements')} {...props}>
      <>
        <SText>
          <Trans
            t={t}
            i18nKey="steps.files.bitRate"
            values={{ bitRate: MUSIC_BIT_RATE }}
          >
            <SBold />
          </Trans>
        </SText>
        <SText>
          <SBold>
            {ACCEPTED_MUSIC_FORMATS.map(format => format.toUpperCase()).join(
              ', '
            )}
          </SBold>
          - <SBold>{MAX_MUSIC_FILE_SIZE}</SBold>
          {t('steps.files.audioMaxFileSize')}
        </SText>
      </>
    </Requirements>
  )
}

MusicRequirements.propTypes = {
  showFooterNote: bool,
  className: string,
  primary: bool,
}

MusicRequirements.defaultProps = {
  showFooterNote: true,
  primary: false,
}
