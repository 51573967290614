import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { number, shape, func } from 'prop-types'
import { ArtistIdList } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-list'
import { SearchArtistIdsForm } from '#pages/distro/wizard/steps/select-artist-id/components/search-artist-ids-form'
import { ARTIST_ID_PLATFORM } from '#pages/distro/_utils/constants'

const useStyles = makeStyles(() => ({
  message: {
    fontSize: 16,
    fontWeight: 500,
    width: '100%',
    minWidth: 1000,
    paddingTop: 30,
  },
  wrapper: {
    minWidth: '31rem',
  },
}))

export const ArtistIdSearcher = ({
  setSelectedArtistIds,
  artistId,
  option,
  setOptionFn,
}) => {
  const getDefaultState = () => {
    if (option === 3 && artistId?.value) {
      return artistId.value.filter(
        ({ type }) => type === ARTIST_ID_PLATFORM.SPOTIFY
      )
    }
    return null
  }
  const [ids, setIds] = useState(getDefaultState())
  const showList = !!ids
  const { t } = useTranslation('distro')
  const classes = useStyles()

  const onFocus = setOptionFn(3)

  return (
    <div>
      <div className={classes.wrapper}>
        <SearchArtistIdsForm {...{ setIds, option, onFocus }} />
        {showList && <ArtistIdList {...{ ids, setSelectedArtistIds }} />}
      </div>
      {showList && (
        <span className={classes.message}>
          {t('ifYouDontSeeYourArtistPage')}
        </span>
      )}
    </div>
  )
}

ArtistIdSearcher.propTypes = {
  setOptionFn: func.isRequired,
  setSelectedArtistIds: func.isRequired,
  option: number.isRequired,
  artistId: shape({}).isRequired,
}
