import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { MasteringInstructions } from '#pages/mastering/wizard/steps/add-track-step/components/mastering-instructions'
import { StepWrapperContainer } from '#pages/mastering/wizard/containers/step-wrapper-container'
import { useWizard } from '#modules/forms/form-wizard/context'
import { SelectedFileToast } from '#components/selected-file-toast'
import { ProfileFilesTree } from '#modules/files-tree/profile-files-tree'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { STRUCTURE_TYPE } from '#constants/structureTypes'

const useStyles = makeStyles(() => ({
  fileTree: {
    padding: '3rem 2rem',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}))

export const AddTrack = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { setStoredFormState, storedFormState } = useWizard()
  const { selectedFileName, selectedFileId, parentId } = storedFormState

  const handleFileSelect = ({ id, name }) => {
    setStoredFormState({ selectedFileId: id, selectedFileName: name })
    navigate(`${NAVIGATION_PATHS.NEW_MASTERING}?trackId=${id}`)
  }

  const onBackButtonClick = () => {
    navigate(NAVIGATION_PATHS.NEW_MASTERING)
  }

  return (
    <>
      {!!selectedFileName && (
        <SelectedFileToast selectedFileName={selectedFileName} />
      )}
      <StepWrapperContainer
        shouldDisableNextButton={!selectedFileName}
        onBackButtonClick={onBackButtonClick}
      >
        <div className={classes.container}>
          <MasteringInstructions />
          <div className={classes.fileTree}>
            <ProfileFilesTree
              title={t('chooseFromExistingFiles')}
              showFilesCondition={SHOW_FILES_CONDITION.MASTERING}
              handleFileSelect={handleFileSelect}
              selectedFileId={selectedFileId}
              parentId={parentId}
              height={1}
              width={1}
              structureCategory={STRUCTURE_TYPE.PROFILE}
            />
          </div>
        </div>
      </StepWrapperContainer>
    </>
  )
}
