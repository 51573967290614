import React, { useState, useCallback, useEffect } from 'react'
import { arrayOf, shape, string, number, func, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import Dropzone from 'react-dropzone'
import { PrivateShareModalContainer } from '#pages/vault/song/tabs/files/containers/private-share-modal-container'
import { ArtistApi } from '#api/requests/artist'
import { FilesApi } from '#api/requests/files'
import { SharesApi } from '#api/requests/shares'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { OverwriteFilesModal } from '#pages/vault/song/tabs/files/components/modals/overwrite-files-modal'
import { ButtonRow } from '#components/button-row'
import { EmptyProject } from '#pages/vault/profile-content/components/empty-project'
import { useUploadManager } from '#modules/upload-manager/context'
import { useGlobalAudioPlayer } from '#modules/global-audio-player-provider/context'
import { useError } from '#hooks/useError'
import { CheckboxActionButtons } from '#pages/vault/song/tabs/files/components/checkbox-action-buttons'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import { FilesList } from '#pages/vault/song/tabs/files/components/files-list'
import {
  EXCLUDE_VAULT_EXTENSIONS,
  SIDES,
} from '#pages/vault/song/tabs/files/utils/constants'
import styled, { css } from 'styled-components'
import { FileChecks } from '#pages/vault/song/tabs/files/components/file-checks'
import { useReload } from '#modules/page-wrapper/context'
import { getFileInfo } from '#utils/getFileInfo'

const SDropZone = styled.div`
  outline: none;
  min-height: calc(100vh - 190px);

  ${({
    theme: {
      tunego: { COLORS },
    },
    isDragActive,
  }) =>
    isDragActive &&
    css`
      box-shadow: 0 0 20px 0 ${COLORS.primary_50};
      border: 2px dashed ${COLORS.primary_50};
    `}
`

export const FilesListContainer = ({
  accessRoles,
  files,
  count,
  masterId,
  pageIndex,
  setPageIndex,
  rowsPerPage,
  setRowsPerPage,
  setShouldSortAscending,
  sortBy,
  setSortBy,
  profileId,
  folderId,
  projectId,
  setShouldRefreshData,
  shouldSortAscending,
}) => {
  const vaultTranslation = useTranslation('vault')
  const player = useGlobalAudioPlayer()
  const { error, setError, clearError } = useError()
  const [privateShareFileId, setPrivateShareFileId] = useState(null)
  const [modalFileName, setModalFileName] = useState('')
  const [checkedFiles, setCheckedFiles] = useState([])
  const [side, setSide] = useState(SIDES.contacts)
  const isExistingRecipient = side === SIDES.contacts

  const {
    onDrop,
    uploadExistingFiles,
    alreadyExistingFiles,
    clearAlreadyExistingFiles,
    isOverwriteFilesModalOpen,
    setIsOverwriteFilesModalOpen,
    fileUploadError,
    clearFileUploadError,
    validationError,
    clearValidationError,
  } = useUploadManager()

  const uploadError = error || validationError || fileUploadError

  const { actions, loading, anyError } = useWithAsyncAction({
    setMasterFile: ArtistApi.setMasterFile,
    getFileDownloadLink: FilesApi.getFileDownloadLink,
    getListenLink: FilesApi.getListenLink,
    addExistingUserShare: SharesApi.addExistingUserShare,
    inviteOffPlatformUserShare: SharesApi.inviteOffPlatformUserShare,
    deleteFile: FilesApi.deleteFile,
  })

  const handleSetError = useCallback(err => setError(err.message), [])

  const isLoading =
    loading.setMasterFile ||
    loading.addExistingUserShare ||
    loading.inviteOffPlatformUserShare

  useEffect(() => {
    if (anyError) {
      handleSetError(anyError)
      setModalFileName('')
    }
  }, [anyError, handleSetError])

  const handlePrivateShare = id => {
    const { name } = files.find(file => file.id === id)
    setPrivateShareFileId(id)
    setModalFileName(name)
  }

  const setFileInfo = (fileId, link) => {
    const file = files.find(singleFile => singleFile.id === fileId)
    player.setFileInfo({
      fileId,
      profileId,
      folderId,
      projectId,
      uri: link,
      fileName: file.name,
      projectName: `${file?.profile?.name} · ${file?.project?.name}`,
      suffix: file.fileSuffix,
    })
  }

  const handleListen = async fileId => {
    const {
      data: { link },
    } = await actions.getListenLink(fileId)
    setFileInfo(fileId, link)
  }

  const handlePreview = async fileId => {
    const {
      data: { link },
    } = await actions.getFileDownloadLink(fileId)
    setFileInfo(fileId, link)
  }

  const { setReloading } = useReload()

  const uploadManagerProps = {
    projectId,
    triggerMutate: setReloading,
  }

  const onDropHandler = filesToUpload => {
    onDrop({
      filesToUpload,
      ...uploadManagerProps,
    })
  }

  const clearErrors = () => {
    clearError()
    clearValidationError()
    clearFileUploadError()
  }

  const onOverwriteFilesModalCloseHandler = () => {
    setIsOverwriteFilesModalOpen(false)
    clearAlreadyExistingFiles()
  }

  const validateFile = file => {
    const { fileSuffix, name } = getFileInfo(file)

    if (EXCLUDE_VAULT_EXTENSIONS.includes(fileSuffix)) {
      return {
        message: vaultTranslation.t('fileTypeNotSupported', {
          fileSuffix,
          name,
        }),
      }
    }

    return null
  }

  const onDropRejectedHandler = files => {
    let errorMessage
    if (files.length > 1) {
      const errors = files
        .map(
          file =>
            file.errors[0]?.message || vaultTranslation.t('dropGeneralError')
        )
        .join('\n')

      errorMessage = vaultTranslation.t('multipleDropError', { errors })
    } else {
      const [file] = files
      errorMessage =
        file?.errors[0]?.message || vaultTranslation.t('dropGeneralError')
    }

    setError(errorMessage)
  }

  return (
    <>
      <Dropzone
        validator={validateFile}
        multiple
        onDropAccepted={onDropHandler}
        onDropRejected={onDropRejectedHandler}
      >
        {({ getRootProps, getInputProps, isDragActive, open }) => {
          return (
            <SDropZone
              {...getRootProps({
                onClick: event => {
                  event.stopPropagation()
                },
              })}
              isDragActive={isDragActive}
            >
              <input {...getInputProps()} />
              <PrivateShareModalContainer
                name={modalFileName}
                onClose={() => setModalFileName('')}
                {...{
                  actions,
                  files,
                  projectId,
                  privateShareFileId,
                  setPrivateShareFileId,
                  setModalFileName,
                  isExistingRecipient,
                  setSide,
                }}
              />
              <ButtonRow
                text={`+ ${vaultTranslation.t('addFile')}`}
                onClick={open}
              >
                <CheckboxActionButtons
                  {...{
                    checkedFiles,
                    setCheckedFiles,
                    accessRoles,
                  }}
                />
              </ButtonRow>
              <FileChecks
                id={projectId}
                profileId={profileId}
                accessRoles={accessRoles}
                files={files}
              />
              {isArrayEmpty(files) ? (
                <EmptyProject onClick={open} />
              ) : (
                <FilesList
                  {...{
                    files,
                    setShouldSortAscending,
                    sortBy,
                    setSortBy,
                    setPageIndex,
                    shouldSortAscending,
                    projectId,
                    masterId,
                    actions,
                    accessRoles,
                    handleListen,
                    handlePreview,
                    handlePrivateShare,
                    setShouldRefreshData,
                    checkedFiles,
                    setCheckedFiles,
                    count,
                    pageIndex,
                    rowsPerPage,
                    setRowsPerPage,
                  }}
                />
              )}
            </SDropZone>
          )
        }}
      </Dropzone>
      <OverwriteFilesModal
        files={alreadyExistingFiles}
        open={isOverwriteFilesModalOpen}
        handleConfirm={() => uploadExistingFiles({ ...uploadManagerProps })}
        handleClose={onOverwriteFilesModalCloseHandler}
      />
      <CommonLoadingOverlay
        message={
          loading.setMasterFile
            ? vaultTranslation.t('masterFilesIsBeingSet')
            : vaultTranslation.t('privateShareIsBeingSent')
        }
        dialogOpen={isLoading}
        error={uploadError}
        onErrorBackdropClick={clearErrors}
      />
    </>
  )
}

FilesListContainer.propTypes = {
  accessRoles: arrayOf(string).isRequired,
  files: arrayOf(
    shape({
      name: string,
    })
  ).isRequired,
  masterId: string.isRequired,
  count: number.isRequired,
  pageIndex: number.isRequired,
  setPageIndex: func.isRequired,
  rowsPerPage: number.isRequired,
  setRowsPerPage: func.isRequired,
  setShouldSortAscending: func.isRequired,
  sortBy: string.isRequired,
  setSortBy: func.isRequired,
  projectId: string.isRequired,
  folderId: string.isRequired,
  profileId: string.isRequired,
  setShouldRefreshData: func.isRequired,
  shouldSortAscending: bool.isRequired,
  parentName: string.isRequired,
}
