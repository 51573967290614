import React, { useEffect } from 'react'
import { Drawer, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { UploaderItem } from './components/uploader-item'
import { useUploadManager } from './context/index'
import { UploaderHeader } from '#modules/upload-manager/components/uploader-header'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '530px',
    right: '100px',
    left: 'auto',
    border: `1px solid ${theme.palette.color.primary}`,
    borderBottom: 'none',
    backgroundColor: theme.palette.color.black,
  },
  drawerClose: {
    transition: theme.transitions.create('max-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: 'hidden',
    maxHeight: 57,
  },
  drawerOpen: {
    overflowY: 'hidden',
    maxHeight: 321,
    transition: theme.transitions.create('max-height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
  },
  list: {
    maxHeight: 265,
    overflowY: 'scroll',
  },
}))

export const UploadManager = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')
  const location = useLocation()
  const isVault = location.pathname.includes(NAVIGATION_PATHS.VAULT_PROFILES)

  const {
    isOpen,
    isMinimized,
    minimizeManager,
    maximizeManager,
    uploadingFilesInProgress,
    isUploading,
    openManager,
    closeManager,
    cancelUpload,
  } = useUploadManager()

  const handleVisibilityStates = () => {
    if (isUploading) {
      maximizeManager()
      openManager()
    } else {
      closeManager()
    }

    if (!isVault) {
      minimizeManager()
    }
  }

  useEffect(() => {
    handleVisibilityStates()
  }, [isUploading])

  if (!isOpen) {
    return null
  }

  return (
    <Drawer
      anchor="bottom"
      variant="permanent"
      classes={{
        paper: `${classes.drawer} ${
          isMinimized ? classes.drawerClose : classes.drawerOpen
        }`,
      }}
    >
      <UploaderHeader />
      {uploadingFilesInProgress.length <= 0 ? (
        <div className={classes.emptyList}>
          <Typography>{t('noFilesUploading')}</Typography>
        </div>
      ) : (
        <div className={classes.list}>
          {uploadingFilesInProgress.map(file => (
            <UploaderItem key={file.id} file={file} onCancel={cancelUpload} />
          ))}
        </div>
      )}
    </Drawer>
  )
}
