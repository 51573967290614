import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class PaymentsApi {
  static addNewCard = paymentMethodId =>
    getApiClient().post(ENDPOINTS.PAYMENT_METHODS, { paymentMethodId })

  static setPaymentMethodAsDefault = paymentMethodId =>
    getApiClient().get(
      buildUrl(ENDPOINTS.PAYMENT_METHODS_SET_AS_DEFAULT, { paymentMethodId })
    )

  static deletePaymentMethod = paymentMethodId =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.PAYMENT_METHODS_DELETE, { paymentMethodId })
    )
}
