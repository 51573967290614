import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { number, string, func, shape } from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useWizard } from '#modules/forms/form-wizard/context'
import {
  FIELDS,
  HAS_VOCALS_OPTIONS,
  PERFORMANCE_TYPE,
} from '#pages/distro/_utils/constants'
import { AddSongMetadataWrapper } from '#pages/distro/wizard/steps/add-song-files/components/add-song-metadata-wrapper'
import {
  prepareDataForMetadataForm,
  formatFormValues,
} from '#pages/distro/_utils/helpers'
import { metadataValidationSchema } from '#pages/distro/_utils/validation'
import { TrackDetailsForm } from '#pages/distro/wizard/steps/add-song-files/components/track-details-form'
import { useArtistGenres } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ArtistApi } from '#api/requests/artist'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useError } from '#hooks/useError'
import styled from 'styled-components'

const SBaseLoader = styled(BaseLoader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const TrackDetailsStep = ({
  songId,
  profileName,
  metadataStep,
  setMetadataStep,
  setPersistedData,
}) => {
  const { t } = useTranslation('distro')
  const getValuesRef = useRef(null)
  const { setStoredFormState, storedFormState } = useWizard()

  const selectedSong = storedFormState[FIELDS.SONG_FILES].find(
    item => item.id === songId || item.isMetadataFormOpened
  )

  const formattedValues = useMemo(
    () =>
      prepareDataForMetadataForm({
        ...selectedSong,
        [FIELDS.SONG_FILE_METADATA_PRIMARY_ARTIST_NAME]:
          selectedSong?.primaryArtistName || null,
        [FIELDS.SONG_FILE_METADATA_SONG_NAME]: selectedSong?.songName || null,
      }),
    [selectedSong]
  )

  useEffect(
    () => () => {
      if (getValuesRef.current) {
        const formValues = getValuesRef.current()

        setPersistedData(formValues)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const {
    handleSubmit,
    getValues,
    register,
    control,
    formState: { errors: formErrors },
    watch,
  } = useForm({
    resolver: yupResolver(
      metadataValidationSchema(t, profileName, selectedSong?.name)
    ),
    defaultValues: {
      ...formattedValues,
      // ...persistedData,
    },
  })

  useEffect(() => {
    getValuesRef.current = getValues
  })

  const { genres, isLoading } = useArtistGenres()
  const genresOptions =
    genres && genres.map(({ name, id }) => ({ label: name, value: id }))

  const hasSongVocals =
    watch(FIELDS.HAS_VOCALS)?.value === HAS_VOCALS_OPTIONS[0].value
  const hasLyrics = watch(FIELDS.SONG_FILE_METADATA_LYRICS)

  const { actions, loading } = useWithAsyncAction({
    patchArtistSong: ArtistApi.patchArtistSong,
  })
  const { error, setResponseError, clearError } = useError()
  const showLoader = isLoading || loading.patchArtistSong

  const saveCurrentForm = async () => {
    try {
      const formValues = getValuesRef.current()
      const values = formatFormValues(formValues)
      const {
        explicitContent,
        isrc,
        lyrics,
        performanceLanguage,
        primaryArtistName,
        primaryGenreId,
        secondaryGenreId,
        songName,
        titleLanguage,
        trackVersion,
      } = values

      const valuesToSend = {
        explicitContent,
        isrc,
        lyrics,
        performanceLanguage,
        primaryArtistName,
        primaryGenreId,
        secondaryGenreId,
        songName,
        titleLanguage,
        trackVersion,
        ...(!hasSongVocals && {
          performanceLanguage: PERFORMANCE_TYPE.INSTRUMENTAL,
          lyrics: '',
        }),
      }

      const response = await actions.patchArtistSong(
        selectedSong?.id,
        valuesToSend
      )
      setPersistedData(response?.data)

      setStoredFormState({
        [FIELDS.SONG_FILES]: storedFormState[FIELDS.SONG_FILES].map(file =>
          file.id === selectedSong?.id ? { ...file, ...response?.data } : file
        ),
      })

      setMetadataStep(metadataStep + 1)
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <AddSongMetadataWrapper
      {...{ metadataStep, setMetadataStep, hasSongVocals }}
      onNextButtonClick={handleSubmit(saveCurrentForm)}
    >
      {showLoader ? (
        <SBaseLoader isLoading={showLoader} />
      ) : (
        <TrackDetailsForm
          {...{ register, control, formErrors, hasLyrics }}
          hasVocals={hasSongVocals}
          genres={genresOptions}
          songName={selectedSong?.name}
          defaultValues={formattedValues}
          profileName={profileName}
        />
      )}
      <CommonLoadingOverlay error={error} onErrorBackdropClick={clearError} />
    </AddSongMetadataWrapper>
  )
}

TrackDetailsStep.propTypes = {
  songId: string.isRequired,
  profileName: string.isRequired,
  metadataStep: number.isRequired,
  setMetadataStep: func.isRequired,
  persistedData: shape({}).isRequired,
  setPersistedData: func.isRequired,
}
