import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'

export class SubscriptionsApi {
  static postUpgradeSubscription = body =>
    getApiClient().post(ENDPOINTS.SUBSCRIPTIONS_UPGRADE, body)

  static postPreviewUpgradeSubscription = body =>
    getApiClient().post(ENDPOINTS.SUBSCRIPTIONS_PREVIEW_UPGRADE, body)
}
