import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  ARTWORK_DIMENSIONS,
  ARTWORK_RATIO,
  MAX_ANIMATED_ARTWORK_SIZE,
  MAX_STATIC_ARTWORK_SIZE,
} from '#pages/sell-collectibles/create-collectible/_constants/form'

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const STitle = styled.p`
  opacity: 0.33;
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  margin-top: 0;
  font-family: ${({ theme: { typography } }) => typography.bold};
`

const SText = styled.p`
  opacity: 0.33;
  line-height: 1.5;
  margin: 0;
`

const SBold = styled.span`
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin-right: 0.2rem;
`

export const ArtworkRequirements = () => {
  const { t } = useTranslation('nftWizard')
  return (
    <SContainer>
      <STitle>{t('steps.files.artworkRequirements')}</STitle>

      <SText>
        <Trans
          i18nKey="steps.files.artworkDimensions"
          ns="nftWizard"
          values={{ ratio: ARTWORK_RATIO, dimensions: ARTWORK_DIMENSIONS }}
        >
          <SBold />
        </Trans>
      </SText>
      <SText>
        <Trans
          i18nKey="steps.files.artworkStaticFiles"
          ns="nftWizard"
          values={{ size: MAX_STATIC_ARTWORK_SIZE }}
        >
          <SBold />
        </Trans>
      </SText>
      <SText>
        <Trans
          i18nKey="steps.files.artworkAnimatedFiles"
          ns="nftWizard"
          values={{ size: MAX_ANIMATED_ARTWORK_SIZE }}
        >
          <SBold />
        </Trans>
      </SText>
    </SContainer>
  )
}
