import React from 'react'
import { navigate } from '@reach/router'
import { string } from 'prop-types'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled from 'styled-components'

const SCreateProfileRedirect = styled.div`
  margin-top: 20px;
`

const SSpan = styled.span`
  font-size: 14px;
  margin-bottom: 0;
`

const SLink = styled.a`
  color: ${({ theme: { colors } }) => colors.primary};
  text-decoration: underline;
  cursor: pointer;
`

export const CreateProfileRedirect = ({ text, linkText, linkTextMore }) => {
  return (
    <SCreateProfileRedirect>
      {text ? <SSpan>{text} </SSpan> : null}
      <SSpan>
        <SLink onClick={() => navigate(NAVIGATION_PATHS.CREATE_PROFILE)}>
          {linkText}
        </SLink>
        {linkTextMore}
      </SSpan>
    </SCreateProfileRedirect>
  )
}

CreateProfileRedirect.propTypes = {
  linkText: string.isRequired,
  linkTextMore: string.isRequired,
  text: string,
}

CreateProfileRedirect.defaultProps = {
  text: '',
}
