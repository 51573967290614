import React from 'react'
import styled from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '#components/date-picker'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SDatePickerWrapper = styled.div`
  width: 25rem;
  position: relative;
  margin-top: 2rem;
`

const SIcon = styled.img`
  position: absolute;
  top: 2.5rem;
  right: 0.625rem;
`

export const DateInput = () => {
  const { t } = useTranslation('nftWizard')

  const { control, register } = useFormContext()

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name: NFT_DETAILS_STEP_FIELDS.RELEASE_DATE,
    control,
  })

  const { minReleaseDate, readOnly } = useNftCreator()

  return (
    <SDatePickerWrapper>
      <DatePicker
        name={NFT_DETAILS_STEP_FIELDS.RELEASE_DATE}
        control={control}
        register={register}
        minDate={minReleaseDate}
        value={value}
        label={t('steps.details.releaseDate')}
        dateFormat="MM/dd/yyyy h:mmaa"
        errorMessage={!readOnly && error?.message}
        disabled={readOnly}
        required
        showTimeSelect
        onChange={onChange}
      />

      <SIcon src="assets/svg/ic-calendar.svg" alt="Calendar icon" />
    </SDatePickerWrapper>
  )
}
