import React from 'react'
import styled, { css } from 'styled-components'
import { node, string, oneOfType } from 'prop-types'
import { VARIANTS } from '#components/button/constants'
import { hexToRgba } from '#utils/hexToRgba'

const SButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.default};
  padding: 0 20px;
  outline: none;
  overflow: hidden;
  font-family: ${({ theme: { typography } }) => typography.bold};

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    `
      &:disabled {
        opacity: 0.5;
        background: ${({ theme: { colors } }) => colors.primary};
        color: ${({ theme: { colors } }) => colors.default};
        cursor: default;
        border: 1px solid ${({ theme: { colors } }) => colors.primary};
      }
  `}

  ${({ variant }) => {
    switch (variant) {
      case VARIANTS.WARNING:
        return css`
          background-color: ${({ theme: { colors } }) => colors.warning};
          border: none;
        `
      case VARIANTS.CANCEL:
        return css`
          background-color: ${({ theme: { colors } }) => colors.darkGrey22};
          border: 1px solid ${({ theme: { colors } }) => colors.default};
          opacity: 0.3;
        `
      case VARIANTS.CANCEL_BLACK:
        return css`
          color: ${({ theme: { colors } }) => colors.primary};
          background-color: ${({ theme: { colors } }) => colors.black};
          border: 1px solid ${({ theme: { colors } }) => colors.primary};
        `
      case VARIANTS.CANCEL_TEXT:
        return css`
          color: ${({ theme: { colors } }) => colors.primary};
          text-decoration: underline;
          background-color: transparent;
          border: none;
        `
      case VARIANTS.TRANSPARENT:
        return css`
          background-color: transparent;
          border: 1px solid ${({ theme: { colors } }) => colors.default};
          opacity: 0.33;
        `
      case VARIANTS.DARK_GREY:
        return css`
          background-color: ${hexToRgba('#222', 0.3)};
          border: 1px solid ${hexToRgba('#fff', 0.3)}
        };
        `
      case VARIANTS.NEW_BLUE_SPECIAL:
        return css`
          margin-right: 20px;
          background-color: ${({ theme: { colors } }) => colors.darkBlue03};
          border: 1px solid ${({ theme: { colors } }) => colors.primary};
          color: ${({ theme: { colors } }) => colors.primary};
        `
      default:
        return css`
          background-color: ${({ theme: { colors } }) => colors.primary};
          border: none;
        `
    }
  }}
`

export const Button = ({ children, variant, className, type, ...rest }) => {
  return (
    <SButton className={className} type={type} variant={variant} {...rest}>
      {children}
    </SButton>
  )
}

Button.propTypes = {
  children: node.isRequired,
  className: string,
  variant: oneOfType([VARIANTS.WARNING, VARIANTS.CANCEL]),
  type: string,
}

Button.defaultProps = {
  variant: '',
  className: '',
  type: 'button',
}
