import React, { useEffect } from 'react'
import { arrayOf, func, string, bool, number } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { MainTreeRow } from '#modules/choose-file-tree/components/main-three-row'
import { useArtistStructureContent } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { isEven } from '#utils/isEven'
import styled from 'styled-components'
import { useReload } from '#modules/page-wrapper/context'

const SEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30rem;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.primary_50};
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_15%']};
`

const SMessage = styled.p`
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 700;
  opacity: 0.5;
  text-align: center;
  width: 21.25rem;
`

export const FilesTree = ({
  handleFileSelect,
  selectedFileId,
  selectedFilesIds,
  showFilesCondition,
  parentId,
  showFileType,
  roles,
  storedFilesIds,
  disabled,
  reloadCount,
  disableSelect,
  disabledTooltipText,
  showProcessing,
}) => {
  const { t } = useTranslation('vault')
  // eslint-disable-next-line no-shadow
  const buildRolesQueryPart = roles =>
    roles.reduce((query, role) => `${query}&roles=${role}`, '')
  const query = `&all=true${buildRolesQueryPart(roles)}`

  const { artistContent, isLoading, mutate } = useArtistStructureContent(
    parentId,
    query
  )

  const { shouldReload, reload } = useReload()

  useEffect(() => {
    if (shouldReload) reload(mutate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload])

  useEffect(() => {
    if (reloadCount > 0) mutate()
  }, [reloadCount])

  if (!artistContent?.data.length && !isLoading) {
    return (
      <SEmptyWrapper>
        <SMessage className="message">{t('youHaventAddedAnyFiles')}</SMessage>
      </SEmptyWrapper>
    )
  }

  return (
    <>
      {artistContent?.data?.map?.(({ id, category, name }, index) => (
        <MainTreeRow
          key={id}
          isEven={isEven(index)}
          id={id}
          category={category}
          name={name}
          handleFileSelect={handleFileSelect}
          selectedFileId={selectedFileId}
          selectedFilesIds={selectedFilesIds}
          showFilesCondition={showFilesCondition}
          showFileType={showFileType}
          isMainRowLast={index + 1 === artistContent?.count}
          storedFilesIds={storedFilesIds}
          disabled={disabled}
          disableSelect={disableSelect}
          disabledTooltipText={disabledTooltipText}
          reloadCount={reloadCount}
          showProcessing={showProcessing}
        />
      ))}
      <BaseLoader isLoading={isLoading} />
    </>
  )
}

FilesTree.propTypes = {
  handleFileSelect: func.isRequired,
  selectedFileId: string,
  selectedFilesIds: arrayOf(string),
  parentId: string.isRequired,
  showFilesCondition: string,
  showFileType: string,
  roles: arrayOf(ACCESS_ROLES),
  storedFilesIds: arrayOf(string),
  disabled: bool,
  reloadCount: number,
  disableSelect: func,
  disabledTooltipText: string,
  showProcessing: bool,
}

FilesTree.defaultProps = {
  showFilesCondition: '',
  showFileType: '',
  selectedFileId: '',
  selectedFilesIds: null,
  roles: [],
  storedFilesIds: [],
  reloadCount: 0,
  disableSelect: () => false,
}
