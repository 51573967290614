import React from 'react'
import { Modal } from '#components/modal'
import { bool, func } from 'prop-types'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '#components/button'
import { Checkbox } from '#components/checkbox'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  RIGHTS_VERIFICATION_FIELDS,
  rightsVerificationSchema,
} from '#pages/sell-collectibles/create-collectible/_constants/form'
import { NAVIGATION_PATHS } from '#routes/routes'

const SModal = styled(Modal)`
  border-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  width: 48.875rem;
  padding: 2.5rem;
  align-items: flex-start;
`

const SHeading = styled.h2`
  font-size: 2.5rem;
  text-transform: uppercase;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  margin: 0;
  padding: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
`

const SContinueButton = styled(Button)`
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.orange_50};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  background-color: transparent;
  text-transform: uppercase;
  width: 13.75rem;
`

const SCancelButton = styled(Button)`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.orange_50};
  text-decoration: underline;
  background-color: transparent;
`

const SCheckbox = styled(Checkbox)`
  align-items: flex-start;
`

export const SLabel = styled.label`
  margin-left: 0.625rem;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  cursor: pointer;
`

const SAnchor = styled.a`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  text-decoration: underline;
  margin-left: 0.2rem;
`
export const RightsVerification = ({
  onContinue,
  setIsOpen,
  ...modalProps
}) => {
  const { t } = useTranslation('nftWizard')
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rightsVerificationSchema),
  })

  return (
    <SModal setIsOpen={setIsOpen} {...modalProps}>
      <SHeading>{t('steps.review.pleaseVerify')}</SHeading>

      <form onSubmit={handleSubmit(onContinue)}>
        <SCheckbox
          name={RIGHTS_VERIFICATION_FIELDS.MUSIC_RIGHTS}
          register={register}
          checked={false}
          required={true}
          hasError={!!errors[RIGHTS_VERIFICATION_FIELDS.MUSIC_RIGHTS]}
          label={t('steps.review.musicCreatorStatement')}
        />
        <SCheckbox
          name={RIGHTS_VERIFICATION_FIELDS.ARTWORK_RIGHTS}
          register={register}
          checked={false}
          required={true}
          hasError={!!errors[RIGHTS_VERIFICATION_FIELDS.ARTWORK_RIGHTS]}
          label={t('steps.review.artworkCreatorStatement')}
        />
        <SCheckbox
          name={RIGHTS_VERIFICATION_FIELDS.TERMS_AND_CONDITIONS}
          register={register}
          checked={false}
          required={true}
          hasError={!!errors[RIGHTS_VERIFICATION_FIELDS.TERMS_AND_CONDITIONS]}
          customLabel={
            <SLabel htmlFor={RIGHTS_VERIFICATION_FIELDS.TERMS_AND_CONDITIONS}>
              <Trans t={t} i18nKey="steps.review.termsAndConditions">
                <SAnchor href={NAVIGATION_PATHS.TERMS} target="_blank" />
              </Trans>
            </SLabel>
          }
        />
        <SButtonWrapper>
          <SContinueButton type="submit">
            {t('steps.review.continue')}
          </SContinueButton>
          <SCancelButton onClick={() => setIsOpen(false)}>
            {t('steps.review.cancel')}
          </SCancelButton>
        </SButtonWrapper>
      </form>
    </SModal>
  )
}

RightsVerification.propTypes = {
  isOpen: bool,
  setIsOpen: func,
  onContinue: func,
}
