import {
  REVIEW_LEVEL,
  SONG_REPORT_NAVIGATION,
} from '#pages/promotion/_utils/constants'

export const generateSideBarMenuFilteredLinks = tier => {
  switch (tier) {
    case REVIEW_LEVEL.GOLD:
      return [
        SONG_REPORT_NAVIGATION.trackRatingCard,
        SONG_REPORT_NAVIGATION.wordCloud,
        SONG_REPORT_NAVIGATION.reviewerComments,
      ]
    case REVIEW_LEVEL.PLATINUM:
      return [
        SONG_REPORT_NAVIGATION.trackRatingCard,
        SONG_REPORT_NAVIGATION.passionTargetMarketing,
        SONG_REPORT_NAVIGATION.distributionOfRatings,
        SONG_REPORT_NAVIGATION.marketPotential,
        SONG_REPORT_NAVIGATION.genreTrackRating,
        SONG_REPORT_NAVIGATION.wordCloud,
        SONG_REPORT_NAVIGATION.elementAnalysis,
        SONG_REPORT_NAVIGATION.reviewerComments,
      ]
    case REVIEW_LEVEL.DIAMOND:
      return [
        SONG_REPORT_NAVIGATION.trackRatingCard,
        SONG_REPORT_NAVIGATION.passionTargetMarketing,
        SONG_REPORT_NAVIGATION.distributionOfRatings,
        SONG_REPORT_NAVIGATION.marketPotential,
        SONG_REPORT_NAVIGATION.marketPotentialInGenre,
        SONG_REPORT_NAVIGATION.majorLabelTrackRating,
        SONG_REPORT_NAVIGATION.genreTrackRating,
        SONG_REPORT_NAVIGATION.wordCloud,
        SONG_REPORT_NAVIGATION.elementAnalysis,
        SONG_REPORT_NAVIGATION.similarArtistsMentioned,
        SONG_REPORT_NAVIGATION.reviewerDemographics,
        SONG_REPORT_NAVIGATION.reviewerComments,
      ]
    default:
      return []
  }
}

export const generateColorRange = data => {
  const range = []
  const spaceBetweenNumbers = 0.8 / Object.keys(data).length
  for (let i = 0; i < Object.keys(data).length; i++) {
    if (i === 0) {
      range.push('rgba(0, 162, 255, 1)')
    } else {
      const offset = i * spaceBetweenNumbers
      range.push(`rgba(0, 162, 255, ${Math.round((1 - offset) * 100) / 100})`)
    }
  }
  return range
}
