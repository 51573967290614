import React from 'react'
import { node, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  columnInRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
  },
})

export const ColumnInRow = ({ children, className, width }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.columnInRow} ${className}`} style={{ width }}>
      {children}
    </div>
  )
}

ColumnInRow.propTypes = {
  children: node.isRequired,
  className: string,
}

ColumnInRow.defaultProps = {
  className: '',
}
