import React from 'react'
import { ConfirmationModal } from '#components/confirmation-modal'
import { useWizard } from '#modules/forms/form-wizard/context'
import { PRICE_TYPE } from '#constants/priceTypes'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { func, bool } from 'prop-types'
import { useUser } from '#hooks/useUser'
import { Text } from '#components/text'

const useStyles = makeStyles(theme => ({
  warningMessage: {
    margin: '30px',
  },
  warningHeader: {
    textTransform: 'uppercase',
    color: theme.palette.color.warning,
  },
}))

export const PriceChangeWarningModal = ({ isOpen, onClose }) => {
  const { formattedPriceOf } = useUser()
  const { incrementStep } = useWizard()
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const classes = useStyles()
  const albumPrice = formattedPriceOf(PRICE_TYPE.DISTRO_ALBUM)

  return (
    <ConfirmationModal
      isOpened={isOpen}
      minHeight="auto"
      confirmationText={t('accept')}
      cancelText={t('cancel')}
      isWarning
      isOwner
      isCancelButtonOutlined
      onClose={onClose}
      onConfirm={incrementStep}
    >
      <Text className={classes.warningHeader}>{t('warning')}</Text>
      <span className={classes.warningMessage}>
        {distroTranslation.t('addingAtrackWill')}
        <b>{albumPrice}</b>
      </span>
    </ConfirmationModal>
  )
}

PriceChangeWarningModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}
