import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'
import { useSettings } from '#hooks/swr/useSettings'
import { maintenanceSwitch } from '../../globals/helpers/maintenance'
import { useSystem } from '#hooks/useSystem'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    paddingBottom: 60,
    backgroundImage: `url("/assets/images/maintenance1.png")`,
    backgroundColor: '#0B0F12',
    backgroundPositionY: '250px',
    backgroundPositionX: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '650px',
    height: '400px',
    position: 'relative',
    top: '250px',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '500px',
    background:
      'linear-gradient(180deg, rgba(11,15,18,1) 50%, rgba(11,15,18,0) 100%)',
  },
  icon: {
    width: '210px',
    height: '37px',
    zIndex: 2,
  },
  text: {
    fontSize: 24,
    paddingTop: 48,
  },
  message: {
    fontFamily: '"AvenirNextLTPro-Medium", "Arial", sans-serif',
    textAlign: 'center',
    paddingTop: 32,
  },
}))

export const Maintenance = () => {
  const { t } = useTranslation()
  const { maintenanceMode } = useSystem()
  let messageInitial = t('sorryForTheInconvenience')
  const savedData = localStorage.getItem('maintenance')

  if (savedData) {
    try {
      const parsed = JSON.parse(savedData)
      const combined = parsed?.message ?? parsed?.details
      if (combined) {
        messageInitial = combined
      }
    } catch (err) {
      //
    }
  }
  const [message, setMessage] = useState(messageInitial)
  const { settings, isError, isLoading } = useSettings({
    refreshInterval: 5_000,
    refreshWhenHidden: true,
    refreshWhenOffline: true,
  })
  const classes = useStyles()

  useEffect(() => {
    if (
      !isError &&
      !isLoading &&
      settings &&
      settings?.mode !== 'maintenance' &&
      maintenanceMode?.enabled === false
    ) {
      maintenanceSwitch(false, true)
    } else if (
      maintenanceMode?.enabled === true &&
      maintenanceMode.message !== message
    ) {
      setMessage(maintenanceMode.message ?? t('sorryForTheInconvenience'))
    } else if (settings?.details && settings.details !== message) {
      setMessage(settings.details)
    }
  }, [settings, message, isError, isLoading, maintenanceMode, t])

  return (
    <div className={classes.wrapper}>
      <div className={classes.gradient} />
      <div className={classes.inner}>
        <img
          src="/assets/svg/tune-go-logo.svg"
          className={classes.icon}
          alt=""
        />
        <div className={classes.text}>{t('theWebsiteIsInMaintenance')}</div>
        <div className={classes.message}>
          {message}&nbsp;
          <Trans
            t={t}
            i18nKey="ifYouNeedToYouCanAlways"
            components={[<Link key={0} href="mailto:contact@tunego.com" />]}
          />
        </div>
      </div>
    </div>
  )
}
