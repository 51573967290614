import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useArtistsInfinite } from '#hooks/swr/useArtists'
import { Breadcrumbs } from '#components/breadcrumbs'
import { ProfilesList } from '#pages/vault/profiles/components/profiles-list'
import { NoProfiles } from './components/no-profiles'
import { useReload } from '#modules/page-wrapper/context'
import { ColumnWrapper } from '#pages/vault/landing-page/components/column-wrapper'
import { PageWrapper } from '#modules/page-wrapper'
import { getBreadcrumbs } from '#pages/vault/profiles/utils/helpers'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import styled from 'styled-components'
import { LoadingImage } from '#components/loaders/loading-image'

const SLoaderWrapper = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SLoader = styled(LoadingImage)`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`

export const Profiles = () => {
  const { t } = useTranslation('vault')
  const { artists, size, isLoading, setSize, mutate } = useArtistsInfinite()
  const [loadedArtists, setLoadedArtists] = useState([])
  const [numberOfArtists, setNumberOfArtists] = useState()
  const { reload, shouldReload } = useReload()
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    reload(mutate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload])

  useEffect(() => {
    if (artists && !isLoading) {
      setInitialLoading(false)
    }
  }, [artists, isLoading])

  useEffect(() => {
    if (artists) {
      const artistList = []
      // eslint-disable-next-line consistent-return, array-callback-return
      artists.map(page => {
        const singlePage = page.data
        singlePage.forEach(item => artistList.push(item))
      })
      setLoadedArtists(artistList)
      setNumberOfArtists(artists[0].count)
    }
  }, [artists])

  const loadMore = () => {
    setSize(size + 1)
  }

  return (
    <PageWrapper showNavBar isFullWidth>
      {initialLoading ? (
        <SLoaderWrapper>
          <SLoader />
          <span>{t('loadingArtistProfiles')}</span>
        </SLoaderWrapper>
      ) : isArrayEmpty(loadedArtists) ? (
        <NoProfiles />
      ) : (
        <>
          <Breadcrumbs breadcrumbs={getBreadcrumbs(t)} />
          <ColumnWrapper>
            <ProfilesList
              items={loadedArtists}
              hasMore={loadedArtists.length < numberOfArtists}
              loadMore={loadMore}
            />
          </ColumnWrapper>
        </>
      )}
    </PageWrapper>
  )
}
