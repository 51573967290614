import React, { useCallback, useEffect } from 'react'
import { Modal } from '#components/modal'
import { bool, func, string } from 'prop-types'
import { BaseInput } from '#components/inputs/base-input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { nameValidationSchema } from '#pages/vault/wizard/utils/validations'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ArtistApi } from '#api/requests/artist'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { VaultModalContainer } from '#pages/vault/profile-content/containers/vault-modal-container'
import styled from 'styled-components'
import { useReload } from '#modules/page-wrapper/context'
import { useVault } from '#hooks/useVault'
import { useError } from '#hooks/useError'

const SModal = styled(Modal)`
  padding: 2rem 2.5rem;
`

const SBaseInput = styled(BaseInput)`
  width: 100%;
  margin-bottom: 2rem;
`

export const AddPackModal = ({ parentId, setIsOpen, ...modalProps }) => {
  const { error, setError, clearError } = useError()
  const { t } = useTranslation('vault')
  const validationTranslation = useTranslation('validation')
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    reset,
  } = useForm({
    resolver: yupResolver(nameValidationSchema(t('albumName'))),
  })

  const { actions, errors, loading } = useWithAsyncAction({
    postArtistAlbum: ArtistApi.postArtistAlbum,
  })

  const { setExpandedStructureId } = useVault()

  const { setReloading } = useReload()

  const handleSetError = useCallback(
    err => {
      setError(
        validationTranslation.t('requestOnlyMessage', {
          message: err.message,
        })
      )
    },
    [t]
  )

  useEffect(() => {
    if (errors.postArtistAlbum) {
      handleSetError(errors.postArtistAlbum)
    }
  }, [errors, handleSetError])

  const onSubmit = async values => {
    const data = {
      ...values,
      parentId,
    }
    const {
      data: { id },
    } = await actions.postArtistAlbum(data)

    setIsOpen(false)
    setReloading()
    setExpandedStructureId(id)
    reset()
  }

  return loading.postArtistAlbum || error ? (
    <CommonLoadingOverlay
      message={t('albumIsBeingCreated')}
      dialogOpen={loading.postArtistAlbum}
      error={error}
      onErrorBackdropClick={clearError}
    />
  ) : (
    <SModal {...modalProps} setIsOpen={setIsOpen} closeBtn onClose={reset}>
      <VaultModalContainer
        title={t('createNewPack')}
        text={t('youCanCreateUnlimitedPacks')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SBaseInput
          errorMessage={formErrors.name?.message}
          name="name"
          inputRef={register}
          label={t('albumName')}
        />
      </VaultModalContainer>
    </SModal>
  )
}

AddPackModal.propTypes = {
  isOpen: bool,
  setIsOpen: func,
  parentId: string.isRequired,
}
