import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import { MIN_ROW_HEIGHT } from '#pages/vault/song/tabs/files/utils/constants'

const SRow = styled.div`
  display: flex;
  min-height: ${MIN_ROW_HEIGHT}px;
  padding-left: 1rem;

  ${({
    theme: {
      tunego: { COLORS },
    },
    isEven,
    isMaster,
  }) =>
    (isEven || isMaster) &&
    css`
      background-color: ${isMaster ? COLORS.primary_85 : COLORS.neutral_95};
    `}
`

export const Row = ({ children, ...rowProps }) => {
  return <SRow {...rowProps}>{children}</SRow>
}

Row.propTypes = {
  isMaster: bool.isRequired,
  isEven: bool.isRequired,
  children: node.isRequired,
}
