import React from 'react'
import { bool } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = isReversed =>
  makeStyles(() => ({
    iconsContainer: {
      display: 'flex',
      flexDirection: isReversed ? 'row-reverse' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 24,
    },
    stripeIconWrapper: {
      display: 'inline-flex',
    },
  }))()

export const StripeIcons = ({ isReversed }) => {
  const classes = useStyles(isReversed)

  return (
    <div className={classes.iconsContainer}>
      <img width="50%" src="/assets/images/credit-cards.png" alt="" />
      <a
        className={classes.stripeIconWrapper}
        href="https://stripe.com/"
        target="_blank"
      >
        <img
          width="120px"
          height="26px"
          src="/assets/images/stripe-logo.png"
          alt=""
        />
      </a>
    </div>
  )
}

StripeIcons.defaultProps = {
  isReversed: false,
}

StripeIcons.propTypes = {
  isReversed: bool,
}
