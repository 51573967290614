import React, { useEffect, useState } from 'react'
import { string, shape, func } from 'prop-types'
import { useLocation } from '@reach/router'
import { EmptyProfile } from '#pages/vault/profile-content/components/empty-profile/empty-profile'
import { TableHeader } from '#pages/vault/profile-content/components/table-header'
import { DeleteModalContainer } from '#pages/vault/profile-content/containers/delete-modal-container'
import { AddCollaboratorModal } from '#pages/vault/_modules/add-collaborator-modal'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { useArtistStructureContent } from '#hooks/swr/useArtists'
import { BaseLoader } from '#components/loaders/base-loader'
import { ACCESS_ROLES } from '#constants/accessRoles'
import { Pagination } from '#components/pagination'
import { useReload } from '#modules/page-wrapper/context'
import { useQuery } from '#hooks/useQuery'
import { TableBody } from '#pages/vault/profile-content/components/table-body'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import { useVault } from '#hooks/useVault'
import { ENDPOINTS } from '#api/endpoints'
import { MoveSingleModal } from '#pages/vault/profile-content/components/move-single-modal'
import styled from 'styled-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

// FIXME: replace MUI table with custom component
const STableWrapper = styled.div`
  width: 100%;

  &.MuiTableRow-head {
    background-color: ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_90};

    &.MuiTableCell-head {
      font-family: ${({
        theme: {
          tunego: { FONT },
        },
      }) => FONT.FAMILY.bold};
    }
  }
  &.MuiTableBody-root {
    &.MuiTableRow-root {
      cursor: pointer;
    }
  }

  &.MuiTableCell-root {
    border: none;
  }
`

// const useStyles = makeStyles(theme => ({
//   table: {
//     width: '100%',
//     '& .MuiTableRow-head': {
//       background: theme.palette.color.minorGrey,
//       '& .MuiTableCell-head': {
//         fontFamily: theme.typography.h2.fontFamily,
//       },
//     },
//     '& .MuiTableBody-root': {
//       '& .MuiTableRow-root': {
//         cursor: 'pointer',
//       },
//     },
//     '& .MuiTableCell-root': {
//       border: 'none',
//     },
//   },
// }))

export const ContentTableContainer = ({
  parent,
  handleAddEditCollaborators,
}) => {
  // TODO workaround around frontend design
  const tabQuery = useQuery('tab')
  const folderQuery = useQuery('folder')
  const { useAltStructuresEndpoint } = useFlags()

  const { state } = useLocation()
  const parentId = parent?.id
  const [typeOfCurrentAction, setTypeOfCurrentAction] = useState(null)
  const [structureCategory, setStructureCategory] = useState(null)
  const [idOfActiveActionItem, setIdOfActiveActionItem] = useState(null)
  const [idOfStructureWithOpenModal, setIdOfStructureWithOpenModal] = useState()
  const [
    idOfParentStructureWithOpenModal,
    setIdOfParentStructureWithOpenModal,
  ] = useState()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [modalValue, setModalValue] = useState(null)
  const [isAddCollaboratorModalOpen, setIsAddCollaboratorModalOpen] =
    useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { shouldReload, reload } = useReload()
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)

  const { expandedStructureId, setExpandedStructureId } = useVault()

  const query = `&page=${pageIndex}&limit=${rowsPerPage}&sortDirection=ASC`
  const { artistContent, isLoading, mutate, isValidating } =
    useArtistStructureContent(parentId, query)

  const showEmptyProfile = isArrayEmpty(artistContent?.data)

  const showAddCollaboratorModal =
    typeOfCurrentAction === ELLIPSIS_OPTIONS.INVITE_COLLABORATOR ||
    isAddCollaboratorModalOpen

  useEffect(() => {
    if (isMoveModalOpen) {
      setTypeOfCurrentAction(null)
    }
  }, [isMoveModalOpen])

  useEffect(() => {
    if (state?.openFolderId) {
      setExpandedStructureId(state?.openFolderId)
    }
  }, [state])

  useEffect(() => {
    if (!artistContent) return
    if (folderQuery && tabQuery === 'collaborators') {
      const folder = artistContent.data.find()
      if (folder) {
        const { id, name, accessRoles } = folder
        handleAddEditCollaborators({
          id,
          name,
          accessRoles,
        })
      }
    }
  }, [artistContent, folderQuery, handleAddEditCollaborators, tabQuery])

  useEffect(() => {
    reload(mutate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReload])

  const [moveParentId, setMoveParentId] = useState(null)
  const [currentParentId, setCurrentParentId] = useState(null)

  const onMoveSingle = (nestedParentId, albumId) => {
    setMoveParentId(nestedParentId || idOfActiveActionItem)
    setCurrentParentId(albumId || parentId)

    setIsMoveModalOpen(true)
  }

  const refreshData = () => {
    const endpoint = useAltStructuresEndpoint
      ? ENDPOINTS.STRUCTURES_ALT
      : ENDPOINTS.STRUCTURES

    const swrKey =
      expandedStructureId === idOfParentStructureWithOpenModal
        ? `${endpoint}?parentId=${parentId}`
        : `${endpoint}?parentId=${parentId}&withoutPagination=true&sortDirection=ASC`

    mutate(swrKey)
  }

  const handleCloseInlineAction = () => {
    refreshData()
    setTypeOfCurrentAction(null)
    setIdOfActiveActionItem(null)
  }

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false)
    handleCloseInlineAction()

    refreshData()
  }
  // eslint-disable-next-line no-shadow
  const handleDeleteModalOpen = (value, structureId, parentId) => {
    setModalValue(value)
    setIdOfStructureWithOpenModal(structureId)
    setIdOfParentStructureWithOpenModal(parentId)
    setIsDeleteModalOpen(true)
  }

  const handleCollaboratorModalClose = () => {
    setTypeOfCurrentAction(null)
    setIsAddCollaboratorModalOpen(false)
  }

  const handleCollaboratorModalOpen = (value, structureId) => {
    setModalValue(value)
    setIdOfStructureWithOpenModal(structureId)
  }

  const shouldEnableStructureDeletion = roles => {
    return roles?.some(role => role === ACCESS_ROLES.OWNER)
  }

  const onChangeRowsPerPageHandler = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPageIndex(0)
  }

  if (isLoading || isValidating) {
    return <BaseLoader text="Loading..." isLoading />
  }

  if (showEmptyProfile) {
    return <EmptyProfile />
  }

  return (
    <div>
      <STableWrapper>
        <TableHeader />
        <TableBody
          {...{
            parent,
            parentId,
            artistContent,
            typeOfCurrentAction,
            idOfActiveActionItem,
            setStructureCategory,
            shouldEnableStructureDeletion,
            handleCloseInlineAction,
            handleDeleteModalOpen,
            handleDeleteModalClose,
            setTypeOfCurrentAction,
            setIdOfActiveActionItem,
            handleAddEditCollaborators,
            handleCollaboratorModalClose,
            handleCollaboratorModalOpen,
          }}
          handleMoveSingle={onMoveSingle}
        />
        <Pagination
          count={artistContent?.count}
          page={pageIndex}
          rowsPerPage={rowsPerPage}
          onChangePage={setPageIndex}
          onChangeRowsPerPage={onChangeRowsPerPageHandler}
        />
      </STableWrapper>
      <DeleteModalContainer
        open={isDeleteModalOpen}
        handleClose={handleDeleteModalClose}
        folderOrSong={modalValue}
        structureId={idOfStructureWithOpenModal}
        parentId={idOfParentStructureWithOpenModal}
        onDelete={refreshData}
      />
      <AddCollaboratorModal
        open={showAddCollaboratorModal}
        handleClose={handleCollaboratorModalClose}
        folderOrProject={modalValue}
        structureId={idOfActiveActionItem}
        structureCategory={structureCategory}
      />
      <MoveSingleModal
        isOpen={isMoveModalOpen}
        setIsOpen={setIsMoveModalOpen}
        artistId={parent?.id}
        projectId={moveParentId}
        folderParentId={currentParentId}
      />
    </div>
  )
}

ContentTableContainer.propTypes = {
  parent: shape({ id: string.isRequired }).isRequired,
  parentId: string.isRequired,
  handleAddEditCollaborators: func,
}

ContentTableContainer.defaultProps = {
  handleAddEditCollaborators: () => {},
}
