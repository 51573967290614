import React, { useEffect, useState } from 'react'
import * as dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { OrderSummary } from '#modules/order-summary'
import { useSubscriptionsPlans } from '#hooks/swr/useSubscription'
import {
  SUBSCRIPTION_PERIOD,
  BILLING_PERIOD,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_STATUS,
} from '#pages/profile/utils/constants'
import { formatIntervalPrice } from '#pages/profile/utils/helpers'
import { CheckoutContainer } from '#pages/subscriptions/_containers/checkout-container'
import { useQuery } from '#hooks/useQuery'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { SubscriptionsApi } from '#api/requests/subscriptions'
import { HeadingBar } from '#components/heading-bar'
import { CURRENCY } from '#constants/currency'
import { UpgradeInfoBox } from '#pages/subscriptions/_components/upgrade-info-box'
import {
  getPaymentOrderData,
  getPaymentProratedData,
} from '#pages/subscriptions/_containers/payment-container/utils/helpers'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display: 'flex',
    marginTop: 64,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  checkoutWrapper: {
    width: 600,
    paddingTop: 60,
    minHeight: 960,
    borderRight: `1px solid ${theme.palette.color.darkGrey33}`,
    position: 'relative',
  },
  orderWrapper: {
    marginTop: 60,
    width: '100%',
  },
  rowValueWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  bold: {
    fontWeight: 700,
    display: 'inline',
  },
}))

export const PaymentContainer = () => {
  const { t } = useTranslation()
  const profileTranslation = useTranslation('profile')
  const classes = useStyles()
  const { user } = useUser()
  const currentSubscription = user?.subscription.subscription

  const planId = useQuery('planId')
  const planPeriod = useQuery('planPeriod')
  const isProrated = useQuery('isProrated')
  const isSwitched = useQuery('isSwitched')
  const isProratedTrue = isProrated === 'true'
  const isSwitchedTrue = isSwitched === 'true'

  const { plans } = useSubscriptionsPlans()
  const chosenPlan = plans?.find(plan => plan.id === planId)
  const { priceMap, tierLevel } = chosenPlan || {}
  const isMonthly = planPeriod === SUBSCRIPTION_PERIOD.MONTH

  const [proratedPrice, setProratedPrice] = useState('')
  const [payment, setPayment] = useState({})

  const { actions } = useWithAsyncAction({
    postPreviewUpgradeSubscription:
      SubscriptionsApi.postPreviewUpgradeSubscription,
  })

  const price =
    priceMap?.[
      isMonthly ? SUBSCRIPTION_TYPES.MONTHLY : SUBSCRIPTION_TYPES.YEARLY
    ]?.value

  const displayedPrice = chosenPlan && formatIntervalPrice(price, isMonthly)
  const displayedTotalPrice =
    chosenPlan && formatIntervalPrice(price, true, false)
  const totalPrice = chosenPlan && price
  const adjustmentPrice =
    proratedPrice && formatIntervalPrice(proratedPrice - price, true, false)
  const isSubscriptionCancelled =
    currentSubscription?.status === SUBSCRIPTION_STATUS.CANCELED
  const totalProratedPrice = isProratedTrue
    ? proratedPrice && proratedPrice.toString()
    : totalPrice && totalPrice.toString()

  const accountRenewalDate = dayjs()
    .add(1, isMonthly ? BILLING_PERIOD.MONTH : BILLING_PERIOD.YEAR)
    .format('MM/DD/YYYY')

  const getPreview = async () => {
    const formattedPeriod =
      planPeriod === SUBSCRIPTION_PERIOD.MONTH
        ? BILLING_PERIOD.MONTH
        : BILLING_PERIOD.YEAR

    const data = await actions.postPreviewUpgradeSubscription({
      planId,
      billingPeriod: formattedPeriod,
      amount: price,
      currency: CURRENCY.USD,
    })
    setProratedPrice(data?.data.amountDue)
  }

  useEffect(() => {
    if (!isSubscriptionCancelled) {
      getPreview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProratedTrue, price])

  const orderData = getPaymentOrderData({
    payment,
    tierLevel,
    displayedPrice,
    planPeriod,
    isSwitchedTrue,
    currentSubscription,
    accountRenewalDate,
    isProratedTrue,
    classes,
    t: profileTranslation.t,
  })

  const proratedData = getPaymentProratedData({
    classes,
    isMonthly,
    tierLevel,
    displayedTotalPrice,
    adjustmentPrice,
    t: profileTranslation.t,
  })

  return (
    <>
      <HeadingBar>{profileTranslation.t('tuneGoSubscription')}</HeadingBar>
      <div className={classes.contentWrapper}>
        <div className={`${classes.checkoutWrapper} ${classes.column}`}>
          <CheckoutContainer
            {...{
              price,
              planId,
              planPeriod,
              setPayment,
              adjustmentPrice,
            }}
          />
        </div>
        <div className={`${classes.orderWrapper} ${classes.column}`}>
          {isSwitchedTrue && <UpgradeInfoBox />}
          <OrderSummary
            orderData={orderData}
            proratedData={isSubscriptionCancelled ? [] : proratedData}
            price={totalProratedPrice}
            customPriceRowTitle={isProratedTrue ? t('totalPrice') : t('total')}
            isProrated={isProratedTrue}
            maxWidth="650px"
          />
        </div>
      </div>
    </>
  )
}
