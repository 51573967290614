import useSWR from 'swr'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'

export const useCollaborators = (structureId, query) => {
  const { data, error, ...rest } = useSWR(
    `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COLLABORATORS, {
      id: structureId,
    })}${query}`
  )
  return {
    collaborators: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
