import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { TableRow, TableCell, TableHead } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  rowHeader: {
    textTransform: 'uppercase',
  },
}))

export const TableHeadRow = () => {
  const { t } = useTranslation('vault')
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        <TableCell width="40%">
          <div className={classes.rowHeader}>{t('name')}</div>
        </TableCell>
        <TableCell width="40%">
          <div className={classes.rowHeader}>{t('role')}</div>
        </TableCell>
        <TableCell width="10%">
          <div className={classes.rowHeader}>{t('joined')}</div>
        </TableCell>
        <TableCell width="10%" />
      </TableRow>
    </TableHead>
  )
}
