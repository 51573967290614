import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '#components/date-picker'
import { FIELDS } from '#pages/distro/_utils/constants'
import { useWizard } from '#modules/forms/form-wizard/context'
import { Text } from '#components/text'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginTop: 65,
    padding: 25,
    width: '100%',
    backgroundColor: theme.palette.color.darkBlue09,
    border: `1px solid ${theme.palette.color.darkGrey}`,
  },
  title: {
    fontSize: 22,
    marginTop: 10,
    marginRight: 25,
  },
  datePickerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  proTip: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  proTipBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  description: {
    gridColumn: 'span 2',
    fontWeight: 'bold',
    marginTop: 15,
    color: theme.palette.color.secondary7B,
  },
  proTipWrapper: {
    marginRight: 10,
  },
  lightBulb: {
    alignSelf: 'baseline',
  },
  proTipLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export const EstimatedReleaseDate = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')
  const { storedFormState } = useWizard()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const minimalDate = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)

  return (
    <div className={classes.container}>
      <div className={classes.datePickerWrapper}>
        <Text className={classes.title}>{t('estimatedReleaseDate')}:</Text>
        <Controller
          name={FIELDS.ESTIMATED_RELEASE_DATE}
          control={control}
          defaultValue={
            new Date(storedFormState[FIELDS.ESTIMATED_RELEASE_DATE])
          }
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={new Date(value)}
              minDate={minimalDate}
              errorMessage={errors[FIELDS.ESTIMATED_RELEASE_DATE]?.message}
              onChange={date => {
                onChange(date)
              }}
            />
          )}
        />
      </div>
      <div>
        <div className={classes.proTipBox}>
          <div className={classes.proTipWrapper}>
            <img
              className={classes.lightBulb}
              height="40px"
              src="/assets/svg/ic-lightbulb.svg"
              alt="lightbulb"
            />
          </div>
          <div className={classes.proTipLabel}>
            <span className={classes.proTip}>{t('proTip')}</span>
            <span>{t('itsSmartToRelease')}</span>
          </div>
        </div>
      </div>
      <span className={classes.description}>{t('setReleaseDateAtLeast')}</span>
    </div>
  )
}
