import React from 'react'
import { ReportsListContainer } from './containers/reports-list-container'
import { PageWrapper } from '#modules/page-wrapper'

export const Reports = () => {
  return (
    <PageWrapper showNavBar>
      <ReportsListContainer />
    </PageWrapper>
  )
}
