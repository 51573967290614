import React from 'react'
import styled from 'styled-components'
import { string, number, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Button } from '#components/button'
import { useUser } from '#hooks/useUser'
import { Text, TEXT_TYPE } from '#components/text'

const useStyles = makeStyles(({ palette }) => {
  return {
    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 375,
      height: 560,
      backgroundColor: `${palette.color.primary00}`,
      border: `1px solid ${palette.color.primary}`,

      '&:hover': {
        backgroundColor: `transparent`,
        boxShadow: `0 0 20px 0 ${palette.color.primary}, inset 0 0 10px 0 ${palette.color.primary}`,
      },

      '&:hover $cardContent': {
        background: `${palette.color.primary}`,
      },
    },
    cardContent: {
      borderBottom: `1px solid ${palette.color.primary}`,
      fontSize: '1.5rem',
      padding: 16,
    },
    imageWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    cardPercentageValue: {
      color: palette.color.primary,
      fontSize: '3.125rem !important',
      fontFamily: 'AvenirNextLTPro-Bold',
    },
    smallTuneGoIcon: {
      height: '24px',
      marginRight: 8,
    },
    cardContentWrapper: {
      padding: 24,
      flexGrow: 1,
      marginBottom: 24,
    },
    cardActions: {
      width: '100%',
      padding: 16,
    },
    cardButton: {
      fontSize: 23,
      lineHeight: '21px',
    },
  }
})

const SButton = styled(Button)`
  height: unset;
  width: 100%;
  font-size: 23px;
  line-height: 21px;
  padding: 20px;
  text-transform: uppercase;
`

export const OneCardOption = ({
  name,
  price,
  description,
  reviewers,
  value,
  onPlanSelect,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')
  const { formattedPriceOf } = useUser()
  const displayPrice = formattedPriceOf(price)
  const handleSelect = () => onPlanSelect(value)

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <div className={classes.imageWrapper}>
          <img
            className={classes.smallTuneGoIcon}
            src="/assets/images/tunego-static-icon.png"
            alt="TuneGO logo"
          />
          <Text type={TEXT_TYPE.SUBTITLE1}>{t(name)}</Text>
        </div>
      </div>
      <div className={classes.cardContentWrapper}>
        <span className={classes.cardPercentageValue}>{displayPrice}</span>
        <Text type={TEXT_TYPE.SUBTITLE1}>
          {`${reviewers} ${t('reviewers')}`}
        </Text>
        <span>{t(description)}</span>
      </div>
      <div className={classes.cardActions}>
        <SButton onClick={handleSelect}>{t('getStarted')}</SButton>
      </div>
    </div>
  )
}

OneCardOption.propTypes = {
  name: string.isRequired,
  price: string.isRequired,
  description: string.isRequired,
  value: string.isRequired,
  reviewers: number.isRequired,
  onPlanSelect: func.isRequired,
}
