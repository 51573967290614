import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { func } from 'prop-types'
import { NAV_BREAKPOINT, getNavItems } from '../../utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled, { css } from 'styled-components'
import {
  isCurrentUri,
  isExternalUri,
} from '#modules/page-wrapper/components/page-navigation-bar/utils/helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SDrawerWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 10000;
`

const SBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 0.3s linear;
`

const SDrawer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.charcoal};
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 260px;
  transition: transform 0.3s linear;
  transform: translateX(${({ open }) => (open ? '0px' : '-260px')});
  padding: 1rem;
`

const SListItem = styled.li`
  padding: 1rem 0;
  text-transform: uppercase;
  color: ${({ theme: { colors }, active }) =>
    active ? colors.primary : colors.default};
`

const linkStyle = css`
  text-decoration: none;
  color: inherit;
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
`

const SLink = styled(Link)`
  ${linkStyle}
`

const SAnchor = styled.a`
  ${linkStyle}
`

const SList = styled.ul`
  background: ${({ theme: { colors } }) => colors.charcoal};
  height: 100%;
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
`

const SNavbarLogo = styled.img`
  width: 94px;
  height: 16px;
`

export const SideNavigation = ({ onClose }) => {
  const {
    enableWalletConnect,
    enableBlog,
    enablePersona,
    enableGettingStarted,
  } = useFlags()

  const links = getNavItems({
    enableWalletConnect,
    enableBlog,
    enablePersona,
    enableGettingStarted,
  })
  const [openSidebar, setOpenSidebar] = useState(false)

  const closeSidebar = () => {
    setOpenSidebar(false)

    setTimeout(onClose, 300)
  }

  useEffect(() => {
    setOpenSidebar(true)

    const resizeHandler = () => {
      if (window.innerWidth > NAV_BREAKPOINT) closeSidebar()
    }

    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <SDrawerWrapper>
      <SBackdrop open={openSidebar} onClick={closeSidebar} />
      <SDrawer role="presentation" open={openSidebar}>
        <Link to={NAVIGATION_PATHS.SELL_COLLECTIBLES}>
          <SNavbarLogo src="/assets/svg/ic-tunego-logo.svg" alt="TuneGO Logo" />
        </Link>
        <SList>
          {links.map(({ title, link }) => {
            const currentLink = isCurrentUri(link)

            const externalLink = isExternalUri(link)

            return (
              <SListItem key={title} active={currentLink}>
                {externalLink ? (
                  <SAnchor
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </SAnchor>
                ) : (
                  <SLink to={link}>{title}</SLink>
                )}
              </SListItem>
            )
          })}
        </SList>
      </SDrawer>
    </SDrawerWrapper>
  )
}

SideNavigation.propTypes = {
  onClose: func,
}

SideNavigation.defaultProps = {
  avatarUri: '',
}
