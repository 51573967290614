import React from 'react'
import styled from 'styled-components'
import { Start } from '#pages/sell-collectibles/landing-page/_components/start'
import { Step } from '#pages/sell-collectibles/landing-page/_components/step'
import { useTranslation } from 'react-i18next'

const SRoadmap = styled.div`
  padding: 12.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/assets/images/sell-collectibles/steps-bg.jpg');
    opacity: 0.05;
    z-index: -1;
  }
`

const SPath = styled.img`
  width: 50rem;
  margin: 2rem 0;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    width: 37.5rem;
  }
`

const SCentralPath = styled.img`
  width: 50rem;
  height: 23.125rem;
  object-fit: contain;
  margin: 2rem 0;
  object-position: left;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    width: 37.5rem;
    height: 17.5rem;
  }
`

export const Roadmap = () => {
  const { t } = useTranslation('sellCollectibles')

  const steps = [
    {
      imgSrc: '/assets/images/sell-collectibles/timeline-1.png',
      iconSrc: '/assets/svg/ic-finish.svg',
      unlocked: true,
    },
    {
      imgSrc: '/assets/images/sell-collectibles/timeline-2.png',
      iconSrc: '/assets/svg/ic-pin.svg',
      unlocked: true,
    },
    {
      imgSrc: '/assets/images/sell-collectibles/timeline-3.png',
      unlocked: false,
    },
    {
      imgSrc: '/assets/images/sell-collectibles/timeline-4.png',
      unlocked: false,
    },
    {
      imgSrc: '/assets/images/sell-collectibles/timeline-5.png',
      unlocked: false,
    },
  ]

  return (
    <SRoadmap>
      <Start />
      <SCentralPath src="/assets/images/sell-collectibles/timeline-center-to-left.png" />
      {steps.map((step, i) => {
        const isEven = i % 2 === 1
        const isLast = i === steps.length - 1

        return (
          <>
            <Step
              key={`${i}`}
              index={i + 1}
              title={t(`step${i + 1}.title`)}
              text={t(`step${i + 1}.text`)}
              right={isEven}
              {...step}
            />
            {!isLast && (
              <SPath
                src={
                  isEven
                    ? '/assets/images/sell-collectibles/timeline-right-to-left.png'
                    : '/assets/images/sell-collectibles/timeline-left-to-right.png'
                }
              />
            )}
          </>
        )
      })}
    </SRoadmap>
  )
}
