export const performanceLanguage = [
  {
    value: 'English',
    label: 'English',
  },
  {
    value: 'Afar',
    label: 'Afar',
  },
  {
    value: 'Abkhazian',
    label: 'Abkhazian',
  },
  {
    value: 'Avestan',
    label: 'Avestan',
  },
  {
    value: 'Afrikaans',
    label: 'Afrikaans',
  },
  {
    value: 'Akan',
    label: 'Akan',
  },
  {
    value: 'Amharic',
    label: 'Amharic',
  },
  {
    value: 'Aragonese',
    label: 'Aragonese',
  },
  {
    value: 'Arabic',
    label: 'Arabic',
  },
  {
    value: 'Assamese',
    label: 'Assamese',
  },
  {
    value: 'Avaric',
    label: 'Avaric',
  },
  {
    value: 'Aymara',
    label: 'Aymara',
  },
  {
    value: 'Azerbaijani',
    label: 'Azerbaijani',
  },
  {
    value: 'Bashkir',
    label: 'Bashkir',
  },
  {
    value: 'Belarusian',
    label: 'Belarusian',
  },
  {
    value: 'Bulgarian',
    label: 'Bulgarian',
  },
  {
    value: 'Bihari labels',
    label: 'Bihari labels',
  },
  {
    value: 'Bislama',
    label: 'Bislama',
  },
  {
    value: 'Bambara',
    label: 'Bambara',
  },
  {
    value: 'Bengali',
    label: 'Bengali',
  },
  {
    value: 'Tibetan',
    label: 'Tibetan',
  },
  {
    value: 'Breton',
    label: 'Breton',
  },
  {
    value: 'Bosnian',
    label: 'Bosnian',
  },
  {
    value: 'Catalan',
    label: 'Catalan',
  },
  {
    value: 'Chechen',
    label: 'Chechen',
  },
  {
    value: 'Chamorro',
    label: 'Chamorro',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Chinese (Simplified)',
    label: 'Chinese (Simplified)',
  },
  {
    value: 'Chinese (Traditional)',
    label: 'Chinese (Traditional)',
  },
  {
    value: 'Corsican',
    label: 'Corsican',
  },
  {
    value: 'Creole, English based',
    label: 'Creole, English based',
  },
  {
    value: 'Creole, French based',
    label: 'Creole, French based',
  },
  {
    value: 'Creole, Portuguese based',
    label: 'Creole, Portuguese based',
  },
  {
    value: 'Cree',
    label: 'Cree',
  },
  {
    value: 'Czech',
    label: 'Czech',
  },
  {
    value: 'Church Slavic',
    label: 'Church Slavic',
  },
  {
    value: 'Chuvash',
    label: 'Chuvash',
  },
  {
    value: 'Welsh',
    label: 'Welsh',
  },
  {
    value: 'Danish',
    label: 'Danish',
  },
  {
    value: 'German',
    label: 'German',
  },
  {
    value: 'Divehi',
    label: 'Divehi',
  },
  {
    value: 'Dzongkha',
    label: 'Dzongkha',
  },
  {
    value: 'Ewe',
    label: 'Ewe',
  },
  {
    value: 'Greek',
    label: 'Greek',
  },
  {
    value: 'Esperanto',
    label: 'Esperanto',
  },
  {
    value: 'Spanish',
    label: 'Spanish',
  },
  {
    value: 'Spanish (Latin America)',
    label: 'Spanish (Latin America)',
  },
  {
    value: 'Estonian',
    label: 'Estonian',
  },
  {
    value: 'Basque',
    label: 'Basque',
  },
  {
    value: 'Persian',
    label: 'Persian',
  },
  {
    value: 'Fulah',
    label: 'Fulah',
  },
  {
    value: 'Finnish',
    label: 'Finnish',
  },
  {
    value: 'Fijian',
    label: 'Fijian',
  },
  {
    value: 'Faroese',
    label: 'Faroese',
  },
  {
    value: 'French',
    label: 'French',
  },
  {
    value: 'Western Frisian',
    label: 'Western Frisian',
  },
  {
    value: 'Irish',
    label: 'Irish',
  },
  {
    value: 'Gaelic',
    label: 'Gaelic',
  },
  {
    value: 'Galician',
    label: 'Galician',
  },
  {
    value: 'Guarani',
    label: 'Guarani',
  },
  {
    value: 'Gujarati',
    label: 'Gujarati',
  },
  {
    value: 'Manx',
    label: 'Manx',
  },
  {
    value: 'Hausa',
    label: 'Hausa',
  },
  {
    value: 'Haitian Creole',
    label: 'Haitian Creole',
  },
  {
    value: 'Hebrew',
    label: 'Hebrew',
  },
  {
    value: 'Hindi',
    label: 'Hindi',
  },
  {
    value: 'Hiri Motu',
    label: 'Hiri Motu',
  },
  {
    value: 'Croatian',
    label: 'Croatian',
  },
  {
    value: 'Haitian',
    label: 'Haitian',
  },
  {
    value: 'Hungarian',
    label: 'Hungarian',
  },
  {
    value: 'Armenian',
    label: 'Armenian',
  },
  {
    value: 'Herero',
    label: 'Herero',
  },
  {
    value: 'Interlingua',
    label: 'Interlingua',
  },
  {
    value: 'Indonesian',
    label: 'Indonesian',
  },
  {
    value: 'Interlingue',
    label: 'Interlingue',
  },
  {
    value: 'Igbo',
    label: 'Igbo',
  },
  {
    value: 'Sichuan Yi',
    label: 'Sichuan Yi',
  },
  {
    value: 'Inupiaq',
    label: 'Inupiaq',
  },
  {
    value: 'Ido',
    label: 'Ido',
  },
  {
    value: 'Icelandic',
    label: 'Icelandic',
  },
  {
    value: 'Italian',
    label: 'Italian',
  },
  {
    value: 'Inuktitut',
    label: 'Inuktitut',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Japanese Katakana',
    label: 'Japanese Katakana',
  },
  {
    value: 'Japanese Roman',
    label: 'Japanese Roman',
  },
  {
    value: 'Japanese Kanji',
    label: 'Japanese Kanji',
  },
  {
    value: 'Japanese Furigana',
    label: 'Japanese Furigana',
  },
  {
    value: 'Javanese',
    label: 'Javanese',
  },
  {
    value: 'Georgian',
    label: 'Georgian',
  },
  {
    value: 'Kongo',
    label: 'Kongo',
  },
  {
    value: 'Kikuyu',
    label: 'Kikuyu',
  },
  {
    value: 'Kuanyama',
    label: 'Kuanyama',
  },
  {
    value: 'Kazakh',
    label: 'Kazakh',
  },
  {
    value: 'Kalaallisut',
    label: 'Kalaallisut',
  },
  {
    value: 'Central Khmer',
    label: 'Central Khmer',
  },
  {
    value: 'Kannada',
    label: 'Kannada',
  },
  {
    value: 'Korean',
    label: 'Korean',
  },
  {
    value: 'Kanuri',
    label: 'Kanuri',
  },
  {
    value: 'Kashmiri',
    label: 'Kashmiri',
  },
  {
    value: 'Kurdish',
    label: 'Kurdish',
  },
  {
    value: 'Komi',
    label: 'Komi',
  },
  {
    value: 'Cornish',
    label: 'Cornish',
  },
  {
    value: 'Kirghiz',
    label: 'Kirghiz',
  },
  {
    value: 'Latin',
    label: 'Latin',
  },
  {
    value: 'Luxembourgish',
    label: 'Luxembourgish',
  },
  {
    value: 'Luganda',
    label: 'Luganda',
  },
  {
    value: 'Limburgan',
    label: 'Limburgan',
  },
  {
    value: 'Lingala',
    label: 'Lingala',
  },
  {
    value: 'Lao',
    label: 'Lao',
  },
  {
    value: 'Lithuanian',
    label: 'Lithuanian',
  },
  {
    value: 'Luba-Katanga',
    label: 'Luba-Katanga',
  },
  {
    value: 'Latvian',
    label: 'Latvian',
  },
  {
    value: 'Malagasy',
    label: 'Malagasy',
  },
  {
    value: 'Marshallese',
    label: 'Marshallese',
  },
  {
    value: 'Maori',
    label: 'Maori',
  },
  {
    value: 'Macedonian',
    label: 'Macedonian',
  },
  {
    value: 'Malayalam',
    label: 'Malayalam',
  },
  {
    value: 'Mongolian',
    label: 'Mongolian',
  },
  {
    value: 'Marathi',
    label: 'Marathi',
  },
  {
    value: 'Malay',
    label: 'Malay',
  },
  {
    value: 'Maltese',
    label: 'Maltese',
  },
  {
    value: 'Burmese',
    label: 'Burmese',
  },
  {
    value: 'Nauru',
    label: 'Nauru',
  },
  {
    value: 'Norwegian Bokmål',
    label: 'Norwegian Bokmål',
  },
  {
    value: 'North Ndebele',
    label: 'North Ndebele',
  },
  {
    value: 'Nepali',
    label: 'Nepali',
  },
  {
    value: 'Ndonga',
    label: 'Ndonga',
  },
  {
    value: 'Dutch',
    label: 'Dutch',
  },
  {
    value: 'Norwegian Nynorsk',
    label: 'Norwegian Nynorsk',
  },
  {
    value: 'Norwegian',
    label: 'Norwegian',
  },
  {
    value: 'South Ndebele',
    label: 'South Ndebele',
  },
  {
    value: 'Navajo',
    label: 'Navajo',
  },
  {
    value: 'Chichewa',
    label: 'Chichewa',
  },
  {
    value: 'Occitan',
    label: 'Occitan',
  },
  {
    value: 'Ojibwa',
    label: 'Ojibwa',
  },
  {
    value: 'Oromo',
    label: 'Oromo',
  },
  {
    value: 'Oriya',
    label: 'Oriya',
  },
  {
    value: 'Ossetian',
    label: 'Ossetian',
  },
  {
    value: 'Punjabi',
    label: 'Punjabi',
  },
  {
    value: 'Pali',
    label: 'Pali',
  },
  {
    value: 'Polish',
    label: 'Polish',
  },
  {
    value: 'Pushto',
    label: 'Pushto',
  },
  {
    value: 'Portuguese',
    label: 'Portuguese',
  },
  {
    value: 'Quechua',
    label: 'Quechua',
  },
  {
    value: 'Romansh',
    label: 'Romansh',
  },
  {
    value: 'Rundi',
    label: 'Rundi',
  },
  {
    value: 'Romanian',
    label: 'Romanian',
  },
  {
    value: 'Russian',
    label: 'Russian',
  },
  {
    value: 'Kinyarwanda',
    label: 'Kinyarwanda',
  },
  {
    value: 'Sanskrit',
    label: 'Sanskrit',
  },
  {
    value: 'Sardinian',
    label: 'Sardinian',
  },
  {
    value: 'Sindhi',
    label: 'Sindhi',
  },
  {
    value: 'Northern Sami',
    label: 'Northern Sami',
  },
  {
    value: 'Sango',
    label: 'Sango',
  },
  {
    value: 'Sinhala',
    label: 'Sinhala',
  },
  {
    value: 'Slovak',
    label: 'Slovak',
  },
  {
    value: 'Slovene',
    label: 'Slovene',
  },
  {
    value: 'Samoan',
    label: 'Samoan',
  },
  {
    value: 'Shona',
    label: 'Shona',
  },
  {
    value: 'Somali',
    label: 'Somali',
  },
  {
    value: 'Albanian',
    label: 'Albanian',
  },
  {
    value: 'Serbian',
    label: 'Serbian',
  },
  {
    value: 'Swati',
    label: 'Swati',
  },
  {
    value: 'Sotho, Southern',
    label: 'Sotho, Southern',
  },
  {
    value: 'Sundanese',
    label: 'Sundanese',
  },
  {
    value: 'Swedish',
    label: 'Swedish',
  },
  {
    value: 'Swahili',
    label: 'Swahili',
  },
  {
    value: 'Tamil',
    label: 'Tamil',
  },
  {
    value: 'Telugu',
    label: 'Telugu',
  },
  {
    value: 'Tajik',
    label: 'Tajik',
  },
  {
    value: 'Thai',
    label: 'Thai',
  },
  {
    value: 'Tigrinya',
    label: 'Tigrinya',
  },
  {
    value: 'Turkmen',
    label: 'Turkmen',
  },
  {
    value: 'Tagalog',
    label: 'Tagalog',
  },
  {
    value: 'Tswana',
    label: 'Tswana',
  },
  {
    value: 'Tonga (Tonga Islands)',
    label: 'Tonga (Tonga Islands)',
  },
  {
    value: 'Turkish',
    label: 'Turkish',
  },
  {
    value: 'Tsonga',
    label: 'Tsonga',
  },
  {
    value: 'Tatar',
    label: 'Tatar',
  },
  {
    value: 'Twi',
    label: 'Twi',
  },
  {
    value: 'Tahitian',
    label: 'Tahitian',
  },
  {
    value: 'Uighur',
    label: 'Uighur',
  },
  {
    value: 'Ukrainian',
    label: 'Ukrainian',
  },
  {
    value: 'Urdu',
    label: 'Urdu',
  },
  {
    value: 'Uzbek',
    label: 'Uzbek',
  },
  {
    value: 'Venda',
    label: 'Venda',
  },
  {
    value: 'Vietnamese',
    label: 'Vietnamese',
  },
  {
    value: 'Volapük',
    label: 'Volapük',
  },
  {
    value: 'Walloon',
    label: 'Walloon',
  },
  {
    value: 'Wolof',
    label: 'Wolof',
  },
  {
    value: 'Xhosa',
    label: 'Xhosa',
  },
  {
    value: 'Yiddish',
    label: 'Yiddish',
  },
  {
    value: 'Cantonese',
    label: 'Cantonese',
  },
  {
    value: 'Yoruba',
    label: 'Yoruba',
  },
  {
    value: 'Zhuang',
    label: 'Zhuang',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Zulu',
    label: 'Zulu',
  },
]
