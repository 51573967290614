import React from 'react'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

const SLabel = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  font-family: ${({ isBolded }) =>
    isBolded
      ? ({ theme: { typography } }) => typography.bold
      : ({ theme: { typography } }) => typography.fontFamily};
  text-transform: ${({ isUpperCased }) =>
    isUpperCased ? 'uppercase' : 'none'};
  position: relative;
`

const SSpan = styled.span`
  color: ${({ theme: { colors } }) => colors.primary};
  font-size: 18px;
  position: absolute;
  top: -0.25rem;
  padding-left: 0.25rem;
`

const SHint = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  font-size: ${({ theme: { fontSize } }) => fontSize.xs};
`

export const Label = ({
  label,
  name,
  required,
  isBolded,
  isUpperCased,
  hint,
}) => {
  return (
    <SLabel
      isBolded={isBolded}
      isUpperCased={isUpperCased}
      {...(name && { htmlFor: name })}
    >
      {label}
      {required && <SSpan> *</SSpan>}
      {hint && <SHint>{hint}</SHint>}
    </SLabel>
  )
}

Label.propTypes = {
  label: string.isRequired,
  name: string,
  required: bool,
  isBolded: bool,
  isUpperCased: bool,
  hint: string,
}

Label.defaultProps = {
  required: false,
  isBolded: false,
  isUpperCased: false,
  name: '',
}
