import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardStepsContainer } from '#pages/promotion/wizard/containers/wizard-steps-container'
import { PageWrapper } from '#modules/page-wrapper'
import { WizardContainer } from '#modules/forms/form-wizard/containers/wizard-container'
import { NAVIGATION_PATHS } from '#routes/routes'
import { getNavigation } from '#pages/promotion/wizard/utils/helpers'

const pageWrapperStyles = () => {
  return {
    maxWidth: 1200,
  }
}

export const CreatePromotion = () => {
  const { t } = useTranslation('promotion')

  return (
    <PageWrapper
      showNavBar={false}
      makeCustomStyles={pageWrapperStyles}
      withoutBorder
    >
      <WizardContainer
        navigationItems={getNavigation(t)}
        navigationExitString={NAVIGATION_PATHS.PROMOTION_REPORTS}
      >
        <WizardStepsContainer />
      </WizardContainer>
    </PageWrapper>
  )
}
