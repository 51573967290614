import React from 'react'
import { makeStyles } from '@material-ui/core'
import { string, bool, func, shape } from 'prop-types'

const useStyles = makeStyles(theme => {
  const size = 40
  const fontFamily = 'AvenirNext-Regular, Avenir Next, sans-serif'
  const lineHeight = 1.1
  return {
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '15px 20px',
      justifyContent: 'space-between',
    },
    thumbnail: {
      width: size,
      height: size,
      marginRight: 20,
      objectFit: 'cover',
    },
    noThumbnail: {
      width: size,
      height: size,
      marginRight: 20,
    },
    artistName: {
      fontSize: 18,
      fontFamily,
      fontWeight: 700,
      lineHeight: 'auto' || lineHeight,
    },
    idContainer: {
      fontFamily,
      fontSize: 14,
      fontWeight: 500,
      display: 'flex',
      lineHeight,
    },
    id: {
      fontFamily,
      fontSize: 14,
      color: theme.palette.primary.main,
      fontWeight: 700,
      textDecoration: 'underline',
      marginLeft: 10,
      lineHeight,
    },

    checkBox: {
      marginRight: '20px',
      width: '25px',
      height: '25px',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.color.default}`,
      overflow: 'hidden',
    },
    blueCheckIcon: {
      padding: '5px',
      width: '100%',
    },
    selected: {
      backgroundColor: theme.palette.color.default,
    },
    itemContent: {
      display: 'flex',
    },
  }
})

export const ArtistIdListItem = ({
  artistId,
  name,
  avatar,
  selected,
  select,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.itemContainer} onClick={select}>
      <div className={classes.itemContent}>
        {avatar ? (
          <img src={avatar} className={classes.thumbnail} alt="Avatar" />
        ) : (
          <div className={classes.noThumbnail} />
        )}
        <div>
          <span className={classes.artistName}>{name}</span>
          <div className={classes.idContainer}>
            Artist ID:<span className={classes.id}>{artistId}</span>
          </div>
        </div>
      </div>
      <div className={`${classes.checkBox} ${selected && classes.selected}`}>
        {selected && (
          <img
            className={classes.blueCheckIcon}
            src="/assets/svg/ic-blue-check.svg"
            alt="Check icon"
          />
        )}
      </div>
    </div>
  )
}

ArtistIdListItem.propTypes = {
  artistId: shape({}).isRequired,
  name: string.isRequired,
  avatar: string.isRequired,
  selected: bool.isRequired,
  select: func.isRequired,
}
