import { useEffect, useState } from 'react'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { FilesApi } from '#api/requests/files'

export const useArtwork = file => {
  const [artwork, setArtwork] = useState(null)

  const { actions } = useWithAsyncAction({
    getLink: FilesApi.getFileDownloadLink,
  })

  useEffect(() => {
    if (file) {
      actions
        .getLink(file.id)
        .then(({ data }) => {
          setArtwork(data.link)
        })
        .catch(() => {
          setArtwork(null)
        })
    } else {
      setArtwork(null)
    }
  }, [file])

  return artwork
}
