import React from 'react'
// import { navigate } from '@reach/router'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { SectionTitle } from '#components/section-title'
// import { NAVIGATION_PATHS } from '#routes/routes'
// import { buildUrl } from '#utils/buildUrl'
// import { useWizard } from '#modules/forms/form-wizard/context'

const useStyles = makeStyles(() => ({
  list: {
    marginTop: '2rem',
    paddingLeft: '1.75rem',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  listItem: {
    paddingLeft: '0.5rem',
    margin: '1rem 0',
  },
  instructionsWrapper: {
    padding: '2rem 3rem',
  },
}))

export const MasteringInstructions = () => {
  const classes = useStyles()
  const { t } = useTranslation('mastering')
  // const {
  //   storedFormState: { parentId },
  // } = useWizard()

  const navigateToCreateProjects = () => {
    // navigate(
    //   buildUrl(NAVIGATION_PATHS.CREATE_SONG_INSIDE_PROFILE, {
    //     artistId: parentId,
    //   })
  }

  return (
    <div className={classes.instructionsWrapper}>
      <SectionTitle>{t('masteringInstructions')}</SectionTitle>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <Trans
            t={t}
            {...{
              i18nKey: 'youCanSelectFiles',
              components: [
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  key={0}
                  className={classes.link}
                  onClick={navigateToCreateProjects}
                />,
              ],
            }}
          />
        </li>
        <li className={classes.listItem}>{t('pleaseLeave3to6')}</li>
        <li className={classes.listItem}>{t('mustBeAwavOraiff')}</li>
      </ul>
    </div>
  )
}
