import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { PRICE_TYPE } from '#constants/priceTypes'
import { useUser } from '#hooks/useUser'

const useStyles = makeStyles(theme => ({
  trackPrice: {
    color: theme.palette.color.primary,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: 36,
  },
  span: {
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.color.default,
    fontSize: 16,
  },
  subHeader: {
    fontSize: 14,
    marginBottom: 24,
  },
}))

export const SubHeader = () => {
  const { t } = useTranslation('mastering')
  const classes = useStyles()

  const { formattedPriceOf } = useUser()
  const price = formattedPriceOf(PRICE_TYPE.MASTER_TRACK)
  const displayPrice = `${price}/TRACK`

  return (
    <div>
      <div className={classes.span}>{t('simpleStraightforwardAffordable')}</div>
      <div className={classes.subHeader}>{t('whyPayStudioRatesWhen')}</div>
      <div className={classes.trackPrice}>{displayPrice}</div>
    </div>
  )
}
