import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { func, string } from 'prop-types'
import { SectionTitle } from '#components/section-title'
import { FilesTree } from '#modules/files-tree'
import { SHOW_FILES_CONDITION } from '#modules/choose-file-tree/utils/constants'

const useStyles = makeStyles(theme => ({
  uploaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  filesTreeWrapper: {
    width: 480,
    border: `1px solid ${theme.palette.color.primary}`,
  },
}))

export const FileTreeUploader = ({
  handleFileSelect,
  selectedFilesIds,
  parentId,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  return (
    <div className={classes.uploaderWrapper}>
      <SectionTitle>{t('chooseFromExistingFiles')}</SectionTitle>
      <div className={classes.filesTreeWrapper}>
        <FilesTree
          handleFileSelect={handleFileSelect}
          selectedFilesIds={selectedFilesIds}
          parentId={parentId}
          showFilesCondition={SHOW_FILES_CONDITION.DISTRO}
        />
      </div>
    </div>
  )
}

FileTreeUploader.propTypes = {
  handleFileSelect: func.isRequired,
  selectedFilesIds: func.isRequired,
  parentId: string.isRequired,
}
