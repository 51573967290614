import useSWR, { useSWRInfinite } from 'swr'
import { swrFetcher } from '#api/api'
import { ENDPOINTS } from '#api/endpoints'
import { buildUrl } from '#utils/buildUrl'
import { useEventSWR } from '#hooks/swr/useEventSWR'
import { EVENTS } from '#api/event'
import { useServerEvent } from '#hooks/swr/useServerEvent'
import { useUser } from '#hooks/useUser'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const useArtists = (query = '') => {
  const { useAltStructuresEndpoint } = useFlags()
  const { data, error, ...rest } = useSWR(
    `${
      useAltStructuresEndpoint ? ENDPOINTS.STRUCTURES_ALT : ENDPOINTS.STRUCTURES
    }${query}`
  )
  return {
    artists: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

// TODO events?
export const useArtistsInfinite = () => {
  const { useAltStructuresEndpoint } = useFlags()
  const getKey = pageIndex => {
    if (useAltStructuresEndpoint) {
      return `${ENDPOINTS.STRUCTURES_ALT}?limit=20&page=${pageIndex}`
    }
    return `${ENDPOINTS.STRUCTURES}?limit=20&page=${pageIndex}` // SWR key
  }

  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    getKey,
    swrFetcher,
    {
      revalidateAll: true,
    }
  )
  return {
    artists: data,
    isLoading: !error && isValidating,
    isError: error,
    size,
    setSize,
    mutate,
  }
}

export const useArtistStructure = structureId => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [EVENTS.PERMISSION_GRANTED, EVENTS.PERMISSION_REVOKED],
    eventAction: (event, _mutate) => {
      if (structureId === event.routingKey) {
        _mutate()
      }
    },
    swrKey:
      structureId &&
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE, { id: structureId }),
  })
  return {
    project: data, // FIXME: this is structure, not a project
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useArtistStructureContent = (parentId, query = '') => {
  const { user } = useUser()
  const { useAltStructuresEndpoint } = useFlags()
  const { data, error, mutate, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.STRUCTURE_CREATED,
      EVENTS.STRUCTURE_REMOVED,
      EVENTS.STRUCTURE_UPDATED,
      EVENTS.STRUCTURE_MOVED,
      EVENTS.PERMISSION_REVOKED,
      EVENTS.PERMISSION_GRANTED,
    ],
    eventAction: (event, _mutate) => {
      if (event.payload.parentId === parentId) {
        _mutate() // TODO patch in-place? hard to do because query filtering applied on backend
      } else if (
        event.payload.type === EVENTS.STRUCTURE_REMOVED &&
        event.payload.id === parentId
      ) {
        _mutate()
      } else if (event.payload.type === EVENTS.STRUCTURE_MOVED) {
        // TODO we dont know our ancestor id if we are a folder..
        const parts = [
          ...event.payload.structure.path.split('/'),
          ...event.payload.previousPath.split('/'),
        ]
        if (
          parts.includes(parentId) ||
          event.payload.structure.id === parentId
        ) {
          _mutate()
        }
      } else if (
        (event.type === EVENTS.PERMISSION_REVOKED ||
          event.type === EVENTS.PERMISSION_GRANTED) &&
        // TODO we do not know possible ancestor profileId...
        (event.payload.profileId === parentId ||
          event.payload.folderId === parentId) &&
        event.payload.targetUserId === user.id
      ) {
        _mutate()
      }
    },
    swrKey: useAltStructuresEndpoint
      ? `${ENDPOINTS.STRUCTURES_ALT}?parentId=${parentId}${query}`
      : `${ENDPOINTS.STRUCTURES}?parentId=${parentId}${query}`,
  })
  return {
    artistContent: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    ...rest,
  }
}

export const useArtistStructureContentPerRoles = ({
  parentId,
  query,
  roles,
}) => {
  const buildRolesQueryPart = roles =>
    roles.reduce((query, role) => `&roles=${role}`, '')

  const queryWithRoles = `${query}${buildRolesQueryPart(roles)}`

  return useArtistStructureContent(parentId, queryWithRoles)
}

export const useArtistProjectFiles = ({ projectId }, query = '') => {
  const { user } = useUser()
  const { data, error, mutate, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.FILE_CREATED,
      EVENTS.FILE_UPDATED,
      EVENTS.FILE_REMOVED,
      EVENTS.FILE_OVERWRITTEN,
    ],
    eventAction: ({ payload }, _mutate) => {
      if (payload.projectId === projectId) {
        _mutate() // TODO patch in-place? hard to do because query filtering applied on backend
      }
    },
    swrKey: `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_SONG_FILES, {
      id: projectId,
    })}${query}`,
  })
  useServerEvent({
    eventKey: [
      EVENTS.STRUCTURE_REMOVED,
      EVENTS.STRUCTURE_MOVED,
      EVENTS.PERMISSION_REVOKED,
    ],
    eventAction: event => {
      if (
        (event.type === EVENTS.STRUCTURE_MOVED &&
          event.payload.structure.id === projectId) ||
        (event.type === EVENTS.STRUCTURE_REMOVED &&
          // TODO we do not know ancestor ids...
          event.payload.id === projectId) ||
        (event.type === EVENTS.PERMISSION_REVOKED &&
          // TODO we do not know ancestor ids...
          event.payload.structureId === projectId &&
          event.payload.targetUserId === user.id)
      ) {
        mutate()
      }
    },
  })
  return {
    files: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    ...rest,
  }
}

export const useArtistGenres = () => {
  const { data, error, ...rest } = useSWR(`${ENDPOINTS.GENRES}`)
  return {
    genres: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useDefaultArtistIds = profileId => {
  const { data, error, ...rest } = useSWR(
    buildUrl(ENDPOINTS.STRUCTURES_DEFAULT_ARTIST_IDS, {
      profileId,
    })
  )

  return {
    profileArtistIds: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useArtistStructureCount = (structureId, category) => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.PERMISSION_GRANTED,
      EVENTS.PERMISSION_REVOKED,
      EVENTS.STRUCTURE_CREATED,
      EVENTS.STRUCTURE_REMOVED,
    ],
    swrKey:
      structureId &&
      `${buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_COUNT, {
        id: structureId,
      })}?category=${category}`,
  })
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useStructureNftFiles = structureId => {
  const { data, error, ...rest } = useEventSWR({
    eventKey: [
      EVENTS.NFT_CREATED,
      EVENTS.NFT_DELETED,
      EVENTS.NFT_FILE_ADDED,
      EVENTS.NFT_FILE_DELETED,
    ],
    swrKey:
      structureId &&
      buildUrl(ENDPOINTS.STRUCTURES_NFT_FILES_COUNT, {
        structureId,
      }),
  })
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
