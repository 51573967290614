import React, { useState } from 'react'
import { string, bool, func, number, arrayOf } from 'prop-types'
import { FolderContent } from '#modules/choose-file-tree/components/folder-content'
import { FilesList } from '#modules/choose-file-tree/components/files-list'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import styled, { css } from 'styled-components'

const RowName = styled.p`
  margin-left: 1.5rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const MainRow = styled.div`
  height: 3.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 1.625rem;
  background: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['primary_10%']};

  ${({
    isEven,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    isEven &&
    css`
      background: ${COLORS['primary_5%']};
    `}

  ${({
    isOpened,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    isOpened &&
    css`
      background: ${COLORS.primary_70};
    `}
`

export const MainTreeRow = ({
  id,
  category,
  isMainRowLast,
  name,
  isEven,
  handleFileSelect,
  selectedFileId,
  selectedFilesIds,
  showFilesCondition,
  showFileType,
  storedFilesIds,
  disabled,
  disableSelect,
  disabledTooltipText,
  reloadCount,
  showProcessing,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleOpened = () => {
    setIsOpened(!isOpened)
  }

  const imageSource =
    category === STRUCTURE_TYPE.ALBUM
      ? `/assets/svg/ic-folder-${isOpened ? 'white' : 'blue'}.svg`
      : `/assets/svg/ic-project-${isOpened ? 'white' : 'blue'}.svg`

  const rowContentProps = {
    handleFileSelect,
    selectedFileId,
    selectedFilesIds,
    showFilesCondition,
    showFileType,
    storedFilesIds,
    disabled,
    disableSelect,
    disabledTooltipText,
    reloadCount,
    showProcessing,
  }

  return (
    <>
      <MainRow isEven={isEven} isOpened={isOpened} onClick={toggleOpened}>
        <img src={imageSource} width="20px" alt="" />
        <RowName>{name}</RowName>
      </MainRow>
      {isOpened && category === STRUCTURE_TYPE.ALBUM && (
        <FolderContent parentId={id} {...rowContentProps} />
      )}
      {isOpened && category === STRUCTURE_TYPE.SONG && (
        <FilesList
          parentId={id}
          {...rowContentProps}
          isMainRowLast={isMainRowLast}
        />
      )}
    </>
  )
}

MainTreeRow.propTypes = {
  id: string.isRequired,
  category: string.isRequired,
  name: string.isRequired,
  isEven: bool.isRequired,
  handleFileSelect: func.isRequired,
  selectedFileId: string,
  selectedFilesIds: arrayOf(string),
  isMainRowLast: bool,
  parentId: string,
  showFilesCondition: string,
  showFileType: string,
  storedFilesIds: arrayOf(string),
  disabled: bool,
  disableSelect: func,
  disabledTooltipText: string,
  reloadCount: number,
  showProcessing: bool,
}

MainTreeRow.defaultProps = {
  selectedFileId: null,
  selectedFilesIds: [],
  parentId: null,
  showFilesCondition: '',
  showFileType: '',
  isMainRowLast: false,
  storedFilesIds: [],
  disableSelect: () => false,
  reloadCount: 0,
}
