import React from 'react'
import { number, shape, string, func, bool } from 'prop-types'
import styled from 'styled-components'

const SCell = styled.td`
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 1.25rem')};
`
export const CollectibleCell = ({ nft, column, revalidate }) => {
  return (
    <SCell noPadding={column.noPadding}>
      {column.render
        ? column.render(nft, revalidate)
        : _.get(nft, column.key) ?? '- -'}
    </SCell>
  )
}

CollectibleCell.propTypes = {
  nft: shape({
    id: string,
    imageId: string,
    packsCount: number,
    status: string,
    title: string,
    totalUnits: number,
  }).isRequired,
  column: shape({
    label: string,
    key: string,
    render: func,
    noPadding: bool,
  }).isRequired,
  revalidate: func.isRequired,
}
