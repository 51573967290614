import React, { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, bool, string } from 'prop-types'
import * as d3 from 'd3'

import { useWindowSize } from '#hooks/useWindowSize'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chart: {
    zIndex: '2',
  },
  barBg: {
    position: 'absolute',
    background: theme.palette.color.black,
    boxShadow: `inset 0 0 25px ${theme.palette.color.primary}`,
    zIndex: '1',
    border: `1px solid ${theme.palette.color.primary}`,
  },
  xAxis: {
    fontSize: '14px',
  },
  yAxis: {
    fontSize: '14px',
  },
  barValue: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  horizontalLines: {
    '& line': {
      stroke: theme.palette.color.primary,
    },
  },
  barBGGroups: {},
}))

export const VerticalBarGraph = ({
  data,
  showBarLabels,
  xUnit = '',
  yUnit = '',
  yDomain = [],
}) => {
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()
  const margin = 60
  const preparedData = data.map((obj, id) => ({
    y: obj[id],
    x: id,
  }))
  const [chartSize, setChartSize] = useState(null)

  const calculateWithMargin = value => value - 2 * margin

  const svgContainerRefMeasured = useCallback(node => {
    if (node !== null) {
      svgContainerRef.current = node
      setChartSize([
        calculateWithMargin(node.parentElement.offsetWidth),
        calculateWithMargin(node.parentElement.offsetHeight),
      ])
    }
  }, [])

  useEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(svgContainerRef.current.parentElement.offsetWidth),
        calculateWithMargin(svgContainerRef.current.parentElement.offsetHeight),
      ]
    setChartSize(getContainerSize())
  }, [windowSize])

  const draw = () => {
    if (!chartSize) {
      return
    }

    d3.select(svgContainerRef.current).select(`.${classes.chart}`).remove()

    d3.select(svgContainerRef.current)
      .select(`.${classes.barBGGroups}`)
      .remove()

    // define scales
    const xScale = d3
      .scaleBand()
      .domain(preparedData.map(s => s.x))
      .range([0, chartSize[0]])
      .padding(0.3)

    const yScale = d3.scaleLinear().domain(yDomain).range([chartSize[1], 0])

    // Chart container - includes SVG and bg divs
    const chartContainer = d3
      .select(svgContainerRef.current)
      .append('div')
      .attr('class', classes.barBGGroups)
      .attr('transform', `translate(${margin}, ${margin})`)

    // SVG Chart init
    const chart = d3
      .select(svgContainerRef.current)
      .append('svg')
      .attr('class', classes.chart)
      .attr('width', chartSize[0] + 2 * margin)
      .attr('height', chartSize[1] + 2 * margin)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`)

    // X Axis
    chart
      .append('g')
      .attr('class', classes.xAxis)
      .attr('transform', `translate(0, ${chartSize[1] + 20})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(0)
          .tickFormat(d => d + xUnit)
      )
      .call(g => g.select('.domain').remove())

    // Y Axis
    chart
      .append('g')
      .attr('class', classes.yAxis)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5, 'f')
          .tickSize(0)
          .tickFormat(d => d + yUnit)
      )
      .call(g => g.select('.domain').remove())

    // Value needed to reduce tick size
    const tickSizeReduce = chartSize[0] / data.length - xScale.bandwidth()

    // Horizontal Lines
    chart
      .append('g')
      .attr('class', classes.horizontalLines)
      .attr('transform', `translate(${tickSizeReduce}, 0)`)
      .call(
        d3
          .axisLeft(yScale)
          .ticks(5, 'f')
          .tickSize(-chartSize[0] + 2 * tickSizeReduce, 0, 0)
          .tickFormat('')
      )
      .call(g => g.select('.domain').remove())

    // Remove first and last horizontal line
    d3.select(`.${classes.horizontalLines} .tick:first-child`).remove()
    d3.select(`.${classes.horizontalLines} .tick:last-child`).remove()

    // Add containers for bars
    const barGroups = chart.selectAll().data(preparedData).enter().append('g')

    // Add containers for div bg
    const barBGGroups = chartContainer
      .selectAll()
      .data(preparedData)
      .enter()
      .append('div')

    // Inserting bars (rects) into g elements
    barGroups
      .append('rect')
      .attr('x', d => xScale(d.x))
      .attr('y', d => yScale(d.y))
      .attr('width', xScale.bandwidth())
      .attr('height', d => chartSize[1] - yScale(d.y))
      .style('fill', '#00A0FF')
      .style('z-index', '2')

    // Bg divs for bar elements
    barBGGroups
      .append('div')
      .attr('class', classes.barBg)
      .style('top', `${margin}px`)
      .style('left', d => `${xScale(d.x) + margin}px`)
      .style('width', `${xScale.bandwidth()}px`)
      .style('height', `${chartSize[1]}px`)

    if (showBarLabels) {
      // Values on bar elements
      barGroups
        .append('text')
        .attr('class', classes.barValue)
        .attr('fill', 'white')
        .attr('x', a => xScale(a.x) + xScale.bandwidth() / 2)
        .attr('y', a => yScale(a.y) + 30)
        .attr('text-anchor', 'middle')
        .text(a => `${a.y}%`)
    }
  }

  useEffect(() => {
    draw() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSize, data, showBarLabels, xUnit, yUnit, yDomain])

  return <div ref={svgContainerRefMeasured} className={classes.chartWrapper} />
}

VerticalBarGraph.propTypes = {
  data: arrayOf(string).isRequired,
  yDomain: arrayOf(string).isRequired,
  showBarLabels: bool,
  xUnit: string,
  yUnit: string,
}

VerticalBarGraph.defaultProps = {
  showBarLabels: false,
  xUnit: '',
  yUnit: '',
}
