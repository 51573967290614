import React from 'react'
import { node } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 32,
    fontFamily: theme.typography.h2.fontFamily,
    marginBottom: 16,
    width: '100%',
  },
}))

export const ModalHeader = ({ children }) => {
  const classes = useStyles()
  return <span className={classes.title}>{children}</span>
}

ModalHeader.propTypes = {
  children: node.isRequired,
}
