import React, { useState, useEffect, useCallback } from 'react'
import { bool, func, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SideOne } from '#pages/vault/_modules/add-collaborator-modal/components/side-one'
import { SideTwo } from '#pages/vault/_modules/add-collaborator-modal/components/side-two'
import { CollaboratorsApi } from '#api/requests/collaborators'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ContributorsApi } from '#api/requests/contributors'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { useError } from '#hooks/useError'
import { FORM_FIELDS } from '#pages/vault/_modules/add-collaborator-modal/constants'
import { pickBy } from 'lodash'

export const AddCollaboratorModal = ({
  open,
  handleClose,
  structureId,
  triggerMutate,
  structureCategory,
}) => {
  const { t } = useTranslation('vault')
  const [withAddAsContributor, setWithAddAsContributor] = useState(false)
  const [isSideOne, setIsSideOne] = useState(true)
  const { error, setError, clearError } = useError()

  const isProject = structureCategory === STRUCTURE_TYPE.SONG

  const { actions, anyLoading, errors } = useWithAsyncAction({
    postCollaborator: CollaboratorsApi.postCollaborator,
    postCollaboratorInvite: CollaboratorsApi.postCollaboratorInvite,
    postContributor: ContributorsApi.postContributor,
    postInviteContributor: ContributorsApi.postInviteContributor,
  })

  const handleSwitchSides = () => {
    setIsSideOne(!isSideOne)
    setWithAddAsContributor(false)
  }

  const closeModal = () => {
    setIsSideOne(true)
    handleClose()
    setWithAddAsContributor(false)
  }

  const getPayloads = (data, isOffPlatform) => {
    const { role, user, emailAddress, collaboratorName } = data
    const listOfRoles = role.map(r => r.value)
    const collaboratorPayload = {
      role: listOfRoles,
      ...(isOffPlatform
        ? {
            email: emailAddress,
            pendingName: collaboratorName,
          }
        : {
            userId: user.id,
          }),
    }

    const contributorPayload = {
      ...collaboratorPayload,
      ...pickBy(data, (value, key) => {
        return (
          !!value &&
          [
            FORM_FIELDS.ISNI,
            FORM_FIELDS.PUBLISHER,
            FORM_FIELDS.PRO_ATTRIBUTE,
            FORM_FIELDS.IPI_CAE_NUMBER,
          ].includes(key)
        )
      }),
    }

    return {
      contributorPayload,
      collaboratorPayload,
    }
  }

  const handleInviteCollaborator = async data => {
    const { contributorPayload, collaboratorPayload } = getPayloads(data)

    await actions.postCollaborator(structureId, collaboratorPayload)
    if (withAddAsContributor) {
      await actions.postContributor(structureId, contributorPayload)
    }

    triggerMutate()
    closeModal()
  }

  const handleInviteOffPlatformCollaborator = async data => {
    const { contributorPayload, collaboratorPayload } = getPayloads(data, true)

    await actions.postCollaboratorInvite(structureId, collaboratorPayload)
    if (withAddAsContributor) {
      await actions.postInviteContributor(structureId, contributorPayload)
    }
    triggerMutate()
    closeModal()
  }

  const handleSetError = useCallback(err => setError(err.message), [])

  useEffect(() => {
    if (errors.postCollaborator) {
      handleSetError(errors.postCollaborator)
    }
    if (errors.postCollaboratorInvite) {
      handleSetError(errors.postCollaboratorInvite)
    }
    if (errors.postContributor) {
      handleSetError(errors.postContributor)
    }
    if (errors.postInviteContributor) {
      handleSetError(errors.postInviteContributor)
    }
  }, [errors, handleSetError])

  if (anyLoading || error) {
    return (
      <CommonLoadingOverlay
        message={t('collaboratorsLoading')}
        dialogOpen={anyLoading}
        error={error}
        onErrorBackdropClick={clearError}
      />
    )
  }

  return isSideOne ? (
    <SideOne
      handleSwitchSides={handleSwitchSides}
      open={open}
      handleClose={closeModal}
      handleInviteCollaborator={handleInviteCollaborator}
      setChecked={setWithAddAsContributor}
      checked={withAddAsContributor}
      isProject={isProject}
    />
  ) : (
    <SideTwo
      handleSwitchSides={handleSwitchSides}
      open={open}
      handleClose={closeModal}
      handleInviteCollaborator={handleInviteOffPlatformCollaborator}
      setChecked={setWithAddAsContributor}
      checked={withAddAsContributor}
      isProject={isProject}
    />
  )
}

AddCollaboratorModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  structureId: string.isRequired,
  triggerMutate: func,
  structureCategory: string.isRequired,
}

AddCollaboratorModal.defaultProps = {
  triggerMutate: () => {},
}
