import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { MasteringApi } from '#api/requests/mastering'
import { BackdropMessage } from '#components/backdrop-message'

const useStyles = makeStyles(() => ({
  linkButton: {
    height: '30px',
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    appearance: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  },
  download: {
    height: 20,
    width: 20,
    transform: 'rotate(180deg)',
  },
  arrow: {
    height: 18,
    width: 18,
  },
}))

export const DownloadButton = ({ fileId, isPreviewReady, url }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [error, setError] = useState('')

  const handleDownload = async () => {
    try {
      const {
        data: { link },
      } = await MasteringApi.getMasteredLink(fileId, true)
      // TODO this can be blocked by aggressive adblockers
      const a = document.createElement('a')
      a.href = link
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err) {
      setError(t('mastering.masters.error'))
    }
  }
  // eslint-disable-next-line consistent-return
  const renderPreviewButton = () => {
    if (isPreviewReady) {
      return (
        <button
          type="button"
          className={classes.linkButton}
          onClick={() => navigate(url, { state: { fromMastersView: true } })}
        >
          <img
            className={classes.arrow}
            src="/assets/svg/ic-arrow-right.svg"
            alt="navigate"
          />
        </button>
      )
    }
  }
  // eslint-disable-next-line consistent-return
  const renderDownloadButton = () => {
    if (fileId) {
      return (
        <button
          type="button"
          className={classes.linkButton}
          onClick={handleDownload}
        >
          <img
            className={classes.download}
            src="/assets/svg/ic-upload.svg"
            alt="download"
          />
        </button>
      )
    }
  }

  return (
    <>
      {renderPreviewButton()}
      {renderDownloadButton()}
      <BackdropMessage message={error} onBackdropClick={() => setError('')} />
    </>
  )
}

DownloadButton.propTypes = {
  fileId: string.isRequired,
  isPreviewReady: bool.isRequired,
  url: string.isRequired,
}
