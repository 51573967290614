import { ROUTES } from '#routes/routes'

export const FOOTER_LINKS = [
  {
    url: ROUTES.TERMS,
    title: 'Terms of Service',
  },
  {
    url: ROUTES.PRIVACY_POLICY,
    title: 'Privacy Policy',
  },
  {
    url: 'https://help.tunego.com/',
    title: 'Contact Support',
  },
]
