import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { HowToSubheader } from '#pages/promotion/landing-page/components/how-to-subheader'
import { Section } from '#components/section'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  bg: {
    width: '100%',
    backgroundImage: `url("/assets/images/how-to-bg.jpg")`,
    height: 1400,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 100%',
    backgroundSize: '200%',
    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'bottom',
      height: 1250,
      backgroundSize: '100%',
    },
  },
}))

const SSection = styled(Section)`
  background-image: none;
`

export const HowToSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.bg}>
      <SSection
        header={t('howToGetPromoted')}
        getStartedUrl={NAVIGATION_PATHS.PROMOTION_NEW}
        iconUrl="/assets/svg/ic-tunego-blue.svg"
        subheaderComponent={<HowToSubheader />}
      />
    </div>
  )
}

HowToSection.propTypes = {
  redirectLink: string.isRequired,
}
