import React from 'react'
import { string, node, number, bool } from 'prop-types'
import { StepHeader } from '#pages/sell-collectibles/create-collectible/_components/step-header'
import { Info } from '#pages/sell-collectibles/create-collectible/_components/info'
import { Header } from '#pages/sell-collectibles/create-collectible/_containers/header'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Loader } from '@tunego/tunego-ui-components'

const SStep = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_70};
  text-transform: uppercase;
  margin: 0;
`

const SWrapper = styled.div`
  position: relative;
`

const SHeaderWrapper = styled.div`
  padding: 2.5rem;
`

const SLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS['neutral_70%']};
  z-index: 10;
  pointer-events: none;
`

const SLoader = styled(Loader)`
  position: fixed;
  top: calc(50vh - 2.5rem);
  left: calc(50vw - 2.5rem);
`

export const StepWrapper = ({
  infoKey,
  title,
  children,
  step,
  loading,
  boldedInfo,
}) => {
  const { t } = useTranslation('nftWizard')

  return (
    <SWrapper>
      <SHeaderWrapper>
        <SStep>{t('stepNumber', { step: `${step}` })}</SStep>
        <Header>
          <StepHeader title={title} />
        </Header>
        {infoKey && (
          <Info
            i18nKey={infoKey}
            bolded={boldedInfo}
            icon={<img src="assets/svg/ic-bulb.svg" alt="Light bulb icon" />}
          />
        )}
      </SHeaderWrapper>
      {children}

      {loading && (
        <SLoaderWrapper>
          <SLoader />
        </SLoaderWrapper>
      )}
    </SWrapper>
  )
}

StepWrapper.propTypes = {
  infoKey: string,
  title: string,
  children: node,
  step: number,
  loading: bool,
  boldedInfo: bool,
}
