import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { IconWithText } from '../../components/icon-with-text'
import { SECURITY_ICONS } from '#pages/vault/landing-page/utils/constants'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.color.darkGrey12,
    paddingBottom: 50,
    position: 'relative',
    textAlign: 'center',
  },
  header: {
    fontFamily: theme.typography.h2.fontFamily,
    textTransform: 'uppercase',
    paddingTop: '6.25rem',
    fontSize: '2.5rem',
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const SecuritySection = () => {
  const classes = useStyles()
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <div className={classes.header}>{t('creativeSecurity')}</div>
      <div className={classes.icons}>
        {SECURITY_ICONS.map(item => (
          <IconWithText key={item.icon} item={item} isUppercase />
        ))}
      </div>
    </div>
  )
}
