import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { ACCEPTED_IMAGE_EXTENSIONS } from '#constants/acceptedFileTypes'
import { MAX_IMAGE_SIZE_DISPLAY } from '#pages/profile/utils/constants'

const SRequirements = styled.p`
  white-space: pre-wrap;
  text-align: center;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
`

const SBold = styled.b`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const AvatarRequirements = ({ className }) => {
  const { t } = useTranslation('validation')

  return (
    <SRequirements className={className}>
      <Trans
        t={t}
        i18nKey="avatarRequirements"
        values={{
          size: MAX_IMAGE_SIZE_DISPLAY,
          formats: ACCEPTED_IMAGE_EXTENSIONS.join(', '),
        }}
      >
        <SBold />
      </Trans>
    </SRequirements>
  )
}

AvatarRequirements.propTypes = {
  className: string,
}
