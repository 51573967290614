export const STORES = [
  { img: '/assets/images/company-icons/vevo-logo.png' },
  {
    img: '/assets/images/company-icons/apple-music-dark.png',
  },
  { img: '/assets/images/company-icons/amazon-dark.png' },
  { img: '/assets/images/company-icons/tidal-dark.png' },
  {
    img: '/assets/images/company-icons/facebook-on-white.png',
  },
]

export const ICON_SECTION_DATA = [
  {
    icon: '/assets/svg/ic-mastering-4.svg',
    headerTranslationKey: 'submitYourVideo',
    subHeaderTranslationKey: 'simplyUploadYourMusic',
    title: 'submitYourVideo',
  },
  {
    icon: '/assets/svg/ic-on-air.svg',
    headerTranslationKey: 'videoGoesLive',
    subHeaderTranslationKey: 'onceApprovedYourVideo',
    title: 'videoGoesLive',
  },
  {
    icon: '/assets/svg/ic-royalties.svg',
    headerTranslationKey: 'collectRoyalties',
    subHeaderTranslationKey: 'collectYourMusicVideo',
    title: 'collectRoyalties',
  },
]
