import React from 'react'
import styled from 'styled-components'
import { node, string, oneOfType } from 'prop-types'

const SHeader = styled.h1`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  font-size: ${({ theme: { fontSize } }) => fontSize['5xl']};
  text-transform: uppercase;
  margin: 0;

  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    font-size: ${({
      theme: {
        tunego: { FONT },
      },
    }) => FONT.SIZE['6xl']};
    margin-bottom: 2rem;
  }
`
export const MainHeader = ({ children, className }) => {
  return <SHeader className={className}>{children}</SHeader>
}

MainHeader.propTypes = {
  children: oneOfType([node, string]),
  className: string,
}
