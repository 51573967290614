import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import { Breadcrumbs } from '#components/breadcrumbs'
import { Pagination } from '#components/pagination'
import { MastersList } from '../../components/masters-list'
import { TableHeader } from '#modules/table/components/table-header'
import { useMasteredFiles } from '#hooks/swr/useMasteredFile'
import { HEADINGS } from '#pages/mastering/_utils/constants'
import { ENDPOINTS } from '#api/endpoints'
import { hexToRgba } from '#utils/hexToRgba'
import { CustomSelect } from '#components/inputs/custom-select'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { useGetProfiles } from '#components/inputs/utils'
import { PROFILE_TYPES } from '#constants/profileTypes'
import { getBreadcrumbs } from '#pages/mastering/view-masters/utils/helpers'

const useStyles = makeStyles(theme => ({
  columnWrapper: {
    maxWidth: 1050,
    margin: '0 auto',
    border: `1px solid ${hexToRgba(theme.palette.color.default, 0.2)}`,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 80,
    background: theme.palette.color.darkBlue03,
    flexGrow: 1,
  },
}))

const SButton = styled(Button)`
  height: 30px;
  margin-right: 28px;
  margin-left: auto;
`

const SCustomSelect = styled(CustomSelect)`
  width: 300px;
  margin-left: 28px;
`

export const MastersListContainer = () => {
  const [pageIndex, setPageIndex] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const masteringTranslation = useTranslation('mastering')
  const classes = useStyles()
  const profiles = useGetProfiles(PROFILE_TYPES.MASTERING)
  const [profileId, setProfileId] = useState(profiles[0].value)
  const breadcrumbs = getBreadcrumbs(masteringTranslation.t)
  const { files } = useMasteredFiles(
    `?page=${pageIndex}&limit=${rowsPerPage}&profileId=${profileId}`
  )

  const triggerMutate = () => {
    mutate(
      `${ENDPOINTS.MASTERING_GET_FILES}?page=${pageIndex}&limit=${rowsPerPage}&profileId=${profileId}`
    )
  }

  const handleNewMaster = () => navigate(NAVIGATION_PATHS.NEW_MASTERING)

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <div className={classes.columnWrapper}>
        <div className={classes.topBar}>
          <SCustomSelect
            options={profiles}
            defaultValue={profiles[0]}
            isForProfiles
            onChange={setProfileId}
          />
          <SButton
            variant={VARIANTS.NEW_BLUE_SPECIAL}
            onClick={handleNewMaster}
          >
            + {masteringTranslation.t('addNewMaster')}
          </SButton>
        </div>
        <TableHeader headings={HEADINGS} />
        <MastersList files={files?.data} triggerMutate={triggerMutate} />
        <Pagination
          count={files?.count || 10}
          page={pageIndex}
          rowsPerPage={rowsPerPage}
          onChangePage={setPageIndex}
          onChangeRowsPerPage={nextRowsPerPage => {
            setRowsPerPage(nextRowsPerPage)
            setPageIndex(0)
          }}
        />
      </div>
    </>
  )
}
