import React, { useState } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { func, string, bool, node } from 'prop-types'
import { CloseIcon } from '#icons'

const modalStyles = {
  overlay: {
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 10000,
    opacity: 1,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: 0,
    border: 'none',
    outline: 'none',
    background: 'transparent',
    overflow: 'visible',
  },
}

const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  max-width: 720px;
  min-height: 140px;
  padding: 40px;
  text-align: left;
  position: relative;
`

const SCloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2.5rem;
  padding: 0;
  cursor: pointer;
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_0};
`

export const Modal = props => {
  const {
    isOpen,
    setIsOpen,
    shouldCloseOnEsc,
    shouldCloseOnOverlayClick,
    className,
    children,
    closeBtn,
    onClose,
    ...rest
  } = props
  const [isOpened, setIsOpened] = useState(false)

  const handleOpen = () => {
    setIsOpened(true)
  }

  const handleClose = () => {
    setIsOpened(false)
    setIsOpen(false)
    if (onClose) onClose()
  }

  const fade = isOpened
    ? {
        overlay: {
          opacity: 1,
        },
      }
    : {}

  const fadeStyles = {
    overlay: {
      ...modalStyles.overlay,
      ...fade.overlay,
    },
    content: {
      ...modalStyles.content,
    },
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={fadeStyles}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldReturnFocusAfterClose
      onAfterOpen={handleOpen}
      onRequestClose={handleClose}
    >
      <SModalContent className={className} {...rest}>
        {closeBtn && (
          <SCloseButton onClick={handleClose}>
            <CloseIcon />
          </SCloseButton>
        )}
        {children}
      </SModalContent>
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  children: node.isRequired,
  shouldCloseOnEsc: bool,
  shouldCloseOnOverlayClick: bool,
  className: string,
  closeBtn: bool,
  onClose: func,
}

Modal.defaultProps = {
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  className: '',
}
