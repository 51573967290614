import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PageWrapper } from '#modules/page-wrapper'
import { ConnectDapperWallet } from '#pages/connect-wallet/_sections/connect-dapper-wallet'
import { WalletConnected } from '#pages/connect-wallet/_sections/wallet-connected'
import { useWallet } from '#hooks/swr/useWallet'
import { useBlockchainAccount } from '#modules/blockchain'
import { VideoBackground } from '#pages/_components/video-background'
import { useWalletSetup } from '#hooks/useWalletSetup'
import { SetupDapperWallet } from '#pages/connect-wallet/_sections/setup-dapper-wallet'
import { WalletsApi } from '#api/requests/wallets'
import { useError } from '#hooks/useError'
import { SetupModal } from '#pages/connect-wallet/_components/setup-modal'
import { useRoyaltySetup } from '#pages/connect-wallet/_hooks/use-royalty-setup'
import { useTranslation } from 'react-i18next'
import { bool } from 'prop-types'

const SWrapper = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  @media screen and (max-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    padding: 1.5rem;
    height: 100vh;
  }
`

const SInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`

const SLoaderWrapper = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

const SError = styled.span`
  color: ${({ theme: { colors } }) => colors.errorRed};
  margin-top: 1rem;
`

export const ConnectWallet = ({ mobile }) => {
  const { t } = useTranslation('connectWallet')

  const { wallet, isLoading } = useWallet()
  const { blockchainAddress } = useBlockchainAccount()

  const [setupModal, setSetupModal] = useState(false)

  const isWalletConnected = wallet || blockchainAddress

  const address = wallet?.address || blockchainAddress

  const {
    isRoyaltyChecked,
    loading: royaltyCheckLoading,
    setIsRoyaltyChecked,
  } = useWalletSetup(address)

  const { error, setError } = useError()

  const isSetupRequired =
    !(isLoading || royaltyCheckLoading) &&
    blockchainAddress &&
    !isRoyaltyChecked

  const setupCallback = () => {
    setIsRoyaltyChecked(true)
    setSetupModal(false)
  }

  const {
    royaltySetupCode,
    runSetup,
    loadingTx,
    setupInProgress,
    error: royaltySetupError,
    setupStatus,
  } = useRoyaltySetup(isSetupRequired, setupCallback)

  const addWallet = async () => {
    try {
      await WalletsApi.postWallet({ address })
    } catch {
      setError(t('connectError'))
    }
  }

  useEffect(() => {
    if (!wallet?.address && isRoyaltyChecked) {
      addWallet()
    }
  }, [isRoyaltyChecked, wallet, blockchainAddress])

  const errorMessage = royaltySetupError || error

  return (
    <PageWrapper showNavBar={!mobile} isFullWidth>
      {isLoading || royaltyCheckLoading || loadingTx ? (
        <SLoaderWrapper>
          <img src="/assets/gifs/loading.gif" alt="loading" />
          <div>{t('loading')}</div>
        </SLoaderWrapper>
      ) : (
        <SWrapper>
          <VideoBackground
            src="/assets/mp4/main-bg.mp4"
            opacity={0.14}
            showVideo={!mobile}
          >
            <SInnerWrapper>
              {isWalletConnected ? (
                isRoyaltyChecked ? (
                  <WalletConnected address={address} mobile={mobile} />
                ) : (
                  <SetupDapperWallet
                    loading={setupInProgress}
                    setupStatus={setupStatus}
                    onSetup={runSetup}
                  />
                )
              ) : (
                <ConnectDapperWallet
                  onConnect={() => setSetupModal(true)}
                  onError={() => setError(t('connectError'))}
                />
              )}

              {errorMessage && <SError>{errorMessage}</SError>}
            </SInnerWrapper>
          </VideoBackground>

          <SetupModal
            isOpen={setupModal}
            setIsOpen={setSetupModal}
            disabled={!royaltySetupCode}
            loading={setupInProgress}
            setupStatus={setupStatus}
            error={royaltySetupError}
            onSetup={runSetup}
          />
        </SWrapper>
      )}
    </PageWrapper>
  )
}

ConnectWallet.propTypes = {
  mobile: bool,
}
