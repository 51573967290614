/* eslint-disable no-useless-catch */
import { ENDPOINTS } from '#api/endpoints'
import { getApiClient } from '../api'
import { buildUrl } from '#utils/buildUrl'

export class FeaturedArtistsApi {
  static postFeaturedArtist = (structureId, data) =>
    getApiClient().post(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_FEATURED_ARTISTS, {
        structureId,
      }),
      data
    )

  static deleteFeaturedArtist = (structureId, featuredArtistId) =>
    getApiClient().delete(
      buildUrl(ENDPOINTS.STRUCTURES_STRUCTURE_FEATURED_ARTISTS_ARTIST, {
        structureId,
        featuredArtistId,
      })
    )
}
