import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SWrapper = styled.div`
  padding-right: 2.5rem;
`

const STitle = styled.h3`
  text-transform: uppercase;
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['6xl']};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SSubTitle = styled.h3`
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SDescription = styled.p`
  white-space: pre-wrap;
  word-break: break-word;
`

export const Description = () => {
  const { t } = useTranslation('nftWizard')

  const { detailsStepMethods } = useNftCreator()

  const values = detailsStepMethods.getValues()

  return (
    <SWrapper>
      <STitle>{values[NFT_DETAILS_STEP_FIELDS.NAME]}</STitle>
      <SDescription>{values[NFT_DETAILS_STEP_FIELDS.DESCRIPTION]}</SDescription>
      <SSubTitle>{t('steps.review.credits')}</SSubTitle>
      <p>
        {t('steps.review.songwriters', {
          songwriters: values[NFT_DETAILS_STEP_FIELDS.SONGWRITERS],
        })}
      </p>
      <p>
        {t('steps.review.producers', {
          producers: values[NFT_DETAILS_STEP_FIELDS.PRODUCERS],
        })}
      </p>
      <p>
        {t('steps.review.artwork', {
          artwork: values[NFT_DETAILS_STEP_FIELDS.ARTWORK_BY],
        })}
      </p>
    </SWrapper>
  )
}
