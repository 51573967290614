import React from 'react'
import styled from 'styled-components'
import { Player } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/player'
import { Countdown } from '@tunego/tunego-ui-components'
import { useTranslation } from 'react-i18next'
import { Creator } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/creator'
import { formatPrice } from '#utils/formatPrice'
import { Description } from '#pages/sell-collectibles/create-collectible/_components/steps/review/_components/description'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_90};
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
`

const SLeftColumn = styled.div`
  border-right: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85}; ;
`

const SEditions = styled.div`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.primary_50};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  border-bottom: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
`

const SPrice = styled.p`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['6xl']};
  padding: 1rem;
  margin: 0;
`

const SCountdown = styled(Countdown)`
  p {
    font-family: ${({
      theme: {
        tunego: { FONT },
      },
    }) => FONT.FAMILY.bold};
    margin: 0;
  }
`

export const NftSummary = () => {
  const { t } = useTranslation('nftWizard')

  const { detailsStepMethods } = useNftCreator()

  const values = detailsStepMethods.getValues()

  return (
    <SWrapper>
      <SLeftColumn>
        <Player />
        <SEditions>
          {t('steps.review.editions', {
            editions: values[NFT_DETAILS_STEP_FIELDS.SCARCITY],
          })}
        </SEditions>
        <SPrice>
          {formatPrice(values[NFT_DETAILS_STEP_FIELDS.PRICE] * 100, {
            trailingZeros: true,
          })}
        </SPrice>
        <SCountdown
          startTime={values[NFT_DETAILS_STEP_FIELDS.RELEASE_DATE]}
          text={t('steps.review.requestedDate')}
        />
        <Creator />
      </SLeftColumn>
      <Description />
    </SWrapper>
  )
}
