import React, { useState, useEffect, useCallback, useRef } from 'react'
import WaveSurfer from 'wavesurfer.js'
import styled, { useTheme } from 'styled-components'
import { bool, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { WAVEFORM_TYPE } from '#components/waveform-player/const'
import { ReflectionBox } from '#components/waveform-player/reflection-box'

const SButton = styled(Button)`
  border: none;
  z-index: 100;
  text-transform: uppercase;
`

const SPlayerWrapper = styled.div`
  position: relative;
  height: 200px;
`

const SPlayButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  top: 35%;
  left: 10%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  svg {
    scale: 1.75;
  }
`

const SOptionButtonsWrapper = styled.div`
  display: flex;
  margin: auto;
  background-color: ${({ theme: { colors } }) => colors.darkGrey};
  width: fit-content;
  border-radius: 10px;
`

export const WaveformPlayer = ({
  masteredTrack,
  originalTrack,
  isOnMainPage,
}) => {
  const { t } = useTranslation('mastering')
  const theme = useTheme()

  const masterWaveformRef = useRef(null)
  const originalWaveformRef = useRef(null)

  const masterWaveInstance = useRef(null)
  const originalWaveInstance = useRef(null)

  const [isMasterPlaying, setIsMasterPlaying] = useState(true)
  const [isSongPlaying, setIsSongPlaying] = useState(false)

  const [buttonVisible, setButtonVisible] = useState(false)

  const handleTogglePlay = useCallback(() => {
    if (!masterWaveInstance.current || !originalWaveInstance.current) return
    if (
      masterWaveInstance.current.isPlaying() ||
      originalWaveInstance.current.isPlaying()
    ) {
      masterWaveInstance.current.pause()
      originalWaveInstance.current.pause()
      setIsSongPlaying(false)
    } else {
      masterWaveInstance.current.play()
      originalWaveInstance.current.play()
      setIsSongPlaying(true)
      if (
        !masterWaveInstance.current.isPlaying() ||
        !originalWaveInstance.current.isPlaying()
      ) {
        masterWaveInstance.current.pause()
        originalWaveInstance.current.pause()
      }
    }
  }, [masterWaveInstance, originalWaveInstance])

  const toggleInstanceForMaster = mute => {
    originalWaveInstance.current.setMute(mute)
    masterWaveInstance.current.setMute(!mute)
  }

  const handleToggleTrack = name => {
    const shouldMasterPlay = name === WAVEFORM_TYPE.MASTER
    setIsMasterPlaying(shouldMasterPlay)

    if (name === WAVEFORM_TYPE.MASTER) {
      toggleInstanceForMaster(true)
      masterWaveInstance.current.setDisabledEventEmissions([])
      originalWaveInstance.current.setDisabledEventEmissions(['seek'])
    }
    if (name === WAVEFORM_TYPE.ORIGINAL) {
      toggleInstanceForMaster(false)
      originalWaveInstance.current.setDisabledEventEmissions([])
      masterWaveInstance.current.setDisabledEventEmissions(['seek'])
    }
  }

  const commonOptions = {
    waveColor: isOnMainPage ? theme.colors.default : theme.colors.primary60,
    progressColor: theme.colors.primary,
    cursorColor: theme.colors.default,
    cursorWidth: 0,
    responsive: true,
    height: 280,
    hideScrollbar: true,
    partialRender: true,
    barWidth: 2,
  }

  const loadWaveform = (fileUrl, containerRef) => {
    const waveInstance = WaveSurfer.create({
      container: containerRef,
      ...commonOptions,
    })
    waveInstance.load(fileUrl)
    return waveInstance
  }

  useEffect(() => {
    if (masterWaveformRef.current) {
      masterWaveInstance.current = loadWaveform(
        masteredTrack,
        masterWaveformRef.current
      )
    }
    if (originalWaveformRef.current) {
      originalWaveInstance.current = loadWaveform(
        originalTrack,
        originalWaveformRef.current
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterWaveInstance.current && originalWaveInstance.current) {
      // eslint-disable-next-line func-names
      masterWaveInstance.current.on('ready', function () {
        setButtonVisible(true)
      })
      // eslint-disable-next-line func-names
      originalWaveInstance.current.on('ready', function () {
        setButtonVisible(true)
      })
      // eslint-disable-next-line func-names
      masterWaveInstance.current.on('finish', function () {
        masterWaveInstance.current.pause()
        setIsSongPlaying(isPlaying => !isPlaying)
      })
      // eslint-disable-next-line func-names
      originalWaveInstance.current.on('finish', function () {
        originalWaveInstance.current.pause()
      })
    }

    return () => {
      masterWaveInstance.current && masterWaveInstance.current.destroy()
      originalWaveInstance.current && originalWaveInstance.current.destroy()
    }
  }, [masterWaveInstance, originalWaveInstance])

  useEffect(() => {
    if (masterWaveInstance.current && originalWaveInstance.current) {
      if (isMasterPlaying) {
        originalWaveInstance.current.un('seek')
        // eslint-disable-next-line func-names
        masterWaveInstance.current.on('seek', function (e) {
          originalWaveInstance.current.seekTo(e)
        })
      } else {
        masterWaveInstance.current.un('seek')
        // eslint-disable-next-line func-names
        originalWaveInstance.current.on('seek', function (e) {
          masterWaveInstance.current.seekTo(e)
        })
      }
    }
  }, [isMasterPlaying])

  return (
    <>
      <SPlayerWrapper>
        <SPlayButtonWrapper
          isVisible={masterWaveInstance.current && originalWaveInstance.current}
          onClick={handleTogglePlay}
        >
          {buttonVisible && (isSongPlaying ? <PauseIcon /> : <PlayArrowIcon />)}
        </SPlayButtonWrapper>
        <ReflectionBox
          ref={masterWaveformRef}
          id="masterWaveForm"
          visible={isMasterPlaying}
          isOnMainPage={isOnMainPage}
        />
        <ReflectionBox
          ref={originalWaveformRef}
          id="originalWavForm"
          visible={!isMasterPlaying}
          isOnMainPage={isOnMainPage}
        />
      </SPlayerWrapper>
      <SOptionButtonsWrapper>
        <SButton
          id="mastered"
          variant={isMasterPlaying ? '' : VARIANTS.TRANSPARENT}
          onClick={() => handleToggleTrack(WAVEFORM_TYPE.MASTER)}
        >
          {t('mastered')}
        </SButton>
        <Button
          id="original"
          variant={isMasterPlaying ? VARIANTS.TRANSPARENT : ''}
          onClick={() => handleToggleTrack(WAVEFORM_TYPE.ORIGINAL)}
        >
          {t('original')}
        </Button>
      </SOptionButtonsWrapper>
    </>
  )
}

WaveformPlayer.propTypes = {
  masteredTrack: string,
  originalTrack: string,
  isOnMainPage: bool,
}

WaveformPlayer.defaultProps = {
  masteredTrack: '',
  originalTrack: '',
  isOnMainPage: false,
}
