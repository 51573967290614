import React from 'react'
import { makeStyles } from '@material-ui/core'
import { func, number, shape } from 'prop-types'
import { formatDate } from '#utils/formatDate'
import { isEven } from '#utils/isEven'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    backgroundColor: theme.palette.color.lighterBlue,
    minHeight: 60,
    alignItems: 'center',
    padding: '5px 0 5px 30px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  isEven: {
    backgroundColor: theme.palette.color.darkBlue,
  },
  cellWrapper: {
    paddingRight: 30,
  },
  ellipsisWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ellipsisContainer: {
    maxWidth: '4rem',
    marginLeft: 'auto',
  },
}))

export const ContributorItem = ({
  contributor,
  renderEllipsisMenu,
  displayRoles,
  index,
}) => {
  const classes = useStyles()
  const {
    id: contributorId,
    status,
    user: { firstName, lastName, id },
    role,
    createdAt,
    pendingName,
  } = contributor
  const contributorName = firstName ? `${firstName} ${lastName}` : pendingName

  return (
    <div className={`${classes.container} ${isEven(index) && classes.isEven}`}>
      <div className={classes.cellWrapper}>{contributorName}</div>
      <div className={classes.cellWrapper}>{displayRoles(role)}</div>
      <div className={classes.ellipsisWrapper}>
        <span>{formatDate(createdAt)}</span>
        <div className={classes.ellipsisContainer}>
          {renderEllipsisMenu && renderEllipsisMenu(status, contributorId, id)}
        </div>
      </div>
    </div>
  )
}

ContributorItem.propTypes = {
  contributor: shape({}).isRequired,
  displayRoles: func.isRequired,
  index: number.isRequired,
  renderEllipsisMenu: func,
}

ContributorItem.defaultProps = {
  renderEllipsisMenu: null,
}
