import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ArtistIdSearcher } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-searcher'
import { ArtistIdText } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-text'
import { ArtistIdRadioButton } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-radio-button'
import { ThumbnailAndArtist } from '#pages/distro/wizard/steps/select-artist-id/components/thumbnail-and-artist'
import {
  ARTIST_ID_OPTION,
  RADIO_BUTTON_PROPS,
} from '#pages/distro/_utils/constants'
import { useDefaultArtistIds } from '#hooks/swr/useArtists'
import { useWizard } from '#modules/forms/form-wizard/context'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { DistroApi } from '#api/requests/distro'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { NewArtistIdFrom } from '#pages/distro/wizard/steps/select-artist-id/components/new-artist-id-form'
import { isSameArtist } from '../../utils/helpers'
import { ArtistIdStepWrapper } from '#pages/distro/wizard/steps/select-artist-id/components/artist-id-step-wrapper'

export const SelectArtistId = () => {
  const { t } = useTranslation()
  const { state, setStoredFormState } = useWizard()
  const { parentId: profileId, distroId, artistId } = state.storedFormState
  const [selectedArtistIds, setSelectedArtistIds] = useState(artistId.value)
  const [option, setOption] = useState(artistId.option)

  const { actions, loading } = useWithAsyncAction({
    setArtistIds: DistroApi.setArtistIds,
  })

  const { profileArtistIds, loading: loadingDefaultArtistIds } =
    useDefaultArtistIds(profileId)

  const changeOption = newOption => {
    if (newOption !== option) {
      setSelectedArtistIds([])
      setOption(newOption)
    }
  }

  useEffect(() => {
    if (!artistId?.value?.length) {
      if (profileArtistIds?.length) {
        setOption(ARTIST_ID_OPTION.DEFAULT)
        setSelectedArtistIds(profileArtistIds)
      } else {
        changeOption(ARTIST_ID_OPTION.NEW)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileArtistIds])

  useEffect(() => {
    if (option === 1 && !loadingDefaultArtistIds) {
      setSelectedArtistIds(profileArtistIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, profileArtistIds])

  // eslint-disable-next-line no-shadow
  const setOptionFn = option => () => changeOption(option)

  const onNextButtonClick = async () => {
    if (!selectedArtistIds?.length) {
      return
    }

    const artistIdsData = selectedArtistIds.map(
      // eslint-disable-next-line no-shadow
      ({ externalId, artistId, name, type }) => ({
        externalId: externalId ?? artistId,
        name,
        type,
      })
    )

    if (!isSameArtist(artistId.value, selectedArtistIds)) {
      await actions.setArtistIds(distroId, artistIdsData)
    }

    setStoredFormState({
      artistId: {
        value: selectedArtistIds,
        option,
      },
    })
  }

  const shouldDisableNextButton = !selectedArtistIds?.length
  const artistIdRadioButtonProps = { setOptionFn, option }
  const addArtistIdProps = {
    setSelectedArtistIds,
    artistId,
    ...artistIdRadioButtonProps,
  }

  return (
    <ArtistIdStepWrapper
      {...{
        onNextButtonClick,
        shouldDisableNextButton,
        noBackButton: true,
      }}
    >
      <ArtistIdText text="artistId" />

      <ArtistIdRadioButton
        {...{
          ...artistIdRadioButtonProps,
          ...RADIO_BUTTON_PROPS[ARTIST_ID_OPTION.DEFAULT],
          hide: !profileArtistIds?.length,
        }}
      >
        <ThumbnailAndArtist {...{ profileArtistIds }} />
      </ArtistIdRadioButton>

      <ArtistIdRadioButton
        {...{
          ...artistIdRadioButtonProps,
          ...RADIO_BUTTON_PROPS[ARTIST_ID_OPTION.NEW],
        }}
      >
        <NewArtistIdFrom {...addArtistIdProps} />
      </ArtistIdRadioButton>

      <ArtistIdRadioButton
        {...{
          ...artistIdRadioButtonProps,
          ...RADIO_BUTTON_PROPS[ARTIST_ID_OPTION.FROM_PLATFORM],
        }}
        withoutOr
      >
        <ArtistIdSearcher {...addArtistIdProps} />
      </ArtistIdRadioButton>

      <CommonLoadingOverlay
        {...{
          dialogOpen: loadingDefaultArtistIds || loading.setArtistIds,
          message: t('loadingTunego'),
        }}
      />
    </ArtistIdStepWrapper>
  )
}
