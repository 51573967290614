import React from 'react'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Avatar } from '@tunego/tunego-ui-components'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import { NFT_FILES_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'

const SWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  max-width: 31.25rem;
`

const SText = styled.p`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const SBold = styled.span`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const Creator = () => {
  const { t } = useTranslation('nftWizard')

  const { filesStepMethods } = useNftCreator()

  const values = filesStepMethods.getValues()

  const { label, avatar } = values[NFT_FILES_STEP_FIELDS.PERSONA]

  return (
    <SWrapper>
      <SAvatar src={avatar} />
      <SText>
        <Trans
          i18nKey="steps.review.createdBy"
          t={t}
          values={{ artist: label }}
        >
          <SBold />
        </Trans>
      </SText>
    </SWrapper>
  )
}
