import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  recommendation: {
    marginTop: 25,
    color: theme.palette.color.secondary7B,
    textAlign: 'center',
  },
}))

export const Recommendation = () => {
  const classes = useStyles()
  const { t } = useTranslation('distro')

  return (
    <span className={classes.recommendation}>
      {t('weRecommendSelectingAll')}
    </span>
  )
}
