import React, { useState } from 'react'
import { string, func, number } from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Pagination } from '#components/pagination'
import { useArtistStructureContent } from '#hooks/swr/useArtists'
import { isArrayEmpty } from '#utils/isArrayEmpty'
import { AlbumSelectionItem } from './components/album-selection-item'
import { STRUCTURE_TYPE } from '#constants/structureTypes'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const SContainer = styled.div`
  text-align: center;
  margin: auto;
  padding: 1.25rem 0;
  max-width: min(37.5rem, 100%);
`

const SSelectionWrapper = styled.div`
  min-height: 15.625rem;
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_70};
  width: 100%;
  max-height: calc(100vh - 30rem);
  overflow: auto;
`

const SHeader = styled.h3`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  text-align: left;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

export const AlbumSelection = ({
  parentId,
  selectedAlbumId,
  setSelectedAlbumId,
}) => {
  const { t } = useTranslation('vault')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { artistContent } = useArtistStructureContent(
    parentId,
    `&page=${page}&limit=${rowsPerPage}&category=${STRUCTURE_TYPE.ALBUM}`
  )

  const albums = artistContent?.data.filter(
    artist => artist.category === STRUCTURE_TYPE.ALBUM
  )

  const onChangeRowsPerPageHandler = nextRowsPerPage => {
    setRowsPerPage(nextRowsPerPage)
    setPage(0)
  }

  return (
    <SContainer>
      {!isArrayEmpty(albums) && (
        <>
          <SHeader>{t('addToAlbum')}</SHeader>
          <SSelectionWrapper>
            {albums.map((album, index) => (
              <AlbumSelectionItem
                key={generateUniqueKey(album, index)}
                album={album}
                selectedAlbumId={selectedAlbumId}
                setSelectedAlbumId={setSelectedAlbumId}
              />
            ))}
          </SSelectionWrapper>
        </>
      )}
      <Pagination
        count={artistContent?.count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={setPage}
        onChangeRowsPerPage={onChangeRowsPerPageHandler}
      />
    </SContainer>
  )
}

AlbumSelection.propTypes = {
  parentId: string.isRequired,
  selectedAlbumId: number.isRequired,
  setSelectedAlbumId: func.isRequired,
}
