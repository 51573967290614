import React, { useState } from 'react'
import { shape, string, func } from 'prop-types'
import {
  NFT_ACCESS_ROLE,
  NFT_STATUS,
} from '#pages/sell-collectibles/_utils/constants'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { navigate } from '@reach/router'
import { NAVIGATION_PATHS } from '#routes/routes'
import {
  ACTION_TYPES,
  COLLECTIBLE_OPTIONS,
} from '#pages/sell-collectibles/my-collectibles/_utils/constants'
import { useTranslation } from 'react-i18next'
import { ConfirmationModal } from '#components/confirmation-modal'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { NftApi } from '#api/requests/nft'
import { useError } from '#hooks/useError'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import styled from 'styled-components'

const SEllipsis = styled.div`
  width: 1rem;
  display: flex;
  justify-content: center;
`

export const CollectibleActions = ({ nft, revalidate }) => {
  const { t } = useTranslation('nftWizard')
  const [confirmationModal, setConfirmationModal] = useState(false)
  const { error, setError, clearError } = useError()

  const isOwner = nft.accessRoles.includes(NFT_ACCESS_ROLE.PROFILE_OWNER)

  const enableActions = nft.status === NFT_STATUS.IN_COMPLETE && isOwner

  const { actions } = useWithAsyncAction({
    deleteNft: NftApi.deleteNft,
  })

  const deleteDraft = async () => {
    clearError()

    try {
      await actions.deleteNft(nft.id)

      revalidate()
    } catch (err) {
      setError(err)
    }
  }

  const determineMenuAction = actionType => {
    switch (actionType) {
      case ACTION_TYPES.edit: {
        return navigate(
          `${NAVIGATION_PATHS.CREATE_COLLECTIBLES}?nftId=${nft.id}`
        )
      }
      case ACTION_TYPES.delete: {
        return setConfirmationModal(true)
      }
      default: {
        return null
      }
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      {enableActions ? (
        <SEllipsis>
          <EllipsisMenu
            options={COLLECTIBLE_OPTIONS(t)}
            onChoose={determineMenuAction}
          />
        </SEllipsis>
      ) : null}

      <ConfirmationModal
        confirmationText={t('deleteDraft')}
        isOpened={confirmationModal}
        isWarning
        isOwner
        onClose={() => setConfirmationModal(false)}
        onConfirm={deleteDraft}
      >
        <p>{t('deleteConfirmation')}</p>
      </ConfirmationModal>

      <Modal isOpen={!!error} setIsOpen={clearError}>
        <p>{t('errorDeletingDraft')}</p>

        <Button onClick={clearError}>{t('cancel')}</Button>
      </Modal>
    </div>
  )
}

CollectibleActions.propTypes = {
  nft: shape({ status: string }).isRequired,
  revalidate: func.isRequired,
}
