import React from 'react'
import styled from 'styled-components'

const SDivider = styled.div`
  border-bottom: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  margin: 2.5rem 0;
  position: relative;
`

const SArrow = styled.div`
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
  border-top: 0.75rem solid
    ${({ theme: { tunego } }) => tunego.COLORS.neutral_85};
  bottom: -0.75rem;
  left: calc(50% - 11px);
  width: 0;
  height: 0;
  position: absolute;

  &::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
    border-top: 0.7rem solid
      ${({ theme: { tunego } }) => tunego.COLORS.neutral_95};
    bottom: 0.1rem;
    left: -0.7rem;
  }
`

export const Divider = () => {
  return (
    <SDivider>
      <SArrow />
    </SDivider>
  )
}
