export const VERSION_STRING = process.env.REACT_APP_VERSION_STRING

const PLACEHOLDERS = {
  www: 'Enter Full URL',
  username: 'Enter username',
  channel: 'Enter channel name',
}

const iconAddressPrefix = '/assets/svg/ic-social'

export const SOCIAL_FIELDS = [
  {
    name: 'websiteUrl',
    label: 'Website',
    placeholder: PLACEHOLDERS.www,
    isUrl: true,
    iconUrl: `${iconAddressPrefix}-web.svg`,
  },
  {
    name: 'spotifyUrl',
    label: 'Spotify',
    placeholder: PLACEHOLDERS.www,
    isUrl: true,
    iconUrl: `${iconAddressPrefix}-spotify.svg`,
  },
  {
    name: 'instagramUsername',
    label: 'Instagram',
    placeholder: PLACEHOLDERS.username,
    iconUrl: `${iconAddressPrefix}-instagram.svg`,
  },
  {
    name: 'appleMusicUrl',
    label: 'Apple Music',
    placeholder: PLACEHOLDERS.www,
    isUrl: true,
    iconUrl: `${iconAddressPrefix}-apple.svg`,
  },
  {
    name: 'youtubeChannel',
    label: 'YouTube',
    placeholder: PLACEHOLDERS.channel,
    iconUrl: `${iconAddressPrefix}-youtube.svg`,
  },
  {
    name: 'soundCloudUsername',
    label: 'SoundCloud',
    placeholder: PLACEHOLDERS.username,
    iconUrl: `${iconAddressPrefix}-soundcloud.svg`,
  },
  {
    name: 'twitterUsername',
    label: 'Twitter',
    placeholder: PLACEHOLDERS.username,
    iconUrl: `${iconAddressPrefix}-twitter.svg`,
  },
  {
    name: 'audioMackUsername',
    label: 'AudioMack',
    placeholder: PLACEHOLDERS.username,
    iconUrl: `${iconAddressPrefix}-audiomack.svg`,
  },
  {
    name: 'facebookUsername',
    label: 'Facebook',
    placeholder: PLACEHOLDERS.username,
    iconUrl: `${iconAddressPrefix}-facebook.svg`,
  },
  {
    name: 'tiktokUrl',
    label: 'TikTok',
    placeholder: PLACEHOLDERS.www,
    isUrl: true,
    iconUrl: `${iconAddressPrefix}-tiktok.svg`,
  },
  {
    name: 'pandoraUrl',
    label: 'Pandora',
    placeholder: PLACEHOLDERS.www,
    isUrl: true,
    iconUrl: `${iconAddressPrefix}-pandora.svg`,
  },
  {
    name: 'twitchChannel',
    label: 'Twitch',
    placeholder: PLACEHOLDERS.channel,
    iconUrl: `${iconAddressPrefix}-twitch.svg`,
  },
]

export const PRO_PUBLISHING_FIELDS = {
  PRO: 'proAttribute',
  IPI_CAE: 'ipiCaeNumber',
  PUBLISHER: 'publisher',
}

const MAX_IMAGE_SIZE = 3

export const MAX_IMAGE_SIZE_DISPLAY = `${MAX_IMAGE_SIZE}`
export const MAX_IMAGE_SIZE_IN_BYTES = MAX_IMAGE_SIZE * 1024 * 1024

export const PROFILE_PAGES = {
  PRIVATE_SHARES: 'privateShares',
  SETTINGS: 'settings',
  LOGOUT: 'logout',
  SECURITY: 'security',
  // temporary hidden upon client's request
  // SOCIAL: 'social',
  PRO: 'pro',
  SUBSCRIPTION: 'subscription',
  PAYMENT_METHODS: 'paymentMethods',
  PAYMENT_HISTORY: 'paymentHistory',
  WALLET_DETAILS: 'walletDetails',
}

export const POSSIBLE_PROFILE_PAGES = Object.values(PROFILE_PAGES)

export const PROFILE_NAVIGATION = (
  enableSubscriptions = false,
  enablePayment = false
) => [
  {
    label: 'privateShares',
    id: PROFILE_PAGES.PRIVATE_SHARES,
  },
  { label: 'userDetails', id: PROFILE_PAGES.SETTINGS },
  // {
  //   label: 'walletDetails',
  //   id: PROFILE_PAGES.WALLET_DETAILS,
  // },
  // temporary hidden upon client's request
  // { label: 'accountSecurity', id: PROFILE_PAGES.SECURITY },
  // { label: 'linksAndStores', id: PROFILE_PAGES.SOCIAL },
  { label: 'proPublishing', id: PROFILE_PAGES.PRO },
  ...(enableSubscriptions
    ? [
        {
          label: 'subscription',
          id: PROFILE_PAGES.SUBSCRIPTION,
        },
      ]
    : []),
  ...(enablePayment
    ? [
        {
          label: 'paymentMethods',
          id: PROFILE_PAGES.PAYMENT_METHODS,
        },
        {
          label: 'paymentHistory',
          id: PROFILE_PAGES.PAYMENT_HISTORY,
        },
      ]
    : []),
  { label: 'logout', id: PROFILE_PAGES.LOGOUT },
]

export const SUBSCRIPTION_PERIOD = {
  MONTH: 'monthly',
  YEAR: 'annually',
}

export const BILLING_PERIOD = {
  MONTH: 'month',
  YEAR: 'year',
}

export const SUBSCRIPTION_PLANS = ['free', 'gold', 'platinum', 'diamond']

export const TRACK_PROMOTION = ['fullPrice', '10off', '15off', '20off']

export const SUBSCRIPTION_TYPES = {
  FREE: 'subscription-free',
  MONTHLY: 'subscription-monthly',
  YEARLY: 'subscription-yearly',
}

export const SUBSCRIPTION_ACTION = {
  CANCEL: 'cancel',
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
  DOWNGRADE_TO_FREE: 'downgradeToFree',
}

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
}

export const VARIANTS = {
  CURRENT: 'current',
  OTHER: 'other',
}

export const MODAL_TYPES = {
  SET_AS_DEFAULT: 'setAsDefault',
  DELETE_THIS_CARD: 'deleteThisCard',
}

export const PAYMENT_HEADERS = [
  {
    name: 'date',
    desktopWidth: '15%',
  },
  {
    name: 'orderNumber',
    desktopWidth: '30%',
  },
  {
    name: 'purchase',
    desktopWidth: '35%',
    headerProps: {
      marginLeft: '50px',
    },
  },
  {
    name: 'payment',
    desktopWidth: '20%',
  },
]

export const SUBSCRIPTION_ERROR = {
  TOO_MANY_PROJECTS: 'Too many projects added to the account.',
  NOT_ENOUGH_SPACE:
    'Not enough space left in your account to upload this file.',
}

export const CARD_BRAND = {
  unionpay: 'UnionPay',
  jcb: 'JCB',
  amex: 'American Express',
  diners: 'Diners Club',
}
