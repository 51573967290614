import React from 'react'
import { string, bool, shape } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  container: {
    padding: 50,
  },
  text: {
    textTransform: props => (props.isUppercase ? 'uppercase' : ''),
  },
}))

export const IconWithText = props => {
  const { item, isUppercase } = props
  const classes = useStyles({ isUppercase })
  const { t } = useTranslation('vault')

  return (
    <div className={classes.container}>
      <img src={item.icon} width="100px" height="100px" alt="icon" />
      <div className={classes.text} fontSize="20px">
        {t(item.text)}
      </div>
    </div>
  )
}

IconWithText.propTypes = {
  item: shape({
    text: string.isRequired,
    icon: string.isRequired,
  }).isRequired,
  isUppercase: bool,
}

IconWithText.defaultProps = {
  isUppercase: false,
}
