import React, { forwardRef } from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import { string, func, bool, node, shape } from 'prop-types'
import { MAX_FILE_SIZE_AS_BYTES } from '#constants/maxFileSize'

const SDropzoneWrapper = styled.div`
  max-width: 420px;
  max-height: 420px;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 0 20px 0
    ${({ error }) =>
      error
        ? ({ theme: { colors } }) => colors.error
        : ({ theme: { colors } }) => colors.primary};
  border: 2px dashed
    ${({ error }) =>
      error
        ? ({ theme: { colors } }) => colors.error
        : ({ theme: { colors } }) => colors.primary};
`

const SDropzone = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  position: relative;
`

const SDropzoneTitle = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-family: ${({ theme: { typography } }) => typography.bold};
  margin: 1rem auto;
  text-align: center;
`

export const CustomDropzone = forwardRef(
  (
    {
      children,
      onDrop,
      title,
      accept,
      error,
      multiple,
      afterDropAccepted,
      className,
      ...otherProps
    },
    ref
  ) => {
    return (
      <Dropzone
        ref={ref}
        multiple={multiple}
        accept={accept}
        maxSize={MAX_FILE_SIZE_AS_BYTES}
        onDrop={onDrop}
        onDropAccepted={() => {
          afterDropAccepted()
        }}
        {...otherProps}
      >
        {({ getRootProps, getInputProps }) => (
          <SDropzoneWrapper className={className} error={error}>
            <SDropzone {...getRootProps()}>
              <input {...getInputProps()} />
              <SDropzoneTitle>{title}</SDropzoneTitle>
              {children}
            </SDropzone>
          </SDropzoneWrapper>
        )}
      </Dropzone>
    )
  }
)

CustomDropzone.propTypes = {
  onDrop: func.isRequired,
  multiple: bool,
  error: bool,
  accept: shape,
  // eslint-disable-next-line react/forbid-prop-types
  title: string,
  afterDropAccepted: func,
  onDropRejected: func,
  children: node,
  className: string,
}

CustomDropzone.defaultProps = {
  multiple: false,
  error: false,
  title: '',
  afterDropAccepted: () => {},
  children: null,
  className: '',
}
