import { useEffect, useState } from 'react'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { FilesApi } from '#api/requests/files'

export const useSong = file => {
  const [song, setSong] = useState(null)
  const [retry, setRetry] = useState(0)

  const { actions } = useWithAsyncAction({
    getListenLink: FilesApi.getListenLink,
  })

  useEffect(() => {
    if (file) {
      actions
        .getListenLink(file.id)
        .then(({ data }) => {
          setSong(data.link)
        })
        .catch(err => {
          setSong(null)

          // handle preview not ready error
          if (err.response.status === 403) {
            setTimeout(() => {
              setRetry(val => val + 1)
            }, 2000)
          }
        })
    } else {
      setSong(null)
    }
  }, [file, retry])

  return song
}
