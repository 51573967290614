import React, { useEffect } from 'react'
import { arrayOf, string, node, shape } from 'prop-types'
import { Step } from '#pages/sell-collectibles/create-collectible/_components/wizard/step'
import { useNftCreator } from '#pages/sell-collectibles/create-collectible/_context'
import styled from 'styled-components'
import { BaseLoader } from '#components/loaders/base-loader'
import { Trans, useTranslation } from 'react-i18next'
import { ErrorMessage } from '#components/error-message'
import { StructureApi } from '#api/requests/structure'
import { CONTACT_SUPPORT_HREF } from '#constants/contact'

const SWrapper = styled.div`
  max-width: 75rem;
  min-height: calc(100vh - 3.75rem);
  margin: auto;
  border-left: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  border-right: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  padding-bottom: 5rem;
`

const SSteps = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  margin: 0;
  height: 6.75rem;
`

const SStatusWrapper = styled.div`
  height: calc(100vh - 3.75rem);
  display: flex;
  justify-content: center;
  align-items: center;
`

const SErrorMessage = styled(ErrorMessage)`
  text-align: center;
  white-space: pre-wrap;
`

const SLink = styled.a`
  color: inherit;
`

export const Wizard = ({ steps, nftId, personaId }) => {
  const { t } = useTranslation('nftWizard')
  const {
    activeStep,
    completedStep,
    setInitialData,
    loading,
    error,
    setPersona,
    disableNavigation,
    processCompleted,
  } = useNftCreator()

  useEffect(() => {
    setInitialData(nftId)
  }, [nftId])

  const preselectPersona = async () => {
    const { data } = await StructureApi.getSingleStructure(personaId)

    setPersona(data)
  }

  useEffect(() => {
    if (personaId) {
      preselectPersona()
    }
  }, [personaId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  return (
    <SWrapper>
      {error ? (
        <SStatusWrapper>
          <SErrorMessage
            errorMessage={
              <Trans t={t} i18nKey={error || 'errorLoadingData'}>
                <SLink href={CONTACT_SUPPORT_HREF} />
              </Trans>
            }
          />
        </SStatusWrapper>
      ) : loading ? (
        <SStatusWrapper>
          <BaseLoader isLoading={loading} text={t('loadingData')} />
        </SStatusWrapper>
      ) : (
        <>
          <SSteps>
            {steps
              .filter(step => !step.hidden)
              .map(({ label, key }, index) => (
                <Step
                  key={key}
                  label={label}
                  isActive={!processCompleted && index === activeStep}
                  isCompleted={index <= completedStep}
                  isUnlocked={index <= completedStep + 1}
                  isDisabled={disableNavigation}
                />
              ))}
          </SSteps>
          <div>{steps[activeStep].content}</div>
        </>
      )}
    </SWrapper>
  )
}

Wizard.propTypes = {
  steps: arrayOf(
    shape({
      label: string,
      key: string,
      content: node,
    })
  ).isRequired,
  nftId: string,
  personaId: string,
}
