/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useEffect, useCallback, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { shape, string, number, bool, arrayOf, objectOf } from 'prop-types'
import * as d3 from 'd3'

import { useTranslation } from 'react-i18next'
import { useWindowSize } from '#hooks/useWindowSize'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => ({
  chartWrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
  },
  legendWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  chart: {
    zIndex: '2',
  },
  xAxis: {
    fontSize: '14px',
  },
  yAxis: {
    fontSize: '14px',
  },
  verticalLines: {
    '& line': {
      stroke: theme.palette.color.primary,
    },
  },
  chartBg: {
    position: 'absolute',
    background: hexToRgba(theme.palette.color.primary, 0.1),
    boxShadow: `inset 0 0 45px ${theme.palette.color.primary}`,
    zIndex: '1',
    border: `1px solid ${theme.palette.color.primary}`,
  },
  ratingLegendImg: {
    display: 'block',
    width: '40px',
    marginBottom: 8,
  },
  ratingLegend: {
    fontSize: '12px',
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 16px',
  },
}))

export const ScatterPlotChart = ({
  data,
  xUnit = '',
  yUnit = '',
  xDomain = [],
  yDomain = [],
  pointRadius = 5,
  thisTrack,
  margin = 80,
  showLeggend = false,
}) => {
  const classes = useStyles()
  const svgContainerRef = useRef(null)
  const windowSize = useWindowSize()
  const theme = useTheme()
  const { t } = useTranslation('promotion')
  const axisMarginFromChart = margin / 3

  const centeredLineStroke = 3

  const [chartSize, setChartSize] = useState(null)

  const calculateWithMargin = value => value - 2 * margin

  const svgContainerRefMeasured = useCallback(node => {
    if (node !== null) {
      svgContainerRef.current = node
      setChartSize([
        calculateWithMargin(node.parentElement.offsetWidth),
        calculateWithMargin(node.parentElement.offsetHeight),
      ])
    }
  }, [])

  useEffect(() => {
    const getContainerSize = () =>
      svgContainerRef.current && [
        calculateWithMargin(svgContainerRef.current.parentElement.offsetWidth),
        calculateWithMargin(svgContainerRef.current.parentElement.offsetHeight),
      ]

    setChartSize(getContainerSize())
  }, [windowSize])

  const draw = () => {
    if (!chartSize) {
      return
    }

    // reset
    d3.select(svgContainerRef.current).select('svg').remove()

    d3.select(svgContainerRef.current).select(`.${classes.chartBg}`).remove()

    // define scales

    const xInitScale = d3.scaleLinear().domain(xDomain).range([-10, 10])

    const preparedXDomain = [xInitScale(xDomain[0]), xInitScale(xDomain[1])]

    const xScale = d3
      .scaleLinear()
      .domain(preparedXDomain)
      .range([0, chartSize[0]])

    const yInitScale = d3.scaleLinear().domain(yDomain).range([10, 90])

    const preparedYDomain = [yInitScale(yDomain[0]), yInitScale(yDomain[1])]

    const yScale = d3
      .scaleLinear()
      .domain(preparedYDomain)
      .range([chartSize[1], 0])

    const xTickStep = Math.ceil((preparedXDomain[1] - preparedXDomain[0]) / 5)
    const yTickStep = Math.ceil((preparedYDomain[1] - preparedYDomain[0]) / 5)

    const thisTrackPrepared = thisTrack
      ? [
          {
            x: xInitScale(Object.keys(thisTrack)[0]),
            y: yInitScale(thisTrack[Object.keys(thisTrack)[0]]),
          },
        ]
      : null
    const preparedData = data.map(obj => ({
      x: xInitScale(Object.keys(obj)[0]),
      y: yInitScale(obj[Object.keys(obj)[0]]),
    }))

    // Chart background
    d3.select(svgContainerRef.current)
      .append('div')
      .attr('class', classes.chartBg)
      .style('top', `${margin}px`)
      .style('left', `${margin}px`)
      .style('height', `${chartSize[1] + 1}px`)
      .style('width', `${chartSize[0] + 1}px`)
      .attr('transform', `translate(${margin}, ${margin})`)

    // SVG Chart init
    const chart = d3
      .select(svgContainerRef.current)
      .append('svg')
      .attr('class', classes.chart)
      .attr('width', chartSize[0] + 2 * margin)
      .attr('height', chartSize[1] + 2 * margin)
      .append('g')
      .attr('overflow', 'hidden')
      .attr('transform', `translate(${margin}, ${margin})`)

    // X Axis Labels
    chart
      .append('g')
      .attr('class', classes.xAxis)
      .attr('transform', `translate(0, ${chartSize[1] + axisMarginFromChart})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(0)
          .ticks(6)
          .tickFormat(d => d + xUnit)
          .tickValues(
            d3.range(
              preparedXDomain[0],
              preparedXDomain[1] + xTickStep,
              xTickStep
            )
          )
      )
      .call(g => g.select('.domain').remove())

    // Y Axis Labels
    chart
      .append('g')
      .attr('class', classes.yAxis)
      .attr('transform', `translate(${-axisMarginFromChart}, 0)`)
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(0)
          .ticks(6)
          .tickValues(
            d3.range(
              preparedYDomain[0],
              preparedYDomain[1] + yTickStep,
              yTickStep
            )
          )
          .tickFormat(d => d + yUnit)
      )
      .call(g => g.select('.domain').remove())

    // Vertical Lines
    chart
      .append('g')
      .attr('class', classes.verticalLines)
      .call(
        d3
          .axisBottom(xScale)
          .tickSize(chartSize[1])
          .tickFormat('')
          .tickValues(
            d3.range(
              preparedXDomain[0],
              preparedXDomain[1] + xTickStep,
              xTickStep
            )
          )
      )
      .call(g => g.select('.domain').remove())

    // Horizontal Lines
    chart
      .append('g')
      .attr('class', classes.verticalLines)
      .call(
        d3
          .axisRight(yScale)
          .tickSize(chartSize[0])
          .tickValues(
            d3.range(
              preparedYDomain[0],
              preparedYDomain[1] + yTickStep,
              yTickStep
            )
          )
          .tickFormat('')
      )
      .call(g => g.select('.domain').remove())

    // Centered Horizontal Line
    chart
      .append('g')
      .attr('stroke-width', centeredLineStroke)
      .attr('class', classes.verticalLines)
      .call(
        d3
          .axisRight(yScale)
          .tickSize(chartSize[0])
          .tickValues([(preparedYDomain[1] + preparedYDomain[0]) / 2])
          .tickFormat('')
      )
      .call(g => g.select('.domain').remove())

    // Centered Vertical Line
    chart
      .append('g')
      .attr('stroke-width', centeredLineStroke)
      .attr('class', classes.verticalLines)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues([0])
          .tickSize(chartSize[1])
          .tickFormat('')
      )
      .call(g => g.select('.domain').remove())

    // Creating Dots Container
    const dotsContainer = chart.append('g')

    // Apllying Clip path to hide dots out of chart
    dotsContainer
      .append('clipPath')
      .attr('id', 'ellipse-clip')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', chartSize[0])
      .attr('height', chartSize[1])

    // Adding dots
    dotsContainer
      .selectAll('dot')
      .data(preparedData)
      .enter()
      .append('circle')
      .attr('clip-path', 'url(#ellipse-clip)')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', pointRadius)
      .attr('stroke', 'white')
      .attr('stroke-width', '0')
      .style('fill', theme.palette.color.primary)

    if (thisTrackPrepared) {
      dotsContainer
        .selectAll('dot')
        .data(thisTrackPrepared)
        .enter()
        .append('circle')
        .attr('clip-path', 'url(#ellipse-clip)')
        .attr('cx', d => xScale(d.x))
        .attr('cy', d => yScale(d.y))
        .attr('r', pointRadius + 3)
        .attr('stroke', 'white')
        .attr('stroke-width', '3')
        .style('fill', theme.palette.color.primary)
    }
  }

  useEffect(() => {
    draw() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSize, data])

  return (
    <div className={classes.chartWrapper}>
      <div ref={svgContainerRefMeasured} id="chartWrapper" />
      {showLeggend && (
        <div className={classes.legendWrapper}>
          <div className={classes.iconWrapper}>
            <img
              src="/assets/images/rating-legend-1.png"
              alt="legend-1"
              className={classes.ratingLegendImg}
            />
            <span className={classes.ratingLegend}>{t('highRating')}</span>
            <span className={classes.ratingLegend}>{t('weakConsensus')}</span>
          </div>
          <div className={classes.iconWrapper}>
            <img
              src="/assets/images/rating-legend-2.png"
              alt="legend-2"
              className={classes.ratingLegendImg}
            />
            <span className={classes.ratingLegend}>{t('highRating')}</span>
            <span className={classes.ratingLegend}>{t('strongConsensus')}</span>
          </div>
          <div className={classes.iconWrapper}>
            <img
              src="/assets/images/rating-legend-3.png"
              alt="legend-3"
              className={classes.ratingLegendImg}
            />
            <span className={classes.ratingLegend}>{t('lowRating')}</span>
            <span className={classes.ratingLegend}>{t('strongConsensus')}</span>
          </div>
          <div className={classes.iconWrapper}>
            <img
              src="/assets/images/rating-legend-4.png"
              alt="legend-4"
              className={classes.ratingLegendImg}
            />
            <span className={classes.ratingLegend}>{t('lowRating')}</span>
            <span className={classes.ratingLegend}>{t('weakConsensus')}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ScatterPlotChart.propTypes = {
  data: arrayOf(objectOf(string)).isRequired,
  xDomain: arrayOf(number).isRequired,
  xUnit: string,
  yUnit: string,
  pointRadius: number,
  yDomain: arrayOf(number).isRequired,
  thisTrack: shape({}).isRequired,
  showLeggend: bool,
  margin: number,
}

ScatterPlotChart.defaultProps = {
  xUnit: '',
  yUnit: '',
  pointRadius: null,
  showLeggend: false,
  margin: null,
}
