import React from 'react'

export const LoadingImage = props => (
  <img
    src="/assets/gifs/loading.gif"
    alt="Loading"
    height={24}
    title="Loading"
    {...props}
  />
)
