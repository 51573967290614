import React from 'react'
import { UnauthenticatedPageWrapper } from '#modules/unauthenticated-page-wrapper'
import { OwnerAgreementText } from '#pages/owner-agreement/components/owner-agreement-text'

export const OwnerAgreement = () => {
  return (
    <UnauthenticatedPageWrapper>
      <OwnerAgreementText />
    </UnauthenticatedPageWrapper>
  )
}
