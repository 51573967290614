import { navigate } from '@reach/router'
import { NOTIFICATION_TYPES } from '#modules/notifications/utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { PROFILE_PAGES } from '#pages/profile/utils/constants'

// TODO: CHANGE PATHS
// eslint-disable-next-line consistent-return, complexity
export const redirectTo = (type, payload) => {
  const { profileId, projectId, folderId, fileId, masteredFileId, nftId } =
    payload || {}

  if (nftId) {
    switch (type) {
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PENDING_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.NFT_PENDING_SPLIT_REINVITED:
      case NOTIFICATION_TYPES.NFT_SPLIT_CREATED:
      case NOTIFICATION_TYPES.NFT_SPLIT_INVITATION:
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_APPROVED:
      case NOTIFICATION_TYPES.NFT_SPLIT_MY_PARAMETERS_REJECTED:
      case NOTIFICATION_TYPES.NFT_SPLIT_FULLY_APPROVED:
      case NOTIFICATION_TYPES.NFT_SOLD_OUT:
      case NOTIFICATION_TYPES.NFTS_SOLD_SINGLE:
      case NOTIFICATION_TYPES.NFT_SPLIT_REMOVED:
      case NOTIFICATION_TYPES.NFT_APPROVED:
      case NOTIFICATION_TYPES.NFT_DENIED:
        return {
          url: `${NAVIGATION_PATHS.CREATE_COLLECTIBLES}?nftId=${nftId}`,
        }
      default:
        return null
    }
  }

  if (fileId && masteredFileId) {
    switch (type) {
      case NOTIFICATION_TYPES.MASTERED_FILE_PREVIEW_READY:
        return {
          url: `${NAVIGATION_PATHS.NEW_MASTERING}?trackId=${fileId}&masteredId=${masteredFileId}&isEdited=true`,
        }
      default:
        return null
    }
  }

  if (projectId && !folderId) {
    switch (type) {
      case NOTIFICATION_TYPES.COLLABORATOR_INVITED:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_ACCEPTED_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_REJECTED:
      case NOTIFICATION_TYPES.COLLABORATOR_ROLE_DESIGNATED:
      case NOTIFICATION_TYPES.COLLABORATOR_REMOVED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/collaborators`,
        }
      case NOTIFICATION_TYPES.SPLIT_CREATED:
      case NOTIFICATION_TYPES.SPLIT_INVITATION:
      case NOTIFICATION_TYPES.SPLIT_PARAMETERS_UPDATED:
      case NOTIFICATION_TYPES.SPLIT_APPROVED:
      case NOTIFICATION_TYPES.SPLIT_REJECTED:
      case NOTIFICATION_TYPES.SPLIT_ROLES_UPDATED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/splits`,
        }
      case NOTIFICATION_TYPES.SHARE_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.SHARE_REVOKED:
      case NOTIFICATION_TYPES.SHARE_INVITATION_REJECTED:
        return {
          url: NAVIGATION_PATHS.PROFILE,
          urlState: { tab: PROFILE_PAGES.PRIVATE_SHARES },
        }
      case NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.CONTRIBUTOR_UPDATED:
      case NOTIFICATION_TYPES.CONTRIBUTOR_INVITATION_REJECTED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/metadata`,
        }
      case NOTIFICATION_TYPES.SHARE_ACCEPTED_INVITATION:
      case NOTIFICATION_TYPES.SHARE_LISTENED:
      case NOTIFICATION_TYPES.SHARE_EXPIRED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/private-shares`,
        }
      case NOTIFICATION_TYPES.FILE_CREATED:
      case NOTIFICATION_TYPES.FILE_RENAMED:
      case NOTIFICATION_TYPES.FILE_DELETED:
      case NOTIFICATION_TYPES.FILE_OVERWRITTEN:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/files`,
        }
      case NOTIFICATION_TYPES.STRUCTURE_NAME_CHANGED:
        return { url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}` }
      case NOTIFICATION_TYPES.STRUCTURE_METADATA_CHANGED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/metadata`,
        }
      case NOTIFICATION_TYPES.STRUCTURE_LYRICS_CHANGED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/song/${projectId}/lyrics`,
        }
      default:
        return null
    }
  }

  if (projectId && folderId) {
    switch (type) {
      case NOTIFICATION_TYPES.STRUCTURE_NAME_CHANGED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}`,
          urlState: { openFolderId: folderId },
        }
      default:
        return null
    }
  }

  if (!projectId && folderId) {
    switch (type) {
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.COLLABORATOR_ACCEPTED_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITED:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_REJECTED:
      case NOTIFICATION_TYPES.STRUCTURE_NAME_CHANGED:
      case NOTIFICATION_TYPES.STRUCTURE_CREATED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}`,
          urlState: { openFolderId: folderId },
        }
      default:
        return null
    }
  }

  if (!projectId) {
    switch (type) {
      case NOTIFICATION_TYPES.COLLABORATOR_INVITED:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_ACCEPTED_INVITATION:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.COLLABORATOR_INVITATION_REJECTED:
      case NOTIFICATION_TYPES.COLLABORATOR_ROLE_DESIGNATED:
      case NOTIFICATION_TYPES.COLLABORATOR_REMOVED:
        return {
          url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}/collaborators`,
        }
      case NOTIFICATION_TYPES.STRUCTURE_CREATED:
        return { url: `${NAVIGATION_PATHS.VAULT_PROFILES}/${profileId}` }
      case NOTIFICATION_TYPES.STRUCTURE_IMAGE_CHANGED:
      case NOTIFICATION_TYPES.STRUCTURE_NAME_CHANGED:
        return { url: `${NAVIGATION_PATHS.VAULT_PROFILES}` }
      default:
        return null
    }
  }

  switch (type) {
    case NOTIFICATION_TYPES.NFTS_SOLD:
      return {
        url: NAVIGATION_PATHS.MY_COLLECTIBLES, // TODO some sale history page?
      }
    default:
      return null
  }
}

export const navigateTo = (notificationType, notificationPayload) => {
  const { url, urlState } =
    redirectTo(notificationType, notificationPayload) || {}
  if (url) {
    navigate(url, { state: urlState })
  }
}
