import React, { useState } from 'react'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { useWizard } from '#modules/forms/form-wizard/context'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { SelectProfile } from '#modules/select-profile'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { ArtistApi } from '#api/requests/artist'
import { EmptyVaultInfo } from '#pages/_components/empty-vault-info'
import { StructureApi } from '#api/requests/structure'
import { EmptyProfileModal } from '#pages/_components/empty-profile-modal'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { useIsVaultEmpty } from '#hooks/useIsVaultEmpty'
import { useError } from '#hooks/useError'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const SelectArtistProfile = () => {
  const { storedFormState, incrementStep, setStoredFormState } = useWizard()
  const { t } = useTranslation('promotion')
  const validationTranslation = useTranslation('validation')
  const { error, setResponseError, clearError } = useError()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const { useAltStructuresEndpoint } = useFlags()

  const { actions, loading } = useWithAsyncAction({
    getArtist: ArtistApi.getArtists,
    getProfileContent: () =>
      StructureApi.getStructureContent('', useAltStructuresEndpoint),
  })
  const isEmptyVault = useIsVaultEmpty(actions.getArtist)

  const onSubmit = async ({ parent }) => {
    const parentId = parent?.value
    const filesCount = parent?.filesCount

    setStoredFormState({ parentId })

    if ((filesCount?.promotion || 0) < 1) {
      setIsModalOpened(true)

      return
    }

    try {
      if (parentId !== storedFormState.parentId) {
        setStoredFormState({
          parentId,
          selectedFileId: '',
          selectedFileName: '',
        })
      }

      const { data } = await actions.getProfileContent(`?parentId=${parentId}`)

      if (data?.count === 0) {
        setIsModalOpened(true)
      } else {
        incrementStep()
        navigate(
          `${NAVIGATION_PATHS.PROMOTION_NEW}?type=${storedFormState.planType}&artistId=${parentId}`,
          { replace: true }
        )
      }
    } catch (err) {
      setResponseError(err)
    }
  }

  return (
    <>
      <StepContainer noNextButton noFormStep>
        {isEmptyVault ? (
          <EmptyVaultInfo />
        ) : (
          <SelectProfile
            subtitle={t('selectTheProfile')}
            createNewProfileText={t('ifYouDoNotSeeTheProfile')}
            onSubmit={onSubmit}
          />
        )}
        <EmptyProfileModal
          profileId={storedFormState?.parentId}
          isOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          message={validationTranslation.t('selectedProfileDoesNot', {
            type: t('promotion'),
          })}
        />
      </StepContainer>
      <CommonLoadingOverlay
        dialogOpen={loading.getProfileContent}
        error={error}
        onErrorBackdropClick={clearError}
      />
    </>
  )
}
