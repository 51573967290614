export const EVENTS = {
  // Structures
  STRUCTURE_CREATED: 'structureCreated',
  STRUCTURE_UPDATED: 'structureUpdated',
  STRUCTURE_MOVED: 'structureMoved',
  STRUCTURE_REMOVED: 'structureRemoved',
  // Files
  FILE_CREATED: 'fileCreated',
  FILE_OVERWRITTEN: 'fileOverwritten',
  FILE_UPDATED: 'fileUpdated',
  FILE_REMOVED: 'fileRemoved',
  // Mastering
  MASTERED_FILE_CREATED: 'masteredFileCreated',
  MASTERED_FILE_UPDATED: 'masteredFileUpdated',
  MASTERED_FILE_REMOVED: 'masteredFileRemoved',
  // Shares
  SHARE_CREATED: 'shareCreated',
  SHARE_UPDATED: 'shareUpdated',
  SHARE_EXPIRED: 'shareExpired',
  // Permissions
  PERMISSION_GRANTED: 'permissionGranted',
  PERMISSION_REVOKED: 'permissionRevoked',
  // Payments
  PAYMENT_CREATED: 'paymentCreated',
  PAYMENT_UPDATED: 'paymentUpdated',
  PAYMENT_INVOICE_UPDATED: 'paymentInvoiceUpdated',
  PAYMENT_CHARGE_UPDATED: 'paymentChargeUpdated',
  PAYMENT_METHOD_ADDED: 'paymentMethodAdded',
  PAYMENT_METHOD_REMOVED: 'paymentMethodRemoved',
  PAYMENT_METHOD_DEFAULT_CHANGED: 'paymentMethodDefaultChanged',
  // Subscriptions
  SUBSCRIPTION_UPGRADED: 'subscriptionUpgraded',
  SUBSCRIPTION_PENDING_UPGRADE: 'subscriptionPendingUpgrade',
  SUBSCRIPTION_PENDING_UPDATE_APPLIED: 'subscriptionPendingUpdateApplied',
  SUBSCRIPTION_PENDING_UPDATE_EXPIRED: 'subscriptionPendingUpdateExpired',
  // User
  USER_UNSUSPENDED: 'userUnsuspended',
  USER_SUSPENDED: 'userSuspended',
  USER_DATA_MODIFIED: 'userDataModified',
  // Nft
  NFT_CREATED: 'nftCreated',
  NFT_UPDATED: 'nftUpdated',
  NFT_FILE_ADDED: 'nftFileAdded',
  NFT_FILE_DELETED: 'nftFileDeleted',
  NFT_OTHER_CREATED: 'nftOtherCreated',
  NFT_OTHER_UPDATED: 'nftOtherUpdated',
  NFT_LEGAL_CREATED: 'nftLegalCreated',
  NFT_DELETED: 'nftDeleted',
  NFT_SPLIT_UPDATED: 'nftSplitUpdated',
  NFT_SPLIT_CREATED: 'nftSplitCreated',
  NFT_SPLIT_DELETED: 'nftSplitDeleted',
  // SETTINGS
  SETTINGS_UPDATED: 'settingsUpdated',
}

export const NFT_BASE_EVENTS = [
  EVENTS.NFT_CREATED,
  EVENTS.NFT_UPDATED,
  EVENTS.NFT_DELETED,
]

// FIXME: remove outdated events
export const NFT_ITEM_EVENTS = [
  EVENTS.NFT_FILE_ADDED,
  EVENTS.NFT_FILE_DELETED,
  EVENTS.NFT_OTHER_CREATED,
  EVENTS.NFT_OTHER_UPDATED,
  EVENTS.NFT_LEGAL_CREATED,
  EVENTS.NFT_SPLIT_UPDATED,
  EVENTS.NFT_SPLIT_CREATED,
  EVENTS.NFT_SPLIT_DELETED,
]

export const NFT_EVENTS = [...NFT_ITEM_EVENTS, ...NFT_BASE_EVENTS]
