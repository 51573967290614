import React from 'react'
import styled from 'styled-components'
import { func, string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(theme => ({
  containerHorizontal: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '35px',
    minWidth: 1080,
  },
  containerVertical: {
    maxWidth: '300px',
  },
  uppercaseParagraph: {
    textTransform: 'uppercase',
    color: theme.palette.color.primary,
    fontWeight: 'bold',
  },
  success: {
    fontSize: 21,
  },
  submittedOrder: {
    fontSize: 18,
    marginBottom: 15,
  },
  congratulations: {
    fontWeight: 'bold',
    fontSize: 14,
    display: 'inline',
  },
  congratulationsMessage: {
    fontSize: 14,
    marginBottom: 20,
    display: 'inline',
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: '1.25rem',
  },
}))

const SButton = styled(Button)`
  margin-top: ${({ showHorizontal }) => (showHorizontal ? 0 : '30px')};
  margin-right: ${({ showHorizontal }) => (showHorizontal ? '90px' : 0)};
  width: ${({ showHorizontal }) => (showHorizontal ? '330px' : '100%')};
  text-transform: uppercase;
`

const SButtonTwo = styled(SButton)`
  width: 100%;
`

export const CheckoutSuccess = ({
  congratulationsMessage,
  onClick,
  buttonText,
  showHorizontally,
}) => {
  const { t } = useTranslation()
  const distroTranslation = useTranslation('distro')
  const promotionTranslation = useTranslation('promotion')
  const classes = useStyles()
  const containerClass =
    classes[showHorizontally ? 'containerHorizontal' : 'containerVertical']

  return (
    <div className={containerClass}>
      <div style={{ width: showHorizontally ? '35%' : '100%' }}>
        <Typography className={cn(classes.uppercaseParagraph, classes.success)}>
          {t('success')}
        </Typography>
        <Typography
          className={cn(classes.uppercaseParagraph, classes.submittedOrder)}
        >
          {t('yourOrderHasBeenSubmitted')}
        </Typography>
      </div>
      <div style={{ width: showHorizontally ? '65%' : '100%' }}>
        <Typography className={classes.congratulations}>
          {promotionTranslation.t('congratulations')}
        </Typography>
        <Typography className={classes.congratulationsMessage}>
          {congratulationsMessage}
        </Typography>
      </div>
      <div
        className={classes.buttonsWrapper}
        style={{ width: showHorizontally ? '840px' : 'none' }}
      >
        <SButtonTwo showHorizontally={showHorizontally} onClick={onClick}>
          {buttonText}
        </SButtonTwo>
        {showHorizontally && (
          <SButton
            showHorizontally={showHorizontally}
            variant={VARIANTS.NEW_BLUE_SPECIAL}
            onClick={() => navigate(NAVIGATION_PATHS.DISTRO_PAST_RELEASES)}
          >
            {distroTranslation.t('gotToMyReleases')}
          </SButton>
        )}
      </div>
    </div>
  )
}

CheckoutSuccess.propTypes = {
  showHorizontally: bool,
  congratulationsMessage: string.isRequired,
  buttonText: string.isRequired,
  onClick: func.isRequired,
}

CheckoutSuccess.defaultProps = {
  showHorizontally: false,
}
