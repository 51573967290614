import React from 'react'
import Dropzone from 'react-dropzone'
import { func, number, shape, string } from 'prop-types'
import { ACCEPTED_IMAGE_TYPES } from '#constants/acceptedFileTypes'
import { ArtworkPreview } from '#pages/distro/wizard/steps/release-details/components/artwork-preview'
import { ArtworkDropzone } from '#pages/distro/wizard/steps/release-details/components/artwork-dropzone'
import { ErrorMessage } from '#components/error-message'
import { FIELDS } from '#pages/distro/_utils/constants'
import styled, { css } from 'styled-components'

const SImageUploadWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 30px;
  margin-top: 10px;
`

const SDropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => `${props.width}px`};
  height: ${props => `${props.width}px`};
  cursor: pointer;

  ${({ artworkUrl }) =>
    artworkUrl &&
    css`
      border: 1px solid ${({ theme: { colors } }) => colors.primary};
      box-shadow: 0 0 20px 0 ${({ theme: { colors } }) => colors.primary};
    `}
`

export const ImageUpload = ({
  handleFileDrop,
  artworkUrl,
  title,
  watch,
  error,
  errors,
  width,
}) => {
  return (
    <SImageUploadWrapper>
      <Dropzone accept={ACCEPTED_IMAGE_TYPES} onDrop={handleFileDrop}>
        {({ getRootProps, getInputProps }) => {
          return (
            <SDropzoneWrapper
              width={width}
              artworkUrl={!artworkUrl}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {artworkUrl ? (
                <ArtworkPreview link={artworkUrl} width={width} />
              ) : (
                <ArtworkDropzone title={title} />
              )}
            </SDropzoneWrapper>
          )
        }}
      </Dropzone>
      <ErrorMessage
        errorMessage={
          (!watch(FIELDS.ARTWORK) && errors[FIELDS.ARTWORK]?.message) || error
        }
      />
    </SImageUploadWrapper>
  )
}

ImageUpload.defaultProps = {
  width: 300,
}

ImageUpload.propTypes = {
  handleFileDrop: func.isRequired,
  artworkUrl: string.isRequired,
  title: string.isRequired,
  errors: shape({}).isRequired,
  error: string.isRequired,
  watch: func.isRequired,
  width: number,
}
