import { PROMOTION_GENRES } from '#pages/promotion/_utils/constants'

export const SORTED_GENRES = PROMOTION_GENRES.sort((a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
})
