import React from 'react'
import { TopSection } from './sections/top-section'
import { Distribute } from './sections/distribute-section'
import { PricingSection } from './sections/pricing-section'
import { HowItWorksSection } from './sections/how-it-works-section'
import { PageWrapper } from '#modules/page-wrapper'

export const LandingPage = () => {
  return (
    <PageWrapper isFullWidth showNavBar>
      <TopSection />
      <Distribute />
      <HowItWorksSection />
      <PricingSection />
    </PageWrapper>
  )
}
