import React from 'react'
import styled, { css } from 'styled-components'
import { func, shape, arrayOf, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { BaseInput } from '#components/inputs/base-input'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { Modal } from '#components/modal'
import { ModalHeader } from '#pages/vault/song/tabs/splits/components/modals/_components/modal-header/ModalHeader'
import { sumColumnTotal } from '#pages/vault/song/tabs/splits/utils/helpers'

const SDivider = styled.div`
  background: ${({ theme: { colors } }) => colors.darkGrey66};
  height: 2px;
  margin: 1rem 0;
  width: 100%;
`

const SSplitsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SUserName = styled.span`
  width: 12.5rem;
`

const STotalsLeftSuggestions = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`

const SPercentage = styled.span`
  margin-left: 5px;
`

const STotalTypo = styled.span`
  width: 12.5rem;
`

const SButtons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
`

const SModal = styled(Modal)`
  width: 37.5rem;
`

const SForm = styled.form`
  width: 100%;
`

const STotals = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const STotalValue = styled.div`
  font-weight: bold;
  width: 130px;
  margin-left: auto;
  text-transform: uppercase;
`
const STotalMaster = styled(STotalValue)`
  margin-left: 20px;
  ${({
    error,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    error &&
    css`
      color: ${COLORS.error_50};
    `}
`
const STotalPublishing = styled(STotalValue)`
  margin-left: auto;
  ${({
    error,
    theme: {
      tunego: { COLORS },
    },
  }) =>
    error &&
    css`
      color: ${COLORS.error_50};
    `}
`

const SMasterIncomeInput = styled.div`
  display: flex;
  align-items: center;
`

const SPublishingIncomeInput = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`

export const EditIncomeModal = ({
  onClose,
  updateIncome,
  splits,
  isOpened,
  singleSplit,
}) => {
  const { t } = useTranslation()
  const vaultTranslation = useTranslation('vault')
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm()

  const watchedFields = watch()
  const watchedFieldsKeysArray = Object.keys(watchedFields)
  const watchedMaster = watchedFieldsKeysArray
    .map(field => field.includes('master') && field)
    .filter(Boolean)
  const watchedPublishing = watchedFieldsKeysArray
    .map(field => field.includes('publishing') && field)
    .filter(Boolean)

  const totalMaster = watchedMaster.length
    ? watchedMaster.reduce(
        (prev, current) => prev + Number(watchedFields[current]),
        0
      )
    : null

  const totalPublishing = watchedPublishing.length
    ? watchedPublishing.reduce(
        (prev, current) => prev + Number(watchedFields[current]),
        0
      )
    : null

  const onChange = splitId => e => {
    if (Number(e.target.value) > 100 || Number(e.target.value) < 0) {
      setError(splitId, {
        type: 'invalid_value',
        message: vaultTranslation.t('selectValueBetween'),
      })
    } else {
      clearErrors(splitId)
      setValue(splitId, e.target.value)
    }
  }

  const isSubmitDisabled = singleSplit
    ? totalMaster !== 100 && totalMaster !== null
    : (totalMaster !== 100 || totalPublishing !== 100) &&
      (totalMaster !== null || totalPublishing !== null)

  return (
    <SModal isOpen={isOpened} setIsOpen={onClose}>
      <ModalHeader>{vaultTranslation.t('editSplits')}</ModalHeader>
      <SDivider />
      {!singleSplit && (
        <STotals>
          <SUserName />
          <STotalMaster>{vaultTranslation.t('master')}</STotalMaster>
          <STotalPublishing>
            {vaultTranslation.t('publishing')}
          </STotalPublishing>
        </STotals>
      )}
      <SForm onSubmit={handleSubmit(updateIncome)}>
        {splits?.map(split => {
          return (
            <SSplitsItem key={split.id}>
              <SUserName>
                {split?.pendingName ||
                  `${split?.user?.firstName} ${split?.user?.lastName}`}
              </SUserName>
              <SMasterIncomeInput>
                <BaseInput
                  defaultValue={split.masterIncomeToDisplay}
                  customWidth={110}
                  name={`${split.id}-master`}
                  inputRef={register}
                  type="number"
                  min={0}
                  max={100}
                  errorMessage={errors[`${split.id}-master`]?.message}
                  onChange={onChange(`${split.id}-master`)}
                />
                <SPercentage>%</SPercentage>
              </SMasterIncomeInput>
              {!singleSplit && (
                <SPublishingIncomeInput>
                  <BaseInput
                    defaultValue={split.publishingToDisplay}
                    customWidth={110}
                    name={`${split.id}-publishing`}
                    inputRef={register}
                    type="number"
                    min={0}
                    max={100}
                    errorMessage={errors[`${split.id}-publishing`]?.message}
                    onChange={onChange(`${split.id}-publishing`)}
                  />
                  <SPercentage>%</SPercentage>
                </SPublishingIncomeInput>
              )}
            </SSplitsItem>
          )
        })}
        <SDivider />
        <STotalsLeftSuggestions>
          <STotalTypo>{vaultTranslation.t('totalMustEqual')}</STotalTypo>
          <STotalMaster error={totalMaster !== 100 && totalMaster !== null}>
            {`${
              totalMaster === null
                ? sumColumnTotal(splits, `publishing`)
                : totalMaster
            }%`}
          </STotalMaster>
          {!singleSplit && (
            <STotalPublishing
              error={totalPublishing !== 100 && totalPublishing !== null}
            >
              {`${
                totalPublishing === null
                  ? sumColumnTotal(splits, `masterIncome`)
                  : totalPublishing
              }%`}
            </STotalPublishing>
          )}
        </STotalsLeftSuggestions>
        <SButtons>
          <Button disabled={isSubmitDisabled} type="submit">
            {vaultTranslation.t('submitApproval')}
          </Button>
          <Button variant={VARIANTS.CANCEL} onClick={onClose}>
            {t('cancel')}
          </Button>
        </SButtons>
      </SForm>
    </SModal>
  )
}

EditIncomeModal.propTypes = {
  onClose: func.isRequired,
  updateIncome: func.isRequired,
  splits: arrayOf(shape({})).isRequired,
  isOpened: bool.isRequired,
  singleSplit: bool,
}

EditIncomeModal.defaultProps = {
  singleSplit: false,
}
