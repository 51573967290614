import React from 'react'
import { shape, arrayOf } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { PaymentHistoryList } from '#pages/profile/tabs/payment-history/components/payment-history-list'
import { PaymentHistoryHeader } from '#pages/profile/tabs/payment-history/components/payment-history-header'

const useStyles = makeStyles(() => ({
  paymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
  },
}))

export const PaymentHistory = ({ payments }) => {
  const classes = useStyles()

  return (
    <div className={classes.paymentWrapper}>
      <PaymentHistoryHeader />
      <PaymentHistoryList {...{ payments }} />
    </div>
  )
}

PaymentHistory.propTypes = {
  payments: arrayOf(shape()).isRequired,
}
