import React from 'react'

import {
  DetailsStepContent,
  FilesStepContent,
  ReviewStepContent,
  // SplitsStepContent,
} from '#pages/sell-collectibles/create-collectible/_components/steps'

export const STEP_NAME = {
  // NEW steps
  FILES: 'files',
  DETAILS: 'details',
  SPLITS: 'splits',
  REVIEW: 'review',
}

export const WIZARD_STEPS = t => [
  {
    label: t(STEP_NAME.FILES),
    key: STEP_NAME.FILES,
    content: <FilesStepContent />,
  },
  {
    label: t(STEP_NAME.DETAILS),
    key: STEP_NAME.DETAILS,
    content: <DetailsStepContent />,
  },
  // {
  //   label: t(STEP_NAME.SPLITS),
  //   key: STEP_NAME.SPLITS,
  //   content: <SplitsStepContent />,
  // },
  {
    label: t(STEP_NAME.REVIEW),
    key: STEP_NAME.REVIEW,
    content: <ReviewStepContent />,
  },
]
