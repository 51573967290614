import React from 'react'
import { ContentTableRow } from '#pages/vault/profile-content/components/rows/content-table-row'
import { FolderContent } from '#pages/vault/profile-content/components/folder-content'
import { useVault } from '#hooks/useVault'

export const TableBody = ({
  parent,
  parentId,
  artistContent,
  typeOfCurrentAction,
  idOfActiveActionItem,
  setStructureCategory,
  shouldEnableStructureDeletion,
  handleCloseInlineAction,
  handleDeleteModalOpen,
  handleDeleteModalClose,
  setTypeOfCurrentAction,
  setIdOfActiveActionItem,
  handleAddEditCollaborators,
  handleCollaboratorModalClose,
  handleCollaboratorModalOpen,
  handleMoveSingle,
}) => {
  const { expandedStructureId } = useVault()

  return artistContent?.data.map((row, index) => {
    const { id, name, accessRoles } = row
    const showFolderContent = expandedStructureId === id

    return (
      <React.Fragment key={name + id}>
        <ContentTableRow
          {...{
            row,
            index,
            parent,
            parentId,
            typeOfCurrentAction,
            idOfActiveActionItem,
            setStructureCategory,
            shouldEnableStructureDeletion,
            handleCloseInlineAction,
            handleDeleteModalOpen,
            handleDeleteModalClose,
            setTypeOfCurrentAction,
            setIdOfActiveActionItem,
            handleAddEditCollaborators,
            handleMoveSingle,
          }}
        />
        {showFolderContent && (
          <FolderContent
            {...{
              vaultId: parentId,
              setTypeOfCurrentAction,
              setIdOfActiveActionItem,
              handleDeleteModalOpen,
              handleDeleteModalClose,
              handleCollaboratorModalClose,
              handleCollaboratorModalOpen,
              setStructureCategory,
              handleMoveSingle,
              parent: { id, accessRoles },
            }}
          />
        )}
      </React.Fragment>
    )
  })
}
