export const TABLE_HEADER = [
  {
    name: 'fileName',
    width: '37.5%',
    padding: '0 0 0 40px',
    sortBy: 'name',
  },
  {
    name: 'fileFormat',
    width: '17.5%',
    sortBy: 'fileSuffix',
  },
  {
    name: 'lastUpdated',
    width: '20%',
    sortBy: 'updatedAt',
  },
  { name: 'fileSize', width: '15%', sortBy: 'size' },
]
// sum of name/format from above table..
export const EDIT_REMOVE_NAME_SIZE = { xs: '55%', sm: '60%' }

export const MIN_ROW_HEIGHT = 60

export const ACTION_TYPES = {
  setMaster: 'set-master',
  editOrRemove: 'editor-or-remove',
  download: 'download',
  listen: 'listen',
  privateSharing: 'private-sharing',
}

export const MENU_OPTIONS = [
  {
    title: 'Set Master',
    actionType: ACTION_TYPES.setMaster,
  },
  {
    title: 'Edit / Remove File',
    actionType: ACTION_TYPES.editOrRemove,
  },
  {
    title: 'Download',
    actionType: ACTION_TYPES.download,
  },
  {
    title: 'Listen',
    actionType: ACTION_TYPES.listen,
  },
  {
    title: 'Private Sharing',
    actionType: ACTION_TYPES.privateSharing,
  },
]

export const FILE_TYPE = {
  AUDIO: 'audio',
}

export const FILE_PREVIEW_STATUS = {
  PROCESSING: 'processing',
  PENDING: 'pending',
  ERROR: 'error',
  DONE: 'done',
}

export const FILE_QUALIFICATION_TYPES = {
  NFT: 'Collectible',
  DISTRO: 'Distro100',
  PROMOTION: 'Promotion',
}

export const getMenuOptions = ({ file, isOwner, isMaster }) => {
  const options = []
  if (!isMaster && file.fileSuffix === 'wav') {
    options.push({
      title: 'Set Master',
      actionType: ACTION_TYPES.setMaster,
    })
  }
  options.push({
    title: `Edit${isOwner ? ' / Remove File' : ''}`,
    actionType: ACTION_TYPES.editOrRemove,
  })
  if (file.category === FILE_TYPE.AUDIO) {
    let additionalMsg = ''
    const isProcessing =
      file.previewStatus === FILE_PREVIEW_STATUS.PROCESSING ||
      file.previewStatus === FILE_PREVIEW_STATUS.PENDING
    const hasError = file.previewStatus === FILE_PREVIEW_STATUS.ERROR
    const disabled = isProcessing || hasError
    if (isProcessing) {
      additionalMsg = ' (Processing)'
    }
    if (hasError) {
      additionalMsg = ' (Transcoding failed. Please contact support)'
    }
    if (isOwner) {
      options.push({
        title: `Private Sharing${additionalMsg}`,
        actionType: ACTION_TYPES.privateSharing,
        disabled,
      })
    }
  }
  return options
}

export const SHARE_TYPES = {
  plays: 'plays',
  days: 'days',
}

export const SIDES = {
  contacts: 'contacts',
  email: 'email',
}

export const EXCLUDE_VAULT_EXTENSIONS = [
  'exe',
  'bat',
  'cmd',
  'sh',
  'msi',
  'vb',
  'vbs',
  'ps1',
  'jar',
  'py',
  'rb',
  'pl',
  'apk',
  'ini',
  'cfg',
  'reg',
  'sys',
  'dll',
  'drv',
  'docm',
  'xlsm',
  'pptm',
  'php',
  'asp',
  'aspx',
  'jsp',
  'html',
  'htm',
  'js',
  'css',
  'zip',
  'rar',
  '7z',
  'tar',
  'gz',
  'iso',
  'img',
  'sql',
  'mdb',
  'accdb',
]
