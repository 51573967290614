import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useMarketFees } from '#hooks/swr/useMarketFees'
import { BaseLoader } from '#components/loaders/base-loader'
import { NFT_DETAILS_STEP_FIELDS } from '#pages/sell-collectibles/create-collectible/_constants/form'
import { CartIcon } from '#icons'
import { SummaryTableBody } from '#pages/sell-collectibles/create-collectible/_components/steps/details/summary-table/_components/summary-table-body'

const STitle = styled.h3`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['2xl']};
  text-transform: uppercase;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const STableWrapper = styled.div`
  border: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  background-color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.secondary_70};
  padding: 2rem;
`

const STable = styled.table`
  color: ${({
    theme: {
      tunego: { COLORS },
    },
    filled,
  }) => (filled ? COLORS.neutral_0 : COLORS.neutral_60)};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.md};
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
`

const SHeader = styled.th`
  border-bottom: 1px solid
    ${({
      theme: {
        tunego: { COLORS },
      },
    }) => COLORS.neutral_85};
  padding: 0.625rem 0;
  text-align: left;

  &:last-child {
    text-align: right;
  }
  color: ${({
    theme: {
      tunego: { COLORS },
    },
  }) => COLORS.neutral_60};
`

const SCartIcon = styled(CartIcon)`
  margin-right: 1rem;
`

const SText = styled.p`
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE.sm};
`

export const SummaryTable = () => {
  const { t } = useTranslation('nftWizard')
  const { watch } = useFormContext()
  const { fees, isLoading } = useMarketFees()

  const scarcity = watch(NFT_DETAILS_STEP_FIELDS.SCARCITY)
  const price = watch(NFT_DETAILS_STEP_FIELDS.PRICE) * 100

  return (
    <SWrapper>
      <STitle>{t('steps.details.potentialEarnings')}</STitle>
      <STableWrapper>
        {isLoading ? (
          <BaseLoader text={t('steps.details.calculating')} isLoading />
        ) : (
          <STable filled={scarcity && price}>
            <thead>
              <tr>
                <SHeader>{t('steps.details.quantity')}</SHeader>
                <SHeader>{t('steps.details.price')}</SHeader>
                <SHeader>{t('steps.details.totalEarnings')}</SHeader>
              </tr>
            </thead>
            <SummaryTableBody fees={fees} scarcity={scarcity} price={price} />
          </STable>
        )}
      </STableWrapper>
      <SText>
        <SCartIcon />
        {t('steps.details.secondarySalesInfo')}
      </SText>
    </SWrapper>
  )
}
