import React from 'react'
import { useParams } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { buildUrl } from '#utils/buildUrl'
import { useArtistStructure } from '#hooks/swr/useArtists'
import { Breadcrumbs } from '#components/breadcrumbs'
import { TabbedViewContainer } from '#pages/vault/song/containers/tabbed-view-container'
import { PageWrapper } from '#modules/page-wrapper'
import { generateTabs } from '#pages/vault/song/utils/helpers'
import { SongContext } from '#pages/vault/song/context/song'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const SongPage = () => {
  const { projectId } = useParams()
  const { project } = useArtistStructure(projectId)
  const { t } = useTranslation('vault')
  const { enableLyrics } = useFlags()

  const nestedBreadcrumbs =
    project?.parentsPath.map((item, index) => {
      if (index === 1) {
        return {
          link: buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, {
            id: project?.parentsPath[0].id,
          }),
          state: { openFolderId: item.id },
          title: item.name,
        }
      }
      return {
        link: buildUrl(NAVIGATION_PATHS.PROFILE_CONTENT, {
          id: item.id,
        }),
        title: item.name,
      }
    }) || []

  const breadcrumbs = [
    {
      link: NAVIGATION_PATHS.VAULT_PROFILES,
      title: t('myVault'),
    },
    ...nestedBreadcrumbs,
    { title: project?.name },
  ]

  return (
    <PageWrapper showNavBar isFullWidth>
      <Breadcrumbs {...{ breadcrumbs }} />
      <SongContext.Provider value={project}>
        {project && (
          <TabbedViewContainer
            {...{ tabs: generateTabs(project?.accessRoles, enableLyrics) }}
          />
        )}
      </SongContext.Provider>
    </PageWrapper>
  )
}
