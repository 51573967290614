import React from 'react'
import styled from 'styled-components'
import { string, bool } from 'prop-types'
import { navigate } from '@reach/router'

import { Button } from '@tunego/tunego-ui-components'
import { MainHeader } from '#pages/_components/section-headers/main-header'
import { SecondaryHeader } from '#pages/_components/section-headers/secondary-header'
import { useTranslation } from 'react-i18next'
import { NAVIGATION_PATHS } from '#routes/routes'
import { mobileAppLink } from '#utils/mobileAppLink'
import { useQuery } from '#hooks/useQuery'

const SButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 1rem;
`

const SMainHeader = styled(MainHeader)`
  @media screen and (min-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    font-size: 2.625rem;
  }
`

const SSecondaryHeader = styled(SecondaryHeader)`
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  text-transform: none;
`

const STertiaryHeader = styled.h3`
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  text-transform: uppercase;
`

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({
      theme: {
        tunego: { BREAKPOINTS },
      },
    }) => BREAKPOINTS.sm}) {
    white-space: pre;
  }
`

const SAddress = styled.div`
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-style: italic;
  margin-bottom: 1rem;
`

const SText = styled.p`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
`
export const WalletConnected = ({ address, mobile }) => {
  const { t } = useTranslation('connectWallet')
  const nftId = useQuery('nftId')

  return (
    <SContent>
      <SMainHeader>{t('successfullyConnected')}</SMainHeader>
      <SSecondaryHeader>{t('yourDapperAddress')}</SSecondaryHeader>
      <SAddress>{address}</SAddress>

      {mobile ? (
        <Button as="a" href={`${mobileAppLink(true)}?nftId=${nftId}`}>
          {t('goBackToApp')}
        </Button>
      ) : (
        <>
          <SText>{t('sellingInfo')}</SText>
          <STertiaryHeader>{t('howToGetPaid')}</STertiaryHeader>
          <SText>{t('sellInTheMarketplace')}</SText>
          <SText>{t('primarySales')}</SText>
          <SText>{t('secondarySales')}</SText>
          <SButton
            onClick={() => navigate(NAVIGATION_PATHS.CREATE_COLLECTIBLES)}
          >
            {t('sellYourCollectibles')}
          </SButton>
        </>
      )}
    </SContent>
  )
}

WalletConnected.propTypes = {
  address: string.isRequired,
  mobile: bool,
}
