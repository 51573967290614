import React from 'react'
import { arrayOf, string } from 'prop-types'
import { FilesContainer } from '#pages/vault/song/tabs/files/containers/files-container'

export const Files = ({ accessRoles }) => {
  return <FilesContainer {...{ accessRoles }} />
}

Files.propTypes = {
  accessRoles: arrayOf(string).isRequired,
}
