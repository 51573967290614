import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, bool, oneOfType, string } from 'prop-types'
import { ImageAndDetails } from '#pages/distro/past-release-details/components/image-and-details'
import { TrackListing } from '#pages/distro/past-release-details/components/track-listing'
import { MusicStores } from '#pages/distro/past-release-details/components/music-stores'
import { ReleaseDetails } from '#pages/distro/past-release-details/components/release-details'
import { Territories } from '#pages/distro/past-release-details/components/territories'

const useStyles = makeStyles(theme => ({
  detailsWrapper: {
    display: 'flex',
    justify: 'center',
    alignItems: 'flex-start',
    width: '100%',
    minWidth: 1180,
    backgroundColor: theme.palette.color.darkBlue09,
    border: `1px solid ${theme.palette.color.darkGrey}`,
    flexGrow: 1,
    padding: 30,
    margin: '30px 0',
  },
  column: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',

    '&:nth-of-type(1)': {
      maxWidth: 580,
    },

    '&:nth-of-type(2)': {
      paddingLeft: 50,
      maxWidth: 520,
    },
  },
  tracksStores: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  releaseDetailsWrapper: {
    width: '100%',
    marginBottom: 60,
  },
  territoriesWrapper: {
    width: '100%',
  },
}))

export const DetailsContainer = ({ isPreview }) => {
  const classes = useStyles()

  return (
    <div className={classes.detailsWrapper}>
      <div className={classes.column}>
        <ImageAndDetails {...{ isPreview }} />
        <div className={classes.tracksStores}>
          <TrackListing {...{ isPreview }} />
          <MusicStores {...{ isPreview }} />
        </div>
      </div>
      <div className={classes.column}>
        <div className={classes.releaseDetailsWrapper}>
          <ReleaseDetails {...{ isPreview }} />
        </div>
        <div className={classes.territoriesWrapper}>
          <Territories {...{ isPreview }} />
        </div>
      </div>
    </div>
  )
}

DetailsContainer.propTypes = {
  isPreview: bool,
  releaseIssues: oneOfType([arrayOf(string), bool]),
}

DetailsContainer.defaultProps = {
  isPreview: false,
  releaseIssues: false,
}
