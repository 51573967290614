import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

const useStyles = makeStyles(() => ({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    textAlign: 'left',
    margin: 'auto',
    padding: '64px 24px 112px',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 1200,
    textAlign: 'left',
    marginBottom: 40,
  },
  header: {
    fontSize: '50px',
    textTransform: 'uppercase',
  },
  img: {
    maxWidth: '90%',
  },
  descriptionWrapper: {
    width: '100%',
    maxWidth: 1200,
    textAlign: 'left',
    marginBottom: 40,
    fontSize: 24,
    whiteSpace: 'pre-line',
  },
}))

const SButton = styled(Button)`
  height: 60px;
  width: 300px;
  font-size: 23px;
  text-transform: uppercase;
`

export const PlaylistCuratorsSection = () => {
  const { t } = useTranslation('promotion')
  const classes = useStyles()

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.headerWrapper}>
        <span className={classes.header}>{t('playlistCurators')}</span>
        <img
          src="/assets/images/spotify-apple.png"
          className={classes.img}
          alt=""
        />
      </div>
      <div className={classes.descriptionWrapper}>
        <p>{t('distributingYourMusicOnSpotifyAndApple')}</p>
        <p>{t('youMustGetPlaylisted')}</p>
      </div>
      <div>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.PROMOTION_NEW)}>
          {t('getStarted')}
        </SButton>
      </div>
    </div>
  )
}

PlaylistCuratorsSection.propTypes = {
  redirectLink: string.isRequired,
}
