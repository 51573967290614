import { usePopper } from 'react-popper'
import { useEffect, useState } from 'react'

export const usePopperReactSelect = ({ id, opened, baseStyles = {} }) => {
  const [menu, setMenu] = useState(null)
  const [control, setControl] = useState(null)

  usePopper(control, menu, {
    placement: 'top',
    strategy: 'fixed',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'preventOverflow', options: { padding: 20 } },
    ],
  })

  useEffect(() => {
    const [menu] = document.getElementsByClassName(`${id}__menu`)
    const [control] = document.getElementsByClassName(`${id}__control`)

    setMenu(menu)
    setControl(control)
  }, [opened])

  return {
    styles: {
      ...baseStyles,
      menuPortal: defaultStyles => ({
        ...defaultStyles,
        zIndex: 100000000,
      }),
    },
  }
}
