import React, { useState } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    width: '100%',
    cursor: 'pointer',
  },
  title: {
    fontSize: 14,
    lineHeight: '14px',
    marginBottom: 5,
    fontWeight: 700,
  },
  contentWrapper: {
    fontSize: 12,
    lineHeight: '14px',
    marginBottom: 10,
  },
}))

export const TerritoryAccordion = ({ title, content }) => {
  const classes = useStyles()
  const [isActive, setIsActive] = useState(false)

  const toggleState = () => {
    setIsActive(!isActive)
  }

  return (
    <>
      <div className={classes.titleWrapper} onClick={toggleState}>
        <span className={classes.title}>{title}</span>
        <img
          width={12}
          height={12}
          src={`/assets/svg/ic-chevron-${isActive ? 'up' : 'down'}.svg`}
          alt=""
        />
      </div>
      {isActive && (
        <div className={classes.contentWrapper}>
          {content.map(c => c.name).join(', ')}
        </div>
      )}
    </>
  )
}

TerritoryAccordion.propTypes = {
  title: string.isRequired,
  content: arrayOf(shape({})).isRequired,
}
