import React from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Section } from '#components/section'
import { NAVIGATION_PATHS } from '#routes/routes'
import styled from 'styled-components'

const useStyles = makeStyles(() => ({
  img: {
    width: 'auto',
    marginRight: 80,
    height: 400,
  },
  wrapper: {
    width: 750,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const SSection = styled(Section)`
  padding-bottom: 54px;
  background-image: none;
`

export const IndieRadioSection = () => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.container}>
      <img src="/assets/images/mic.png" alt="" className={classes.img} />
      <div className={classes.wrapper}>
        <SSection
          header={t('indieRadio')}
          getStartedUrl={NAVIGATION_PATHS.PROMOTION_NEW}
          subheaderComponent={
            <>
              <p style={{ textAlign: 'left' }}>
                {t('ifYouAreAnIndependentArtist')}
              </p>
              <p style={{ textAlign: 'left' }}>
                {t('researchingAndSubmittingYour')}
              </p>
            </>
          }
          isAlignedToLeft
          getStartedButtonToLeft
        />
      </div>
    </div>
  )
}

IndieRadioSection.propTypes = {
  redirectLink: string.isRequired,
}
