import * as yup from 'yup'
import i18next from 'i18next'
import { FORM_FIELDS } from '#pages/vault/_modules/add-collaborator-modal/constants'

const collaboratorUserValidationSchema = t => {
  return yup.object().shape({
    [FORM_FIELDS.USER]: yup
      .object()
      .nullable()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('userName'),
        })
      ),
    [FORM_FIELDS.PUBLISHER]: yup.string().trim().nullable(),
    [FORM_FIELDS.IPI_CAE_NUMBER]: yup
      .string()
      .trim()
      .nullable()
      .transform(value => value || null)
      .test(
        i18next.t('validation:acceptOnlyDigits'),
        i18next.t('validation:onlyDigits', {
          field: t(`ipiCae`),
        }),
        value => {
          if (value) {
            return /^\d+$/.test(value)
          }
          return true
        }
      )
      .min(
        9,
        i18next.t('validation:minimumLength', {
          field: t(`ipiCae`),
          number: 9,
        })
      )
      .max(
        11,
        i18next.t('validation:maximumLength', {
          field: t(`ipiCae`),
          number: 11,
        })
      ),
  })
}

const collaboratorOffPlatformValidationSchema = t => {
  return yup.object().shape({
    role: yup
      .array()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('role'),
        })
      )
      .typeError(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('role'),
        })
      ),
    collaboratorName: yup
      .string()
      .trim()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('userName'),
        })
      ),
    emailAddress: yup
      .string()
      .email(i18next.t('validation:validEmailAddress'))
      .required(i18next.t('validation:validEmailAddress')),
  })
}

const collaboratorRoleValidationSchema = t => {
  return yup.object().shape({
    role: yup
      .array()
      .required(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('role'),
        })
      )
      .typeError(
        i18next.t('validation:mustNotBeEmpty', {
          field: t('role'),
        })
      ),
  })
}

export const createCollaboratorValidationSchema = t => {
  return yup
    .mixed()
    .concat(collaboratorUserValidationSchema(t))
    .concat(collaboratorRoleValidationSchema(t))
}

export const createCollaboratorOffPlatformValidationSchema = t => {
  return yup.mixed().concat(collaboratorOffPlatformValidationSchema(t))
}
