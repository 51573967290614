import React, { useState } from 'react'
import { arrayOf, func, object } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { Row } from '#pages/promotion/reports/components/row'
import { ColumnInRow } from '#modules/table/components/column-in-row'
import {
  HEADINGS,
  MASTER_OPTIONS,
  ACTION_TYPES,
} from '#pages/mastering/_utils/constants'
import { DownloadButton } from '#pages/mastering/view-masters/components/download-button'
import { StatusButton } from '#pages/mastering/view-masters/components/status-button'
import { MASTER_STATUS } from '#pages/mastering/wizard/utils/constants'
import { NAVIGATION_PATHS } from '#routes/routes'
import { EllipsisMenu } from '#pages/_components/ellipsis-menu'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { MasteringApi } from '#api/requests/mastering'
import { BackdropMessage } from '#components/backdrop-message'
import { formatDate } from '#utils/formatDate'
import { UserImage } from '#components/user-image'

const useStyles = makeStyles(() => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  centeredColumnRow: {
    justifyContent: 'center',
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export const MastersList = ({ files, triggerMutate }) => {
  const { t } = useTranslation('mastering')
  const classes = useStyles()
  const { actions } = useWithAsyncAction({
    deleteMasteredFile: MasteringApi.deleteMasteredFile,
  })
  const [error, setError] = useState('')

  const handleDeleteMaster = async id => {
    try {
      await actions.deleteMasteredFile(id)
      triggerMutate()
    } catch (err) {
      const response = err.response?.data
      const { message } = response
      setError(message)
    }
  }

  const determineMenuAction = (actionType, id) => {
    switch (actionType) {
      case ACTION_TYPES.DELETE_MASTER: {
        return handleDeleteMaster(id)
      }
      default: {
        return null
      }
    }
  }

  const determineMenuOptions = (options, isDisabled) => {
    const masterOptions = options.map(option => ({
      ...option,
      disabled: !isDisabled,
    }))
    return [...masterOptions]
  }

  const clearError = () => {
    setError('')
  }

  return (
    <>
      {files?.map(({ file, ...masteredFile }, index) => {
        const { genre, createdAt, status, id, masterUrl, profile } =
          masteredFile

        const profiles = profile?.map(pro => t(`options.${pro}`)).join(', ')
        const isPreviewReady = status === MASTER_STATUS.PREVIEW_READY
        const isMasterReady = status === MASTER_STATUS.MASTER_READY

        return (
          <Row
            key={id}
            isLighter={index % 2 === 1}
            isPreviewReady={isPreviewReady}
          >
            <ColumnInRow width={HEADINGS[0].width} p={0}>
              <UserImage userImageUrl={file?.profile?.avatarUrl} />
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[1].width}>
              <div className={classes.ellipsis}>{file?.profile?.name}</div>
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[2].width}>
              <div className={classes.ellipsis}>{file?.name}</div>
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[3].width}>
              <div className={classes.settings}>
                <span>{t(`styles.${genre}`)}</span>
                <span>{profiles}</span>
              </div>
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[4].width}>
              {formatDate(createdAt)}
            </ColumnInRow>
            <ColumnInRow
              width={HEADINGS[5].width}
              className={classes.centeredColumnRow}
            >
              <StatusButton status={status} />
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[6].width}>
              <DownloadButton
                fileId={masterUrl ? id : null}
                isPreviewReady={isPreviewReady}
                url={`${NAVIGATION_PATHS.NEW_MASTERING}?trackId=${file.id}&masteredId=${id}&isEdited=true`}
              />
            </ColumnInRow>
            <ColumnInRow width={HEADINGS[7].width}>
              <EllipsisMenu
                options={determineMenuOptions(
                  MASTER_OPTIONS,
                  isPreviewReady || isMasterReady
                )}
                onChoose={actionType => {
                  determineMenuAction(actionType, id)
                }}
              />
            </ColumnInRow>
          </Row>
        )
      })}
      <BackdropMessage message={error} onBackdropClick={clearError} />
    </>
  )
}

MastersList.propTypes = {
  files: arrayOf(object).isRequired,
  triggerMutate: func.isRequired,
}
