import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { hotjar } from 'react-hotjar'

export const useAnalytics = () => {
  const GA_ID = process.env.REACT_APP_GA
  const ADWORDS_ID = process.env.REACT_APP_ADWORDS_TAG

  const getHotJarSetting = value => {
    if (value) return Number.parseInt(value, 10)
    return null
  }

  const HJID = getHotJarSetting(process.env.REACT_APP_HOTJAR_ID)
  const HJSV = getHotJarSetting(process.env.REACT_APP_HOTJAR_SV)

  useEffect(() => {
    if (GA_ID)
      ReactGA.initialize([
        {
          trackingId: GA_ID,
        },
        { trackingId: ADWORDS_ID },
      ])

    if (HJID && HJSV) hotjar.initialize(HJID, HJSV)
  }, [])
}
