import React, { useEffect, useState } from 'react'
import { func, number, string } from 'prop-types'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { SubscriptionsApi } from '#api/requests/subscriptions'
import { PaymentsApi } from '#api/requests/payments'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { NAVIGATION_PATHS } from '#routes/routes'
import { PAYMENT_STATUSES } from '#constants/paymentStatuses'
import { CommonLoadingOverlay } from '#modules/common-loading-overlay'
import { DIFFERENT_PAYMENT_METHOD } from '#pages/subscriptions/_utils/constants'
import { PaymentFormContainer } from '#modules/payments/containers/payment-form-container'
import {
  SUBSCRIPTION_PERIOD,
  BILLING_PERIOD,
} from '#pages/profile/utils/constants'
import { useQuery } from '#hooks/useQuery'
import { CURRENCY } from '#constants/currency'
import { useError } from '#hooks/useError'
import { useUser } from '#hooks/useUser'

export const CheckoutContainer = ({
  planId,
  planPeriod,
  price,
  setPayment,
  adjustmentPrice,
}) => {
  const { mutate } = useUser()
  const { t } = useTranslation()

  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false)
  const { error, setResponseError, setError, clearError } = useError()
  const isProrated = useQuery('isProrated')

  const isSwitched = useQuery('isSwitched')
  const isSwitchedTrue = isSwitched === 'true'
  const isProratedTrue = isProrated === 'true'

  const { actions, errors } = useWithAsyncAction({
    postUpgradeSubscription: SubscriptionsApi.postUpgradeSubscription,
    setPaymentMethodAsDefault: PaymentsApi.setPaymentMethodAsDefault,
    addNewCard: PaymentsApi.addNewCard,
  })

  useEffect(() => {
    if (errors.postPromotionStripeIntent) {
      setIsPaymentInProgress(false)
      setError(errors.postPromotionStripeIntent)
    }
  }, [errors])

  const handlePostUpgrade = async (billingPeriod, paymentId) => {
    const {
      data: {
        payment,
        subscription: { card },
      },
    } = await actions.postUpgradeSubscription({
      planId,
      billingPeriod,
      amount: price,
      currency: CURRENCY.USD,
      paymentMethodId: isProrated === 'true' ? undefined : paymentId,
    })
    mutate()

    if (payment && payment.status === PAYMENT_STATUSES.COMPLETED) {
      setPayment(payment)

      navigate(NAVIGATION_PATHS.SUBSCRIPTIONS_PAYMENT_SUCCESS, {
        state: {
          payment,
          planId,
          billingPeriod,
          adjustmentPrice,
          isSwitchedTrue,
          isProratedTrue,
          card,
        },
        replace: true,
      })
    }
  }

  const handlePayment = async ({
    methodId,
    stripe,
    cardElement,
    defaultMethodToSave,
    nameOnCard,
  }) => {
    setIsPaymentInProgress(true)

    const formattedPeriod =
      planPeriod === SUBSCRIPTION_PERIOD.MONTH
        ? BILLING_PERIOD.MONTH
        : BILLING_PERIOD.YEAR

    if (methodId === DIFFERENT_PAYMENT_METHOD) {
      try {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        })

        const {
          data: { clientSecret },
        } = await actions.addNewCard(paymentMethod.id)

        if (clientSecret) {
          const response = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })

          if (response.error) {
            setIsPaymentInProgress(false)
            setError(response.error.message)
          }
        }

        if (defaultMethodToSave) {
          const response = await actions.setPaymentMethodAsDefault(
            paymentMethod.id
          )

          if (response.error) {
            setIsPaymentInProgress(false)
            setError(response.error.message)
          }
        }

        await handlePostUpgrade(formattedPeriod, paymentMethod.id)
      } catch (err) {
        setIsPaymentInProgress(false)
        setResponseError(err)
      }
      setIsPaymentInProgress(false)
    } else {
      try {
        await handlePostUpgrade(formattedPeriod, methodId)
      } catch (err) {
        setIsPaymentInProgress(false)
        setResponseError(err)
      }
    }
    setIsPaymentInProgress(false)
  }

  const showLoader = isPaymentInProgress || error

  return (
    <>
      {showLoader && (
        <CommonLoadingOverlay
          message={t('payments.pleaseWaitWhileYourPayment')}
          dialogOpen={isPaymentInProgress}
          error={error}
          onErrorBackdropClick={clearError}
        />
      )}
      <PaymentFormContainer handlePayment={handlePayment} />
    </>
  )
}

CheckoutContainer.propTypes = {
  price: number.isRequired,
  planId: string.isRequired,
  planPeriod: string.isRequired,
  setPayment: func.isRequired,
  adjustmentPrice: number.isRequired,
}
