import React from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { HomeNavigation } from '#pages/home/_components/home-navigation'
import { SnoopWelcomeSection } from '#pages/home/snoop-drop-homepage/sections/snoop-welcome-section'
import { CreatorsCollectorsSection } from '#pages/home/snoop-drop-homepage/sections/creators-collectors-section'
import { SecureSection } from '#pages/home/_sections/secure-section/SecureSection'
import { InfoSection } from '#pages/home/_sections/info-section'
import {
  LANDING_PAGE_INFO,
  MARKETPLACE_URL,
} from '#pages/home/_utils/constants'
import { Footer } from '#components/footer'
import { useLogin } from '#hooks/useLogin'

const useStyles = makeStyles(theme => ({
  distroSection: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

export const SnoopDropHomepage = () => {
  const signupRedirect = useLogin(true)

  const classes = useStyles()

  const marketplaceRedirect = () => navigate(MARKETPLACE_URL)

  return (
    <>
      <HomeNavigation onButtonClick={signupRedirect} />
      <SnoopWelcomeSection />
      <CreatorsCollectorsSection />
      <SecureSection src="/assets/images/snoop/snoop-vault.png" />
      <InfoSection
        onButtonClick={signupRedirect}
        {...LANDING_PAGE_INFO.vault}
        image="/assets/images/snoop/snoop-mobile-vault.png"
      />
      <InfoSection
        reverse
        onButtonClick={signupRedirect}
        onButtonClick2={marketplaceRedirect}
        {...LANDING_PAGE_INFO.marketplace}
        image="/assets/images/snoop/snoop-mobile-marketplace.png"
      />
      <InfoSection
        onButtonClick={signupRedirect}
        {...LANDING_PAGE_INFO.distro}
        className={classes.distroSection}
      />
      <InfoSection
        reverse
        isLast
        onButtonClick={signupRedirect}
        {...LANDING_PAGE_INFO.social}
      />
      <Footer />
    </>
  )
}
