import React from 'react'
import { node, bool } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  row: {
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 0 0.5rem 1.5rem',
  },
  lighter: {
    background: theme.palette.color.darkGrey0B,
  },
  sumTotal: {
    background: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.5)',
  },
}))

export const RowWrapper = ({ children, isLighter, isSumTotal }) => {
  const classes = useStyles()

  return (
    <div
      className={cn(classes.row, {
        [classes.lighter]: isLighter,
        [classes.sumTotal]: isSumTotal,
      })}
      // eslint-disable-next-line react/no-unknown-property
      minHeight={60}
    >
      {children}
    </div>
  )
}

RowWrapper.propTypes = {
  isLighter: bool,
  isSumTotal: bool,
  children: node.isRequired,
}

RowWrapper.defaultProps = {
  isLighter: false,
  isSumTotal: false,
}
