import React from 'react'
import styled, { css } from 'styled-components'
import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CopyIcon } from './copy-link.svg'
import { ReactComponent as OpenIcon } from './open-link.svg'
import { string, func } from 'prop-types'

const SOption = styled.div`
  font-family: ${({ theme: { typography } }) => typography.bold};
  font-size: ${({ theme: { fontSize } }) => fontSize.sm};
  padding: 0.375rem 1rem 0.625rem;
  display: flex;
  align-items: center;
  margin-top: -0.625rem;
`

const iconStyle = css`
  color: inherit;
  text-decoration: none;
  border: none;
  background-color: transparent;
  margin-left: 0.375rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all 0.3s linear;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ theme: { colors } }) => colors.default};
    background-color: ${({ theme: { colors } }) => colors.darkBlue09};
  }
`

const SButton = styled.button`
  ${iconStyle}
`

const SAnchor = styled.a`
  ${iconStyle}
`

export const MarketplaceEllipsisOption = ({ artistId, onCopy }) => {
  const { t } = useTranslation('vault')

  const onCopyLink = () => {
    copy(`${process.env.REACT_APP_MARKETPLACE_URL}creator/${artistId}`)
    onCopy(t('textCopied'))
  }

  return (
    <SOption>
      <>{t('marketplace')}:</>

      <SButton onClick={onCopyLink}>
        <CopyIcon />
      </SButton>
      <SAnchor
        href={`${process.env.REACT_APP_MARKETPLACE_URL}creator/${artistId}`}
        target="_blank"
      >
        <OpenIcon />
      </SAnchor>
    </SOption>
  )
}

MarketplaceEllipsisOption.propTypes = {
  artistId: string,
  onCopy: func.isRequired,
}
