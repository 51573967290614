import React from 'react'
import { ELLIPSIS_OPTIONS } from '#pages/vault/profile-content/utils/constants'
import { EditRow } from '#pages/vault/profile-content/components/rows/edit-row'
import { DuplicateRowInline } from '#pages/vault/profile-content/components/rows/duplicate-row'
import { AlbumSubRow } from '#pages/vault/profile-content/components/rows/album-sub-row'

export const FolderContentRow = ({
  row,
  index,
  parent,
  vaultId,
  parentId,
  artistContent,
  setStructureCategory,
  handleDeleteModalOpen,
  handleDeleteModalClose,
  typeOfCurrentNestedAction,
  idOfNestedActiveActionItem,
  setTypeOfCurrentNestedAction,
  shouldEnableStructureDeletion,
  handleCloseNestedInlineAction,
  setIdOfNestedActiveActionItem,
  handleMoveSingle,
}) => {
  const { name, childrenUpdatedAt, id, category, accessRoles } = row
  const isNestedActiveActionItem = idOfNestedActiveActionItem === id
  const isEditRow =
    typeOfCurrentNestedAction === ELLIPSIS_OPTIONS.EDIT_INLINE &&
    isNestedActiveActionItem
  const isDuplicateRowInline =
    typeOfCurrentNestedAction === ELLIPSIS_OPTIONS.DUPLICATE_INLINE &&
    isNestedActiveActionItem

  switch (true) {
    case isEditRow:
      return (
        <EditRow
          key={name}
          {...{
            name,
            id,
            index,
            parentId,
            category,
            shouldEnableDeletion: shouldEnableStructureDeletion(accessRoles),
            handleCloseInlineAction: handleCloseNestedInlineAction,
            handleDeleteModalOpen,
            handleDeleteModalClose,
            isNested: true,
            isLastRow: index === artistContent.count - 1,
          }}
        />
      )
    case isDuplicateRowInline:
      return (
        <DuplicateRowInline
          key={name}
          {...{
            name,
            id,
            parentId,
            category,
            handleCloseInlineAction: handleCloseNestedInlineAction,
          }}
        />
      )
    default:
      return (
        <AlbumSubRow
          {...{
            accessRoles,
            vaultId,
            name,
            updatedAt: childrenUpdatedAt,
            id,
            category,
            index,
            typeOfCurrentNestedAction,
            setTypeOfCurrentNestedAction,
            idOfNestedActiveActionItem,
            setIdOfNestedActiveActionItem,
            parent,
            isLastRow: index === artistContent.count - 1,
            setStructureCategory,
            handleMoveSingle,
          }}
          key={name}
          subRowIndex={index}
        />
      )
  }
}
