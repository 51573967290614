import React from 'react'
import { bool, func, string } from 'prop-types'
import { Modal } from '#components/modal'
import { Button } from '#components/button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VARIANTS } from '#components/button/constants'
import { TransactionLoader } from '#pages/connect-wallet/_components/transaction-loader'
import { SETUP_STATUS } from '#pages/connect-wallet/_hooks/use-royalty-setup'

const SButton = styled(Button)`
  text-transform: uppercase;
`

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`

const SText = styled.p`
  text-align: center;
`

const SHeading = styled.h2`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { typography } }) => typography.bold};
  text-transform: uppercase;
`

const SError = styled.p`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.error};
`

export const SetupModal = ({
  setIsOpen,
  isOpen,
  onSetup,
  disabled,
  error,
  loading,
  setupStatus,
}) => {
  const { t } = useTranslation('connectWallet')

  const LOADER_TEXT = {
    [SETUP_STATUS.AwaitingDapper]: t('confirmTransactionInDapperPopup'),
    [SETUP_STATUS.InProgress]: t('handlingRequest'),
  }

  return (
    <Modal
      {...{ isOpen, setIsOpen }}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <SHeading>{t('completeSetup')}</SHeading>
      {loading ? (
        <TransactionLoader text={LOADER_TEXT[setupStatus]} />
      ) : (
        <>
          <SText>{t('continueSetup')}</SText>
          <SButtonWrapper>
            <SButton
              variant={VARIANTS.NEW_BLUE_SPECIAL}
              onClick={() => setIsOpen(false)}
            >
              {t('cancel')}
            </SButton>
            <SButton disabled={disabled} onClick={onSetup}>
              {t('continue')}
            </SButton>
          </SButtonWrapper>

          {error && <SError>{error}</SError>}
        </>
      )}
    </Modal>
  )
}

SetupModal.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  onSetup: func.isRequired,
  disabled: bool,
  loading: bool,
  error: string,
  setupStatus: string,
}
