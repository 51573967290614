import React from 'react'
import { makeStyles } from '@material-ui/core'
import { bool, func, string } from 'prop-types'
import { BottomButtonsNavigation } from '#modules/forms/components/bottom-buttons-navigation'
import { hexToRgba } from '#utils/hexToRgba'

const useStyles = makeStyles(theme => {
  return {
    container: {
      backgroundColor: hexToRgba(theme.palette.color.primary, 0.5),
      width: '100%',
      height: '100%',
    },
    footer: {
      height: '100%',
      borderTop: 'none',
    },
    buttonWrapperClassName: {
      justifyContent: 'flex-end',
    },
  }
})

export const AddSongMetadataFooter = ({
  onBackButtonClick,
  onNextButtonClick,
  customNextTitle,
  shouldDisableNextButton,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <BottomButtonsNavigation
        className={classes.footer}
        buttonWrapperClassName={classes.buttonWrapperClassName}
        customNextTitle={customNextTitle}
        shouldDisableNextButton={shouldDisableNextButton}
        onBackButtonClick={onBackButtonClick}
        onNextButtonClick={onNextButtonClick}
        onBackButtonType="button"
        onNextButtonType="button"
      />
    </div>
  )
}

AddSongMetadataFooter.propTypes = {
  onBackButtonClick: func.isRequired,
  onNextButtonClick: func.isRequired,
  shouldDisableNextButton: bool,
  customNextTitle: string,
}

AddSongMetadataFooter.defaultProps = {
  shouldDisableNextButton: false,
  customNextTitle: '',
}
