import React from 'react'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { string, func, arrayOf, shape } from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '#components/button'
import { VARIANTS } from '#components/button/constants'
import { CheckBoxItem } from '#pages/distro/wizard/steps/delivery-and-release-date/components/checkbox-item'
import { generateUniqueKey } from '#utils/generateUniqueKey'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.color.eden,
    border: `1px solid ${theme.palette.color.darkGrey}`,
    display: 'flex',
    flexDirection: 'column',
    padding: 25,
    marginRight: 25,
    minWidth: 240,
  },
  territoryWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
}))

const SButton = styled(Button)`
  width: 120px;
`

export const TerritoriesSwitcher = ({
  selectedTerritory,
  setSelectedTerritory,
  checkTerritory,
  territoriesFiltered,
  checkedTerritory,
}) => {
  const classes = useStyles()
  const { register } = useFormContext()

  const onSelectedTerritoryChanged = value => {
    setSelectedTerritory(value)
  }

  return (
    <div className={classes.container}>
      {Object.keys(territoriesFiltered).map((territory, i) => (
        <div
          key={generateUniqueKey(territory, i)}
          className={classes.territoryWrapper}
        >
          <SButton
            variant={
              selectedTerritory === territory ? undefined : VARIANTS.DARK_GREY
            }
            onClick={() => onSelectedTerritoryChanged(territory)}
          >
            {territory}
          </SButton>
          <CheckBoxItem
            register={register}
            name={territory}
            value={territory}
            checked={checkedTerritory[territory]}
            isIndeterminate={checkedTerritory[territory] === null}
            width="unset"
            onChange={event => checkTerritory(territory, event.target.checked)}
          />
        </div>
      ))}
    </div>
  )
}

TerritoriesSwitcher.propTypes = {
  checkedTerritory: shape({}).isRequired,
  territoriesFiltered: arrayOf(shape({})).isRequired,
  selectedTerritory: string.isRequired,
  setSelectedTerritory: func.isRequired,
  checkTerritory: func.isRequired,
}
