import React from 'react'
import styled, { css } from 'styled-components'
import { StepTitle } from '#pages/sell-collectibles/landing-page/_components/step-title'
import { string, bool } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CreateCollectibleButton } from '#pages/_components/create-collectible-button'

const SStepWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7.5rem;

  ${({ right }) =>
    right &&
    `
      flex-direction: row-reverse;
  `}
`

const SStep = styled.div`
  max-width: 40.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    max-width: 30rem;
  }
`

const stepHeaderStyle = css`
  font-family: 'AvenirNextLTPro-Bold', Arial, serif;
  text-transform: uppercase;
  font-size: ${({ theme: { fontSize } }) => fontSize['2xl']};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  line-height: normal;
`

const SUnlock = styled.div`
  ${stepHeaderStyle};
  color: ${({ theme: { colors } }) => colors.darkGrey66};
`

const SStepImage = styled.img`
  max-width: 32.875rem;

  @media screen and (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.lg}) {
    max-width: 20rem;
  }
`

const SStepNumber = styled.div`
  ${stepHeaderStyle};
  color: ${({ theme: { colors } }) => colors.primary};
`

export const Step = ({
  title,
  text,
  iconSrc,
  unlocked,
  index,
  className,
  right,
  imgSrc,
}) => {
  const { t } = useTranslation('sellCollectibles')

  return (
    <SStepWrapper right={right} className={className}>
      {imgSrc && <SStepImage src={imgSrc} alt={title} />}
      <SStep>
        <>
          {unlocked ? (
            <SStepNumber>
              <img src={iconSrc} alt={`${title} icon`} />
              <span>{t('step', { index })}</span>
            </SStepNumber>
          ) : (
            <SUnlock>
              <img src="assets/svg/ic-lock.svg" alt="Lock icon" />
              <span>{t('unlock', { index })}</span>
            </SUnlock>
          )}
        </>
        <StepTitle>{title}</StepTitle>
        <p>{text}</p>
        <CreateCollectibleButton />
      </SStep>
    </SStepWrapper>
  )
}

StepTitle.propTypes = {
  index: string,
  title: string.isRequired,
  text: string.isRequired,
  unlocked: bool,
  iconSrc: string,
  className: string,
  right: bool,
  imgSrc: string,
}
