import React from 'react'
import styled from 'styled-components'

const SHeader = styled.h1`
  text-transform: uppercase;
  margin: 0;
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
  font-size: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.SIZE['5xl']};
`

export const StepHeader = ({ title }) => {
  return <SHeader>{title}</SHeader>
}
