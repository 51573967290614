import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { Button } from '#components/button'
import { NAVIGATION_PATHS } from '#routes/routes'

// Mocked video component

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  video: {
    width: '100%',
    maxWidth: 1200,
    boxShadow: `0 0 30px 0 ${theme.palette.color.primary}, inset 0 0 30px 0 ${theme.palette.color.primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '36px',
    [theme.breakpoints.up('md')]: {
      height: 676,
      marginBottom: '60px',
    },
  },
  btn: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginBottom: 130,
    },
  },
}))

const SButton = styled(Button)`
  width: 300px;
  height: 60px;
  font-size: 23px;
  text-transform: uppercase;
`

export const SyncHighlightSection = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.video}>
        <iframe
          src="https://player.vimeo.com/video/511974155"
          width={1200}
          height={700}
          frameBorder={0}
          allow="autoplay; fullscreen"
        />
      </div>
      <div className={classes.btn}>
        <SButton onClick={() => navigate(NAVIGATION_PATHS.PROMOTION_NEW)}>
          {t('getStarted')}
        </SButton>
      </div>
    </div>
  )
}

SyncHighlightSection.propTypes = {
  redirectLink: string.isRequired,
}
