import React from 'react'
import { Button, ButtonTheme } from '@tunego/tunego-ui-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { func, object } from 'prop-types'

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  width: 100%;
  margin-top: 2rem;
`

const SText = styled.p`
  font-family: ${({
    theme: {
      tunego: { FONT },
    },
  }) => FONT.FAMILY.bold};
`

export const OverwriteFile = ({ onCancel, onUpload, alreadyExistingFile }) => {
  const { t } = useTranslation('nftWizard')

  const enableOverwrite = !alreadyExistingFile?.isUsedInQuicklisting

  return (
    <>
      <SText>
        {t(
          enableOverwrite
            ? 'steps.files.doYouWantToOverwrite'
            : 'steps.files.cannotOverwrite'
        )}
      </SText>
      <SButtonWrapper center={!enableOverwrite}>
        <Button buttonTheme={ButtonTheme.Secondary_Warning} onClick={onCancel}>
          {t('steps.files.cancel')}
        </Button>
        {enableOverwrite && (
          <Button buttonTheme={ButtonTheme.Warning} onClick={onUpload}>
            {t('steps.files.continue')}
          </Button>
        )}
      </SButtonWrapper>
    </>
  )
}

OverwriteFile.propTypes = {
  onCancel: func,
  onUpload: func,
  alreadyExistingFile: object,
}
