import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { arrayOf, shape, string } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'

const useStyles = makeStyles(theme => ({
  stickyNavbar: {
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '100%',
    paddingRight: 16,
    position: 'sticky',
    top: '9rem',
  },
  activeLink: {
    fontFamily: '"AvenirNextLTPro-Bold", "Arial", sans-serif',
    color: theme.palette.primary.main,
  },
  linkWrapper: {
    width: '100%',
    padding: '8px 0',
  },
  link: {
    width: '100%',
    cursor: 'pointer',
  },
}))

export const StickyReviewNavbar = ({ links }) => {
  const classes = useStyles()
  const { t } = useTranslation('promotion')

  return (
    <div className={classes.stickyNavbar}>
      {links.map(link => (
        <div key={link.element} className={classes.linkWrapper}>
          <Link
            className={classes.link}
            activeClass={classes.activeLink}
            to={link.element}
            offset={-200}
            duration={500}
            spy
            smooth
          >
            {t(link.title)}
          </Link>
        </div>
      ))}
    </div>
  )
}

StickyReviewNavbar.propTypes = {
  links: arrayOf(
    shape({
      element: string,
    })
  ).isRequired,
}
