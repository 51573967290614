import React from 'react'
import styled from 'styled-components'
import { bool, func } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button } from '#components/button'

const SButton = styled(Button)`
  background-color: ${({ isFormValid }) =>
    isFormValid ? ({ theme: { colors } }) => colors.primary : 'transparent'};
  border: 1px solid ${({ theme: { colors } }) => colors.primary};
  color: ${({ isFormValid }) =>
    isFormValid
      ? ({ theme: { colors } }) => colors.default
      : ({ theme: { colors } }) => colors.primary};
  pointer-events: ${({ isFormValid }) => (isFormValid ? 'auto' : 'none')};
  margin-top: 30px;
  width: 100%;
  text-transform: uppercase;
`

export const CompletePurchaseButton = ({
  handlePurchaseSubmit,
  isFormValid,
}) => {
  const { t } = useTranslation()

  return (
    <SButton isFormValid={isFormValid} onClick={handlePurchaseSubmit}>
      {t('payments.completePurchase')}
    </SButton>
  )
}

CompletePurchaseButton.propTypes = {
  handlePurchaseSubmit: func.isRequired,
  isFormValid: bool.isRequired,
}
