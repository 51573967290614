import React, { useState } from 'react'
import { useWithAsyncAction } from '#hooks/useWithAsyncAction'
import { PromotionApi } from '#api/requests/promotion'
import { useWizard } from '#modules/forms/form-wizard/context'
import { StepContainer } from '#modules/forms/form-wizard/containers/step-container'
import { PaymentCheckout } from '#modules/checkout'
import { CHECKOUT_TYPE } from '#modules/checkout/utils/constants'
import { PROMO_PRICE_TYPE } from '#constants/priceTypes'
import { useFile } from '#hooks/swr/useFiles'
import { CURRENCY } from '#constants/currency'
import { useUser } from '#hooks/useUser'

export const Checkout = () => {
  const { incrementStep, storedFormState } = useWizard()
  const { planType, selectedFileName, selectedFileId, trackName, genre } =
    storedFormState
  const { priceOf } = useUser()
  const priceType = PROMO_PRICE_TYPE[planType]
  const price = priceOf(priceType)
  const type = CHECKOUT_TYPE.PROMOTION
  const [paymentId, setPaymentId] = useState(null)
  const { file } = useFile(selectedFileId)
  const { actions } = useWithAsyncAction({
    postPromotionStripeIntent: PromotionApi.postPromotionStripeIntent,
    postUnpaidPromotion: PromotionApi.postUnpaidPromotion,
  })

  const orderData = [
    {
      rowTitle: 'Artist Profile',
      rowValue: file?.profile?.name,
    },
    {
      rowTitle: 'Song File',
      rowValue: selectedFileName,
    },
    {
      rowTitle: 'Review Level',
      rowValue: planType,
    },
  ]

  const handlePayment = async ({
    stripe,
    stopLoading,
    setError,
    paymentMethodId,
  }) => {
    const {
      data: { id: promotionId },
    } = await actions.postUnpaidPromotion({
      fileId: selectedFileId,
      trackName,
      genre: Number(genre.value),
      reviewLevel: planType,
    })

    const {
      // eslint-disable-next-line no-shadow
      data: { clientSecret, paymentId },
    } = await actions.postPromotionStripeIntent(promotionId, {
      amount: price.value,
      currency: CURRENCY.USD,
    })

    setPaymentId(paymentId)

    const response = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    })
    if (response.error) {
      stopLoading()
      setError(response.error.message)
    }

    incrementStep()
  }

  const orderSummaryProps = {
    orderData,
    price: price?.value,
    showCard: true,
    showOrderNumber: true,
  }

  return (
    <StepContainer noNextButton>
      <PaymentCheckout
        {...{ orderSummaryProps, handlePayment, type, paymentId }}
        withCard
      />
    </StepContainer>
  )
}
