export const getFileInfo = file => {
  if (!file) return null

  const { name, type, size } = file
  const fileSuffix = name.split('.').pop()
  const fileName = name.replace(`.${fileSuffix}`, '')
  const category = type.includes('audio') ? 'audio' : 'session'
  // BE expects string
  const fileSize = String(size)

  return { fileSuffix, name: fileName, category, fileSize }
}
